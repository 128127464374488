import HowToCreateProject from './Sub/Project/HowToCreateProject';
import HowToManageProject from './Sub/Project/HowToManageProject';
import AccordionBasic from '@/components/molecules/AccordionBasic';

export default function HelpProject(): JSX.Element {
    const lists = [
        {
            id: 'createProject',
            title: 'How to create project',
            content: <HowToCreateProject />,
        },
        {
            id: 'manageProject',
            title: 'How to manage project',
            content: <HowToManageProject />,
        },
    ];

    return <AccordionBasic lists={lists} />;
}
