import { axiosClient } from '@/services/GatewayService';
import { AppDispatch } from '@/redux/store';
import { toast } from 'react-toastify';

import {
    FORGOT_PASSWORD_EMAIL_REQUEST,
    FORGOT_PASSWORD_EMAIL_SUCCESS,
    FORGOT_PASSWORD_EMAIL_FAIL,
    FORGOT_PASSWORD_PHONE_REQUEST,
    FORGOT_PASSWORD_PHONE_SUCCESS,
    FORGOT_PASSWORD_PHONE_FAIL,
    RESET_PASSWORD_EMAIL_REQUEST,
    RESET_PASSWORD_EMAIL_SUCCESS,
    RESET_PASSWORD_EMAIL_FAIL,
    RESET_PASSWORD_PHONE_REQUEST,
    RESET_PASSWORD_PHONE_SUCCESS,
    RESET_PASSWORD_PHONE_FAIL,
    RESET_PASSWORD_PHONE_RESET,
    VERIFY_OTP_REQUEST,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAIL,
    CLEAR_STATES,
} from '../reducers';

/**
 * Function to send forgot password with email
 *
 * @param {FormData} formData
 * @return {Promise<void>}
 */
export const forgotPasswordEmail = (formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: FORGOT_PASSWORD_EMAIL_REQUEST });
        try {
            const res = await axiosClient.post('auth/reset-password/email', formData);

            dispatch({
                type: FORGOT_PASSWORD_EMAIL_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: FORGOT_PASSWORD_EMAIL_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to resend forgot password with email
 *
 * @param {FormData} formData
 * @return {Promise<void>}
 */
export const resendPasswordEmail = (formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: FORGOT_PASSWORD_EMAIL_REQUEST });
        try {
            const res = await axiosClient.post('auth/reset-password/email', formData);

            dispatch({
                type: FORGOT_PASSWORD_EMAIL_SUCCESS,
                payload: res.data,
            });

            toast.success(res?.data?.message, {
                position: toast.POSITION.TOP_CENTER,
            });
        } catch (error: any) {
            dispatch({
                type: FORGOT_PASSWORD_EMAIL_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to send forgot password with phone number
 *
 * @param {FormData} formData
 * @return {Promise<void>}
 */
export const forgotPasswordPhone = (formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: FORGOT_PASSWORD_PHONE_REQUEST });
        try {
            const res = await axiosClient.post('auth/reset-password/phone', formData);

            dispatch({
                type: FORGOT_PASSWORD_PHONE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: FORGOT_PASSWORD_PHONE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to resend forgot password with phone number
 *
 * @param {FormData} formData
 * @return {Promise<void>}
 */
export const resendPasswordPhone = (formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: FORGOT_PASSWORD_PHONE_REQUEST });
        try {
            const res = await axiosClient.post(
                'auth/reset-password/phone/resend',
                formData,
            );

            dispatch({
                type: FORGOT_PASSWORD_PHONE_SUCCESS,
                payload: res.data,
            });

            toast.success(res?.data?.message, {
                position: toast.POSITION.TOP_CENTER,
            });
        } catch (error: any) {
            dispatch({
                type: FORGOT_PASSWORD_PHONE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to verify OTP
 *
 * @param {FormData} formData
 * @return {Promise<void>}
 */
export const verifyOTP = (token: number): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: VERIFY_OTP_REQUEST });
        try {
            const res = await axiosClient.get(
                `auth/reset-password/phone/verify?reset_otp=${token}`,
            );

            dispatch({
                type: VERIFY_OTP_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: VERIFY_OTP_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to reset password with email
 *
 * @param {FormData} formData
 * @return {Promise<void>}
 */
export const resetPasswordEmail = (formData: FormData, token: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESET_PASSWORD_EMAIL_REQUEST });
        try {
            const res = await axiosClient.post(
                `auth/reset-password/email/accept?reset_token=${token}`,
                formData,
            );

            dispatch({
                type: RESET_PASSWORD_EMAIL_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: RESET_PASSWORD_EMAIL_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to reset password with email
 *
 * @param {FormData} formData
 * @return {Promise<void>}
 */
export const resetPasswordPhone = (formData: FormData, otp: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESET_PASSWORD_PHONE_REQUEST });
        try {
            const res = await axiosClient.post(
                `auth/reset-password/phone/accept?reset_otp=${otp}`,
                formData,
            );

            dispatch({
                type: RESET_PASSWORD_PHONE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: RESET_PASSWORD_PHONE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to clear Forgot Password State
 *
 * @returns {void}
 */
export const clearForgotPasswordState = (): any => {
    return (dispatch: AppDispatch): void => {
        dispatch({ type: CLEAR_STATES });
    };
};

/**
 * Function to clear Forgot Password State
 *
 */
export const clearForgotPasswordPhoneState = (): any => {
    return (dispatch: AppDispatch): void => {
        dispatch({ type: RESET_PASSWORD_PHONE_RESET });
    };
};
