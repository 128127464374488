export const ALL_TENDER_BOX_REQUEST = 'ALL_TENDER_BOX_REQUEST';
export const ALL_TENDER_BOX_SUCCESS = 'ALL_TENDER_BOX_SUCCESS';
export const ALL_TENDER_BOX_FAIL = 'ALL_TENDER_BOX_FAIL';
export const ALL_TENDER_BOX_RESET = 'ALL_TENDER_BOX_RESET';

export const ALL_PROJECT_SECTOR_REQUEST = 'ALL_PROJECT_SECTOR_REQUEST';
export const ALL_PROJECT_SECTOR_SUCCESS = 'ALL_PROJECT_SECTOR_SUCCESS';
export const ALL_PROJECT_SECTOR_FAIL = 'ALL_PROJECT_SECTOR_FAIL';
export const ALL_PROJECT_SECTOR_RESET = 'ALL_PROJECT_SECTOR_RESET';

export const ALL_PROJECT_LOCATION_REQUEST = 'ALL_PROJECT_LOCATION_REQUEST';
export const ALL_PROJECT_LOCATION_SUCCESS = 'ALL_PROJECT_LOCATION_SUCCESS';
export const ALL_PROJECT_LOCATION_FAIL = 'ALL_PROJECT_LOCATION_FAIL';
export const ALL_PROJECT_LOCATION_RESET = 'ALL_PROJECT_LOCATION_RESET';

export const ALL_TENDER_INTENTION_REQUEST = 'ALL_TENDER_INTENTION_REQUEST';
export const ALL_TENDER_INTENTION_SUCCESS = 'ALL_TENDER_INTENTION_SUCCESS';
export const ALL_TENDER_INTENTION_FAIL = 'ALL_TENDER_INTENTION_FAIL';
export const ALL_TENDER_INTENTION_RESET = 'ALL_TENDER_INTENTION_RESET';

export const EDIT_PROJECT_TENDER_REQUEST = ' EDIT_PROJECT_TENDER_REQUEST';
export const EDIT_PROJECT_TENDER_SUCCESS = 'EDIT_PROJECT_TENDER_SUCCESS';
export const EDIT_PROJECT_TENDER_FAIL = 'EDIT_PROJECT_TENDER_FAIL';
export const EDIT_PROJECT_TENDER_RESET = 'EDIT_PROJECT_TENDER_RESET';

export const OPEN_PROJECT_TO_TENDER_REQUEST = 'OPEN_PROJECT_TO_TENDER_REQUEST';
export const OPEN_PROJECT_TO_TENDER_SUCCESS = 'OPEN_PROJECT_TO_TENDER_SUCCESS';
export const OPEN_PROJECT_TO_TENDER_FAIL = 'OPEN_PROJECT_TO_TENDER_FAIL';
export const OPEN_PROJECT_TO_TENDER_RESET = 'OPEN_PROJECT_TO_TENDER_RESET';

export const CLOSE_PROJECT_TO_TENDER_REQUEST = 'CLOSE_PROJECT_TO_TENDER_REQUEST';
export const CLOSE_PROJECT_TO_TENDER_SUCCESS = 'CLOSE_PROJECT_TO_TENDER_SUCCESS';
export const CLOSE_PROJECT_TO_TENDER_FAIL = 'CLOSE_PROJECT_TO_TENDER_FAIL';
export const CLOSE_PROJECT_TO_TENDER_RESET = 'CLOSE_PROJECT_TO_TENDER_RESET';

export const ALL_PROJECT_RANGE_VALUE_REQUEST = 'ALL_PROJECT_RANGE_VALUE_REQUEST';
export const ALL_PROJECT_RANGE_VALUE_SUCCESS = 'ALL_PROJECT_RANGE_VALUE_SUCCESS';
export const ALL_PROJECT_RANGE_VALUE_FAIL = 'ALL_PROJECT_RANGE_VALUE_FAIL';
export const ALL_PROJECT_RANGE_VALUE_RESET = 'ALL_PROJECT_RANGE_VALUE_RESET';

export const SEND_TENDER_REQUEST = ' SEND_TENDER_REQUEST';
export const SEND_TENDER_SUCCESS = 'SEND_TENDER_SUCCESS';
export const SEND_TENDER_FAIL = 'SEND_TENDER_FAIL';
export const SEND_TENDER_RESET = 'SEND_TENDER_RESET';

export const APPROVE_TENDER_REQUEST = 'APPROVE_TENDER_REQUEST';
export const APPROVE_TENDER_SUCCESS = 'APPROVE_TENDER_SUCCESS';
export const APPROVE_TENDER_FAIL = 'APPROVE_TENDER_FAIL';
export const APPROVE_TENDER_RESET = 'APPROVE_TENDER_RESET';

export const REJECT_TENDER_REQUEST = 'REJECT_TENDER_REQUEST';
export const REJECT_TENDER_SUCCESS = 'REJECT_TENDER_SUCCESS';
export const REJECT_TENDER_FAIL = 'REJECT_TENDER_FAIL';
export const REJECT_TENDER_RESET = 'REJECT_TENDER_RESET';

export const CLEAR_STATE = 'CLEAR_STATE';

const initialState = {
    loading: false,
    response: null,
    error: null,
    status: null,
};

export const allTenderBoxReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_TENDER_BOX_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_TENDER_BOX_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_TENDER_BOX_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ALL_TENDER_BOX_RESET:
            return initialState;

        default:
            return state;
    }
};

export const allProjectSectorReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_PROJECT_SECTOR_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_PROJECT_SECTOR_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_PROJECT_SECTOR_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ALL_PROJECT_SECTOR_RESET:
            return initialState;

        default:
            return state;
    }
};

export const allProjectLocationReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_PROJECT_LOCATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_PROJECT_LOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_PROJECT_LOCATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ALL_PROJECT_LOCATION_RESET:
            return initialState;

        default:
            return state;
    }
};

export const allTenderIntentionReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_TENDER_INTENTION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_TENDER_INTENTION_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_TENDER_INTENTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ALL_TENDER_INTENTION_RESET:
            return initialState;

        default:
            return state;
    }
};

export const editProjectTenderReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case EDIT_PROJECT_TENDER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case EDIT_PROJECT_TENDER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case EDIT_PROJECT_TENDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case EDIT_PROJECT_TENDER_RESET:
            return initialState;

        default:
            return state;
    }
};

export const openProjectToTenderReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case OPEN_PROJECT_TO_TENDER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case OPEN_PROJECT_TO_TENDER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case OPEN_PROJECT_TO_TENDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case OPEN_PROJECT_TO_TENDER_RESET:
            return initialState;

        default:
            return state;
    }
};

export const closeProjectToTenderReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case CLOSE_PROJECT_TO_TENDER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CLOSE_PROJECT_TO_TENDER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case CLOSE_PROJECT_TO_TENDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLOSE_PROJECT_TO_TENDER_RESET:
            return initialState;

        default:
            return state;
    }
};

export const allProjectRangeValueReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_PROJECT_RANGE_VALUE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_PROJECT_RANGE_VALUE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_PROJECT_RANGE_VALUE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ALL_PROJECT_RANGE_VALUE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const sendTenderReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case SEND_TENDER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case SEND_TENDER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case SEND_TENDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case SEND_TENDER_RESET:
            return initialState;

        default:
            return state;
    }
};

export const approveTenderReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case APPROVE_TENDER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case APPROVE_TENDER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case APPROVE_TENDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case APPROVE_TENDER_RESET:
            return initialState;

        default:
            return state;
    }
};

export const rejectTenderReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case REJECT_TENDER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case REJECT_TENDER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case REJECT_TENDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case REJECT_TENDER_RESET:
            return initialState;

        default:
            return state;
    }
};
