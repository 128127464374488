import { Fragment } from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { FaStarOfLife } from 'react-icons/fa';

import styled from 'styled-components';

interface InputBasicProps {
    id?: string;
    outerClassName?: string;
    innerClassName?: string;
    rules: {
        function: (name: any, rules: any) => any;
        name: string;
        rules: any;
        errors?: any;
        control: any;
    };
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    options: any[];
    withoutborderbottom?: boolean;
    disabled?: boolean;
    defaultValue?: string;
    label: string;
}

export default function InputSelectControlled(props: InputBasicProps): JSX.Element {
    const {
        id,
        outerClassName,
        innerClassName,
        rules,
        options,
        withoutborderbottom,
        disabled,
        defaultValue,
        label,
    } = props;

    return (
        <Fragment>
            <div className={`input-group ${outerClassName ?? 'mb-3'}`}>
                <Label htmlFor={id} errors={rules?.errors[rules?.name] !== undefined}>
                    {label}
                    {rules?.rules?.required !== undefined && (
                        <FaStarOfLife
                            color="#FB6056"
                            size={7}
                            style={{
                                position: 'relative',
                                top: -6,
                                left: 3,
                            }}
                        />
                    )}
                </Label>
                <div className={`form-floating ${innerClassName ?? ''}`}>
                    <Controller
                        name={rules?.name}
                        control={rules?.control}
                        render={({ field }) => (
                            <SelectWrapper
                                id={id}
                                defaultValue={defaultValue}
                                withoutborderbottom={withoutborderbottom?.toString()}
                                errors={rules?.errors[rules?.name] !== undefined}
                                disabled={disabled}
                                {...field}
                            >
                                {options.map((option: any, index: number) => (
                                    <option key={index} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </SelectWrapper>
                        )}
                    />

                    {rules?.errors[rules?.name] !== undefined && (
                        <span
                            className="invalid-feedback d-block text-start"
                            style={{
                                fontSize: '0.75rem',
                            }}
                        >
                            {rules?.errors[rules?.name].message}
                        </span>
                    )}
                </div>
            </div>
        </Fragment>
    );
}

const SelectWrapper = styled(Form.Select)`
    border: none;
    ${props =>
        props.withoutborderbottom !== 'true' && 'border-bottom: 1px solid #cfcfcf;'}
    background-color: transparent;
    border-radius: 0;
    padding: 25px 0 30px 0 !important;
    height: 45px !important;
    background-image: none;
    appearance: auto;

    [hidden] {
        color: #cfcfcf !important;
    }

    &:disabled {
        background-color: transparent;
        color: #cfcfcf;
    }

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #ffff inset;
    }

    // focus
    &:focus {
        box-shadow: none;
    }
`;

export const Label = styled.label<{ errors?: boolean }>`
    color: #838383;
    top: 3px !important;
    font-size: 12px;
    opacity: 0.65;
    position: absolute;

    .form-control:focus + & {
        top: -10px !important;
        padding-left: 70px !important;
        font-size: 12px !important;
    }

    .form-control:not(:placeholder-shown) + & {
        top: -10px !important;
        font-size: 12px !important;
        padding-left: 70px !important;
    }
`;
