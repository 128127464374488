import { MutableRefObject, useCallback, useRef } from 'react';
import Map, {
    AttributionControl,
    FullscreenControl,
    GeolocateControl,
    MapRef,
    Marker,
    MarkerDragEvent,
    NavigationControl,
    ScaleControl,
} from 'react-map-gl';

// import ControlPanel from './ControlPanel';
// import CustomOverlay from './CustomOverlay';
import { useSearchBoxCore } from '@mapbox/search-js-react';
import { v4 } from 'uuid';
import InputAsyncSelect from '@/components/atoms/Inputs/InputAsyncSelect';

const MiniMaps = ({
    defaultMap,
    setDefaultMap,
    withRef,
}: {
    defaultMap: { lat: number; lng: number; zoom: number; address: string };
    setDefaultMap: (value: {
        lat: number;
        lng: number;
        zoom: number;
        address: string;
    }) => void;
    withRef?: MutableRefObject<MapRef | undefined>;
}): JSX.Element => {
    const key = import.meta.env.VITE_MAP_BOX;

    const mapRef = useRef<MapRef>();
    // const [value, setValue] = useState('');

    const searchBoxCore = useSearchBoxCore({
        accessToken: key,
        types: 'country, place, region, postcode, district, locality, street, address',
    });

    const handleFindSuggestion = async (value: string): Promise<any> => {
        const response = await searchBoxCore.suggest(value, {
            sessionToken: v4(),
        });

        return response;
    };

    const handleFindAddress = async (latLang: {
        lng: number;
        lat: number;
    }): Promise<any> => {
        const response = await searchBoxCore.reverse(latLang);

        return response;
    };

    const handleRetrieveSuggestion = async (value: any): Promise<void> => {
        const response = await searchBoxCore.retrieve(value, {
            sessionToken: v4(),
        });

        const results = response?.features[0]?.properties;

        onSelectCity({
            latitude: results?.coordinates?.latitude,
            longitude: results?.coordinates?.longitude,
        });

        setDefaultMap({
            ...defaultMap,
            lat: results?.coordinates?.latitude,
            lng: results?.coordinates?.longitude,
            address: `${results?.name ?? results?.coordinates?.latitude} - ${
                results?.place_formatted ?? results?.coordinates?.longitude
            }`,
        });
    };

    // const handleOnMoveMap = useCallback((evt: { viewState: MarkerDragEvent }) => {
    // }, []);

    const handleOnDrag = useCallback(async (evt: MarkerDragEvent) => {
        const result = await handleFindAddress({
            lat: evt.lngLat.lat,
            lng: evt.lngLat.lng,
        });

        setDefaultMap({
            ...defaultMap,
            lat: evt.lngLat.lat,
            lng: evt.lngLat.lng,
            address:
                `${
                    (result?.features[0]?.properties?.name as string) ?? evt.lngLat.lat
                } - ${
                    (result?.features[0]?.properties?.place_formatted as string) ??
                    evt.lngLat.lng
                }` ?? `lat:${evt.lngLat.lat}, lang:${evt.lngLat.lng}`,
        });
    }, []);

    const onSelectCity = useCallback(
        ({ longitude, latitude }: { longitude: number; latitude: number }) => {
            if (withRef !== undefined) {
                withRef.current?.flyTo({ center: [longitude, latitude], duration: 2000 });
            } else {
                mapRef.current?.flyTo({ center: [longitude, latitude], duration: 2000 });
            }
        },
        [],
    );

    const handlePromiseFind = (
        inputValue: string,
        callback: (
            options: Array<{
                value: string;
                label: string;
            }>,
        ) => void,
    ): void => {
        setTimeout(async () => {
            const results = await handleFindSuggestion(
                inputValue !== '' ? inputValue : 'Sydney',
            );

            const options = results?.suggestions.map(
                (item: {
                    name: string;
                    place_formatted: string;
                    mapbox_id: string;
                    coordinates: any;
                }) => ({
                    ...item,
                    label: `${item?.name ?? (item?.coordinates?.latitude as string)} - ${
                        item?.place_formatted ?? (item?.coordinates?.longitud as string)
                    }`,
                    value: item?.mapbox_id,
                }),
            );

            callback(options);
        }, 1000);
    };

    return (
        <div className="row mt-3">
            <div className={withRef !== undefined ? 'col-md-12 mb-3' : 'col-md-4'}>
                <InputAsyncSelect
                    cacheOptions
                    defaultOptions
                    value={{
                        label: defaultMap?.address,
                        value: defaultMap?.address,
                    }}
                    loadOptions={handlePromiseFind}
                    onChange={async (value: any) => {
                        await handleRetrieveSuggestion(value);
                    }}
                />
            </div>
            <div className={withRef !== undefined ? 'col-md-12' : 'col-md-8'}>
                {defaultMap?.lat !== 0 && defaultMap?.lng !== 0 && (
                    <Map
                        mapboxAccessToken={key}
                        ref={withRef ?? (mapRef as any)}
                        initialViewState={{
                            longitude: defaultMap.lng,
                            latitude: defaultMap.lat,
                            zoom: defaultMap.zoom,
                        }}
                        // onMove={e => handleOnMoveMap(e)}
                        style={{
                            width: '100%',
                            height: '75vh',
                            display: 'inline-list-item',
                            borderRadius: 20,
                        }}
                        mapStyle={'mapbox://styles/mapbox/light-v11'}
                        attributionControl={false}
                    >
                        <Marker
                            longitude={defaultMap.lng}
                            latitude={defaultMap.lat}
                            draggable
                            // onClick={e => handleManyMarker(e, item)}
                            anchor="bottom"
                            // onDrag={handleOnDrag}
                            onDragEnd={handleOnDrag}
                        >
                            <svg
                                width="40"
                                height="40"
                                viewBox="0 0 24 24"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <title>Drag to change your location</title>
                                <defs></defs>
                                <g
                                    id="Page-1"
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                >
                                    <g id="ic-marker" fillRule="nonzero" fill="#4A4A4A">
                                        <path
                                            d="M12,6.17520814 C12,6.17520814 7,12.2541451 7,14.995119 C7,17.736093 9.23857625,19.9580914 12,19.9580914 C14.7614237,19.9580914 17,17.736093 17,14.995119 C17,12.2541451 12,6.17520814 12,6.17520814 Z M10.4683998,4.86210025 L12,3 L13.5316002,4.86210025 C13.5771565,4.91748701 13.6569919,5.01600867 13.7662775,5.15315604 C13.9452831,5.37779801 14.1439408,5.63188928 14.3574503,5.91097897 C14.9673143,6.70816461 15.5773268,7.55013189 16.1498093,8.40306481 C16.5629039,9.01852817 16.9417444,9.61832921 17.2792085,10.1970667 C18.3766677,12.0791657 19,13.6301602 19,14.995119 C19,18.8768817 15.8535808,22 12,22 C8.14641923,22 5,18.8768817 5,14.995119 C5,13.6301602 5.62333227,12.0791657 6.7207915,10.1970667 C7.05825558,9.61832921 7.43709608,9.01852817 7.85019068,8.40306481 C8.42267315,7.55013189 9.03268571,6.70816461 9.64254968,5.91097897 C9.85605923,5.63188928 10.0547169,5.37779801 10.2337225,5.15315604 C10.3430081,5.01600867 10.4228435,4.91748701 10.4683998,4.86210025 Z M12,18 C10.3431458,18 9,16.6568542 9,15 C9,13.3431458 10.3431458,12 12,12 C13.6568542,12 15,13.3431458 15,15 C15,16.6568542 13.6568542,18 12,18 Z M12,16 C12.5522847,16 13,15.5522847 13,15 C13,14.4477153 12.5522847,14 12,14 C11.4477153,14 11,14.4477153 11,15 C11,15.5522847 11.4477153,16 12,16 Z"
                                            id="Combined-Shape"
                                            transform="translate(12.000000, 12.500000) scale(-1, -1) translate(-12.000000, -12.500000) "
                                        ></path>
                                    </g>
                                </g>
                            </svg>
                        </Marker>
                        <ScaleControl />
                        <FullscreenControl position="top-left" />
                        <NavigationControl position="top-left" />
                        <GeolocateControl position="top-left" />
                        <AttributionControl
                            customAttribution=" © Constructapp"
                            position="bottom-right"
                        />
                    </Map>
                )}
            </div>
        </div>
    );
};
export default MiniMaps;
