/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { store } from '@/redux/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import App from './App';
import Error500 from './components/organism/ErrorPage/Error500';
import { CursorifyProvider } from '@cursorify/react';

/**
 * Fallback component for error boundary
 *
 * @returns JSX.Element
 */
function FallbackComponent(): JSX.Element {
    return <Error500 />;
}

const baseUrl = import.meta.env.VITE_BASE_URL;
const sentryDsn = import.meta.env.VITE_SENTRY_DSN;

Sentry.init({
    dsn: sentryDsn,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', baseUrl],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <React.StrictMode>
                <DndProvider backend={HTML5Backend}>
                    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
                        <CursorifyProvider
                            delay={2}
                            opacity={0.7}
                            breakpoint={997}
                            cursor={null}
                            defaultCursorVisible={true}
                        >
                            <App />
                        </CursorifyProvider>
                    </Sentry.ErrorBoundary>
                </DndProvider>
            </React.StrictMode>
        </Provider>
    </BrowserRouter>,
);
