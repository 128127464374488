export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST';
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_FAIL = 'NOTIFICATIONS_FAIL';

export const NOTIFICATIONS_TOAST_REQUEST = 'NOTIFICATIONS_TOAST_REQUEST';
export const NOTIFICATIONS_TOAST_SUCCESS = 'NOTIFICATIONS_TOAST_SUCCESS';
export const NOTIFICATIONS_TOAST_FAIL = 'NOTIFICATIONS_TOAST_FAIL';

export const UNREAD_NOTIFICATIONS_REQUEST = 'UNREAD_NOTIFICATIONS_REQUEST';
export const UNREAD_NOTIFICATIONS_SUCCESS = 'UNREAD_NOTIFICATIONS_SUCCESS';
export const UNREAD_NOTIFICATIONS_FAIL = 'UNREAD_NOTIFICATIONS_FAIL';

export const MARK_READ_NOTIFICATIONS_REQUEST = 'MARK_READ_NOTIFICATIONS_REQUEST';
export const MARK_READ_NOTIFICATIONS_SUCCESS = 'MARK_READ_NOTIFICATIONS_SUCCESS';
export const MARK_READ_NOTIFICATIONS_FAIL = 'MARK_READ_NOTIFICATIONS_FAIL';
export const MARK_READ_NOTIFICATIONS_RESET = 'MARK_READ_NOTIFICATIONS_RESET';

export const MARK_UNREAD_NOTIFICATIONS_REQUEST = 'MARK_UNREAD_NOTIFICATIONS_REQUEST';
export const MARK_UNREAD_NOTIFICATIONS_SUCCESS = 'MARK_UNREAD_NOTIFICATIONS_SUCCESS';
export const MARK_UNREAD_NOTIFICATIONS_FAIL = 'MARK_UNREAD_NOTIFICATIONS_FAIL';
export const MARK_UNREAD_NOTIFICATIONS_RESET = 'MARK_UNREAD_NOTIFICATIONS_RESET';

export const DELETE_NOTIFICATIONS_REQUEST = 'DELETE_NOTIFICATIONS_REQUEST';
export const DELETE_NOTIFICATIONS_SUCCESS = 'DELETE_NOTIFICATIONS_SUCCESS';
export const DELETE_NOTIFICATIONS_FAIL = 'DELETE_NOTIFICATIONS_FAIL';
export const DELETE_NOTIFICATIONS_RESET = 'DELETE_NOTIFICATIONS_RESET';

export const ACCEPT_PROJECT_INVITATION_REQUEST = ' ACCEPT_PROJECT_INVITATION_REQUEST';
export const ACCEPT_PROJECT_INVITATION_SUCCESS = 'ACCEPT_PROJECT_INVITATION_SUCCESS';
export const ACCEPT_PROJECT_INVITATION_FAIL = 'ACCEPT_PROJECT_INVITATION_FAIL';

export const DECLINE_PROJECT_INVITATION_REQUEST = 'DECLINE_PROJECT_INVITATION_REQUEST';
export const DECLINE_PROJECT_INVITATION_SUCCESS = 'DECLINE_PROJECT_INVITATION_SUCCESS';
export const DECLINE_PROJECT_INVITATION_FAIL = 'DECLINE_PROJECT_INVITATION_FAIL';
export const CLEAR_STATE_ACCEPT_DECLINE_PROJECT_INVITATION =
    'CLEAR_STATE_ACCEPT_DECLINE_PROJECT_INVITATION';

export const CLEAR_STATE = 'CLEAR_STATE';

const initialState = {
    loading: false,
    response: null,
    error: null,
    status: null,
};

export const allNotificationsReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case NOTIFICATIONS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case NOTIFICATIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const notificationsToastReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case NOTIFICATIONS_TOAST_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case NOTIFICATIONS_TOAST_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case NOTIFICATIONS_TOAST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const unreadNotificationsReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case UNREAD_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UNREAD_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                totalUnread: action.payload.meta.total,
                status: 200,
            };

        case UNREAD_NOTIFICATIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const actionsNotificationsReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case MARK_READ_NOTIFICATIONS_REQUEST:
        case MARK_UNREAD_NOTIFICATIONS_REQUEST:
        case DELETE_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case MARK_READ_NOTIFICATIONS_SUCCESS:
        case MARK_UNREAD_NOTIFICATIONS_SUCCESS:
        case DELETE_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case MARK_READ_NOTIFICATIONS_FAIL:
        case MARK_UNREAD_NOTIFICATIONS_FAIL:
        case DELETE_NOTIFICATIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case MARK_READ_NOTIFICATIONS_RESET:
        case MARK_UNREAD_NOTIFICATIONS_RESET:
        case DELETE_NOTIFICATIONS_RESET:
            return initialState;

        default:
            return state;
    }
};

export const acceptDeclineProjectInvitationReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case ACCEPT_PROJECT_INVITATION_REQUEST:
        case DECLINE_PROJECT_INVITATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ACCEPT_PROJECT_INVITATION_SUCCESS:
        case DECLINE_PROJECT_INVITATION_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ACCEPT_PROJECT_INVITATION_FAIL:
        case DECLINE_PROJECT_INVITATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE_ACCEPT_DECLINE_PROJECT_INVITATION:
            return initialState;

        default:
            return state;
    }
};
