import { formatDateAgo } from '@/utility/Utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function CustomToast({
    title,
    body,
    date,
    redirect,
}: {
    title: string;
    body: string;
    date: number;
    redirect: string;
}): JSX.Element {
    const domainsToRemove = [
        'https://staging.constructapp.team',
        'https://constructapp.team',
        'https://app-staging.constructapp.team',
    ];

    // remove the domain from the url
    let trimmedUrl = redirect;
    for (const domainToRemove of domainsToRemove) {
        const regex = new RegExp(
            domainToRemove.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'),
            'g',
        );
        trimmedUrl = trimmedUrl.replace(regex, '');
    }

    return (
        <div role="button" onClick={() => toast.dismiss()}>
            <h6 className="fw-semibold text-dark">{title}</h6>
            <p
                className="mb-2"
                style={{
                    fontSize: 14,
                }}
                dangerouslySetInnerHTML={{ __html: body }}
            ></p>
            <p
                className="text-end mb-0"
                style={{
                    fontSize: 12,
                }}
            >
                {formatDateAgo(date)}
            </p>
        </div>
    );
}
