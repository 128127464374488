import {
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    GOOGLE_REDIRECT_REQUEST,
    GOOGLE_REDIRECT_SUCCESS,
    GOOGLE_REDIRECT_FAIL,
    REGISTER_COMPANY_COMPLETED_REQUEST,
    REGISTER_COMPANY_COMPLETED_SUCCESS,
    REGISTER_COMPANY_COMPLETED_FAIL,
    REGISTER_INHOUSE_COMPLETED_REQUEST,
    REGISTER_INHOUSE_COMPLETED_SUCCESS,
    REGISTER_INHOUSE_COMPLETED_FAIL,
    COMPANY_USER_CREDENTIALS_REQUEST,
    COMPANY_USER_CREDENTIALS_SUCCESS,
    COMPANY_USER_CREDENTIALS_FAIL,
    INHOUSE_USER_CREDENTIALS_REQUEST,
    INHOUSE_USER_CREDENTIALS_SUCCESS,
    INHOUSE_USER_CREDENTIALS_FAIL,
    ALL_NICHE_REQUEST,
    ALL_NICHE_SUCCESS,
    ALL_NICHE_SUB_SUCCESS,
    ALL_NICHE_FAIL,
    ALL_SUBCONTRACTOR_REQUEST,
    ALL_SUBCONTRACTOR_SUCCESS,
    ALL_SUBCONTRACTOR_FAIL,
    CLEAR_STATES,
} from '../reducers';

import { axiosClient } from '@/services/GatewayService';
import { AppDispatch } from '@/redux/store';
import { toast } from 'react-toastify';

/**
 * Function login user
 *
 * @param {FormData} formData
 * @return {Promise<void>}
 */
export const registerCompany = (formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: REGISTER_REQUEST });
        try {
            const res = await axiosClient.post('auth/register/company-user', formData);

            dispatch({
                type: REGISTER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: REGISTER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function login user
 *
 * @return {Promise<void>}
 */
export const googleRedirect = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: GOOGLE_REDIRECT_REQUEST });
        try {
            const res = await axiosClient.get('auth/google');

            dispatch({
                type: GOOGLE_REDIRECT_SUCCESS,
                payload: res?.data,
            });
        } catch (error: any) {
            dispatch({
                type: GOOGLE_REDIRECT_FAIL,
                payload: error?.response.data.message,
            });
        }
    };
};

/**
 * Function register on complete
 *
 * @param {FormData} formData
 * @return {Promise<void>}
 */
export const registerCompanyCompleted = (
    formData: FormData,
    token: string,
    should_register?: boolean,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: REGISTER_COMPANY_COMPLETED_REQUEST });
        try {
            const res = await axiosClient.post(
                'auth/register/company-user/complete',
                formData,
                {
                    params: {
                        verification_token: token,
                        should_register,
                    },
                },
            );

            dispatch({
                type: REGISTER_COMPANY_COMPLETED_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: REGISTER_COMPANY_COMPLETED_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function register inhouse on complete
 *
 * @return {Promise<void>}
 */
export const registerInhouseCompleted = (formData: FormData, token: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: REGISTER_INHOUSE_COMPLETED_REQUEST });
        try {
            const res = await axiosClient.post(
                `auth/register/inhouse-user/complete?invitation_token=${token}`,
                formData,
            );

            dispatch({
                type: REGISTER_INHOUSE_COMPLETED_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: REGISTER_INHOUSE_COMPLETED_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function get company user credentials
 *
 * @return {Promise<void>}
 */
export const getCompanyUserCredentialsToken = (token: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: COMPANY_USER_CREDENTIALS_REQUEST });
        try {
            const res = await axiosClient.get(
                `auth/register/company-user/data?verification_token=${token}`,
            );

            dispatch({
                type: COMPANY_USER_CREDENTIALS_SUCCESS,
                payload: res?.data,
            });
        } catch (error: any) {
            dispatch({
                type: COMPANY_USER_CREDENTIALS_FAIL,
                payload: error?.response.data.message,
            });
        }
    };
};

/**
 * Function get inhouse user credentials
 *
 * @return {Promise<void>}
 */
export const getInhouseUserCredentialsToken = (token: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: INHOUSE_USER_CREDENTIALS_REQUEST });
        try {
            const res = await axiosClient.get(
                `auth/register/inhouse-user/data?invitation_token=${token}`,
            );

            dispatch({
                type: INHOUSE_USER_CREDENTIALS_SUCCESS,
                payload: res?.data,
            });
        } catch (error: any) {
            dispatch({
                type: INHOUSE_USER_CREDENTIALS_FAIL,
                payload: error?.response.data.message,
            });
        }
    };
};

/**
 * Function to resend email
 *
 * @param {FormData} formData
 * @return {Promise<void>}
 */
export const resendRegisterEmail = (formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: REGISTER_COMPANY_COMPLETED_REQUEST });
        try {
            const res = await axiosClient.post(
                'auth/register/company-user/resend',
                formData,
            );

            dispatch({
                type: REGISTER_COMPANY_COMPLETED_SUCCESS,
                payload: res.data,
            });

            toast.success(res?.data?.message, {
                position: toast.POSITION.TOP_CENTER,
            });
        } catch (error: any) {
            dispatch({
                type: REGISTER_COMPANY_COMPLETED_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function for get all company niche
 *
 * @return {Promise<void>}
 */
export const getNiche = (params?: { with_sub_niche?: number }): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_NICHE_REQUEST });
        try {
            const res = await axiosClient.get('company/niches', {
                params: {
                    // page: 1,
                    // per_page: 100,
                    sort_by: 'id',
                    sort_asc: 1,
                    with_sub_niche: params?.with_sub_niche,
                },
            });

            if (params?.with_sub_niche !== 1) {
                dispatch({
                    type: ALL_NICHE_SUCCESS,
                    payload: res?.data,
                });
            } else {
                dispatch({
                    type: ALL_NICHE_SUB_SUCCESS,
                    payload: res?.data,
                });
            }
        } catch (error: any) {
            dispatch({
                type: ALL_NICHE_FAIL,
                payload: error?.response.data.message,
            });
        }
    };
};

/**
 * Function for get all sub contractor
 *
 * @return {Promise<void>}
 */
export const getSubContractor = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_SUBCONTRACTOR_REQUEST });
        try {
            const res = await axiosClient.get('company/sub-contract-trades', {
                params: {
                    page: 1,
                    per_page: 100,
                    sort_by: 'id',
                    sort_asc: 1,
                },
            });

            dispatch({
                type: ALL_SUBCONTRACTOR_SUCCESS,
                payload: res?.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_SUBCONTRACTOR_FAIL,
                payload: error?.response.data.message,
            });
        }
    };
};

/**
 * Function to clear state of register
 *
 * @returns {void}
 */
export const clearRegisterState = (): any => {
    return (dispatch: AppDispatch): void => {
        dispatch({ type: CLEAR_STATES });
    };
};
