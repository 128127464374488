import documentsImage1 from '@/assets/images/document-drawing/help-documents-drawings-image-1.webp';
import drawingsImage1 from '@/assets/images/document-drawing/help-documents-drawings-image-2.webp';

import { Fragment } from 'react';

export default function WhatIsDifferentModule(): JSX.Element {
    const contents = [
        {
            id: 1,
            text: "Document's is a place where you can store your documents. Type of documents that can be stored are pdf, doc, docx, xls, xlsx, ppt, pptx, txt, and csv. ",
            alt: 'documentsFolderPage',
            image: documentsImage1,
        },
        {
            id: 2,
            text: "Drawing's is a place where you can store your images. Type of images that can be stored are jpg, jpeg, png, and gif",
            alt: 'documentsFolderPage',
            image: drawingsImage1,
        },
    ];

    return (
        <Fragment>
            {contents.map((content, index) => (
                <div key={index} className="text-grey-300 mb-10">
                    {content.image.length > 0 && (
                        <img
                            src={content.image}
                            alt={content.alt}
                            className="img-fluid"
                        />
                    )}
                    <p className="mt-4 pl-2">{content.text}</p>
                </div>
            ))}
        </Fragment>
    );
}
