import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import Header from '@/components/molecules/Header';
import { Title } from '@/components/molecules/Typography';

import BadgeCheck from '@/assets/svg/icons/badge-check.svg';

export default function SuccessfullyFinished(): JSX.Element {
    const navigate = useNavigate();

    const handleConfirm = (): void => {
        navigate('/');
    };

    return (
        <>
            <div className="container-fluid container-xs container-lg">
                <header>
                    <Header text="Return to home screen" link="/" />
                </header>
                <main>
                    <div className="row my-5">
                        <div className="col-md-12 text-center my-5">
                            <Title text="Successfully Setup Account" />
                            <img
                                src={BadgeCheck}
                                alt="badge-check"
                                className="img-fluid mt-5"
                            />
                        </div>
                        <div className="col-md-4 mx-auto text-center mb-3">
                            <span className="text-muted">
                                Your account has been successfully setup, go to general
                                dashboard to continue access the application
                            </span>
                        </div>
                    </div>
                    <div className="row my-5">
                        <div className="col-md-4 mx-auto text-center">
                            <ButtonBasic
                                type="button"
                                text="Go to Login Page"
                                className="my-3 w-75 btn-primary"
                                onClick={handleConfirm}
                            />
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}
