import { axiosClient } from '@/services/GatewayService';
import { AppDispatch } from '@/redux/store';

import {
    DETAIL_DRAWING_REQUEST,
    DETAIL_DRAWING_SUCCESS,
    DETAIL_DRAWING_FAIL,
    DETAIL_DRAWING_RESET,
    HISTORY_DRAWING_REQUEST,
    HISTORY_DRAWING_SUCCESS,
    HISTORY_DRAWING_FAIL,
    HISTORY_DRAWING_RESET,
    DELETE_DRAWING_REQUEST,
    DELETE_DRAWING_SUCCESS,
    DELETE_DRAWING_FAIL,
    DELETE_DRAWING_RESET,
    RESTORE_DRAWING_REQUEST,
    RESTORE_DRAWING_SUCCESS,
    RESTORE_DRAWING_FAIL,
    RESTORE_DRAWING_RESET,
    PIN_UNPIN_DRAWING_SPACE_REQUEST,
    PIN_UNPIN_DRAWING_SPACE_SUCCESS,
    PIN_UNPIN_DRAWING_SPACE_FAIL,
    PIN_UNPIN_DRAWING_SPACE_RESET,
    UPLOAD_DRAWING_FILE_REQUEST,
    UPLOAD_DRAWING_FILE_SUCCESS,
    UPLOAD_DRAWING_FILE_FAIL,
    UPLOAD_DRAWING_FILE_RESET,
    UPDATE_DRAWING_REQUEST,
    UPDATE_DRAWING_SUCCESS,
    UPDATE_DRAWING_FAIL,
    UPDATE_DRAWING_RESET,
    INVITE_USER_DRAWING_REQUEST,
    INVITE_USER_DRAWING_SUCCESS,
    INVITE_USER_DRAWING_FAIL,
    INVITE_USER_DRAWING_RESET,
    ALL_DRAWING_PUBLIC_SPACE_REQUEST,
    ALL_DRAWING_PUBLIC_SPACE_SUCCESS,
    ALL_DRAWING_PUBLIC_SPACE_FAIL,
    ALL_DRAWING_PRIVATE_SPACE_REQUEST,
    ALL_DRAWING_PRIVATE_SPACE_SUCCESS,
    ALL_DRAWING_PRIVATE_SPACE_FAIL,
    ALL_DRAWING_RESTORE_SPACE_REQUEST,
    ALL_DRAWING_RESTORE_SPACE_SUCCESS,
    ALL_DRAWING_RESTORE_SPACE_FAIL,
    ALL_DRAWING_PUBLIC_FOLDER_REQUEST,
    ALL_DRAWING_PUBLIC_FOLDER_SUCCESS,
    ALL_DRAWING_PUBLIC_FOLDER_FAIL,
    ALL_DRAWING_PUBLIC_FOLDER_RESET,
    ALL_DRAWING_PRIVATE_FOLDER_REQUEST,
    ALL_DRAWING_PRIVATE_FOLDER_SUCCESS,
    ALL_DRAWING_PRIVATE_FOLDER_FAIL,
    ALL_DRAWING_PRIVATE_FOLDER_RESET,
    CREATE_DRAWING_FOLDER_REQUEST,
    CREATE_DRAWING_FOLDER_SUCCESS,
    CREATE_DRAWING_FOLDER_FAIL,
    CREATE_DRAWING_FOLDER_RESET,
    ALL_DRAWING_COMPARE_FILE_REQUEST,
    ALL_DRAWING_COMPARE_FILE_SUCCESS,
    ALL_DRAWING_COMPARE_FILE_FAIL,
    ALL_DRAWING_COMPARE_FILE_RESET,
    SEND_DRAWING_REQUEST,
    SEND_DRAWING_SUCCESS,
    SEND_DRAWING_FAIL,
    SEND_DRAWING_RESET,
    SHARE_DRAWING_REQUEST,
    SHARE_DRAWING_SUCCESS,
    SHARE_DRAWING_FAIL,
    SHARE_DRAWING_RESET,
    PASTE_DRAWING_REQUEST,
    PASTE_DRAWING_SUCCESS,
    PASTE_DRAWING_FAIL,
    PASTE_DRAWING_RESET,
    SHARED_USER_PRIVATE_DRAWING_REQUEST,
    SHARED_USER_PRIVATE_DRAWING_SUCCESS,
    SHARED_USER_PRIVATE_DRAWING_FAIL,
    SHARED_USER_PRIVATE_DRAWING_RESET,
    UPDATE_USER_PRIVATE_DRAWING_REQUEST,
    UPDATE_USER_PRIVATE_DRAWING_SUCCESS,
    UPDATE_USER_PRIVATE_DRAWING_FAIL,
    UPDATE_USER_PRIVATE_DRAWING_RESET,
    DELETE_USER_PRIVATE_DRAWING_REQUEST,
    DELETE_USER_PRIVATE_DRAWING_SUCCESS,
    DELETE_USER_PRIVATE_DRAWING_FAIL,
    DELETE_USER_PRIVATE_DRAWING_RESET,
    CREATE_SHARE_LINK_DRAWING_REQUEST,
    CREATE_SHARE_LINK_DRAWING_SUCCESS,
    CREATE_SHARE_LINK_DRAWING_FAIL,
    CREATE_SHARE_LINK_DRAWING_RESET,
    GET_SHARE_LINK_DRAWING_REQUEST,
    GET_SHARE_LINK_DRAWING_SUCCESS,
    GET_SHARE_LINK_DRAWING_FAIL,
    GET_SHARE_LINK_DRAWING_RESET,
} from '../reducers';

/**
 * Function to get all drawing folders
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param number params.per_page - number of project per page
 * @param string params.search - search of project
 * @param string params.sort_by - sort by of project
 * @param number params.sort_asc - sort asc of project
 * @return Promise<void>
 */
export const getAllDrawingPublicSpace = (params: {
    project_id: string;
    page: number;
    per_page: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_DRAWING_PUBLIC_SPACE_REQUEST });
        try {
            const res = await axiosClient.get('project/drawings/folders', {
                params: {
                    ...params,
                    public: 1,
                },
            });

            dispatch({
                type: ALL_DRAWING_PUBLIC_SPACE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_DRAWING_PUBLIC_SPACE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all drawing folders
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param number params.per_page - number of project per page
 * @param string params.search - search of project
 * @param string params.sort_by - sort by of project
 * @param number params.sort_asc - sort asc of project
 * @return Promise<void>
 */
export const getAllDrawingPrivateSpace = (params: {
    project_id: string;
    page: number;
    per_page: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_DRAWING_PRIVATE_SPACE_REQUEST });
        try {
            const res = await axiosClient.get('project/drawings/folders', {
                params: {
                    ...params,
                    public: 0,
                },
            });

            dispatch({
                type: ALL_DRAWING_PRIVATE_SPACE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_DRAWING_PRIVATE_SPACE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all drawing folders
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param number params.per_page - number of project per page
 * @param string params.search - search of project
 * @param string params.sort_by - sort by of project
 * @param number params.sort_asc - sort asc of project
 * @return Promise<void>
 */
export const getAllDrawingRestoreSpace = (params: {
    project_id: string;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
    public: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_DRAWING_RESTORE_SPACE_REQUEST });
        try {
            const res = await axiosClient.get('project/drawings/folders', {
                params: {
                    ...params,
                    is_restore: 1,
                },
            });

            dispatch({
                type: ALL_DRAWING_RESTORE_SPACE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_DRAWING_RESTORE_SPACE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get inside space
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param number params.per_page - number of project per page
 * @param string params.search - search of project
 * @param string params.sort_by - sort by of project
 * @param number params.sort_asc - sort asc of project
 * @return Promise<void>
 */
export const getAllDrawingPublicFolder = (
    params: {
        project_id: string;
        id?: number;
        uuid?: string;
        folder_id?: string;
        folder_uuid?: string;
        page: number;
        per_page: number;
        search?: string;
        sort_by?: string;
        sort_asc?: number;
    },
    is_trash: boolean,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_DRAWING_PUBLIC_FOLDER_REQUEST });
        try {
            const res = await axiosClient.get(
                `project/drawings${is_trash ? '/trash' : ''}`,
                {
                    params: {
                        ...params,
                        public: 1,
                    },
                },
            );

            dispatch({
                type: ALL_DRAWING_PUBLIC_FOLDER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_DRAWING_PUBLIC_FOLDER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get inside space
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param number params.per_page - number of project per page
 * @param string params.search - search of project
 * @param string params.sort_by - sort by of project
 * @param number params.sort_asc - sort asc of project
 * @return Promise<void>
 */
export const getAllDrawingPrivateFolder = (
    params: {
        project_id: string;
        id?: number;
        uuid?: string;
        folder_id?: string;
        folder_uuid?: string;
        page: number;
        per_page: number;
        search?: string;
        sort_by?: string;
        sort_asc?: number;
    },
    is_trash: boolean,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_DRAWING_PRIVATE_FOLDER_REQUEST });
        try {
            const res = await axiosClient.get(
                `project/drawings${is_trash ? '/trash' : ''}`,
                {
                    params: {
                        ...params,
                        public: 0,
                    },
                },
            );

            dispatch({
                type: ALL_DRAWING_PRIVATE_FOLDER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_DRAWING_PRIVATE_FOLDER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get inside space
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param number params.per_page - number of project per page
 * @param string params.search - search of project
 * @param string params.sort_by - sort by of project
 * @param number params.sort_asc - sort asc of project
 * @return Promise<void>
 */
export const searchDrawingAll = (
    params: {
        project_id: string;
        public: number;
        page: number;
        per_page: number;
        search: string;
        sort_by?: string;
        sort_asc?: number;
    },
    is_trash: boolean,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({
            type:
                params.public === 0
                    ? ALL_DRAWING_PRIVATE_FOLDER_REQUEST
                    : ALL_DRAWING_PUBLIC_FOLDER_REQUEST,
        });
        try {
            const res = await axiosClient.get(
                `project/drawings/search${is_trash ? '/trash' : ''}`,
                {
                    params,
                },
            );

            dispatch({
                type:
                    params.public === 0
                        ? ALL_DRAWING_PRIVATE_FOLDER_SUCCESS
                        : ALL_DRAWING_PUBLIC_FOLDER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type:
                    params.public === 0
                        ? ALL_DRAWING_PRIVATE_FOLDER_FAIL
                        : ALL_DRAWING_PUBLIC_FOLDER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get inside folder
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param number params.per_page - number of project per page
 * @param string params.search - search of project
 * @param string params.sort_by - sort by of project
 * @param number params.sort_asc - sort asc of project
 * @return Promise<void>
 * TODO:DEPRECATED
 */
export const getDrawingSubFolderInsidePublicFolder = (params: {
    project_id: string;
    // folder_id: string;
    drawing_id: string;
    page: number;
    per_page: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
    public: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({
            type:
                params.public === 0
                    ? ALL_DRAWING_PRIVATE_FOLDER_REQUEST
                    : ALL_DRAWING_PUBLIC_FOLDER_REQUEST,
        });
        try {
            const res = await axiosClient.get('project/drawings', {
                params,
            });

            dispatch({
                type:
                    params.public === 0
                        ? ALL_DRAWING_PRIVATE_FOLDER_SUCCESS
                        : ALL_DRAWING_PUBLIC_FOLDER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type:
                    params.public === 0
                        ? ALL_DRAWING_PRIVATE_FOLDER_FAIL
                        : ALL_DRAWING_PUBLIC_FOLDER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get detail drawing folders
 *
 * @param string params.id - id of drawing folders
 * @param string params.project_id - id of project
 * @return Promise<void>
 */
export const detailDrawing = (params: {
    id?: string;
    uuid?: string;
    project_id?: string;
    trashed?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_DRAWING_REQUEST });
        try {
            const res = await axiosClient.get('project/drawings', {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: DETAIL_DRAWING_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DETAIL_DRAWING_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get history drawing folders
 *
 * @param string params.id - id of drawing folders
 * @param string params.project_id - id of project
 * @return Promise<void>
 */
export const historyDrawing = (params: {
    drawing_id?: string;
    uuid?: string;
    project_id?: string;
    page: number;
    per_page: number;
    trashed?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: HISTORY_DRAWING_REQUEST });
        try {
            const res = await axiosClient.get('project/drawings/histories', {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: HISTORY_DRAWING_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: HISTORY_DRAWING_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to create drawing space
 *
 * @param string params.project_id - id of project
 * @param FormData formData- body data of drawing folders
 * @return Promise<void>
 */
export const createDrawingFolder = (
    params: {
        project_id: string;
        folder_id: string;
    },
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CREATE_DRAWING_FOLDER_REQUEST });
        try {
            const res = await axiosClient.post('project/drawings/subfolders', formData, {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: CREATE_DRAWING_FOLDER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: CREATE_DRAWING_FOLDER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to delete drawing folders
 *
 * @param string params.ids - ids of drawing folders
 * @param string params.project_id - id of project
 * @param FormData formData- body data of drawing folders
 * @return Promise<void>
 */
export const deleteDrawing = (
    params: { project_id: string; permanently: number },
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_DRAWING_REQUEST });

        try {
            const res = await axiosClient.post('project/drawings/delete', formData, {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: DELETE_DRAWING_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DELETE_DRAWING_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

export const restoreDrawing = (
    params: { project_id: string; folder_id?: string },
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESTORE_DRAWING_REQUEST });
        const sessionRestoreFolderId = sessionStorage.getItem('restoreFolderId');

        try {
            const res = await axiosClient.post('project/drawings/restore', formData, {
                params: {
                    ...params,
                    folder_id: sessionRestoreFolderId,
                },
            });

            dispatch({
                type: RESTORE_DRAWING_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: RESTORE_DRAWING_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get detail drawing folders
 *
 * @param string params.id - id of drawing folders
 * @param string params.project_id - id of project
 * @return Promise<void>
 */
export const getSharedUserPrivateDrawing = (params: {
    drawing_id?: string;
    project_id?: string;
    // page: number;
    // per_page: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SHARED_USER_PRIVATE_DRAWING_REQUEST });
        try {
            const res = await axiosClient.get('project/drawings/assignees', {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: SHARED_USER_PRIVATE_DRAWING_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: SHARED_USER_PRIVATE_DRAWING_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get detail drawing folders
 *
 * @param string params.id - id of drawing folders
 * @param string params.project_id - id of project
 * @return Promise<void>
 */
export const updateSharedUserPrivateDrawing = (
    params: {
        drawing_id?: string;
        project_id?: string;
    },
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_USER_PRIVATE_DRAWING_REQUEST });
        try {
            const res = await axiosClient.post(
                'project/drawings/assignees/permissions',
                formData,
                {
                    params: {
                        ...params,
                    },
                },
            );

            dispatch({
                type: UPDATE_USER_PRIVATE_DRAWING_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UPDATE_USER_PRIVATE_DRAWING_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get detail drawing folders
 *
 * @param string params.id - id of drawing folders
 * @param string params.project_id - id of project
 * @return Promise<void>
 */
export const deleteSharedUserPrivateDrawing = (
    params: {
        drawing_id?: string;
        project_id?: string;
    },
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_USER_PRIVATE_DRAWING_REQUEST });
        try {
            const res = await axiosClient.post(
                'project/drawings/assignees/delete',
                formData,
                {
                    params: {
                        ...params,
                    },
                },
            );

            dispatch({
                type: DELETE_USER_PRIVATE_DRAWING_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DELETE_USER_PRIVATE_DRAWING_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to pin drawing folders
 *
 * @param string params.ids - ids of drawing folders
 * @param string params.project_id - id of project
 * @return Promise<void>
 */
export const pinUnpinDrawingSpace = (
    params: { id?: string; uuid?: string; project_id?: string },
    isPin: boolean,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: PIN_UNPIN_DRAWING_SPACE_REQUEST });
        try {
            const res = await axiosClient.put(
                `project/drawings/folders/${isPin ? 'pin' : 'unpin'}`,
                null,
                {
                    params: {
                        ...params,
                    },
                },
            );

            dispatch({
                type: PIN_UNPIN_DRAWING_SPACE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: PIN_UNPIN_DRAWING_SPACE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to upload drawing file
 *
 * @param string params.project_id - id of project
 * @param FormData formData- body data of drawing folders
 * @return Promise<void>
 */
export const uploadDrawingFile = (
    params: {
        project_id: string;
        folder_id?: string;
    },
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPLOAD_DRAWING_FILE_REQUEST });
        try {
            const res = await axiosClient.post('project/drawings', formData, {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: UPLOAD_DRAWING_FILE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UPLOAD_DRAWING_FILE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to send drawing file to email
 *
 * @param string params.project_id - id of project
 * @param FormData formData- body data of drawing folders
 * @return Promise<void>
 */
export const sendDrawingToEmail = (
    params: {
        project_id: string;
    },
    data: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SEND_DRAWING_REQUEST });
        try {
            const res = await axiosClient.post('project/drawings/send', data, {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: SEND_DRAWING_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: SEND_DRAWING_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

export const shareDrawingToEmail = (
    params: {
        project_id: string;
    },
    data: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SHARE_DRAWING_REQUEST });
        try {
            const res = await axiosClient.post('project/drawings/share', data, {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: SHARE_DRAWING_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: SHARE_DRAWING_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to upload drawing file
 *
 * @param string params.project_id - id of project
 * @param FormData formData- body data of drawing folders
 * @return Promise<void>
 */
export const updateDrawing = (
    params: {
        id: string;
        project_id?: string;
    },
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_DRAWING_REQUEST });
        try {
            const res = await axiosClient.post('project/drawings/meta', formData, {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: UPDATE_DRAWING_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UPDATE_DRAWING_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to share folder
 *
 * @param string params.ids - ids of drawing folders
 * @param string params.project_id - id of project
 * @param FormData formData- body data of drawing folders
 * @return Promise<void>
 */
export const inviteUserDrawing = (
    params: {
        project_id: string;
    },
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: INVITE_USER_DRAWING_REQUEST });
        try {
            const res = await axiosClient.post('project/drawings/invite', formData, {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: INVITE_USER_DRAWING_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: INVITE_USER_DRAWING_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all drawing compare file
 *
 * @param params
 * @returns
 */
export const getAllDrawingCompareFile = (params: {
    project_id: string;
    format?: string;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_DRAWING_COMPARE_FILE_REQUEST });
        try {
            const res = await axiosClient.get('project/drawings/files', {
                params,
            });

            dispatch({
                type: ALL_DRAWING_COMPARE_FILE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_DRAWING_COMPARE_FILE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to paste drawing
 *
 * @param params
 * @param type
 * @param formData
 * @returns
 */
export const pasteDrawing = (
    params: { project_id: string; folder_id: string },
    type: string,
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: PASTE_DRAWING_REQUEST });
        try {
            const res = await axiosClient.post(`project/drawings/${type}`, formData, {
                params,
            });

            dispatch({
                type: PASTE_DRAWING_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: PASTE_DRAWING_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to create share link drawing
 *
 * @param params
 * @param type
 * @param formData
 * @returns
 */
export const createShareLinkDrawing = (formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CREATE_SHARE_LINK_DRAWING_REQUEST });
        try {
            const res = await axiosClient.post(
                'project/drawings/sharelink/create',
                formData,
            );

            dispatch({
                type: CREATE_SHARE_LINK_DRAWING_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: CREATE_SHARE_LINK_DRAWING_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get share link drawing
 *
 * @param params
 * @param type
 * @param formData
 * @returns
 */
export const getShareLinkDrawing = (id: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: GET_SHARE_LINK_DRAWING_REQUEST });
        try {
            const res = await axiosClient.get(`project/drawings/sharelink/${id}`);

            dispatch({
                type: GET_SHARE_LINK_DRAWING_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: GET_SHARE_LINK_DRAWING_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

export const clearAllDrawingFolder = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_DRAWING_PUBLIC_FOLDER_RESET });
        dispatch({ type: ALL_DRAWING_PRIVATE_FOLDER_RESET });
    };
};

export const clearCreateDrawingSpace = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CREATE_DRAWING_FOLDER_RESET });
    };
};

export const clearDetailDrawing = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_DRAWING_RESET });
    };
};

export const clearUpdateDrawing = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_DRAWING_RESET });
    };
};

export const clearUploadDrawingFile = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPLOAD_DRAWING_FILE_RESET });
    };
};

export const clearPinUnpinDrawingSpace = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: PIN_UNPIN_DRAWING_SPACE_RESET });
    };
};

export const clearDeleteDrawing = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_DRAWING_RESET });
    };
};

export const clearAllDrawingCompareFile = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_DRAWING_COMPARE_FILE_RESET });
    };
};

export const clearSendDrawingToEmail = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SEND_DRAWING_RESET });
    };
};

export const clearShareDrawingToEmail = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SHARE_DRAWING_RESET });
    };
};

export const clearRestoreDrawing = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESTORE_DRAWING_RESET });
    };
};

export const clearInviteUserDrawing = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: INVITE_USER_DRAWING_RESET });
    };
};

export const clearPasteDrawing = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: PASTE_DRAWING_RESET });
    };
};

export const clearHistoryDrawing = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: HISTORY_DRAWING_RESET });
    };
};

export const clearSharedUserPrivateDrawing = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SHARED_USER_PRIVATE_DRAWING_RESET });
    };
};

export const clearUpdateSharedUserPrivateDrawing = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_USER_PRIVATE_DRAWING_RESET });
    };
};

export const clearDeleteSharedUserPrivateDrawing = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_USER_PRIVATE_DRAWING_RESET });
    };
};

export const clearCreateShareLinkDrawing = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CREATE_SHARE_LINK_DRAWING_RESET });
    };
};

export const clearGetShareLinkDrawing = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: GET_SHARE_LINK_DRAWING_RESET });
    };
};
