import { Fragment, useState } from 'react';
import styled from 'styled-components';

import Eye from '@/assets/svg/icons/eye.svg';
import EyeSlash from '@/assets/svg/icons/eye-slash.svg';
import { FaStarOfLife } from 'react-icons/fa';
import { Placeholder } from 'react-bootstrap';

interface InputBasicProps {
    id?: string;
    type?: string;
    placeholder?: string;
    outerClassName?: string;
    innerClassName?: string;
    icon?: JSX.Element | string;
    isRequired?: boolean;
    value?: string;
    name?: string;
    disabled?: boolean;
    maxLength?: number;
    minLength?: number;
    rules?: {
        function: (name: any, rules: any) => any;
        name: string;
        rules: any;
        errors?: any;
    };
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    autoComplete?: string;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    loading?: boolean;
    inputRef?: any;
    readOnly?: boolean;
}

/**
 * Input Basic Component
 *
 * @param props
 * @returns
 */
export default function InputBasic(props: InputBasicProps): JSX.Element {
    const {
        id,
        type,
        placeholder,
        icon,
        outerClassName,
        innerClassName,
        isRequired,
        value,
        name,
        minLength,
        maxLength,
        disabled,
        rules,
        onChange,
        autoComplete,
        onKeyDown,
        loading,
        inputRef,
        readOnly,
    } = props;

    const [passwordType, setPasswordType] = useState('password');

    /**
     * Function to toggle password type
     *
     * @return {void}
     */
    const togglePassword = (): void => {
        setPasswordType(passwordType === 'password' ? 'text' : 'password');
    };

    return (
        <Fragment>
            {loading ?? false ? (
                <div className={`input-group text-start ${outerClassName ?? 'mb-3'}`}>
                    <div className={`form-floating ${innerClassName ?? ''}`}>
                        <Placeholder xs={6} />
                        <Placeholder as={'h2'} animation="glow">
                            <Placeholder xs={12} />
                        </Placeholder>
                    </div>
                </div>
            ) : icon != null ? (
                <div className={`input-group ${outerClassName ?? 'mb-3'}`}>
                    <InputWrapper
                        className={`form-floating ${innerClassName ?? ''}`}
                        errors={rules?.errors[rules?.name] !== undefined}
                    >
                        <Icon className="input-group-text span-custom">
                            <>
                                {typeof icon === 'string' ? (
                                    <IconContent
                                        className="icon-active"
                                        icon={icon}
                                        errors={rules?.errors[rules?.name] !== undefined}
                                    />
                                ) : (
                                    <span>{icon}</span>
                                )}
                            </>
                        </Icon>
                        {type === 'password' && (
                            <img
                                src={passwordType === 'password' ? EyeSlash : Eye}
                                alt="icon"
                                id="eyeIcon"
                                style={{
                                    width: '1.25rem',
                                    height: '1.25rem',
                                    position: 'absolute',
                                    right: 10,
                                    top: 17,
                                    cursor: 'pointer',
                                }}
                                onClick={() => togglePassword()}
                            />
                        )}
                        <Input
                            id={id}
                            type={type === 'password' ? passwordType : type}
                            placeholder={placeholder}
                            className="form-control"
                            required={isRequired}
                            defaultValue={value}
                            name={name}
                            disabled={disabled}
                            onChange={onChange}
                            minLength={minLength}
                            maxLength={maxLength}
                            autoComplete={autoComplete}
                            ref={inputRef}
                            readOnly={readOnly}
                            {...rules?.function(rules?.name, rules?.rules)}
                            errors={rules?.errors[rules?.name] !== undefined}
                        />
                        <Label
                            htmlFor={`htmlFor${id ?? 'input'}`}
                            errors={rules?.errors[rules?.name] !== undefined}
                            className="label-active"
                        >
                            {placeholder}
                            {rules?.rules?.required !== undefined && (
                                <FaStarOfLife
                                    color="#FB6056"
                                    size={7}
                                    style={{
                                        position: 'relative',
                                        top: -6,
                                        left: 3,
                                    }}
                                />
                            )}
                        </Label>
                        {rules?.errors[rules?.name] !== undefined && (
                            <span
                                className="invalid-feedback d-block text-start"
                                style={{
                                    fontSize: '0.75rem',
                                }}
                            >
                                {rules?.errors[rules?.name].message}
                            </span>
                        )}
                    </InputWrapper>
                </div>
            ) : (
                <div className={`input-group ${outerClassName ?? 'mb-3'}`}>
                    <InputWrapper
                        className={`form-floating ${innerClassName ?? ''}`}
                        errors={rules?.errors[rules?.name] !== undefined}
                    >
                        {type === 'password' && (
                            <img
                                src={passwordType === 'password' ? EyeSlash : Eye}
                                alt="icon"
                                style={{
                                    width: '1.25rem',
                                    height: '1.25rem',
                                    position: 'absolute',
                                    right: 10,
                                    top: 17,
                                    cursor: 'pointer',
                                }}
                                onClick={() => togglePassword()}
                            />
                        )}
                        <InputWithoutIcon
                            id={id}
                            type={type === 'password' ? passwordType : type}
                            placeholder={placeholder}
                            className="form-control"
                            required={isRequired}
                            defaultValue={value}
                            name={name}
                            disabled={disabled}
                            onChange={onChange}
                            minLength={minLength}
                            maxLength={maxLength}
                            autoComplete={autoComplete}
                            onKeyDown={onKeyDown}
                            ref={inputRef}
                            readOnly={readOnly}
                            {...rules?.function(rules?.name, rules?.rules)}
                            errors={rules?.errors[rules?.name] !== undefined}
                        />
                        <LabelWithoutIcon
                            htmlFor={`htmlFor${id ?? 'input'}`}
                            errors={rules?.errors[rules?.name] !== undefined}
                            className="label-active"
                        >
                            {placeholder}
                            {rules?.rules?.required !== undefined && (
                                <FaStarOfLife
                                    color="#FB6056"
                                    size={7}
                                    style={{
                                        position: 'relative',
                                        top: -6,
                                        left: 3,
                                    }}
                                />
                            )}
                        </LabelWithoutIcon>
                        {rules?.errors[rules?.name] !== undefined && (
                            <span
                                className="invalid-feedback d-block text-start"
                                style={{
                                    fontSize: '0.75rem',
                                }}
                            >
                                {rules?.errors[rules?.name].message}
                            </span>
                        )}
                    </InputWrapper>
                </div>
            )}
        </Fragment>
    );
}

export const InputWrapper = styled.div<{ errors?: boolean }>`
    &:focus-within {
        .icon-active {
            background-color: ${props => (props.errors ?? false ? '#FB6056' : '#5648fb')};
        }

        .label-active {
            color: ${props => (props.errors ?? false ? '#FB6056' : '#5648fb')};
        }
    }
`;

export const Input = styled.input<{ errors?: boolean }>`
    border: none;
    border-bottom: 1px solid #cfcfcf;
    background-color: transparent;
    border-radius: 0;
    margin-top: 10px;
    padding: 0 50px 10px 63px !important;
    height: 45px !important;

    &:focus {
        box-shadow: none;
        border-bottom: 1px solid
            ${props => (props.errors ?? false ? '#FB6056' : '#5648fb')};
        background-color: transparent;

        .icon-content {
            background-color: ${props => (props.errors ?? false ? '#FB6056' : '#5648fb')};
        }
    }

    &:disabled {
        background-color: transparent;
        color: #cfcfcf;
    }

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #ffff inset;
    }
`;

export const Icon = styled.span`
    border: none;
    border-radius: 0;
    background-color: transparent;
    position: absolute;
    top: 10px;
    border-right: 1px solid #cfcfcf;

    .span-custom {
        width: 30px;
        /* auto: 30px; */
    }
`;

export const IconContent = styled.span<{ icon: string; errors?: boolean }>`
    width: 1.5rem;
    height: 1.5rem;
    mask: url(${props => props.icon}) no-repeat center;
    mask-size: 80%;
    -webkit-mask: url(${props => props.icon}) no-repeat center;
    -webkit-mask-size: 80%;
    background-color: #838383;
`;

export const Label = styled.label<{ errors?: boolean }>`
    padding-left: 60px !important;
    color: #838383;
    top: -2px !important;

    .form-control:focus + & {
        top: -10px !important;
        padding-left: 70px !important;
        font-size: 12px !important;
    }

    .form-control:not(:placeholder-shown) + & {
        top: -10px !important;
        font-size: 12px !important;
        padding-left: 70px !important;
    }
`;

export const InputWithoutIcon = styled.input<{ errors?: boolean; type: string }>`
    border: none;
    border-bottom: 1px solid #cfcfcf;
    background-color: transparent;
    border-radius: 0;
    margin-top: 10px;
    padding: ${props =>
        props.type === 'password'
            ? '0 50px 10px 4px !important'
            : '0 10px 0 3px !important'};
    height: 45px !important;

    &:focus {
        box-shadow: none;
        border-bottom: 1px solid
            ${props => (props.errors ?? false ? '#FB6056' : '#5648fb')};
        background-color: transparent;
    }

    &:disabled {
        background-color: transparent;
        color: #cfcfcf;
    }

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #ffff inset;
    }
`;

export const LabelWithoutIcon = styled.label<{ errors?: boolean }>`
    padding-left: 0 !important;
    color: #838383;
    top: -2px !important;

    &:focus {
        box-shadow: none;
        border-bottom: 1px solid
            ${props => (props.errors ?? false ? '#FB6056' : '#5648fb')};
        background-color: transparent;
    }

    .form-control:focus + & {
        // color: ${props => (props.errors ?? false ? '#FB6056' : '#5648fb')};
        top: -10px !important;
        font-size: 12px !important;
    }

    .form-control:not(:placeholder-shown) + & {
        top: -10px !important;
        font-size: 12px !important;
    }
`;
