export const ALL_PROJECT_HISTORY_REQUEST = 'ALL_PROJECT_HISTORY_REQUEST';
export const ALL_PROJECT_HISTORY_SUCCESS = 'ALL_PROJECT_HISTORY_SUCCESS';
export const ALL_PROJECT_HISTORY_FAIL = 'ALL_PROJECT_HISTORY_FAIL';

export const DETAIL_PROJECT_HISTORY_REQUEST = 'DETAIL_PROJECT_HISTORY_REQUEST';
export const DETAIL_PROJECT_HISTORY_SUCCESS = 'DETAIL_PROJECT_HISTORY_SUCCESS';
export const DETAIL_PROJECT_HISTORY_FAIL = 'DETAIL_PROJECT_HISTORY_FAIL';

const initialState = {
    loading: false,
    response: null,
    error: null,
    status: null,
};

export const allProjectHistoryReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_PROJECT_HISTORY_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_PROJECT_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_PROJECT_HISTORY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        // case CLEAR_STATE:
        //     return initialState;

        default:
            return state;
    }
};

export const detailProjectHistoryReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DETAIL_PROJECT_HISTORY_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_PROJECT_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DETAIL_PROJECT_HISTORY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        // case CLEAR_STATE:
        //     return initialState;

        default:
            return state;
    }
};
