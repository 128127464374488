import authImage13 from '@/assets/images/auth/help-auth-image-13.webp';

import { Fragment } from 'react';

export default function HowToLogin(): JSX.Element {
    const contents = [
        {
            id: 1,
            text: 'To log in, you simply need to go to the landing page then click the login button, so you will be redirected to the login page and you only need to fill in your email and password that have been registered with the application.',
            alt: 'loginPage',
            image: authImage13,
        },
    ];

    return (
        <Fragment>
            {contents.map((content, index) => (
                <div key={index} className="text-gray">
                    <img src={content.image} alt={content.alt} className="img-fluid" />
                    <p className="mt-4 pl-2">{content.text}</p>
                </div>
            ))}
        </Fragment>
    );
}
