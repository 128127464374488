import { axiosClient } from '@/services/GatewayService';

export const createDrawingSpaceLib = async (
    params: {
        project_id: string;
        public: number;
    },
    formData: FormData,
): Promise<any> => {
    try {
        const res = await axiosClient.post('project/drawings/folders', formData, {
            params: {
                ...params,
            },
        });

        return res?.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const requestMultipleDrawingLib = async (
    params: {
        project_id: string;
    },
    formData: FormData,
): Promise<any> => {
    try {
        const res = await axiosClient.post(
            `drawings/sharelink/download/${params.project_id}`,
            formData,
        );

        return res?.data;
    } catch (error: any) {
        throw new Error(error);
    }
};
