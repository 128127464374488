import { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import InputBasic from '@/components/atoms/Inputs/InputBasic';
import ModalAction from '@/components/atoms/Modals/ModalAction';
import { createDocumentFolder } from '../redux/actions';
import { RootState } from '@/redux/store';

export default function ModalDocumentCreate({
    modal,
    setModal,
}: {
    modal: boolean;
    setModal: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
    });

    const dispatch = useDispatch();
    const { id, urlSpaceId, urlFolderId } = useParams();

    const projectId = id ?? '';
    const folderUuid = urlFolderId ?? urlSpaceId ?? '';

    const createDocumentFolderState = useSelector(
        (state: RootState) => state.createDocumentFolder,
    );

    // reset form when success
    useEffect(() => {
        if (createDocumentFolderState.status === 200) {
            reset();
        }
    }, [createDocumentFolderState]);

    /**
     * Function on submit
     *
     * @param any data
     * @return void
     * @see cypress/e2e/dashboard/Project/project.cy.ts
     *      To cypress unit tester
     */
    const onSubmit = (data: any): void => {
        // initialize form data
        const payload: any = new FormData();

        // append data to form data
        for (const key in data) {
            payload.append(key, data[key as keyof typeof data]);

            // if description is empty, delete it
            if (key === 'description' && data[key as keyof typeof data] === '') {
                payload.delete(key);
            }
        }

        // Dispatch action
        dispatch(
            createDocumentFolder(
                { project_id: projectId, folder_id: folderUuid },
                payload,
            ),
        );
    };

    return (
        <ModalAction
            modal={modal}
            setModal={setModal}
            aria-labelledby="contained-modal-title-vcenter"
            title="Create New Sub Folder"
            size="sm"
        >
            <form action="#" onSubmit={handleSubmit(onSubmit)} method="post">
                <InputBasic
                    id="folderName"
                    type="text"
                    placeholder="Folder Name"
                    innerClassName="my-0"
                    outerClassName="px-4 mt-3"
                    rules={{
                        function: register,
                        name: 'name',
                        rules: {
                            required: 'Folder name is required',
                        },
                        errors,
                    }}
                />
                <InputBasic
                    id="descriptionFolder"
                    type="text"
                    placeholder="Description"
                    innerClassName="my-0"
                    outerClassName="px-4 mt-3"
                    rules={{
                        function: register,
                        name: 'description',
                        rules: {
                            maxLength: {
                                value: 2000,
                                message: 'Maximum length of 2000 characters',
                            },
                        },
                        errors,
                    }}
                />
                <ButtonBasic
                    text="Create Sub Folder"
                    type="submit"
                    className="btn btn-primary btn-sm mt-4"
                    loading={createDocumentFolderState.loading}
                />
            </form>
        </ModalAction>
    );
}
