import { useLocation, useNavigate } from 'react-router-dom';

import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import Header from '@/components/molecules/Header';
import { Title } from '@/components/molecules/Typography';

import { ThreeDots } from 'react-loader-spinner';

import { useEffect } from 'react';
import Cookies from 'js-cookie';

export default function RedirectAdmin(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();

    const handleConfirm = (): void => {
        navigate('/');
    };

    // Get the params from the URL, and decrypt it
    useEffect(() => {
        if (location?.search !== '') {
            const searchParams = new URLSearchParams(location.search);
            const params = searchParams.get('direct');
            if (params !== null) {
                setTimeout(() => {
                    Cookies.set('user', params, {
                        expires: 1,
                        sameSite: 'None',
                        secure: true,
                    });
                    window.location.href = '/';
                }, 5000);
            }
        }
    }, [location.search]);

    return (
        <>
            <div className="container-fluid container-xs container-lg">
                <header>
                    <Header text="Return to home screen" link="/" />
                </header>
                <main>
                    <div className="row my-5">
                        <div className="col-md-12 text-center my-5">
                            <Title text="Please wait will be redirect to dashboard page" />
                        </div>
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#5648FB"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                            visible={true}
                        />
                        <div className="col-md-4 mx-auto text-center mb-3">
                            <span className="text-muted">
                                If you are not redirected automatically, click the button
                            </span>
                        </div>
                    </div>
                    <div className="row my-5">
                        <div className="col-md-4 mx-auto text-center">
                            <ButtonBasic
                                type="button"
                                text="Go to Dashboard Page"
                                className="my-3 w-75 btn-primary"
                                onClick={handleConfirm}
                            />
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}
