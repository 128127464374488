import * as React from 'react';
import { useState, cloneElement } from 'react';
import { useControl } from 'react-map-gl';
import { createPortal } from 'react-dom';

import type { MapboxMap, IControl } from 'react-map-gl';

class OverlayControl implements IControl {
    _map: MapboxMap | null = null;
    _container: HTMLElement = document.createElement('div');
    _redraw: () => void;

    constructor(redraw: () => void) {
        this._redraw = redraw;
    }

    onAdd(map: MapboxMap): HTMLElement {
        this._map = map;
        map.on('move', this._redraw);
        /* global document */
        this._container = document.createElement('div');
        this._redraw();
        return this._container;
    }

    onRemove(): void {
        this._container.remove();
        this?._map?.off('move', this._redraw);
        this._map = null;
    }

    getMap(): MapboxMap | null {
        return this._map;
    }

    getElement(): HTMLElement {
        this._container.style.padding = '10px';
        // this._container.style.position = 'absolute';
        // this._container.style.top = '0';
        // this._container.style.right = '5rem';
        // this._container.style.zIndex = '1000';
        this._container.style.pointerEvents = 'all';

        return this._container;
    }
}

/**
 * A custom control that rerenders arbitrary React content whenever the camera changes
 */
function CustomOverlay(props: { children: React.ReactElement }): JSX.Element {
    const [, setVersion] = useState(0);

    const ctrl = useControl<OverlayControl>(() => {
        const forceUpdate = (): void => setVersion(v => v + 1);
        return new OverlayControl(forceUpdate);
    });

    const map = ctrl.getMap();

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    return map ? (
        createPortal(cloneElement(props.children, { map }), ctrl.getElement())
    ) : (
        <></>
    );
}

export default React.memo(CustomOverlay);
