// import { RiErrorWarningLine } from 'react-icons/ri';

interface BadgeBasicProps {
    id?: string;
    text: string;
    variant: string;
    className?: string;
}

/**
 * Create pill badge component.
 *
 * @param props BadgeBasicProps
 * @constructor
 */
export default function BadgePill(props: BadgeBasicProps): JSX.Element {
    const { id, text, variant, className } = props;

    /**
     * Function for set background color
     *
     * @param value string
     * @returns string
     */
    const backgroundList = (value: string): string => {
        switch (value) {
            case 'primary':
                return '#5648fb';
            case 'danger':
                return 'rgba(251, 96, 86, 0.1)';
            case 'warning':
                return '#5648fb';
            case 'info':
                return '#5648fb';
            case 'grey':
                return 'rgba(48, 48, 48, 0.2)';
            case 'success':
                return 'rgba(6, 189, 128, 0.2)';

            default:
                return '';
        }
    };

    /**
     * Function for set color text
     *
     * @param value string
     * @returns string
     */
    const colorList = (value: string): string => {
        switch (value) {
            case 'primary':
                return '#5648fb';
            case 'danger':
                return '#FB6056';
            case 'warning':
                return '#5648fb';
            case 'info':
                return '#5648fb';
            case 'grey':
                return 'rgba(48, 48, 48, 1)';

            case 'success':
                return 'rgba(6, 189, 128, 1)';

            default:
                return '';
        }
    };

    return (
        <>
            <span
                id={id}
                className={`badge ${className ?? ''}`}
                style={{
                    padding: '5px 10px',
                    backgroundColor: backgroundList(variant),
                    fontWeight: 500,
                    borderRadius: 90,
                    fontSize: 11,
                    color: colorList(variant),
                }}
            >
                {text}
            </span>
        </>
    );
}
