import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';

import EditNicheTrade from '../../components/NicheTrade/EditNicheTrade';
import AddNicheTrade from '../../components/NicheTrade/AddNicheTrade';

import { RootState } from '@/redux/store';

import {
    getAllNicheTrade,
    getDetailNicheTrade,
    deleteNicheTrade,
    clearActionNicheTrade,
} from '../../redux/actions';
import { currentProjectAccess } from '@/utility/Utils';
import TableBorderless from '@/components/organism/TableBorderless';

export default function ManageNicheTrade(): JSX.Element {
    const dispatch = useDispatch();

    const { id } = useParams();
    const projectId: string = id ?? '';

    const allNicheTradeState = useSelector((state: RootState) => state.allNicheTrade);
    const actionNicheTradeState = useSelector(
        (state: RootState) => state.actionNicheTrade,
    );
    // const currentProfileProjectState = useSelector(
    //     (state: RootState) => state.currentProfileProject,
    // );
    // const currentProfileState = useSelector((state: RootState) => state.currentProfile);

    // const ownerId: number =
    //     currentProfileProjectState?.response?.company?.company_user?.id;
    // const userId: number = currentProfileState?.response?.id;

    const projectAccess = currentProjectAccess();

    const summaryAccess = projectAccess?.summary;

    const [selectedNiche, setSelectedNiche] = useState<[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [params, setParams] = useState({
        project_id: projectId,
        // page: 1,
        // per_page: 1000,
        search: '',
        sort_by: 'name',
        sort_asc: 1,
    });

    const [modal, setModal] = useState({
        add: false,
        success: false,
        edit: false,
        danger: false,
    });
    const [modalType, setModalType] = useState('');

    const tableCollaborator = [
        {
            title: 'Niche Trade Name',
            selector: 'name',
        },
        {
            title: 'Total Task',
            selector: 'total_task',
        },
        {
            title: 'Description',
            selector: 'description',
        },
        {
            title: 'Action',
            selector: '',
            withAction: true,
            className: 'text-center',
            actions: [
                {
                    type: 'edit',
                    text: 'Edit Niche Trade',
                    onClick: (row: any) => handleEditNiche(row),
                },
            ],
        },
    ];

    const tableViewOnly = [
        {
            title: 'Niche Trade Name',
            selector: 'name',
        },
        {
            title: 'Total Task',
            selector: 'total_task',
        },
        {
            title: 'Description',
            selector: 'description',
        },
    ];

    /**
     * Function to handle search
     *
     * @param string value
     * @returns void
     */
    const handleSearch = useCallback((value: string): void => {
        setSearchValue(value);
    }, []);

    /**
     * Function to handle debounce search
     *
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleDebouncedSearch = useCallback(() => {
        setParams({
            ...params,
            search: searchValue,
            // page: 1,
        });

        const payload = {
            ...params,
            search: searchValue,
        };

        dispatch(getAllNicheTrade(payload));
    }, [searchValue]);

    // /**
    //  * Function for handle pagination
    //  *
    //  * @param number page
    //  * @param string type
    //  * @returns void
    //  */
    // const handlePagination = (page: number, type: string): void => {
    //     let newPage = params.page;

    //     if (type === 'next') {
    //         newPage = params.page + 1;
    //     }

    //     if (type === 'prev') {
    //         newPage = params.page - 1;
    //     }

    //     if (type === 'page') {
    //         newPage = page;
    //     }

    //     const payload = {
    //         ...params,
    //         page: newPage,
    //     };

    //     setParams(payload);
    //     dispatch(getAllNicheTrade(payload));
    // };

    /**
     * Function to show modal add
     *
     * @return void
     */
    const handleAddNiche = (): void => {
        setModalType('add');

        setModal((prev: any) => ({
            ...prev,
            add: true,
        }));
    };

    /**
     * Function to show modal edit
     *
     * @param row {id-number}
     * @return void
     */
    const handleEditNiche = (row: { id: number }): void => {
        dispatch(
            getDetailNicheTrade({
                project_id: projectId,
                id: row.id,
            }),
        );

        setModalType('edit');

        setModal(prev => ({
            ...prev,
            edit: true,
        }));
    };

    /**
     * Function to handle delete
     *
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleDeleteNiche = (): void => {
        const payload = selectedNiche.map((item: any) => item.id);

        setModalType('delete');

        dispatch(
            deleteNicheTrade({
                ids: payload.toString(),
                project_id: projectId,
            }),
        );
    };

    // Function for handle debounce search
    useEffect(() => {
        const debounce = setTimeout(() => {
            handleDebouncedSearch();
        }, 500);

        return () => {
            clearTimeout(debounce);
        };
    }, [handleDebouncedSearch]);

    // Function for show success modal
    useEffect(() => {
        if (actionNicheTradeState.status === 200) {
            dispatch(getAllNicheTrade(params));

            setModal(prev => ({
                ...prev,
                add: false,
                danger: false,
                success: true,
            }));

            dispatch(clearActionNicheTrade());
            setSelectedNiche([]);
        }
    }, [actionNicheTradeState]);

    return (
        <div className="mt-4">
            <TableBorderless
                header={summaryAccess?.collaborate ? tableCollaborator : tableViewOnly}
                items={allNicheTradeState?.response ?? []}
                loading={allNicheTradeState?.loading}
                handleSearch={handleSearch}
                // handlePagination={handlePagination}
                withoutPagination
                selected={selectedNiche}
                setSelected={setSelectedNiche}
                moduleType="Niche Trade"
                actionClass="btn-outline-danger"
                addTitle="+ Add Niche"
                actionTitle="Delete Selected Niche"
                withAdd={summaryAccess?.collaborate}
                withAction={summaryAccess?.collaborate}
                withoutCheckbox={!summaryAccess?.collaborate}
                handleAdd={() => handleAddNiche()}
                handleAction={() => setModal(prev => ({ ...prev, danger: true }))}
            />

            <AddNicheTrade modal={modal} setModal={setModal} params={params} />
            <EditNicheTrade modal={modal} setModal={setModal} params={params} />

            <ModalDashboard
                modal={modal.success}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success"
                body={`Success ${modalType} niche trade`}
                onConfirm={() => setModal(prev => ({ ...prev, success: false }))}
            />
            <ModalDashboard
                modal={modal.danger}
                setModal={setModal}
                variant="danger"
                type="cancel"
                title="Delete Niche?"
                body="Are you sure want to delete this niche?"
                withCancel
                onConfirm={handleDeleteNiche}
                loading={actionNicheTradeState?.loading}
            />
        </div>
    );
}
