import subscriptionImage1 from '@/assets/images/subscription/help-subscription-image-1.webp';
import subscriptionImage6 from '@/assets/images/subscription/help-subscription-image-6.webp';

import { Fragment } from 'react';

export default function HowToChangeCreditCard(): JSX.Element {
    const contents = [
        {
            id: 1,
            text: 'You can click the change card button in the active subscription section of the card information section, then the page will move to detailed card information.',
            alt: 'loginPage',
            image: subscriptionImage1,
        },
        {
            id: 2,
            text: 'After the page moves to detailed card information, you can input your new card data, making sure everything is correct, after that you can click the check card button, if all the data you input is correct then a notification will appear if your card is correct then click the save changes button.',
            alt: 'loginPage',
            image: subscriptionImage6,
        },
    ];

    return (
        <Fragment>
            {contents.map((content, index) => (
                <div key={index} className="text-grey-300 mb-10">
                    <img src={content.image} alt={content.alt} className="img-fluid" />
                    <p className="mt-4 pl-2">{content.text}</p>
                </div>
            ))}
        </Fragment>
    );
}
