import { Fragment, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';

import EmailIcons from '@/assets/svg/icons/mail-alt-3.svg';

import InputBasic from '@/components/atoms/Inputs/InputBasic';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import LinkBack from '@/components/molecules/LinkBack';
import { HeaderContent } from '@/components/molecules/Typography';
import ModalBasic from '@/components/atoms/Modals/ModalBasic';
import { HeaderStepper, BodyStepper } from '@/components/molecules/VerticalStepper';

import styled from 'styled-components';

import terms from '@/assets/json/terms.json';
import {
    FieldErrors,
    UseFormRegister,
    UseFormUnregister,
    UseFormWatch,
} from 'react-hook-form';

export default function InviteInhouse(params: {
    register: UseFormRegister<any>;
    errors: FieldErrors<any>;
    unregister: UseFormUnregister<any>;
    watch: UseFormWatch<any>;
}): JSX.Element {
    const { register, errors, unregister, watch } = params;

    // params
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);

    // const inAppRegister = location;

    const [modal, setModal] = useState({
        agreement: false,
    });
    const [mailCount, setMailCount] = useState([
        {
            id: 0,
            value: '',
        },
        {
            id: 1,
            value: '',
        },
        {
            id: 2,
            value: '',
        },
    ]);

    /**
     * Function to remove mail
     *
     * @param {string} id
     * @returns {void}
     */
    const removeMail = (id: number): void => {
        const newMail = [...mailCount];
        const itemIndex = newMail.findIndex(item => item.id === id);
        newMail.splice(itemIndex, 1);
        setMailCount(newMail);

        unregister(`invited_inhouse_users${id}`);
    };

    /**
     * Function to handle change
     *
     * @param {any} e
     * @param {string} id
     */
    const handleChange = (e: any, id: number): void => {
        const newMail = [...mailCount];
        const itemIndex = newMail.findIndex(item => item.id === id);
        newMail[itemIndex] = { ...newMail[itemIndex], value: e.target.value };
        setMailCount(newMail);
    };

    /**
     * Function to add mail
     *
     * @returns {void}
     */
    const addMail = (): void => {
        setMailCount(prevMailCount => [
            ...prevMailCount,
            {
                id:
                    prevMailCount.length === 0
                        ? 0
                        : prevMailCount[prevMailCount.length - 1].id + 1,
                value: '',
            },
        ]);
    };

    return (
        <Fragment>
            <HeaderStepper className="row fade-in mt-5">
                <div className="col-md-4 ms-auto mt-2 mb-5">
                    <HeaderContent text="Invite Inhouse Worker" />
                    <p className="fw-normal h6 mb-0" style={{ fontSize: 16 }}>
                        Invite your inhouse worker <b>($5.99/user/month)</b>
                    </p>
                </div>
                <div className="col-md-4 text-end">
                    <LinkBack text="Return to home screen" />
                </div>
            </HeaderStepper>
            <BodyStepper className="row fade-in">
                <div className="col-md-4 mx-auto">
                    <div className="d-flex justify-content-end">
                        {mailCount.length < 5 && (
                            <span
                                className="link-primary"
                                onClick={addMail}
                                role="button"
                            >
                                + Add User
                            </span>
                        )}
                    </div>
                    {mailCount.map(item => (
                        <div className="d-flex justify-content between" key={item.id}>
                            <InputBasic
                                id="inviteInhouse"
                                type="text"
                                placeholder="Email Address"
                                outerClassName="mb-3"
                                icon={EmailIcons}
                                isRequired
                                value={item.value}
                                onChange={e => handleChange(e, item.id)}
                                rules={{
                                    function: register,
                                    name: `invited_inhouse_users${item.id}`,
                                    rules: {
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'Invalid email address',
                                        },
                                    },
                                    errors,
                                }}
                            />
                            <Link
                                to="#"
                                className="link-danger"
                                style={{
                                    textDecoration: 'none',
                                    position: 'relative',
                                    left: 0,
                                    top: 14,
                                }}
                                onClick={() => removeMail(item.id)}
                            >
                                <FiX className="me-1" />
                            </Link>
                        </div>
                    ))}
                    {queryParams.get('in_app_registration') !== 'true' && (
                        <Form.Check
                            type="checkbox"
                            inline
                            id="agreeTerms"
                            label="Agree to the terms of services and privacy policy"
                            onClick={() =>
                                watch('agreeTerms') === false &&
                                setModal({
                                    agreement: true,
                                })
                            }
                            {...register('agreeTerms', {
                                required:
                                    'Please agree to the terms of services and privacy policy',
                            })}
                        />
                    )}
                    {errors.agreeTerms != null && (
                        <p
                            className="invalid-feedback d-block text-start"
                            style={{ fontSize: '0.75rem' }}
                        >
                            {errors.agreeTerms.message as string}
                        </p>
                    )}
                </div>
            </BodyStepper>

            <ModalBasic
                modal={modal.agreement}
                setModal={setModal}
                title="Constructapp Terms and Conditions"
                size="lg"
            >
                <StructureList
                    className="overflow-auto pe-3"
                    style={{
                        height: '50vh',
                        fontSize: 11,
                    }}
                    // onScroll={(e: any) => handleScrollBottom(e)}
                >
                    <ol className="lists text-start">
                        {terms.map((item, indexTerms) => (
                            <Fragment key={indexTerms}>
                                <li className="mb-1">{item.title}</li>
                                <ol className="subLists mb-3">
                                    {item.lists.map((list, indexItems) => (
                                        <li key={indexItems}>
                                            {list.text}
                                            {list.subLists.length > 0 && (
                                                <ol type="a" className="subSubLists">
                                                    {list.subLists.map(
                                                        (subList, indexSubList) => (
                                                            <li key={indexSubList}>
                                                                {subList}
                                                            </li>
                                                        ),
                                                    )}
                                                </ol>
                                            )}
                                        </li>
                                    ))}
                                </ol>
                            </Fragment>
                        ))}
                    </ol>
                    <ButtonBasic
                        id="btnAgree"
                        type="button"
                        text="I have read and agree to the terms of services and privacy policy"
                        className="mt-3 btn-primary text-center"
                        onClick={() => setModal({ agreement: false })}
                        // disabled={isDisabledButton}
                    />
                </StructureList>
            </ModalBasic>
        </Fragment>
    );
}

const StructureList = styled.div`
    ol {
        margin: 0;
    }
    /* ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0 0 0 15px;
    }

    ol > li {
        display: table;
        counter-increment: item;
    }

    ol > li:before {
        content: counters(item, '.') ' ';
        display: table-cell;
        padding-right: 0.6em;
    }

    li ol > li:before {
        content: counters(item, '.') ' ';
    }

    //subSubLists to a,b,c
    .subSubLists {
        list-style-type: lower-alpha !important;
        counter-reset: item;
    } */
`;
