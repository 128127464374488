/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Pagination } from 'react-bootstrap';
import { CustomPagination } from './styles';

export default function PaginationView(props: {
    items: {
        data: Array<{}>;
        meta: {
            current_page: number;
            from: number;
            last_page: number;
            link: [];
            path: string;
            per_page: number;
            to: number;
            total: number;
        };
    };
    handlePagination?: (page: number, type: string) => void;
    withoutPagination?: boolean;
}): JSX.Element {
    const { items, handlePagination, withoutPagination } = props;
    const { current_page: currentPage, last_page: lastPage } = items?.meta || {};
    const showPagination = !(withoutPagination ?? false) || false;

    const shouldDisplayPage = (page: number): boolean => {
        if (
            page === 1 ||
            page === lastPage ||
            (page >= currentPage - 1 && page <= currentPage + 1) ||
            (page === currentPage - 2 && currentPage > 3) ||
            (page === currentPage + 2 && currentPage < lastPage - 2)
        ) {
            return true;
        }

        return false;
    };

    return (
        <div className="row g-0">
            <div className="col-lg-12 mx-auto text-center mt-3">
                {showPagination ? (
                    <CustomPagination className="justify-content-center align-items-center">
                        <Pagination.Prev
                            onClick={() => handlePagination?.(0, 'prev')}
                            disabled={currentPage === 1}
                        />

                        {Array.from({ length: lastPage }, (_, i) => i + 1).map(page => {
                            if (shouldDisplayPage(page)) {
                                return (
                                    <Pagination.Item
                                        key={page}
                                        active={page === currentPage}
                                        onClick={() => handlePagination?.(page, 'page')}
                                        disabled={page === currentPage}
                                    >
                                        {page}
                                    </Pagination.Item>
                                );
                            } else if (
                                (page === currentPage - 3 && currentPage > 3) ||
                                (page === currentPage + 3 && currentPage < lastPage - 2)
                            ) {
                                return <Pagination.Ellipsis key={page} />;
                            }

                            return null;
                        })}

                        <Pagination.Next
                            onClick={() => handlePagination?.(0, 'next')}
                            disabled={currentPage === lastPage}
                        />
                    </CustomPagination>
                ) : (
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: '50px' }}
                    />
                )}
            </div>
        </div>
    );
}
