import { axiosClient } from '@/services/GatewayService';
import { AppDispatch } from '@/redux/store';

import {
    ALL_PROJECT_REQUEST,
    ALL_PROJECT_SUCCESS,
    ALL_PROJECT_FAIL,
    ALL_TENDER_REQUEST,
    ALL_TENDER_SUCCESS,
    ALL_TENDER_FAIL,
    DETAIL_PROJECT_REQUEST,
    DETAIL_PROJECT_SUCCESS,
    DETAIL_PROJECT_FAIL,
    DETAIL_PROJECT_RESET,
    CREATE_PROJECT_REQUEST,
    CREATE_PROJECT_SUCCESS,
    CREATE_PROJECT_FAIL,
    CREATE_PROJECT_RESET,
    UPDATE_PROJECT_REQUEST,
    UPDATE_PROJECT_SUCCESS,
    UPDATE_PROJECT_FAIL,
    UPDATE_PROJECT_RESET,
    INVITE_PROJECT_MEMBER_REQUEST,
    INVITE_PROJECT_MEMBER_SUCCESS,
    INVITE_PROJECT_MEMBER_FAIL,
    INVITE_PROJECT_MEMBER_RESET,
    PIN_PROJECT_REQUEST,
    PIN_PROJECT_SUCCESS,
    PIN_PROJECT_FAIL,
    PIN_PROJECT_RESET,
    UNPIN_PROJECT_REQUEST,
    UNPIN_PROJECT_SUCCESS,
    UNPIN_PROJECT_FAIL,
    UNPIN_PROJECT_RESET,
    LEAVE_PROJECT_REQUEST,
    LEAVE_PROJECT_SUCCESS,
    LEAVE_PROJECT_FAIL,
    LEAVE_PROJECT_RESET,
    ALL_MEMBER_INVITED_REQUEST,
    ALL_MEMBER_INVITED_SUCCESS,
    ALL_MEMBER_INVITED_FAIL,
    DETAIL_MEMBER_INVITED_REQUEST,
    DETAIL_MEMBER_INVITED_SUCCESS,
    DETAIL_MEMBER_INVITED_FAIL,
    DETAIL_MEMBER_INVITED_RESET,
    ALL_MEMBER_INHOUSE_REQUEST,
    ALL_MEMBER_INHOUSE_SUCCESS,
    ALL_MEMBER_INHOUSE_FAIL,
    DETAIL_MEMBER_INHOUSE_REQUEST,
    DETAIL_MEMBER_INHOUSE_SUCCESS,
    DETAIL_MEMBER_INHOUSE_FAIL,
    DETAIL_MEMBER_INHOUSE_RESET,
    CREATE_BUG_REPORT_REQUEST,
    CREATE_BUG_REPORT_SUCCESS,
    CREATE_BUG_REPORT_FAIL,
    CREATE_BUG_REPORT_RESET,
    CHECK_USER_EMAIL_REQUEST,
    CHECK_USER_EMAIL_SUCCESS,
    CHECK_USER_EMAIL_FAIL,
    CHECK_USER_EMAIL_RESET,
    RESEND_PROJECT_INVITATION_REQUEST,
    RESEND_PROJECT_INVITATION_SUCCESS,
    RESEND_PROJECT_INVITATION_FAIL,
    RESEND_PROJECT_INVITATION_RESET,
    CANCEL_PROJECT_INVITATION_REQUEST,
    CANCEL_PROJECT_INVITATION_SUCCESS,
    CANCEL_PROJECT_INVITATION_FAIL,
    CANCEL_PROJECT_INVITATION_RESET,
    CLEAR_STATE,
} from '../reducers';

/**
 * Function to get all project
 *
 * @param number params.page - page of project
 * @param number params.per_page - number of project per page
 * @param string params.search - search of project
 * @param string params.sort_by - sort by of project
 * @param number params.sort_asc - sort asc of project
 * @return Promise<void>
 */
export const getAllProject = (params: {
    page: number;
    per_page: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_PROJECT_REQUEST });
        try {
            const res = await axiosClient.get('project/lives', {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: ALL_PROJECT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_PROJECT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get detail of project
 *
 *
 * @param number id - id of project
 * @return Promise<void>
 */
export const getDetailProject = (id: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_PROJECT_REQUEST });
        try {
            const res = await axiosClient.get('project', {
                params: {
                    id,
                },
            });

            dispatch({
                type: DETAIL_PROJECT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DETAIL_PROJECT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all tender
 *
 * @param number params.page - page of tender
 * @param number params.per_page - number of tender per page
 * @param string params.search - search of tender
 * @param string params.sort_by - sort by of tender
 * @param number params.sort_asc - sort asc of tender
 * @return Promise<void>
 */
export const getAllTender = (params: {
    page: number;
    per_page: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_TENDER_REQUEST });
        try {
            const res = await axiosClient.get('project/tenders', {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: ALL_TENDER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_TENDER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to create a project
 *
 * @param formData FormData - data of project
 * @return Promise<void>
 */
export const createProject = (formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CREATE_PROJECT_REQUEST });
        try {
            const res = await axiosClient.post('project', formData);

            dispatch({
                type: CREATE_PROJECT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: CREATE_PROJECT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to update a project
 *
 * @param formData FormData - data of project
 * @return Promise<void>
 */
export const updateProject = (formData: FormData, id: any): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_PROJECT_REQUEST });
        try {
            const res = await axiosClient.post('project', formData, {
                params: {
                    id,
                },
            });

            dispatch({
                type: UPDATE_PROJECT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UPDATE_PROJECT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to invite member to a project
 *
 * @param number project id - id of project
 * @param formData FormData - data of project
 * @return Promise<void>
 */
export const inviteProjectMember = (project_id: string, data: object): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: INVITE_PROJECT_MEMBER_REQUEST });
        try {
            const res = await axiosClient.post('project/members', data, {
                params: {
                    project_id,
                },
            });

            dispatch({
                type: INVITE_PROJECT_MEMBER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: INVITE_PROJECT_MEMBER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to pin a project
 *
 * @param number id - id of project
 * @return Promise<void>
 */
export const pinProject = (id: number): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: PIN_PROJECT_REQUEST });
        try {
            const res = await axiosClient.put('project/pin', null, {
                params: {
                    id,
                },
            });

            dispatch({
                type: PIN_PROJECT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: PIN_PROJECT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to unpin a project
 *
 * @param number id - id of project
 * @return Promise<void>
 */
export const unpinProject = (id: number): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UNPIN_PROJECT_REQUEST });
        try {
            const res = await axiosClient.put('project/unpin', null, {
                params: {
                    id,
                },
            });

            dispatch({
                type: UNPIN_PROJECT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UNPIN_PROJECT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to leave a project
 *
 * @param number id - id of project
 * @return Promise<void>
 */
export const leaveProject = (id: number): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: LEAVE_PROJECT_REQUEST });
        try {
            const res = await axiosClient.delete('project', {
                params: {
                    id,
                },
            });

            dispatch({
                type: LEAVE_PROJECT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: LEAVE_PROJECT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all invited user of project
 *
 * @param number params.page - page of list
 * @param number params.per_page - number of list per page
 * @param string params.search - search of list
 * @param string params.sort_by - sort by of list
 * @param number params.sort_asc - sort asc of list
 * @return Promise<void>
 */
export const getAllMemberInvited = (params: {
    project_id: string | undefined;
    page: number;
    per_page: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_MEMBER_INVITED_REQUEST });
        try {
            const res = await axiosClient.get('project/members', {
                params: {
                    ...params,
                    invited: 1,
                },
            });

            dispatch({
                type: ALL_MEMBER_INVITED_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_MEMBER_INVITED_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all inhouse user of project
 *
 * @param number params.page - page of list
 * @param number params.per_page - number of list per page
 * @param string params.search - search of list
 * @param string params.sort_by - sort by of list
 * @param number params.sort_asc - sort asc of list
 * @return Promise<void>
 */
export const getAllMemberInhouse = (params: {
    project_id: string | undefined;
    page: number;
    per_page: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_MEMBER_INHOUSE_REQUEST });
        try {
            const res = await axiosClient.get('project/members', {
                params: {
                    ...params,
                    inhouse: 1,
                },
            });

            dispatch({
                type: ALL_MEMBER_INHOUSE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_MEMBER_INHOUSE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get selected invited user of project
 *
 * @param number params.page - page of list
 * @return Promise<void>
 */
export const getDetailMemberInvited = (params: {
    project_id: string | undefined;
    id: string | undefined;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_MEMBER_INVITED_REQUEST });
        try {
            const res = await axiosClient.get('project/members', {
                params: {
                    ...params,
                    invited: 1,
                },
            });

            dispatch({
                type: DETAIL_MEMBER_INVITED_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DETAIL_MEMBER_INVITED_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get selected inhouse user of project
 *
 * @param number params.page - page of list
 * @return Promise<void>
 */
export const getDetailMemberInhouse = (params: {
    project_id: string | undefined;
    id: string | undefined;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_MEMBER_INHOUSE_REQUEST });
        try {
            const res = await axiosClient.get('project/members', {
                params: {
                    ...params,
                    inhouse: 1,
                },
            });

            dispatch({
                type: DETAIL_MEMBER_INHOUSE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DETAIL_MEMBER_INHOUSE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to create bug report
 *
 * @param number params.page - page of list
 * @return Promise<void>
 */
export const createBugReport = (formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CREATE_BUG_REPORT_REQUEST });
        try {
            const res = await axiosClient.post('bug/report', formData);

            dispatch({
                type: CREATE_BUG_REPORT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: CREATE_BUG_REPORT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function for check user email are exist, not registered, or registered
 *
 * @param string email - email of user
 */
export const checkUserEmail = (email: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CHECK_USER_EMAIL_REQUEST });
        try {
            const res = await axiosClient.get('company/inhouse/users/anonymous', {
                params: {
                    email,
                },
            });

            dispatch({
                type: CHECK_USER_EMAIL_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: CHECK_USER_EMAIL_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to resend invitation
 *
 * @param string project_id - id of project
 * @param string email - email of user
 * @return Promise<void>
 */
export const resendProjectInvitation = (project_id: string, email: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESEND_PROJECT_INVITATION_REQUEST });
        try {
            const res = await axiosClient.put(
                'project/members/invitations/resend',
                null,
                {
                    params: {
                        project_id,
                        email,
                    },
                },
            );

            dispatch({
                type: RESEND_PROJECT_INVITATION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: RESEND_PROJECT_INVITATION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to cancel invitation
 *
 * @param string project_id - id of project
 * @param string email - email of user
 * @return Promise<void>
 */
export const cancelProjectInvitation = (project_id: string, email: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CANCEL_PROJECT_INVITATION_REQUEST });
        try {
            const res = await axiosClient.delete('project/members/invitations/cancel', {
                params: {
                    project_id,
                    email,
                },
            });

            dispatch({
                type: CANCEL_PROJECT_INVITATION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: CANCEL_PROJECT_INVITATION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to clear state of project
 *
 * @returns Promise<void>
 */
export const clearStateProject = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CLEAR_STATE });
    };
};

export const clearDetailProject = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_PROJECT_RESET });
    };
};

export const clearStateCreateProject = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CREATE_PROJECT_RESET });
    };
};

export const clearStateUpdateProject = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_PROJECT_RESET });
    };
};

export const clearStateInviteProjectMember = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: INVITE_PROJECT_MEMBER_RESET });
    };
};

export const clearStatePinUnpinProject = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: PIN_PROJECT_RESET });
        dispatch({ type: UNPIN_PROJECT_RESET });
    };
};

export const clearStateLeaveProject = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: LEAVE_PROJECT_RESET });
    };
};

export const clearStateDetailMemberInvited = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_MEMBER_INVITED_RESET });
    };
};

export const clearStateDetailMemberInhouse = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_MEMBER_INHOUSE_RESET });
    };
};

export const clearStateCreateBugReport = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CREATE_BUG_REPORT_RESET });
    };
};

export const clearStateCheckUserEmail = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CHECK_USER_EMAIL_RESET });
    };
};

export const clearStateProjectResendCancelInvitation = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESEND_PROJECT_INVITATION_RESET });
        dispatch({ type: CANCEL_PROJECT_INVITATION_RESET });
    };
};
