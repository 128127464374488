import documentsImage9 from '@/assets/images/document-drawing/help-documents-drawings-image-9.webp';
import documentsImage10 from '@/assets/images/document-drawing/help-documents-drawings-image-10.webp';

import { Fragment } from 'react';

export default function HowToPreviewFile(): JSX.Element {
    const contents = [
        {
            id: 1,
            text: 'To preview you can double click in file or click see details in file options.',
            alt: 'documentsFolderPage',
            image: documentsImage9,
        },
        {
            id: 2,
            text: 'After that you can see the preview of the file. You can also download the file by clicking the download button.',
            alt: 'documentsFolderPage',
            image: documentsImage10,
        },
        // {
        //   id: 3,
        //   text: "To assign the task to other user, you must create the sub task, then you can type the email of the user that you want to assign the task.",
        //   alt: "schedulePage",
        //   image: scheduleImage3,
        // },
    ];

    return (
        <Fragment>
            {contents.map((content, index) => (
                <div key={index} className="">
                    {content.image.length > 0 && (
                        <img
                            src={content.image}
                            alt={content.alt}
                            className="img-fluid"
                        />
                    )}
                    <p className="mt-4 pl-2">{content.text}</p>
                </div>
            ))}
        </Fragment>
    );
}
