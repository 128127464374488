import PhoneInput from 'react-phone-number-input';
import { FaStarOfLife } from 'react-icons/fa';

import 'react-phone-number-input/style.css';

import styled from 'styled-components';

interface InputPhoneProps {
    id?: string;
    placeholder?: string;
    outerClassName?: string;
    isRequired?: boolean;
    disabled?: boolean;
    defaultValue?: number;
    country?: string;
    rules?: {
        function: (name: any, rules: any) => any;
        name: string;
        rules: any;
        errors?: any;
    };
    onChange?: (value: any) => void;
    value?: any;
    customRef?: any;
}

export default function InputPhone(props: InputPhoneProps): JSX.Element {
    const {
        id,
        placeholder,
        outerClassName,
        isRequired,
        country,
        rules,
        disabled,
        onChange,
        value,
        customRef,
    } = props;

    return (
        <>
            <div className={`input-group ${outerClassName ?? ''}`} ref={customRef}>
                <InputWrapper
                    className="form-floating"
                    errors={rules?.errors[rules?.name] !== undefined}
                >
                    <PhoneNumberInput
                        id={id}
                        // placeholder={placeholder}
                        className="form-control"
                        defaultCountry={country}
                        international
                        value={value}
                        onChange={onChange}
                        required={isRequired}
                        disabled={disabled}
                        // with search
                        {...rules?.function(rules?.name, rules?.rules)}
                        errors={rules?.errors[rules?.name]}
                    />
                    <Label
                        htmlFor={`htmlFor${id ?? 'input'}`}
                        errors={rules?.errors[rules?.name] !== undefined}
                        className="label-active"
                    >
                        {placeholder}
                        {(isRequired ?? false) && (
                            <FaStarOfLife
                                color="#FB6056"
                                size={7}
                                style={{
                                    position: 'relative',
                                    top: -6,
                                    left: 3,
                                }}
                            />
                        )}
                    </Label>
                    {rules?.errors[rules?.name] !== undefined && (
                        <span
                            className="invalid-feedback d-block text-start"
                            style={{
                                fontSize: '0.75rem',
                            }}
                        >
                            {rules?.errors[rules?.name].message ?? 'Invalid'}
                        </span>
                    )}
                </InputWrapper>
            </div>
        </>
    );
}

const InputWrapper = styled.div<{ errors?: boolean }>`
    .PhoneInputInput {
        border: none;
        background-color: transparent;
        border-left: 1px solid #cfcfcf;
        padding: 7px;
    }

    .PhoneInput {
        display: flex;
    }

    .PhoneInput--focus {
        border-bottom: 1px solid
            ${props => (props.errors ?? false ? '#FB6056' : '#5648fb')};
        box-shadow: none !important;
    }

    &:focus-within {
        .icon-active {
            background-color: ${props => (props.errors ?? false ? '#FB6056' : '#5648fb')};
        }

        .label-active {
            color: ${props => (props.errors ?? false ? '#FB6056' : '#5648fb')};
        }
    }

    .PhoneInputInput:focus-visible {
        outline: none;
    }

    &:focus-within {
        .PhoneInputCountryIcon {
            color: ${props => (props.errors ?? false ? '#FB6056' : '#5648fb')};
        }
    }
`;

const Label = styled.label<{ errors?: boolean }>`
    padding-left: 60px !important;
    color: #838383;
    top: -2px !important;

    .form-control:focus + & {
        top: -10px !important;
        padding-left: 70px !important;
        font-size: 12px !important;
    }

    .form-control:not(:placeholder-shown) + & {
        top: -10px !important;
        font-size: 12px !important;
        padding-left: 70px !important;
    }
`;

const PhoneNumberInput = styled(PhoneInput)<{ errors?: boolean }>`
    border: none;
    border-bottom: 1px solid #cfcfcf;
    background-color: transparent;
    border-radius: 0;
    margin-top: 10px;
    padding: 0 0 10px 8px !important;
    height: 45px !important;

    &:focus {
        box-shadow: none;
        border-bottom: 1px solid
            ${props => (props.errors ?? false ? '#FB6056' : '#5648fb')};
        background-color: transparent;

        .icon-content {
            background-color: ${props => (props.errors ?? false ? '#FB6056' : '#5648fb')};
        }
    }
`;
