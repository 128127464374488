import { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';

import ManageProjectInhouse from '../../components/ManageUser/ManageProjectInhouse';
import ManageProjectInvited from '../../components/ManageUser/ManageProjectInvited';
import PendingInvitation from '../../components/ManageUser/PendingInvitation';
import InviteMember from '../../components/ManageUser/InviteMember';

import styled from 'styled-components';

// import { checkRoles } from '@/utility/Utils';
import { currentProjectAccess } from '@/utility/Utils';
import { useAppDispatch, useAppSelector } from '@/redux/hook';
import {
    resendProjectInvitation,
    cancelProjectInvitation,
    clearStateProjectResendCancelInvitation,
} from '@/routes/Dashboard/Project/redux/actions';
import { RootState } from '@/redux/store';
import { useParams } from 'react-router-dom';
import {
    clearStateUpdateMemberPermission,
    getAllPendingInvitation,
} from '../../redux/actions';

export default function ManageUser(): JSX.Element {
    const projectAccess = currentProjectAccess();
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const projectId: string = id ?? '';

    const summaryAccess = projectAccess?.summary;

    const resendProjectInvitationState = useAppSelector(
        (state: RootState) => state.resendProjectInvitation,
    );
    const cancelProjectInvitationState = useAppSelector(
        (state: RootState) => state.cancelProjectInvitation,
    );

    const updateMemberPermissionState = useAppSelector(
        (state: RootState) => state.updateMemberPermission,
    );

    const [modal, setModal] = useState({
        success: false,
        invite: false,
        successDelete: false,
        resend: false,
        cancel: false,
        successUpdate: false,
    });

    const [modalType, setModalType] = useState({
        email: '',
        type: '',
    });

    const params = {
        project_id: id,
        page: 1,
        per_page: 10,
        search: '',
        sort_by: '',
        sort_asc: 1,
    };

    useEffect(() => {
        if (resendProjectInvitationState?.status === 200) {
            setModal(prev => ({ ...prev, resend: false }));
            setModalType(prev => ({ ...prev, type: 'resend invitation' }));
            dispatch(getAllPendingInvitation(params));

            setTimeout(() => {
                setModal(prev => ({ ...prev, success: true }));
                dispatch(clearStateProjectResendCancelInvitation());
            }, 1000);
        }
    }, [resendProjectInvitationState]);

    useEffect(() => {
        if (cancelProjectInvitationState?.status === 200) {
            setModal(prev => ({ ...prev, cancel: false }));
            setModalType(prev => ({ ...prev, type: 'cancel invitation' }));
            dispatch(getAllPendingInvitation(params));

            setTimeout(() => {
                setModal(prev => ({ ...prev, success: true }));
                dispatch(clearStateProjectResendCancelInvitation());
            }, 1000);
        }
    }, [cancelProjectInvitationState]);

    useEffect(() => {
        if (updateMemberPermissionState?.status === 200) {
            setModal(prev => ({ ...prev, successUpdate: true }));

            setTimeout(() => {
                dispatch(clearStateUpdateMemberPermission());
            }, 1000);
        }
    }, [updateMemberPermissionState]);

    /**
     * Function to resend invitation
     *
     * @param email string
     * @returns void
     */
    const handleResendInvitation = (email: string): void => {
        dispatch(resendProjectInvitation(projectId, email));
    };

    /**
     * Function to cancel invitation
     *
     * @param email string
     * @returns void
     */
    const handleCancelInvitation = (email: string): void => {
        dispatch(cancelProjectInvitation(projectId, email));
    };

    return (
        <>
            <WrapperTabs
                defaultActiveKey="manageProjectInvited"
                id="projectNav"
                className="border-0"
            >
                <Tab eventKey="manageProjectInvited" title="Invited User">
                    <ManageProjectInvited modal={modal} setModal={setModal} />
                </Tab>

                <Tab eventKey="manageProjectInhouse" title="In-House User">
                    <ManageProjectInhouse modal={modal} setModal={setModal} />
                </Tab>

                {summaryAccess?.invite && (
                    <Tab eventKey="pendingUser" title="Pending Invitation">
                        <PendingInvitation
                            setModal={setModal}
                            setModalType={setModalType}
                        />
                    </Tab>
                )}
            </WrapperTabs>

            <InviteMember
                modal={modal.invite}
                setModal={setModal}
                setModalType={setModalType}
            />

            <ModalDashboard
                modal={modal.success}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body={`Successfully ${modalType.type}`}
                onConfirm={() => {
                    setModal({ ...modal, success: false });
                }}
            />
            <ModalDashboard
                modal={modal.successDelete}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body="Successfully delete user from project"
                onConfirm={() => {
                    setModal({ ...modal, successDelete: false });
                }}
            />
            <ModalDashboard
                modal={modal.successUpdate}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body="Successfully update user permission"
                onConfirm={() => {
                    setModal({ ...modal, successUpdate: false });
                }}
            />
            <ModalDashboard
                modal={modal.resend}
                setModal={setModal}
                variant="info"
                type="resend"
                title="Resend Invitation?"
                body={`Are you sure want to resend invitation to ${modalType.email}?`}
                withCancel
                onConfirm={() => handleResendInvitation(modalType.email)}
                loading={resendProjectInvitationState?.loading}
            />
            <ModalDashboard
                modal={modal.cancel}
                setModal={setModal}
                variant="danger"
                type="cancel"
                title="Cancel Invitation?"
                body={`Are you sure want to cancel invitation on ${modalType.email}?`}
                withCancel
                onConfirm={() => handleCancelInvitation(modalType.email)}
                loading={cancelProjectInvitationState?.loading}
            />
        </>
    );
}

const WrapperTabs = styled(Tabs)`
    .nav-item {
        padding: 1rem;
    }

    .nav-link {
        border-radius: 20px;
        background: rgba(86, 72, 251, 0.5);
        color: #fff !important;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        padding: 0.2rem 1rem;

        &.active {
            color: #fff !important;
            background-color: #5648fb;
        }
    }
`;
