import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';

import { TitleWithBack } from '@/components/molecules/Typography';

import styled from 'styled-components';

import { RootState } from '@/redux/store';

import Histories from '../components/Histories';

import { getAllProjectHistory } from '../redux/actions';
import { useParams } from 'react-router-dom';
import { currentProjectAccess } from '@/utility/Utils';

interface SwitchTabPayload {
    project_id: string | undefined;
    page: number;
    per_page: number;
    search: string;
    summary: number;
    schedule: number;
    drawing: number;
    document: number;
    invited_user: number;
    inhouse_user: number;
}

export default function History(): JSX.Element {
    const dispatch = useDispatch();

    const { id } = useParams();

    const detailProjectState = useSelector((state: RootState) => state.detailProject);
    const projectAccess = currentProjectAccess();

    const summaryAccess = projectAccess?.summary;
    const scheduleAccess = projectAccess?.schedule;
    const drawingAccess = projectAccess?.drawing;
    const documentAccess = projectAccess?.document;

    const [params, setParams] = useState({
        project_id: id,
        page: 1,
        per_page: 10,
        search: '',
        summary: 1,
        schedule: 0,
        drawing: 0,
        document: 0,
        invited_user: 1,
        inhouse_user: 1,
    });

    const [searchTerm, setSearchTerm] = useState('');

    const listTab: Array<{
        key: string;
        title: string;
        permission: boolean;
    }> = [
        {
            key: 'summary',
            title: 'Summary',
            permission: !summaryAccess?.collaborate && !summaryAccess?.view,
        },
        {
            key: 'schedule',
            title: 'Schedule',
            // TODO:SCHEDULE PERMISSION
            permission:
                scheduleAccess?.design?.can_collaborate_schedule === false &&
                scheduleAccess?.design?.can_view_schedule === false &&
                scheduleAccess?.construction?.can_collaborate_schedule === false &&
                scheduleAccess?.construction?.can_view_schedule === false,
        },
        {
            key: 'drawing',
            title: 'Drawings',
            permission: !drawingAccess?.collaborate && !drawingAccess?.view,
        },
        {
            key: 'document',
            title: 'Documents',
            permission: !documentAccess?.collaborate && !documentAccess?.view,
        },
    ];

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setParams({
                ...params,
                search: searchTerm,
                page: 1,
            });
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    useEffect(() => {
        dispatch(getAllProjectHistory(params));
    }, [params]);

    /**
     * Function for handle search
     *
     * @param event React.ChangeEvent<HTMLInputElement>
     * @returns void
     */
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchTerm(event.target.value);
    };

    /**
     * Function for handle pagination
     *
     * @param number page
     * @param string type
     * @returns void
     */
    const handlePagination = (page: number, type: string): void => {
        let newPage = params.page;

        if (type === 'next') {
            newPage = params.page + 1;
        }

        if (type === 'prev') {
            newPage = params.page - 1;
        }

        if (type === 'page') {
            newPage = page;
        }

        const payload = {
            ...params,
            page: newPage,
        };

        setParams(payload);
        dispatch(getAllProjectHistory(payload));
    };

    /**
     * Function for handle check filter
     *
     * @param string name
     * @param boolean value
     * @returns void
     */
    const handleCheckFilter = (name: string, value: boolean): void => {
        setParams({
            ...params,
            [name]: value ? 1 : 0,
            page: 1,
        });

        const payload = {
            ...params,
            [name]: value ? 1 : 0,
            page: 1,
        };

        dispatch(getAllProjectHistory(payload));
    };

    /**
     * Function for handle switch tab
     *
     * @param string key
     * @returns void
     */
    const handleSwitchTab = (key: string): void => {
        const payload: SwitchTabPayload = {
            project_id: id,
            page: 1,
            per_page: 10,
            search: '',
            summary: 0,
            schedule: 0,
            drawing: 0,
            document: 0,
            invited_user: 1,
            inhouse_user: 1,
        };

        if (key === 'summary') {
            payload.summary = 1;
        }

        if (key === 'schedule') {
            payload.schedule = 1;
        }

        if (key === 'drawing') {
            payload.drawing = 1;
        }

        if (key === 'document') {
            payload.document = 1;
        }

        setParams({
            ...params,
            ...payload,
        });

        dispatch(getAllProjectHistory(payload));
    };

    return (
        <Fragment>
            <TitleWithBack
                text={`All History (${detailProjectState?.response?.name as string})`}
            />
            <WrapperTabs
                defaultActiveKey="summary"
                id="nav"
                className="my-5"
                onSelect={selectedKey => handleSwitchTab(selectedKey as string)}
            >
                {listTab.map(
                    (item, index) =>
                        !item?.permission && (
                            <Tab key={index} eventKey={item.key} title={item.title}>
                                <Histories
                                    handlePagination={handlePagination}
                                    params={params}
                                    handleCheckFilter={handleCheckFilter}
                                    handleSearch={handleSearch}
                                />
                            </Tab>
                        ),
                )}
            </WrapperTabs>
        </Fragment>
    );
}

const WrapperTabs = styled(Tabs)`
    .nav-link {
        border: none;
        background: none !important;
        color: rgba(207, 207, 207, 1);
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        padding-left: 0;
        padding-right: 0;
        padding: 1rem 1.5rem;
        &.active {
            color: #5648fb !important;
            border-bottom: 2px solid #5648fb;
        }
    }
`;
