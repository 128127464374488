/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Router from '@/routes/Router';

import '@/App.scss';
import 'react-slideshow-image/dist/styles.css';
import 'react-phone-number-input/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import { ToastContainer } from 'react-toastify';
import { useState, useEffect } from 'react';
import { CloseButton } from 'react-bootstrap';
import { browserName } from 'react-device-detect';
import { Link } from 'react-router-dom';
import ButtonBasic from './components/atoms/Buttons/ButtonBasic';

import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import { getCredential, getId } from './utility/Utils';
import styled from 'styled-components';

function App(): JSX.Element {
    const [isBrowserCheck, setIsBrowserCheck] = useState(false);
    const [permissionStatus, setPermissionStatus] = useState('');
    const [isPermissionCookies, setIsPermissionCookies] = useState(true);

    const ipState = useSelector((state: RootState) => state.ip);
    const userCredentialsState = getCredential();

    // Function to get wallpaper landing on mount
    useEffect(() => {
        // Check if browser is not chrome
        if (browserName !== 'Chrome' && browserName !== 'Firefox') {
            setIsBrowserCheck(true);
        }

        const getCookiesPermission = Cookies.get('user_permissions_experience');

        if (getCookiesPermission !== undefined) {
            setIsPermissionCookies(false);
        }

        return () => {
            setIsBrowserCheck(false);
        };
    }, []);

    // Check if the browser supports notifications
    useEffect(() => {
        void checkPermissionStatus();

        // clear all notifications when the user closes the browser
        return () => {
            window.removeEventListener(
                'notificationpermissionchange',
                handlePermissionChange,
            );
        };
    }, []);

    const checkPermissionStatus = async (): Promise<void> => {
        try {
            const permission = await Notification.requestPermission();
            setPermissionStatus(permission);

            // listen for changes to the permission
            window.addEventListener(
                'notificationpermissionchange',
                handlePermissionChange,
            );
        } catch (error) {}
    };

    /**
     * Function to handle permission change
     *
     * @returns void
     */
    const handlePermissionChange = (): void => {
        void checkPermissionStatus();
    };

    /**
     * Function to handle allow permission
     *
     * @returns void
     */
    const handleAllowPermission = async (): Promise<void> => {
        try {
            const permission = await Notification.requestPermission();
            setPermissionStatus(permission);
        } catch (error) {}
    };

    /**
     * Function to handle accept cookies
     *
     * @returns void
     */
    const handleAcceptCookies = (): void => {
        const data = {
            accepted: true,
            ip: ipState?.response?.ipString,
            userId: getId(),
        };

        Cookies.set('user_permissions_experience', JSON.stringify(data), {
            sameSite: 'None',
            secure: true,
        });
        setIsPermissionCookies(false);
    };

    /**
     * Function to handle decline cookies
     *
     * @returns void
     */
    const handleDeclineCookies = (): void => {
        const data = {
            accepted: false,
        };

        Cookies.set('user_permissions_experience', JSON.stringify(data), {
            sameSite: 'None',
            secure: true,
        });
        setIsPermissionCookies(false);
    };
    return (
        <div className="App">
            {/* Permission notification */}
            {permissionStatus === 'default' && (
                <div
                    className="w-100 bg-danger text-white py-2 px-3 d-none d-md-block"
                    style={{
                        fontSize: '0.875em',
                    }}
                >
                    <div className="d-flex text-center justify-content-between align-items-center">
                        <div className="w-100 text-center">
                            <small className="">
                                Please allow notification to get notification{' '}
                            </small>
                            <Link
                                to="#"
                                onClick={handleAllowPermission}
                                className="text-white text-decoration-none"
                            >
                                <b>Allow notification</b>
                            </Link>
                        </div>
                        <CloseButton
                            variant="white"
                            className=""
                            onClick={() => setPermissionStatus('')}
                        />
                    </div>
                </div>
            )}

            {/* Browser check */}
            {isBrowserCheck && (
                <div
                    className="w-100 bg-info text-white py-2 px-3 d-none d-md-block"
                    style={{
                        fontSize: '0.875em',
                    }}
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <small className="w-100 text-center">
                            You are using {browserName} browser. For better experience,
                            please use <b>Google Chrome </b>
                            or <b>Firefox</b>
                        </small>
                        <CloseButton
                            variant="white"
                            className=""
                            onClick={() => setIsBrowserCheck(false)}
                        />
                    </div>
                </div>
            )}
            <Router />
            <ToastContainer />

            {/* Cookies */}
            {isPermissionCookies && userCredentialsState !== null && (
                <CookiesWrapper className="position-fixed bottom-0 rounded-2 bg-white text-white pb-4 pt-2 px-4">
                    <div className="w-100 text-end">
                        <CloseButton className="" onClick={handleDeclineCookies} />
                    </div>
                    <div className="d-flex flex-column">
                        <p className="text-center text-dark">
                            This website uses cookies to ensure you get the best
                            experience on our website.
                        </p>
                        <p className="text-center text-dark">
                            Are you okay with our use of cookies?
                        </p>

                        <ButtonBasic
                            text={'Accept all'}
                            className="btn-outline-success"
                            onClick={handleAcceptCookies}
                        />
                    </div>
                </CookiesWrapper>
            )}
        </div>
    );
}

const CookiesWrapper = styled.div`
    font-size: 0.875em;
    z-index: 9999;
    transform: translateX(50%);
    width: 50vw;

    @media (max-width: 657px) {
        width: 100%;
        transform: translateX(0%);
        right: 0;
    }

    @media (min-width: 768px) {
        width: 400px;
        transform: translateX(85%);
    }

    @media (min-width: 1200px) {
        width: 400px;
        transform: translateX(0%);
        left: 50%;
    }
`;

export default App;
