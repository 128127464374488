import WhatIsDirectory from './Sub/Directory/WhatIsDirectory';

import AccordionBasic from '@/components/molecules/AccordionBasic';

export default function HelpDirectory(): JSX.Element {
    const lists = [
        {
            id: 'whatIsDirectory',
            title: 'What is Directory',
            content: <WhatIsDirectory />,
        },
    ];

    return <AccordionBasic lists={lists} />;
}
