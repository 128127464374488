import styled from 'styled-components';
import OtpInput from 'react-otp-input';
interface InputOtpProps {
    value: string;
    length: number;
    onChange: (value: string) => void;
    inputStyle?: string;
    containerStyle?: string;
}

/**
 * Input OTP with validation boilerplate.
 *
 * @param value string
 * @param length number
 * @param onChange (value: string) => void
 * @param inputStyle string
 * @param containerStyle string
 * @returns JSX.Element
 */
export default function InputOtp({
    value,
    length,
    onChange,
    inputStyle,
    containerStyle,
}: InputOtpProps): JSX.Element {
    return (
        <OtpForm
            value={value}
            onChange={(value: string): void => onChange(value)}
            numInputs={length}
            inputStyle={inputStyle}
            containerStyle={containerStyle}
            isInputNum
        />
    );
}

const OtpForm = styled(OtpInput)`
    .otp-input {
        width: 5rem;
        height: 3rem;
        border-radius: 10px;
        text-align: center;
        font-size: 32px;
        border: 1px solid #838383;
    }

    .otp-input:focus {
        outline: none;
        border: 1px solid #5648fb;
        box-shadow: none;
    }
`;
