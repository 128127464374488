import { axiosClient } from '@/services/GatewayService';
import { AppDispatch } from '@/redux/store';

import {
    NOTIFICATION_SETTING_REQUEST,
    NOTIFICATION_SETTING_SUCCESS,
    NOTIFICATION_SETTING_FAIL,
    UPDATE_NOTIFICATION_SETTING_REQUEST,
    UPDATE_NOTIFICATION_SETTING_SUCCESS,
    UPDATE_NOTIFICATION_SETTING_FAIL,
    UPDATE_NOTIFICATION_SETTING_RESET,
    CLEAR_STATE,
} from '../reducers';

/**
 * Function to get of notification setting
 *
 * @return Promise<void>
 */
export const getNotificationSetting = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: NOTIFICATION_SETTING_REQUEST });
        try {
            const res = await axiosClient.get('user/notifications/settings/mapped');

            dispatch({
                type: NOTIFICATION_SETTING_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: NOTIFICATION_SETTING_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to update of company setting
 *
 * @return Promise<void>
 */
export const updateNotificationSetting = (params: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_NOTIFICATION_SETTING_REQUEST });
        try {
            const res = await axiosClient.post(
                'user/notifications/settings/mapped',
                params,
            );

            dispatch({
                type: UPDATE_NOTIFICATION_SETTING_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UPDATE_NOTIFICATION_SETTING_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to clear state of update notification
 *
 * @returns Promise<void>
 */
export const clearUpdateNotificationSetting = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_NOTIFICATION_SETTING_RESET });
    };
};

export const clearStateNotificatioNSetting = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CLEAR_STATE });
    };
};
