/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Dropdown, Form, Table } from 'react-bootstrap';

import {
    CustomDropdown,
    CustomDropdownMenu,
    RightClick,
    TableHeader,
    TableWrapper,
} from './styles';
import { HiDotsVertical } from 'react-icons/hi';

import uuid from 'react-uuid';
import { AiOutlineFileDone } from 'react-icons/ai';

interface ActionProps {
    CellText: any;
    rowKey: any;
    rowValue: any;
    type: string;
    text: string;
    onClick: (row: any) => void;
    variant?: string;
    Cell?: any;
    only?: any;
    url?: string;
}
export default function TableView(props: {
    header: Array<{
        selector?: string;
        Cell?: (props: any, index: any) => ReactNode;
        title: string;
        withAction?: boolean;
        only?: (row: any) => any;
        className?: string;
        actions?: Array<{
            only: (arg0: any) => unknown;
            text?: string;
            CellText?: (props: any) => ReactNode;
            type?: string;
            link?: string;
            onClick?: (props: any) => void;
            variant?: string;
        }>;
    }>;
    moduleType: string;
    withoutCheckbox?: boolean;
    handleCheckAll: (value: boolean) => void;
    handleCheck: (id: number, checked: boolean) => void;
    handleCheckRow: (id: number) => void;
    renderActions: (action: ActionProps, row: any) => void;
    renderContent: (col: any, row: any, index: number) => JSX.Element;
    disableClickRow?: boolean;
    loading?: boolean;
    renderIcons?: (type: string) => ReactNode;
    withRightClick?: boolean;
    hiddenCheckbox?: boolean;
    dataTemp: any[];
    singleSelected?: boolean;
}): JSX.Element {
    const {
        header,
        moduleType,
        withoutCheckbox,
        handleCheckAll,
        handleCheck,
        handleCheckRow,
        renderActions,
        renderContent,
        disableClickRow,
        loading,
        renderIcons,
        withRightClick,
        hiddenCheckbox,
        dataTemp,
        singleSelected,
    } = props;

    const [selectedRow, setSelectedRow] = useState<number>(0);
    const [clickedCell, setClickedCell] = useState<{
        rowIndex: number | null;
        colIndex: number | null;
    }>({
        rowIndex: null,
        colIndex: null,
    });

    const [outsideClick, setOutsideClick] = useState<boolean>(false);

    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const menuRef = useRef<HTMLDivElement>(null);

    // Function to handle click outside of menu
    useEffect(() => {
        function handleClickOutside(event: any): void {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setClickedCell({ rowIndex: null, colIndex: null });
                setOutsideClick(false);
                setSelectedRow(0);
            }
        }

        document.body.addEventListener('click', handleClickOutside);

        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, []);

    /**
     * Function to handle right click on cell
     *
     * @param event
     * @param rowIndex
     * @param colIndex
     */
    // const handleCellRightClick = (
    //     event: {
    //         preventDefault: () => void;
    //         clientX: number;
    //         clientY: number;
    //     },
    //     rowIndex: number,
    //     colIndex: number,
    // ): void => {
    //     event.preventDefault();

    //     setClickedCell({ rowIndex, colIndex });
    //     setMenuPosition({ x: event.clientX, y: event.clientY });
    // };

    // const handleContextMenu = (
    //     event: any,
    //     index: number,
    //     key: number,
    //     row: {
    //         id: number;
    //         prev: string;
    //     },
    // ): void => {
    //     if (row.prev === undefined && (withRightClick ?? false)) {
    //         handleCellRightClick(event, index, key);
    //         setSelectedRow(row.id);
    //     }
    // };

    const handleOutsideContextMenu = (event: {
        preventDefault: () => void;
        clientX: number;
        clientY: number;
    }): void => {
        event.preventDefault();

        setOutsideClick(true);
        setMenuPosition({ x: event.clientX, y: event.clientY });
    };

    /**
     * Function for click right and open a menu
     *
     * @param row
     * @returns void
     */
    const handleRowClick = (row: { id: number; isChecked: boolean }): void => {
        if (!(disableClickRow ?? false) && withoutCheckbox !== true) {
            handleCheckRow(row.id);
        }
    };

    return (
        <TableWrapper className="h-100">
            <Table hover responsive id={`table${moduleType.replace(' ', '')}`}>
                <TableHeader>
                    <tr>
                        {withoutCheckbox !== true &&
                            (!(hiddenCheckbox ?? false) ?? true) && (
                                <th
                                    className="ps-4"
                                    style={{
                                        width: '2.5rem',
                                    }}
                                >
                                    <Form.Check
                                        type="checkbox"
                                        id="checkboxAll"
                                        onChange={(e: any) =>
                                            handleCheckAll(e?.target?.checked)
                                        }
                                        checked={
                                            dataTemp.find(
                                                (item: any) => item.isChecked === false,
                                            ) === undefined
                                        }
                                    />
                                </th>
                            )}
                        {header.map((item, index) => (
                            <th
                                key={uuid()}
                                className={`fw-semibold ${item.className ?? ''}`}
                                style={
                                    withoutCheckbox !== false
                                        ? {
                                              paddingLeft: 25,
                                          }
                                        : {
                                              paddingLeft: 0,
                                          }
                                }
                            >
                                {item.title}
                            </th>
                        ))}
                    </tr>
                </TableHeader>
                <tbody>
                    {dataTemp.map(
                        (
                            row: {
                                id: number;
                                isChecked: boolean;
                                name: string;
                                prev: string;
                                className: string;
                            },
                            index,
                        ) => (
                            <tr
                                key={uuid()}
                                onClick={() => setSelectedRow(row.id)}
                                style={{
                                    backgroundColor:
                                        row?.isChecked && singleSelected !== true
                                            ? '#cecece'
                                            : '#ffff',
                                    cursor:
                                        withoutCheckbox !== true
                                            ? loading === true
                                                ? 'progress'
                                                : 'pointer'
                                            : 'default',
                                }}
                            >
                                {withoutCheckbox !== true &&
                                    (!(hiddenCheckbox ?? false) ?? true) && (
                                        <td
                                            className={`ps-4 ${row?.className ?? ''}`}
                                            style={{
                                                width: '2.5rem',
                                            }}
                                        >
                                            {row.prev === undefined && (
                                                <Form.Check
                                                    type="checkbox"
                                                    id={`checkbox_${row.id}`}
                                                    checked={row?.isChecked}
                                                    onChange={(e: any) =>
                                                        handleCheck(
                                                            row.id,
                                                            e?.target?.checked,
                                                        )
                                                    }
                                                />
                                            )}
                                        </td>
                                    )}
                                {header.map((col, key) =>
                                    col?.withAction != null && col?.actions != null ? (
                                        <td key={uuid()} className={col?.className ?? ''}>
                                            {row.prev === undefined && (
                                                <Dropdown
                                                    className={
                                                        col?.only != null &&
                                                        col?.only(row) === false
                                                            ? 'd-none'
                                                            : ''
                                                    }
                                                    drop="down-centered"
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <CustomDropdown
                                                        variant="none"
                                                        id="dropdown-autoclose-true"
                                                    >
                                                        <HiDotsVertical size={14} />
                                                    </CustomDropdown>

                                                    <CustomDropdownMenu>
                                                        {col.actions.map(e =>
                                                            renderActions(e as any, row),
                                                        )}
                                                    </CustomDropdownMenu>
                                                </Dropdown>
                                            )}
                                        </td>
                                    ) : (
                                        <td
                                            key={uuid()}
                                            onClick={() => handleRowClick(row)}
                                            // onContextMenu={event =>
                                            //     handleContextMenu(event, index, key, row)
                                            // }
                                            className={col?.className ?? ''}
                                            style={
                                                withoutCheckbox !== false
                                                    ? {
                                                          paddingLeft: 25,
                                                          userSelect: 'none',
                                                          WebkitUserSelect: 'none',
                                                          MozUserSelect: 'none',
                                                      }
                                                    : {
                                                          paddingLeft: 0,
                                                      }
                                            }
                                        >
                                            {renderContent(col, row, index)}
                                        </td>
                                    ),
                                )}
                            </tr>
                        ),
                    )}
                </tbody>
            </Table>
            <div
            // onContextMenu={handleOutsideContextMenu} className="h-100"
            >
                {outsideClick && (
                    <RightClick
                        ref={menuRef}
                        style={{
                            position: 'absolute',
                            top: menuPosition.y - 100,
                            left: menuPosition.x - 100,
                        }}
                        className="d-flex flex-column"
                    >
                        <div className={'button'}>
                            <AiOutlineFileDone className="react-icon me-2" size={18} />
                            <span className="">Paste</span>
                        </div>
                    </RightClick>
                )}
            </div>
            {clickedCell.rowIndex !== null && clickedCell.colIndex !== null && (
                <RightClick
                    ref={menuRef}
                    style={{
                        position: 'absolute',
                        top: menuPosition.y - 200,
                        left: menuPosition.x - 50,
                    }}
                    className="d-flex flex-column"
                >
                    {header
                        ?.find(item => item?.withAction)
                        ?.actions?.map(e => (
                            <div
                                key={uuid()}
                                className={`button ${
                                    // hidden button if condition is true
                                    e?.only != null &&
                                    e?.only(dataTemp[clickedCell?.rowIndex as number]) ===
                                        false
                                        ? 'd-none'
                                        : ''
                                }`}
                                onClick={e?.onClick?.bind(
                                    null,
                                    dataTemp[clickedCell?.rowIndex as number],
                                )}
                            >
                                {renderIcons?.(e?.type ?? '')}
                                <span className={`text-${e.variant ?? ''}`}>
                                    {e.text}
                                </span>
                            </div>
                        ))}
                </RightClick>
            )}
        </TableWrapper>
    );
}
