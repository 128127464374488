import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import BasicTooltip from '@/components/atoms/Tooltips';
import {
    AiOutlineReload,
    AiOutlineDelete,
    AiOutlineDownload,
    // AiOutlineShareAlt,
    AiOutlineUpload,
    AiOutlineSend,
    AiOutlineFolderAdd,
} from 'react-icons/ai';
import { BiCopy, BiCut, BiPaste } from 'react-icons/bi';
import {
    HandleActionMultiple,
    HandleActionRightClick,
    HandleModal,
    StructureProps,
} from '../interface';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';

export default function MultipleAction({
    items,
    documentAccess,
    selectedCheckbox,
    urlSpaceId,
    urlTypeId,
    contextData,
    reduxFolder,
    handleModal,
    handleActionMultiple,
    handleSetContext,
    handleActionRightClick,
}: {
    items: StructureProps[] | null;
    documentAccess: {
        collaborate: boolean;
        view: boolean;
        invite: boolean;
        post_publicly: boolean;
    };
    selectedCheckbox: string[];
    urlSpaceId: string | undefined;
    urlTypeId: string | undefined;
    contextData: {
        type: string;
        data: StructureProps[];
    };
    reduxFolder: any;
    handleModal: HandleModal;
    handleActionMultiple: HandleActionMultiple;
    handleSetContext: (type: string, rowData?: StructureProps[]) => void;
    handleActionRightClick: HandleActionRightClick;
}): JSX.Element {
    const pasteDocumentState = useSelector((state: RootState) => state.pasteDocument);

    const BtnUpload = (): JSX.Element => (
        <BasicTooltip text="Upload file">
            <ButtonBasic
                onClick={() => handleModal(false, 'upload', 'Upload File', null)}
                text={<AiOutlineUpload size={16} />}
                className="btn btn-outline-warning btn-sm px-3 me-2"
                style={{
                    paddingBottom: '0.2rem',
                    paddingTop: '0.2rem',
                }}
            />
        </BasicTooltip>
    );

    const BtnNewFolder = (): JSX.Element => (
        <BasicTooltip text="Create new folder">
            <ButtonBasic
                onClick={() => handleModal(false, 'create', 'Create New Folder', null)}
                text={<AiOutlineFolderAdd size={16} />}
                className="btn btn-outline-primary btn-sm px-3 me-2"
                style={{
                    paddingBottom: '0.2rem',
                    paddingTop: '0.2rem',
                }}
            />
        </BasicTooltip>
    );

    const BtnPaste = (): JSX.Element => (
        <BasicTooltip text="Paste">
            <ButtonBasic
                onClick={(e: any) => handleActionRightClick(e, contextData)}
                text={<BiPaste size={16} />}
                className="btn btn-outline-success btn-sm px-3 me-2"
                style={{
                    paddingBottom: '0.2rem',
                    paddingTop: '0.2rem',
                }}
            />
        </BasicTooltip>
    );

    const BtnMoveFolder = (): JSX.Element => (
        <BasicTooltip text="Cut Selected">
            <ButtonBasic
                onClick={() =>
                    handleSetContext(
                        'move folder',
                        selectedCheckbox
                            .map((uuid: string) =>
                                items?.find(item => item.uuid === uuid),
                            )
                            .filter(
                                (item: StructureProps | undefined) => item !== undefined,
                            ) as StructureProps[],
                    )
                }
                text={<BiCut size={16} />}
                className="btn btn-outline-warning btn-sm px-3 me-2"
                style={{
                    paddingBottom: '0.2rem',
                    paddingTop: '0.2rem',
                }}
            />
        </BasicTooltip>
    );

    const BtnCopyFolder = (): JSX.Element => (
        <BasicTooltip text="Copy Selected">
            <ButtonBasic
                onClick={() =>
                    handleSetContext(
                        'copy folder',
                        selectedCheckbox
                            .map((uuid: string) =>
                                items?.find(item => item.uuid === uuid),
                            )
                            .filter(
                                (item: StructureProps | undefined) => item !== undefined,
                            ) as StructureProps[],
                    )
                }
                text={<BiCopy size={16} />}
                className="btn btn-outline-info btn-sm px-3 me-2"
                style={{
                    paddingBottom: '0.2rem',
                    paddingTop: '0.2rem',
                }}
            />
        </BasicTooltip>
    );

    const BtnSend = (): JSX.Element => (
        <BasicTooltip text="Send selected">
            <ButtonBasic
                onClick={(e: any) => handleActionMultiple(e, 'send')}
                text={<AiOutlineSend size={16} />}
                className="btn btn-outline-dark btn-sm px-3 me-2"
                style={{
                    paddingBottom: '0.2rem',
                    paddingTop: '0.2rem',
                }}
            />
        </BasicTooltip>
    );

    // const BtnShare = (): JSX.Element => (
    //     <BasicTooltip text="Share selected">
    //         <ButtonBasic
    //             onClick={(e: any) => handleActionMultiple(e, 'share')}
    //             text={<AiOutlineShareAlt size={16} />}
    //             className="btn btn-outline-info btn-sm px-3 me-2"
    //             style={{
    //                 paddingBottom: '0.2rem',
    //                 paddingTop: '0.2rem',
    //             }}
    //         />
    //     </BasicTooltip>
    // );

    const BtnDelete = (): JSX.Element => (
        <BasicTooltip text="Move to Trash selected">
            <ButtonBasic
                onClick={(e: any) => handleActionMultiple(e, 'delete')}
                text={<AiOutlineDelete size={16} />}
                className="btn btn-outline-danger btn-sm px-3 me-2"
                style={{
                    paddingBottom: '0.2rem',
                    paddingTop: '0.2rem',
                }}
            />
        </BasicTooltip>
    );

    const BtnDownload = (): JSX.Element => (
        <BasicTooltip text="Download selected">
            <ButtonBasic
                onClick={(e: any) => handleActionMultiple(e, 'download')}
                text={<AiOutlineDownload size={16} />}
                className="btn btn-outline-success btn-sm px-3 me-2"
                style={{
                    paddingBottom: '0.2rem',
                    paddingTop: '0.2rem',
                }}
                loading={pasteDocumentState.loading}
            />
        </BasicTooltip>
    );

    const BtnRestore = (): JSX.Element => (
        <BasicTooltip text="Restore selected">
            <ButtonBasic
                onClick={(e: any) => handleActionMultiple(e, 'restore')}
                text={<AiOutlineReload size={16} />}
                className="btn btn-outline-info btn-sm px-3 me-2"
                style={{
                    paddingBottom: '0.2rem',
                    paddingTop: '0.2rem',
                }}
            />
        </BasicTooltip>
    );

    const BtnDeletePermanently = (): JSX.Element => (
        <BasicTooltip text="Delete Permanently">
            <ButtonBasic
                onClick={(e: any) => handleActionMultiple(e, 'delete permanent')}
                text={<AiOutlineDelete size={16} />}
                className="btn btn-outline-danger btn-sm px-3 me-2"
                style={{
                    paddingBottom: '0.2rem',
                    paddingTop: '0.2rem',
                }}
            />
        </BasicTooltip>
    );

    if (urlSpaceId !== undefined) {
        if (urlTypeId === 'public') {
            if (urlSpaceId?.includes('search=')) {
                if (selectedCheckbox.length > 0) {
                    return (
                        <div className="col-9 text-end align-self-center">
                            {/* public search multiple */}
                            <BtnCopyFolder />
                            <BtnDownload />
                            <BtnSend />
                            <BtnDelete />
                        </div>
                    );
                } else {
                    return (
                        <div className="col-9 text-end align-self-center">
                            {/* public search single */}
                        </div>
                    );
                }
            } else if (urlSpaceId === 'trash') {
                if (selectedCheckbox.length > 0) {
                    if (documentAccess?.collaborate) {
                        return (
                            <div className="col-9 text-end align-self-center">
                                {/* public trash multiple collaborator */}
                                <BtnRestore />
                                <BtnDeletePermanently />
                            </div>
                        );
                    } else {
                        return (
                            <div className="col-9 text-end align-self-center">
                                {/* public trash multiple view only */}
                            </div>
                        );
                    }
                } else {
                    if (documentAccess?.collaborate) {
                        return (
                            <div className="col-9 text-end align-self-center">
                                {/* public trash single collaborator */}
                            </div>
                        );
                    } else {
                        return (
                            <div className="col-9 text-end align-self-center">
                                {/* public trash single view only */}
                            </div>
                        );
                    }
                }
            } else {
                if (selectedCheckbox.length > 0) {
                    if (documentAccess?.collaborate) {
                        return (
                            <div className="col-9 text-end align-self-center">
                                {/* public general multiple collaborator */}
                                {documentAccess?.post_publicly && (
                                    <>
                                        {/* With post publicly */}
                                        <BtnCopyFolder />
                                        <BtnMoveFolder />
                                        {contextData?.data.length > 0 && <BtnPaste />}
                                    </>
                                )}
                                <BtnDownload />
                                <BtnSend />
                                <BtnDelete />
                            </div>
                        );
                    } else {
                        return (
                            <div className="col-9 text-end align-self-center">
                                {/* public general multiple view only */}
                                <BtnDownload />
                                <BtnSend />
                                {documentAccess?.post_publicly && (
                                    <>
                                        {/* With post publicly */}
                                        <BtnCopyFolder />
                                        <BtnMoveFolder />
                                        {contextData?.data.length > 0 && <BtnPaste />}
                                    </>
                                )}
                            </div>
                        );
                    }
                } else {
                    if (documentAccess?.collaborate) {
                        return (
                            <div className="col-9 text-end align-self-center">
                                {/* public general single collaborator */}
                                {documentAccess?.post_publicly && (
                                    <>
                                        {/* With post publicly */}
                                        <BtnUpload />
                                        <BtnNewFolder />
                                        {contextData?.data.length > 0 && <BtnPaste />}
                                    </>
                                )}
                            </div>
                        );
                    } else {
                        return (
                            <div className="col-9 text-end align-self-center">
                                {/* public general single view only */}
                                {documentAccess?.post_publicly && (
                                    <>
                                        {/* With post publicly */}
                                        <BtnUpload />
                                        <BtnNewFolder />
                                        {contextData?.data.length > 0 && <BtnPaste />}
                                    </>
                                )}
                            </div>
                        );
                    }
                }
            }
        } else if (urlTypeId === 'private') {
            if (urlSpaceId?.includes('search=')) {
                if (selectedCheckbox.length > 0) {
                    return (
                        <div className="col-9 text-end align-self-center">
                            {/* private search multiple */}
                            <BtnCopyFolder />
                            <BtnDownload />
                            <BtnSend />
                            <BtnDelete />
                        </div>
                    );
                } else {
                    return (
                        <div className="col-9 text-end align-self-center">
                            {/* private search single */}
                        </div>
                    );
                }
            } else if (urlSpaceId === 'trash') {
                if (selectedCheckbox.length > 0) {
                    return (
                        <div className="col-9 text-end align-self-center">
                            {/* private trash multiple collaborator */}
                            <BtnRestore />
                            <BtnDeletePermanently />
                        </div>
                    );
                } else {
                    return (
                        <div className="col-9 text-end align-self-center">
                            {/* private trash single collaborator */}
                        </div>
                    );
                }
            } else {
                if (selectedCheckbox.length > 0) {
                    if (
                        reduxFolder?.response?.permission?.can_collaborate_document ===
                        true
                    ) {
                        return (
                            <div className="col-9 text-end align-self-center">
                                {/* private general multiple collaborator */}
                                {/* <BtnUpload /> */}
                                <BtnCopyFolder />
                                <BtnMoveFolder />
                                {contextData?.data.length > 0 && <BtnPaste />}
                                <BtnSend />
                                {/* <BtnShare /> */}
                                <BtnDownload />
                                <BtnDelete />
                            </div>
                        );
                    } else {
                        return (
                            <div className="col-9 text-end align-self-center">
                                {/* private general multiple view only */}
                                <BtnDownload />
                                <BtnSend />
                            </div>
                        );
                    }
                } else {
                    if (
                        reduxFolder?.response?.permission?.can_collaborate_document ===
                        true
                    ) {
                        return (
                            <div className="col-9 text-end align-self-center">
                                {/* private general single collaborator */}
                                <BtnUpload />
                                <BtnNewFolder />
                                {contextData?.data.length > 0 && <BtnPaste />}
                            </div>
                        );
                    } else {
                        return (
                            <div className="col-9 text-end align-self-center">
                                {/* private general single view only */}
                            </div>
                        );
                    }
                }
            }
        }
    }

    return <div className="col-9 text-end align-self-center"></div>;
}
