import { Tab, Tabs } from 'react-bootstrap';

import styled from 'styled-components';

import ActiveInhouse from './ManageInhouse/ActiveInhouse';
import InactiveInhouse from './ManageInhouse/InactiveInhouse';

export default function ManageInhouse(): JSX.Element {
    return (
        <>
            <WrapperTabs defaultActiveKey="activeInhouse" id="nav" className="border-0">
                <Tab eventKey="activeInhouse" title="Active Inhouse">
                    <ActiveInhouse />
                </Tab>
                <Tab eventKey="inactiveInhouse" title="Terminated Inhouse">
                    <InactiveInhouse />
                </Tab>
            </WrapperTabs>
        </>
    );
}

const WrapperTabs = styled(Tabs)`
    .nav-item {
        padding: 1rem;
    }

    .nav-link {
        border-radius: 20px;
        background: rgba(86, 72, 251, 0.5);
        color: #fff !important;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        padding: 0.2rem 1rem;

        &.active {
            color: #fff !important;
            background-color: #5648fb;
        }
    }
`;
