import { Pagination, Dropdown } from 'react-bootstrap';

import CoverBackground from '@/assets/images/CoverProfile.png';

import styled, { css } from 'styled-components';

export const RightClick = styled.div`
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px;

    .button {
        cursor: pointer;
        padding: 0.3rem 0.5rem;

        &:hover {
            background-color: #edecfe;
            border-radius: 5px;
        }
    }
`;

export const TableSearch = styled.input`
    background-color: transparent;
    border: none;
    height: 2rem;
    font-family: 'Montserrat', sans-serif;

    &::placeholder {
        color: #303030;
        font-size: 14px;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }
`;

export const TableWrapper = styled.div`
    color: #303030;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;

    th,
    td {
        vertical-align: middle;
        height: 50px;
    }

    .table {
        margin-bottom: 0;
    }

    @media (min-width: 768px) and (max-width: 1173px) {
        .table-responsive {
            /* overflow-x: inherit; */
            overflow-y: inherit !important;
        }
    }

    @media (min-width: 1174px) {
        .table-responsive {
            overflow-x: inherit;
            overflow-y: inherit !important;
        }
    }

    table {
        border-collapse: separate;
        border-spacing: 0 10px;
        margin-top: -10px;
    }

    td:first-child {
        -moz-border-radius: 20px 0 0 20px;
        -webkit-border-radius: 20px 0 0 20px;
    }
    td:last-child {
        -moz-border-radius: 0 20px 20px 0;
        -webkit-border-radius: 0 20px 20px 0;
    }

    tr:hover td:first-child {
        -moz-border-radius: 20px 0 20px 0;
        -webkit-border-radius: 20px 20px0 0 0;
    }

    tr:hover td:last-child {
        -moz-border-radius: 0 20px 20px 0;
        -webkit-border-radius: 0 20px 20px 0;
    }

    tr:hover td {
        background-color: #edecfe;
    }

    .tr-active-hover td:first-child {
        -moz-border-radius: 20px 0 20px 0;
        -webkit-border-radius: 20px 20px0 0 0;
    }

    .tr-active-hover td:last-child {
        -moz-border-radius: 0 20px 20px 0;
        -webkit-border-radius: 0 20px 20px 0;
    }

    .tr-active-hover td {
        background-color: #edecfe;
    }

    .tr-active td:first-child {
        -moz-border-radius: 20px 0 20px 0;
        -webkit-border-radius: 20px 20px0 0 0;
    }

    .tr-active td:last-child {
        -moz-border-radius: 0 20px 20px 0;
        -webkit-border-radius: 0 20px 20px 0;
    }

    .tr-active td {
        background-color: #edecfe;
    }

    .table-active td {
        background-color: #edecfe !important;
    }

    .table-active td:first-child {
        -moz-border-radius: 20px 0 20px 0;
        -webkit-border-radius: 20px 20px0 0 0;
    }

    .table-active td:last-child {
        -moz-border-radius: 0 20px 20px 0;
        -webkit-border-radius: 0 20px 20px 0;
    }
`;

export const TableHeader = styled.thead`
    font-weight: 500;
`;

export const WrapperRowLeft = styled.div<{ loading: string }>`
    position: relative;

    @media (max-width: 991px) {
        .border-md-bottom {
            border-bottom: 1px solid #dee2e6 !important;
        }
    }
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        background-color: #5648fb;
        transition: all 0.5s ease-in-out;
    }

    ${({ loading }) =>
        loading === 'true' &&
        css`
            &:after {
                width: 100%;
            }
        `}
`;

export const CustomPagination = styled(Pagination)`
    .page-item {
        margin-left: 5px;
        margin-right: 5px;
    }
    .page-link {
        border: none;
        background: transparent;
        border-radius: 5px;
        color: #000;

        &:active {
            background: #5648fb;
            color: #fff !important;
            border-radius: 5px;
        }

        &:hover {
            background: #5648fb;
            color: #fff !important;
            border-radius: 5px;
        }
    }

    .page-link.active,
    .active > .page-link {
        color: #fff !important;
    }

    .page-link:focus {
        box-shadow: none;
    }

    .active {
        background: #5648fb;
        border-radius: 5px;
        color: #fff !important;
    }
`;

export const CustomDropdown = styled(Dropdown.Toggle)`
    background: transparent;
    border: none;

    &:hover {
        color: #5648fb;
        background: #dddafe;
        box-shadow: none;
        border: none;
    }

    &:focus {
        color: #5648fb;
        background: #dddafe;
        box-shadow: none;
        border: none;
    }

    &:active {
        color: #5648fb;
        background: #dddafe;
        box-shadow: none;
        border: none;
    }

    &:focus-visible {
        color: #5648fb;
        background: #dddafe;
        box-shadow: none;
        border: none;
    }

    &:after {
        display: none;
    }
`;

export const CustomDropdownMenu = styled(Dropdown.Menu)`
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 0.5rem;

    .text-dark::active {
        background: #5648fb;
        color: #fff;
    }

    a {
        text-decoration: none;
        color: #000;
    }

    .label-filter {
        font-size: 14px;
    }

    .dropdown-item {
        font-size: 14px;
        vertical-align: middle;
        padding: 0.3rem 0.5rem;
        border-radius: 5px;

        &:hover {
            background: #dddafe;
            border-radius: 5px;
            a {
                color: #000;
            }
        }

        &:active {
            background: #5648fb;
            border-radius: 5px;
            color: #fff !important;

            a {
                color: #fff !important;
            }

            .text-dark {
                color: #fff !important;
            }

            .custom-icon {
                background: #fff !important;
                color: #fff !important;
            }

            .react-icon {
                color: #fff !important;
            }

            .text-danger {
                color: #fff !important;
            }

            .bg-danger {
                background: #fff !important;
            }
        }
    }
`;

export const CustomDropdownAccess = styled(Dropdown.Menu)`
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 17rem;

    a {
        text-decoration: none;
        color: #000;
    }

    .label-filter {
        font-size: 14px;
    }

    .dropdown-item {
        font-size: 14px;
        vertical-align: middle;
        padding: 0.3rem 0.5rem;
        border-radius: 5px;

        &:hover {
            background: #dddafe;
            border-radius: 5px;
            a {
                color: #000;
            }
        }
    }
`;

// --------------------Table.js--------------------

export const WrapperRowRight = styled.div`
    // border-left: 1px solid #dee2e6;
    // border-bottom: 1px solid #dee2e6;
    min-height: 35rem;
`;

export const WrapperNotSelected = styled.div`
    margin: 162px auto;
    .icon-selected {
        float: none;
        margin: auto;
    }
    .text-selected {
        font-size: 14px;
    }
`;

export const WrapperSelected = styled.div``;

export const ProfileSelected = styled.img`
    position: relative;
    left: 4rem;
    top: 4rem;
    transform: translateX(-50%);
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    border: 5px solid #fff;
    object-fit: cover;
`;

export const CoverSelected = styled.div<{ cover: string }>`
    background: url(${CoverBackground}) no-repeat center;
    background-size: cover;
    width: 100%;
    height: 7rem;
`;

export const IconContent = styled.span<{ icon: string; errors?: boolean }>`
    display: block;
    width: 3rem;
    height: 3rem;
    mask: url(${props => props.icon}) no-repeat center;
    mask-size: 80%;
    -webkit-mask: url(${props => props.icon}) no-repeat center;
    -webkit-mask-size: 80%;
    background-color: #cfcfcf;
`;

export const IconDropdown = styled.span<{ icon: string; color: string }>`
    width: 1rem;
    height: 1rem;
    mask: url(${props => props.icon}) no-repeat center;
    mask-size: 80%;
    -webkit-mask: url(${props => props.icon}) no-repeat center;
    -webkit-mask-size: 80%;
    background-color: ${props => props.color};
    display: inline-block;
    margin-right: 5px;
    mask-position: bottom;
`;

export const ActionIcon = styled.span<{ icon: string; color: string }>`
    width: 1.2rem;
    height: 1.2rem;
    mask: url(${props => props.icon}) no-repeat center;
    mask-size: 80%;
    -webkit-mask: url(${props => props.icon}) no-repeat center;
    -webkit-mask-size: 80%;
    background-color: ${props => props.color};
    display: inline-block;
    margin-right: 5px;
    mask-position: center;
    vertical-align: middle;
`;

export const ImageProject = styled.img`
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 1rem;
`;

export const ProjectList = styled.div`
    /* overflow-y: scroll;
    height: 310px; */
    align-content: flex-start;
`;

export const FilterDropdown = styled(Dropdown.Toggle)`
    border-radius: 50px;
    border: 1px solid rgba(6, 189, 128, 1);
    background-color: rgba(6, 189, 128, 0.2);
    font-size: 13px;
    font-weight: 500;
    color: #838383;
    height: 32px;

    &:hover {
        background-color: rgba(6, 189, 128, 1);
        border: 1px solid rgba(6, 189, 128, 0.2);
        color: #fff;
    }

    & + .btn:first:active {
        background-color: rgba(6, 189, 128, 1) !important;
        border: 1px solid rgba(6, 189, 128, 1) !important;
        color: #fff;
    }

    &:after {
        display: none;
    }

    &:active {
        background-color: rgba(6, 189, 128, 1) !important;
        border: 1px solid rgba(6, 189, 128, 1) !important;
        color: #fff;
    }

    & + .dropdown-menu {
        border-radius: 10px;
        padding: 0.5rem 1rem;
    }

    @media (max-width: 991px) {
        margin-bottom: 15px;
    }
`;

export const SelectOption = styled.select`
    margin-top: 5px;
    border-radius: 100px;
    font-size: 13px;

    width: 10rem;

    //active
    &:focus {
        box-shadow: none;
        outline: none;
    }

    &:focus-visible {
        box-shadow: none;
        outline: none;
    }
`;
