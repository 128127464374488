import { CSSProperties } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/**
 * Basic tooltip component boilerplate.
 *
 * @param children
 * @param text
 * @constructor
 */
export default function BasicTooltip({
    children,
    text,
    placement = 'top',
    style,
    className,
}: {
    children: JSX.Element;
    text: any;
    placement?: string;
    style?: CSSProperties;
    className?: string;
}): JSX.Element {
    return (
        <OverlayTrigger
            placement={placement as any}
            overlay={<Tooltip id="button-tooltip-2">{text}</Tooltip>}
        >
            {({ ref, ...triggerHandler }) => (
                <div
                    {...triggerHandler}
                    ref={ref}
                    style={style}
                    className={`d-inline-flex align-items-center ${className ?? ''}`}
                >
                    {children}
                </div>
            )}
        </OverlayTrigger>
    );
}
