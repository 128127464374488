import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CloseButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@/components/molecules/Card';
import styled from 'styled-components';

import { createDirectMessage } from '../redux/actions';
import { RootState } from '@/redux/store';
import { generateInitialImageUrl } from '@/utility/Utils';

export default function ListChatting(params: any): JSX.Element {
    const { setShow } = params;
    const dispatch = useDispatch();

    const [inhouseList, setInhouseList] = useState([]);

    const allUserChatState = useSelector((state: RootState) => state.allUserChat);

    // get all user chat
    useEffect(() => {
        if (allUserChatState?.status === 200) {
            setInhouseList(allUserChatState?.response?.data);
        }
    }, [allUserChatState]);

    /**
     * Function to handle change input
     * @param e any
     * @returns void
     */
    const handleChange = (e: any): void => {
        const value = e.target.value;
        // filter inhouse list per string
        if (allUserChatState?.response?.data.length > 0) {
            const filtered = allUserChatState?.response?.data?.filter((item: any) => {
                return item.name.toLowerCase().includes(value.toLowerCase());
            });

            // if not found add new in top
            if (filtered.length === 0) {
                filtered.push({
                    name: value,
                    email: value,
                    company_name: 'Other Company',
                    avatar: generateInitialImageUrl(value),
                });
            }

            return setInhouseList(filtered);
        }

        const data = [];

        data.push({
            name: value,
            email: value,
            company_name: 'Other Company',
            avatar: generateInitialImageUrl(value),
        });

        return setInhouseList(data as []);
    };

    /**
     * Function to handle new direct message
     *
     * @param email string
     * @returns any
     */
    const handleNewDirectMessage = (email: string): any => {
        // if not email
        if (!email.includes('@')) {
            return toast.error('Please Input Email');
        }

        const payload = new FormData();
        payload.append('interlocutor_email', email);

        dispatch(createDirectMessage(payload));

        dispatch({
            type: 'NEW_DIRECT_REDIRECTED',
            payload: false,
        });
    };

    return (
        <>
            <ChatList className="col-lg-3 mb-4">
                <Card classOuter="py-2 border-0 bg-white" classBody="p-0">
                    <div className="chat-header pb-0 mt-2">
                        <div className="d-flex my-3">
                            <CloseButton
                                style={{
                                    height: 7,
                                    width: 7,
                                    marginTop: 3,
                                }}
                                onClick={() => setShow(0)}
                            />
                            <h6 className="ms-3 mb-0">New Direct Message</h6>
                        </div>
                    </div>
                    <div className="row px-4">
                        <div className="col-md-12">
                            <input
                                type="text"
                                placeholder="Search username or email..."
                                className="form-control search-chat mt-3"
                                onChange={event => handleChange(event)}
                            />
                            {/* <BiSearch
                                    className="search-icon"
                                    color="rgba(131, 131, 131, 1)"
                                    size={20}
                                /> */}
                        </div>
                    </div>
                    <div className="chat-body">
                        <div className="wrapper-list px-3 mt-3">
                            {inhouseList.map((list: any) => (
                                <div
                                    key={list.id}
                                    className="chat-list d-flex align-items-center"
                                    onClick={() => handleNewDirectMessage(list.email)}
                                >
                                    <NewProfilePicture
                                        src={
                                            list.avatar ??
                                            generateInitialImageUrl(list.name)
                                        }
                                        alt="avatar"
                                        className=""
                                    />
                                    <div className="d-flex flex-column justify-content-center ms-2">
                                        <NameList className="chat-name mb-1" status={0}>
                                            {list.name}
                                        </NameList>
                                        <MessageList
                                            className="chat-message mb-0"
                                            status={0}
                                        >
                                            {list.company_name}
                                        </MessageList>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Card>
            </ChatList>
        </>
    );
}

const NewProfilePicture = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50px;
    object-fit: cover;
    object-position: center;
    border: none;
`;

const NameList = styled.h6<{ status: number }>`
    font-size: 14px;
    font-weight: ${props => (props.status === 0 ? '400' : '500')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 222px;
`;

const MessageList = styled.p<{ status: number }>`
    font-size: 12px;
    color: ${props => (props.status === 0 ? 'rgba(131, 131, 131, 1)' : '#000')};
    font-weight: ${props => (props.status === 0 ? '400' : '500')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 220px;
`;

const ChatList = styled.div`
    .chat-header {
        justify-content: space-around;
        padding-left: 30px;
        padding-right: 25px;

        .dropdown-item {
            font-size: 13px;
            padding: 0.5rem;

            &:hover {
                background-color: #edecfe;
                color: rgba(86, 72, 251, 1);
                border-radius: 5px;

                .dropdown-active {
                    background-color: rgba(86, 72, 251, 1);
                }
            }

            &:active {
                background-color: #edecfe;
                color: rgba(86, 72, 251, 1);
                border-radius: 5px;

                .dropdown-active {
                    background-color: rgba(86, 72, 251, 1);
                }
            }

            &:focus {
                background-color: #edecfe;
                color: rgba(86, 72, 251, 1);
                border-radius: 5px;

                .dropdown-active {
                    background-color: rgba(86, 72, 251, 1);
                }
            }

            &:focus-visible {
                box-shadow: none;
                border: none;
                outline: none;
            }
        }

        .dropdown-menu {
            border: 1px solid rgba(131, 131, 131, 0.2);
            padding: 0.5rem;
            border-radius: 10px !important;
        }
    }

    .chat-body {
        justify-content: space-around;
        overflow-y: unset;
        /* padding-bottom: 5rem; */
    }

    .chat-job {
        font-size: 13px;
        font-weight: 500;
    }

    .chat-company {
        font-size: 13px;
        font-weight: 500;
    }

    .chat-count {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        background-color: #5648fb;
        color: #fff;
        font-size: 13px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .chat-list {
        border-bottom: 1px solid #e5e5e5;
        padding: 0.5rem 0;
        cursor: pointer;

        &:hover {
            background-color: #f5f5f5;
        }
    }

    .chat-profile {
        padding: 0.5rem 0;
    }

    .btn-new-chat,
    .dropdown-toggle {
        height: 35px;
        width: 35px;
        border-radius: 50px;
        padding: 0;

        /* After */
        &:after {
            display: none;
        }
    }

    .icon-new-chat {
        width: 23px;
        height: 23px;
        vertical-align: top;
    }

    .search-icon {
        position: relative;
        top: 7.8rem;
        right: 2.5rem;
    }

    .online-circle {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        background-color: rgba(6, 189, 128, 1);
        position: absolute;
        left: 55px;
        border: 3px solid #fff;
        margin-top: 35px;
    }

    .search-chat {
        height: 45px;
        border-radius: 50px;
        border: 1.132px solid rgba(131, 131, 131, 0.1);
        background-color: #ffff;

        &:focus {
            box-shadow: none;
        }

        &::placeholder {
            font-size: 13px;
            font-weight: 400;
        }

        &,
        .form-control {
            padding: 0 2.3rem 0 1rem;
        }
    }

    .bottom-link {
        font-size: 13px;
        font-weight: 500;
        margin-top: 1rem;
    }

    .wrapper-list {
        overflow-y: scroll;
        height: calc(100vh - 196px);

        // hidden scrollbar
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    }
`;
