import { axiosClient } from '@/services/GatewayService';
import { AppDispatch } from '@/redux/store';

import {
    INTENT_STRIPE_REQUEST,
    INTENT_STRIPE_SUCCESS,
    INTENT_STRIPE_FAIL,
    ADD_PAYMENT_METHOD_REQUEST,
    ADD_PAYMENT_METHOD_SUCCESS,
    ADD_PAYMENT_METHOD_FAIL,
    ADD_PAYMENT_METHOD_RESET,
    DETAIL_PAYMENT_METHOD_REQUEST,
    DETAIL_PAYMENT_METHOD_SUCCESS,
    DETAIL_PAYMENT_METHOD_FAIL,
    ALL_INVOICE_REQUEST,
    ALL_INVOICE_SUCCESS,
    ALL_INVOICE_FAIL,
    DETAIL_INVOICE_REQUEST,
    DETAIL_INVOICE_SUCCESS,
    DETAIL_INVOICE_FAIL,
    CHECK_COUPON_REQUEST,
    CHECK_COUPON_SUCCESS,
    CHECK_COUPON_FAIL,
    CHECK_COUPON_RESET,
    APPLY_COUPON_REQUEST,
    APPLY_COUPON_SUCCESS,
    APPLY_COUPON_FAIL,
    APPLY_COUPON_RESET,
    SWAP_BILLING_REQUEST,
    SWAP_BILLING_SUCCESS,
    SWAP_BILLING_FAIL,
    SWAP_BILLING_RESET,
} from '../reducers';

/**
 * Function to get intent of stripe
 *
 * @return Promise<void>
 */
export const getIntentStripe = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: INTENT_STRIPE_REQUEST });
        try {
            const res = await axiosClient.get('user/subscriptions/intent');

            dispatch({
                type: INTENT_STRIPE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: INTENT_STRIPE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to add payment method
 *
 * @param {string} payment_method_id
 * @return Promise<void>
 */
export const addPaymentMethod = (payment_method_id: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ADD_PAYMENT_METHOD_REQUEST });
        try {
            const res = await axiosClient.post('user/subscriptions/payment-method', {
                payment_method_id,
            });

            dispatch({
                type: ADD_PAYMENT_METHOD_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ADD_PAYMENT_METHOD_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get payment method of current user
 *
 * @return Promise<void>
 */
export const getPaymentMethod = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_PAYMENT_METHOD_REQUEST });
        try {
            const res = await axiosClient.get('user/subscriptions/payment-method');

            dispatch({
                type: DETAIL_PAYMENT_METHOD_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DETAIL_PAYMENT_METHOD_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all invoice
 *
 * @return Promise<void>
 */
export const getAllInvoice = (params: {
    page: number;
    per_page: number;
    search?: string;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_INVOICE_REQUEST });
        try {
            const res = await axiosClient.get('user/subscriptions/invoices', {
                params,
            });

            dispatch({
                type: ALL_INVOICE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_INVOICE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all invoice
 *
 * @return Promise<void>
 */
export const getDetailInvoice = (id: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_INVOICE_REQUEST });
        try {
            const res = await axiosClient.get('user/subscriptions/invoices', {
                params: {
                    id,
                },
            });

            dispatch({
                type: DETAIL_INVOICE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DETAIL_INVOICE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to check active coupon
 *
 * @return Promise<void>
 */
export const checkActiveCoupon = (code: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CHECK_COUPON_REQUEST });
        try {
            const res = await axiosClient.get('user/subscriptions/coupon', {
                params: {
                    code,
                },
            });

            dispatch({
                type: CHECK_COUPON_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: CHECK_COUPON_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to apply coupon
 *
 * @return Promise<void>
 */
export const applyCoupon = (formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: APPLY_COUPON_REQUEST });
        try {
            const res = await axiosClient.post('user/subscriptions/coupon', formData);

            dispatch({
                type: APPLY_COUPON_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: APPLY_COUPON_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to swap billing
 *
 * @return Promise<void>
 */
export const swapBilling = (formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SWAP_BILLING_REQUEST });
        try {
            const res = await axiosClient.post('user/subscriptions/cycle', formData);

            dispatch({
                type: SWAP_BILLING_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: SWAP_BILLING_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to clear state
 *
 * @return Promise<void>
 */
export const clearStateAddPayment = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ADD_PAYMENT_METHOD_RESET });
    };
};

export const clearStateCheckCoupon = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CHECK_COUPON_RESET });
    };
};

export const clearStateApplyCoupon = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: APPLY_COUPON_RESET });
    };
};

export const clearStateSwapBilling = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SWAP_BILLING_RESET });
    };
};
