import documentsImage4 from '@/assets/images/document-drawing/help-documents-drawings-image-4.webp';
import documentsImage5 from '@/assets/images/document-drawing/help-documents-drawings-image-5.webp';
import documentsImage6 from '@/assets/images/document-drawing/help-documents-drawings-image-6.webp';

import { Fragment } from 'react';

export default function HowToUploadFile(): JSX.Element {
    const contents = [
        {
            id: 1,
            text: 'You can upload file by clicking the upload button, then the modal will appear. You can drag and drop the file to the upload area, or you can click the upload area to select the file.',
            alt: 'documentsFolderPage',
            image: documentsImage4,
        },
        {
            id: 2,
            text: 'You can also rename and add description to the file.',
            alt: 'documentsFolderPage',
            image: documentsImage5,
        },
        {
            id: 3,
            text: 'Successfully uploaded file will be shown in the folder.',
            alt: 'documentsFolderPage',
            image: documentsImage6,
        },
    ];

    return (
        <Fragment>
            {contents.map((content, index) => (
                <div key={index} className="text-grey-300 mb-10">
                    {content.image.length > 0 && (
                        <img
                            src={content.image}
                            alt={content.alt}
                            className="img-fluid"
                        />
                    )}
                    <p className="mt-4 pl-2">{content.text}</p>
                </div>
            ))}
        </Fragment>
    );
}
