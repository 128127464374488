/* eslint-disable no-useless-escape */
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import passwordIcon from '@/assets/svg/icons/key-alt.svg';

import BadgeCheck from '@/assets/svg/icons/badge-check.svg';

import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import Header from '@/components/molecules/Header';
import InputBasic from '@/components/atoms/Inputs/InputBasic';
import PasswordValidation from '@/components/molecules/PasswordValidation';
import { Title } from '@/components/molecules/Typography';

import {
    resetPasswordEmail,
    resetPasswordPhone,
    clearForgotPasswordState,
} from '../redux/actions';
import Loader from '@/components/molecules/Loader';
import { RootState } from '@/redux/store';

interface ValidationListsProps {
    id: string;
    text: string;
    isValidate: boolean;
}

export default function NewPassword(): JSX.Element {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
    });

    const resetPasswordEmailState = useSelector(
        (state: RootState) => state.resetPasswordEmail,
    );
    const resetPasswordPhoneState = useSelector(
        (state: RootState) => state.resetPasswordPhone,
    );

    const [resetType, setResetType] = useState(0);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const [loadParameters, setLoadParameter] = useState<any>(null);

    const value = watch('new_password');

    const [validationLists, setValidationLists] = useState<ValidationListsProps[]>([
        {
            id: 'isMaxLength',
            text: 'At least 10 characters',
            isValidate: false,
        },
        {
            id: 'isUppercase',
            text: 'An uppercase character',
            isValidate: false,
        },
        {
            id: 'isLowercase',
            text: 'A lowercase character',
            isValidate: false,
        },
        {
            id: 'isNumber',
            text: 'A number',
            isValidate: false,
        },
        {
            id: 'isSpecialCharacter',
            text: 'A special character',
            isValidate: false,
        },
    ]);

    useEffect(() => {
        if (
            resetPasswordEmailState.status === 200 ||
            resetPasswordPhoneState.status === 200
        ) {
            navigate('/reset-password/successfully', {
                state: { nextStep: true },
            });
            dispatch(clearForgotPasswordState());
        }
    }, [resetPasswordEmailState, resetPasswordPhoneState]);

    // Redirect to login page if there is no reset-token
    // useEffect(() => {
    //     if (search === '') {
    //         navigate('/login');
    //     }
    // }, [search]);

    // set params value to the state
    useEffect(() => {
        if (searchParams !== null) {
            const resetToken = searchParams.get('reset_token');
            const resetOtp = searchParams.get('reset_otp');

            setLoadParameter(resetToken ?? resetOtp);

            resetToken !== null && setResetType(1);
            resetOtp !== null && setResetType(2);
        }
    }, [searchParams]);

    // For password validation
    useEffect(() => {
        if (value !== undefined) {
            const isMaxLength = value.length >= 10;
            const isUppercase = /[A-Z]/.test(value);
            const isLowercase = /[a-z]/.test(value);
            const isNumber = /[0-9]/.test(value);
            const isSpecialCharacter = /[^a-zA-Z0-9]/.test(value);

            setValidationLists(prevState => [
                {
                    ...prevState[0],
                    isValidate: isMaxLength,
                },
                {
                    ...prevState[1],
                    isValidate: isUppercase,
                },
                {
                    ...prevState[2],
                    isValidate: isLowercase,
                },
                {
                    ...prevState[3],
                    isValidate: isNumber,
                },
                {
                    ...prevState[4],
                    isValidate: isSpecialCharacter,
                },
            ]);
        }
    }, [value]);

    /**
     * Function on submit
     *
     * @param {object} data
     * @return {void}
     * @see cypress/e2e/authentication/register/register.cy.ts
     *      To cypress unit tester
     */
    const onSubmit = (data: any): void => {
        // initialize form data
        const payload = new FormData();

        // append data to form data
        for (const key in data) {
            payload.append(key, data[key as keyof typeof data]);
        }

        // Dispatch login action with form data as payload
        resetType === 1 && dispatch(resetPasswordEmail(payload, loadParameters));
        resetType === 2 && dispatch(resetPasswordPhone(payload, loadParameters));
    };

    return (
        <Fragment>
            {resetPasswordEmailState.loading === true && <Loader />}
            {resetPasswordPhoneState.loading === true && <Loader />}
            <div className="container-fluid container-xs container-lg">
                <header>
                    <Header text="Return to home screen" link="/" />
                </header>
                <main className="main-content">
                    <form action="#" onSubmit={handleSubmit(onSubmit)} method="post">
                        <div className="row my-5">
                            <div className="col-md-12 text-center my-5">
                                <Title text="Enter New Password" />
                                <img
                                    src={BadgeCheck}
                                    alt="badge-check"
                                    className="img-fluid mt-5"
                                />
                            </div>
                            <div className="col-md-8 col-lg-6 col-xxl-4 mx-auto text-center">
                                <span className="text-muted">
                                    Create a new password that meets all requirements to
                                    continue the process
                                </span>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col-md-8 col-lg-6 col-xxl-4 mx-auto text-center">
                                <InputBasic
                                    id="newPassword"
                                    type="password"
                                    placeholder="New Password"
                                    outerClassName="mb-3"
                                    icon={passwordIcon}
                                    rules={{
                                        function: register,
                                        name: 'new_password',
                                        rules: {
                                            required: 'Password is required',
                                            validate: {
                                                isMaxLength: (value: string) =>
                                                    value.length >= 10 ||
                                                    'At least 10 characters required',
                                                isUppercase: (value: string) =>
                                                    /[A-Z]/.test(value) ||
                                                    'An uppercase character required',
                                                isLowercase: (value: string) =>
                                                    /[a-z]/.test(value) ||
                                                    'A lowercase character required',
                                                isNumber: (value: string) =>
                                                    /[0-9]/.test(value) ||
                                                    'A number required',
                                                isSpecialCharacter: (value: string) =>
                                                    /[^a-zA-Z0-9]/.test(value) ||
                                                    'A special character required',
                                            },
                                        },
                                        errors,
                                    }}
                                />
                                <InputBasic
                                    id="newPasswordConfirmation"
                                    type="password"
                                    placeholder="Confirm Password"
                                    outerClassName="mb-5"
                                    icon={passwordIcon}
                                    rules={{
                                        function: register,
                                        name: 'new_password_confirmation',
                                        rules: {
                                            required: 'Confirm Password is required',
                                            validate: {
                                                isMatch: (value: string) =>
                                                    value === watch('new_password') ||
                                                    'Password does not match',
                                            },
                                        },
                                        errors,
                                    }}
                                />
                                <PasswordValidation lists={validationLists} />

                                <ButtonBasic
                                    type="submit"
                                    text="Confirm"
                                    className="my-3 w-50 btn-primary"
                                />
                            </div>
                        </div>
                    </form>
                </main>
            </div>
        </Fragment>
    );
}
