import { axiosClient } from '@/services/GatewayService';
import { AppDispatch } from '@/redux/store';

import {
    NOTIFICATIONS_REQUEST,
    NOTIFICATIONS_SUCCESS,
    NOTIFICATIONS_FAIL,
    NOTIFICATIONS_TOAST_REQUEST,
    NOTIFICATIONS_TOAST_SUCCESS,
    NOTIFICATIONS_TOAST_FAIL,
    UNREAD_NOTIFICATIONS_REQUEST,
    UNREAD_NOTIFICATIONS_SUCCESS,
    UNREAD_NOTIFICATIONS_FAIL,
    MARK_READ_NOTIFICATIONS_REQUEST,
    MARK_READ_NOTIFICATIONS_SUCCESS,
    MARK_READ_NOTIFICATIONS_FAIL,
    MARK_READ_NOTIFICATIONS_RESET,
    MARK_UNREAD_NOTIFICATIONS_REQUEST,
    MARK_UNREAD_NOTIFICATIONS_SUCCESS,
    MARK_UNREAD_NOTIFICATIONS_FAIL,
    MARK_UNREAD_NOTIFICATIONS_RESET,
    DELETE_NOTIFICATIONS_REQUEST,
    DELETE_NOTIFICATIONS_SUCCESS,
    DELETE_NOTIFICATIONS_FAIL,
    DELETE_NOTIFICATIONS_RESET,
    ACCEPT_PROJECT_INVITATION_REQUEST,
    ACCEPT_PROJECT_INVITATION_SUCCESS,
    ACCEPT_PROJECT_INVITATION_FAIL,
    DECLINE_PROJECT_INVITATION_REQUEST,
    DECLINE_PROJECT_INVITATION_SUCCESS,
    DECLINE_PROJECT_INVITATION_FAIL,
    CLEAR_STATE_ACCEPT_DECLINE_PROJECT_INVITATION,
    CLEAR_STATE,
} from '../reducers';

/**
 * Function to get all notifications
 *
 * @return Promise<void>
 */
export const getNotifications = (params: {
    page: number;
    per_page: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: NOTIFICATIONS_REQUEST });
        try {
            const res = await axiosClient.get('user/notifications', {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: NOTIFICATIONS_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: NOTIFICATIONS_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all notifications
 *
 * @return Promise<void>
 */
export const getNotificationsToast = (params: {
    page: number;
    per_page: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: NOTIFICATIONS_TOAST_REQUEST });
        try {
            const res = await axiosClient.get('user/notifications', {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: NOTIFICATIONS_TOAST_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: NOTIFICATIONS_TOAST_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all unread notifications
 *
 * @return Promise<void>
 */
export const getUnreadNotifications = (params: {
    page: number;
    per_page: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UNREAD_NOTIFICATIONS_REQUEST });
        try {
            const res = await axiosClient.get('user/notifications', {
                params: {
                    ...params,
                    unreaded: 1,
                },
            });

            dispatch({
                type: UNREAD_NOTIFICATIONS_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UNREAD_NOTIFICATIONS_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to mark notifications as read
 *
 * @param string id
 * @return Promise<void>
 */
export const markAsReadNotifications = (ids: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: MARK_READ_NOTIFICATIONS_REQUEST });
        try {
            const res = await axiosClient.put('user/notifications/read', null, {
                params: {
                    ids,
                },
            });

            dispatch({
                type: MARK_READ_NOTIFICATIONS_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: MARK_READ_NOTIFICATIONS_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to mark notifications as unread
 *
 * @param string id
 * @return Promise<void>
 */
export const markAsUnReadNotifications = (ids: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: MARK_UNREAD_NOTIFICATIONS_REQUEST });
        try {
            const res = await axiosClient.put('user/notifications/unread', null, {
                params: {
                    ids,
                },
            });

            dispatch({
                type: MARK_UNREAD_NOTIFICATIONS_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: MARK_UNREAD_NOTIFICATIONS_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to delete notifications
 *
 * @param string id
 * @return Promise<void>
 */
export const deleteNotifications = (ids: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_NOTIFICATIONS_REQUEST });
        try {
            const res = await axiosClient.delete('user/notifications', {
                params: {
                    ids,
                },
            });

            dispatch({
                type: DELETE_NOTIFICATIONS_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DELETE_NOTIFICATIONS_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to accept invitation on project
 *
 * @param string id
 * @return Promise<void>
 */
export const acceptProjectInvitation = (uri: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ACCEPT_PROJECT_INVITATION_REQUEST });
        try {
            const res = await axiosClient.put(uri);

            dispatch({
                type: ACCEPT_PROJECT_INVITATION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ACCEPT_PROJECT_INVITATION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to decline invitation on project
 *
 * @param string id
 * @return Promise<void>
 */
export const declineProjectInvitation = (uri: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DECLINE_PROJECT_INVITATION_REQUEST });
        try {
            const res = await axiosClient.delete(uri);

            dispatch({
                type: DECLINE_PROJECT_INVITATION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DECLINE_PROJECT_INVITATION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to reset state of mark read notifications
 *
 * @returns Promise<void>
 */
export const clearActionsNotifications = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: MARK_READ_NOTIFICATIONS_RESET });
        dispatch({ type: MARK_UNREAD_NOTIFICATIONS_RESET });
        dispatch({ type: DELETE_NOTIFICATIONS_RESET });
    };
};

/**
 * Function to clear state of activated and terminated of inhouse
 *
 * @returns Promise<void>
 */
export const clearNotifications = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CLEAR_STATE });
    };
};

/**
 * Function to clear state of activated and terminated of inhouse
 *
 * @returns Promise<void>
 */
export const clearStateAcceptDeclineProject = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CLEAR_STATE_ACCEPT_DECLINE_PROJECT_INVITATION });
    };
};
