import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';

import {
    getAllMemberInhouse,
    getDetailMemberInhouse,
    clearStateDetailMemberInhouse,
} from '@/routes/Dashboard/Project/redux/actions';
import { kickMemberProject, updateMemberPermission } from '../../redux/actions';

import { RootState } from '@/redux/store';
import { generateInitialImageUrl } from '@/utility/Utils';
import { useAppDispatch } from '@/redux/hook';
import TableBorderless from '@/components/organism/TableBorderless';

export default function ManageProjectInhouse(props: any): JSX.Element {
    const {
        setModal,
    }: {
        setParams: (params: any) => void;
        setModal: (modal: any) => void;
    } = props;

    const dispatch = useAppDispatch();

    const { id } = useParams();
    const projectId: string = id ?? '';

    const allMemberInhouseState = useSelector(
        (state: RootState) => state.allMemberInhouse,
    );
    const detailMemberInhouseState = useSelector(
        (state: RootState) => state.detailMemberInhouse,
    );
    const kickMemberProjectState = useSelector(
        (state: RootState) => state.kickMemberProject,
    );
    const currentProfileProjectState = useSelector(
        (state: RootState) => state.currentProfileProject,
    );

    const permissionUser = currentProfileProjectState?.response?.access;

    const [searchValue, setSearchValue] = useState('');
    const [selectedInhouse, setSelectedInhouse] = useState<[]>([]);
    const [params, setParams] = useState({
        project_id: id,
        page: 1,
        per_page: 10,
        search: '',
        sort_by: '',
        sort_asc: 1,
    });

    const [modalChild, setModalChild] = useState({
        danger: false,
        success: false,
    });

    const [accessTemp, setAccessTemp] = useState<{
        schedule: {
            can_invite_schedule: boolean;
            construction: {
                can_collaborate_schedule: boolean;
                can_view_schedule: boolean;
                disable_option: number;
            };
            design: {
                can_collaborate_schedule: boolean;
                can_view_schedule: boolean;
                disable_option: number;
            };
        };
        summary: {
            collaborate: boolean;
            view: boolean;
            invite: boolean;
        };
        document: {
            collaborate: boolean;
            view: boolean;
            invite: boolean;
            post_publicly: boolean;
        };
        drawing: {
            collaborate: boolean;
            view: boolean;
            invite: boolean;
            post_publicly: boolean;
        };
    }>({
        schedule: {
            can_invite_schedule: false,
            construction: {
                can_collaborate_schedule: false,
                can_view_schedule: false,
                disable_option: 0,
            },
            design: {
                can_collaborate_schedule: false,
                can_view_schedule: false,
                disable_option: 0,
            },
        },
        summary: { collaborate: false, view: false, invite: false },
        document: {
            collaborate: false,
            view: false,
            invite: false,
            post_publicly: false,
        },
        drawing: {
            collaborate: false,
            view: false,
            invite: false,
            post_publicly: false,
        },
    });

    const tableItems = [
        {
            title: 'Name',
            Cell: (row: { name: string; avatar: string; email: string }) => (
                <div className="d-flex align-items-center">
                    <div className="me-3">
                        <img
                            src={row.avatar ?? generateInitialImageUrl(row.name)}
                            alt="cover"
                            style={{
                                width: 40,
                                height: 40,
                                borderRadius: 100,
                                objectFit: 'cover',
                            }}
                        />
                    </div>
                    <div>
                        <p className="mb-0">{row.name}</p>
                        <p
                            className="text-muted mb-0"
                            style={{
                                fontSize: 12,
                            }}
                        >
                            {row.email}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Company',
            selector: '',
            Cell: (row: any) => <span>{row.company.company_name}</span>,
        },
        {
            title: 'Phone',
            selector: 'phone',
        },
    ];

    /**
     * Function to handle search
     *
     * @param string value
     * @returns void
     */
    const handleSearch = useCallback((value: string): void => {
        setSearchValue(value);
    }, []);

    /**
     * Function to handle debounce search
     *
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleDebouncedSearch = useCallback(() => {
        setParams({
            ...params,
            search: searchValue,
            page: 1,
        });

        const payload = {
            ...params,
            search: searchValue,
            page: 1,
        };

        dispatch(getAllMemberInhouse(payload));
    }, [searchValue]);

    /**
     * Function to handle kick of member
     *
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleKickMember = (): void => {
        const payload = selectedInhouse.map((item: any) => item.email);

        dispatch(kickMemberProject(projectId, payload.toString()));
    };

    /**
     * Function to handle pagination
     *
     * @param number page
     * @param string type
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handlePagination = (page: number, type: string): void => {
        setSelectedInhouse([]);
        if (type === 'page') {
            setParams({
                ...params,
                page,
            });

            const payload = {
                ...params,
                page,
            };

            dispatch(getAllMemberInhouse(payload));
        }

        if (type === 'next') {
            setParams({
                ...params,
                page: params.page + 1,
            });

            const payload = {
                ...params,
                page: params.page + 1,
            };

            dispatch(getAllMemberInhouse(payload));
        }

        if (type === 'prev') {
            setParams({
                ...params,
                page: params.page - 1,
            });

            const payload = {
                ...params,
                page: params.page - 1,
            };

            dispatch(getAllMemberInhouse(payload));
        }
    };

    /**
     * Function for handle switch access
     *
     * @param boolean value
     * @param string type
     * @param string access
     * @returns void
     */
    const handleProjectAccess = (
        value: boolean,
        type: string,
        access: string,
        space?: any,
    ): void => {
        const newAccessTemp: any = { ...accessTemp };

        if (type === 'schedule') {
            if (access === 'can_invite_schedule') {
                newAccessTemp.schedule.can_invite_schedule = value;
            } else {
                newAccessTemp[type][space][access] = value;

                if (access === 'can_collaborate_schedule') {
                    newAccessTemp[type][space].can_view_schedule = !value;
                } else if (access === 'can_view_schedule') {
                    newAccessTemp[type][space].can_collaborate_schedule = !value;
                } else if (access === 'noAccess') {
                    newAccessTemp[type][space] = {
                        can_collaborate_schedule: false,
                        can_view_schedule: false,
                        ...newAccessTemp[type][space],
                    };
                }
            }
        } else {
            newAccessTemp[type as keyof typeof newAccessTemp] = {
                ...accessTemp[type as keyof typeof accessTemp],
                [access]: value,
            };

            if (access === 'collaborate') {
                newAccessTemp[type].view = !value;
            } else if (access === 'view') {
                newAccessTemp[type].collaborate = !value;
            } else if (access === 'remove') {
                if (type === 'document' || type === 'drawing') {
                    newAccessTemp[type] = {
                        collaborate: false,
                        view: false,
                        invite: false,
                        post_publicly: false,
                    };
                } else {
                    newAccessTemp[type] = {
                        collaborate: false,
                        view: false,
                        invite: false,
                    };
                }
            }
        }

        setAccessTemp(newAccessTemp);
    };

    /**
     * Function for handle save permissions
     *
     * @returns void
     */
    const handleSavePermissions = (): void => {
        const params: {
            project_id: string;
            email: string;
        } = {
            project_id: projectId,
            email: detailMemberInhouseState?.response?.email,
        };

        const updatedAccessTemp = {
            can_collaborate_summary: accessTemp.summary.collaborate ? 1 : 0,
            can_view_summary: accessTemp.summary.view ? 1 : 0,
            can_invite_summary: accessTemp.summary.invite ? 1 : 0,
            can_collaborate_document: accessTemp.document.collaborate ? 1 : 0,
            can_view_document: accessTemp.document.view ? 1 : 0,
            can_invite_document: accessTemp.document.invite ? 1 : 0,
            can_post_publicly_document: accessTemp.document.post_publicly ? 1 : 0,
            can_collaborate_drawing: accessTemp.drawing.collaborate ? 1 : 0,
            can_view_drawing: accessTemp.drawing.view ? 1 : 0,
            can_invite_drawing: accessTemp.drawing.invite ? 1 : 0,
            can_post_publicly_drawing: accessTemp.drawing.post_publicly ? 1 : 0,
            can_invite_schedule: accessTemp.schedule.can_invite_schedule ? 1 : 0,
            member_space_schedules: [
                {
                    can_collaborate_schedule: accessTemp?.schedule?.design
                        ?.can_collaborate_schedule
                        ? 1
                        : 0,
                    can_view_schedule: accessTemp?.schedule?.design.can_view_schedule
                        ? 1
                        : 0,
                    space_type: 'design',
                },
                {
                    can_collaborate_schedule: accessTemp?.schedule?.construction
                        ?.can_collaborate_schedule
                        ? 1
                        : 0,
                    can_view_schedule: accessTemp?.schedule?.construction
                        ?.can_view_schedule
                        ? 1
                        : 0,
                    space_type: 'construction',
                },
            ],
        };

        dispatch(updateMemberPermission(params, updatedAccessTemp));
    };

    // Function for reset state when component unmount
    // useEffect(() => {
    //     return () => {
    //         setSelectedInhouse([]);
    //         dispatch(clearStateDetailMemberInhouse());
    //     };
    // }, []);

    // Function for handle if activated inhouse is clicked then get detail
    useEffect(() => {
        if (selectedInhouse.length > 0) {
            const payload = selectedInhouse.map((item: any) => item.id).pop();

            dispatch(
                getDetailMemberInhouse({
                    project_id: id,
                    id: payload.toString(),
                }),
            );
        } else {
            dispatch(clearStateDetailMemberInhouse());
        }
    }, [selectedInhouse]);

    // Function for handle if delete user success, then refresh data
    useEffect(() => {
        if (kickMemberProjectState?.status === 200) {
            setModalChild(prev => ({
                ...prev,
                danger: false,
            }));

            dispatch(getAllMemberInhouse(params));

            setTimeout(() => {
                setSelectedInhouse([]);
            }, 1000);
        }
    }, [kickMemberProjectState]);

    // Function for handle debounce search
    useEffect(() => {
        const debounce = setTimeout(() => {
            handleDebouncedSearch();
        }, 500);

        return () => {
            clearTimeout(debounce);
        };
    }, [handleDebouncedSearch]);

    // Function for handle if get detail success, then set access temp
    useEffect(() => {
        if (detailMemberInhouseState?.status === 200) {
            setAccessTemp(detailMemberInhouseState?.response?.access);
        }
    }, [detailMemberInhouseState]);

    return (
        <>
            <TableBorderless
                withoutBorder
                header={tableItems}
                items={allMemberInhouseState?.response ?? []}
                loading={allMemberInhouseState?.loading}
                detailsState={detailMemberInhouseState?.response ?? null}
                detailsLoading={detailMemberInhouseState?.loading}
                selected={selectedInhouse}
                setSelected={setSelectedInhouse}
                projectAccessState={accessTemp}
                handleProjectAccess={handleProjectAccess}
                handleSearch={handleSearch}
                handlePagination={handlePagination}
                moduleType="Inhouse User"
                actionTitle="Delete selected inhouse user"
                actionClass="btn-outline-danger"
                addTitle="Invite User"
                withDetail="user"
                withAdd={permissionUser?.summary?.invite === true}
                withAction
                withProjectAccess
                handleAction={() => setModalChild(prev => ({ ...prev, danger: true }))}
                handleAdd={() =>
                    setModal((prev: object) => ({
                        ...prev,
                        invite: true,
                    }))
                }
                handleSavePermissions={handleSavePermissions}
            />
            <ModalDashboard
                modal={modalChild.danger}
                setModal={setModalChild}
                variant="danger"
                type="delete"
                title="Delete Inhouse?"
                body="Are you sure want to delete this inhouse user?"
                withCancel
                onConfirm={handleKickMember}
                loading={kickMemberProjectState?.loading}
            />
        </>
    );
}
