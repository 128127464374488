import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tab, Tabs, Tooltip } from 'react-bootstrap';

import PublicDrawing from '../components/PublicDrawing';
import PrivateDrawing from '../components/PrivateDrawing';

import {
    getAllDrawingPublicSpace,
    getAllDrawingPrivateSpace,
    getAllDrawingPublicFolder,
    getAllDrawingPrivateFolder,
    pinUnpinDrawingSpace,
    detailDrawing,
    searchDrawingAll,
    clearPinUnpinDrawingSpace,
    clearUpdateDrawing,
    clearUploadDrawingFile,
    deleteDrawing,
    clearDeleteDrawing,
    clearCreateDrawingSpace,
    clearSendDrawingToEmail,
    restoreDrawing,
    clearRestoreDrawing,
    clearInviteUserDrawing,
    pasteDrawing,
    clearPasteDrawing,
    clearShareDrawingToEmail,
    historyDrawing,
    getSharedUserPrivateDrawing,
    getAllDrawingRestoreSpace,
    clearAllDrawingFolder,
    createShareLinkDrawing,
    clearCreateShareLinkDrawing,
} from '../redux/actions';

import styled from 'styled-components';

import { RootState } from '@/redux/store';
import {
    StructureProps,
    VersionProps,
} from '@/routes/ProjectDashboard/Drawing/components/Explorer/interface';
import { createDrawingSpaceLib, requestMultipleDrawingLib } from '../lib';
import ModalDrawingEdit from '../components/ModalDrawingEdit';
import ModalDrawingDetail from '../components/ModalDrawingDetail';
import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';
import ModalDrawingUpload from '../components/ModalDrawingUpload';
import ModalDrawingCreate from '../components/ModalDrawingCreate';
import { debounce } from 'lodash';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import ModalDrawingPreview from '../components/ModalDrawingPreview';
import { toast } from 'react-toastify';
import ModalDrawingShare from '../components/ModalDrawingShare';
import ModalDrawingSend from '../components/ModalDrawingSend';
import ModalDrawingInvite from '../components/ModalDrawingInvite';
import InputSearchSelect from '@/components/atoms/Inputs/InputSearchSelect';
import { copyTextToClipboard, currentProjectAccess } from '@/utility/Utils';
import ModalDrawingShareLink from '../components/ModalDrawingShareLink';
import { AiOutlineLink } from 'react-icons/ai';

export default function Drawing(): JSX.Element {
    const { id, urlTypeId, urlSpaceId, urlFolderId } = useParams();
    const projectId = id ?? '';

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currentPermission = currentProjectAccess();
    const drawingAccess = currentPermission?.drawing;

    const initialParams = {
        page: 1,
        per_page: 40,
        project_id: projectId ?? '',
    };

    const initialFolderParams = {
        page: 1,
        per_page: 40,
        project_id: projectId ?? '',
        folder_id: '',
    };

    const [drawingPublicParams, setDrawingPublicParams] = useState(initialParams);
    const [drawingPrivateParams, setDrawingPrivateParams] = useState(initialParams);

    const [drawingPublicFolderParams, setDrawingPublicFolderParams] =
        useState(initialFolderParams);
    const [drawingPrivateFolderParams, setDrawingPrivateFolderParams] =
        useState(initialFolderParams);

    const [drawingSearchParams, setDrawingSearchParams] = useState({
        ...initialFolderParams,
        search: '',
        public: urlTypeId === 'public' ? 1 : 0,
    });
    const [historyParams, setHistoryParams] = useState({
        ...initialParams,
        drawing_id: '',
    });

    const [sharedUserParams, setSharedUserParams] = useState({
        // ...initialParams,
        project_id: projectId,
        drawing_id: '',
    });

    const [modal, setModal] = useState<{
        type: string;
        onConfirm: () => void;
        loading: boolean;
        show: boolean;
        title: string;
        data: any;
        variant: string;
    }>({
        type: '',
        onConfirm: () => {},
        loading: false,
        show: false,
        title: '',
        data: null,
        variant: '',
    });
    const [key, setKey] = useState<string>('public');
    const [selected, setSelected] = useState<string[]>([]);
    const [selectedCheckbox, setSelectedCheckbox] = useState<string[]>([]);
    const [deletedSpaceId, setDeletedSpaceId] = useState<string>('');
    const [isCreate, setIsCreate] = useState<boolean>(false);
    const [isShowDetail, setIsShowDetail] = useState<boolean>(false);
    const [isShowEdit, setIsShowEdit] = useState<boolean>(false);
    const [isShowUpload, setIsShowUpload] = useState<boolean>(false);
    const [isShowRenameUpload, setIsShowRenameUpload] = useState<boolean>(false);
    const [isPreview, setIsPreview] = useState<boolean>(false);
    const [isCompare, setIsCompare] = useState<boolean>(false);
    const [isShare, setIsShare] = useState<boolean>(false);
    const [isSend, setIsSend] = useState<boolean>(false);
    const [isInvite, setIsInvite] = useState<boolean>(false);
    const [isShareLink, setIsShareLink] = useState<boolean>(false);
    const [restoreSpace, setRestoreSpace] = useState<
        Array<{
            label: string;
            value: string;
        }>
    >([]);

    const [rightClick, setRightClick] = useState<{
        type: string;
        data: StructureProps[] | null;
    }>({
        type: '',
        data: null,
    });

    const [onPreview, setOnPreview] = useState<{
        selected: StructureProps | null;
        data: StructureProps[] | null;
    }>({
        selected: null,
        data: null,
    });

    const [isSuccess, setIsSuccess] = useState({
        status: false,
        title: '',
        message: '',
    });

    const allDrawingPublicFolderState = useSelector(
        (state: RootState) => state.allDrawingPublicFolder,
    );

    const allDrawingRestoreSpaceState = useSelector(
        (state: RootState) => state.allDrawingRestoreSpace,
    );

    const allDrawingPrivateFolderState = useSelector(
        (state: RootState) => state.allDrawingPrivateFolder,
    );

    const createDrawingFolderState = useSelector(
        (state: RootState) => state.createDrawingFolder,
    );

    const pinUnpinDrawingSpaceState = useSelector(
        (state: RootState) => state.pinUnpinDrawingSpace,
    );
    const updateDrawingState = useSelector((state: RootState) => state.updateDrawing);
    const uploadDrawingFileState = useSelector(
        (state: RootState) => state.uploadDrawingFile,
    );
    const deleteDrawingState = useSelector((state: RootState) => state.deleteDrawing);
    const restoreDrawingState = useSelector((state: RootState) => state.restoreDrawing);
    const inviteUserDrawingState = useSelector(
        (state: RootState) => state.inviteUserDrawing,
    );
    const sendDrawingState = useSelector((state: RootState) => state.sendDrawing);
    const shareDrawingState = useSelector((state: RootState) => state.shareDrawing);
    const pasteDrawingState = useSelector((state: RootState) => state.pasteDrawing);
    const createShareLinkDrawingState = useSelector(
        (state: RootState) => state.createShareLinkDrawing,
    );

    const folderUuid = urlFolderId ?? urlSpaceId ?? '';

    /**
     * Function for handle load url
     *
     * @returns void
     */
    const handleLoadUrl = useCallback(() => {
        if (urlSpaceId !== undefined) {
            // if space id params is search
            if (urlSpaceId.includes('search=')) {
                handleSearchDispatch(urlSpaceId.split('search=')[1]);
                return;
            }

            const page = 1;

            if (urlTypeId === 'public') {
                // reset params to page 1
                setDrawingPublicFolderParams({
                    ...drawingPublicFolderParams,
                    page,
                });

                dispatch(
                    getAllDrawingPublicFolder(
                        {
                            ...drawingPublicFolderParams,
                            page,
                            folder_id: urlSpaceId !== 'trash' ? folderUuid : '',
                        },
                        urlSpaceId === 'trash',
                    ),
                );

                return;
            }

            // reset params to page 1
            setDrawingPrivateFolderParams({
                ...drawingPrivateFolderParams,
                page,
            });

            dispatch(
                getAllDrawingPrivateFolder(
                    {
                        ...drawingPrivateFolderParams,
                        page,
                        folder_id: urlSpaceId !== 'trash' ? folderUuid : '',
                    },
                    urlSpaceId === 'trash',
                ),
            );
        }
    }, [urlTypeId, urlSpaceId, urlFolderId]);

    // Load Url
    useEffect(handleLoadUrl, [handleLoadUrl]);

    // Load Url Type Public or Private
    useEffect(() => {
        if (urlTypeId !== undefined) {
            setKey(urlTypeId);
        }
    }, [urlTypeId]);

    /**
     * Function for handle load space
     *
     * @returns void
     */
    const loadSpace = useCallback(() => {
        dispatch(getAllDrawingPublicSpace(drawingPublicParams));
        dispatch(getAllDrawingPrivateSpace(drawingPrivateParams));
    }, [drawingPublicParams, drawingPrivateParams]);

    /**
     * Function for handle reload space
     *
     * @returns void
     */
    const reloadSpace = useCallback(() => {
        const params = {
            ...initialParams,
            page: 1,
        };

        setDrawingPublicParams(params);
        setDrawingPrivateParams(params);

        dispatch(getAllDrawingPublicSpace(params));
        dispatch(getAllDrawingPrivateSpace(params));
    }, []);

    // On Load Data Space
    useEffect(() => {
        loadSpace();
    }, []);

    // If pin unpin success
    useEffect(() => {
        if (pinUnpinDrawingSpaceState.status === 200) {
            // close modal
            handleModal(false, '', '', null);

            // reload data
            reloadSpace();

            setTimeout(() => {
                dispatch(clearPinUnpinDrawingSpace());
                setIsSuccess((prev: any) => ({
                    ...prev,
                    status: true,
                }));
            }, 500);
        }
    }, [pinUnpinDrawingSpaceState]);

    // If update success
    useEffect(() => {
        if (updateDrawingState.status === 200) {
            // close modal
            setIsShowEdit(false);

            // reload data
            reloadSpace();

            // if url space not undefined
            if (urlSpaceId !== undefined) {
                handleLoadUrl();
            }

            setTimeout(() => {
                dispatch(clearUpdateDrawing());
                setIsSuccess({
                    status: true,
                    title: 'Success Update',
                    message: 'Success to update',
                });
            }, 500);
        }
    }, [updateDrawingState]);

    // If delete success
    useEffect(() => {
        if (deleteDrawingState.status === 200) {
            handleClearSelected();

            // close modal
            handleModal(false, '', '', null);

            // reload data
            reloadSpace();

            setTimeout(() => {
                dispatch(clearDeleteDrawing());
                setIsSuccess({
                    status: true,
                    title: 'Success Delete',
                    message:
                        'Your file is being deleted in the background. Please refresh the page after a few minutes.',
                });
            }, 500);

            if (urlSpaceId !== undefined) {
                if (deletedSpaceId === urlSpaceId) {
                    dispatch(clearAllDrawingFolder());
                    return navigate(`/project/${projectId}/drawings/${key}`);
                }

                handleLoadUrl();
            }
        }
    }, [deleteDrawingState]);

    // If Restore success
    useEffect(() => {
        if (restoreDrawingState.status === 200) {
            handleClearSelected();
            // close modal
            handleModal(false, '', '', null);

            // reload data
            reloadSpace();

            if (urlSpaceId !== undefined) {
                handleLoadUrl();
            }

            setTimeout(() => {
                dispatch(clearRestoreDrawing());
                // setRestoreSpaceSelected('');
                setIsSuccess({
                    status: true,
                    title: 'Success Restore',
                    message:
                        'Your file is being restored in the background. Please refresh the page after a few minutes.',
                });
            }, 500);
        }
    }, [restoreDrawingState]);

    // IF send success
    useEffect(() => {
        if (sendDrawingState.status === 200) {
            // close modal
            setIsSend(false);

            // clear selected
            handleClearSelected();

            setTimeout(() => {
                dispatch(clearSendDrawingToEmail());
                setIsSuccess({
                    status: true,
                    title: 'Success Send',
                    message: 'Success to send',
                });
            }, 500);
        }
    }, [sendDrawingState]);

    // If share success
    useEffect(() => {
        if (shareDrawingState.status === 200) {
            dispatch(
                getSharedUserPrivateDrawing({
                    ...sharedUserParams,
                    drawing_id: selected[0],
                }),
            );

            setTimeout(() => {
                dispatch(clearShareDrawingToEmail());
            }, 500);
        }
    }, [shareDrawingState]);

    // If paste success
    useEffect(() => {
        if (pasteDrawingState.status === 200) {
            // reload data
            handleLoadUrl();

            handleClearSelected();

            // if cut success
            if (
                pasteDrawingState.response?.message ===
                'The specified drawing has been moved successfully'
            ) {
                reloadSpace();
            }

            setTimeout(() => {
                dispatch(clearPasteDrawing());
            }, 500);
        }
    }, [pasteDrawingState]);

    // If Invite success
    useEffect(() => {
        if (inviteUserDrawingState.status === 200) {
            setIsInvite(false);

            setTimeout(() => {
                dispatch(clearInviteUserDrawing());
                setIsSuccess({
                    status: true,
                    title: 'Success Invite',
                    message: 'Success to invite user',
                });
            }, 500);
        }
    }, [inviteUserDrawingState]);

    // If upload success
    useEffect(() => {
        if (uploadDrawingFileState.status === 200) {
            // close modal
            setIsShowUpload(false);
            setIsShowRenameUpload(false);

            // reload data
            handleLoadUrl();
            handleClearSelected();

            setTimeout(() => {
                dispatch(clearUploadDrawingFile());
            }, 500);
        }
    }, [uploadDrawingFileState]);

    // If create success
    useEffect(() => {
        if (createDrawingFolderState.status === 200) {
            // close modal
            setIsCreate(false);

            // reload data
            handleLoadUrl();

            setTimeout(() => {
                dispatch(clearCreateDrawingSpace());
                setIsSuccess({
                    status: true,
                    title: 'Success Create',
                    message: 'Success to create drawing folder',
                });
            }, 500);
        }
    }, [createDrawingFolderState]);

    // Load Restore Space
    useEffect(() => {
        if (allDrawingRestoreSpaceState?.status === 200) {
            setRestoreSpace(
                allDrawingRestoreSpaceState?.response?.map((item: StructureProps) => ({
                    label: item.name,
                    value: item.uuid,
                })) ?? [],
            );
        }
    }, [allDrawingRestoreSpaceState]);

    // if share link success
    useEffect(() => {
        void (async () => {
            if (createShareLinkDrawingState.status === 200) {
                // setIsShareLink(true);
                await copyTextToClipboard(
                    `${window.location.origin}/drawings/public/${
                        createShareLinkDrawingState.response?.data?.id as string
                    }`,
                );
                toast.info('Success to create share link', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                });
                setTimeout(() => {
                    dispatch(clearCreateShareLinkDrawing());
                }, 500);
            }
        })();
    }, [createShareLinkDrawingState]);

    const handleClearSelected = useCallback(() => {
        setSelected([]);
        setSelectedCheckbox([]);
    }, []);

    /**
     * Function for handle select tab
     *
     * @param key string | null
     * @returns void
     */
    const handleSelectTab = (key: string | null): void => {
        if (key !== null) {
            setKey(key);

            // Push Url
            navigate(`/project/${projectId}/drawings/${key}`);
        }
    };

    /**
     * Handle pagination
     *
     * @param number page
     * @param string type
     * @returns void
     */
    const handlePagination = (page: number): void => {
        const next = page + 1;

        if (key === 'public') {
            const params = {
                ...drawingPublicParams,
                page: next,
            };

            setDrawingPublicParams(params);
            dispatch(getAllDrawingPublicSpace(params));
        }

        if (key === 'private') {
            const params = {
                ...drawingPrivateParams,
                page: next,
            };

            setDrawingPrivateParams(params);

            dispatch(getAllDrawingPrivateSpace(params));
        }
    };

    /**
     * Function for handle pagination folder
     *
     * @param page number
     * @returns void
     */
    const handlePaginationFolder = (page: number): void => {
        const next = page + 1;

        if (urlSpaceId?.includes('search=') ?? false) {
            const params = {
                ...drawingSearchParams,
                page: next,
            };

            setDrawingSearchParams(params);

            return dispatch(searchDrawingAll(params, urlSpaceId === 'search'));
        }

        if (key === 'public') {
            const params = {
                ...drawingPublicFolderParams,
                folder_id: folderUuid,
                page: next,
            };

            setDrawingPublicFolderParams(params);
            return dispatch(getAllDrawingPublicFolder(params, urlSpaceId === 'trash'));
        }

        if (key === 'private') {
            const params = {
                ...drawingPrivateFolderParams,
                folder_id: folderUuid,
                page: next,
            };

            setDrawingPrivateFolderParams(params);

            dispatch(getAllDrawingPrivateFolder(params, urlSpaceId === 'trash'));
        }
    };

    /**
     * Function for handle open space
     *
     * @param e React.MouseEvent<HTMLElement>
     * @param id string
     * @param moduleType string
     * @param tab string
     * @param spaceId string
     * @returns void
     */
    const handleOpenSpace = useCallback(
        (
            e: React.MouseEvent<HTMLElement>,
            id: string,
            moduleType: string,
            tab: string,
            spaceId: string,
        ) => {
            handleClearSelected();

            navigate(`/project/${id}/${moduleType}/${tab}/${spaceId}`);
        },
        [navigate],
    );

    /**
     * Function for handle open folder
     *
     * @param e React.MouseEvent<HTMLElement>
     * @param id string
     * @param moduleType string
     * @param tab string
     * @param spaceId string
     * @param folderId string
     * @returns void
     */
    const handleOpenFolder = useCallback(
        (
            e: React.MouseEvent<HTMLElement>,
            id: string,
            moduleType: string,
            tab: string,
            spaceId: string,
            folderId: string,
        ) => {
            e.stopPropagation();
            e.preventDefault();

            // Clear selected
            handleClearSelected();

            if (spaceId === 'trash') {
                return toast.error("You can't open folder in trash");
            }

            navigate(`/project/${id}/${moduleType}/${tab}/${spaceId}/${folderId}`);
        },
        [navigate],
    );

    /**
     * Function for handle previous
     *
     * @param e React.MouseEvent<HTMLElement>
     * @param id string
     * @param moduleType string
     * @param tab string
     * @param spaceId string
     * @param folderId string
     * @returns void
     */
    const handlePrevious = useCallback(
        (
            e: React.MouseEvent<HTMLElement>,
            id: string,
            moduleType: string,
            tab: string,
            spaceId: string,
            folderId: string,
        ) => {
            e.stopPropagation();
            e.preventDefault();

            if (spaceId === folderId) {
                return navigate(`/project/${id}/${moduleType}/${tab}/${spaceId}`);
            }

            navigate(`/project/${id}/${moduleType}/${tab}/${spaceId}/${folderId}`);
        },
        [navigate],
    );

    /**
     * Function for handle preview file
     *
     * @param e React.MouseEvent<HTMLElement>
     * @param rowData StructureProps
     * @param allFiles StructureProps[]
     * @returns void
     */
    const handlePreviewFile = useCallback(
        (
            e: React.MouseEvent<HTMLElement>,
            rowData: StructureProps,
            allFiles: StructureProps[],
        ) => {
            e.stopPropagation();
            e.preventDefault();

            setIsPreview(true);
            setOnPreview({
                selected: rowData,
                data: allFiles,
            });
        },
        [],
    );

    /**
     * Function for handle preview revision
     *
     * @param e React.MouseEvent<HTMLElement>
     * @param rowData VersionProps
     * @param allFiles VersionProps[] | null
     * @returns void
     */
    const handlePreviewRevision = useCallback(
        (
            e: React.MouseEvent<HTMLElement>,
            rowData: VersionProps,
            allFiles: VersionProps[] | null,
        ) => {
            setIsPreview(true);
            setOnPreview({
                selected: rowData as any,
                data: allFiles as any,
            });
        },
        [],
    );

    /**
     * Function for handle search
     *
     * @param e React.ChangeEvent<HTMLInputElement>
     * @returns void
     */
    const handleSearch = debounce((e: any) => {
        const { value } = e.target;

        if (value !== '') {
            return navigate(
                `/project/${projectId}/drawings/${key}/search=${value as string}`,
            );
        }

        navigate(`/project/${projectId}/drawings/${key}`);
    }, 500);

    /**
     * Function for handle remove selected
     *
     * @returns void
     */
    const handleRemoveSelected = (): void => {
        handleClearSelected();
    };

    /**
     * Function for handle search dispatch
     *
     * @param value string
     * @returns void
     */
    const handleSearchDispatch = (value: string): void => {
        const payload = {
            ...drawingSearchParams,
            search: value,
            public: urlTypeId === 'public' ? 1 : 0,
            page: 1,
        };

        setDrawingSearchParams(payload);

        dispatch(searchDrawingAll(payload, urlFolderId === 'trash'));
    };

    /**
     * Function for handle create space
     *
     * @param e any
     * @returns Promise<void>
     */
    const handleCreateSpace = async (e: any): Promise<void> => {
        return await new Promise((resolve, reject) => {
            const { value } = e.target;

            const payload = {
                project_id: projectId,
                public: key === 'public' ? 1 : 0,
            };

            const formData = new FormData();

            let name = value;
            if (value === '') {
                name = 'New Folder';
            }

            formData.append('name', name);

            createDrawingSpaceLib(payload, formData)
                .then(res => {
                    resolve(res);
                })
                .catch(error => {
                    reject(error);
                });
        });
    };

    /**
     * Function for handle pin
     *
     * @param uuid string
     * @returns void
     */
    const handlePin = (uuid: string): void => {
        dispatch(pinUnpinDrawingSpace({ id: uuid, project_id: projectId }, true));
    };

    /**
     * Function for handle unpin
     *
     * @param uuid string
     * @returns void
     */
    const handleUnpin = (uuid: string): void => {
        dispatch(pinUnpinDrawingSpace({ id: uuid, project_id: projectId }, false));
    };

    /**
     * Function for handle show create
     *
     * @returns void
     */
    const handleShowCreate = (): void => {
        setIsCreate(true);
    };

    /**
     * Function for handle show detail
     *
     * @param uuid string
     * @returns void
     */
    const handleShowDetail = (uuid: string): void => {
        setIsShowDetail(true);

        dispatch(
            detailDrawing({
                id: uuid,
                project_id: projectId,
                trashed: urlSpaceId === 'trash' ? 1 : 0,
            }),
        );

        dispatch(
            historyDrawing({
                ...historyParams,
                drawing_id: uuid,
                trashed: urlSpaceId === 'trash' ? 1 : 0,
            }),
        );
    };

    /**
     * Function for handle show edit
     *
     * @param uuid string
     * @returns void
     */
    const handleShowEdit = (uuid: string): void => {
        setIsShowEdit(true);

        dispatch(detailDrawing({ id: uuid, project_id: projectId }));
        dispatch(historyDrawing({ ...historyParams, drawing_id: uuid }));
    };

    /**
     * Function for handle show send
     *
     * @param uuid string[]
     * @returns void
     */
    const handleShowSend = (uuid: string[]): void => {
        setIsSend(true);
        setSelected(uuid);
    };

    /**
     * Function for handle show share
     *
     * @param uuid string[]
     * @returns void
     */
    const handleShowShare = (uuid: string[]): void => {
        setIsShare(true);
        setSelected(uuid);
        dispatch(
            getSharedUserPrivateDrawing({ ...sharedUserParams, drawing_id: uuid[0] }),
        );
    };

    /**
     * Function for handle show share link
     *
     * @param uuid string[]
     * @returns void
     */
    const handleShareLink = (uuid: string[], type: '1' | '2'): void => {
        // TODO: If Expired date is ready show modal
        // setIsShareLink(true);
        setSelected(uuid);

        const formData = new FormData();

        formData.append('drawing_id', uuid[0]);

        if (uuid[0] === '') {
            formData.delete('drawing_id');
        }

        formData.append('project_id', projectId);
        formData.append('type', type);

        dispatch(createShareLinkDrawing(formData));
    };

    /**
     * Function for handle select checkbox
     *
     * @param e React.ChangeEvent<HTMLInputElement>
     * @param uuid string
     * @returns void
     */
    const handleDelete = (uuid: string[]): void => {
        const formData = new FormData();

        setDeletedSpaceId(uuid[0]);

        uuid.forEach(item => {
            formData.append('ids[]', item);
        });

        dispatch(
            deleteDrawing(
                {
                    project_id: projectId,
                    permanently: 0,
                },
                formData,
            ),
        );
    };

    /**
     * Function for handle delete permanently
     *
     * @param uuid string[]
     * @returns void
     */
    const handleDeletePermanently = (uuid: string[]): void => {
        const formData = new FormData();

        uuid.forEach(item => {
            formData.append('ids[]', item);
        });

        dispatch(
            deleteDrawing(
                {
                    project_id: projectId,
                    permanently: 1,
                },
                formData,
            ),
        );
    };

    /**
     * Function for handle restore
     *
     * @param uuid string[]
     * @returns void
     */
    const handleRestore = (uuid: string[]): void => {
        const formData = new FormData();

        uuid.forEach(item => {
            formData.append('ids[]', item);
        });

        dispatch(
            restoreDrawing(
                {
                    project_id: projectId,
                },
                formData,
            ),
        );
    };

    /**
     * Function for handle select space restore
     *
     * @param value string
     * @returns void
     */
    const handleSelectSpaceRestore = (value: string): void => {
        // if (value === undefined) {
        //     // TODO: FIx
        //     // return sessionStorage.removeItem('restoreFolderId');
        //     // dispatch(getAllDrawingRestoreSpace({ project_id: prjectId, public: 1 }));
        // }

        sessionStorage.setItem('restoreFolderId', value);
    };

    /**
     * Function for handle select space restore
     *
     * @param value string
     * @returns void
     */
    const handleDownload = (uuid: string[]): void => {
        const formData = new FormData();

        uuid.forEach(item => {
            formData.append('ids[]', item);
        });

        void toast.promise(
            new Promise<void>((resolve, reject) => {
                requestMultipleDrawingLib(
                    {
                        project_id: projectId,
                    },
                    formData,
                )
                    .then(res => {
                        resolve();
                    })
                    .catch((error: any) => {
                        reject(error);
                    });
            }),
            {
                pending: 'Downloading...',
                success: 'Download success',
                error: 'Download failed',
            },
            {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'downloadNotification',
            },
        );
    };

    /**
     * Function for handle download single file
     *
     * @param data StructureProps | null
     * @returns void
     */
    const handleDownloadSingleFile = useCallback(
        (data: StructureProps | null): void => {
            if (data?.type === 'Folder') {
                return handleDownload([data.uuid]);
            }

            // Open new tab
            window.open(data?.file ?? '', '_blank');
        },
        [dispatch],
    );

    /**
     * Function for handle dispatch right click
     *
     * @param uuid string[]
     * @param type string
     * @param folder_id string
     * @returns void
     */
    const handleDispatchRightClick = useCallback(
        (uuid: string[], type: string, folder_id: string): void => {
            const formData = new FormData();

            uuid.forEach(item => {
                formData.append('ids[]', item);
            });

            dispatch(pasteDrawing({ project_id: projectId, folder_id }, type, formData));
        },
        [dispatch],
    );

    /**
     * Function for handle action multiple
     *
     * @param e React.MouseEvent<HTMLElement>
     * @param type string
     * @returns void
     */
    const handleModal = (
        show: boolean,
        type: string,
        title: string,
        data: StructureProps | null,
    ): void => {
        let onConfirm = (): void => {};
        let variant = '';
        let loading = false;

        switch (type) {
            case 'pin':
                onConfirm = () => handlePin(data?.uuid ?? '');
                loading = false;
                variant = 'info';

                // Specific modal success
                setIsSuccess((prev: any) => ({
                    ...prev,
                    title: 'Success Pin',
                    message: 'Success to pin drawing folder',
                }));

                break;
            case 'unpin':
                onConfirm = () => handleUnpin(data?.uuid ?? '');
                loading = false;
                variant = 'danger';

                // Specific modal success
                setIsSuccess((prev: any) => ({
                    ...prev,
                    title: 'Success Unpin',
                    message: 'Success to unpin drawing folder',
                }));

                break;
            case 'information':
                handleShowDetail(data?.uuid ?? '');
                break;
            case 'create':
                handleShowCreate();
                break;
            case 'edit':
                handleShowEdit(data?.uuid ?? '');
                break;
            case 'upload':
                setIsShowUpload(true);
                break;
            case 'download':
                handleDownload([data?.uuid ?? '']);
                break;
            case 'download single':
                handleDownloadSingleFile(data);
                break;
            case 'share':
                handleShowShare([data?.uuid ?? '']);
                break;
            case 'share link':
                handleShareLink([data?.uuid ?? ''], '1');
                break;
            case 'send':
                handleShowSend([data?.uuid ?? '']);
                break;
            case 'delete':
                onConfirm = () => handleDelete([data?.uuid ?? '']);
                loading = false;
                variant = 'danger';
                break;
            case 'restore':
                onConfirm = () => handleRestore([data?.uuid ?? '']);
                loading = false;
                variant = 'success';
                sessionStorage.removeItem('restoreFolderId');
                dispatch(
                    getAllDrawingRestoreSpace({
                        project_id: projectId,
                        public: urlTypeId === 'public' ? 1 : 0,
                    }),
                );
                break;
            case 'delete permanent':
                onConfirm = () => handleDeletePermanently([data?.uuid ?? '']);
                loading = false;
                variant = 'danger';
                break;
            case 'compare':
                setIsPreview(true);
                setOnPreview({
                    selected: data,
                    data:
                        key === 'public'
                            ? allDrawingPublicFolderState.response?.data?.filter(
                                  (item: StructureProps) => item.uuid !== data?.uuid,
                              )
                            : allDrawingPrivateFolderState.response?.data?.filter(
                                  (item: StructureProps) => item.uuid !== data?.uuid,
                              ),
                });
                setIsCompare(true);
                break;
            default:
                break;
        }

        setModal({
            show,
            type,
            onConfirm,
            loading,
            title,
            data,
            variant,
        });
    };

    /**
     * Function for handle action multiple
     *
     * @param e React.MouseEvent<HTMLElement>
     * @param type string
     */
    const handleActionMultiple = (
        e: React.MouseEvent<HTMLElement>,
        type: string,
    ): void => {
        switch (type) {
            case 'download':
                handleDownload(selectedCheckbox);
                break;
            case 'share':
                setSelected(selectedCheckbox);
                setIsShare(true);
                break;
            case 'send':
                handleShowSend(selectedCheckbox);
                break;
            case 'delete':
                setModal({
                    show: true,
                    type: 'delete',
                    onConfirm: () => handleDelete(selectedCheckbox),
                    loading: false,
                    title: 'Delete',
                    data: null,
                    variant: 'danger',
                });
                break;
            case 'delete permanent':
                setModal({
                    show: true,
                    type: 'delete permanent',
                    onConfirm: () => handleDeletePermanently(selectedCheckbox),
                    loading: false,
                    title: 'Delete Permanent',
                    data: null,
                    variant: 'danger',
                });
                break;
            case 'restore':
                setModal({
                    show: true,
                    type: 'restore',
                    onConfirm: () => handleRestore(selectedCheckbox),
                    loading: false,
                    title: 'Restore',
                    data: null,
                    variant: 'success',
                });
                sessionStorage.removeItem('restoreFolderId');
                dispatch(
                    getAllDrawingRestoreSpace({
                        project_id: projectId,
                        public: urlTypeId === 'public' ? 1 : 0,
                    }),
                );
                break;
            default:
                break;
        }
    };

    /**
     * Function for handle action right click
     *
     * @param e React.MouseEvent<HTMLElement>
     * @param contextData { type: string; data: StructureProps[] }
     * @returns void
     */
    const handleActionRightClick = (
        e: React.MouseEvent<HTMLElement>,
        contextData: {
            type: string;
            data: StructureProps[];
        },
    ): void => {
        e.preventDefault();

        switch (contextData.type) {
            case 'copy space':
            case 'copy folder':
                handleDispatchRightClick(
                    contextData.data.map(item => item.uuid),
                    'copy',
                    folderUuid,
                );
                break;

            case 'move space':
            case 'move folder':
                handleDispatchRightClick(
                    contextData.data.map(item => item.uuid),
                    'move',
                    folderUuid,
                );

                break;

            default:
                break;
        }
    };

    // Data for tab
    const tabLists = [
        {
            id: 1,
            title: (
                <OverlayTrigger
                    overlay={
                        <Tooltip id="tooltip-public">
                            All invited users to this project can see these drawings
                        </Tooltip>
                    }
                >
                    <span>Published Public</span>
                </OverlayTrigger>
            ),
            key: 'public',
            params: drawingPublicParams,
            element: (
                <PublicDrawing
                    handleOpenSpace={handleOpenSpace}
                    handleOpenFolder={handleOpenFolder}
                    handlePrevious={handlePrevious}
                    handleCreateSpace={handleCreateSpace}
                    handlePagination={handlePagination}
                    handlePaginationFolder={handlePaginationFolder}
                    handleModal={handleModal}
                    handleSearch={handleSearch}
                    handlePreviewFile={handlePreviewFile}
                    handleRemoveSelected={handleRemoveSelected}
                    selectedCheckbox={selectedCheckbox}
                    setSelectedCheckbox={setSelectedCheckbox}
                    handleActionMultiple={handleActionMultiple}
                    rightClick={rightClick}
                    handleActionRightClick={handleActionRightClick}
                    handlePreviewRevision={handlePreviewRevision}
                    drawingSpaceParams={drawingPublicParams}
                    drawingFolderParams={drawingPublicFolderParams}
                />
            ),
        },
        {
            id: 2,
            title: (
                <OverlayTrigger
                    overlay={
                        <Tooltip id="tooltip-public">
                            All invited users to this project can see these drawings
                        </Tooltip>
                    }
                >
                    <span>Private</span>
                </OverlayTrigger>
            ),
            key: 'private',
            params: drawingPrivateParams,
            element: (
                <PrivateDrawing
                    handleOpenSpace={handleOpenSpace}
                    handleOpenFolder={handleOpenFolder}
                    handlePrevious={handlePrevious}
                    handleCreateSpace={handleCreateSpace}
                    handlePagination={handlePagination}
                    handlePaginationFolder={handlePaginationFolder}
                    handleModal={handleModal}
                    handleSearch={handleSearch}
                    handlePreviewFile={handlePreviewFile}
                    handleRemoveSelected={handleRemoveSelected}
                    selectedCheckbox={selectedCheckbox}
                    setSelectedCheckbox={setSelectedCheckbox}
                    handleActionMultiple={handleActionMultiple}
                    rightClick={rightClick}
                    handleActionRightClick={handleActionRightClick}
                    handlePreviewRevision={handlePreviewRevision}
                    drawingSpaceParams={drawingPrivateParams}
                    drawingFolderParams={drawingPrivateFolderParams}
                />
            ),
        },
    ];

    return (
        <WrapperCard>
            <div className="card-body p-0">
                {drawingAccess?.invite && (
                    <ButtonBasic
                        text="Invite User"
                        onClick={() => setIsInvite(true)}
                        className="btn btn-primary btn-sm px-3 py-1"
                    />
                )}
                {key === 'public' && (
                    <ButtonBasic
                        text={<AiOutlineLink />}
                        onClick={() => handleShareLink([''], '2')}
                        className="btn btn-primary btn-sm px-3 py-1 ms-2"
                    />
                )}

                <WrapperTabs
                    activeKey={key}
                    id="nav"
                    className="border-0"
                    onSelect={k => handleSelectTab(k)}
                >
                    {tabLists.map((tab, index) => (
                        <Tab key={index} eventKey={tab.key} title={tab.title}>
                            {tab.element}
                        </Tab>
                    ))}
                </WrapperTabs>
            </div>
            <ModalDashboard
                modal={isSuccess.status}
                setModal={setModal}
                variant="success"
                type="success"
                title={isSuccess.title}
                body={isSuccess.message}
                onConfirm={() => setIsSuccess({ status: false, title: '', message: '' })}
            />

            <ModalDashboard
                modal={modal.show}
                setModal={setModal}
                variant={modal.variant}
                type={modal.type}
                title={modal.title}
                body={`Are you sure want to ${modal.type} this?`}
                withCancel
                onConfirm={modal.onConfirm}
                loading={
                    Boolean(pinUnpinDrawingSpaceState.loading) ||
                    Boolean(deleteDrawingState.loading) ||
                    Boolean(restoreDrawingState.loading)
                }
                subBody={
                    modal.type === 'delete permanent'
                        ? "You can't undo this action after this step"
                        : ''
                }
                extraBody={
                    modal?.type === 'restore' && (
                        <div>
                            Restore to:
                            <InputSearchSelect
                                options={restoreSpace}
                                isMulti={false}
                                isClearable
                                onChange={(e: any) => handleSelectSpaceRestore(e?.value)}
                                loading={allDrawingRestoreSpaceState.loading}
                                disabled={allDrawingRestoreSpaceState.loading}
                            />
                        </div>
                    )
                }
            />
            <ModalDrawingInvite modal={isInvite} setModal={setIsInvite} />
            <ModalDrawingCreate modal={isCreate} setModal={setIsCreate} />
            <ModalDrawingDetail modal={isShowDetail} setModal={setIsShowDetail} />
            <ModalDrawingEdit modal={isShowEdit} setModal={setIsShowEdit} />
            <ModalDrawingShare
                modal={isShare}
                setModal={setIsShare}
                selected={selected}
            />
            <ModalDrawingSend modal={isSend} setModal={setIsSend} selected={selected} />
            <ModalDrawingShareLink
                modal={isShareLink}
                setModal={setIsShareLink}
                selected={selected}
            />
            <ModalDrawingUpload
                modalUpload={isShowUpload}
                setModalUpload={setIsShowUpload}
                modalRename={isShowRenameUpload}
                setModalRename={setIsShowRenameUpload}
            />
            <ModalDrawingPreview
                modal={isPreview}
                setModal={setIsPreview}
                state={onPreview}
                setState={setOnPreview}
                compare={isCompare}
                setCompare={setIsCompare}
                handleDownloadSingle={handleDownloadSingleFile}
            />
        </WrapperCard>
    );
}

const WrapperCard = styled.div`
    margin-top: 2rem;
    border-radius: 10px;
    background-color: #ffff;
`;

const WrapperTabs = styled(Tabs)`
    .nav-item {
        padding: 1rem 1rem 1rem 0rem;
    }

    .nav-link {
        border-radius: 20px;
        background: rgba(86, 72, 251, 0.5);
        color: #fff !important;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        padding: 0.2rem 1rem;

        &.active {
            color: #fff !important;
            background-color: #5648fb;
        }
    }
`;
