import { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import ModalAction from '@/components/atoms/Modals/ModalAction';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import InputArea from '@/components/atoms/Inputs/InputArea';
import InputSelect from '@/components/atoms/Inputs/InputSelect';

import styled from 'styled-components';
import { RootState } from '@/redux/store';
import { approveTender } from '../../TenderBox/redux/actions';

interface ParamsProps {
    modal: boolean;
    setModal: Dispatch<
        SetStateAction<{
            danger: boolean;
            caution: boolean;
            success: boolean;
            acceptTender: boolean;
            rejectTender: boolean;
        }>
    >;
    setModalType: Dispatch<SetStateAction<string>>;
    notificationDetail: {
        id: string;
        uuid: string;
        actions: Array<{
            name: string;
            method: string;
            accept: string;
            uri: string;
        }>;
    };
}

export default function AcceptTender(props: ParamsProps): JSX.Element {
    const { modal, setModal, notificationDetail } = props;

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
    });
    const dispatch = useDispatch();

    const approveTenderState = useSelector((state: RootState) => state.approveTender);

    const roleOptions = [
        { value: '2', label: 'View Only' },
        { value: '1', label: 'Collaborator' },
    ];

    // If the status is 200, reset the form
    useEffect(() => {
        if (approveTenderState.status === 200) {
            reset();
        }
    }, [approveTenderState.status]);

    /**
     * Function on submit
     *
     * @param any data
     * @return void
     * @see cypress/e2e/dashboard/Project/project.cy.ts
     *      To cypress unit tester
     */

    const onSubmit = (data: any): void => {
        const getLink = notificationDetail?.actions.find(
            (item: { name: string; method: string; accept: string; uri: string }) =>
                item.name === 'Accept',
        );
        const urlParams = new URLSearchParams(new URL(getLink?.uri ?? '').search);

        const newPayload = {
            tender_applicant_id: urlParams.get('tender_applicant_id'),
            notification_uuid: urlParams.get('notification_uuid'),
            can_collaborate_summary: data.summary_role === '1' ? '1' : '0',
            can_view_summary: data.summary_role === '2' ? '1' : '0',
            can_invite_summary: data.can_invite_summary === '1' ? '1' : '0',
            can_collaborate_document: data.document_role === '1' ? '1' : '0',
            can_view_document: data.document_role === '2' ? '1' : '0',
            can_invite_document: data.can_invite_document === '1' ? '1' : '0',
            can_post_publicly_document:
                data.can_post_publicly_document === '1' ? '1' : '0',
            can_collaborate_drawing: data.drawing_role === '1' ? '1' : '0',
            can_view_drawing: data.drawing_role === '2' ? '1' : '0',
            can_invite_drawing: data.can_invite_drawing === '1' ? '1' : '0',
            can_post_publicly_drawing: data.can_post_publicly_drawing === '1' ? '1' : '0',
            can_invite_schedule: data.can_invite_schedule === '1' ? '1' : '0',
            member_space_schedules: [
                {
                    can_collaborate_schedule:
                        data.schedule_role_design === '1' ? '1' : '0',
                    can_view_schedule: data.schedule_role_design === '2' ? '1' : '0',
                    space_type: 'design',
                },
                {
                    can_collaborate_schedule:
                        data.schedule_role_construction === '1' ? '1' : '0',
                    can_view_schedule:
                        data.schedule_role_construction === '2' ? '1' : '0',
                    space_type: 'construction',
                },
            ],
            comment: data.comment,
        };

        // Dispatch login action with form data as payload
        dispatch(approveTender(newPayload));
    };

    return (
        <ModalAction
            modal={modal}
            setModal={setModal}
            aria-labelledby="contained-modal-title-vcenter"
            title="Accept Tender"
            size="lg"
        >
            <form
                action="#"
                onSubmit={handleSubmit(onSubmit)}
                method="post"
                style={{
                    fontSize: 14,
                }}
            >
                {/* <Controller
                        control={control}
                        name="getAll"
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur } }) => {
                            return (
                                <div className="text-start mt-3">
                                    <label className="fw-semibold px-4">
                                        Select User
                                    </label>
                                    <InputSearchSelect
                                        placeholder="Type or select email"
                                        innerClassName="mb-1 px-4"
                                        outerClassName=""
                                        options={userList}
                                        isMulti={false}
                                        withCreatable
                                        placeholderCreatable="Invite"
                                        loading={checkUserEmailState.loading}
                                        onChange={(value: {
                                            value: string;
                                            label: string;
                                        }) => {
                                            const isValid = regex.test(value.value);

                                            if (isValid) {
                                                setSelectedUser(value);
                                                onChange(value.value);
                                                dispatch(checkUserEmail(value.value));
                                                setIsErrorEmail(false);
                                            }
                                        }}
                                        onBlur={e => {
                                            const isValid = regex.test(e.target.value);

                                            if (e.target.value !== '' && isValid) {
                                                onBlur();
                                                setSelectedUser({
                                                    value: e.target.value,
                                                    label: e.target.value,
                                                });
                                                setValue('getAll', e.target.value);
                                                dispatch(checkUserEmail(e.target.value));
                                                setIsErrorEmail(false);
                                            } else {
                                                if (selectedUser?.value === '') {
                                                    onBlur();
                                                    setSelectedUser({
                                                        value: '',
                                                        label: '',
                                                    });
                                                    setValue('getAll', '');
                                                    setIsErrorEmail(true);
                                                } else {
                                                    onBlur();
                                                    setSelectedUser(selectedUser);
                                                    setValue(
                                                        'getAll',
                                                        selectedUser?.value,
                                                    );
                                                    setIsErrorEmail(false);
                                                }
                                            }
                                        }}
                                        value={selectedUser}
                                    />
                                    {errors.getAll != null && (
                                        <span
                                            className="text-danger px-4"
                                            style={{
                                                fontSize: 12,
                                            }}
                                        >
                                            Please select user
                                        </span>
                                    )}
                                    {isErrorEmail && (
                                        <span
                                            className="text-danger px-4"
                                            style={{
                                                fontSize: 12,
                                            }}
                                        >
                                            Please input valid email
                                        </span>
                                    )}
                                </div>
                            );
                        }}
                    /> */}

                <AccessWrapper className="d-flex flex-column justify-content-between text-start px-4 mt-4">
                    <label className="mb-3 fw-semibold">Select Access</label>
                    {/* Summary */}
                    <label>Summary</label>
                    <div className="d-flex justify-content-between w-100">
                        <InputSelect
                            options={roleOptions}
                            innerClassName="me-2"
                            rules={{
                                function: register,
                                name: 'summary_role',
                                rules: {
                                    required: 'Summary role is required',
                                },
                                errors,
                            }}
                        />
                        <div className="d-flex flex-column w-75 justify-content-center ms-5">
                            <Form.Check
                                type="checkbox"
                                label="Ability to invite users"
                                id="inviteUserCheckboxSummary"
                                className="mt-2"
                                {...register('can_invite_summary')}
                            />
                        </div>
                    </div>

                    {/* Schedule */}
                    {/* TODO:SCHEDULE PERMISSION */}
                    <label>Schedule</label>

                    <div className="d-flex justify-content-between align-items-center">
                        <div className="w-50">
                            <label className="mt-2">Design</label>
                            <InputSelect
                                options={[
                                    {
                                        value: '0',
                                        label: 'No Access',
                                    },
                                    ...roleOptions,
                                ]}
                                innerClassName="me-2"
                                rules={{
                                    function: register,
                                    name: 'schedule_role_design',
                                    rules: {
                                        required: 'Schedule role is required',
                                    },
                                    errors,
                                }}
                            />
                        </div>

                        <div className="w-50">
                            <label className="mt-2">Construction</label>
                            <InputSelect
                                options={[
                                    {
                                        value: '0',
                                        label: 'No Access',
                                    },
                                    ...roleOptions,
                                ]}
                                innerClassName="me-2"
                                rules={{
                                    function: register,
                                    name: 'schedule_role_construction',
                                    rules: {
                                        required: 'Schedule role is required',
                                    },
                                    errors,
                                }}
                            />
                        </div>

                        <div className="w-75 ms-5">
                            <Form.Check
                                type="checkbox"
                                id="schedule_group"
                                label="Ability to invite users"
                                style={{ fontSize: 14 }}
                                {...register('can_invite_schedule')}
                            />
                        </div>
                    </div>

                    {/* Document */}
                    <Form.Check
                        type="checkbox"
                        id="document_group"
                        label="Document"
                        style={{ fontSize: 14 }}
                        {...register('document')}
                    />

                    {watch('document') !== false && watch('document') !== undefined && (
                        <div className="d-flex justify-content-between w-100">
                            <InputSelect
                                options={roleOptions}
                                innerClassName="me-2"
                                rules={{
                                    function: register,
                                    name: 'document_role',
                                    rules: {
                                        required: 'Document access is required',
                                    },
                                    errors,
                                }}
                            />

                            <div className="d-flex flex-column w-75 justify-content-end ms-5">
                                <Form.Check
                                    type="checkbox"
                                    label="Ability to invite users"
                                    id="inviteUserCheckboxDocument"
                                    className="mt-2"
                                    {...register('can_invite_document')}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Post Publicly"
                                    id="postCheckboxDocument"
                                    className="mt-2"
                                    {...register('can_post_publicly_document')}
                                />
                            </div>
                        </div>
                    )}

                    {/* Drawing */}
                    <Form.Check
                        type="checkbox"
                        id="drawing_group"
                        label="Drawing"
                        style={{ fontSize: 14 }}
                        {...register('drawing')}
                    />
                    {watch('drawing') !== false && watch('drawing') !== undefined && (
                        <div className="d-flex justify-content-between w-100">
                            <InputSelect
                                options={roleOptions}
                                innerClassName="me-2"
                                rules={{
                                    function: register,
                                    name: 'drawing_role',
                                    rules: {
                                        required: 'Drawing access is required',
                                    },
                                    errors,
                                }}
                            />
                            <div className="d-flex flex-column w-75 justify-content-end ms-5">
                                <Form.Check
                                    type="checkbox"
                                    label="Ability to invite users"
                                    id="inviteUserCheckboxDrawing"
                                    className="mt-2"
                                    {...register('can_invite_drawing')}
                                />

                                <Form.Check
                                    type="checkbox"
                                    label="Post Publicly"
                                    id="postCheckboxDrawing"
                                    className="mt-2"
                                    {...register('can_post_publicly_drawing')}
                                />
                            </div>
                        </div>
                    )}
                </AccessWrapper>

                <InputArea
                    id="Comment"
                    placeholder="Comment"
                    outerClassName="px-4 mb-4"
                    rules={{
                        function: register,
                        name: 'comment',
                        rules: {},
                        errors,
                    }}
                />

                <ButtonBasic
                    text="Accept Tender"
                    type="submit"
                    className="btn btn-primary"
                    loading={approveTenderState.loading}
                />
            </form>
        </ModalAction>
    );
}

const AccessWrapper = styled.div`
    .form-select {
        font-size: 14px;
        padding: 0 !important;
    }
`;
