import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import ModalBasic from '@/components/atoms/Modals/ModalBasic';
import InputBasic from '@/components/atoms/Inputs/InputBasic';

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from '@/utility/Utils';

import { logout } from '@/routes/Authentication/Login/redux/actions';
import {
    getCurrentProfile,
    undoDeleteCurrentUser,
    clearStateUndoDeleteAccount,
} from '@/routes/Dashboard/Setting/PersonalSetting/redux/actions';
import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { RootState } from '@/redux/store';
import {
    getWallpaperGeneralDashboard,
    getWallpaperProjectDashboard,
} from '@/routes/Dashboard/Home/redux/actions';

export default function CountdownDeleteAccount(): JSX.Element {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
    });

    const dispatch = useDispatch();

    const currentProfileState = useSelector((state: RootState) => state.currentProfile);
    const ipState = useSelector((state: RootState) => state.ip);

    const undoDeleteAccountState = useSelector(
        (state: RootState) => state.undoDeleteAccount,
    );

    const logoutState = useSelector((state: RootState) => state.login);

    const companyName = currentProfileState?.response?.company?.company_name;
    const statusDeleted = currentProfileState?.response?.account_deletion_request;

    const [modal, setModal] = useState({
        verifyDelete: false,
        success: false,
    });

    const [days, setDays] = useState(30);
    const [hours, setHours] = useState(23);
    const [minutes, setMinutes] = useState(59);
    const [seconds, setSeconds] = useState(59);

    // Function for handle delete account
    useEffect(() => {
        if (statusDeleted !== null && statusDeleted !== undefined) {
            setModal(prev => ({ ...prev, verifyDelete: true }));

            // get current time australia/sydney
            const now = moment().tz('Australia/Sydney');

            // get delete time
            const deleteAt = moment.unix(
                currentProfileState?.response?.account_deletion_request?.performed_at,
            );

            // convert to Australia/Sydney timezone
            const convertAu = momentTimeZone(deleteAt).tz('Australia/Sydney');

            // get duration between now and delete time
            const duration = moment.duration(moment(convertAu).diff(now));

            setDays(duration.days());
            setHours(duration.hours());
            setMinutes(duration.minutes());
            setSeconds(duration.seconds());
        }
    }, [statusDeleted]);

    // If time already reach 0, then logout
    useEffect(() => {
        if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
            dispatch(logout());
        }
    }, [days, hours, minutes, seconds]);

    // Function for handle undo delete account
    useEffect(() => {
        if (undoDeleteAccountState?.status === 200) {
            setModal(prev => ({ ...prev, verifyDelete: false }));

            dispatch(clearStateUndoDeleteAccount());

            setTimeout(() => {
                dispatch(getCurrentProfile({ force: 1 }));
                dispatch(getWallpaperGeneralDashboard(ipState?.response?.ipString));
                dispatch(getWallpaperProjectDashboard(ipState?.response?.ipString));
                setModal(prev => ({ ...prev, success: true }));
            }, 1000);
        }
    }, [undoDeleteAccountState]);

    // Function for countdown
    useEffect(() => {
        const interval = setInterval(() => {
            switch (true) {
                case seconds > 0:
                    setSeconds(seconds - 1);
                    break;
                case minutes > 0:
                    setMinutes(minutes - 1);
                    setSeconds(59);
                    break;
                case hours > 0:
                    setHours(hours - 1);
                    setMinutes(59);
                    setSeconds(59);
                    break;
                case days > 0:
                    setDays(days - 1);
                    setHours(23);
                    setMinutes(59);
                    setSeconds(59);
                    break;
                default:
                    clearInterval(interval);
                    break;
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [days, hours, minutes, seconds]);

    /**
     * Function on submit
     *
     * @param any data
     * @return void
     * @see cypress/e2e/authentication/login/login.cy.ts
     *      To cypress unit tester
     */
    const onSubmit = (data: any): void => {
        // Dispatch undoDelete action with form data as payload
        dispatch(undoDeleteCurrentUser());
    };

    /**
     * Function for handle logout
     *
     * @return
     */
    const handleLogout = (): void => {
        dispatch(logout());
    };

    return (
        <>
            <ModalBasic
                modal={modal.verifyDelete}
                setModal={setModal}
                title="Account Disabled"
                size="lg"
            >
                <div className="px-5 py-3">
                    <form action="#" onSubmit={handleSubmit(onSubmit)} method="post">
                        <div className="text-start">
                            <p>
                                Your account has been disabled since&nbsp;
                                <b>
                                    {formatDate(
                                        currentProfileState?.response
                                            ?.account_deletion_request?.requested_at,
                                        'DD MMMM YYYY',
                                    )}
                                </b>
                                &nbsp;because you took action to delete your account.
                            </p>
                            <p>
                                In this condition, you cannot perform any action on this
                                application, and your account will be permanently deleted
                                in:
                            </p>
                            <Countdown className="countdown-text">
                                <span>
                                    {days < 10 ? `0${days}` : days}d&nbsp;
                                    {hours < 10 ? `0${hours}` : hours}h&nbsp;
                                    {minutes < 10 ? `0${minutes}` : minutes}m&nbsp;
                                    {seconds < 10 ? `0${seconds}` : seconds}s&nbsp;
                                </span>
                            </Countdown>
                            <p>
                                You can cancel this action before the above period
                                expires, if you want to cancel this action, you can input
                                your company name and type &nbsp;
                                <b>&quot;{companyName}&quot;</b>
                            </p>
                            <InputBasic
                                id="companyName"
                                type="text"
                                placeholder="Company Name"
                                outerClassName="mt-4 mb-5"
                                // icon={UserIcons}
                                rules={{
                                    function: register,
                                    name: 'company_name',
                                    rules: {
                                        required: 'Please enter your company name',
                                        // value must be same with company name
                                        validate: (value: string) =>
                                            value === companyName ||
                                            'The company name is not match, make sure you enter the correct company name',
                                    },
                                    errors,
                                }}
                            />
                        </div>
                        <ButtonBasic
                            id="btnLogout"
                            type="button"
                            text="Logout"
                            className="mt-3 btn-outline-primary me-2"
                            onClick={handleLogout}
                            loading={logoutState?.loading}
                        />
                        <ButtonBasic
                            id="btnUndo"
                            type="submit"
                            text="Undo Delete Process"
                            className="mt-3 btn-primary"
                            disabled={
                                errors.company_name !== undefined ||
                                watch('company_name') === ''
                            }
                            loading={undoDeleteAccountState?.loading}
                        />
                    </form>
                </div>
            </ModalBasic>
            <ModalDashboard
                modal={modal.success}
                setModal={setModal}
                variant="success"
                type="success"
                title="Account Restored"
                body="Successfully restored account"
                onConfirm={() => {
                    setModal({ ...modal, success: false });
                }}
            />
        </>
    );
}

const Countdown = styled.div`
    font-size: 3rem;
    font-weight: 700;
    color: rgba(86, 72, 251, 1);
    margin-bottom: 1rem;
    text-align: center;
`;
