import React, { useEffect, useMemo, useState } from 'react';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

interface INotification {
    id: number;
    content: string;
}

export const pusherKey: string = import.meta.env.VITE_PUSHER_KEY;
export const pusherCluster: string = import.meta.env.VITE_PUSHER_CLUSTER;
export const baseUrl: string = import.meta.env.VITE_BASE_URL;

// A channel listens to a particular event
export const MESSAGE_CHANNEL_REQUEST_EVENT =
    '.App\\Events\\User\\Message\\MessageChannelRequested';
export const MESSAGE_REQUEST_EVENT = '.App\\Events\\User\\Message\\MessageRequested';

export const MESSAGE_SENT = '.App\\Events\\User\\Message\\MessageSent';
export const GENERAL_NOTIFICATION_SENT =
    '.App\\Events\\User\\Notification\\NotificationSent';
/**
 * Pusher configurations
 */
const pusherConfig = {
    key: pusherKey,
    cluster: pusherCluster,
    encrypted: true,

    // auth endpoint for private channels
    // e.g. for Laravel https://example.com/api/broadcasting/auth
    authEndpoint: `${baseUrl}/broadcasting/auth`,
};

/**
 * Context for Channels
 */
type TChannels = Echo | undefined;
const ChannelsContext = React.createContext<TChannels>(undefined);

/**
 * Channel Context Provider
 *
 * @param React.ReactNode children
 * @param number authUser
 */
export function ChannelsProvider({
    children,
    authUser,
    authToken,
}: {
    children: React.ReactNode;
    authUser?: any;
    authToken?: string;
}): JSX.Element {
    const [channels, setChannels] = useState<TChannels>(undefined);
    useEffect(() => {
        const channels = getChannels(pusherConfig, authToken);
        setChannels(channels);
        return () => {
            // disconnect from server and reset the channels
            channels?.disconnect();
            setChannels(undefined);
        };
    }, [authUser, authToken]);
    return (
        <ChannelsContext.Provider value={channels}>{children}</ChannelsContext.Provider>
    );
}

/**
 * Hook to use the channels provided via context
 *
 * @returns Echo
 */
export function useChannels(): Echo | undefined {
    const channels = React.useContext(ChannelsContext);
    return channels;
}

/**
 * Use private channels
 * It simply returns the useChannels with authenticated user bindings
 *
 * @returns Echo
 * @param authUserId
 */
export function usePrivateChannels(authUserId: number, type: string): Echo | any {
    const channels = useChannels();
    return useMemo(() => {
        return channels?.private(`${type}.${authUserId}`);
    }, [channels, authUserId]);
}

/**
 * Get the channels
 *
 * @returns Echo
 * @param pusherConfig
 * @param authToken
 */
export function getChannels(pusherConfig: any, authToken?: string): Echo | undefined {
    try {
        const client = new Pusher(pusherConfig.key, {
            cluster: pusherConfig.cluster,
            authEndpoint: pusherConfig.authEndpoint,
            auth:
                authToken != null
                    ? {
                          // pass the authorization token when using private channels
                          headers: {
                              Authorization: `Bearer ${authToken}`,
                          },
                      }
                    : undefined,
            // Pass if you want to use encrypted connection
            // @TODO: Prepare for encrypted connection
            // forceTLS: false,
            // wsHost: window.location.hostname,
            // wsPort: 443,
            // wssPort: 443,
            // disableStats: false,
            // enabledTransports: ['ws', 'wss'],
        });

        return new Echo({
            broadcaster: 'pusher',
            client,
        });
    } catch (e) {}

    // return undefined;
}

/**
 * Our notification channel which notification
 *
 * @returns void
 * @param authUserId
 * @param onChange
 */
export function useMessageChannelRequest(
    authUserId: number,
    onChange: (notification: INotification) => void,
): void {
    const channels = usePrivateChannels(authUserId, 'messages');

    useEffect(() => {
        if (channels != null) {
            channels.listen(MESSAGE_CHANNEL_REQUEST_EVENT, onChange);

            // call the api to get the latest data
            // const fetchData = (): void => {
            //     dispatch(getChatList({ page: 1, per_page: 9999 }));
            // };

            // fetchData(); // Call initially

            // Set interval to call the API every 30 sec
            // const intervalId = setInterval(fetchData, 30000);

            // Clean up interval when the component is unmounted
            // return () => {
            //     clearInterval(intervalId);
            //     channels.stopListening(MESSAGE_CHANNEL_REQUEST_EVENT);
            // };
        }
    }, [channels, onChange]);
}

/**
 * For chat channel
 *
 * @param authUserId
 * @param channelId
 * @param onChange onChange
 * @returns void
 */
export function useMessageRequest(
    authUserId: number,
    channelId: number,
    onChange?: (notification: any) => void,
): void {
    const channels = usePrivateChannels(authUserId, 'messages');

    useEffect(() => {
        if (channels != null) {
            channels.listen(MESSAGE_REQUEST_EVENT, onChange);

            return () => {
                channels.stopListening(MESSAGE_REQUEST_EVENT);
            };
        }
    }, [channels, onChange, channelId]);
}

/**
 * For chat send message channel
 *
 * @param authUserId
 * @param channelId
 * @param onChange onChange
 * @returns void
 */
export function useMessageSent(
    authUserId: number,
    channelId: number,
    onChange?: (notification: any) => void,
): void {
    const channels = usePrivateChannels(authUserId, 'messages');

    useEffect(() => {
        if (channels != null) {
            channels.listen(MESSAGE_SENT, onChange);

            return () => {
                channels.stopListening(MESSAGE_SENT);
            };
        }
    }, [channels, onChange, channelId]);
}

/**
 * Our notification channel which notification
 *
 * @returns void
 * @param authUserId
 * @param onChange
 */
export function useGeneralNotification(
    authUserId: number,
    onChange: (notification: {
        notification: {
            id: number;
            title: string;
            description: string;
            created_at: number;
            redirect_url: string;
        };
    }) => void,
): void {
    const channels = usePrivateChannels(authUserId, 'notifications');
    // const dispatch = useDispatch();

    useEffect(() => {
        if (channels != null) {
            channels.listen(GENERAL_NOTIFICATION_SENT, onChange);

            // call the api to get the latest data
            // const fetchData = (): void => {
            //     dispatch(getChatList({ page: 1, per_page: 9999 }));            // };

            // // Call initially
            // fetchData();

            // Set interval to call the API every 60 seconds
            // const intervalId = setInterval(fetchData, 1800000);

            // Clean up interval when the component is unmounted
            return () => {
                // clearInterval(intervalId);
                channels.stopListening(GENERAL_NOTIFICATION_SENT);
            };
        }
    }, [channels, onChange]);
}
