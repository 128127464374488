import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '@/components/molecules/Header';
import InputOtp from '@/components/atoms/Inputs/InputOtp';
import { Title } from '@/components/molecules/Typography';
import TimerCountdown from '@/components/molecules/TimerCountdown';

import {
    verifyOTP,
    forgotPasswordPhone,
    clearForgotPasswordState,
} from '@/routes/Authentication/ForgotPassword/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '@/components/molecules/Loader';
import { RootState } from '@/redux/store';

export default function EnterOtp(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const phoneStorage = sessionStorage.getItem('phone');

    const [otp, setOtp] = useState<string>('');
    const verifyOtpState = useSelector((state: RootState) => state.verifyOTP);

    /**
     * Function for handle change on verifyOtpState
     *
     * @returns {void}
     */
    useEffect(() => {
        if (verifyOtpState.status === 200) {
            navigate(`/reset-password?reset_otp=${otp}`);
        }
    }, [verifyOtpState]);

    /**
     * Function for handle change after otp length is 4
     *
     * @returns {void}
     */
    useEffect(() => {
        if (otp.length > 3) {
            dispatch(verifyOTP(parseInt(otp)));
        }
    }, [otp]);

    /**
     * Function for handle change on otp and call dispatch
     *
     * @param {number} otp
     * @returns {void}
     */
    const handleChange = (otp: string): void => {
        setOtp(otp);
    };

    /**
     * Function for resend otp
     *
     * @returns {void}
     */
    const resendOTP = (): void => {
        // initialize form data
        const formData = new FormData();

        // append data to form data
        formData.append('phone', phoneStorage ?? '');

        dispatch(forgotPasswordPhone(formData));
    };

    /**
     * Function to handle redirect on confirm button
     *
     * @returns {void}
     */
    const handleBack = (): void => {
        // Remove email and phone from session storage,
        // if user go back to this page after from email sent page
        sessionStorage.clear();
        dispatch(clearForgotPasswordState());
        navigate('/forgot-password/enter-phone');
    };
    return (
        <Fragment>
            {verifyOtpState.loading === true && <Loader />}
            <div className="container-fluid container-xs container-lg">
                <header>
                    <Header text="Return to home screen" link="/" />
                </header>
                <main className="main-content">
                    <div className="row mb-5">
                        <div className="col-md-12 text-center">
                            <Title text="Enter OTP Number" />
                        </div>
                        <div className="col-md-8 col-lg-6 col-xxl-4 mx-auto text-center mb-3">
                            <span className="text-muted">
                                Enter the code we just send on your mobile phone
                                number&nbsp;
                            </span>
                            <b>{phoneStorage}</b>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-5 col-lg-6 col-xxl-4 mx-auto text-center">
                            <InputOtp
                                value={otp}
                                length={4}
                                onChange={(value: string) => handleChange(value)}
                                containerStyle="mb-4"
                                inputStyle="w-100 otp-input mx-2"
                            />
                            <TimerCountdown
                                minute={5}
                                second={0}
                                textCount="Resend code in"
                                textButton="Resend"
                                textDesc="Didn't receive the code?"
                                onClick={resendOTP}
                            />
                            <div className="mt-3">
                                <span className="text-muted">Wrong number?&nbsp;</span>
                                <span
                                    role="button"
                                    className="link-primary"
                                    onClick={() => handleBack()}
                                >
                                    Change
                                </span>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </Fragment>
    );
}
