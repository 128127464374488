import HowToChangeCreditCard from './Sub/Subscription/HowToChangeCreditCard';
import HowToInsertCouponCode from './Sub/Subscription/HowToInsertCouponCode';
import HowToRenewSubscription from './Sub/Subscription/HowToRenewSubscription';
import HowToStopSubscription from './Sub/Subscription/HowToStopSubscription';
import WhatFeaturesSubscription from './Sub/Subscription/WhatFeaturesSubscription';
import AccordionBasic from '@/components/molecules/AccordionBasic';

export default function HelpSubscription(): JSX.Element {
    const lists = [
        {
            id: 'featuresSubscription',
            title: 'What features are in the subscription',
            content: <WhatFeaturesSubscription />,
        },
        {
            id: 'HowToInsertCouponCode',
            title: 'How to insert coupon code',
            content: <HowToInsertCouponCode />,
        },
        {
            id: 'HowToRenewSubscription',
            title: 'How to renew subscription',
            content: <HowToRenewSubscription />,
        },
        {
            id: 'HowToChangeCreditCard',
            title: 'How to change credit card',
            content: <HowToChangeCreditCard />,
        },
        {
            id: 'HowToStopSubscription',
            title: 'How to stop subscription',
            content: <HowToStopSubscription />,
        },
    ];

    return <AccordionBasic lists={lists} />;
}
