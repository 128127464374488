import { MeiliSearch } from 'meilisearch';
import { getId } from './Utils';

// Meili Search

const meiliHost = import.meta.env.VITE_MEILISEARCH_HOST;
const meiliKey = import.meta.env.VITE_MEILISEARCH_KEY;
const meiliIndexMessage = import.meta.env.VITE_MEILI_INDEX_MESSAGE_CHANNELS;
const meiliIndexMessageDetails = import.meta.env.VITE_MEILI_INDEX_MESSAGE;

const userId: string = getId();

export const meiliGetChatLists = (callback: (response: any) => void): void => {
    try {
        const client = new MeiliSearch({
            host: meiliHost,
            apiKey: meiliKey,
        });

        const index = client.index(meiliIndexMessage);

        index
            .search('', {
                filter: `members.id = ${userId} AND (deleted_by IS EMPTY OR deleted_by.id != ${userId}) AND message_channel_category_id != 3`,
                page: 1,
                hitsPerPage: 1000,
                sort: ['latest_message_at:desc'],
            })
            .then(response => {
                callback(response);
            })
            .catch(error => {
                throw new Error(error as string);
            });
    } catch (error) {
        throw new Error(error as string);
    }
};

export const meiliGetChatCS = (callback: (response: any) => void): void => {
    try {
        const client = new MeiliSearch({
            host: meiliHost,
            apiKey: meiliKey,
        });

        const index = client.index(meiliIndexMessage);

        index
            .search('', {
                // filter: [
                //     `members.id = ${userId} AND deleted_by IS EMPTY OR deleted_by.id != ${userId}`,
                // ],
                filter: 'is_administrator = 1 AND message_channel_category_id = 3',
                page: 1,
                hitsPerPage: 1000,
                sort: ['latest_message_at:desc'],
            })
            .then(response => {
                callback(response);
            })
            .catch(error => {
                throw new Error(error as string);
            });
    } catch (error) {
        throw new Error(error as string);
    }
};

export const meiliGetChatDetails = (
    channelId: number,
    callback: (response: any) => void,
): void => {
    try {
        const client = new MeiliSearch({
            host: meiliHost,
            apiKey: meiliKey,
        });

        const index = client.index(meiliIndexMessageDetails);

        index
            .search('', {
                filter: [`message_channel_id = ${channelId}`],
                page: 1,
                hitsPerPage: 1000,
                sort: ['created_at:asc'],
            })
            .then(response => {
                callback(response);
            })
            .catch(error => {
                throw new Error(error as string);
            });
    } catch (error) {
        throw new Error(error as string);
    }
};

export const meiliGetChatDetailsCS = (
    channelId: number,
    csId: string,
    callback: (response: any) => void,
): void => {
    try {
        const client = new MeiliSearch({
            host: meiliHost,
            apiKey: meiliKey,
        });

        const index = client.index(meiliIndexMessageDetails);

        index
            .search('', {
                filter: [
                    `message_channel_is_administrator = 1 AND message_channel_category_id IN [1,3] AND sent_by.id IN [${csId}, ${userId}]`,
                ],
                page: 1,
                hitsPerPage: 1000,
                sort: ['created_at:asc'],
            })
            .then(response => {
                callback(response);
            })
            .catch(error => {
                throw new Error(error as string);
            });
    } catch (error) {
        throw new Error(error as string);
    }
};
