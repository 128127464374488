import scheduleImage4 from '@/assets/images/schedule/help-schedule-image-4.webp';
import scheduleImage5 from '@/assets/images/schedule/help-schedule-image-5.webp';
import scheduleImage6 from '@/assets/images/schedule/help-schedule-image-6.webp';

import { Fragment } from 'react';

export default function WhatIsSchedule(): JSX.Element {
    const contents = [
        {
            id: 1,
            text: 'Schedule is a feature that can be used to manage your task, you can create, and assign to your team. We have 3 types of task, which are construction, design, and private. Construction and Design task, is a task that can be seen by all member in the project, for private task is a task that only you and your assignee can see.',
            alt: 'schedulePage',
            image: scheduleImage4,
        },
        {
            id: 2,
            text: 'You can also add a comment to the task, set status of the task, and see the history of the task. So you can track the progress of the task.',
            alt: 'schedulePage',
            image: scheduleImage5,
        },
        {
            id: 3,
            text: 'Archive is a feature that can be used to hide the task, so you can focus on the task that is still active.',
            alt: 'schedulePage',
            image: scheduleImage6,
        },
    ];

    return (
        <Fragment>
            {contents.map((content, index) => (
                <div key={index} className="text-grey-300 mb-10">
                    {content.image.length > 0 && (
                        <img
                            src={content.image}
                            alt={content.alt}
                            className="img-fluid"
                        />
                    )}
                    <p className="mt-4 pl-2">{content.text}</p>
                </div>
            ))}
        </Fragment>
    );
}
