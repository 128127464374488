import directoryImage1 from '@/assets/images/directory/help-directory-image-1.webp';

import { Fragment } from 'react';

export default function WhatIsDirectory(): JSX.Element {
    const contents = [
        {
            id: 1,
            text: 'Directory is a place where you can see all the company in the application, you can see the company name, company email, and company phone number.',
            alt: 'directoryPage',
            image: directoryImage1,
        },
    ];

    return (
        <Fragment>
            {contents.map((content, index) => (
                <div key={index} className="text-grey-300 mb-10">
                    {content.image.length > 0 && (
                        <img
                            src={content.image}
                            alt={content.alt}
                            className="img-fluid"
                        />
                    )}
                    <p className="mt-4 pl-2">{content.text}</p>
                </div>
            ))}
        </Fragment>
    );
}
