export const ALL_TASK_DESIGN_REQUEST = 'ALL_TASK_DESIGN_REQUEST';
export const ALL_TASK_DESIGN_SUCCESS = 'ALL_TASK_DESIGN_SUCCESS';
export const ALL_TASK_DESIGN_FAIL = 'ALL_TASK_DESIGN_FAIL';
export const ALL_TASK_DESIGN_RESET = 'ALL_TASK_DESIGN_RESET';

export const ALL_TASK_CONSTRUCTION_REQUEST = 'ALL_TASK_CONSTRUCTION_REQUEST';
export const ALL_TASK_CONSTRUCTION_SUCCESS = 'ALL_TASK_CONSTRUCTION_SUCCESS';
export const ALL_TASK_CONSTRUCTION_FAIL = 'ALL_TASK_CONSTRUCTION_FAIL';
export const ALL_TASK_CONSTRUCTION_RESET = 'ALL_TASK_CONSTRUCTION_RESET';

export const ALL_TASK_PRIVATE_REQUEST = 'ALL_TASK_PRIVATE_REQUEST';
export const ALL_TASK_PRIVATE_SUCCESS = 'ALL_TASK_PRIVATE_SUCCESS';
export const ALL_TASK_PRIVATE_FAIL = 'ALL_TASK_PRIVATE_FAIL';
export const ALL_TASK_PRIVATE_RESET = 'ALL_TASK_PRIVATE_RESET';

export const ALL_TASK_ARCHIVE_REQUEST = 'ALL_TASK_ARCHIVE_REQUEST';
export const ALL_TASK_ARCHIVE_SUCCESS = 'ALL_TASK_ARCHIVE_SUCCESS';
export const ALL_TASK_ARCHIVE_FAIL = 'ALL_TASK_ARCHIVE_FAIL';

export const SEARCH_PUBLIC_TASK_REQUEST = 'SEARCH_PUBLIC_TASK_REQUEST';
export const SEARCH_PUBLIC_TASK_SUCCESS = 'SEARCH_PUBLIC_TASK_SUCCESS';
export const SEARCH_PUBLIC_TASK_FAIL = 'SEARCH_PUBLIC_TASK_FAIL';
export const SEARCH_PRIVATE_TASK_REQUEST = 'SEARCH_PRIVATE_TASK_REQUEST';
export const SEARCH_PRIVATE_TASK_SUCCESS = 'SEARCH_PRIVATE_TASK_SUCCESS';
export const SEARCH_PRIVATE_TASK_FAIL = 'SEARCH_PRIVATE_TASK_FAIL';

export const DETAIL_TASK_REQUEST = 'DETAIL_TASK_REQUEST';
export const DETAIL_TASK_SUCCESS = 'DETAIL_TASK_SUCCESS';
export const DETAIL_TASK_FAIL = 'DETAIL_TASK_FAIL';
export const DETAIL_TASK_RESET = 'DETAIL_TASK_RESET';

export const DETAIL_TASK_HISTORY_REQUEST = 'DETAIL_TASK_HISTORY_REQUEST';
export const DETAIL_TASK_HISTORY_SUCCESS = 'DETAIL_TASK_HISTORY_SUCCESS';
export const DETAIL_TASK_HISTORY_FAIL = 'DETAIL_TASK_HISTORY_FAIL';

export const DETAIL_TASK_COMMENT_REQUEST = 'DETAIL_TASK_COMMENT_REQUEST';
export const DETAIL_TASK_COMMENT_SUCCESS = 'DETAIL_TASK_COMMENT_SUCCESS';
export const DETAIL_TASK_COMMENT_FAIL = 'DETAIL_TASK_COMMENT_FAIL';

export const ADD_TASK_REQUEST = 'ADD_TASK_REQUEST';
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';
export const ADD_TASK_FAIL = 'ADD_TASK_FAIL';
export const ADD_TASK_RESET = 'ADD_TASK_RESET';

export const ADD_SUB_TASK_REQUEST = 'ADD_SUB_TASK_REQUEST';
export const ADD_SUB_TASK_SUCCESS = 'ADD_SUB_TASK_SUCCESS';
export const ADD_SUB_TASK_FAIL = 'ADD_SUB_TASK_FAIL';
export const ADD_SUB_TASK_RESET = 'ADD_SUB_TASK_RESET';

export const UPDATE_TASK_REQUEST = 'UPDATE_TASK_REQUEST';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_FAIL = 'UPDATE_TASK_FAIL';

export const DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAIL = 'DELETE_TASK_FAIL';
export const DELETE_TASK_RESET = 'DELETE_TASK_RESET';

export const CLEAR_TASK_ACTION = 'CLEAR_TASK_ACTION';

export const SHARE_TASK_REQUEST = 'SHARE_TASK_REQUEST';
export const SHARE_TASK_SUCCESS = 'SHARE_TASK_SUCCESS';
export const SHARE_TASK_FAIL = 'SHARE_TASK_FAIL';
export const SHARE_TASK_RESET = 'SHARE_TASK_RESET';

export const ARCHIVE_TASK_REQUEST = 'ARCHIVE_TASK_REQUEST';
export const ARCHIVE_TASK_SUCCESS = 'ARCHIVE_TASK_SUCCESS';
export const ARCHIVE_TASK_FAIL = 'ARCHIVE_TASK_FAIL';

export const UNARCHIVE_TASK_REQUEST = 'UNARCHIVE_TASK_REQUEST';
export const UNARCHIVE_TASK_SUCCESS = 'UNARCHIVE_TASK_SUCCESS';
export const UNARCHIVE_TASK_FAIL = 'UNARCHIVE_TASK_FAIL';

export const ARCHIVE_UNARCHIVE_RESET = 'ARCHIVE_UNARCHIVE_RESET';

export const UPDATE_TASK_ORDERING_REQUEST = 'UPDATE_TASK_ORDERING_REQUEST';
export const UPDATE_TASK_ORDERING_SUCCESS = 'UPDATE_TASK_ORDERING_SUCCESS';
export const UPDATE_TASK_ORDERING_FAIL = 'UPDATE_TASK_ORDERING_FAIL';
export const UPDATE_TASK_ORDERING_RESET = 'UPDATE_TASK_ORDERING_RESET';

export const UPDATE_TASK_RANGE_REQUEST = 'UPDATE_TASK_RANGE_REQUEST';
export const UPDATE_TASK_RANGE_SUCCESS = 'UPDATE_TASK_RANGE_SUCCESS';
export const UPDATE_TASK_RANGE_FAIL = 'UPDATE_TASK_RANGE_FAIL';
export const UPDATE_TASK_RANGE_RESET = 'UPDATE_TASK_RANGE_RESET';

export const ALL_PROJECT_MEMBER_REQUEST = 'ALL_PROJECT_MEMBER_REQUEST';
export const ALL_PROJECT_MEMBER_SUCCESS = 'ALL_PROJECT_MEMBER_SUCCESS';
export const ALL_PROJECT_MEMBER_FAIL = 'ALL_PROJECT_MEMBER_FAIL';

export const FIND_PERMISSIONS_BY_EMAIL_REQUEST = 'FIND_PERMISSIONS_BY_EMAIL_REQUEST';
export const FIND_PERMISSIONS_BY_EMAIL_SUCCESS = 'FIND_PERMISSIONS_BY_EMAIL_SUCCESS';
export const FIND_PERMISSIONS_BY_EMAIL_FAIL = 'FIND_PERMISSIONS_BY_EMAIL_FAIL';
export const FIND_PERMISSIONS_BY_EMAIL_RESET = 'FIND_PERMISSIONS_BY_EMAIL_RESET';

export const UPDATE_PERMISSION_ASSIGNEE_REQUEST = 'UPDATE_PERMISSION_ASSIGNEE_REQUEST';
export const UPDATE_PERMISSION_ASSIGNEE_SUCCESS = 'UPDATE_PERMISSION_ASSIGNEE_SUCCESS';
export const UPDATE_PERMISSION_ASSIGNEE_FAIL = 'UPDATE_PERMISSION_ASSIGNEE_FAIL';
export const UPDATE_PERMISSION_ASSIGNEE_RESET = 'UPDATE_PERMISSION_ASSIGNEE_RESET';

export const ADD_PERMISSION_ASSIGNEE_DIRECTLY_REQUEST =
    'ADD_PERMISSION_ASSIGNEE_DIRECTLY_REQUEST';
export const ADD_PERMISSION_ASSIGNEE_DIRECTLY_SUCCESS =
    'ADD_PERMISSION_ASSIGNEE_DIRECTLY_SUCCESS';
export const ADD_PERMISSION_ASSIGNEE_DIRECTLY_FAIL =
    'ADD_PERMISSION_ASSIGNEE_DIRECTLY_FAIL';
export const ADD_PERMISSION_ASSIGNEE_DIRECTLY_RESET =
    'ADD_PERMISSION_ASSIGNEE_DIRECTLY_RESET';

export const RESTORE_TASK_REQUEST = 'RESTORE_TASK_REQUEST';
export const RESTORE_TASK_SUCCESS = 'RESTORE_TASK_SUCCESS';
export const RESTORE_TASK_FAIL = 'RESTORE_TASK_FAIL';
export const RESTORE_TASK_RESET = 'RESTORE_TASK_RESET';

export const POST_TASK_COMMENT_REQUEST = 'POST_TASK_COMMENT_REQUEST';
export const POST_TASK_COMMENT_SUCCESS = 'POST_TASK_COMMENT_SUCCESS';
export const POST_TASK_COMMENT_FAIL = 'POST_TASK_COMMENT_FAIL';
export const POST_TASK_COMMENT_RESET = 'POST_TASK_COMMENT_RESET';

export const EDIT_TASK_COMMENT_REQUEST = 'EDIT_TASK_COMMENT_REQUEST';
export const EDIT_TASK_COMMENT_SUCCESS = 'EDIT_TASK_COMMENT_SUCCESS';
export const EDIT_TASK_COMMENT_FAIL = 'EDIT_TASK_COMMENT_FAIL';
export const EDIT_TASK_COMMENT_RESET = 'EDIT_TASK_COMMENT_RESET';

export const DELETE_TASK_COMMENT_REQUEST = 'DELETE_TASK_COMMENT_REQUEST';
export const DELETE_TASK_COMMENT_SUCCESS = 'DELETE_TASK_COMMENT_SUCCESS';
export const DELETE_TASK_COMMENT_FAIL = 'DELETE_TASK_COMMENT_FAIL';
export const DELETE_TASK_COMMENT_RESET = 'DELETE_TASK_COMMENT_RESET';

export const REMOVE_TASK_ASSIGNEE_REQUEST = 'REMOVE_TASK_ASSIGNEE_REQUEST';
export const REMOVE_TASK_ASSIGNEE_SUCCESS = 'REMOVE_TASK_ASSIGNEE_SUCCESS';
export const REMOVE_TASK_ASSIGNEE_FAIL = 'REMOVE_TASK_ASSIGNEE_FAIL';
export const REMOVE_TASK_ASSIGNEE_RESET = 'REMOVE_TASK_ASSIGNEE_RESET';

export const COLLAPSE_TASK_REQUEST = 'COLLAPSE_TASK_REQUEST';
export const COLLAPSE_TASK_SUCCESS = 'COLLAPSE_TASK_SUCCESS';
export const COLLAPSE_TASK_FAIL = 'COLLAPSE_TASK_FAIL';
export const COLLAPSE_TASK_RESET = 'COLLAPSE_TASK_RESET';

const initialState = {
    loading: false,
    response: null,
    error: null,
    status: null,
};

export const allTaskDesignReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_TASK_DESIGN_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_TASK_DESIGN_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_TASK_DESIGN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ALL_TASK_DESIGN_RESET:
            return initialState;

        default:
            return state;
    }
};

export const allTaskConstructionReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_TASK_CONSTRUCTION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_TASK_CONSTRUCTION_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_TASK_CONSTRUCTION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ALL_TASK_CONSTRUCTION_RESET:
            return initialState;

        default:
            return state;
    }
};

export const allTaskPrivateReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_TASK_PRIVATE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_TASK_PRIVATE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_TASK_PRIVATE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ALL_TASK_PRIVATE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const allTaskArchiveReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_TASK_ARCHIVE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_TASK_ARCHIVE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_TASK_ARCHIVE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        default:
            return state;
    }
};

export const searchPublicTaskReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case SEARCH_PUBLIC_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case SEARCH_PUBLIC_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case SEARCH_PUBLIC_TASK_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        default:
            return state;
    }
};

export const searchPrivateTaskReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case SEARCH_PRIVATE_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case SEARCH_PRIVATE_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case SEARCH_PRIVATE_TASK_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        default:
            return state;
    }
};

export const detailTaskReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DETAIL_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DETAIL_TASK_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case DETAIL_TASK_RESET:
            return initialState;

        default:
            return state;
    }
};

export const detailTaskHistoryReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DETAIL_TASK_HISTORY_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_TASK_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DETAIL_TASK_HISTORY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        default:
            return state;
    }
};

export const detailTaskCommentReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DETAIL_TASK_COMMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_TASK_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DETAIL_TASK_COMMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        default:
            return state;
    }
};

export const addTaskReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ADD_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ADD_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ADD_TASK_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ADD_TASK_RESET:
            return initialState;

        default:
            return state;
    }
};

export const deleteTaskReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DELETE_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DELETE_TASK_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case DELETE_TASK_RESET:
            return initialState;

        default:
            return state;
    }
};

export const restoreTaskReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case RESTORE_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case RESTORE_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case RESTORE_TASK_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case RESTORE_TASK_RESET:
            return initialState;

        default:
            return state;
    }
};

export const shareTaskReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case SHARE_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case SHARE_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case SHARE_TASK_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case SHARE_TASK_RESET:
            return initialState;

        default:
            return state;
    }
};

export const archiveTaskReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ARCHIVE_TASK_REQUEST:
        case UNARCHIVE_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ARCHIVE_TASK_SUCCESS:
        case UNARCHIVE_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ARCHIVE_TASK_FAIL:
        case UNARCHIVE_TASK_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ARCHIVE_UNARCHIVE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const addSubtaskScheduleReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ADD_SUB_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ADD_SUB_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ADD_SUB_TASK_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ADD_SUB_TASK_RESET:
            return initialState;

        default:
            return state;
    }
};

export const updateTaskOrderingReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case UPDATE_TASK_ORDERING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_TASK_ORDERING_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case UPDATE_TASK_ORDERING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case UPDATE_TASK_ORDERING_RESET:
            return initialState;

        default:
            return state;
    }
};

export const updateTaskRangeReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case UPDATE_TASK_RANGE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_TASK_RANGE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case UPDATE_TASK_RANGE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case UPDATE_TASK_RANGE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const allProjectMemberScheduleReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case ALL_PROJECT_MEMBER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_PROJECT_MEMBER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_PROJECT_MEMBER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        default:
            return state;
    }
};

export const findPermissionsByEmailReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case FIND_PERMISSIONS_BY_EMAIL_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case FIND_PERMISSIONS_BY_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case FIND_PERMISSIONS_BY_EMAIL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case FIND_PERMISSIONS_BY_EMAIL_RESET:
            return initialState;

        default:
            return state;
    }
};

export const updatePermissionAssigneeReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case UPDATE_PERMISSION_ASSIGNEE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_PERMISSION_ASSIGNEE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case UPDATE_PERMISSION_ASSIGNEE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case UPDATE_PERMISSION_ASSIGNEE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const addPermissionAssigneeDirectlyReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case ADD_PERMISSION_ASSIGNEE_DIRECTLY_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ADD_PERMISSION_ASSIGNEE_DIRECTLY_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ADD_PERMISSION_ASSIGNEE_DIRECTLY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ADD_PERMISSION_ASSIGNEE_DIRECTLY_RESET:
            return initialState;

        default:
            return state;
    }
};

export const postTaskCommentReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case POST_TASK_COMMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case POST_TASK_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case POST_TASK_COMMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case POST_TASK_COMMENT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const editTaskCommentReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case EDIT_TASK_COMMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case EDIT_TASK_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case EDIT_TASK_COMMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case EDIT_TASK_COMMENT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const deleteTaskCommentReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DELETE_TASK_COMMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_TASK_COMMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DELETE_TASK_COMMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case DELETE_TASK_COMMENT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const removeTaskAssigneeReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case REMOVE_TASK_ASSIGNEE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case REMOVE_TASK_ASSIGNEE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case REMOVE_TASK_ASSIGNEE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case REMOVE_TASK_ASSIGNEE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const collapseTaskReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case COLLAPSE_TASK_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case COLLAPSE_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case COLLAPSE_TASK_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case COLLAPSE_TASK_RESET:
            return initialState;

        default:
            return state;
    }
};
