import { axiosClient } from '@/services/GatewayService';
import { AppDispatch } from '@/redux/store';

import {
    CHAT_LIST_REQUEST,
    CHAT_LIST_SUCCESS,
    // CHAT_LIST_FAIL,
    CHAT_DETAILS_REQUEST,
    CHAT_DETAILS_SUCCESS,
    CHAT_DETAILS_FAIL,
    CHAT_DETAILS_RESET,
    CHAT_PIN_REQUEST,
    CHAT_PIN_SUCCESS,
    CHAT_PIN_FAIL,
    CHAT_PIN_RESET,
    CHAT_UNPIN_REQUEST,
    CHAT_UNPIN_SUCCESS,
    CHAT_UNPIN_FAIL,
    CHAT_UNPIN_RESET,
    SEND_MESSAGE_REQUEST,
    SEND_MESSAGE_SUCCESS,
    SEND_MESSAGE_FAIL,
    SEND_MESSAGE_RESET,
    SEND_FILE_MESSAGE_REQUEST,
    SEND_FILE_MESSAGE_SUCCESS,
    SEND_FILE_MESSAGE_FAIL,
    DELETE_CHANNEL_CHAT_REQUEST,
    DELETE_CHANNEL_CHAT_SUCCESS,
    DELETE_CHANNEL_CHAT_FAIL,
    DELETE_CHANNEL_CHAT_RESET,
    NEW_DIRECT_MESSAGE_REQUEST,
    NEW_DIRECT_MESSAGE_SUCCESS,
    NEW_DIRECT_MESSAGE_FAIL,
    NEW_DIRECT_MESSAGE_RESET,
    NEW_GROUP_MESSAGE_REQUEST,
    NEW_GROUP_MESSAGE_SUCCESS,
    NEW_GROUP_MESSAGE_FAIL,
    NEW_GROUP_MESSAGE_RESET,
    EXIT_GROUP_REQUEST,
    EXIT_GROUP_SUCCESS,
    EXIT_GROUP_FAIL,
    EXIT_GROUP_RESET,
    EDIT_GROUP_REQUEST,
    EDIT_GROUP_SUCCESS,
    EDIT_GROUP_FAIL,
    EDIT_GROUP_RESET,
    KICK_ADMIN_REQUEST,
    KICK_ADMIN_SUCCESS,
    KICK_ADMIN_FAIL,
    KICK_ADMIN_RESET,
    KICK_MEMBER_REQUEST,
    KICK_MEMBER_SUCCESS,
    KICK_MEMBER_FAIL,
    KICK_MEMBER_RESET,
    PROMOTE_ADMIN_REQUEST,
    PROMOTE_ADMIN_SUCCESS,
    PROMOTE_ADMIN_FAIL,
    PROMOTE_ADMIN_RESET,
    PROMOTE_MEMBER_REQUEST,
    PROMOTE_MEMBER_SUCCESS,
    PROMOTE_MEMBER_FAIL,
    PROMOTE_MEMBER_RESET,
    INVITE_TO_CURRENT_GROUP_REQUEST,
    INVITE_TO_CURRENT_GROUP_SUCCESS,
    INVITE_TO_CURRENT_GROUP_FAIL,
    INVITE_TO_CURRENT_GROUP_RESET,
    ALL_USER_REQUEST,
    ALL_USER_SUCCESS,
    ALL_USER_FAIL,
    DELETE_CHAT_REQUEST,
    DELETE_CHAT_SUCCESS,
    DELETE_CHAT_FAIL,
    DELETE_CHAT_RESET,
    RESET_CHAT_DETAILS_BROADCAST,
    RESET_CHAT_DETAILS_NEW_MESSAGE,
    TOTAL_ONLINE_REQUEST,
    TOTAL_ONLINE_SUCCESS,
    TOTAL_ONLINE_FAIL,
    SET_ONLINE_REQUEST,
    SET_ONLINE_SUCCESS,
    SET_ONLINE_FAIL,
    CHAT_MARK_AS_READ_REQUEST,
    CHAT_MARK_AS_READ_SUCCESS,
    CHAT_MARK_AS_READ_FAIL,
    // CHAT_MARK_AS_READ_RESET,
} from '../reducers';

/**
 * Function to get message list of user, with broadcast channel
 *
 * @return Promise<void>
 */
export const getChatList = (params: { page: number; per_page?: number }): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CHAT_LIST_REQUEST });
        try {
            const res = await axiosClient.post('user/messages/channels/broadcast', null, {
                params: { ...params, intercept_error: true },
            });

            dispatch({
                type: CHAT_LIST_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {}
    };
};

/**
 * Function to get details of message with broadcast channel
 *
 * @return Promise<void>
 */
export const getChatDetailsById = (params: {
    channel_id: number;
    page?: number;
    per_page?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CHAT_DETAILS_REQUEST });
        try {
            const res = await axiosClient.post('user/messages/broadcast', null, {
                params,
            });

            dispatch({
                type: CHAT_DETAILS_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: CHAT_DETAILS_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to pin chat by id
 *
 * @return Promise<void>
 */
export const pinChatById = (params: { channel_id: number }): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CHAT_PIN_REQUEST });
        try {
            const res = await axiosClient.put('user/messages/channels/pin', null, {
                params,
            });

            dispatch({
                type: CHAT_PIN_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: CHAT_PIN_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to unpin chat by id
 * @return Promise<void>
 */
export const unpinChatById = (params: { channel_id: number }): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CHAT_UNPIN_REQUEST });
        try {
            const res = await axiosClient.put('user/messages/channels/unpin', null, {
                params,
            });

            dispatch({
                type: CHAT_UNPIN_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: CHAT_UNPIN_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get details of message with broadcast channel
 *
 * @return Promise<void>
 */
export const sendMessageText = (params: { channel_id: number }, data: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SEND_MESSAGE_REQUEST });
        try {
            const res = await axiosClient.post('user/messages/text', data, {
                params,
            });

            dispatch({
                type: SEND_MESSAGE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: SEND_MESSAGE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get details of message with broadcast channel
 *
 * @return Promise<void>
 */
export const sendFileMessage = (params: { channel_id: number }, data: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SEND_FILE_MESSAGE_REQUEST });
        try {
            const res = await axiosClient.post('user/messages/document', data, {
                params,
            });

            dispatch({
                type: SEND_FILE_MESSAGE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: SEND_FILE_MESSAGE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get details of message with broadcast channel
 *
 * @return Promise<void>
 */
export const createDirectMessage = (data: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: NEW_DIRECT_MESSAGE_REQUEST });
        try {
            const res = await axiosClient.post('user/messages/channels/direct', data);

            dispatch({
                type: NEW_DIRECT_MESSAGE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: NEW_DIRECT_MESSAGE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get details of message with broadcast channel
 *
 * @return Promise<void>
 */
export const createGroupMessage = (data: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: NEW_GROUP_MESSAGE_REQUEST });
        try {
            const res = await axiosClient.post('user/messages/channels/group', data);

            dispatch({
                type: NEW_GROUP_MESSAGE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: NEW_GROUP_MESSAGE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get details of message with broadcast channel
 *
 * @return Promise<void>
 */
export const deleteChatChannel = (params: { id: number }): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_CHANNEL_CHAT_REQUEST });
        try {
            const res = await axiosClient.delete('user/messages/channels', {
                params,
            });

            dispatch({
                type: DELETE_CHANNEL_CHAT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DELETE_CHANNEL_CHAT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get delete message
 *
 * @return Promise<void>
 */
export const deleteMessage = (params: { id: number }): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_CHAT_REQUEST });
        try {
            const res = await axiosClient.delete('user/messages', {
                params,
            });

            dispatch({
                type: DELETE_CHAT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DELETE_CHAT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get details of message with broadcast channel
 *
 * @return Promise<void>
 */
export const exitFromGroup = (params: { channel_id: number }): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: EXIT_GROUP_REQUEST });
        try {
            const res = await axiosClient.delete('user/messages/channels/group', {
                params,
            });

            dispatch({
                type: EXIT_GROUP_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: EXIT_GROUP_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to edit group details
 *
 * @return Promise<void>
 */
export const editGroup = (params: { channel_id: number }, formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: EDIT_GROUP_REQUEST });
        try {
            const res = await axiosClient.post(
                'user/messages/channels/group/metadata',
                formData,
                {
                    params,
                },
            );

            dispatch({
                type: EDIT_GROUP_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: EDIT_GROUP_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get details of message with broadcast channel
 *
 * @return Promise<void>
 */
export const kickAdminFromGroup = (params: {
    channel_id: number;
    email: string;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: KICK_ADMIN_REQUEST });
        try {
            const res = await axiosClient.delete(
                'user/messages/channels/administrators',
                {
                    params,
                },
            );

            dispatch({
                type: KICK_ADMIN_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: KICK_ADMIN_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get details of message with broadcast channel
 *
 * @return Promise<void>
 */
export const kickMemberFromGroup = (params: {
    channel_id: number;
    email: string;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: KICK_MEMBER_REQUEST });
        try {
            const res = await axiosClient.delete('user/messages/channels/participants', {
                params,
            });

            dispatch({
                type: KICK_MEMBER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: KICK_MEMBER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get details of message with broadcast channel
 *
 * @return Promise<void>
 */
export const promoteAdminFromGroup = (
    params: {
        channel_id: number;
    },
    data: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: PROMOTE_ADMIN_REQUEST });
        try {
            const res = await axiosClient.post(
                'user/messages/channels/administrators',
                data,
                {
                    params,
                },
            );

            dispatch({
                type: PROMOTE_ADMIN_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: PROMOTE_ADMIN_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get details of message with broadcast channel
 *
 * @return Promise<void>
 */
export const promoteMemberFromGroup = (
    params: {
        channel_id: number;
    },
    data: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: PROMOTE_MEMBER_REQUEST });
        try {
            const res = await axiosClient.post(
                'user/messages/channels/participants',
                data,
                {
                    params,
                },
            );

            dispatch({
                type: PROMOTE_MEMBER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: PROMOTE_MEMBER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get details of message with broadcast channel
 *
 * @return Promise<void>
 */
export const inviteToCurrentGroup = (
    params: {
        channel_id: number;
    },
    data: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: INVITE_TO_CURRENT_GROUP_REQUEST });
        try {
            const res = await axiosClient.post(
                'user/messages/channels/participants/new',
                data,
                {
                    params,
                },
            );

            dispatch({
                type: INVITE_TO_CURRENT_GROUP_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: INVITE_TO_CURRENT_GROUP_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get details of message with broadcast channel
 *
 * @return Promise<void>
 */
export const getAllUserChat = (params: {
    page: number;
    per_page: number;
    search: string;
    sort_by: string;
    sort_asc?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_USER_REQUEST });
        try {
            const res = await axiosClient.get('user/messages/accounts', {
                params,
            });

            dispatch({
                type: ALL_USER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_USER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get total online of users
 *
 * @return Promise<void>
 */
export const getTotalOnlineChat = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: TOTAL_ONLINE_REQUEST });
        try {
            const res = await axiosClient.get('user/messages/accounts/online', {
                params: {
                    intercept_error: true,
                },
            });

            dispatch({
                type: TOTAL_ONLINE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: TOTAL_ONLINE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get total online of users
 *
 * @return Promise<void>
 */
export const setOnline = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SET_ONLINE_REQUEST });
        try {
            const res = await axiosClient.put('user/account/online');

            dispatch({
                type: SET_ONLINE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: SET_ONLINE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

export const chatMarkAsRead = (channel_id: number): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CHAT_MARK_AS_READ_REQUEST });
        try {
            const res = await axiosClient.put('user/messages/channels/read', null, {
                params: { channel_id },
            });

            dispatch({
                type: CHAT_MARK_AS_READ_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: CHAT_MARK_AS_READ_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to clear state of pin and unpin chat
 *
 * @returns Promise<void>
 */
export const clearStatePinUnpin = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CHAT_PIN_RESET });
        dispatch({ type: CHAT_UNPIN_RESET });
    };
};

/**
 * Function to clear state
 *
 * @returns Promise<void>
 */
export const clearStateDeleteChatChannel = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_CHANNEL_CHAT_RESET });
    };
};

export const clearStateNewMessage = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: NEW_DIRECT_MESSAGE_RESET });
        dispatch({ type: NEW_GROUP_MESSAGE_RESET });
    };
};

export const clearChatDetails = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CHAT_DETAILS_RESET });
    };
};

export const clearStateExitGroup = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: EXIT_GROUP_RESET });
    };
};

export const clearStateEditGroup = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: EDIT_GROUP_RESET });
    };
};

export const clearStateParticipantsGroup = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: KICK_ADMIN_RESET });
        dispatch({ type: KICK_MEMBER_RESET });
    };
};

export const clearStatePromote = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: PROMOTE_ADMIN_RESET });
        dispatch({ type: PROMOTE_MEMBER_RESET });
    };
};

export const clearStateInviteToCurrentGroup = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: INVITE_TO_CURRENT_GROUP_RESET });
    };
};

export const clearStateDeleteMessage = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_CHAT_RESET });
    };
};

export const clearSendNewMessage = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SEND_MESSAGE_RESET });
    };
};

export const clearBroadcastChatDetails = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESET_CHAT_DETAILS_BROADCAST });
    };
};

export const clearDetailsNewMessage = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESET_CHAT_DETAILS_NEW_MESSAGE });
    };
};
