import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, Modal } from 'react-bootstrap';

import { AiOutlineCheckCircle } from 'react-icons/ai';

import InputBasic from '@/components/atoms/Inputs/InputBasic';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import ModalBasic from '@/components/atoms/Modals/ModalBasic';

import { deleteCurrentAccount, clearStateDeleteAccount } from '../redux/actions';
import styled from 'styled-components';
import { RootState } from '@/redux/store';

interface ParamsProps {
    modal: {
        deleteAccount: boolean;
        success: boolean;
    };
    setModal: (value: any) => void;
}

interface DataProps {
    id: number;
    title: string;
}

export default function DeleteAccount(params: ParamsProps): JSX.Element {
    const { modal, setModal } = params;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<any>({
        criteriaMode: 'all',
    });

    const currentProfileState = useSelector((state: RootState) => state.currentProfile);
    const deleteAccountState = useSelector((state: RootState) => state.deleteAccount);

    const companyName = currentProfileState?.response?.company?.company_name;

    const rules: DataProps[] = [
        {
            id: 1,
            title: 'All In-house Users from this company will not be able to log in during the deletion process. When the day of deletion arrives, the In-house User account will also be deleted',
        },
        {
            id: 2,
            title: 'All Downlines (Companies working for the Company Owner User) will be automatically locked out of the projects they participate in (as sub-contractors from companies whose Company Owner deletes accounts)',
        },
        {
            id: 3,
            title: 'Everyone who is part of a project together with the Company of the Company Owner User will be given a notification that will notify that the Company Owner User of this Company is carrying out an attempted account deletion within 30 days of all data related to this Company in projects that followed will disappear without trace',
        },
    ];

    // clear state on update profile
    useEffect(() => {
        if (deleteAccountState.status === 200) {
            setModal((prev: any) => ({
                ...prev,
                deleteAccount: false,
            }));

            dispatch(clearStateDeleteAccount());

            setTimeout(() => {
                setModal((prev: any) => ({
                    ...prev,
                    success: true,
                }));
            }, 1000);
        }
    }, [deleteAccountState]);

    /**
     * Function on submit
     *
     * @param any data
     * @return void
     * @see cypress/e2e/authentication/login/login.cy.ts
     *      To cypress unit tester
     */
    const onSubmit = (data: any): void => {
        // Dispatch login action with form data as payload
        dispatch(deleteCurrentAccount());
    };

    return (
        <>
            <ModalBasic
                modal={modal.deleteAccount}
                setModal={setModal}
                title="Are you sure want to delete your account?"
                size="lg"
                withClose
                // onConfirm={() => {
                //     setModal({ ...modal, success: false });
                // }}
            >
                <div className="px-5 py-3">
                    <form action="#" onSubmit={handleSubmit(onSubmit)} method="post">
                        <div className="text-start">
                            {rules.map(list => (
                                <Fragment key={list.id}>
                                    <Form.Check
                                        id={list.title}
                                        type="checkbox"
                                        label={list.title}
                                        className="mt-3"
                                        value={list.id}
                                        {...register(list?.id.toString(), {
                                            required: 'Please check this box',
                                        })}
                                    />
                                    <div
                                        className="invalid-feedback d-block text-start"
                                        style={{ fontSize: '0.75rem' }}
                                    >
                                        {errors?.[list?.id.toString()] != null && (
                                            <p>
                                                {
                                                    errors?.[list?.id?.toString()]
                                                        ?.message as string
                                                }
                                            </p>
                                        )}
                                    </div>
                                </Fragment>
                            ))}
                            <InputBasic
                                id="companyName"
                                type="text"
                                placeholder="Company Name"
                                outerClassName="mt-4 mb-5"
                                // icon={UserIcons}
                                rules={{
                                    function: register,
                                    name: 'company_name',
                                    rules: {
                                        required: 'Please enter your company name',
                                        // value must be same with company name
                                        validate: (value: string) =>
                                            value === companyName ||
                                            'The company name is not match, make sure you enter the correct company name',
                                    },
                                    errors,
                                }}
                            />
                        </div>
                        <ButtonBasic
                            id="btnSubmit"
                            type="submit"
                            text="Delete Company"
                            className="my-3 btn-danger"
                            loading={deleteAccountState.loading}
                        />
                    </form>
                </div>
            </ModalBasic>
            <ModalWrapper
                show={modal.success}
                onHide={() => navigate('/')}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <h5 className="fw-semibold text-center">Account Deleted</h5>
                </Modal.Header>
                <Modal.Body>
                    <AiOutlineCheckCircle className="text-success fs-1" size={80} />
                    <p className="mt-3 text-center">Successfully deleted account</p>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonBasic
                        className="btn-success"
                        text="Confirm"
                        onClick={() => navigate('/')}
                    />
                </Modal.Footer>
            </ModalWrapper>
        </>
    );
}

const ModalWrapper = styled(Modal)`
    .modal-content {
        font-family: 'Montserrat', sans-serif;
        padding: 0.5rem 1rem;
        border-radius: 20px;
    }

    .modal-header {
        border: none;
        justify-content: center;

        .btn-close {
            position: absolute;
            right: 20px;
            top: 25px;
            font-size: 10px;
        }
    }
    .modal-body {
        text-align: center;
        font-size: 14px;
        padding: 0;
    }
    .modal-footer {
        justify-content: center;
        border: none;
        flex-wrap: initial;
    }
`;
