import { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import MailAlt from '@/assets/svg/icons/mail-alt-3.svg';

import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import Header from '@/components/molecules/Header';
import InputBasic from '@/components/atoms/Inputs/InputBasic';
import Loader from '@/components/molecules/Loader';
import { Title } from '@/components/molecules/Typography';

import { forgotPasswordEmail } from '@/routes/Authentication/ForgotPassword/redux/actions';
import { RootState } from '@/redux/store';

interface FormDataProps {
    email: string;
}

export default function EnterEmail(): JSX.Element {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormDataProps>({
        criteriaMode: 'all',
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const emailState = useSelector((state: RootState) => state.forgotPasswordEmail);

    useEffect(() => {
        if (emailState.status === 200) {
            // Set email to session storage
            sessionStorage.setItem('email', emailState?.response?.address);
            // Redirect to enter code page if email is sent
            navigate('/forgot-password/email-sent', {
                state: { nextStep: true },
            });
        }
    }, [emailState]);

    /**
     * Function on submit
     *
     * @param {object} data
     * @return {void}
     */
    const onSubmit = (data: FormDataProps): void => {
        // initialize form data
        const payload = new FormData();

        // append data to form data
        for (const key in data) {
            payload.append(key, data[key as keyof typeof data]);
        }

        // Dispatch login action with form data as payload
        dispatch(forgotPasswordEmail(payload));
    };

    return (
        <Fragment>
            {emailState.loading === true && <Loader />}
            <div className="container-fluid container-xs container-lg">
                <header>
                    <Header text="Return to home screen" link="/" />
                </header>
                <main className="main-content">
                    <div className="row mb-5">
                        <div className="col-md-12 text-center">
                            <Title text="Email Verification" />
                        </div>
                        <div className="col-md-8 col-lg-6 col-xxl-4 mx-auto text-center mb-3">
                            <span className="text-muted">
                                Please enter your email that registered on system to
                                verification & reset your password.
                            </span>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-8 col-lg-6 col-xxl-4 mx-auto text-center">
                            <form
                                action="#"
                                onSubmit={handleSubmit(onSubmit)}
                                method="post"
                            >
                                <InputBasic
                                    id="email"
                                    type="email"
                                    placeholder="Email"
                                    icon={MailAlt}
                                    name="email"
                                    rules={{
                                        function: register,
                                        name: 'email',
                                        rules: {
                                            required: 'Email is required',
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message:
                                                    'Entered value does not match email format',
                                            },
                                        },
                                        errors,
                                    }}
                                />
                                <ButtonBasic
                                    type="submit"
                                    text="Confirm"
                                    className="my-3 w-50 btn-primary"
                                />
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        </Fragment>
    );
}
