import Cookies from 'js-cookie';

import { axiosClient } from '@/services/GatewayService';
import { AppDispatch } from '@/redux/store';
import {
    // beamsClient, beamsTokenProvider,
    encryptData,
} from '@/utility/Utils';

import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_REQUEST,
} from '../reducers';

/**
 * Function login user
 *
 * @param {FormData} formData
 * @return {Promise<void>}
 */
export const login = (formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: LOGIN_REQUEST });
        try {
            const res = await axiosClient.post('auth/login', formData);

            // if remember me is true, set cookie expires on session/default 1 month
            (formData.get('rememberMe') as any) !== 'false'
                ? Cookies.set('user', encryptData(JSON.stringify(res.data)), {
                      sameSite: 'None',
                      secure: true,
                  })
                : Cookies.set('user', encryptData(JSON.stringify(res.data)), {
                      expires: 1,
                      sameSite: 'None',
                      secure: true,
                  });

            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            });

            const redirectUrl = sessionStorage.getItem('redirectUrl');

            // If redirect url is not empty, redirect to url
            if (redirectUrl !== null) {
                window.location.href = redirectUrl;
                sessionStorage.removeItem('redirectUrl');
                return;
            }

            // TODO:ADD After bakcend ready
            // const { phone } = res?.data;
            // if (phone === null) {
            //     window.location.href = '/setting/company';
            //     return;
            // }

            window.location.href = '/';
        } catch (error: any) {
            dispatch({
                type: LOGIN_FAIL,
                payload: error?.response?.data?.message,
            });
        }
    };
};

/**
 * Function get csrf token
 *
 * @return {Promise<void>}
 */
export const getCSRF = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: LOGIN_REQUEST });
        try {
            // get csrf token
            await axiosClient.get('sanctum/csrf-cookie');
        } catch (error: any) {
            // eslint-disable-next-line no-console
            throw new Error(error);
        }
    };
};

/**
 * Function logout
 *
 * @return {Promise<void>}
 */
export const logout = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: LOGOUT_REQUEST });

        try {
            const res = await axiosClient.delete('auth/logout');

            // await beamsClient.stop();

            Cookies.remove('user');

            dispatch({
                type: LOGOUT_SUCCESS,
                payload: res.data,
            });

            window.location.href = '/login';
        } catch (error: any) {
            // await beamsClient.stop();

            Cookies.remove('user');

            dispatch({
                type: LOGIN_FAIL,
                payload: error?.response?.data?.message,
            });

            window.location.href = '/login';
        }
    };
};

/**
 * Function login user
 *
 * @return {Promise<void>}
 */
export const loginGoogle = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: LOGIN_REQUEST });
        try {
            const res = await axiosClient.post('auth/provider/google');

            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: LOGIN_FAIL,
                payload: error?.response?.data?.message,
            });
        }
    };
};
