import { useEffect, useState } from 'react';
import { CloseButton, Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import InputSearchSelect from '@/components/atoms/Inputs/InputSearchSelect';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';

import styled from 'styled-components';
import { RootState } from '@/redux/store';

import { AddAssigneeModalsProps } from '../../interfaces';
import { generateInitialImageUrl } from '@/utility/Utils';
import {
    addPermissionAssigneeDirectly,
    findPermissionByEmail,
    getAllProjectMemberSchedule,
    resetFindPermissionByEmail,
    updatePermissionAssignee,
} from '../../redux/actions';
import { useAppDispatch } from '@/redux/hook';

export default function AddAssignee({
    subtask,
    type,
    modal,
    setModal,
    projectId,
    assignees,
    setModalType,
    setAssignees,
    selectedTask,
    handleDeleteAssignee,
}: AddAssigneeModalsProps): JSX.Element {
    const dispatch = useAppDispatch();
    const [users, setUsers] = useState<any>([]);
    const [selectedUsers, setSelectedUsers] = useState<any>([]);

    const allProjectMemberScheduleState = useSelector(
        (state: RootState) => state.allProjectMemberSchedule,
    );

    const findPermissionsByEmailState = useSelector(
        (state: RootState) => state.findPermissionsByEmail,
    );

    const updatePermissionAssigneeState = useSelector(
        (state: RootState) => state.updatePermissionAssignee,
    );

    useEffect(() => {
        handleListUser();
    }, [allProjectMemberScheduleState, assignees]);

    const handleListUser = (): void => {
        if (allProjectMemberScheduleState?.status === 200) {
            const data = allProjectMemberScheduleState?.response;

            const newData = data.map(
                (item: { user_name: string; user_email: string }) => {
                    return {
                        label: `${item.user_name} - ${item.user_email}`,
                        value: item.user_email,
                    };
                },
            );

            // Filter out duplicate users from assignees
            const filteredData = newData.filter(
                (item: any) =>
                    !assignees.some(
                        (assignee: any) => assignee.user_email === item.value,
                    ),
            );

            setUsers(filteredData);
        }
    };

    useEffect(() => {
        if (modal.addAssignee) {
            dispatch(
                getAllProjectMemberSchedule({
                    project_id: projectId,
                }),
            );
        }
    }, [modal.addAssignee]);

    const handleNumberAccess = (
        can_collaborate_schedule: boolean,
        can_view_schedule: boolean,
    ): number => {
        let result = 0;

        if (can_collaborate_schedule) {
            result = 2;
        } else if (can_view_schedule) {
            result = 1;
        }

        return result;
    };

    useEffect(() => {
        if (
            findPermissionsByEmailState.loading !== true &&
            findPermissionsByEmailState?.status === 200
        ) {
            const response = findPermissionsByEmailState?.response;

            const designPermission = response?.member_space_schedules?.find(
                (item: any) => item.space_type === 'design',
            );

            const constructionPermission = response?.member_space_schedules?.find(
                (item: any) => item.space_type === 'construction',
            );

            // const privatePermission = response?.member_space_schedules?.find(
            //     (item: any) => item.space_type === 'private',
            // );

            const designAccess =
                designPermission !== undefined
                    ? {
                          type: 'Design',
                          access: handleNumberAccess(
                              designPermission?.can_collaborate_schedule,
                              designPermission?.can_view_schedule,
                          ),
                          disable_option: designPermission?.disable_option,
                          no_access_disabled: designPermission?.no_access_disabled,
                      }
                    : {
                          type: 'Design',
                          access: 0,
                          disable_option: true,
                          no_access_disabled: true,
                      };

            const constructionAccess =
                constructionPermission !== undefined
                    ? {
                          type: 'Construction',
                          access: handleNumberAccess(
                              constructionPermission?.can_collaborate_schedule,
                              constructionPermission?.can_view_schedule,
                          ),
                          disable_option: constructionPermission?.disable_option,
                          no_access_disabled: constructionPermission?.no_access_disabled,
                      }
                    : {
                          type: 'Construction',
                          access: 0,
                          disable_option: true,
                          no_access_disabled: true,
                      };

            // const privateAccess =
            //     privatePermission !== undefined
            //         ? {
            //               type: 'Private',
            //               access: handleNumberAccess(
            //                   privatePermission?.can_collaborate_schedule,
            //                   privatePermission?.can_view_schedule,
            //               ),
            //           }
            //         : undefined;

            setAssignees((prev: any) => {
                // Check if the email already exists in the assignees list
                const isDuplicateEmail: boolean = prev.some(
                    (assignee: any) => assignee.user_email === response?.user_email,
                );

                // If the email is not a duplicate, add it to the assignees list
                if (!isDuplicateEmail) {
                    return [
                        ...prev,
                        {
                            id: response?.id,
                            can_invite_user: response?.can_invite_schedule === 1,
                            message: response?.message,
                            user_email: response?.user_email,
                            user_name: response?.user_name,
                            user_avatar: response?.user_avatar,
                            disabled: response?.disabled,
                            permission: [designAccess, constructionAccess],
                        },
                    ];
                }

                // If the email is a duplicate, return the previous assignees list unchanged
                return prev;
            });

            // reset state
            dispatch(resetFindPermissionByEmail());
        }
    }, [findPermissionsByEmailState]);

    const handleFinalizeUsers = (): void => {
        selectedUsers.forEach((item: any) => {
            const payload = new FormData();

            payload.append('project_id', projectId);
            payload.append('type', type);
            payload.append('email', item.value);

            handleFindPermission(payload, item.value);
        });

        // reset state
        setSelectedUsers([]);
    };

    const handleFindPermission = (payload: FormData, email: string): void => {
        return dispatch(findPermissionByEmail(payload, email));
    };

    const handleChangePermission = (
        index: number,
        key: number,
        itemType: string,
        Type: string,
        value: number | boolean,
    ): void => {
        setAssignees(prev => {
            const newData = prev.map((item: any, i: number) => {
                if (i === index) {
                    if (Type === 'radio') {
                        const newPermission = item.permission.map(
                            (permissionItem: any, j: number) => {
                                if (j === key) {
                                    return {
                                        ...permissionItem,
                                        access: value,
                                    };
                                }
                                return permissionItem;
                            },
                        );

                        return {
                            ...item,
                            permission: newPermission,
                        };
                    }

                    if (Type === 'checkbox') {
                        return {
                            ...item,
                            can_invite_user: value,
                        };
                    }
                }
                return item;
            });

            return newData;
        });
    };

    const handleOnSubmit = (): void => {
        const assigneeData = assignees.map((item: any) => {
            return {
                id: item.id,
                email: item.user_email,
                member_space_schedules: item.permission
                    .map((permissionItem: any) => {
                        if (permissionItem !== undefined) {
                            return {
                                space_type: permissionItem.type.toLowerCase(),
                                can_view_schedule: permissionItem.access === 1 ? 1 : 0,
                                can_collaborate_schedule:
                                    permissionItem.access === 2 ? 1 : 0,
                            };
                        }
                        return null;
                    })
                    .filter(Boolean),
                can_invite_schedule: item.can_invite_user,
            };
        });

        const data = {
            project_id: projectId,
            type,
            assignees: assigneeData,
        };

        if (assignees.length > 0) {
            if (selectedTask !== null) {
                dispatch(addPermissionAssigneeDirectly(selectedTask.id, data));
            } else {
                dispatch(updatePermissionAssignee(data));
            }
        }

        setModal(prev => ({ ...prev, addAssignee: false }));
    };

    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/g;

    return (
        <ModalWrapper
            show={modal.addAssignee}
            onHide={() => setModal(prev => ({ ...prev, addAssignee: false }))}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header
                closeButton
                style={{
                    padding: '1.5rem 2.3rem',
                }}
            >
                <h6 className="fw-semibold mb-0">Add Assignee</h6>
            </Modal.Header>
            <Modal.Body className="p-0">
                {/* <form action="#" method="posts"> */}
                <div
                    className="row"
                    style={{
                        padding: '1.5rem 2.3rem',
                    }}
                >
                    <div className="col-md-12 py-3">
                        <div className="form-group">
                            <label htmlFor="colorId" className="text-start fw-semibold">
                                Invited User
                            </label>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="text-start w-75">
                                    <InputSearchSelect
                                        id="subtaskAssignee"
                                        placeholder="Type or select email"
                                        // innerClassName="mb-3"
                                        // outerClassName="px-4"
                                        options={users}
                                        isMulti
                                        withCreatable
                                        isClearable={true}
                                        placeholderCreatable="Invite"
                                        value={selectedUsers}
                                        // onChange={newValue => setSelectedUsers(newValue)}
                                        // TODO:WITH VALIDATION EMAIL
                                        onChange={(
                                            newValue,
                                            meta: {
                                                action: string;
                                                removedValue: {
                                                    value: string;
                                                    label: string;
                                                };
                                            },
                                        ) => {
                                            if (meta.action === 'remove-value') {
                                                // remove selected user
                                                setSelectedUsers((prev: any) =>
                                                    prev.filter(
                                                        (user: any) =>
                                                            user.value !==
                                                            meta.removedValue.value,
                                                    ),
                                                );

                                                // back to users
                                                setUsers((prev: any) => [
                                                    ...prev,
                                                    meta.removedValue,
                                                ]);
                                            }

                                            if (meta.action === 'clear') {
                                                setSelectedUsers([]);
                                                handleListUser();
                                            }

                                            newValue.forEach((item: any) => {
                                                const isValid = regex.test(item.value);

                                                if (isValid) {
                                                    // set selected user with previous
                                                    setSelectedUsers(newValue);

                                                    // remove from users
                                                    setUsers((prev: any) =>
                                                        prev.filter(
                                                            (user: any) =>
                                                                user.value !== item.value,
                                                        ),
                                                    );
                                                }
                                            });
                                        }}
                                        onBlur={e => {
                                            const isValid = regex.test(e.target.value);

                                            if (e.target.value !== '' && isValid) {
                                                // set selected user with previous
                                                const newData = {
                                                    label: e.target.value,
                                                    value: e.target.value,
                                                };
                                                setSelectedUsers((prev: any) => [
                                                    ...prev,
                                                    newData,
                                                ]);
                                            }
                                        }}
                                    />
                                </div>

                                <ButtonBasic
                                    id="btnInviteUser"
                                    text="Add Assignees"
                                    type="button"
                                    className="btn btn-primary btn-sm"
                                    onClick={handleFinalizeUsers}
                                    loading={findPermissionsByEmailState.loading}
                                    disabled={selectedUsers.length === 0}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="m-0" />

                {assignees.map((user, index: number) => (
                    <div key={index} className="row">
                        <div
                            className="col-md-12 px-4 py-2"
                            // style={{
                            //     padding: '0 2.3rem',
                            // }}
                        >
                            <div className="d-flex align-items-center px-4 py-3 justify-content-between">
                                <div>
                                    <UserAvatar
                                        src={
                                            user.user_avatar ??
                                            generateInitialImageUrl(
                                                user.user_name ?? user.user_email,
                                            )
                                        }
                                    />
                                    <span className="ms-2 fw-medium">
                                        {user.user_name ?? user.user_email}
                                    </span>
                                </div>
                                <CloseButton
                                    onClick={() => {
                                        handleDeleteAssignee(user.user_email);

                                        // back to users
                                        setUsers((prev: any) => [
                                            ...prev,
                                            {
                                                label: `${user.user_name} - ${user.user_email}`,
                                                value: user.user_email,
                                            },
                                        ]);
                                    }}
                                />
                            </div>
                            {/* {type !== 'private' ? ( */}
                            <div className="px-4 py-3">
                                <label
                                    htmlFor="colorId"
                                    className="text-start fw-semibold"
                                >
                                    Permission
                                </label>
                                {user.disabled && (
                                    <span className="text-danger d-block">
                                        Permission for this user has been disabled
                                    </span>
                                )}

                                <div className="row g-3">
                                    {/* <div className="col-md-12 col-lg-6"> */}
                                    {user?.permission?.length > 0 ? (
                                        user?.permission?.map(
                                            (item, key: number) =>
                                                item !== undefined && (
                                                    <div key={key} className="col-xl-6">
                                                        <label
                                                            htmlFor="colorId"
                                                            className="text-start fw-semibold"
                                                        >
                                                            {item?.type}
                                                        </label>
                                                        <div className="d-flex justify-content-between mt-3">
                                                            <Form.Check
                                                                id={`noAccess-${index}-${key}-${item?.type}`}
                                                                type="radio"
                                                                label="No Access"
                                                                className="me-3"
                                                                name={`permission-${index}-${key}-${item?.type}`}
                                                                defaultChecked={
                                                                    item?.access === 0
                                                                }
                                                                disabled={
                                                                    // item.disabled ||
                                                                    user.disabled ||
                                                                    item.no_access_disabled
                                                                }
                                                                onClick={() =>
                                                                    handleChangePermission(
                                                                        index,
                                                                        key,
                                                                        item?.type,
                                                                        'radio',
                                                                        0,
                                                                    )
                                                                }
                                                            />
                                                            <Form.Check
                                                                id={`viewOnly-${index}-${key}-${item?.type}`}
                                                                type="radio"
                                                                label="View Only"
                                                                className="me-3"
                                                                name={`permission-${index}-${key}-${item?.type}`}
                                                                defaultChecked={
                                                                    item?.access === 1
                                                                }
                                                                disabled={
                                                                    item.disable_option ===
                                                                        0 || user.disabled
                                                                }
                                                                onClick={() =>
                                                                    handleChangePermission(
                                                                        index,
                                                                        key,
                                                                        item?.type,
                                                                        'radio',
                                                                        1,
                                                                    )
                                                                }
                                                            />
                                                            <Form.Check
                                                                id={`contributor-${index}-${key}-${item?.type}`}
                                                                type="radio"
                                                                label="Collaborator"
                                                                className="me-3"
                                                                name={`permission-${index}-${key}-${item?.type}`}
                                                                defaultChecked={
                                                                    item?.access === 2
                                                                }
                                                                disabled={
                                                                    item.disable_option ===
                                                                        0 ||
                                                                    item.disable_option ===
                                                                        1 ||
                                                                    user.disabled
                                                                }
                                                                onClick={() =>
                                                                    handleChangePermission(
                                                                        index,
                                                                        key,
                                                                        item?.type,
                                                                        'radio',
                                                                        2,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                ),
                                        )
                                    ) : (
                                        <span>No Permission</span>
                                    )}
                                    {/* </div> */}
                                </div>
                                <div className="mt-3">
                                    <label
                                        htmlFor="colorId"
                                        className="text-start fw-semibold"
                                    >
                                        Invite Other Members Outside Project
                                    </label>
                                    <Form.Check
                                        id={`inviteOutside-${index}`}
                                        type="checkbox"
                                        label="Ability to invite users"
                                        className="me-3 mt-3"
                                        name={`permission-${index}`}
                                        value={user?.can_invite_user?.toString()}
                                        defaultChecked={user?.can_invite_user}
                                        disabled={user?.disabled}
                                        onClick={(e: any) =>
                                            handleChangePermission(
                                                index,
                                                0,
                                                '',
                                                'checkbox',
                                                e.target.checked,
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            {/* ) : (
                                    <div className="text-center mb-4">
                                        <span className="text-muted">
                                            Permission for Private will automatically be
                                            set to Collaborator yet
                                        </span>
                                    </div>
                                )} */}
                        </div>

                        <hr className="m-0" />
                    </div>
                ))}
                <div className="p-3 text-center">
                    <ButtonBasic
                        id="btnSubmit"
                        text="Confirm"
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={handleOnSubmit}
                        loading={updatePermissionAssigneeState.loading}
                    />
                </div>
                {/* </form> */}
            </Modal.Body>
        </ModalWrapper>
    );
}

const ModalWrapper = styled(Modal)`
    background: #00000078;
`;

const UserAvatar = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
`;
