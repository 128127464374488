import { Modal } from 'react-bootstrap';

import { BiArrowBack } from 'react-icons/bi';
import { HiOutlineDocumentDownload } from 'react-icons/hi';
import // BsFillArrowLeftCircleFill,
// BsFillArrowRightCircleFill,
// BsThreeDotsVertical,
'react-icons/bs';

import { OpenFile, Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { SelectionMode } from '@react-pdf-viewer/selection-mode';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

// import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
// import CSVReader from 'react-csv-reader';
import ImageViewer from 'react-simple-image-viewer';

import packageJson from '../../../../../package.json';

import styled from 'styled-components';
import BasicTooltip from '@/components/atoms/Tooltips';

interface ParamsProps {
    // modal: boolean;
    // setModal: (value: any) => void;
    // list: any;
    // selected: {
    //     id: number;
    //     name: string;
    //     link: string;
    //     type: string;
    //     file: string;
    //     format: string;
    // };
    // setSelected: (value: any) => void;
    filePreview: {
        isOpen: boolean;
        name: string;
        link: string;
    };
    setFilePreview: (value: any) => void;
}

export default function PreviewChatFile(props: ParamsProps): JSX.Element {
    const { filePreview, setFilePreview } = props;

    const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];

    const format = filePreview.name.substring(filePreview.name.lastIndexOf('.') + 1);

    const pdfWorker = `https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`;
    // const pdfWorker = 'https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js';

    // const handleBack = (id: number): void => {
    //     const index: number = list.data.findIndex((item: any) => item.id === id);
    //     if (index > 0) {
    //         setSelected(list.data[index - 1]);
    //     }
    // };

    // const handleNext = (id: number): void => {
    //     const index: number = list.data.findIndex((item: any) => item.id === id);
    //     if (index < list.data.length - 1) {
    //         setSelected(list.data[index + 1]);
    //     }
    // };

    const toolbarPluginInstance = toolbarPlugin({
        getFilePlugin: {
            fileNameGenerator: (file: OpenFile) => {
                const fileName = file.name.substring(file.name.lastIndexOf('/') + 1);
                return fileName;
            },
        },
        searchPlugin: {
            keyword: 'PDF',
        },
        selectionModePlugin: {
            selectionMode: SelectionMode.Hand,
        },
    });
    const { Toolbar } = toolbarPluginInstance;

    /**
     * Function for handle header
     *
     * @param selected object
     * @returns void
     */
    const HeaderPreview = (): JSX.Element => {
        // // get format file
        // // const format = selected.name.substring(selected.name.lastIndexOf('.') + 1);
        // const format = selected.format;

        return (
            <div className="d-flex flex-column w-100">
                <div
                    className="d-flex justify-content-between text-white p-3"
                    style={{
                        backgroundColor: 'rgb(48, 48, 48)',
                    }}
                >
                    <div className="d-flex align-items-center">
                        <BasicTooltip text="Back" placement="bottom">
                            <BiArrowBack
                                onClick={() =>
                                    setFilePreview({ ...filePreview, isOpen: false })
                                }
                                size={20}
                                color="#fff"
                                style={{
                                    cursor: 'pointer',
                                }}
                            />
                        </BasicTooltip>
                        <h6 className="fw-semibold mb-0 ms-2">{filePreview.name}</h6>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        {/* <BasicTooltip text="Previous" placement="bottom">
                            <BsFillArrowLeftCircleFill
                                size={20}
                                color="#fff"
                                onClick={() => handleBack(selected.id)}
                                role="button"
                                className="mx-3"
                            />
                        </BasicTooltip> */}
                        <BasicTooltip text="Download" placement="bottom">
                            <HiOutlineDocumentDownload
                                size={20}
                                color="#fff"
                                className="mx-3"
                                role="button"
                                onClick={() =>
                                    handleDownload(filePreview.name, filePreview.link)
                                }
                            />
                        </BasicTooltip>
                        {/* <BasicTooltip text="Next" placement="bottom">
                            <BsFillArrowRightCircleFill
                                size={20}
                                color="#fff"
                                onClick={() => handleNext(selected.id)}
                                className="mx-3"
                                role="button"
                            />
                        </BasicTooltip> */}
                        {/* <BsThreeDotsVertical
                            size={20}
                            color="#fff"
                            style={{
                                cursor: 'pointer',
                            }}
                            className="mx-3"
                        /> */}
                    </div>
                </div>
                {format === 'pdf' && (
                    <div
                        className="p-3"
                        style={{
                            backgroundColor: 'rgb(213, 213, 213)',
                        }}
                    >
                        <Toolbar />
                    </div>
                )}
            </div>
        );
    };

    // const papaparseOptions = {
    //     header: true,
    //     dynamicTyping: true,
    //     skipEmptyLines: true,
    //     transformHeader: (header: 'name') => header.toLowerCase().replace(/\W/g, '_'),
    // };
    /**
     * Function for handle view
     *
     * @param selected
     * @returns JSX.Element
     */
    const BodyPreview = (): JSX.Element => {
        // get format file
        // const format = selected.name.substring(selected.name.lastIndexOf('.') + 1);

        switch (format) {
            case 'pdf':
                return (
                    <Viewer
                        fileUrl={filePreview.link}
                        plugins={[toolbarPluginInstance as any]}
                    />
                );
            // case 'image':
            //     return <img src={selected.link} alt="" className="img-preview" />;
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return (
                    <ImageViewer
                        src={[filePreview.link]}
                        disableScroll
                        // closeOnClickOutside
                        // alt={isPreviewImage}
                        onClose={() => setFilePreview({ ...filePreview, isOpen: false })}
                    />
                );

            default:
                // return <div>File not supported</div>;
                return (
                    <DocViewer
                        pluginRenderers={DocViewerRenderers}
                        className="w-100 h-100"
                        config={{
                            header: {
                                disableHeader: true,
                                disableFileName: true,
                                retainURLParams: true,
                            },
                            csvDelimiter: ',',
                        }}
                        // pluginRenderers={{
                        //     fileType: {
                        //         pptx: () => <div>File not supported</div>,
                        //     },
                        // }}
                        documents={[{ uri: filePreview.link }]}
                    />
                );
        }
    };

    /**
     * Function to download file open new tab
     * @param file
     *
     * @returns void
     */
    const handleDownload = (name: string, file: string): void => {
        const a = document.createElement('a');
        a.href = file;
        a.download = name;
        a.target = '_blank';
        a.rel = 'noopener noreferrer';
        a.click();
    };

    return (
        <ModalViewer
            show={filePreview.isOpen}
            // onHide={() => setFilePreview({ ...filePreview, isOpen: false })}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            fullscreen
        >
            <Worker workerUrl={pdfWorker}>
                <Modal.Header
                    className="p-0"
                    style={{
                        zIndex: 2,
                    }}
                >
                    <HeaderPreview />
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-between align-items-center p-0">
                    {/* File */}
                    <BodyPreview />
                    {/* End File */}
                </Modal.Body>
            </Worker>
        </ModalViewer>
    );
}

const ModalViewer = styled(Modal)`
    .img-preview {
        width: 90%;
        height: 80%;
        object-fit: cover;
    }

    .modal-content {
        background: transparent;
        border: none;
    }

    .modal-dialog {
        margin: 0;
    }

    .modal-header {
        border-radius: 0;
        border: none;
    }
`;
