import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PinIcon from '@/assets/svg/icons/pin.svg';

import { getAllProject } from '../redux/actions';

import styled from 'styled-components';
import { RootState } from '@/redux/store';
import { Link } from 'react-router-dom';
import TableBorderless from '@/components/organism/TableBorderless';
import { Form } from 'react-bootstrap';
import BasicTooltip from '@/components/atoms/Tooltips';

export default function LiveProject(props: {
    params: {
        page: number;
        per_page: number;
        search: string;
        sort_by: string;
        sort_asc: number;
    };
    setParams: Dispatch<
        SetStateAction<{
            page: number;
            per_page: number;
            search: string;
            sort_by: string;
            sort_asc: number;
        }>
    >;
    setModal: (modal: any) => void;
    handleOpenModal: (
        data: {
            id: number;
            name: string;
            tender: {
                intention: string;
                project_detail: string;
                due_date: string;
            };
        },
        type: string,
    ) => void;
}): JSX.Element {
    const { params, setParams, setModal, handleOpenModal } = props;

    const dispatch = useDispatch();

    const currentProfileState = useSelector((state: RootState) => state.currentProfile);
    const pinnedLength = currentProfileState?.response?.pinned_projects?.length ?? 0;
    const userRole = currentProfileState?.response?.role?.name;

    const allProjectState = useSelector((state: RootState) => state.allProject);

    const [searchValue, setSearchValue] = useState('');

    const tableItems = [
        {
            title: 'Project Name',
            selector: 'name',
            Cell: (
                row: {
                    id: number;
                    name: string;
                    picture: string;
                    pinned: boolean;
                },
                index: number,
            ) => (
                <div key={index} className="d-flex  align-items-center">
                    <Icons
                        icon={PinIcon}
                        color="#000"
                        style={row?.pinned ? { opacity: 1 } : { opacity: 0 }}
                    />
                    <img
                        src={row.picture}
                        alt="company"
                        className="rounded-circle me-2"
                        style={{
                            width: '3rem',
                            height: '3rem',
                            objectFit: 'cover',
                        }}
                    />
                    <Link
                        to={`${row.id}/summary`}
                        className="link-primary"
                        style={{
                            textDecoration: 'none',
                        }}
                    >
                        {row.name}
                    </Link>
                </div>
            ),
        },
        {
            title: 'Project Number',
            selector: 'number',
        },
        {
            title: 'Project Address',
            selector: 'address',
        },
        {
            title: 'Invited by',
            Cell: (row: any) => <span>{row?.invited_by?.name}</span>,
        },
        {
            title: 'Project Estimate',
            selector: 'value',
        },
        {
            title: 'Action',
            withAction: true,
            Cell: (row: any) => (
                <div className="mb-1">
                    <BasicTooltip text={'Turning on will place project in Tender Box'}>
                        <CustomSwitch
                            type="switch"
                            id={row.id}
                            label="Open to Tender"
                            bsPrefix="d-flex align-items-center gap-2"
                            style={{
                                fontSize: 13,
                            }}
                            checked={row?.tender_status === 1}
                            onChange={() =>
                                row?.tender_status === 1
                                    ? handleOpenModal(row, 'closeTender')
                                    : handleOpenModal(row, 'openTender')
                            }
                        />
                    </BasicTooltip>
                </div>
            ),
            actions: [
                {
                    type: 'edit',
                    text: 'Edit Tender Information',
                    onClick: (row: any) => handleOpenModal(row, 'editTender'),
                    only: (row: any) => row?.tender_status !== 0,
                },
                {
                    type: 'pin',
                    text: 'Pin Project',
                    onClick: (row: any) => handleOpenModal(row, 'pin'),
                    only: (row: any) => pinnedLength < 7 && row?.pinned === false,
                },
                {
                    type: 'unpin',
                    text: 'Unpin Project',
                    onClick: (row: any) => handleOpenModal(row, 'unpin'),
                    only: (row: any) => row?.pinned !== false,
                },
                {
                    type: 'detail',
                    text: 'View Project Details',
                    url: 'summary',
                },
                {
                    type: 'setting',
                    text: 'Settings',
                    url: 'setup',
                },
                {
                    type: 'leave',
                    text: 'Leave Project',
                    variant: 'danger',
                    onClick: (row: any) => handleOpenModal(row, 'leave'),
                },
            ],
        },
    ];

    /**
     * Function to handle search
     *
     * @param string value
     * @returns void
     */
    const handleSearch = useCallback((value: string): void => {
        setSearchValue(value);
    }, []);

    /**
     * Function to handle debounce search
     *
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleDebouncedSearch = useCallback(() => {
        setParams({
            ...params,
            search: searchValue,
            page: 1,
        });

        const payload = {
            ...params,
            search: searchValue,
            page: 1,
        };

        dispatch(getAllProject(payload));
    }, [searchValue]);

    /**
     * Function to handle pagination
     *
     * @param number page
     * @param string type
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handlePagination = (page: number, type: string): void => {
        if (type === 'page') {
            setParams({
                ...params,
                page,
            });

            const payload = {
                ...params,
                page,
            };

            dispatch(getAllProject(payload));
        }

        if (type === 'next') {
            setParams({
                ...params,
                page: params.page + 1,
            });

            const payload = {
                ...params,
                page: params.page + 1,
            };

            dispatch(getAllProject(payload));
        }

        if (type === 'prev') {
            setParams({
                ...params,
                page: params.page - 1,
            });

            const payload = {
                ...params,
                page: params.page - 1,
            };

            dispatch(getAllProject(payload));
        }
    };

    // Function for handle debounce search
    useEffect(() => {
        const debounce = setTimeout(() => {
            handleDebouncedSearch();
        }, 500);

        return () => {
            clearTimeout(debounce);
        };
    }, [handleDebouncedSearch]);

    return (
        <div className="mt-4">
            <TableBorderless
                moduleType="Live Project"
                header={tableItems}
                items={allProjectState?.response ?? []}
                handleSearch={handleSearch}
                withAdd={userRole === 'Company User'}
                addTitle="+ New Project"
                withoutCheckbox={true}
                handlePagination={handlePagination}
                handleAdd={() => setModal((prev: any) => ({ ...prev, add: true }))}
                loading={allProjectState?.loading}
            />
        </div>
    );
}

const Icons = styled.span<{ icon: string; color: string }>`
    width: 1.2rem;
    height: 1.2rem;
    mask: url(${props => props.icon}) no-repeat center;
    mask-size: 80%;
    -webkit-mask: url(${props => props.icon}) no-repeat center;
    -webkit-mask-size: 80%;
    background-color: ${props => props.color};
    display: inline-block;
    margin-right: 5px;
    mask-position: center;
    vertical-align: middle;
`;

const CustomSwitch = styled(Form.Check)`
    .form-check-input {
        width: 2.5rem;
        height: 1.5rem;
    }

    .form-switch {
        padding: 0;
    }
`;
