import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { clearStateDetailMemberInvited } from '@/routes/Dashboard/Project/redux/actions';

import { getAllPendingInvitation } from '../../redux/actions';

import { RootState } from '@/redux/store';
import moment from 'moment';
import TableBorderless from '@/components/organism/TableBorderless';

export default function PendingInvitation(props: any): JSX.Element {
    const {
        setModal,
        setModalType,
    }: {
        setModal: (modal: object) => void;
        setModalType: (modal: object) => void;
    } = props;

    const dispatch = useDispatch();

    const { id } = useParams();

    const allPendingInvitationState = useSelector(
        (state: RootState) => state.allPendingInvitation,
    );
    const currentProfileProjectState = useSelector(
        (state: RootState) => state.currentProfileProject,
    );

    const permissionUser = currentProfileProjectState?.response?.access;

    const [searchValue, setSearchValue] = useState('');
    const [params, setParams] = useState({
        project_id: id,
        page: 1,
        per_page: 10,
        search: '',
        sort_by: '',
        sort_asc: 1,
    });

    const tableItems = [
        {
            title: 'Email',
            Cell: (row: { name: string; avatar: string; email: string }) => (
                <span className="mb-0">{row?.email}</span>
            ),
        },
        {
            title: 'Company',
            Cell: (row: any) => (
                <span>{row?.company?.company_name ?? 'Company not registered'}</span>
            ),
        },
        {
            title: 'Phone',
            Cell: (row: any) => <span>{row?.phone ?? 'Phone not registered'}</span>,
        },
        {
            title: 'Expired Date',
            Cell: (row: any) => moment.unix(row?.expired_at).format('DD/MM/YYYY'),
        },
        {
            title: 'Action',
            withAction: true,
            // only: (row: any) => row?.status?.id === 4 || row?.status?.id === 3,
            actions: [
                {
                    text: 'Resend Invitation',
                    type: 'resend',
                    variant: '',
                    onClick: (row: any) => handleOpenModal(row?.email, 'resend'),
                },
                {
                    text: 'Cancel Invitation',
                    type: 'cancel',
                    variant: 'danger',
                    onClick: (row: any) => handleOpenModal(row?.email, 'cancel'),
                    // Cell: '',
                    // only: (row: any) => row?.status?.id === 4,
                },
            ],
        },
    ];

    /**
     * Function to handle search
     *
     * @param string value
     * @returns void
     */
    const handleSearch = useCallback((value: string): void => {
        setSearchValue(value);
    }, []);

    /**
     * Function to handle debounce search
     *
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleDebouncedSearch = useCallback(() => {
        setParams({
            ...params,
            search: searchValue,
            page: 1,
        });

        const payload = {
            ...params,
            search: searchValue,
            page: 1,
        };

        dispatch(getAllPendingInvitation(payload));
    }, [searchValue]);

    /**
     * Function to handle pagination
     *
     * @param number page
     * @param string type
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handlePagination = (page: number, type: string): void => {
        if (type === 'page') {
            setParams({
                ...params,
                page,
            });

            const payload = {
                ...params,
                page,
            };

            dispatch(getAllPendingInvitation(payload));
        }

        if (type === 'next') {
            setParams({
                ...params,
                page: params.page + 1,
            });

            const payload = {
                ...params,
                page: params.page + 1,
            };

            dispatch(getAllPendingInvitation(payload));
        }

        if (type === 'prev') {
            setParams({
                ...params,
                page: params.page - 1,
            });

            const payload = {
                ...params,
                page: params.page - 1,
            };

            dispatch(getAllPendingInvitation(payload));
        }
    };

    /**
     * Function to handle open modal
     *
     * @param id
     * @param email
     * @param type
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleOpenModal = (email: string, type: string): void => {
        setModalType({
            email,
            type,
        });

        setModal((prev: { type: string }) => ({
            ...prev,
            [type]: true,
        }));
    };

    // Function for reset state when component unmount
    useEffect(() => {
        return () => {
            dispatch(clearStateDetailMemberInvited());
        };
    }, []);

    // Function for handle debounce search
    useEffect(() => {
        const debounce = setTimeout(() => {
            handleDebouncedSearch();
        }, 500);

        return () => {
            clearTimeout(debounce);
        };
    }, [handleDebouncedSearch]);

    return (
        <>
            <TableBorderless
                withoutBorder
                header={tableItems}
                items={allPendingInvitationState?.response ?? []}
                loading={allPendingInvitationState?.loading}
                handlePagination={handlePagination}
                handleSearch={handleSearch}
                moduleType="Pending Invitation"
                actionClass="btn-outline-danger"
                addTitle="Invite User"
                withAdd={permissionUser?.summary?.invite === true}
                handleAdd={() =>
                    setModal((prev: any) => ({
                        ...prev,
                        invite: true,
                    }))
                }
                withoutCheckbox
            />
        </>
    );
}
