import { generateInitialImageUrl } from '@/utility/Utils';
import { Fragment, useRef, useState } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

interface InputFileProps {
    title?: string;
    file?: string;
    loading?: boolean;
    action?: any;
    payload?: string;
    name: string;
}

/**
 * Input File component boilerplate.
 *
 * @param props
 * @constructor
 */
export default function InputFile(props: InputFileProps): JSX.Element {
    const { title, file, loading, action, payload, name } = props;

    const dispatch = useDispatch();

    const inputFileRef = useRef<HTMLInputElement>(null);

    const [imageError, setImageError] = useState(false);

    /**
     * Function to handle the upload of the file.
     *
     * @returns void
     */
    const handleUpload = (): void => {
        if (inputFileRef.current !== undefined && inputFileRef.current !== null) {
            inputFileRef.current.click();

            inputFileRef.current.onchange = (e: any) => {
                const file = e.target.files[0];
                const fileSize = file.size / 1024 / 1024; // Convert file size to MB

                if (file !== undefined && fileSize <= 2) {
                    const formData = new FormData();
                    formData.append(payload ?? '', file);
                    dispatch(action(formData));
                    setImageError(false);
                    // setImage(URL.createObjectURL(file));
                } else {
                    setImageError(true);
                }
            };
        }
    };

    return (
        <Fragment>
            <div className="d-flex mb-2">
                <StyledInputFile type="file" ref={inputFileRef} accept="image/*" />

                <label htmlFor="profilePicture" className="mb-0">
                    {loading !== true ? (
                        <CirclePreviewFile
                            id="profile-picture"
                            // file={file ?? generateInitialImageUrl(name)}
                            style={{
                                backgroundImage: `url(${
                                    file ?? `'${generateInitialImageUrl(name)}'`
                                })`,
                            }}
                            onClick={handleUpload}
                        />
                    ) : (
                        <ThreeCircles
                            height="90"
                            width="90"
                            color="#5648fb"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="three-circles-rotating"
                            outerCircleColor=""
                            innerCircleColor=""
                            middleCircleColor=""
                        />
                    )}
                </label>
                <div className="ms-3 mt-1">
                    {title != null ? (
                        <p className="mb-1 h6">{title}</p>
                    ) : (
                        <Link
                            className="link-primary"
                            to="#"
                            onClick={handleUpload}
                            style={{
                                fontSize: 14,
                                display: 'block',
                            }}
                        >
                            Change Photo
                        </Link>
                    )}
                    <div
                        className="fst-italic mt-2"
                        style={{
                            fontSize: 11,
                            color: 'rgba(141, 180, 223, 1)',
                        }}
                    >
                        <p className="mb-0">Type file : .jpg, .jpeg, .png</p>
                        <p className="mb-0">Max size : 2mb</p>
                        <p className="mb-0">
                            Recommend Ratio : (1 : 1) max 1080px : 1080px
                        </p>
                    </div>
                    {imageError && (
                        <small
                            className="invalid-feedback d-block text-start"
                            style={{
                                fontSize: '0.75rem',
                            }}
                        >
                            Max file size 2mb
                        </small>
                    )}
                </div>
            </div>
            {title != null && (
                <Link
                    className="link-primary"
                    to="#"
                    onClick={handleUpload}
                    style={{
                        fontSize: 14,
                    }}
                >
                    Change Photo
                </Link>
            )}
        </Fragment>
    );
}

const StyledInputFile = styled.input`
    display: none;
`;

const CirclePreviewFile = styled.section<{ file?: string }>`
    width: 90px;
    height: 90px;
    border-radius: 50%;
    /* background-image: url(${props => props.file}); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #ffffff;

    &:hover {
        border: 1px solid #5648fb;
        transition: 1s ease;
    }
`;
