import { Fragment } from 'react';
import { Form } from 'react-bootstrap';

import styled from 'styled-components';

interface InputBasicProps {
    id?: string;
    type?: string;
    placeholder?: string;
    outerClassName?: string;
    innerClassName?: string;
    icon?: JSX.Element | string;
    isRequired?: boolean;
    value?: any;
    name?: string;
    disabled?: boolean;
    maxLength?: number;
    minLength?: number;
    rules?: {
        function: (name: any, rules: any) => any;
        name: string;
        rules: any;
        errors?: any;
    };
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    autoComplete?: string;
    options: any;
    withoutborderbottom?: boolean;
}

export default function InputSelect(props: InputBasicProps): JSX.Element {
    const {
        id,
        placeholder,
        outerClassName,
        innerClassName,
        rules,
        options,
        withoutborderbottom,
        value,
        onChange,
        disabled,
    } = props;

    return (
        <Fragment>
            <div className={`input-group ${outerClassName ?? 'mb-3'}`}>
                <div className={`form-floating ${innerClassName ?? ''}`}>
                    <SelectWrapper
                        id={id}
                        defaultValue={placeholder}
                        aria-label="Default select example"
                        withoutborderbottom={withoutborderbottom?.toString()}
                        value={value}
                        onChange={onChange}
                        {...rules?.function(rules?.name, rules?.rules)}
                        errors={rules?.errors[rules?.name] !== undefined}
                        disabled={disabled}
                    >
                        <option
                            className="placeholder"
                            hidden
                            disabled
                            value={placeholder}
                        >
                            {placeholder}
                        </option>

                        {options.map((option: any, index: number) => (
                            <option key={index} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </SelectWrapper>
                    {/* <IconWrapper>
                        <svg
                            height="20"
                            width="20"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                            focusable="false"
                            className="css-tj5bde-Svg"
                            color={disabled ?? false ? '#cccccc' : '#000'}
                        >
                            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                        </svg>
                    </IconWrapper> */}

                    {rules?.errors[rules?.name] !== undefined && (
                        <span
                            className="invalid-feedback d-block text-start"
                            style={{
                                fontSize: '0.75rem',
                            }}
                        >
                            {rules?.errors[rules?.name].message}
                        </span>
                    )}
                </div>
            </div>
        </Fragment>
    );
}

const SelectWrapper = styled(Form.Select)`
    border: none;
    ${props =>
        props.withoutborderbottom !== 'true' && 'border-bottom: 1px solid #cfcfcf;'}
    background-color: transparent;
    border-radius: 0;
    /* margin-top: 10px; */
    padding: 0 0 10px 0 !important;
    height: 45px !important;
    background-image: none;
    appearance: auto;

    [hidden] {
        color: #cfcfcf !important;
    }

    &:disabled {
        background-color: transparent;
        color: #cfcfcf;
    }

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #ffff inset;
    }

    // focus
    &:focus {
        box-shadow: none;
    }
`;
