import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import ModalAction from '@/components/atoms/Modals/ModalAction';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';
import InputArea from '@/components/atoms/Inputs/InputArea';
import InputSelect from '@/components/atoms/Inputs/InputSelect';
import InputSearchSelect from '@/components/atoms/Inputs/InputSearchSelect';
import { getActiveInhouse } from '@/routes/Dashboard/Setting/CompanySetting/redux/actions';

import {
    inviteProjectMember,
    clearStateInviteProjectMember,
    getAllMemberInhouse,
    getAllMemberInvited,
    checkUserEmail,
    clearStateCheckUserEmail,
} from '@/routes/Dashboard/Project/redux/actions';

import styled from 'styled-components';
import { RootState } from '@/redux/store';
import { getAllPendingInvitation } from '../../redux/actions';
import { currentProjectAccess } from '@/utility/Utils';

interface ParamsProps {
    modal: boolean;
    setModal: Dispatch<
        SetStateAction<{
            success: boolean;
            invite: boolean;
            successDelete: boolean;
            resend: boolean;
            cancel: boolean;
            successUpdate: boolean;
        }>
    >;
    setModalType: Dispatch<SetStateAction<{ email: string; type: string }>>;
}

export default function InviteMember(props: ParamsProps): JSX.Element {
    const { modal, setModal, setModalType } = props;
    const { id } = useParams();
    const dispatch = useDispatch();

    const projectAccess = currentProjectAccess();

    const summaryAccess = projectAccess?.summary;
    const scheduleAccess = projectAccess?.schedule;
    const drawingAccess = projectAccess?.drawing;
    const documentAccess = projectAccess?.document;

    const inviteProjectMemberState = useSelector(
        (state: RootState) => state.inviteProjectMember,
    );
    const currentProfileProjectState = useSelector(
        (state: RootState) => state.currentProfileProject,
    );
    const checkUserEmailState = useSelector((state: RootState) => state.checkUserEmail);
    const activeInhouseState = useSelector((state: RootState) => state.allActiveInhouse);
    // const detailProjectState = useSelector((state: RootState) => state.detailProject);

    const {
        register,
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
    });

    const [userList, setUserList] = useState<any>([]);
    const [modalChild, setModalChild] = useState({
        success: false,
    });
    const [selectedUser, setSelectedUser] = useState<{
        value: string;
        label: string;
    } | null>({
        value: '',
        label: 'Type or select email',
    });
    const [checkEmail, setCheckEmail] = useState({
        email: '',
        message: '',
        status: false,
        withAction: false,
    });
    const [isErrorEmail, setIsErrorEmail] = useState(false);
    const params = {
        project_id: id,
        page: 1,
        per_page: 10,
        search: '',
        sort_by: '',
        sort_asc: 1,
    };
    const roleOptions = [
        { value: '2', label: 'View Only' },
        { value: '1', label: 'Collaborator' },
    ];

    const roleInviteOptions = [{ value: '2', label: 'View Only' }];

    // For select user then set modal
    useEffect(() => {
        if (checkUserEmailState?.status === 200) {
            const { email, company, role } = checkUserEmailState?.response;
            const currentProject = currentProfileProjectState?.response;

            if (company?.id !== currentProject?.company?.id && role?.id === 2) {
                // If invite other inhouse user
                setCheckEmail(prev => ({
                    ...prev,
                    email,
                    status: true,
                    withAction: true,
                    message:
                        'Inviting the inhouse user must include the company owner instead. Are you want to replace the email with company owner?',
                }));
            } else if (
                checkUserEmailState?.response?.message ===
                "Can't found the requested user"
            ) {
                // If Other Company
                setCheckEmail(prev => ({
                    ...prev,
                    status: true,
                    withAction: false,
                    message: 'Other Company, are you sure to invite?',
                }));
            }
        }
    }, [checkUserEmailState]);

    // For clear state check user email
    useEffect(() => {
        if (!checkEmail.status) {
            dispatch(clearStateCheckUserEmail());
        }
    }, [checkEmail.status]);

    // For get all inhouse user
    useEffect(() => {
        if (activeInhouseState?.status === 200) {
            const data = activeInhouseState?.response?.data;
            setUserList(
                data.map((item: any) => {
                    return {
                        label: `${item.name as string} - Inhouse User`,
                        value: item.email,
                    };
                }),
            );
        }
    }, [activeInhouseState]);

    // If modal closed
    useEffect(() => {
        if (!modal) {
            reset();
            setSelectedUser({
                value: '',
                label: 'Type or select email',
            });
        }
    }, [modal]);

    // Function to load inhouse user 9999, then if unmount then reset to 10
    useEffect(() => {
        if (modal) {
            dispatch(
                getActiveInhouse({
                    page: 1,
                    per_page: 9999,
                    search: '',
                    sort_by: 'name',
                    sort_asc: 1,
                }),
            );
        }
        // return () => {
        //     dispatch(getAllMemberInhouse(params));
        // };
    }, [modal]);

    // Function to handle modal, when pin or unpin project
    useEffect(() => {
        if (inviteProjectMemberState?.status === 200) {
            setModal(prev => ({
                ...prev,
                invite: false,
            }));
            dispatch(getAllMemberInhouse(params));
            dispatch(getAllMemberInvited(params));
            dispatch(getAllPendingInvitation(params));

            // reset input
            setModalType(prev => ({
                ...prev,
                email: '',
                type: 'invite user to project',
            }));

            setTimeout(() => {
                reset();

                setModalChild(prev => ({
                    ...prev,
                    success: true,
                }));
            }, 1000);
        }
    }, [inviteProjectMemberState]);

    /**
     * Function on submit
     *
     * @param any data
     * @return void
     * @see cypress/e2e/dashboard/Project/project.cy.ts
     *      To cypress unit tester
     */
    const onSubmit = (data: any): void => {
        // if true then 1 else 0

        const newPayload = {
            email: selectedUser?.value,

            can_collaborate_summary: data.summary_role === '1' ? '1' : '0',
            can_view_summary: data.summary_role === '2' ? '1' : '0',
            can_invite_summary: data.can_invite_summary === true ? '1' : '0',

            can_collaborate_document:
                data.document === false ? '0' : data.document_role === '1' ? '1' : '0',
            can_view_document:
                data.document === false ? '0' : data.document_role === '2' ? '1' : '0',
            can_invite_document:
                data.document === false
                    ? '0'
                    : data.can_invite_document === true
                    ? '1'
                    : '0',
            can_post_publicly_document:
                data.document === false
                    ? '0'
                    : data.can_post_publicly_document === true
                    ? '1'
                    : '0',

            can_collaborate_drawing:
                data.drawing === false ? '0' : data.drawing_role === '1' ? '1' : '0',
            can_view_drawing:
                data.drawing === false ? '0' : data.drawing_role === '2' ? '1' : '0',
            can_invite_drawing:
                data.drawing === false
                    ? '0'
                    : data.can_invite_drawing === true
                    ? '1'
                    : '0',
            can_post_publicly_drawing:
                data.drawing === false
                    ? '0'
                    : data.can_post_publicly_drawing === true
                    ? '1'
                    : '0',

            can_invite_schedule: data.can_invite_schedule === true ? '1' : '0',
            member_space_schedules: [
                {
                    can_collaborate_schedule:
                        data.schedule_role_design === '1' ? '1' : '0',
                    can_view_schedule: data.schedule_role_design === '2' ? '1' : '0',
                    space_type: 'design',
                },
                {
                    can_collaborate_schedule:
                        data.schedule_role_construction === '1' ? '1' : '0',
                    can_view_schedule:
                        data.schedule_role_construction === '2' ? '1' : '0',
                    space_type: 'construction',
                },
            ],
            comment: data.comment,
        };

        // console.log('newPayload', newPayload);
        // console.log(data);

        // Dispatch login action with form data as payload
        dispatch(inviteProjectMember(id as string, newPayload));
    };

    /**
     * Function to redirect to project dashboard summary
     *
     * @return void
     */
    const handleConfirmModal = (): void => {
        setModalChild(prev => ({
            ...prev,
            success: false,
        }));

        // clear state
        dispatch(clearStateInviteProjectMember());
    };

    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/g;

    return (
        <>
            <ModalAction
                modal={modal}
                setModal={setModal}
                aria-labelledby="contained-modal-title-vcenter"
                title="Invite User"
                size="lg"
            >
                <form
                    action="#"
                    onSubmit={handleSubmit(onSubmit)}
                    method="post"
                    style={{
                        fontSize: 14,
                    }}
                >
                    <Controller
                        control={control}
                        name="getAll"
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur } }) => {
                            return (
                                <div className="text-start mt-3">
                                    <label className="fw-semibold px-4">
                                        Select User
                                    </label>
                                    <InputSearchSelect
                                        placeholder="Type or select email"
                                        innerClassName="mb-1 px-4"
                                        outerClassName=""
                                        options={userList}
                                        isMulti={false}
                                        withCreatable
                                        placeholderCreatable="Invite"
                                        loading={checkUserEmailState.loading}
                                        onChange={(value: {
                                            value: string;
                                            label: string;
                                        }) => {
                                            const isValid = regex.test(value.value);

                                            if (isValid) {
                                                setSelectedUser(value);
                                                onChange(value.value);
                                                dispatch(checkUserEmail(value.value));
                                                setIsErrorEmail(false);
                                            }
                                        }}
                                        onBlur={e => {
                                            const isValid = regex.test(e.target.value);

                                            if (e.target.value !== '' && isValid) {
                                                onBlur();
                                                setSelectedUser({
                                                    value: e.target.value,
                                                    label: e.target.value,
                                                });
                                                setValue('getAll', e.target.value);
                                                dispatch(checkUserEmail(e.target.value));
                                                setIsErrorEmail(false);
                                            } else {
                                                if (selectedUser?.value === '') {
                                                    onBlur();
                                                    setSelectedUser({
                                                        value: '',
                                                        label: '',
                                                    });
                                                    setValue('getAll', '');
                                                    setIsErrorEmail(true);
                                                } else {
                                                    onBlur();
                                                    setSelectedUser(selectedUser);
                                                    setValue(
                                                        'getAll',
                                                        selectedUser?.value,
                                                    );
                                                    setIsErrorEmail(false);
                                                }
                                            }
                                        }}
                                        value={selectedUser}
                                    />
                                    {errors.getAll != null && (
                                        <span
                                            className="text-danger px-4"
                                            style={{
                                                fontSize: 12,
                                            }}
                                        >
                                            Please select user
                                        </span>
                                    )}
                                    {isErrorEmail && (
                                        <span
                                            className="text-danger px-4"
                                            style={{
                                                fontSize: 12,
                                            }}
                                        >
                                            Please input valid email
                                        </span>
                                    )}
                                </div>
                            );
                        }}
                    />

                    <AccessWrapper className="d-flex flex-column justify-content-between text-start px-4 mt-4">
                        <label className="mb-3 fw-semibold">Select Access</label>
                        {/* Summary */}
                        <label>Summary</label>
                        <div className="d-flex justify-content-between w-100">
                            <InputSelect
                                options={
                                    summaryAccess?.collaborate
                                        ? roleOptions
                                        : roleInviteOptions
                                }
                                innerClassName="me-2"
                                rules={{
                                    function: register,
                                    name: 'summary_role',
                                    rules: {
                                        required: 'Summary role is required',
                                    },
                                    errors,
                                }}
                            />
                            <div className="d-flex flex-column w-75 justify-content-center ms-5">
                                {summaryAccess?.invite && (
                                    <Form.Check
                                        type="checkbox"
                                        label="Ability to invite users"
                                        id="inviteUserCheckboxSummary"
                                        className="mt-2"
                                        {...register('can_invite_summary')}
                                    />
                                )}
                            </div>
                        </div>

                        {/* Schedule */}
                        {scheduleAccess?.design?.can_collaborate_schedule === true ||
                        scheduleAccess?.design?.can_view_schedule === true ||
                        scheduleAccess?.construction?.can_collaborate_schedule === true ||
                        scheduleAccess?.construction?.can_view_schedule === true ||
                        scheduleAccess?.can_invite_schedule === true ? (
                            <>
                                <label>Schedule</label>

                                <div className="d-flex justify-content-between align-items-center">
                                    {scheduleAccess?.design?.can_collaborate_schedule ===
                                        true ||
                                    scheduleAccess?.design?.can_view_schedule === true ? (
                                        <div className="w-50">
                                            <label className="mt-2">Design</label>
                                            <InputSelect
                                                options={
                                                    scheduleAccess?.design
                                                        ?.can_collaborate_schedule ===
                                                    true
                                                        ? [
                                                              {
                                                                  value: '0',
                                                                  label: 'No Access',
                                                              },
                                                              ...roleOptions,
                                                          ]
                                                        : [
                                                              {
                                                                  value: '0',
                                                                  label: 'No Access',
                                                              },
                                                              ...roleInviteOptions,
                                                          ]
                                                }
                                                innerClassName="me-2"
                                                rules={{
                                                    function: register,
                                                    name: 'schedule_role_design',
                                                    rules: {
                                                        required:
                                                            'Schedule role is required',
                                                    },
                                                    errors,
                                                }}
                                            />
                                        </div>
                                    ) : null}
                                    {scheduleAccess?.construction
                                        ?.can_collaborate_schedule === true ||
                                    scheduleAccess?.construction?.can_view_schedule ===
                                        true ? (
                                        <div className="w-50">
                                            <label className="mt-2">Construction</label>
                                            <InputSelect
                                                options={
                                                    scheduleAccess?.construction
                                                        ?.can_collaborate_schedule ===
                                                    true
                                                        ? [
                                                              {
                                                                  value: '0',
                                                                  label: 'No Access',
                                                              },
                                                              ...roleOptions,
                                                          ]
                                                        : [
                                                              {
                                                                  value: '0',
                                                                  label: 'No Access',
                                                              },
                                                              ...roleInviteOptions,
                                                          ]
                                                }
                                                innerClassName="me-2"
                                                rules={{
                                                    function: register,
                                                    name: 'schedule_role_construction',
                                                    rules: {
                                                        required:
                                                            'Schedule role is required',
                                                    },
                                                    errors,
                                                }}
                                            />
                                        </div>
                                    ) : null}

                                    <div className="w-75 ms-5">
                                        {scheduleAccess?.can_invite_schedule === true && (
                                            <Form.Check
                                                type="checkbox"
                                                id="schedule_group"
                                                label="Ability to invite users"
                                                style={{ fontSize: 14 }}
                                                {...register('can_invite_schedule')}
                                            />
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : null}

                        {/* Document */}
                        {documentAccess?.collaborate || documentAccess?.view ? (
                            <Form.Check
                                type="checkbox"
                                id="document_group"
                                label="Document"
                                style={{ fontSize: 14 }}
                                {...register('document')}
                            />
                        ) : null}
                        {watch('document') !== false &&
                            watch('document') !== undefined && (
                                <div className="d-flex justify-content-between w-100">
                                    <InputSelect
                                        options={
                                            documentAccess?.collaborate
                                                ? roleOptions
                                                : roleInviteOptions
                                        }
                                        innerClassName="me-2"
                                        rules={{
                                            function: register,
                                            name: 'document_role',
                                            rules: {
                                                required: 'Document access is required',
                                            },
                                            errors,
                                        }}
                                    />

                                    <div className="d-flex flex-column w-75 justify-content-end ms-5">
                                        {documentAccess?.invite && (
                                            <Form.Check
                                                type="checkbox"
                                                label="Ability to invite users"
                                                id="inviteUserCheckboxDocument"
                                                className="mt-2"
                                                {...register('can_invite_document')}
                                            />
                                        )}
                                        {documentAccess?.post_publicly && (
                                            <Form.Check
                                                type="checkbox"
                                                label="Post Publicly"
                                                id="postCheckboxDocument"
                                                className="mt-2"
                                                {...register(
                                                    'can_post_publicly_document',
                                                )}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}

                        {/* Drawing */}
                        {drawingAccess?.collaborate || drawingAccess?.view ? (
                            <Form.Check
                                type="checkbox"
                                id="drawing_group"
                                label="Drawing"
                                style={{ fontSize: 14 }}
                                {...register('drawing')}
                            />
                        ) : null}
                        {watch('drawing') !== false && watch('drawing') !== undefined && (
                            <div className="d-flex justify-content-between w-100">
                                <InputSelect
                                    options={
                                        drawingAccess?.collaborate
                                            ? roleOptions
                                            : roleInviteOptions
                                    }
                                    innerClassName="me-2"
                                    rules={{
                                        function: register,
                                        name: 'drawing_role',
                                        rules: {
                                            required: 'Drawing access is required',
                                        },
                                        errors,
                                    }}
                                />
                                <div className="d-flex flex-column w-75 justify-content-end ms-5">
                                    {drawingAccess?.invite && (
                                        <Form.Check
                                            type="checkbox"
                                            label="Ability to invite users"
                                            id="inviteUserCheckboxDrawing"
                                            className="mt-2"
                                            {...register('can_invite_drawing')}
                                        />
                                    )}
                                    {drawingAccess?.post_publicly && (
                                        <Form.Check
                                            type="checkbox"
                                            label="Post Publicly"
                                            id="postCheckboxDrawing"
                                            className="mt-2"
                                            {...register('can_post_publicly_drawing')}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </AccessWrapper>

                    <InputArea
                        id="Comment"
                        placeholder="Comment"
                        outerClassName="px-4 mb-4"
                        rules={{
                            function: register,
                            name: 'comment',
                            rules: {},
                            errors,
                        }}
                    />

                    <ButtonBasic
                        text="Invite"
                        type="submit"
                        className="btn btn-primary btn-sm w-25"
                        loading={inviteProjectMemberState.loading}
                        disabled={
                            selectedUser?.value === '' ||
                            selectedUser?.value === undefined ||
                            selectedUser?.value === null
                        }
                    />
                </form>
            </ModalAction>

            <ModalDashboard
                modal={modalChild.success}
                setModal={setModalChild}
                variant="success"
                type="success"
                title="Success!"
                body={'Successfully invite user'}
                onConfirm={() => handleConfirmModal()}
            />

            {checkUserEmailState.loading === false && (
                <ModalAction
                    modal={checkEmail.status}
                    setModal={setCheckEmail}
                    title="Caution"
                    size="sm"
                    withoutHeader
                >
                    <div className="px-3 pt-4">
                        <p
                            className="text-center mb-0"
                            style={{
                                fontSize: 14,
                            }}
                        >
                            {checkEmail.message}
                        </p>

                        {checkEmail.withAction ? (
                            <div className="d-flex justify-content-around mt-4">
                                <ButtonBasic
                                    text="No"
                                    className="btn-danger w-25"
                                    onClick={() => {
                                        setCheckEmail(prev => ({
                                            ...prev,
                                            status: false,
                                        }));
                                    }}
                                    withTooltip="Company owner and inhouse user will be invited"
                                />
                                <ButtonBasic
                                    text="Yes"
                                    className="btn-primary w-25"
                                    onClick={() => {
                                        // Replace email with company owner
                                        const companyEmail =
                                            checkUserEmailState?.response?.company
                                                ?.company_email;

                                        setSelectedUser({
                                            value: companyEmail,
                                            label: companyEmail,
                                        });
                                        setCheckEmail(prev => ({
                                            ...prev,
                                            status: false,
                                        }));
                                    }}
                                    withTooltip="The email will be replaced with company owner"
                                />
                            </div>
                        ) : (
                            <div className="d-flex justify-content-center mt-4">
                                <ButtonBasic
                                    text="Confirm"
                                    className="btn-primary w-50"
                                    onClick={() => {
                                        setCheckEmail(prev => ({
                                            ...prev,
                                            status: false,
                                        }));
                                        dispatch(clearStateCheckUserEmail());
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </ModalAction>
            )}
        </>
    );
}

const AccessWrapper = styled.div`
    .form-select {
        font-size: 14px;
        padding: 0 !important;
    }
`;
