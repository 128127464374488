import { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { isValidPhoneNumber } from 'react-phone-number-input';

import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import Header from '@/components/molecules/Header';
import InputPhone from '@/components/atoms/Inputs/InputPhone';
import Loader from '@/components/molecules/Loader';
import { Title } from '@/components/molecules/Typography';

import { forgotPasswordPhone } from '@/routes/Authentication/ForgotPassword/redux/actions';
import { RootState } from '@/redux/store';

interface FormDataProps {
    phone: string;
}

export default function EnterPhone(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormDataProps>({
        criteriaMode: 'all',
    });

    const phoneState = useSelector((state: RootState) => state.forgotPasswordPhone);

    useEffect(() => {
        if (phoneState.status === 200) {
            // Set email to session storage
            sessionStorage.setItem('phone', phoneState?.response?.address);
            // Redirect to enter code page if email is sent
            navigate('/forgot-password/enter-otp', {
                state: { nextStep: true },
            });
        }
    }, [phoneState]);

    /**
     * Function on submit
     *
     * @param {object} data
     * @return {void}
     */
    const onSubmit = (data: FormDataProps): void => {
        data.phone = data.phone.replace(/\s/g, '');
        // initialize form data
        const payload = new FormData();

        // append data to form data
        payload.append('phone', data.phone);

        // Dispatch login action with form data as payload
        dispatch(forgotPasswordPhone(payload));
    };

    return (
        <Fragment>
            {phoneState.loading === true && <Loader />}
            <div className="container-fluid container-xs container-lg">
                <header>
                    <Header text="Return to home screen" link="/" />
                </header>
                <main className="main-content">
                    <div className="row mb-5">
                        <div className="col-md-12 text-center">
                            <Title text="Mobile Phone Verification" />
                        </div>
                        <div className="col-md-8 col-lg-6 col-xxl-4 mx-auto text-center mb-3">
                            <span className="text-muted">
                                Please enter your email that registered on system to
                                verification & reset your password.
                            </span>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-8 col-lg-6 col-xxl-4 mx-auto text-center">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <InputPhone
                                    id="phone"
                                    placeholder="Phone Number"
                                    country="AU"
                                    rules={{
                                        function: register,
                                        name: 'phone',
                                        rules: {
                                            required: 'Phone Number is required',
                                            validate: (value: any) =>
                                                isValidPhoneNumber(value) ||
                                                'Phone Number is not valid',
                                        },
                                        errors,
                                    }}
                                />
                                <ButtonBasic
                                    type="submit"
                                    text="Confirm"
                                    className="my-3 w-50 btn-primary"
                                />
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        </Fragment>
    );
}
