import RcGantt, { Gantt, GanttRef, enUS } from 'ibnu-rc-gantt';
import { ThreeDots } from 'react-loader-spinner';

import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import BasicTooltip from '@/components/atoms/Tooltips';

import {
    ChartDataProps,
    GanttChartProps,
    SelectedTask,
} from '@/routes/ProjectDashboard/Schedule/interfaces';

import { useEffect, useMemo, useRef, useState } from 'react';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai';
import styled from 'styled-components';
import { BsArchive } from 'react-icons/bs';
import { generateInitialImageUrl } from '@/utility/Utils';
import { MdDragIndicator } from 'react-icons/md';
import { useDrag, useDrop } from 'react-dnd';
import moment from 'moment';
import AddAssignee from '@/routes/ProjectDashboard/Schedule/components/AddTask/AddAssignee';
import AddTask from '@/routes/ProjectDashboard/Schedule/components/AddTask/AddTask';
import ArchiveTask from '@/routes/ProjectDashboard/Schedule/components/ArchiveTask';
import DetailTask from '@/routes/ProjectDashboard/Schedule/components/DetailTask';
import SearchTask from '@/routes/ProjectDashboard/Schedule/components/SearchTask';
import { RootState } from '@/redux/store';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@/redux/hook';
import {
    searchPublicTask,
    searchPrivateTask,
    getAllTaskConstructionSchedule,
    getAllTaskDesignSchedule,
    getAllTaskPrivateSchedule,
    updateTaskOrdering,
    updateTaskRangeSchedule,
    getDetailTask,
    getDetailTaskHistory,
    getDetailTaskComment,
    archivingTask,
    clearArchiveUnarchive,
    restoreTask,
    deletePermanentTask,
    resetRestoreTask,
    resetDeleteTask,
    postTaskComment,
    editTaskComment,
    deleteTaskComment,
    removeTaskAssignee,
    resetAddPermissionAssigneeDirectly,
    resetRemoveTaskAssignee,
    collapseTask,
} from '@/routes/ProjectDashboard/Schedule/redux/actions';
import ModalSchedule from '@/components/atoms/Modals/ModalSchedule';
import { useSearchParams } from 'react-router-dom';
import { TableSearch } from '../TableBorderless/styles';

const ItemTypes = {
    TASK: 'task',
    SUBTASK: 'subtask',
};

export default function GanttChartNew({
    reducer,
    params,
    isOnlyMe,
    selectedTab,
    projectId,
    setIsOnlyMe,
    setParams,
    loadData,
    permissions,
}: GanttChartProps): JSX.Element {
    const ref = useRef<GanttRef>();
    const dispatch = useAppDispatch();
    const [queryParameters] = useSearchParams();

    const searchPublicTaskState = useSelector(
        (state: RootState) => state.searchPublicTask,
    );
    const searchPrivateTaskState = useSelector(
        (state: RootState) => state.searchPrivateTask,
    );
    const restoreTaskState = useSelector((state: RootState) => state.restoreTask);
    const deleteTaskState = useSelector((state: RootState) => state.deleteTask);
    const deleteTaskCommentState = useSelector(
        (state: RootState) => state.deleteTaskComment,
    );
    const archiveTaskState = useSelector((state: RootState) => state.archiveTask);
    const detailTaskState = useSelector((state: RootState) => state.detailTask);
    const removeTaskAssigneeState = useSelector(
        (state: RootState) => state.removeTaskAssignee,
    );

    const addPermissionAssigneeDirectlyState = useSelector(
        (state: RootState) => state.addPermissionAssigneeDirectly,
    );

    const [modal, setModal] = useState({
        addTask: false,
        addAssignee: false,
        detailTask: false,
        archiveTask: false,
        searchTask: false,
        success: false,
        successArchive: false,
        archiveCaution: false,
        archiveSubtaskCaution: false,
        deleteCaution: false,
        restoreCaution: false,
        deleteCautionComment: false,
        deleteCautionAssignee: false,
    });

    const [subtask, setSubtask] = useState<{
        id: number | null;
        name: string;
        level: number;
    }>({
        id: null,
        name: '',
        level: 0,
    });

    const [isLoadingAssignee, setIsLoadingAssignee] = useState(false);
    const [selectedTask, setSelectedTask] = useState<SelectedTask | null>(null);
    const [selectedSubtask, setSelectedSubtask] = useState(null);
    const [selectedTrashed, setSelectedTrashed] = useState<number[]>([]);

    const [data, setData] = useState<ChartDataProps>([]);

    const [modalType, setModalType] = useState('');
    const [commentId, setCommentId] = useState<number>(0);

    const [backTasks, setBackTasks] = useState<SelectedTask[] | null>(null);

    const [assignees, setAssignees] = useState<
        Array<{
            id: number;
            can_invite_user: boolean;
            message: string;
            user_email: string;
            user_name: string;
            user_avatar: string;
            disabled: boolean;
            permission: Array<{
                type: string;
                access: number;
                disable_option: number;
                no_access_disabled: boolean;
            }>;
        }>
    >([]);

    const customSights: Gantt.SightConfig[] = [
        {
            label: 'Daily',
            value: Gantt.ESightValues.day,
            type: 'day',
        },
        {
            label: '3 Days',
            value: Gantt.ESightValues.threeDay,
            type: 'threeDay',
        },
        {
            label: 'Weekly',
            value: Gantt.ESightValues.week,
            type: 'week',
        },
        {
            label: 'Monthly',
            value: Gantt.ESightValues.month,
            type: 'month',
        },
        {
            label: 'Quarterly',
            value: Gantt.ESightValues.quarter,
            type: 'quarter',
        },
    ];

    // If reducer of get all task design, construction, private success
    useEffect(() => {
        if (reducer.status === 200 && reducer.loading === false) {
            const formatTask = (item: any): any => ({
                ...item,
                startDate: moment(item.start_at).format('YYYY-MM-DD'),
                endDate: moment(item.end_at).format('YYYY-MM-DD'),
                backgroundColor: item.color,
                borderColor: item.color,
                children: item.children?.map(formatTask),
                collapsed: item.is_open === 0 ? 1 : 0,
            });

            const tasks = reducer.response?.data.map(formatTask);

            setData(tasks);

            const taskParams = queryParameters.get('task_id');
            const archiveParams = queryParameters.get('archive');
            if (taskParams !== null) {
                // find task by id recursive
                const findTaskByIdRecursive = (
                    tasks: ChartDataProps,
                    id: number,
                ): any => {
                    for (const task of tasks) {
                        if (task.id === id) {
                            return task;
                        }

                        if (task.children !== undefined && task.children.length > 0) {
                            const child = findTaskByIdRecursive(task.children, id);
                            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                            if (child) {
                                return child;
                            }
                        }
                    }
                    return null;
                };

                // if params not null then open detail task
                if (tasks !== undefined) {
                    const getTask = findTaskByIdRecursive(tasks, Number(taskParams));
                    if (getTask !== null) {
                        handleDetailTask(getTask, false);
                    }
                    if (archiveParams !== null) {
                        setTimeout(() => {
                            setModal({ ...modal, archiveTask: true });
                        }, 1000);
                    }
                }
            }
        }
    }, [reducer]);

    // If close modal add task and details task, then clear assignees
    useEffect(() => {
        if (!modal.addTask || !modal.detailTask) {
            setAssignees([]);
        }
    }, [modal.addTask]);

    // FIX 3 times calling, and fix modal when closed will close all modal
    useEffect(() => {
        if (archiveTaskState.status === 200) {
            setModal(prev => ({
                ...prev,
                archiveCaution: false,
                archiveSubtaskCaution: false,
            }));

            loadData();

            setTimeout(() => {
                dispatch(clearArchiveUnarchive());
                handleUpdateSearchTask();

                if (selectedSubtask !== null) {
                    dispatch(getDetailTask(detailTaskState?.response?.id, projectId));
                } else {
                    setModal(prev => ({
                        ...prev,
                        detailTask: false,
                    }));

                    if (backTasks !== null) {
                        setBackTasks(null);
                    }
                }
            }, 500);
        }
    }, [archiveTaskState]);

    // If restore task success, then load data
    useEffect(() => {
        if (restoreTaskState.status === 200) {
            setModal(prev => ({
                ...prev,
                restoreCaution: false,
            }));

            loadData();

            setTimeout(() => {
                dispatch(resetRestoreTask());
                handleUpdateSearchTask();
            }, 500);
        }
    }, [restoreTaskState]);

    // If delete  task success, then load data
    useEffect(() => {
        if (deleteTaskState.status === 200) {
            setModal(prev => ({
                ...prev,
                deleteCaution: false,
            }));

            loadData();

            setTimeout(() => {
                dispatch(resetDeleteTask());
                handleUpdateSearchTask();
            }, 500);
        }
    }, [deleteTaskState]);

    // If add permission assignee success, then change state for find and update assignee
    useEffect(() => {
        if (addPermissionAssigneeDirectlyState.status === 200) {
            setData(prevData => findAndUpdateAssignee(prevData, selectedTask?.id ?? 0));

            setTimeout(() => {
                dispatch(resetAddPermissionAssigneeDirectly());
                handleUpdateSearchTask();
            }, 500);
        }
    }, [addPermissionAssigneeDirectlyState]);

    // If remove task assignee success, then change state for find and remove assignee
    useEffect(() => {
        if (removeTaskAssigneeState.status === 200) {
            setModal(prev => ({
                ...prev,
                deleteCautionAssignee: false,
            }));

            setData(prevData =>
                findAndRemoveAssignee(
                    prevData,
                    selectedTask?.id ?? 0,
                    selectedTrashed[0],
                ),
            );

            dispatch(getDetailTask(detailTaskState?.response?.id ?? 0, projectId));

            setTimeout(() => {
                dispatch(resetRemoveTaskAssignee());
                handleUpdateSearchTask();
            }, 500);
        }
    }, [removeTaskAssigneeState]);

    /**
     * Function for handle Show Only Me
     *
     * @param value - boolean
     * @returns void
     */
    const handleShowOnlyMe = (value: boolean): void => {
        setIsOnlyMe(prev => ({
            ...prev,
            [selectedTab]: value,
        }));

        setParams(prev => ({
            ...prev,
            only_me: value ? 1 : 0,
        }));

        const newParams = {
            ...params,
            only_me: value ? 1 : 0,
        };
        switch (selectedTab) {
            case 'design':
                dispatch(getAllTaskDesignSchedule(newParams));
                break;
            case 'construction':
                dispatch(getAllTaskConstructionSchedule(newParams));
                break;
            case 'private':
                dispatch(getAllTaskPrivateSchedule(newParams));
                break;
            default:
                break;
        }
    };

    /**
     * Function for handle detail of task
     *
     * @param taskId - number
     * @returns void
     */
    const handleDetailTask = (task: any, isArchive: boolean): void => {
        setIsLoadingAssignee(true);
        dispatch(getDetailTask(task.id, projectId));
        dispatch(getDetailTaskHistory(task.id, projectId));
        dispatch(getDetailTaskComment(task.id, projectId));

        if (isArchive) {
            setSelectedTask({
                ...task,
                startDate: moment(task.start_at).format('YYYY-MM-DD'),
                endDate: moment(task.end_at).format('YYYY-MM-DD'),
            });
        } else {
            setSelectedTask(task);
        }

        setSubtask({
            id: task.task_id,
            name: task.parent_task_name,
            level: task.level,
        });

        setModal(prev => ({
            ...prev,
            detailTask: true,
        }));

        // set back tasks when duplicate {NEW FEATURE}
        if (backTasks !== null) {
            setBackTasks(prev => {
                if (
                    prev !== null &&
                    !prev.some(existingTask => existingTask.id === task.id)
                ) {
                    return [...prev, task];
                } else {
                    return prev;
                }
            });
        } else {
            setBackTasks([task]);
        }
    };

    /**
     * Function for handle add assignee
     *
     * @param task - any
     * @returns void
     */
    const handleAddAssignee = (task: any): void => {
        setSelectedTask(task);
        setSubtask({
            id: task.task_id,
            name: task.name,
            level: task.level,
        });

        setModal(prev => ({
            ...prev,
            addAssignee: true,
        }));
    };

    const handleCircleStatus = (
        status: Array<{
            value: string;
            name: string;
            selected: boolean;
        }>,
    ): string => {
        const selected = status.find(item => item.selected)?.value;

        switch (selected) {
            case 'open':
                return '#e0e0e0';
            case 'in progress':
                return '#5648fb';
            case 'on hold':
                return '#ffcb45';
            case 'completed':
                return '#06bd80';
            default:
                return '#000';
        }
    };

    /**
     * Function for handle add subtask
     *
     * @param task - any
     * @returns void
     */
    const handleAddSubtask = (task: any): void => {
        setSubtask({
            id: task.id,
            name: task.name,
            level: task.level,
        });
        setSelectedTask(null);
        setModal({ ...modal, detailTask: false, addTask: true });
    };

    /**
     * Function to handle update task range
     *
     * @param record - any
     * @param startDate - string
     * @param endDate - string
     */
    const handleUpdateTaskRange = (
        record: any,
        startDate: string,
        endDate: string,
    ): void => {
        const newFormatStart = moment(startDate).format('YYYY-MM-DD');
        const newFormatEnd = moment(endDate).format('YYYY-MM-DD');

        dispatch(
            updateTaskRangeSchedule(record.id, {
                start_at: newFormatStart,
                end_at: newFormatEnd,
            }),
        );
    };

    /**
     * Function to handle update order
     *
     * @param taskId - number
     * @param targetId - number
     */
    const handleUpdateOrder = (taskId: number, targetId: number): void => {
        const payload = new FormData();

        payload.append('target', targetId.toString());

        dispatch(updateTaskOrdering(taskId, payload));
    };

    /**
     * Function to handle highlight task
     *
     * @param id - number
     */
    const handleHighlightTask = (id: number): void => {
        setModal(prev => ({
            ...prev,
            searchTask: false,
        }));

        if (ref?.current !== null) {
            ref?.current?.hightLightById(id);
        }

        handleUpdateSearchTask();
    };

    /**
     * Function to handle search task
     *
     * @returns void
     */
    const handleUpdateSearchTask = (): void => {
        switch (selectedTab) {
            case 'design':
            case 'construction':
                dispatch(
                    searchPublicTask({
                        project_id: projectId,
                        type: selectedTab,
                        search: '',
                    }),
                );
                break;
            case 'private':
                dispatch(
                    searchPrivateTask({
                        project_id: projectId,
                        search: '',
                    }),
                );
                break;
            default:
                break;
        }
    };

    /**
     * Function to debounce
     *
     * @see https://stackoverflow.com/questions/24004791/can-someone-explain-the-debounce-function-in-javascript
     * @param func - (...args: any[]) => void
     * @param delay - number
     * @returns (...args: any[]) => void
     */
    const debounce = (
        func: (...args: any[]) => void,
        delay: number,
    ): ((...args: any[]) => void) => {
        let timeoutId: ReturnType<typeof setTimeout> | null = null;

        return (...args: any[]): void => {
            if (timeoutId != null) {
                clearTimeout(timeoutId);
            }

            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const debouncedHandleUpdateOrder = debounce((draggedId, targetId) => {
        handleUpdateOrder?.(draggedId, targetId);
    }, 1000);

    /**
     * Function to render draggable name
     *
     * @param record - any
     * @returns - JSX.Element
     */
    const DraggableName = ({ record }: { record: any }): JSX.Element => {
        const refDrag = useRef<HTMLDivElement>(null);

        // drag
        const [{ isDragging, handlerId }, connectDrag] = useDrag(() => ({
            type: ItemTypes.TASK,
            item: {
                id: record.id,
                level: record.level,
                taskId: record.task_id,
                index: findTaskIndexById(data, record.id),
            },
            collect: monitor => {
                const result = {
                    handlerId: monitor.getHandlerId(),
                    isDragging: monitor.isDragging(),
                };
                return result;
            },
        }));

        // drop
        const [, connectDrop] = useDrop({
            accept: ItemTypes.TASK,
            hover(
                {
                    id: draggedId,
                    level: draggedLevel,
                    taskId: draggedTaskId,
                    index: draggedIndex,
                }: {
                    id: number;
                    level: number;
                    taskId: number;
                    index: number;
                },
                monitor,
            ) {
                // Don't replace items with themselves
                if (refDrag.current == null) {
                    return;
                }

                const hoverIndex = findTaskIndexById(data, record.id);

                // If hover on itself then return
                if (hoverIndex === null) {
                    return;
                }

                // Determine rectangle on screen
                const hoverBoundingRect = refDrag.current?.getBoundingClientRect();
                // Get vertical middle
                const hoverMiddleY =
                    (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                // Determine mouse position
                const clientOffset = monitor.getClientOffset();

                if (clientOffset == null) {
                    return;
                }
                // Get pixels to the top
                const hoverClientY = clientOffset.y - hoverBoundingRect.top;
                // Only perform the move when the mouse has crossed half of the items height
                // When dragging downwards, only move when the cursor is below 50%
                // When dragging upwards, only move when the cursor is above 50%
                // Dragging downwards
                if (draggedIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return;
                }
                // Dragging upwards
                if (draggedIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return;
                }

                if (
                    draggedId !== record.id &&
                    draggedLevel === record.level &&
                    draggedTaskId === record.task_id
                ) {
                    handleDragDropUpdateState(
                        draggedId,
                        draggedLevel,
                        draggedTaskId,
                        record.id,
                        record.level,
                        record.task_id,
                    )
                        .then(() => {
                            debouncedHandleUpdateOrder(draggedId, record.id);
                        })
                        .catch(err => {
                            throw new Error(`Error updating state: ${err as string}`);
                        });
                }

                // Time to actually perform the action
                draggedIndex = hoverIndex;
            },
        });

        connectDrag(refDrag);
        connectDrop(refDrag);
        const opacity = isDragging ? 0.4 : 1;
        const containerStyle = useMemo(() => ({ opacity }), [opacity]);

        return (
            <div
                style={containerStyle}
                ref={refDrag}
                data-handler-id={handlerId}
                role="button"
            >
                <div className="d-flex align-items-center">
                    <div>
                        <MdDragIndicator color="rgb(189, 185, 185)" />
                    </div>

                    <span
                        id={`task-${record.id as string}`}
                        className={record.level === 0 ? 'fw-semibold' : 'fw-normal'}
                        onClick={() => handleDetailTask(record, false)}
                    >
                        {record.name}
                    </span>
                </div>
            </div>
        );
    };

    /**
     * Function to update state when drag and drop
     *
     * @param draggedId - number
     * @param draggedLevel - number
     * @param draggedTaskId - number
     * @param targetId - number
     * @param targetLevel - number
     * @param targetTaskId - number
     */
    const handleDragDropUpdateState = async (
        draggedId: number,
        draggedLevel: number,
        draggedTaskId: number,
        targetId: number,
        targetLevel: number,
        targetTaskId: number,
    ): Promise<void> => {
        // Find the task that is being dragged
        const draggedTask = findTaskById(data, draggedId);
        // const draggedIndex = findTaskIndexById(data, draggedId);

        // Find the task that is the target of the drop
        const targetTask = findTaskById(data, targetId);
        // const targetIndex = findTaskIndexById(data, targetId);

        // If the dragged task and the target task are at the same level
        // and have the same parent, swap their order
        if (draggedLevel === targetLevel && draggedTask?.parent === targetTask?.parent) {
            const newData = swapTasksAtSameLevel(data, draggedId, targetId);
            setData(newData);
        }
    };

    /**
     * Function to swap tasks at the same level
     *
     * @param tasks - ChartDataProps
     * @param draggedId  - number
     * @param targetId  - number
     * @returns ChartDataProps
     */
    const swapTasksAtSameLevel = (
        tasks: ChartDataProps,
        draggedId: number,
        targetId: number,
    ): ChartDataProps => {
        return tasks.map(task => {
            if (task.id === draggedId) {
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                return findTaskById(tasks, targetId) || task;
            } else if (task.id === targetId) {
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                return findTaskById(tasks, draggedId) || task;
            } else if (task.children !== undefined && task.children.length > 0) {
                return {
                    ...task,
                    children: swapTasksAtSameLevel(task.children, draggedId, targetId),
                };
            } else {
                return task;
            }
        });
    };

    /**
     * Function to find task by id
     *
     * @param data - ChartDataProps
     * @param id  - number
     * @returns any
     */
    const findTaskById = (data: ChartDataProps, id: number): any => {
        for (const task of data) {
            if (task.id === id) {
                return task;
            }

            if (task.children !== undefined && task.children.length > 0) {
                const child = findTaskById(task.children, id);
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                if (child) {
                    return child;
                }
            }
        }
        return null;
    };

    /**
     * Function to find task index by id
     *
     * @param tasks - ChartDataProps
     * @param id - number
     *
     * @returns number | null
     */
    const findTaskIndexById = (tasks: ChartDataProps, id: number): number | null => {
        for (let i = 0; i < tasks.length; i++) {
            if (tasks[i].id === id) {
                return i;
            }

            if (tasks[i].children !== null && tasks[i].children !== undefined) {
                const index = findTaskIndexById(tasks[i].children, id);
                if (index !== null) {
                    return index;
                }
            }
        }
        return null;
    };

    /**
     * Function to update state of task al task
     *
     * @param record - any
     * @param startDate - string
     * @param endDate - string
     *
     * @returns void
     */
    const handleUpdateTaskRangeState = (
        record: any,
        startDate: string,
        endDate: string,
    ): void => {
        // update state data if children then update recursive
        const newData = updateTaskRange(data, record, startDate, endDate);
        setData(newData);
    };

    /**
     * Function to update task range
     *
     * @param tasks - ChartDataProps
     * @param record - any
     * @param startDate - string
     * @param endDate - string
     * @returns ChartDataProps
     */
    const updateTaskRange = (
        tasks: ChartDataProps,
        record: any,
        startDate: string,
        endDate: string,
    ): ChartDataProps => {
        return tasks.map(task => {
            if (task.id === record.id) {
                return {
                    ...task,
                    startDate,
                    endDate,
                };
            } else if (task.children !== undefined && task.children.length > 0) {
                return {
                    ...task,
                    children: updateTaskRange(task.children, record, startDate, endDate),
                };
            } else {
                return task;
            }
        });
    };

    /**
     * Function to handle delete assignee
     *
     * @param email - string
     * @returns void
     */
    const handleDeleteAssignee = (email: string): void => {
        setAssignees((prev: any) => {
            const filteredData = prev.filter((item: any) => item.user_email !== email);
            return filteredData;
        });
    };

    /**
     * Function to handle search task
     *
     * @param value - string
     * @returns void
     */
    const handleSearchTask = debounce((value: string): void => {
        switch (selectedTab) {
            case 'design':
            case 'construction':
                dispatch(
                    searchPublicTask({
                        ...params,
                        type: selectedTab,
                        search: value,
                    }),
                );
                break;
            case 'private':
                dispatch(
                    searchPrivateTask({
                        ...params,
                        search: value,
                    }),
                );
                break;
            default:
                break;
        }
    }, 500);

    /**
     * Function to handle success add task
     *
     * @params newTaskData: any
     * @returns void
     */
    const handleSuccessAddTask = (newTaskData: any): void => {
        setAssignees([]);

        setTimeout(() => {
            handleUpdateSearchTask();
        }, 500);

        setModal(prev => ({
            ...prev,
            addTask: false,
        }));

        // set back task as null
        if (backTasks !== null) {
            handleDetailTask(backTasks[backTasks.length - 1], true);
        }

        // add data to state, when task_id !== null then add to children
        if (newTaskData.task_id !== null) {
            // It's a sub-task
            setData(prevData =>
                findAndUpdateTask(prevData, newTaskData.task_id, newTaskData),
            );
        } else {
            // It's a parent task
            setData(prevData => [...prevData, newTaskData]);
        }
    };

    /**
     * Function to handle success when edit task
     *
     * @params newTaskData: any
     * @returns void
     */
    const handleSuccessEditTask = (newTaskData: any): void => {
        setAssignees([]);

        setTimeout(() => {
            handleUpdateSearchTask();
        }, 500);

        setModal(prev => ({
            ...prev,
            detailTask: false,
        }));

        // set back task as null
        if (backTasks !== null) {
            setBackTasks(null);
        }

        // replace data to state, when task_id !== null then add to children
        if (newTaskData.task_id !== null) {
            // It's a sub-task
            setData(prevData => findAndEditTask(prevData, newTaskData.id, newTaskData));
        }

        // It's a parent task
        setData(prevData => findAndEditTask(prevData, newTaskData.id, newTaskData));
    };

    /**
     * Function to find and update task in state
     *
     * @param tasks - ChartDataProps
     * @param parentId - number
     * @param newTask - any
     *
     * @returns ChartDataProps
     */
    const findAndUpdateTask = (
        tasks: ChartDataProps,
        parentId: number,
        newTask: any,
    ): ChartDataProps => {
        return tasks.map(task => {
            if (task.id === parentId) {
                // Found the parent task
                if (task?.children?.length > 0) {
                    // If children exist, add the new task
                    return {
                        ...task,
                        children: [...task.children, newTask],
                    };
                } else {
                    // If no children, create a new children array
                    return {
                        ...task,
                        children: [newTask],
                    };
                }
            } else if (task?.children?.length > 0) {
                // Recursively search for the parent task in children
                return {
                    ...task,
                    children: findAndUpdateTask(task.children, parentId, newTask),
                };
            }
            return task;
        });
    };

    /**
     * Function to find and update assignee in state
     *
     * @param tasks - ChartDataProps
     * @param taskId - number
     *
     * @returns ChartDataProps
     */
    const findAndEditTask = (
        tasks: ChartDataProps,
        taskId: number,
        newTask: any,
    ): ChartDataProps => {
        return tasks.map(task => {
            if (task.id === taskId) {
                // Found the parent task
                return {
                    ...task,
                    ...newTask,
                };
            } else if (task?.children?.length > 0) {
                // Recursively search for the parent task in children
                return {
                    ...task,
                    children: findAndEditTask(task.children, taskId, newTask),
                };
            }
            return task;
        });
    };

    /**
     * Function to find and update assignee in state
     * @param tasks - ChartDataProps
     * @param taskId - number
     *
     * @returns ChartDataProps
     */
    const findAndUpdateAssignee = (
        tasks: ChartDataProps,
        taskId: number,
    ): ChartDataProps => {
        return tasks.map(task => {
            if (task.id === taskId) {
                // Found the parent task
                return {
                    ...task,
                    assignees,
                };
            } else if (task?.children?.length > 0) {
                // Recursively search for the parent task in children
                return {
                    ...task,
                    children: findAndUpdateAssignee(task.children, taskId),
                };
            }
            return task;
        });
    };

    /**
     * Function to find and remove assignee in state
     * @param tasks - ChartDataProps
     * @param taskId - number
     * @param assigneeId - number
     *
     * @returns ChartDataProps
     */
    const findAndRemoveAssignee = (
        tasks: ChartDataProps,
        taskId: number,
        assigneeId: number,
    ): ChartDataProps => {
        return tasks.map((task: any) => {
            if (task.id === taskId) {
                // Found the parent task
                return {
                    ...task,
                    assignees: task.assignees.filter(
                        (item: any) => item.id !== assigneeId,
                    ),
                };
            } else if (task?.children?.length > 0) {
                // Recursively search for the parent task in children
                return {
                    ...task,
                    children: findAndRemoveAssignee(task.children, taskId, assigneeId),
                };
            }
            return task;
        });
    };

    /**
     * Function to handle modal archive task
     *
     * @returns void
     */
    const handleOpenModalArchive = (): void => {
        setModal(prev => ({
            ...prev,
            archiveCaution: true,
        }));
        setSelectedSubtask(null);
    };

    /**
     * Function to handle modal restore task
     *
     * @param ids - number[]
     * @returns void
     */
    const handleOpenModalRestore = (ids: number[]): void => {
        setModal(prev => ({
            ...prev,
            restoreCaution: true,
        }));

        setSelectedTrashed(ids);
    };

    /**
     * Function to handle modal delete task
     *
     * @param ids - number[]
     * @returns void
     */
    const handleOpenModalDelete = (ids: number[]): void => {
        setModal(prev => ({
            ...prev,
            deleteCaution: true,
        }));

        setSelectedTrashed(ids);
    };

    /**
     * Function to handle modal delete comment
     *
     * @param id - number
     */
    const handleOpenModalRemoveAssignee = (id: number): void => {
        setModal(prev => ({
            ...prev,
            deleteCautionAssignee: true,
        }));

        setSelectedTrashed([id]);
    };

    /**
     * Function to handle archive task
     *
     * @param task - { id: number }
     */
    const handleArchiveTask = (task: { id: number } | null): void => {
        if (task !== null) {
            dispatch(archivingTask(task.id, projectId));
        }
    };

    /**
     * Function to handle restore task
     *
     * @return void
     */
    const handleRestoreTask = (): void => {
        dispatch(
            restoreTask(projectId, { task_ids: selectedTrashed, type: selectedTab }),
        );
    };

    /**
     * Function to handle delete permanent task
     *
     * @return void
     */
    const handleDeletePermanent = (): void => {
        dispatch(
            deletePermanentTask(projectId, {
                task_ids: selectedTrashed,
                type: selectedTab,
            }),
        );
    };

    /**
     * Function to handle select subtask
     *
     * @param subtask
     * @returns void
     */
    const handleSelectSubtask = (subtask: any): void => {
        setSelectedSubtask(subtask);
        setModal(prev => ({
            ...prev,
            archiveSubtaskCaution: true,
        }));
    };

    /**
     * Function to handle archive subtask
     *
     * @param value - string
     * @returns void
     */
    const handleSendComment = (value: string, document: any[]): void => {
        const formData = new FormData();
        formData.append('comment', value);

        if (document.length > 0) {
            formData.append('document', document[0]);
        }

        dispatch(postTaskComment(selectedTask?.id ?? 0, projectId, formData));
    };

    /**
     * Function to handle edit comment
     *
     * @param commentId - number
     * @param value - string
     * @returns void
     */
    const handleEditComment = (
        commentId: number,
        value: string,
        document: any[],
    ): void => {
        const formData = new FormData();
        formData.append('comment', value);

        if (document.length > 0) {
            formData.append('document', document[0]);
        }

        dispatch(editTaskComment(selectedTask?.id ?? 0, projectId, commentId, formData));
    };

    /**
     * Function to handle delete comment
     *
     * @param id - number
     * @returns void
     */
    const handleDeleteComment = (id: number): void => {
        setCommentId(id);

        setModal(prev => ({
            ...prev,
            deleteCautionComment: true,
        }));
    };

    /**
     * Function to handle delete comment
     *
     * @returns void
     */
    const handleDeleteCommentAction = (): void => {
        dispatch(deleteTaskComment(selectedTask?.id ?? 0, projectId, commentId));
    };

    /**
     * Function to handle add assignee
     *
     * @returns void
     */
    const handleRemoveAssignee = (): void => {
        dispatch(
            removeTaskAssignee(selectedTask?.id ?? 0, {
                project_id: projectId,
                type: selectedTab,
                assignees: selectedTrashed,
            }),
        );
    };

    /**
     * Function to update collapse
     *
     * @param tasks - ChartDataProps
     * @param task - any
     * @param collapsed - number
     * @returns ChartDataProps
     */
    const updateCollapse = (
        tasks: ChartDataProps,
        task: any,
        collapsed: number,
    ): ChartDataProps => {
        return tasks.map(item => {
            if (item.id === task.id) {
                return {
                    ...item,
                    collapsed,
                };
            } else if (item.children !== undefined && item.children.length > 0) {
                return {
                    ...item,
                    children: updateCollapse(item.children, task, collapsed),
                };
            } else {
                return item;
            }
        });
    };

    /**
     * Function to handle collapse
     *
     *
     * @param task
     * @returns void
     */
    const handleCollapse = (task: any): void => {
        const newData = updateCollapse(data, task, task.collapsed === 0 ? 1 : 0);
        setData(newData);

        dispatch(
            collapseTask({
                task_id: task.id,
                is_open: task.collapsed,
            }),
        );
    };

    return (
        <WrapperGantt className="mt-3">
            <div className="row mb-2" style={{ fontSize: 14 }}>
                <div className="col-md-5">
                    <div className="mb-3 d-flex align-items-center justify-content-start">
                        <div
                            className="d-flex align-items-center"
                            style={{ paddingLeft: 14 }}
                        >
                            <AiOutlineSearch size={20} color="rgba(131, 131, 131, 1)" />
                            <TableSearch
                                type="text"
                                id={`search-task-${selectedTab}`}
                                className="w-100 ms-2"
                                placeholder="Search task"
                                onClick={() => setModal({ ...modal, searchTask: true })}
                            />
                        </div>
                        {permissions.collaborate && (
                            <ButtonBasic
                                id={`create-task-${selectedTab}`}
                                text="Add New Task"
                                onClick={() => {
                                    setSubtask({
                                        id: null,
                                        name: '',
                                        level: 0,
                                    });
                                    setModal({ ...modal, addTask: true });
                                    setSelectedTask(null);
                                    setSelectedSubtask(null);
                                }}
                                className="btn btn-primary btn-sm px-3 py-1"
                                style={{
                                    fontSize: 12,
                                }}
                            />
                        )}
                    </div>
                </div>
                <div className="col-md-7">
                    <div className="mb-3 d-flex align-items-center justify-content-end">
                        <span className="me-2">Show only assigned to me</span>
                        <CustomSwitcher
                            id={`show-only-me-${selectedTab}`}
                            type="switch"
                            checked={isOnlyMe}
                            onChange={(e: {
                                target: {
                                    checked: boolean;
                                };
                            }) => handleShowOnlyMe?.(e.target.checked ?? false)}
                        />
                    </div>
                </div>
            </div>

            <div style={{ width: '100%', height: '80vh' }}>
                {reducer.loading === false ? (
                    <RcGantt
                        innerRef={ref as any}
                        showUnitSwitch={false}
                        data={data as any}
                        locale={enUS}
                        customSights={customSights}
                        renderBar={(
                            barInfo: {
                                record: {
                                    backgroundColor: string;
                                    name: string;
                                    niche: {
                                        name: string;
                                    };
                                    startDate: string;
                                    endDate: string;
                                };
                                label: string;
                            },
                            { width, height },
                        ) => (
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Popover
                                        id="popover-basic"
                                        className="border-0 shadow-lg w-100"
                                    >
                                        <Popover.Body
                                            className="d-flex flex-column"
                                            style={{
                                                gap: 10,
                                            }}
                                        >
                                            <span
                                                className="fw-bold"
                                                style={{
                                                    fontSize: 14,
                                                }}
                                            >
                                                {barInfo.record.name}
                                            </span>

                                            <span
                                                className="fw-medium"
                                                style={{
                                                    fontSize: 12,
                                                }}
                                            >
                                                Niche Trade - {barInfo.record.niche.name}
                                            </span>

                                            <div
                                                className="d-flex justify-content-start fw-medium"
                                                style={{
                                                    fontSize: 13,
                                                }}
                                            >
                                                <span className="text-end">
                                                    {moment(
                                                        barInfo.record.startDate,
                                                    ).format('DD MMM YYYY')}{' '}
                                                    -{' '}
                                                    {moment(
                                                        barInfo.record.endDate,
                                                    ).format('DD MMM YYYY')}
                                                </span>
                                            </div>
                                        </Popover.Body>
                                    </Popover>
                                }
                            >
                                <svg width={width} height={20}>
                                    <rect
                                        x={0}
                                        y={0}
                                        width={width}
                                        height={20}
                                        fill={barInfo?.record?.backgroundColor}
                                        stroke={barInfo?.record?.backgroundColor}
                                        rx={10}
                                        ry={10}
                                    />
                                    <text
                                        x={width / 2}
                                        y={20 / 2}
                                        fill="#fff"
                                        dominantBaseline="middle"
                                        textAnchor="middle"
                                        fontSize={12}
                                    >
                                        {barInfo.label}
                                    </text>
                                </svg>
                            </OverlayTrigger>
                        )}
                        columns={[
                            {
                                name: 'name',
                                label: 'Task',
                                width: 300,
                                maxWidth: 300,
                                minWidth: 100,
                                render: record =>
                                    permissions.collaborate ? (
                                        <DraggableName record={record} />
                                    ) : (
                                        <span
                                            className={
                                                record.level === 0
                                                    ? 'fw-semibold'
                                                    : 'fw-normal'
                                            }
                                            role="button"
                                            onClick={() =>
                                                handleDetailTask(record, false)
                                            }
                                        >
                                            {record.name}
                                        </span>
                                    ),
                            },
                            {
                                name: 'status',
                                label: '',
                                width: 50,
                                maxWidth: 50,
                                minWidth: 50,
                                render: record => (
                                    <div className="d-flex align-items-end">
                                        <CircleStatus
                                            color={handleCircleStatus(record.status)}
                                        />
                                    </div>
                                ),
                            },
                            {
                                name: 'assignee',
                                label: 'Assignee',
                                width: 100,
                                maxWidth: 300,
                                minWidth: 100,
                                align: 'left',
                                style: {
                                    padding: '0 5px',
                                },
                                render: record => (
                                    <div className="">
                                        {record?.assignees?.length === 0 ? (
                                            permissions.collaborate ? (
                                                <BasicTooltip text={'Add assignee'}>
                                                    <AddButtonAssignee
                                                        id={`add-assignee-${
                                                            record.id as string
                                                        }`}
                                                        onClick={() => {
                                                            setSubtask({
                                                                id: record.id,
                                                                name: record.name,
                                                                level: record.level,
                                                            });
                                                            setAssignees([]);
                                                            handleAddAssignee(record);
                                                        }}
                                                    >
                                                        <AiOutlinePlus
                                                            role="button"
                                                            className="icon"
                                                            color="#5648fb"
                                                        />
                                                    </AddButtonAssignee>
                                                </BasicTooltip>
                                            ) : null
                                        ) : (
                                            record?.assignees?.slice(0, 4).map(
                                                (
                                                    assignee: {
                                                        user_name: string;
                                                        user_avatar: string;
                                                        user_email: string;
                                                    },
                                                    key: number,
                                                ) => (
                                                    <BasicTooltip
                                                        key={key}
                                                        text={
                                                            assignee?.user_name ??
                                                            assignee?.user_email
                                                        }
                                                        style={{
                                                            top: 3,
                                                            right:
                                                                key === 0 ? 0 : key * 10,
                                                            position: 'relative',
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                assignee?.user_avatar ??
                                                                generateInitialImageUrl(
                                                                    assignee?.user_name ??
                                                                        assignee?.user_email,
                                                                )
                                                            }
                                                            alt="assignee"
                                                            style={{
                                                                width: 25,
                                                                height: 25,
                                                            }}
                                                            className="rounded-circle img-fluid border border-white"
                                                        />
                                                    </BasicTooltip>
                                                ),
                                            )
                                        )}

                                        {record?.assignees?.length > 4 && (
                                            <BasicTooltip
                                                placement="right"
                                                text={record?.assignees
                                                    ?.slice(4)
                                                    .map(
                                                        (assignee: {
                                                            user_name: string;
                                                            user_email: string;
                                                        }) => (
                                                            <p
                                                                key={assignee.user_email}
                                                                className="my-1"
                                                            >
                                                                {assignee?.user_name ??
                                                                    assignee?.user_email}
                                                            </p>
                                                        ),
                                                    )}
                                                style={{
                                                    top: -5,
                                                    right: 40,
                                                    position: 'relative',
                                                }}
                                            >
                                                <div
                                                    className="rounded-circle bg-primary text-white border border-white d-flex justify-content-center align-items-center"
                                                    style={{
                                                        width: 25,
                                                        height: 25,
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    <span className="">
                                                        +{record?.assignees?.length - 4}
                                                    </span>
                                                </div>
                                            </BasicTooltip>
                                        )}
                                    </div>
                                ),
                            },
                            {
                                name: 'total_duration',
                                label: 'Total Duration',
                                width: 100,
                                maxWidth: 100,
                                minWidth: 100,
                                align: 'left',
                                style: {
                                    padding: '0 5px',
                                },
                                render: record => (
                                    <div className="fw-semibold d-flex justify-content-between align-items-center">
                                        <span
                                            className={
                                                record.level !== 0
                                                    ? 'fw-normal'
                                                    : 'fw-semibold'
                                            }
                                        >
                                            {moment(record.endDate).diff(
                                                moment(record.startDate),
                                                'days',
                                            ) + 1}{' '}
                                            Days
                                        </span>
                                        {permissions.collaborate && (
                                            <BasicTooltip text={'Add Subtask'}>
                                                <AddButtonSubTask
                                                    onClick={() => {
                                                        setSubtask({
                                                            id: record.id,
                                                            name: record.name,
                                                            level: record.level,
                                                        });
                                                        setModal({
                                                            ...modal,
                                                            addTask: true,
                                                        });
                                                    }}
                                                >
                                                    <AiOutlinePlus
                                                        role="button"
                                                        className="icon"
                                                        color="#5648fb"
                                                    />
                                                </AddButtonSubTask>
                                            </BasicTooltip>
                                        )}
                                    </div>
                                ),
                            },
                        ]}
                        onExpand={task => handleCollapse(task)}
                        onBarClick={task => handleDetailTask(task, false)}
                        onUpdate={async (record, startDate, endDate) => {
                            if (permissions.collaborate) {
                                handleUpdateTaskRangeState(record, startDate, endDate);
                                handleUpdateTaskRange?.(record, startDate, endDate);
                                return true;
                            }
                            return false;
                        }}
                        renderHeader={
                            reducer?.response?.data?.length > 0 && (
                                <div
                                    id="date-range-header"
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                        maxWidth: 500,
                                        fontSize: 14,
                                        marginTop: 12,
                                        marginLeft: 12,
                                    }}
                                >
                                    <span className="fw-semibold">
                                        {reducer?.response?.date_ranges}
                                    </span>
                                    <span className="fw-semibold">
                                        {reducer?.response?.total_days} Days
                                    </span>
                                </div>
                            )
                        }
                    />
                ) : (
                    <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{
                            height: '80vh',
                        }}
                    >
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#5648FB"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            // wrapperClassName=""
                            visible={true}
                        />
                    </div>
                )}
            </div>
            <OverlayIndicator>
                <div className="d-flex align-items-center gap-2 link-archive">
                    <BsArchive />

                    <span
                        id={`archive-task-${selectedTab}`}
                        role="button"
                        onClick={() => setModal({ ...modal, archiveTask: true })}
                    >
                        Archived Task
                    </span>
                </div>

                <div className="d-flex align-items-center gap-2">
                    <div className="square-public-holiday"></div>
                    <span>Public Holiday</span>
                </div>
            </OverlayIndicator>
            <AddAssignee
                subtask={subtask}
                type={selectedTab}
                modal={modal}
                setModal={setModal}
                projectId={projectId}
                setModalType={setModalType}
                assignees={assignees}
                setAssignees={setAssignees}
                selectedTask={selectedTask}
                handleDeleteAssignee={handleDeleteAssignee}
            />
            <AddTask
                subtask={subtask}
                type={selectedTab}
                modal={modal}
                projectId={projectId}
                assignees={assignees}
                setModal={setModal}
                setModalType={setModalType}
                setAssignees={setAssignees}
                handleSuccessAddTask={handleSuccessAddTask}
                backTasks={backTasks}
                setBackTasks={setBackTasks}
                handleDetailTask={handleDetailTask}
            />
            <DetailTask
                subtask={subtask}
                type={selectedTab}
                modal={modal}
                projectId={projectId}
                assignees={assignees}
                setModal={setModal}
                setModalType={setModalType}
                setAssignees={setAssignees}
                selectedTask={selectedTask}
                setSelectedTask={setSelectedTask}
                handleSuccessEditTask={handleSuccessEditTask}
                handleSelectSubtask={handleSelectSubtask}
                handleOpenModalArchive={handleOpenModalArchive}
                handleAddSubtask={handleAddSubtask}
                handleSendComment={handleSendComment}
                handleEditComment={handleEditComment}
                handleDeleteComment={handleDeleteComment}
                handleOpenModalRemoveAssignee={handleOpenModalRemoveAssignee}
                handleDetailTask={handleDetailTask}
                commentId={commentId}
                permissions={permissions}
                isLoadingAssignee={isLoadingAssignee}
                setIsLoadingAssignee={setIsLoadingAssignee}
                handleDragDropUpdateState={handleDragDropUpdateState}
                backTasks={backTasks}
                setBackTasks={setBackTasks}
            />
            <ArchiveTask
                modal={modal}
                setModal={setModal}
                projectId={projectId}
                setModalType={setModalType}
                type={selectedTab}
                handleOpenModalRestore={handleOpenModalRestore}
                handleOpenModalDelete={handleOpenModalDelete}
                handleDetailTask={handleDetailTask}
                permissions={permissions}
            />
            <SearchTask
                data={data}
                modal={modal}
                setModal={setModal}
                handleHighlightTask={handleHighlightTask}
                handleSearchTask={handleSearchTask}
                reducer={
                    selectedTab === 'design'
                        ? searchPublicTaskState
                        : selectedTab === 'construction'
                        ? searchPublicTaskState
                        : searchPrivateTaskState
                }
            />
            <ModalSchedule
                modal={modal}
                state="success"
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body={`Successfully ${modalType}`}
                onConfirm={() => {
                    setModal({ ...modal, success: false });
                    // loadData();
                }}
                // outsideClick={() => loadData()}
            />
            <ModalSchedule
                modal={modal}
                state="successArchive"
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body={'Successfully archive task'}
                onConfirm={() => {
                    setModal({ ...modal, successArchive: false });
                }}
            />
            <ModalSchedule
                modal={modal}
                state="archiveCaution"
                setModal={setModal}
                variant="danger"
                type="cancel"
                title="Archive Task"
                body="Are you sure want to archive this task?"
                withCancel
                onConfirm={() => handleArchiveTask(selectedTask ?? null)}
                loading={archiveTaskState?.loading}
            />
            <ModalSchedule
                modal={modal}
                state="archiveSubtaskCaution"
                setModal={setModal}
                variant="danger"
                type="cancel"
                title="Archive Task"
                body="Are you sure want to archive this task?"
                withCancel
                onConfirm={() => handleArchiveTask(selectedSubtask ?? null)}
                loading={archiveTaskState?.loading}
            />
            <ModalSchedule
                modal={modal}
                state="restoreCaution"
                setModal={setModal}
                variant="info"
                type="restore"
                title="Restore Task"
                body="Are you sure want to restore selected task?"
                withCancel
                onConfirm={handleRestoreTask}
                loading={restoreTaskState?.loading}
            />
            <ModalSchedule
                modal={modal}
                state="deleteCaution"
                setModal={setModal}
                variant="danger"
                type="cancel"
                title="Delete Permanently"
                body="Are you sure want to delete selected task permanently?"
                subBody="This action cannot be undone"
                withCancel
                onConfirm={handleDeletePermanent}
                loading={deleteTaskState?.loading}
            />
            <ModalSchedule
                modal={modal}
                state="deleteCautionComment"
                setModal={setModal}
                variant="danger"
                type="cancel"
                title="Delete Comment"
                body="Are you sure want to delete this comment?"
                withCancel
                onConfirm={handleDeleteCommentAction}
                loading={deleteTaskCommentState?.loading}
            />
            <ModalSchedule
                modal={modal}
                state="deleteCautionAssignee"
                setModal={setModal}
                variant="danger"
                type="cancel"
                title="Remove Assignee"
                body="Are you sure want to remove this assignee?"
                withCancel
                onConfirm={() => handleRemoveAssignee()}
                loading={removeTaskAssigneeState?.loading}
            />
        </WrapperGantt>
    );
}

const WrapperGantt = styled.div`
    margin-bottom: 5rem;

    .btn-group {
        font-size: 12px !important;
        float: right;
    }

    .col-md-6 {
        margin-bottom: 1rem;
    }

    .gantt-time-indicator {
        background-color: #bbb6fd !important;
        padding: 0.5rem 1rem;

        &:hover {
            background-color: #5648fb !important;
        }
    }

    .time-range {
        font-size: 14px;
    }
    .icon {
        color: #5648fb;
    }

    .gantt-body main {
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .gantt-table-header-cell {
        border: none;
    }

    .gantt-table-body-cell {
        border: none;
    }

    .gantt-task-bar-bar {
        top: -6px !important;
    }

    .gantt-task-bar-label {
        top: -5px !important;
        color: #fff;
        text-shadow: 0 0 3px #000;
        display: none;
    }

    .gantt-task-bar-resize-bg {
        top: -6px !important;
    }

    .gantt-task-bar-resize-handle-left {
        top: -3px !important;
        // left: 5px !important;
        // background: transparent !important;
    }

    .gantt-task-bar-resize-handle-right {
        top: -3px !important;
        // left: 220px !important;
        // background: transparent !important;
    }

    .gantt-task-bar-date-text {
        top: -5px !important;
    }

    .gantt-body {
        background: transparent;
        border: none;
    }

    .gantt-task-bar-date-text {
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }

    .gantt-time-indicator {
        display: none !important;
    }
`;

const AddButtonSubTask = styled.button`
    background: var(--primary-100, #dddafe);
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: #e0e0e0;
    }

    &:focus {
        box-shadow: none;
    }

    &:active {
        box-shadow: none;
        border: none !important;
        border-color: none !important;
    }
`;

const AddButtonAssignee = styled.button`
    background: var(--primary-100, #dddafe);
    border: 1px dashed #5648fb;
    border-radius: 50%;
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: #e0e0e0;
    }

    &:focus {
        box-shadow: none;
    }

    &:active {
        box-shadow: none;
        border: none !important;
        border-color: none !important;
    }
`;

const CustomSwitcher = styled(Form.Check)`
    .form-check-input {
        height: 30px;
        width: 60px;
        box-shadow: none;
        background-color: rgba(196, 196, 196, 1);
        border: none;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");

        &:after {
            content: '';
            font-size: 6px;
            color: #fff;
            margin-left: 35px;
            font-weight: 600;
        }

        &:checked {
            background-color: #5648fb;
            border-color: #5648fb;
            &:after {
                content: '';
                font-size: 6px;
                color: #fff;
                margin-left: 5px;
                font-weight: 600;
            }
        }

        &:focus {
            box-shadow: none;
            border-color: #5648fb;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
        }
    }

    .form-switch .form-check-input {
        background-size: 100% 100%;
    }
`;

const CircleStatus = styled.span<{ color: string }>`
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: ${props => props.color};
    display: inline-block;
`;

const OverlayIndicator = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 6rem;
    position: fixed;
    width: 100%;
    bottom: 0px;
    left: 0;
    background-color: #ffffff;
    font-size: 14px;

    .link-archive {
        &:hover {
            color: #5648fb;
        }
    }

    .square-public-holiday {
        background-color: #fee2e2;
        width: 15px;
        height: 15px;
    }

    @media (max-width: 991px) {
        padding: 1rem 3rem;
    }
`;
