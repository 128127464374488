export const NOTIFICATION_SETTING_REQUEST = 'NOTIFICATION_SETTING_REQUEST';
export const NOTIFICATION_SETTING_SUCCESS = 'NOTIFICATION_SETTING_SUCCESS';
export const NOTIFICATION_SETTING_FAIL = 'NOTIFICATION_SETTING_FAIL';

export const UPDATE_NOTIFICATION_SETTING_REQUEST = 'UPDATE_NOTIFICATION_SETTING_REQUEST';
export const UPDATE_NOTIFICATION_SETTING_SUCCESS = 'UPDATE_NOTIFICATION_SETTING_SUCCESS';
export const UPDATE_NOTIFICATION_SETTING_FAIL = 'UPDATE_NOTIFICATION_SETTING_FAIL';
export const UPDATE_NOTIFICATION_SETTING_RESET = 'UPDATE_NOTIFICATION_SETTING_RESET';

export const CLEAR_STATE = 'CLEAR_STATE';

const initialState = {
    loading: false,
    response: null,
    error: null,
    status: null,
};

export const notificationSettingReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case NOTIFICATION_SETTING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case NOTIFICATION_SETTING_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case NOTIFICATION_SETTING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const updateNotificationSettingReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case UPDATE_NOTIFICATION_SETTING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_NOTIFICATION_SETTING_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case UPDATE_NOTIFICATION_SETTING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case UPDATE_NOTIFICATION_SETTING_RESET:
            return initialState;

        default:
            return state;
    }
};
