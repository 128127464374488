import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import ModalAction from '@/components/atoms/Modals/ModalAction';
import InputBasic from '@/components/atoms/Inputs/InputBasic';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';

import { createBugReport, clearStateCreateBugReport } from '../redux/actions';

import { RootState } from '@/redux/store';
import InputArea from '@/components/atoms/Inputs/InputArea';
import { useLocation } from 'react-router-dom';

interface ParamsProps {
    modal: boolean;
    setModal: (modal: any) => void;
}

export default function AddBug(props: ParamsProps): JSX.Element {
    const { modal, setModal } = props;
    const location = useLocation();

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
    });

    const dispatch = useDispatch();

    const [modalChild, setModalChild] = useState({
        success: false,
    });

    const createBugReportState = useSelector((state: RootState) => state.createBugReport);

    // Function to handle modal, when pin or unpin project
    useEffect(() => {
        if (createBugReportState?.status === 200) {
            setModal(false);
            reset();

            setTimeout(() => {
                setModalChild(prev => ({
                    ...prev,
                    success: true,
                }));
                dispatch(clearStateCreateBugReport());
            }, 1000);
        }
    }, [createBugReportState]);

    // set location
    useEffect(() => {
        if (location.pathname !== null) {
            setValue('route', location.pathname);
        }
    }, [location]);
    /**
     * Function on submit
     *
     * @param any data
     * @return void
     * @see cypress/e2e/dashboard/Project/project.cy.ts
     *      To cypress unit tester
     */
    const onSubmit = (data: any): void => {
        // initialize form data
        const payload: any = new FormData();

        // append data to form data
        for (const key in data) {
            payload.append(key, data[key as keyof typeof data]);
        }

        // Dispatch login action with form data as payload
        dispatch(createBugReport(payload));
    };

    return (
        <>
            <ModalAction
                modal={modal}
                setModal={setModal}
                aria-labelledby="contained-modal-title-vcenter"
                title="Create Bug Report"
                size="md"
            >
                <form action="#" onSubmit={handleSubmit(onSubmit)} method="post">
                    <InputBasic
                        id="bugTitle"
                        type="text"
                        placeholder="Bug Name"
                        innerClassName="my-0"
                        outerClassName="px-4 mt-3"
                        rules={{
                            function: register,
                            name: 'title',
                            rules: {
                                required: 'Bug title is required',
                            },
                            errors,
                        }}
                    />

                    <InputBasic
                        id="bugPath"
                        type="text"
                        placeholder="Bug Path"
                        innerClassName="my-0"
                        outerClassName="px-4 mt-3 d-none"
                        rules={{
                            function: register,
                            name: 'route',
                            rules: {},
                            errors,
                        }}
                    />

                    <InputArea
                        id="bugDescription"
                        placeholder="Description"
                        innerClassName="my-0"
                        outerClassName="px-4 mt-3"
                        isRequired
                        rules={{
                            function: register,
                            name: 'description',
                            rules: {
                                required: 'Bug description is required',
                                // max length 2500
                                maxLength: {
                                    value: 2000,
                                    message: 'Maximum length of 2000 characters',
                                },
                            },
                            errors,
                        }}
                    />

                    <ButtonBasic
                        text="Submit"
                        type="submit"
                        className="btn btn-primary btn-sm mt-4"
                        loading={createBugReportState.loading}
                    />
                </form>
            </ModalAction>

            <ModalDashboard
                modal={modalChild.success}
                setModal={setModalChild}
                variant="success"
                type="success"
                title="Success!"
                body={'Successfully, your bug report has been created'}
                onConfirm={() => setModalChild(prev => ({ ...prev, success: false }))}
            />
        </>
    );
}
