import { Fragment } from 'react';

import Header from '@/components/molecules/Header';

import ForgotPasswordMethod from '../components/ForgotPasswordMethod';

function ForgotPassword(): JSX.Element {
    return (
        <Fragment>
            <div className="container-fluid container-xs container-lg">
                <header>
                    <Header text="Return to home screen" link="/" />
                </header>

                <main className="main-content">
                    <ForgotPasswordMethod />
                </main>
            </div>
        </Fragment>
    );
}

export default ForgotPassword;
