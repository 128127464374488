/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';

import {
    getNotificationSetting,
    updateNotificationSetting,
    clearUpdateNotificationSetting,
} from '../redux/actions';

import Card from '@/components/molecules/Card';
import { Title } from '@/components/molecules/Typography';
import InputSwitcher from '@/components/atoms/Inputs/InputSwitcher';
import InputTime from '@/components/atoms/Inputs/InputTime';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';
import { useNavigate } from 'react-router-dom';
import { RootState } from '@/redux/store';

export default function NotificationSetting(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [setting, setSetting] = useState<{
        push_notification: {
            activated: boolean;
        };
        email_notification: {
            activated: boolean;
            delayHours: any;
            delayMinutes: any;
        };
        phone_notification: {
            activated: boolean;
            delayHours: any;
            delayMinutes: any;
        };
        disable_other_notification: boolean;
    }>({
        push_notification: {
            activated: false,
        },
        email_notification: {
            activated: false,
            delayHours: 0,
            delayMinutes: 0,
        },
        phone_notification: {
            activated: false,
            delayHours: 0,
            delayMinutes: 0,
        },
        disable_other_notification: false,
    });

    const [modal, setModal] = useState({
        caution: false,
        success: false,
    });

    const notificationSettingState = useSelector(
        (state: RootState) => state.notificationSetting,
    );
    const updateNotificationSettingState = useSelector(
        (state: RootState) => state.updateNotificationSetting,
    );

    // For load data on first mount
    useEffect(() => {
        dispatch(getNotificationSetting());
    }, []);

    // For handle if update success
    useEffect(() => {
        if (updateNotificationSettingState.status === 200) {
            setModal(prev => ({
                ...prev,
                caution: false,
            }));
            dispatch(clearUpdateNotificationSetting());

            setTimeout(() => {
                setModal(prev => ({
                    ...prev,
                    success: true,
                }));
                dispatch(getNotificationSetting());
            }, 1000);
        }
    }, [updateNotificationSettingState]);

    // For load data to state
    useEffect(() => {
        if (notificationSettingState?.status === 200) {
            const response = notificationSettingState?.response;
            setSetting({
                ...setting,
                push_notification: {
                    ...setting.push_notification,
                    activated: response.push_notification?.activated,
                },
                email_notification: {
                    ...setting.email_notification,
                    activated: response.email_notification?.activated,
                    delayHours: handleHours(response.email_notification?.delay),
                    delayMinutes: handleMinute(response.email_notification?.delay),
                },
                phone_notification: {
                    ...setting.phone_notification,
                    activated: response.phone_notification?.activated,
                    delayHours: handleHours(response.phone_notification?.delay),
                    delayMinutes: handleMinute(response.phone_notification?.delay),
                },
                disable_other_notification: response.disable_other_notification,
            });
        }
    }, [notificationSettingState]);

    /**
     * Function for handle switch
     *
     * @param number value
     * @param string type
     */
    const handleSwitch = (value: boolean, type: string): any => {
        const disableAll = (): any => {
            if (
                (type === 'email_notification' && value) ||
                (type === 'phone_notification' && value)
            ) {
                return false;
            } else if (
                (type === 'email_notification' && !value) ||
                (type === 'phone_notification' && !value)
            ) {
                return false;
            } else if (
                (type === 'push_notification' && value) ||
                (type === 'push_notification' && !value)
            ) {
                return;
            }

            return false;
        };

        setSetting({
            ...setting,
            [type]: {
                ...(setting[type as keyof typeof setting] as any),
                activated: value,
            },
            disable_other_notification: disableAll(),
        });

        // if (value && ) {
        //     setSetting({
        //         ...setting,
        //         email_notification: {
        //             ...(setting.email_notification as any),
        //             activated: false,
        //         },
        //         disable_other_notification: false,
        //     });
        // }
    };

    /**
     * Function for handle minutes
     *
     * @param number val
     * @returns number | undefined
     */
    const handleMinute = (val: number): number | undefined => {
        if (val !== undefined) {
            return val % 60;
        }
    };

    /**
     * Function for handle hours
     *
     * @param number val
     * @returns number | undefined
     */
    const handleHours = (val: number): number | undefined => {
        if (val !== undefined) {
            return Math.floor(val / 60);
        }
    };

    /**
     * Function for handle change of time in state
     *
     * @param any event
     * @param string state
     * @param string type
     * @returns number
     */
    const handleChangeTime = (event: any, state: string, type: string): any => {
        const value = event.target.value;

        if (value === '') {
            return setSetting({
                ...setting,
                [type]: {
                    ...(setting[type as keyof typeof setting] as any),
                    [state]: 0,
                },
            });
        }

        setSetting({
            ...setting,
            [type]: {
                ...(setting[type as keyof typeof setting] as any),
                [state]: value,
            },
        });
    };

    /**
     * Function for handle hours to minutes
     *
     * @param number hours
     * @param number minutes
     * @returns number
     */
    const handleHoursToMinutes = (hours: number, minutes: number): number => {
        if (hours !== undefined && minutes !== undefined) {
            return hours * 60 + minutes;
        }

        return 0;
    };

    /**
     * Function for handle submit form
     *
     * @returns void
     */
    const handleSubmit = (): void => {
        const formData: any = new FormData();

        const emailNotificationDelay = handleHoursToMinutes(
            parseInt(setting?.email_notification?.delayHours),
            parseInt(setting?.email_notification?.delayMinutes),
        );

        const phoneNotificationDelay = handleHoursToMinutes(
            parseInt(setting?.phone_notification?.delayHours),
            parseInt(setting?.phone_notification?.delayMinutes),
        );

        formData.append(
            'email_notification_activated',
            setting.email_notification.activated ? 1 : 0,
        );

        formData.append(
            'phone_notification_activated',
            setting.phone_notification.activated ? 1 : 0,
        );

        formData.append(
            'push_notification_activated',
            setting.push_notification.activated ? 1 : 0,
        );

        formData.append('phone_notification_delay', phoneNotificationDelay);
        formData.append('email_notification_delay', emailNotificationDelay);

        formData.append(
            'disable_other_notification',
            setting.disable_other_notification ? 1 : 0,
        );

        dispatch(updateNotificationSetting(formData));
    };

    return (
        <>
            <Title text="Notification Settings" />

            <Card classOuter="mt-5 px-2 py-4" loading={notificationSettingState.loading}>
                <div className="row mb-3">
                    <div className="col-md-12">
                        <h6 className="card-title fw-semibold">
                            Configurations Notification
                        </h6>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <InputSwitcher
                            label="Mail Notification"
                            withTooltip="Enable/Disable mail notification
                            for unread notification"
                            checked={setting?.email_notification?.activated}
                            onChange={(e: any): any =>
                                handleSwitch(
                                    e.currentTarget.checked,
                                    'email_notification',
                                )
                            }
                        />
                    </div>
                    <div className="col-md-6">
                        <InputTime
                            label="Notification Reminder by Email"
                            withTooltip="How many minutes/hours of notification
                            should get another reminder through email"
                            hoursValue={setting?.email_notification?.delayHours}
                            minutesValue={setting?.email_notification?.delayMinutes}
                            onChangeHours={(event: any): any => {
                                handleChangeTime(
                                    event,
                                    'delayHours',
                                    'email_notification',
                                );
                            }}
                            onChangeMinutes={(event: any): any => {
                                handleChangeTime(
                                    event,
                                    'delayMinutes',
                                    'email_notification',
                                );
                            }}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <InputSwitcher
                            label="SMS Push Notification"
                            withTooltip="Enable/Disable SMS push
                            notification for unread notification"
                            checked={setting?.phone_notification?.activated}
                            onChange={(e: any): any =>
                                handleSwitch(
                                    e.currentTarget.checked,
                                    'phone_notification',
                                )
                            }
                        />
                    </div>
                    <div className="col-md-6">
                        <InputTime
                            label="Notification Reminder by SMS"
                            withTooltip="How many minutes/hours of notification
                            should get another reminder through SMS"
                            hoursValue={setting?.phone_notification?.delayHours}
                            minutesValue={setting?.phone_notification?.delayMinutes}
                            onChangeHours={(event: any): any => {
                                handleChangeTime(
                                    event,
                                    'delayHours',
                                    'phone_notification',
                                );
                            }}
                            onChangeMinutes={(event: any): any => {
                                handleChangeTime(
                                    event,
                                    'delayMinutes',
                                    'phone_notification',
                                );
                            }}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <InputSwitcher
                            label="All In-App Notification"
                            withTooltip="Enable/Disable all in-app notification"
                            checked={setting?.push_notification?.activated}
                            onChange={(e: any): any =>
                                handleSwitch(e.currentTarget.checked, 'push_notification')
                            }
                        />
                    </div>
                    <div className="col-md-6">
                        <label
                            htmlFor="label"
                            className="me-2 mb-2"
                            style={{
                                fontSize: 14,
                                fontWeight: 500,
                            }}
                        >
                            All Notification (Mail, SMS)
                        </label>
                        <Form.Check
                            type="checkbox"
                            id="default-checkbox"
                            className="form"
                            label="Disable All Notification"
                            checked={setting?.disable_other_notification}
                            onChange={(e: any): any =>
                                setSetting({
                                    ...setting,
                                    email_notification: {
                                        ...setting.email_notification,
                                        activated: false,
                                    },
                                    phone_notification: {
                                        ...setting.phone_notification,
                                        activated: false,
                                    },
                                    disable_other_notification: e.currentTarget.checked,
                                })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-2 mb-3 ms-auto">
                        <ButtonBasic
                            text="Cancel"
                            type="button"
                            className="w-100 btn-outline-primary"
                            onClick={() => navigate('/')}
                        />
                    </div>
                    <div className="col-lg-2 mb-3">
                        <ButtonBasic
                            text="Save Changes"
                            type="button"
                            className="w-100 btn-primary"
                            onClick={() => {
                                setModal({ ...modal, caution: true });
                            }}
                        />
                    </div>
                </div>
            </Card>
            <ModalDashboard
                modal={modal.caution}
                setModal={setModal}
                variant="info"
                type="caution"
                title="Save Changes?"
                body="Are you sure want to save this changes?"
                withCancel
                onConfirm={() => handleSubmit()}
                loading={updateNotificationSettingState?.loading}
            />
            <ModalDashboard
                modal={modal.success}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body="Successfully save change"
                onConfirm={() => {
                    setModal({ ...modal, success: false });
                }}
            />
        </>
    );
}
