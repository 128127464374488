import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import GanttChart from '@/components/organism/GanttCharts/GanttChartNew';
import {
    getAllArchiveTask,
    getAllTaskConstructionSchedule,
    getAllTaskDesignSchedule,
    getAllTaskPrivateSchedule,
    resetAllTaskSpace,
    searchPrivateTask,
    searchPublicTask,
} from '../redux/actions';
import { RootState } from '@/redux/store';

import { currentProjectAccess } from '@/utility/Utils';
import { Tab, Tabs } from 'react-bootstrap';
import { AiOutlineLock } from 'react-icons/ai';

import styled from 'styled-components';
import { getAllNicheTrade } from '../../Settings/redux/actions';

export default function Schedule(): JSX.Element {
    const dispatch = useDispatch();
    const [queryParameters] = useSearchParams();

    const { id } = useParams();
    const navigate = useNavigate();
    const projectAccess = currentProjectAccess();

    const projectId: string = id ?? '';

    const allTaskDesignState = useSelector((state: RootState) => state.allTaskDesign);
    const allTaskConstructionState = useSelector(
        (state: RootState) => state.allTaskConstruction,
    );

    const allTaskPrivateState = useSelector((state: RootState) => state.allTaskPrivate);

    const [isOnlyMe, setIsOnlyMe] = useState({
        design: false,
        construction: false,
        private: false,
    });

    const [params, setParams] = useState({
        project_id: projectId,
        // only_me: 0,
    });

    const tabs = [
        {
            key: 'design',
            title: 'Design',
        },
        {
            key: 'construction',
            title: 'Construction',
        },
        {
            key: 'private',
            title: (
                <>
                    <AiOutlineLock
                        className="me-2"
                        style={{
                            verticalAlign: 'sub',
                        }}
                    />
                    <span>Private</span>
                </>
            ),
        },
    ];

    // get niche trade
    useEffect(() => {
        dispatch(
            getAllNicheTrade({
                project_id: projectId,
                // page: 1,
                // per_page: 1000,
            }),
        );
    }, []);

    const designAccess = projectAccess?.schedule.design;
    const constructionAccess = projectAccess?.schedule.construction;
    // const privateAccess = projectAccess?.schedule.private;

    /**
     * Function handle mount selected tab
     *
     * @returns string
     */
    const handleMountSelectedTab = (): string => {
        let selected = 'design';

        const tabParams = queryParameters.get('tab');

        if (tabParams !== null) {
            selected = tabParams;
            navigate('');
        } else {
            if (
                designAccess?.can_collaborate_schedule === true ||
                designAccess?.can_view_schedule === true
            ) {
                selected = 'design';
            } else if (
                constructionAccess?.can_collaborate_schedule === true ||
                constructionAccess?.can_view_schedule === true
            ) {
                selected = 'construction';
            } else {
                selected = 'private';
            }
        }

        return selected;
    };
    const [selectedTab, setSelectedTab] = useState('');

    useEffect(() => {
        setSelectedTab(handleMountSelectedTab());
    }, []);

    /**
     * Function load data
     *
     * @returns void
     */
    const loadData = (): void => {
        if (selectedTab === '') return;

        switch (selectedTab) {
            case 'design':
                dispatch(
                    getAllTaskDesignSchedule({
                        ...params,
                        only_me: isOnlyMe.design ? 1 : 0,
                    }),
                );
                dispatch(
                    getAllArchiveTask({
                        ...params,
                        per_page: 10,
                        type: selectedTab,
                    }),
                );
                dispatch(
                    searchPublicTask({
                        ...params,
                        type: selectedTab,
                        search: '',
                    }),
                );
                break;
            case 'construction':
                dispatch(
                    getAllTaskConstructionSchedule({
                        ...params,
                        only_me: isOnlyMe.construction ? 1 : 0,
                    }),
                );
                dispatch(
                    getAllArchiveTask({
                        ...params,
                        per_page: 10,
                        type: selectedTab,
                    }),
                );
                dispatch(
                    searchPublicTask({
                        ...params,
                        type: selectedTab,
                        search: '',
                    }),
                );
                break;
            case 'private':
                dispatch(
                    getAllTaskPrivateSchedule({
                        ...params,
                        only_me: isOnlyMe.private ? 1 : 0,
                    }),
                );
                dispatch(
                    getAllArchiveTask({
                        ...params,
                        per_page: 10,
                        type: selectedTab,
                    }),
                );
                dispatch(
                    searchPrivateTask({
                        ...params,
                        search: '',
                    }),
                );
                break;
            default:
                break;
        }
    };

    // load data
    useEffect(() => {
        if (selectedTab === '') return;

        dispatch(resetAllTaskSpace());
        loadData();
    }, [selectedTab]);

    return (
        <div className="row mt-4">
            <div className="col-md-12 mt-1">
                <WrapperTabs
                    id="uncontrolled-tab"
                    activeKey={selectedTab}
                    className="border-0"
                    onSelect={(k: any) => setSelectedTab(k)}
                >
                    {tabs.map(tab =>
                        projectAccess?.schedule[tab.key]?.can_collaborate_schedule ===
                            false &&
                        projectAccess?.schedule[tab.key]?.can_view_schedule ===
                            false ? null : (
                            <Tab eventKey={tab.key} title={tab.title} key={tab.key}>
                                <GanttChart
                                    params={params}
                                    selectedTab={selectedTab}
                                    setIsOnlyMe={setIsOnlyMe}
                                    setParams={setParams}
                                    loadData={loadData}
                                    projectId={projectId}
                                    reducer={
                                        tab.key === 'design'
                                            ? allTaskDesignState
                                            : tab.key === 'construction'
                                            ? allTaskConstructionState
                                            : allTaskPrivateState
                                    }
                                    isOnlyMe={
                                        tab.key === 'design'
                                            ? isOnlyMe.design
                                            : tab.key === 'construction'
                                            ? isOnlyMe.construction
                                            : isOnlyMe.private
                                    }
                                    permissions={{
                                        collaborate:
                                            projectAccess?.schedule[tab.key]
                                                ?.can_collaborate_schedule !== false,
                                        invite: projectAccess?.schedule
                                            ?.can_invite_schedule,
                                    }}
                                />
                            </Tab>
                        ),
                    )}
                </WrapperTabs>
            </div>
        </div>
    );
}

const WrapperTabs = styled(Tabs)`
    .nav-item {
        padding: 1rem;
    }

    .nav-link {
        border-radius: 20px;
        background: rgba(86, 72, 251, 0.5);
        color: #fff !important;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        padding: 0.2rem 1rem;

        &.active {
            color: #fff !important;
            background-color: #5648fb;
        }
    }
`;
