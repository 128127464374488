import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { BiArrowBack } from 'react-icons/bi';

export function Title(props: { text?: string }): JSX.Element {
    const { text } = props;

    return <h1 className="fw-semibold h5">{text}</h1>;
}

export function TitleWithBack(props: { text?: string }): JSX.Element {
    const { text } = props;
    const navigate = useNavigate();

    return (
        <div className="d-flex">
            <BiArrowBack
                size={25}
                onClick={() => navigate(-1)}
                style={{
                    cursor: 'pointer',
                }}
            />
            <h1 className="fw-semibold h5 ms-4 mb-0">{text}</h1>
        </div>
    );
}

export function SubTitle(props: { text?: string }): JSX.Element {
    const { text } = props;

    return <h2 className="h6">{text}</h2>;
}

export function HeaderContent(props: { text?: string; subtext?: string }): JSX.Element {
    const { text, subtext } = props;

    return (
        <Fragment>
            <h1 className="fw-semibold h4 mb-3" style={{ fontSize: 20 }}>
                {text}
            </h1>
            <h2 className="fw-normal h6" style={{ fontSize: 16 }}>
                {subtext}
            </h2>
        </Fragment>
    );
}

export function HeaderCard(props: { text?: string; className?: string }): JSX.Element {
    const { text, className } = props;

    return (
        <Fragment>
            <h1 className={`fw-semibold ${className ?? ''}`} style={{ fontSize: 16 }}>
                {text}
            </h1>
        </Fragment>
    );
}
