import { axiosClient } from '@/services/GatewayService';
import { AppDispatch } from '@/redux/store';

import {
    SETTING_COMPANY_REQUEST,
    SETTING_COMPANY_SUCCESS,
    SETTING_COMPANY_FAIL,
    ACTIVE_INHOUSE_REQUEST,
    ACTIVE_INHOUSE_SUCCESS,
    ACTIVE_INHOUSE_FAIL,
    PENDING_INHOUSE_REQUEST,
    PENDING_INHOUSE_SUCCESS,
    PENDING_INHOUSE_FAIL,
    INACTIVE_INHOUSE_REQUEST,
    INACTIVE_INHOUSE_SUCCESS,
    INACTIVE_INHOUSE_FAIL,
    UPDATE_COMPANY_REQUEST,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_FAIL,
    UPDATE_COMPANY_RESET,
    UPDATE_COMPANY_PICTURE_REQUEST,
    UPDATE_COMPANY_PICTURE_SUCCESS,
    UPDATE_COMPANY_PICTURE_FAIL,
    UPDATE_COMPANY_PICTURE_RESET,
    ACTIVATED_INHOUSE_REQUEST,
    ACTIVATED_INHOUSE_SUCCESS,
    ACTIVATED_INHOUSE_FAIL,
    ACTIVATED_INHOUSE_RESET,
    TERMINATED_INHOUSE_REQUEST,
    TERMINATED_INHOUSE_SUCCESS,
    TERMINATED_INHOUSE_FAIL,
    TERMINATED_INHOUSE_RESET,
    DETAIL_INHOUSE_REQUEST,
    DETAIL_INHOUSE_SUCCESS,
    DETAIL_INHOUSE_FAIL,
    DETAIL_INHOUSE_RESET,
    INVITED_INHOUSE_REQUEST,
    INVITED_INHOUSE_SUCCESS,
    INVITED_INHOUSE_FAIL,
    INVITED_INHOUSE_RESET,
    RESEND_INVITATION_REQUEST,
    RESEND_INVITATION_SUCCESS,
    RESEND_INVITATION_FAIL,
    CANCEL_INVITATION_REQUEST,
    CANCEL_INVITATION_SUCCESS,
    CANCEL_INVITATION_FAIL,
    CLEAR_STATE,
} from '../reducers';

/**
 * Function to get of company setting
 *
 * @return Promise<void>
 */
export const getCompanySetting = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SETTING_COMPANY_REQUEST });
        try {
            const res = await axiosClient.get('company/profile');

            dispatch({
                type: SETTING_COMPANY_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: SETTING_COMPANY_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get active inhouse of company
 *
 * @return Promise<void>
 */
export const getAllPendingInhouse = (params: {
    page: number;
    per_page: number;
    search: string;
    sort_by: string;
    sort_asc: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: PENDING_INHOUSE_REQUEST });

        try {
            const res = await axiosClient.get('company/inhouse/invitations', {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: PENDING_INHOUSE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: PENDING_INHOUSE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get active inhouse of company
 *
 * @return Promise<void>
 */
export const getActiveInhouse = (params: {
    page: number;
    per_page: number;
    search: string;
    sort_by: string;
    sort_asc: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ACTIVE_INHOUSE_REQUEST });

        try {
            const res = await axiosClient.get('company/inhouse/users', {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: ACTIVE_INHOUSE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ACTIVE_INHOUSE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get terminate inhouse of company
 *
 * @return Promise<void>
 */
export const getInactiveInhouse = (params: {
    page: number;
    per_page: number;
    search: string;
    sort_by: string;
    sort_asc: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: INACTIVE_INHOUSE_REQUEST });
        try {
            const res = await axiosClient.get('company/inhouse/users', {
                params: {
                    ...params,
                    terminated: 1,
                },
            });

            dispatch({
                type: INACTIVE_INHOUSE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: INACTIVE_INHOUSE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to update profile of company
 *
 * @param {FormData} formData
 * @return Promise<void>
 */
export const updateCompany = (formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_COMPANY_REQUEST });
        try {
            const res = await axiosClient.post('company/profile', formData);

            dispatch({
                type: UPDATE_COMPANY_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UPDATE_COMPANY_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to upload profile picture of company
 *
 * @param {FormData} formData
 * @return Promise<void>
 */
export const updateCompanyPicture = (formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_COMPANY_PICTURE_REQUEST });
        try {
            const res = await axiosClient.post('company/profile/picture', formData);

            dispatch({
                type: UPDATE_COMPANY_PICTURE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UPDATE_COMPANY_PICTURE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function get detail of inhouse
 *
 * @param {FormData} formData
 * @return Promise<void>
 */
export const getDetailInhouse = (ids: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_INHOUSE_REQUEST });
        try {
            const res = await axiosClient.get('company/inhouse/users', {
                params: {
                    id: ids,
                },
            });

            dispatch({
                type: DETAIL_INHOUSE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DETAIL_INHOUSE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to activate inhouse of company
 *
 * @param {FormData} formData
 * @return Promise<void>
 */
export const activatedInhouse = (id: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ACTIVATED_INHOUSE_REQUEST });
        try {
            const res = await axiosClient.put('company/inhouse/users', null, {
                params: {
                    ids: id,
                },
            });

            dispatch({
                type: ACTIVATED_INHOUSE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ACTIVATED_INHOUSE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to terminate inhouse of company
 *
 * @param {FormData} formData
 * @return Promise<void>
 */
export const terminatedInhouse = (id: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: TERMINATED_INHOUSE_REQUEST });
        try {
            const res = await axiosClient.delete('company/inhouse/users', {
                params: {
                    ids: id,
                },
            });

            dispatch({
                type: TERMINATED_INHOUSE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: TERMINATED_INHOUSE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function register inhouse on complete
 *
 * @return {Promise<void>}
 */
export const invitedInhouse = (data: object, project_id: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: INVITED_INHOUSE_REQUEST });

        try {
            const res = await axiosClient.post(
                `company/inhouse/invitations?${
                    project_id !== '' ? `project_id=${project_id}` : ''
                }`,
                data,
            );

            dispatch({
                type: INVITED_INHOUSE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: INVITED_INHOUSE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function resend invitation
 *
 * @return {Promise<void>}
 */
export const resendInvitation = (id: number): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESEND_INVITATION_REQUEST });
        try {
            const res = await axiosClient.put('company/inhouse/invitations', null, {
                params: {
                    id,
                },
            });

            dispatch({
                type: RESEND_INVITATION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: RESEND_INVITATION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function cancel invitation
 *
 * @return {Promise<void>}
 */
export const cancelInvitation = (id: number): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CANCEL_INVITATION_REQUEST });
        try {
            const res = await axiosClient.delete('company/inhouse/invitations', {
                params: {
                    id,
                },
            });

            dispatch({
                type: CANCEL_INVITATION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: CANCEL_INVITATION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to clear state
 *
 * @returns Promise<void>
 */
export const clearStateCompanySetting = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CLEAR_STATE });
    };
};

/**
 * Function to clear state
 *
 * @returns Promise<void>
 */
export const clearUpdateCompany = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_COMPANY_RESET });
        dispatch({ type: UPDATE_COMPANY_PICTURE_RESET });
        dispatch({ type: INVITED_INHOUSE_RESET });
    };
};

// export const clearUpdateCompanyPicture = (): any => {
//     return async (dispatch: AppDispatch): Promise<void> => {};
// };

/**
 * Function to clear state detail of inhouse
 *
 * @returns Promise<void>
 */
export const clearDetailInhouse = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_INHOUSE_RESET });
    };
};

/**
 * Function to clear state of activated and terminated of inhouse
 *
 * @returns Promise<void>
 */
export const clearActivatedTerminateInhouse = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ACTIVATED_INHOUSE_RESET });
        dispatch({ type: TERMINATED_INHOUSE_RESET });
    };
};
