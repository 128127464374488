import PaginationView from '@/components/organism/TableBasic/PaginationView';
import { Dropdown, Form } from 'react-bootstrap';
import { HiDotsVertical } from 'react-icons/hi';
import styled from 'styled-components';
import { ScheduleTableProps } from '../../interfaces';
import { ThreeDots } from 'react-loader-spinner';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { generateInitialImageUrl } from '@/utility/Utils';
import { RootState } from '@/redux/store';
import { useSelector } from 'react-redux';

export default function ScheduleTable({
    state,
    handlePagination,
    handleSearch,
    handleOpenModalRestore,
    handleOpenModalDelete,
    handleDetailTask,
    permissions,
}: ScheduleTableProps): JSX.Element {
    const [selectedItem, setSelectedItem] = useState<number[]>([]);
    const [selectedAll, setSelectedAll] = useState<boolean>(false);

    const deleteTaskState = useSelector((state: RootState) => state.deleteTask);
    const restoreTaskState = useSelector((state: RootState) => state.restoreTask);

    const handleCheckAllItem = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const isChecked = e.target.checked;

        if (isChecked) {
            setSelectedItem(state?.response?.data.map(item => item.id));
            setSelectedAll(true);
        } else {
            setSelectedItem([]);
            setSelectedAll(false);
        }
    };

    const handleCheckItem = (e: React.ChangeEvent<HTMLInputElement>, item: any): void => {
        const isChecked = e.target.checked;

        if (isChecked) {
            setSelectedItem([...selectedItem, item.id]);
        } else {
            setSelectedItem(selectedItem.filter(id => id !== item.id));
        }
    };

    useEffect(() => {
        if (deleteTaskState.status === 200 || restoreTaskState.status === 200) {
            setSelectedItem([]);
            setSelectedAll(false);
        }
    }, [deleteTaskState, restoreTaskState]);

    return (
        <div>
            {selectedItem.length > 0 && (
                <div
                    className="row mb-3 p-2 gy-3 gy-lg-0 text-primary"
                    style={{
                        borderRadius: 10,
                        backgroundColor: '#DDDAFE',
                    }}
                >
                    <div className="col-md-12 col-lg-3">
                        <Form.Check
                            type="checkbox"
                            className="m-0"
                            onChange={e => handleCheckAllItem(e)}
                            checked={selectedAll}
                            label={
                                <span className="ms-2 fw-semibold">
                                    {selectedItem.length}&nbsp;item selected
                                </span>
                            }
                        />
                    </div>
                    <div className="col-md-12 col-lg-6 text-lg-center d-lg-flex justify-content-center">
                        <div
                            className="mx-lg-2 link-primary"
                            role="button"
                            onClick={() => handleOpenModalRestore(selectedItem)}
                        >
                            <svg
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                    verticalAlign: 'text-bottom',
                                }}
                            >
                                <path
                                    d="M2.99988 2.5V6.66667M2.99988 6.66667H7.16654M2.99988 6.66667L5.49988 4.40973C6.82677 3.22213 8.57899 2.5 10.4999 2.5C14.642 2.5 17.9999 5.85786 17.9999 10C17.9999 14.1421 14.642 17.5 10.4999 17.5C6.93057 17.5 3.94361 15.0066 3.18572 11.6667"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M2.99988 2.5V6.66667M2.99988 6.66667H7.16654M2.99988 6.66667L5.49988 4.40973C6.82677 3.22213 8.57899 2.5 10.4999 2.5C14.642 2.5 17.9999 5.85786 17.9999 10C17.9999 14.1421 14.642 17.5 10.4999 17.5C6.93057 17.5 3.94361 15.0066 3.18572 11.6667"
                                    stroke="black"
                                    strokeOpacity="0.2"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <span className="ms-2">Restore Task</span>
                        </div>
                        <div
                            className="mx-lg-2 link-primary"
                            role="button"
                            onClick={() => handleOpenModalDelete(selectedItem)}
                        >
                            <svg
                                width="21"
                                height="20"
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                    verticalAlign: 'text-bottom',
                                }}
                            >
                                <path
                                    d="M3.83331 5H17.1666M13.8333 5L13.6078 4.32339C13.3892 3.66771 13.2799 3.33987 13.0772 3.09748C12.8982 2.88345 12.6684 2.71777 12.4087 2.61565C12.1147 2.5 11.7691 2.5 11.078 2.5H9.922C9.23085 2.5 8.88528 2.5 8.59123 2.61565C8.33158 2.71777 8.10171 2.88345 7.92272 3.09748C7.72002 3.33987 7.61074 3.66771 7.39218 4.32339L7.16665 5M15.5 5V13.5C15.5 14.9001 15.5 15.6002 15.2275 16.135C14.9878 16.6054 14.6054 16.9878 14.135 17.2275C13.6002 17.5 12.9001 17.5 11.5 17.5H9.49998C8.09985 17.5 7.39978 17.5 6.865 17.2275C6.3946 16.9878 6.01215 16.6054 5.77246 16.135C5.49998 15.6002 5.49998 14.9001 5.49998 13.5V5M12.1666 8.33333V14.1667M8.83331 8.33333V14.1667"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M3.83331 5H17.1666M13.8333 5L13.6078 4.32339C13.3892 3.66771 13.2799 3.33987 13.0772 3.09748C12.8982 2.88345 12.6684 2.71777 12.4087 2.61565C12.1147 2.5 11.7691 2.5 11.078 2.5H9.922C9.23085 2.5 8.88528 2.5 8.59123 2.61565C8.33158 2.71777 8.10171 2.88345 7.92272 3.09748C7.72002 3.33987 7.61074 3.66771 7.39218 4.32339L7.16665 5M15.5 5V13.5C15.5 14.9001 15.5 15.6002 15.2275 16.135C14.9878 16.6054 14.6054 16.9878 14.135 17.2275C13.6002 17.5 12.9001 17.5 11.5 17.5H9.49998C8.09985 17.5 7.39978 17.5 6.865 17.2275C6.3946 16.9878 6.01215 16.6054 5.77246 16.135C5.49998 15.6002 5.49998 14.9001 5.49998 13.5V5M12.1666 8.33333V14.1667M8.83331 8.33333V14.1667"
                                    stroke="black"
                                    strokeOpacity="0.2"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <span className="ms-2">Delete Permanently</span>
                        </div>
                    </div>
                    <div
                        className="col-md-12 col-lg-3 text-lg-end"
                        role="button"
                        onClick={() => {
                            setSelectedItem([]);
                            setSelectedAll(false);
                        }}
                    >
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                                verticalAlign: 'text-bottom',
                            }}
                        >
                            <path
                                d="M5 5L15 15M15 5L5 15"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M5 5L15 15M15 5L5 15"
                                stroke="black"
                                strokeOpacity="0.2"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <span className="ms-2">Dismiss</span>
                    </div>
                </div>
            )}
            <SearchTable className="position-relative mb-3">
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                        top: 12,
                        left: 12,
                        position: 'absolute',
                    }}
                >
                    <path
                        d="M10.5304 10.5407L14 14M12 7C12 9.76142 9.76142 12 7 12C4.23858 12 2 9.76142 2 7C2 4.23858 4.23858 2 7 2C9.76142 2 12 4.23858 12 7Z"
                        stroke="#838383"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
                <input
                    type="text"
                    className="ps-5 py-2 w-100 border-0 border-bottom"
                    placeholder="Search task"
                    onChange={e => handleSearch(e.target.value)}
                />
            </SearchTable>

            {state?.loading !== true ? (
                state?.response?.data?.length > 0 ? (
                    <div className="table-responsive">
                        <table
                            className="table table-hover "
                            style={{
                                verticalAlign: 'middle',
                            }}
                        >
                            <thead>
                                <tr>
                                    {permissions.collaborate && (
                                        <th>
                                            <Form.Check
                                                type="checkbox"
                                                className="m-0"
                                                onChange={e => handleCheckAllItem(e)}
                                                checked={selectedAll}
                                            />
                                        </th>
                                    )}
                                    <th scope="col" className="fw-medium">
                                        Task
                                    </th>
                                    <th scope="col" className="fw-medium">
                                        Archived Date
                                    </th>
                                    <th scope="col" className="fw-medium">
                                        Archived by
                                    </th>
                                    <th scope="col" className="fw-medium">
                                        Parent Task
                                    </th>
                                    <th scope="col" className="fw-medium">
                                        Permanently Deleted at
                                    </th>
                                    {permissions.collaborate && <th></th>}
                                </tr>
                            </thead>

                            <tbody>
                                {state?.response?.data.map(
                                    (
                                        item: {
                                            id: number;
                                            name: string;
                                            deleted_at: string;
                                            creator_id: string;
                                            parent_task_name: string;
                                            permanently_deleted_at: string;
                                            archived_by: {
                                                user_avatar: string;
                                                user_name: string;
                                                user_email: string;
                                            };
                                            disabled: boolean;
                                        },
                                        index: number,
                                    ) => (
                                        <tr key={index}>
                                            {permissions.collaborate && (
                                                <td>
                                                    <Form.Check
                                                        type="checkbox"
                                                        className="m-0"
                                                        onChange={e =>
                                                            handleCheckItem(e, item)
                                                        }
                                                        checked={selectedItem.includes(
                                                            item?.id,
                                                        )}
                                                    />
                                                </td>
                                            )}
                                            <td>
                                                <span
                                                    className="link-primary"
                                                    role="button"
                                                    onClick={() =>
                                                        handleDetailTask(item, true)
                                                    }
                                                >
                                                    {item?.name}
                                                </span>
                                            </td>
                                            <td>
                                                {moment(item.deleted_at).format(
                                                    'DD/MM/YYYY',
                                                )}
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        src={
                                                            item?.archived_by
                                                                ?.user_avatar ??
                                                            generateInitialImageUrl(
                                                                item?.archived_by
                                                                    ?.user_name ??
                                                                    item?.archived_by
                                                                        ?.user_email,
                                                            )
                                                        }
                                                        alt="assignee"
                                                        style={{
                                                            width: 30,
                                                            height: 30,
                                                        }}
                                                        className="rounded-circle img-fluid border border-white me-2"
                                                    />
                                                    {item?.archived_by?.user_name}
                                                </div>
                                            </td>
                                            <td>
                                                {item?.parent_task_name ?? '-'}{' '}
                                                {item?.disabled && '(Archived)'}
                                            </td>
                                            <td className="text-danger">
                                                {moment(
                                                    item?.permanently_deleted_at,
                                                ).format('DD/MM/YYYY')}
                                            </td>
                                            {permissions.collaborate && (
                                                <td>
                                                    <Dropdown
                                                        drop="down-centered"
                                                        style={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <CustomDropdown
                                                            variant="none"
                                                            id="dropdown-autoclose-true"
                                                        >
                                                            <HiDotsVertical size={14} />
                                                        </CustomDropdown>

                                                        <CustomDropdownMenu>
                                                            {!item?.disabled && (
                                                                <Dropdown.Item
                                                                    className="text-decoration-none"
                                                                    onClick={() =>
                                                                        handleOpenModalRestore(
                                                                            [item.id],
                                                                        )
                                                                    }
                                                                >
                                                                    <svg
                                                                        width="18"
                                                                        height="17"
                                                                        viewBox="0 0 21 20"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        style={{
                                                                            verticalAlign:
                                                                                'text-bottom',
                                                                        }}
                                                                    >
                                                                        <path
                                                                            d="M2.99988 2.5V6.66667M2.99988 6.66667H7.16654M2.99988 6.66667L5.49988 4.40973C6.82677 3.22213 8.57899 2.5 10.4999 2.5C14.642 2.5 17.9999 5.85786 17.9999 10C17.9999 14.1421 14.642 17.5 10.4999 17.5C6.93057 17.5 3.94361 15.0066 3.18572 11.6667"
                                                                            stroke="currentColor"
                                                                            strokeWidth="2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                        <path
                                                                            d="M2.99988 2.5V6.66667M2.99988 6.66667H7.16654M2.99988 6.66667L5.49988 4.40973C6.82677 3.22213 8.57899 2.5 10.4999 2.5C14.642 2.5 17.9999 5.85786 17.9999 10C17.9999 14.1421 14.642 17.5 10.4999 17.5C6.93057 17.5 3.94361 15.0066 3.18572 11.6667"
                                                                            stroke="black"
                                                                            strokeOpacity="0.2"
                                                                            strokeWidth="2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>
                                                                    <span className="ms-1">
                                                                        Restore task
                                                                    </span>
                                                                </Dropdown.Item>
                                                            )}
                                                            <Dropdown.Item
                                                                className="text-decoration-none"
                                                                onClick={() => {
                                                                    handleOpenModalDelete(
                                                                        [item.id],
                                                                    );
                                                                }}
                                                            >
                                                                <svg
                                                                    width="18"
                                                                    height="17"
                                                                    viewBox="0 0 21 20"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    style={{
                                                                        verticalAlign:
                                                                            'text-bottom',
                                                                    }}
                                                                >
                                                                    <path
                                                                        d="M3.83331 5H17.1666M13.8333 5L13.6078 4.32339C13.3892 3.66771 13.2799 3.33987 13.0772 3.09748C12.8982 2.88345 12.6684 2.71777 12.4087 2.61565C12.1147 2.5 11.7691 2.5 11.078 2.5H9.922C9.23085 2.5 8.88528 2.5 8.59123 2.61565C8.33158 2.71777 8.10171 2.88345 7.92272 3.09748C7.72002 3.33987 7.61074 3.66771 7.39218 4.32339L7.16665 5M15.5 5V13.5C15.5 14.9001 15.5 15.6002 15.2275 16.135C14.9878 16.6054 14.6054 16.9878 14.135 17.2275C13.6002 17.5 12.9001 17.5 11.5 17.5H9.49998C8.09985 17.5 7.39978 17.5 6.865 17.2275C6.3946 16.9878 6.01215 16.6054 5.77246 16.135C5.49998 15.6002 5.49998 14.9001 5.49998 13.5V5M12.1666 8.33333V14.1667M8.83331 8.33333V14.1667"
                                                                        stroke="currentColor"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M3.83331 5H17.1666M13.8333 5L13.6078 4.32339C13.3892 3.66771 13.2799 3.33987 13.0772 3.09748C12.8982 2.88345 12.6684 2.71777 12.4087 2.61565C12.1147 2.5 11.7691 2.5 11.078 2.5H9.922C9.23085 2.5 8.88528 2.5 8.59123 2.61565C8.33158 2.71777 8.10171 2.88345 7.92272 3.09748C7.72002 3.33987 7.61074 3.66771 7.39218 4.32339L7.16665 5M15.5 5V13.5C15.5 14.9001 15.5 15.6002 15.2275 16.135C14.9878 16.6054 14.6054 16.9878 14.135 17.2275C13.6002 17.5 12.9001 17.5 11.5 17.5H9.49998C8.09985 17.5 7.39978 17.5 6.865 17.2275C6.3946 16.9878 6.01215 16.6054 5.77246 16.135C5.49998 15.6002 5.49998 14.9001 5.49998 13.5V5M12.1666 8.33333V14.1667M8.83331 8.33333V14.1667"
                                                                        stroke="black"
                                                                        strokeOpacity="0.2"
                                                                        strokeWidth="1.5"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                                <span className="ms-1">
                                                                    Delete task
                                                                </span>
                                                            </Dropdown.Item>
                                                        </CustomDropdownMenu>
                                                    </Dropdown>
                                                </td>
                                            )}
                                        </tr>
                                    ),
                                )}
                            </tbody>
                        </table>
                        <PaginationView
                            items={state?.response}
                            handlePagination={handlePagination}
                            withoutPagination={false}
                        />
                    </div>
                ) : (
                    <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{
                            height: '50vh',
                        }}
                    >
                        <p className="mb-0">No archived task found</p>
                    </div>
                )
            ) : (
                <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{
                        height: '50vh',
                    }}
                >
                    <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color="#5648FB"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        // wrapperClassName=""
                        visible={true}
                    />
                </div>
            )}
        </div>
    );
}

const SearchTable = styled.div`
    input:focus-visible {
        outline: none;
        box-shadow: none;
    }

    input:focus {
        border-bottom: 1px solid #5648fb !important;
    }
`;

export const CustomDropdown = styled(Dropdown.Toggle)`
    background: transparent;
    border: none;

    &:hover {
        color: #5648fb;
        background: #dddafe;
        box-shadow: none;
        border: none;
    }

    &:focus {
        color: #5648fb;
        background: #dddafe;
        box-shadow: none;
        border: none;
    }

    &:active {
        color: #5648fb;
        background: #dddafe;
        box-shadow: none;
        border: none;
    }

    &:focus-visible {
        color: #5648fb;
        background: #dddafe;
        box-shadow: none;
        border: none;
    }

    &:after {
        display: none;
    }
`;

export const CustomDropdownMenu = styled(Dropdown.Menu)`
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 0.5rem;

    .text-dark::active {
        background: #5648fb;
        color: #fff;
    }

    a {
        text-decoration: none;
        color: #000;
    }

    .label-filter {
        font-size: 14px;
    }

    .dropdown-item {
        font-size: 14px;
        vertical-align: middle;
        padding: 0.3rem 0.5rem;
        border-radius: 5px;

        &:hover {
            background: #dddafe;
            border-radius: 5px;
            a {
                color: #000;
            }
        }

        &:active {
            background: #5648fb;
            border-radius: 5px;

            a {
                color: #fff;
            }

            .custom-icon {
                background: #fff !important;
                color: #fff !important;
            }

            .react-icon {
                color: #fff !important;
            }

            .text-danger {
                color: #fff !important;
            }

            .bg-danger {
                background: #fff !important;
            }
        }
    }
`;
