import {
    LegacyRef,
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getAllCompany, getAllCompanyMap } from '../redux/actions';
import { RootState } from '@/redux/store';
import FormRange from 'react-bootstrap/esm/FormRange';
import styled from 'styled-components';
import Map, {
    AttributionControl,
    CircleLayer,
    FullscreenControl,
    GeolocateControl,
    Layer,
    MapRef,
    Marker,
    NavigationControl,
    Popup,
    ScaleControl,
    Source,
} from 'react-map-gl';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createDirectMessage } from '../../Chat/redux/actions';
import InputSearchSelect from '@/components/atoms/Inputs/InputSearchSelect';
import { getNiche } from '@/routes/Authentication/Register/redux/actions';
import type { FeatureCollection } from 'geojson';

import ControlPanel from './ControlPanel';
import CustomOverlay from './CustomOverlay';
import { useSearchBoxCore } from '@mapbox/search-js-react';
import { v4 } from 'uuid';
import InputAsyncSelect from '@/components/atoms/Inputs/InputAsyncSelect';
import { Title } from '@/components/molecules/Typography';
import { ThreeDots } from 'react-loader-spinner';

export default function Directory(): JSX.Element {
    const key = import.meta.env.VITE_MAP_BOX;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const mapRef = useRef<MapRef>();
    const allCompanyState = useSelector((state: RootState) => state.allCompany);
    const allCompanyMapState = useSelector((state: RootState) => state.allCompanyMap);

    const currentProfileState = useSelector((state: RootState) => state.currentProfile);

    const allNicheSubContractorState = useSelector(
        (state: RootState) => state.allNicheSubContractor,
    );

    const newDirectMessageState = useSelector(
        (state: RootState) => state.newDirectMessage,
    );
    const [isShowCompany, setIsShowCompany] = useState(0);
    const [range, setRange] = useState('10');

    const [params, setParams] = useState({
        page: 1,
        per_page: 30,
        search: '',
        vic_distance: '10',
        longitude: 0,
        latitude: 0,
    });

    const [tableData, setTableData] = useState<any>([]);
    const [initialMap, setInitialMap] = useState({
        latitude: 0,
        longitude: 0,
        status: false,
        zoom: 11,
    });

    const [viewCoordinates, setViewCoordinates] = useState({
        latitude: 0,
        longitude: 0,
        zoom: 11,
    });
    const [isViewCoordinates, setIsViewCoordinates] = useState(false);

    const [nicheLists, setNicheList] = useState<Array<{ label: string; value: string }>>(
        [],
    );
    const [groupMarkers, setGroupMarkers] = useState<any>([]);
    const [popupManyInfo, setPopupManyInfo] = useState<{
        latitude: number;
        longitude: number;
        data: any;
    } | null>(null);

    const metersToPixelsAtMaxZoom = (meters: number, latitude: number): number => {
        return meters / 0.075 / Math.cos((latitude * Math.PI) / 180);
    };

    const layerStyle: CircleLayer = {
        id: 'point',
        type: 'circle',
        paint: {
            'circle-radius': {
                base: 2,
                stops: [
                    [0, 0],
                    [
                        20,
                        metersToPixelsAtMaxZoom(
                            Number(range) * 1000,
                            initialMap.latitude,
                        ),
                    ],
                ],
            },
            'circle-color': '#000000',
            'circle-opacity': 0.1,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#000000',
            'circle-stroke-opacity': 0.2,
        },
    };

    const geojson: FeatureCollection = {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [
                        isViewCoordinates
                            ? viewCoordinates.longitude
                            : initialMap?.longitude ?? 0,
                        isViewCoordinates
                            ? viewCoordinates.latitude
                            : initialMap?.latitude ?? 0,
                    ],
                },
                properties: {
                    id: String(new Date().getTime()),
                }, // Add properties here
            },
        ],
    };

    // get all niche and subcontractor
    useEffect(() => {
        dispatch(getNiche({ with_sub_niche: 1 }));
    }, []);

    // get all company
    useEffect(() => {
        if (allCompanyState?.status === 200) {
            if (params.page > 1) {
                const newTableData = [...tableData, ...allCompanyState?.response?.data];
                const uniqueTableData = Array.from(
                    new Set(newTableData.map(item => item.id)),
                ).map(id => {
                    return newTableData.find(item => item.id === id);
                });
                return setTableData(uniqueTableData);
            }
            setTableData(allCompanyState?.response?.data);
        }
    }, [allCompanyState]);

    // get all company
    useEffect(() => {
        if (currentProfileState?.status === 200) {
            const { latitude, longitude } = currentProfileState?.response?.company;

            setInitialMap({
                latitude,
                longitude,
                status: true,
                zoom: 11,
            });
        }
    }, [currentProfileState]);
    // Use Effect if range is changed then get all company with debounce
    useEffect(() => {
        const debounce = setTimeout(() => {
            // TODO: page = 1
            const payload = {
                ...params,
                // page: 1,
                vic_distance: range,
                company_id: searchParams.get('id') ?? '',
            };

            setParams(payload);
            dispatch(getAllCompany(payload));
            dispatch(getAllCompanyMap(payload));
        }, 500);

        return () => {
            clearTimeout(debounce);
        };
    }, [range]);

    // Function to handle redirect
    useEffect(() => {
        if (
            newDirectMessageState?.status === 200 &&
            newDirectMessageState?.isRedirected === true
        ) {
            const chId = newDirectMessageState?.response?.id;

            navigate('/chat', {
                state: {
                    id: chId,
                },
            });
        }
    }, [newDirectMessageState]);

    // Function to handle get all niche
    useEffect(() => {
        if (allNicheSubContractorState?.status === 200) {
            const nicheList = allNicheSubContractorState?.nicheSub?.data?.map(
                (item: any) => ({
                    label: item.name,
                    value: item.full_id,
                }),
            );

            setNicheList(nicheList);
        }
    }, [allNicheSubContractorState]);

    // Function to handle get all company map
    useEffect(() => {
        if (allCompanyMapState?.status === 200) {
            const group: any = [];

            allCompanyMapState?.response.forEach((item: any) => {
                const index = group.findIndex(
                    (x: any) =>
                        x.latitude === item.latitude && x.longitude === item.longitude,
                );

                if (index === -1) {
                    group.push({
                        latitude: item.latitude,
                        longitude: item.longitude,
                        data: [item],
                    });
                } else {
                    group[index].data.push(item);
                }
            });

            setGroupMarkers(group);
        }
    }, [allCompanyMapState]);

    useEffect(() => {
        if (allCompanyMapState?.status === 200 && searchParams.get('id') != null) {
            const { id, latitude, longitude } = allCompanyMapState?.response[0];

            setTimeout(() => {
                setIsShowCompany(id);

                setInitialMap({
                    latitude,
                    longitude,
                    status: true,
                    zoom: 11,
                });

                setViewCoordinates({
                    latitude,
                    longitude,
                    zoom: 20,
                });

                mapRef.current?.flyTo({
                    center: [longitude, latitude],
                    duration: 2000,
                });
            }, 2000);
        }
    }, [searchParams, allCompanyMapState]);

    const searchBoxCore = useSearchBoxCore({
        accessToken: key,
        types: 'country, place, region, postcode, district, locality, street, address',
    });

    const handleFindSuggestion = async (value: string): Promise<any> => {
        const response = await searchBoxCore.suggest(value, {
            sessionToken: v4(),
        });

        return response;
    };

    const handleRetrieveSuggestion = async (value: any): Promise<void> => {
        const response = await searchBoxCore.retrieve(value, {
            sessionToken: v4(),
        });

        const results = response?.features[0]?.properties;

        const payload = {
            ...params,
            page: 1,
            longitude: results?.coordinates?.longitude,
            latitude: results?.coordinates?.latitude,
        };

        setParams(payload);

        dispatch(getAllCompany(payload));
        dispatch(getAllCompanyMap(payload));

        isViewCoordinates
            ? setViewCoordinates({
                  latitude: results?.coordinates?.latitude,
                  longitude: results?.coordinates?.longitude,
                  zoom: 11,
              })
            : setInitialMap({
                  latitude: results?.coordinates?.latitude,
                  longitude: results?.coordinates?.longitude,
                  status: true,
                  zoom: 11,
              });

        onSelectCity({
            latitude: results?.coordinates?.latitude,
            longitude: results?.coordinates?.longitude,
        });
    };

    const onSelectCity = useCallback(
        ({ longitude, latitude }: { longitude: number; latitude: number }) => {
            mapRef.current?.flyTo({ center: [longitude, latitude], duration: 2000 });
        },
        [],
    );

    const handlePromiseFind = (
        inputValue: string,
        callback: (
            options: Array<{
                value: string;
                label: string;
            }>,
        ) => void,
    ): void => {
        setTimeout(async () => {
            const results = await handleFindSuggestion(
                inputValue !== '' ? inputValue : 'Sydney',
            );

            const options = results?.suggestions.map(
                (item: { name: string; place_formatted: string; mapbox_id: string }) => ({
                    ...item,
                    label: `${item?.name} - ${item?.place_formatted}`,
                    value: item?.mapbox_id,
                }),
            );

            callback(options);
        }, 1000);
    };

    /**
     * Function to handle pagination
     *
     * @param number page
     * @param string type
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handlePagination = (page: number, type: string): void => {
        if (type === 'page') {
            setParams({
                ...params,
                page,
            });

            const payload = {
                ...params,
                page,
            };

            dispatch(getAllCompany(payload));
        }

        if (type === 'next') {
            setParams({
                ...params,
                page: params.page + 1,
            });

            const payload = {
                ...params,
                page: params.page + 1,
            };

            dispatch(getAllCompany(payload));
        }

        if (type === 'prev') {
            setParams({
                ...params,
                page: params.page - 1,
            });

            const payload = {
                ...params,
                page: params.page - 1,
            };

            dispatch(getAllCompany(payload));
        }
    };

    const handleManyMarker = (evt: any, data: any): void => {
        evt.originalEvent.stopPropagation();

        setPopupManyInfo(data);
    };

    // const [popupCompanyInfo, setPopupCompanyInfo] = useState<any>(null);
    // const handleCompanyMarker = (evt: any, data: any): void => {
    //     evt.originalEvent.stopPropagation();
    //     setPopupCompanyInfo(data);
    // };

    const handleSetCoordinate = useCallback(
        (evt: { viewState: any }, isView: boolean, params: any) => {
            if (isView) {
                setViewCoordinates({
                    latitude: evt.viewState.latitude,
                    longitude: evt.viewState.longitude,
                    zoom: evt.viewState.zoom,
                });
            }
        },
        [],
    );

    const handleDispatchCoordinate = useCallback(
        (evt: { viewState: any }, isView: boolean, params: any) => {
            const payload = {
                ...params,
                page: 1,
                longitude: evt.viewState.longitude,
                latitude: evt.viewState.latitude,
            };

            setParams(payload);

            if (isView) {
                dispatch(getAllCompany(payload));
                dispatch(getAllCompanyMap(payload));
            }
        },
        [],
    );

    const handleViewCoordinates = (value: boolean): void => {
        setIsViewCoordinates(value);
        const { latitude, longitude } = currentProfileState?.response?.company;

        if (!value) {
            setInitialMap({
                latitude,
                longitude,
                status: true,
                zoom: 11,
            });

            mapRef.current?.flyTo({
                center: [longitude ?? 0, latitude ?? 0],
                duration: 2000,
            });

            const payload = {
                ...params,
                page: 1,
                longitude: longitude ?? 0,
                latitude: latitude ?? 0,
            };

            setParams(payload);

            dispatch(getAllCompany(payload));
            dispatch(getAllCompanyMap(payload));

            return;
        }

        setViewCoordinates({
            latitude: initialMap?.latitude ?? 0,
            longitude: initialMap?.longitude ?? 0,
            zoom: 11,
        });
    };

    const handleCollapseCompany = (id: number): void => {
        isShowCompany === id ? setIsShowCompany(0) : setIsShowCompany(id);
    };

    /**
     * Function to handle redirect
     *
     * @param id string
     * @returns void
     */
    const handleRedirectChat = (email: string): any => {
        // if not email
        if (!email.includes('@')) {
            return toast.error('Please enter valid email');
        }

        const payload = new FormData();
        payload.append('interlocutor_email', email);

        dispatch(createDirectMessage(payload));

        dispatch({
            type: 'NEW_DIRECT_REDIRECTED',
            payload: true,
        });
    };

    /**
     * Function to handle custom toggle
     *
     * @param children
     * @param onClick
     * @returns JSX.Element
     */
    const CustomToggle = forwardRef(
        (
            { children, onClick }: any,
            ref: LegacyRef<HTMLAnchorElement> | undefined,
        ): JSX.Element => (
            <a
                href=""
                className="text-dark text-decoration-none"
                ref={ref}
                onClick={e => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                &#x25bc;
            </a>
        ),
    );

    CustomToggle.displayName = 'RadiusToggle';

    /**
     * Function to handle filter niche
     *
     * @param value
     * @returns void
     */
    const handleFilterNiche = (value: any): void => {
        const payload = {
            ...params,
            page: 1,
            niches: value.map((item: any) => item.value).join(','),
        };

        setParams(payload);

        dispatch(getAllCompany(payload));
        dispatch(getAllCompanyMap(payload));
    };

    const markers = useMemo(
        () =>
            groupMarkers.map(
                (
                    item: {
                        latitude: number;
                        longitude: number;
                        data: any;
                    },
                    index: number,
                ) => (
                    <Marker
                        key={index}
                        longitude={item?.longitude}
                        latitude={item?.latitude}
                        onClick={e => handleManyMarker(e, item)}
                        anchor="bottom"
                    >
                        <PinMark role="button">{item.data.length}</PinMark>
                    </Marker>
                ),
            ),
        [groupMarkers],
    );

    const handleCompanyNiche = (
        companyNiche: {
            id: number;
            name: string;
        } | null,
        companyNicheOther: string | null,
        companyNicheTrade: {
            id: number;
            name: string;
        } | null,
        companySubContractTrade: {
            id: number;
            name: string;
        } | null,
        companySubContractTradeOther: string | null,
    ): string => {
        if (companyNiche !== null) {
            return companyNiche.name;
        }

        if (companyNicheTrade !== null) {
            return companyNicheTrade.name;
        }

        if (companySubContractTrade !== null) {
            return companySubContractTrade.name;
        }

        if (companySubContractTradeOther !== null) {
            return 'Other';
        }

        if (companyNicheOther !== null) {
            return 'Other';
        }
        return '-';
    };

    const handleResetParams = (): void => {
        const { latitude, longitude } = currentProfileState?.response?.company;

        const payload = {
            ...params,
            page: 1,
            per_page: 30,
            search: '',
            vic_distance: '10',
            longitude: 0,
            latitude: 0,
            company_id: '',
        };

        setSearchParams({
            id: '',
        });
        navigate('/directory');
        setIsShowCompany(0);

        setParams(payload);
        dispatch(getAllCompany(payload));
        dispatch(getAllCompanyMap(payload));

        setInitialMap({
            latitude,
            longitude,
            status: true,
            zoom: 11,
        });

        setViewCoordinates({
            latitude,
            longitude,
            zoom: 11,
        });

        mapRef.current?.flyTo({
            center: [longitude, latitude],
            duration: 2000,
        });
    };

    return (
        <PageWrapper className="row">
            <Title text="Directory" />

            <div className="col-md-12 mt-4">
                <div className="row mb-0">
                    <div className="col-md-4">
                        <InputSearchSelect
                            options={nicheLists}
                            isMulti={true}
                            placeholder="Filter by trade"
                            onChange={handleFilterNiche}
                        />
                    </div>
                    <div className="col-md-4">
                        <InputAsyncSelect
                            cacheOptions
                            defaultOptions
                            loadOptions={handlePromiseFind}
                            onChange={async (value: any) => {
                                await handleRetrieveSuggestion(value);
                            }}
                            placeholder="Location"
                        />
                    </div>

                    <div className="col-md-4">
                        <InputTextWrapper
                            className=""
                            style={{
                                padding: '13px 0px',
                            }}
                        >
                            <label
                                style={{
                                    fontSize: '1rem',
                                    color: 'hsl(0, 0%, 50%)',
                                }}
                            >
                                Proximity {range}km
                            </label>
                            <FormRange
                                min={0}
                                max={500}
                                step={10}
                                value={range}
                                onChange={e => setRange(e.target.value)}
                                style={{
                                    padding: '12px 0px',
                                }}
                            />
                        </InputTextWrapper>
                    </div>
                </div>
            </div>

            {searchParams.get('id') != null && (
                <div className="col-md-12 text-end">
                    <span
                        className="link-primary"
                        role="button"
                        style={{
                            fontSize: '14px',
                        }}
                        onClick={handleResetParams}
                    >
                        Reset to default
                    </span>
                </div>
            )}

            <div className="col-md-12 col-lg-5 col-xxl-4 pt-md-0">
                {allCompanyState?.loading === true ? (
                    <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{
                            height: '80vh',
                        }}
                    >
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#5648FB"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            visible={true}
                        />
                    </div>
                ) : tableData.length > 0 ? (
                    <WrapperScroll>
                        <InfiniteScroll
                            next={() => handlePagination(params.page + 1, 'next')}
                            hasMore={
                                allCompanyState?.response?.meta?.last_page !== params.page
                            }
                            loader={<span></span>}
                            dataLength={tableData.length}
                            scrollableTarget="scrollableDiv"
                            // height={500}
                        >
                            <div id="scrollableDiv" className="table-responsive">
                                <table className="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="fw-semibold">
                                                Company Name
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((item: any, index: number) => (
                                            <tr
                                                key={index}
                                                onClick={() =>
                                                    handleCollapseCompany(item.id)
                                                }
                                            >
                                                <td>
                                                    <ListWrapper
                                                        className="d-flex"
                                                        isShow={isShowCompany === item.id}
                                                    >
                                                        <div className="d-flex justify-content-between w-100">
                                                            <CompanyImage
                                                                src={item.company_picture}
                                                                alt="company"
                                                                className="rounded-circle"
                                                            />
                                                            <div
                                                                className="d-flex flex-column"
                                                                style={{ width: '85%' }}
                                                            >
                                                                <div className="d-flex justify-content-between">
                                                                    <span
                                                                        className="fw-semibold"
                                                                        style={{
                                                                            width: '60%',
                                                                        }}
                                                                    >
                                                                        {
                                                                            item.company_name
                                                                        }
                                                                    </span>
                                                                    <span className="w-100 text-end">
                                                                        {handleCompanyNiche(
                                                                            item?.company_niche,
                                                                            item?.company_niche_other,
                                                                            item?.company_niche_trade,
                                                                            item?.company_sub_contract_trade,
                                                                            item?.company_sub_contract_trade_other,
                                                                        )}
                                                                    </span>
                                                                </div>
                                                                <CollapseItem
                                                                    isShow={
                                                                        isShowCompany ===
                                                                        item.id
                                                                    }
                                                                >
                                                                    <div className="mt-3">
                                                                        <SubTitle>
                                                                            Website
                                                                        </SubTitle>
                                                                        <p
                                                                            className="link-primary"
                                                                            onClick={() =>
                                                                                window.open(
                                                                                    item?.company_website_url,
                                                                                    '_blank',
                                                                                )
                                                                            }
                                                                        >
                                                                            {/* {item?.company_website_url ??
                                                                                '-'} */}
                                                                            Open Website
                                                                        </p>
                                                                    </div>
                                                                    <div>
                                                                        <SubTitle>
                                                                            Email
                                                                        </SubTitle>
                                                                        <p>
                                                                            {item
                                                                                ?.company_user
                                                                                ?.email ??
                                                                                '-'}
                                                                        </p>
                                                                    </div>
                                                                    <div>
                                                                        <SubTitle>
                                                                            Contact Number
                                                                        </SubTitle>
                                                                        <p>
                                                                            {item?.company_phone ??
                                                                                '-'}
                                                                        </p>
                                                                    </div>
                                                                    <div>
                                                                        <SubTitle>
                                                                            Address
                                                                        </SubTitle>
                                                                        <p>
                                                                            {item?.company_address ??
                                                                                '-'}
                                                                        </p>
                                                                    </div>
                                                                    <div>
                                                                        <SubTitle>
                                                                            Company
                                                                            Description
                                                                        </SubTitle>
                                                                        <p>
                                                                            {item?.company_description ===
                                                                            'null'
                                                                                ? '-'
                                                                                : item?.company_description ??
                                                                                  '-'}
                                                                        </p>
                                                                    </div>
                                                                    <div className="text-end">
                                                                        <ButtonBasic
                                                                            text="Start Chat"
                                                                            onClick={() =>
                                                                                handleRedirectChat(
                                                                                    item?.company_email ??
                                                                                        item
                                                                                            ?.company_user
                                                                                            ?.email,
                                                                                )
                                                                            }
                                                                            loading={
                                                                                newDirectMessageState.loading
                                                                            }
                                                                        />
                                                                    </div>
                                                                </CollapseItem>
                                                            </div>
                                                        </div>
                                                    </ListWrapper>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </InfiniteScroll>
                    </WrapperScroll>
                ) : (
                    <div className="text-center">
                        <p>No data found</p>
                    </div>
                )}
            </div>
            <div className="col-md-12 col-lg-7 col-xxl-8 pt-md-0">
                {initialMap?.status && (
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                        }}
                    >
                        <Map
                            mapboxAccessToken={key}
                            ref={mapRef as any}
                            initialViewState={initialMap}
                            onDrag={e =>
                                handleSetCoordinate(e, isViewCoordinates, params)
                            }
                            onDragEnd={e =>
                                handleDispatchCoordinate(e, isViewCoordinates, params)
                            }
                            onZoom={e =>
                                handleSetCoordinate(e, isViewCoordinates, params)
                            }
                            onZoomEnd={e =>
                                handleDispatchCoordinate(e, isViewCoordinates, params)
                            }
                            style={{
                                width: '100%',
                                height: '75vh',
                                display: 'inline-list-item',
                                borderRadius: 20,
                            }}
                            mapStyle={'mapbox://styles/mapbox/light-v11'}
                            attributionControl={false}
                        >
                            <Source id="dataGeo" type="geojson" data={geojson}>
                                <Layer {...layerStyle} />
                            </Source>
                            {markers}
                            {popupManyInfo !== null && (
                                <Popup
                                    anchor="top"
                                    longitude={Number(popupManyInfo?.longitude)}
                                    latitude={Number(popupManyInfo?.latitude)}
                                    onClose={() => setPopupManyInfo(null)}
                                >
                                    <ul className="p-2 pb-0 mb-0">
                                        {popupManyInfo?.data.map((item: any) => (
                                            <li key={item.id} className="mb-2">
                                                {item.company_name} |{' '}
                                                {item?.company_address}
                                            </li>
                                        ))}
                                    </ul>
                                </Popup>
                            )}
                            <ScaleControl />
                            <FullscreenControl position="top-left" />
                            <NavigationControl position="top-left" />
                            <GeolocateControl position="top-left" />
                            <AttributionControl
                                customAttribution=" © Constructapp"
                                position="bottom-right"
                            />
                            <CustomOverlay>
                                <ControlPanel
                                    value={isViewCoordinates}
                                    setValue={handleViewCoordinates}
                                />
                            </CustomOverlay>
                        </Map>
                    </div>
                )}
            </div>
        </PageWrapper>
    );
}

const WrapperScroll = styled.div`
    .table-responsive {
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;

const InputTextWrapper = styled.div`
    input {
        border: none;
        border-bottom: 1px solid #cfcfcf;
        background-color: transparent;
        border-radius: 0;
        padding: 24px 0px 14px 0px;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
`;

const PageWrapper = styled.div`
    td {
        padding: 0;
    }

    .table-responsive {
        max-height: 75vh;
    }
`;

const ListWrapper = styled.div<{ isShow: boolean }>`
    padding: 1rem;
    margin-bottom: 1rem;
    &:hover {
        border-radius: 20px;
        background-color: #edecfe;
        cursor: pointer;
    }

    ${props =>
        props.isShow &&
        `
        background-color: #edecfe;
        border-radius: 20px;
    `}
`;

const PinMark = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #000;
    border: 2px solid #fff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SubTitle = styled.p`
    margin-bottom: 0;
    font-weight: 600;
`;

const CompanyImage = styled.img`
    width: 40px;
    height: 40px;
    object-fit: cover;
`;

const CollapseItem = styled.div<{ isShow: boolean }>`
    max-height: ${props => (props.isShow ? '1000px' : '0')};
    transition: max-height 1s cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
`;
