import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';

import styled from 'styled-components';
import { RootState } from '@/redux/store';
import { Accordion } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import InputArea from '@/components/atoms/Inputs/InputArea';
import { editProjectTender } from '../../TenderBox/redux/actions';
import moment from 'moment';
import ModalAction from '@/components/atoms/Modals/ModalAction';

interface ParamsProps {
    modal: boolean;
    setModal: (modal: any) => void;
    selectedProject: {
        id: number;
        name: string;
        type: string;
        tender: {
            intention: string;
            project_detail: string;
            due_date: string;
        };
    };
}

export default function EditTender(props: ParamsProps): JSX.Element {
    const { modal, setModal, selectedProject } = props;

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
        setValue,
    } = useForm<any>({
        mode: 'onChange',
    });

    const dispatch = useDispatch();

    const allTenderIntentionState = useSelector(
        (state: RootState) => state.allTenderIntention,
    );

    const editProjectTenderState = useSelector(
        (state: RootState) => state.editProjectTender,
    );

    useEffect(() => {
        if (modal) {
            setValue('project_detail', selectedProject.tender.project_detail ?? '');
            setValue('intention', selectedProject.tender.intention);

            setValue(
                'due_date',
                selectedProject.tender.due_date !== null
                    ? moment(selectedProject.tender.due_date).toDate()
                    : null,
            );
        }
    }, [modal]);

    // Reset if success
    useEffect(() => {
        if (editProjectTenderState?.status === 200) {
            reset();
        }
    }, [editProjectTenderState]);

    /**
     * Function on submit
     *
     * @param any data
     * @return void
     * @see cypress/e2e/dashboard/Project/project.cy.ts
     *      To cypress unit tester
     */
    const onSubmit = (data: any): void => {
        // initialize form data
        const formData: any = new FormData();

        // append data to form data
        for (const key in data) {
            formData.append(key, data[key as keyof typeof data]);
        }

        formData.append('project_id', selectedProject.id.toString());

        // Check if due date is not today date
        if (
            moment(data.due_date).format('YYYY-MM-DD') !==
            moment(new Date()).format('YYYY-MM-DD')
        ) {
            formData.set('due_date', moment(data.due_date).format('YYYY-MM-DD'));
        } else {
            formData.delete('due_date');
        }

        // If due date is invalid date
        if (formData.get('due_date') === 'Invalid date') {
            formData.delete('due_date');
        }

        // Dispatch login action with form data as formData
        dispatch(editProjectTender(formData));
    };

    return (
        <>
            <ModalAction
                modal={modal}
                setModal={setModal}
                title={`Tender ${selectedProject.name}`}
                size="lg"
            >
                <form action="#" onSubmit={handleSubmit(onSubmit)} method="post">
                    <div
                        className="pt-4 px-4 text-start d-flex flex-column"
                        style={{
                            fontSize: '0.875rem',
                            textAlign: 'justify',
                        }}
                    >
                        <div className="mb-3">
                            <label
                                htmlFor="project_detail"
                                className="fw-semibold"
                                style={{
                                    fontSize: 18,
                                }}
                            >
                                Project Details
                            </label>
                            <InputArea
                                id="project_detail"
                                innerClassName="my-0"
                                outerClassName="my-0"
                                rules={{
                                    function: register,
                                    name: 'project_detail',
                                    rules: {
                                        maxLength: {
                                            value: 500,
                                            message: 'Maximum length of 500 characters',
                                        },
                                    },
                                    errors,
                                }}
                            />
                        </div>
                        <div className="mb-5">
                            <h6 className="fw-semibold mb-2">Itention of tender</h6>

                            <div className="row align-items-center">
                                <div className="col-md-8">
                                    <InputSelectRounded
                                        className="form-select"
                                        {...register('intention')}
                                    >
                                        {allTenderIntentionState?.response?.map(
                                            (item: string[], index: number) => (
                                                <option key={index} value={item}>
                                                    {item}
                                                </option>
                                            ),
                                        )}
                                    </InputSelectRounded>
                                </div>
                                <div className="col-md-4">
                                    <InputDateWrapper>
                                        <Controller
                                            control={control}
                                            name="due_date"
                                            render={({ field }) => (
                                                <DatePicker
                                                    className="border-0 py-2"
                                                    placeholderText="Due Date"
                                                    onChange={date => {
                                                        field.onChange(date);
                                                    }}
                                                    isClearable
                                                    selected={field.value}
                                                    minDate={
                                                        new Date(
                                                            new Date().setDate(
                                                                new Date().getDate() + 1,
                                                            ),
                                                        )
                                                    }
                                                />
                                            )}
                                        />

                                        <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="icon-date"
                                        >
                                            <path
                                                d="M2.25 6.75H15.75M5.25 2.25V3.75M12.75 2.25V3.75M4.5 9.75H6M4.5 12.75H6M8.25 9.75H9.75M8.25 12.75H9.75M12 9.75H13.5M12 12.75H13.5M4.65 15.75H13.35C14.1901 15.75 14.6101 15.75 14.931 15.5865C15.2132 15.4427 15.4427 15.2132 15.5865 14.931C15.75 14.6101 15.75 14.1901 15.75 13.35V6.15C15.75 5.30992 15.75 4.88988 15.5865 4.56901C15.4427 4.28677 15.2132 4.0573 14.931 3.91349C14.6101 3.75 14.1901 3.75 13.35 3.75H4.65C3.80992 3.75 3.38988 3.75 3.06901 3.91349C2.78677 4.0573 2.5573 4.28677 2.41349 4.56901C2.25 4.88988 2.25 5.30992 2.25 6.15V13.35C2.25 14.1901 2.25 14.6101 2.41349 14.931C2.5573 15.2132 2.78677 15.4427 3.06901 15.5865C3.38988 15.75 3.80992 15.75 4.65 15.75Z"
                                                stroke="#5648FB"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </InputDateWrapper>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end">
                            <ButtonBasic
                                text="Cancel"
                                type="button"
                                className="btn btn-outline-primary me-2"
                                onClick={() => setModal(false)}
                            />
                            <ButtonBasic
                                text="Post Tender"
                                type="submit"
                                className="btn btn-primary btn-sm"
                                loading={editProjectTenderState.loading}
                            />
                        </div>
                    </div>
                </form>
            </ModalAction>
        </>
    );
}

const InputSelectRounded = styled.select`
    border-radius: 20px;
    border: 1px solid #cfcfcf;
    padding: 8px 20px;
    background-color: #ffffff;
    font-size: 0.875rem;
    font-weight: 400;
    color: #000000;
    margin-right: 20px;

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &::placeholder {
        color: #000000;
    }

    &:-ms-input-placeholder {
        color: #000000;
    }
`;

const InputDateWrapper = styled.div`
    border-bottom: 1px solid #cfcfcf;
    position: relative;

    input:focus-visible {
        outline: none;
    }

    .react-datepicker__input-container input {
        padding: 0px 0 10px 2px !important;
    }

    .icon-date {
        position: absolute;
        right: 10px;
        top: 10px;
        transform: translateY(-50%);
    }
`;

const CustomAccordion = styled(Accordion)`
    .accordion-item {
        border: none;
    }

    .accordion-button {
        font-size: 14px;
        padding: 5px 10px;

        &:focus {
            box-shadow: none;
        }
    }

    .accordion-button:not(.collapsed) {
        background-color: transparent;
        border: none;
        box-shadow: none;
    }

    .accordion-body {
        padding-top: 0;
        padding-bottom: 0;
    }
`;
