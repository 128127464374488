export const ALL_DRAWING_PUBLIC_SPACE_REQUEST = 'ALL_DRAWING_PUBLIC_SPACE_REQUEST';
export const ALL_DRAWING_PUBLIC_SPACE_SUCCESS = 'ALL_DRAWING_PUBLIC_SPACE_SUCCESS';
export const ALL_DRAWING_PUBLIC_SPACE_FAIL = 'ALL_DRAWING_PUBLIC_SPACE_FAIL';

export const ALL_DRAWING_PRIVATE_SPACE_REQUEST = 'ALL_DRAWING_PRIVATE_SPACE_REQUEST';
export const ALL_DRAWING_PRIVATE_SPACE_SUCCESS = 'ALL_DRAWING_PRIVATE_SPACE_SUCCESS';
export const ALL_DRAWING_PRIVATE_SPACE_FAIL = 'ALL_DRAWING_PRIVATE_SPACE_FAIL';

export const ALL_DRAWING_RESTORE_SPACE_REQUEST = 'ALL_DRAWING_RESTORE_SPACE_REQUEST';
export const ALL_DRAWING_RESTORE_SPACE_SUCCESS = 'ALL_DRAWING_RESTORE_SPACE_SUCCESS';
export const ALL_DRAWING_RESTORE_SPACE_FAIL = 'ALL_DRAWING_RESTORE_SPACE_FAIL';

// File
export const ALL_DRAWING_PUBLIC_FOLDER_REQUEST = ' ALL_DRAWING_PUBLIC_FOLDER_REQUEST';
export const ALL_DRAWING_PUBLIC_FOLDER_SUCCESS = 'ALL_DRAWING_PUBLIC_FOLDER_SUCCESS';
export const ALL_DRAWING_PUBLIC_FOLDER_FAIL = 'ALL_DRAWING_PUBLIC_FOLDER_FAIL';
export const ALL_DRAWING_PUBLIC_FOLDER_RESET = 'ALL_DRAWING_PUBLIC_FOLDER_RESET';

export const ALL_DRAWING_PRIVATE_FOLDER_REQUEST = 'ALL_DRAWING_PRIVATE_FOLDER_REQUEST';
export const ALL_DRAWING_PRIVATE_FOLDER_SUCCESS = 'ALL_DRAWING_PRIVATE_FOLDER_SUCCESS';
export const ALL_DRAWING_PRIVATE_FOLDER_FAIL = 'ALL_DRAWING_PRIVATE_FOLDER_FAIL';
export const ALL_DRAWING_PRIVATE_FOLDER_RESET = 'ALL_DRAWING_PRIVATE_FOLDER_RESET';

export const DETAIL_DRAWING_REQUEST = 'DETAIL_DRAWING_REQUEST';
export const DETAIL_DRAWING_SUCCESS = 'DETAIL_DRAWING_SUCCESS';
export const DETAIL_DRAWING_FAIL = 'DETAIL_DRAWING_FAIL';
export const DETAIL_DRAWING_RESET = 'DETAIL_DRAWING_RESET';

export const HISTORY_DRAWING_REQUEST = 'HISTORY_DRAWING_REQUEST';
export const HISTORY_DRAWING_SUCCESS = 'HISTORY_DRAWING_SUCCESS';
export const HISTORY_DRAWING_FAIL = 'HISTORY_DRAWING_FAIL';
export const HISTORY_DRAWING_RESET = 'HISTORY_DRAWING_RESET';

export const CREATE_DRAWING_FOLDER_REQUEST = ' CREATE_DRAWING_FOLDER_REQUEST';
export const CREATE_DRAWING_FOLDER_SUCCESS = 'CREATE_DRAWING_FOLDER_SUCCESS';
export const CREATE_DRAWING_FOLDER_FAIL = 'CREATE_DRAWING_FOLDER_FAIL';
export const CREATE_DRAWING_FOLDER_RESET = 'CREATE_DRAWING_FOLDER_RESET';

export const DELETE_DRAWING_REQUEST = 'DELETE_DRAWING_REQUEST';
export const DELETE_DRAWING_SUCCESS = 'DELETE_DRAWING_SUCCESS';
export const DELETE_DRAWING_FAIL = 'DELETE_DRAWING_FAIL';
export const DELETE_DRAWING_RESET = 'DELETE_DRAWING_RESET';

// restore
export const RESTORE_DRAWING_REQUEST = 'RESTORE_DRAWING_REQUEST';
export const RESTORE_DRAWING_SUCCESS = 'RESTORE_DRAWING_SUCCESS';
export const RESTORE_DRAWING_FAIL = 'RESTORE_DRAWING_FAIL';
export const RESTORE_DRAWING_RESET = 'RESTORE_DRAWING_RESET';

export const PIN_UNPIN_DRAWING_SPACE_REQUEST = 'PIN_UNPIN_DRAWING_SPACE_REQUEST';
export const PIN_UNPIN_DRAWING_SPACE_SUCCESS = 'PIN_UNPIN_DRAWING_SPACE_SUCCESS';
export const PIN_UNPIN_DRAWING_SPACE_FAIL = 'PIN_UNPIN_DRAWING_SPACE_FAIL';
export const PIN_UNPIN_DRAWING_SPACE_RESET = 'PIN_UNPIN_DRAWING_SPACE_RESET';

export const UPLOAD_DRAWING_FILE_REQUEST = 'UPLOAD_DRAWING_FILE_REQUEST';
export const UPLOAD_DRAWING_FILE_SUCCESS = 'UPLOAD_DRAWING_FILE_SUCCESS';
export const UPLOAD_DRAWING_FILE_FAIL = 'UPLOAD_DRAWING_FILE_FAIL';
export const UPLOAD_DRAWING_FILE_RESET = 'UPLOAD_DRAWING_FILE_RESET';

export const SHARED_USER_PRIVATE_DRAWING_REQUEST = 'SHARED_USER_PRIVATE_DRAWING_REQUEST';
export const SHARED_USER_PRIVATE_DRAWING_SUCCESS = 'SHARED_USER_PRIVATE_DRAWING_SUCCESS';
export const SHARED_USER_PRIVATE_DRAWING_FAIL = 'SHARED_USER_PRIVATE_DRAWING_FAIL';
export const SHARED_USER_PRIVATE_DRAWING_RESET = 'SHARED_USER_PRIVATE_DRAWING_RESET';

export const UPDATE_USER_PRIVATE_DRAWING_REQUEST = 'UPDATE_USER_PRIVATE_DRAWING_REQUEST';
export const UPDATE_USER_PRIVATE_DRAWING_SUCCESS = 'UPDATE_USER_PRIVATE_DRAWING_SUCCESS';
export const UPDATE_USER_PRIVATE_DRAWING_FAIL = 'UPDATE_USER_PRIVATE_DRAWING_FAIL';
export const UPDATE_USER_PRIVATE_DRAWING_RESET = 'UPDATE_USER_PRIVATE_DRAWING_RESET';

export const DELETE_USER_PRIVATE_DRAWING_REQUEST = 'DELETE_USER_PRIVATE_DRAWING_REQUEST';
export const DELETE_USER_PRIVATE_DRAWING_SUCCESS = 'DELETE_USER_PRIVATE_DRAWING_SUCCESS';
export const DELETE_USER_PRIVATE_DRAWING_FAIL = 'DELETE_USER_PRIVATE_DRAWING_FAIL';
export const DELETE_USER_PRIVATE_DRAWING_RESET = 'DELETE_USER_PRIVATE_DRAWING_RESET';

export const UPDATE_DRAWING_REQUEST = 'UPDATE_DRAWING_REQUEST';
export const UPDATE_DRAWING_SUCCESS = 'UPDATE_DRAWING_SUCCESS';
export const UPDATE_DRAWING_FAIL = 'UPDATE_DRAWING_FAIL';
export const UPDATE_DRAWING_RESET = 'UPDATE_DRAWING_RESET';

export const INVITE_USER_DRAWING_REQUEST = 'INVITE_USER_DRAWING_REQUEST';
export const INVITE_USER_DRAWING_SUCCESS = 'INVITE_USER_DRAWING_SUCCESS';
export const INVITE_USER_DRAWING_FAIL = 'INVITE_USER_DRAWING_FAIL';
export const INVITE_USER_DRAWING_RESET = 'INVITE_USER_DRAWING_RESET';

export const ALL_DRAWING_COMPARE_FILE_REQUEST = 'ALL_DRAWING_COMPARE_FILE_REQUEST';
export const ALL_DRAWING_COMPARE_FILE_SUCCESS = 'ALL_DRAWING_COMPARE_FILE_SUCCESS';
export const ALL_DRAWING_COMPARE_FILE_FAIL = 'ALL_DRAWING_COMPARE_FILE_FAIL';
export const ALL_DRAWING_COMPARE_FILE_RESET = 'ALL_DRAWING_COMPARE_FILE_RESET';

export const SEND_DRAWING_REQUEST = 'SEND_DRAWING_REQUEST';
export const SEND_DRAWING_SUCCESS = 'SEND_DRAWING_SUCCESS';
export const SEND_DRAWING_FAIL = 'SEND_DRAWING_FAIL';
export const SEND_DRAWING_RESET = 'SEND_DRAWING_RESET';

export const SHARE_DRAWING_REQUEST = 'SHARE_DRAWING_REQUEST';
export const SHARE_DRAWING_SUCCESS = 'SHARE_DRAWING_SUCCESS';
export const SHARE_DRAWING_FAIL = 'SHARE_DRAWING_FAIL';
export const SHARE_DRAWING_RESET = 'SHARE_DRAWING_RESET';

export const PASTE_DRAWING_REQUEST = 'PASTE_DRAWING_REQUEST';
export const PASTE_DRAWING_SUCCESS = 'PASTE_DRAWING_SUCCESS';
export const PASTE_DRAWING_FAIL = 'PASTE_DRAWING_FAIL';
export const PASTE_DRAWING_RESET = 'PASTE_DRAWING_RESET';

export const CREATE_SHARE_LINK_DRAWING_REQUEST = 'CREATE_SHARE_LINK_DRAWING_REQUEST';
export const CREATE_SHARE_LINK_DRAWING_SUCCESS = 'CREATE_SHARE_LINK_DRAWING_SUCCESS';
export const CREATE_SHARE_LINK_DRAWING_FAIL = 'CREATE_SHARE_LINK_DRAWING_FAIL';
export const CREATE_SHARE_LINK_DRAWING_RESET = 'CREATE_SHARE_LINK_DRAWING_RESET';

export const GET_SHARE_LINK_DRAWING_REQUEST = 'GET_SHARE_LINK_DRAWING_REQUEST';
export const GET_SHARE_LINK_DRAWING_SUCCESS = 'GET_SHARE_LINK_DRAWING_SUCCESS';
export const GET_SHARE_LINK_DRAWING_FAIL = 'GET_SHARE_LINK_DRAWING_FAIL';
export const GET_SHARE_LINK_DRAWING_RESET = 'GET_SHARE_LINK_DRAWING_RESET';

const initialState = {
    loading: false,
    response: null,
    error: null,
    status: null,
};

export const allDrawingPublicSpaceReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_DRAWING_PUBLIC_SPACE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_DRAWING_PUBLIC_SPACE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_DRAWING_PUBLIC_SPACE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        default:
            return state;
    }
};

export const allDrawingPrivateSpaceReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_DRAWING_PRIVATE_SPACE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_DRAWING_PRIVATE_SPACE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_DRAWING_PRIVATE_SPACE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        default:
            return state;
    }
};

export const allDrawingRestoreSpaceReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_DRAWING_RESTORE_SPACE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_DRAWING_RESTORE_SPACE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_DRAWING_RESTORE_SPACE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        default:
            return state;
    }
};

export const allDrawingPublicFolderReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_DRAWING_PUBLIC_FOLDER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_DRAWING_PUBLIC_FOLDER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_DRAWING_PUBLIC_FOLDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ALL_DRAWING_PUBLIC_FOLDER_RESET:
            return initialState;

        default:
            return state;
    }
};

export const allDrawingPrivateFolderReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case ALL_DRAWING_PRIVATE_FOLDER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_DRAWING_PRIVATE_FOLDER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_DRAWING_PRIVATE_FOLDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ALL_DRAWING_PRIVATE_FOLDER_RESET:
            return initialState;

        default:
            return state;
    }
};

export const createDrawingFolderReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case CREATE_DRAWING_FOLDER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CREATE_DRAWING_FOLDER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case CREATE_DRAWING_FOLDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CREATE_DRAWING_FOLDER_RESET:
            return initialState;

        default:
            return state;
    }
};

export const detailDrawingReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DETAIL_DRAWING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_DRAWING_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DETAIL_DRAWING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case DETAIL_DRAWING_RESET:
            return initialState;

        default:
            return state;
    }
};

export const historyDrawingReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case HISTORY_DRAWING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case HISTORY_DRAWING_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case HISTORY_DRAWING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case HISTORY_DRAWING_RESET:
            return initialState;

        default:
            return state;
    }
};

export const updateDrawingReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case UPDATE_DRAWING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_DRAWING_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case UPDATE_DRAWING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case UPDATE_DRAWING_RESET:
            return initialState;

        default:
            return state;
    }
};

export const uploadDrawingFileReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case UPLOAD_DRAWING_FILE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPLOAD_DRAWING_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case UPLOAD_DRAWING_FILE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case UPLOAD_DRAWING_FILE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const sharedUserPrivateDrawingReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case SHARED_USER_PRIVATE_DRAWING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case SHARED_USER_PRIVATE_DRAWING_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case SHARED_USER_PRIVATE_DRAWING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case SHARED_USER_PRIVATE_DRAWING_RESET:
            return initialState;

        default:
            return state;
    }
};

export const updateUserPrivateDrawingReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case UPDATE_USER_PRIVATE_DRAWING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_USER_PRIVATE_DRAWING_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case UPDATE_USER_PRIVATE_DRAWING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case UPDATE_USER_PRIVATE_DRAWING_RESET:
            return initialState;

        default:
            return state;
    }
};

export const deleteUserPrivateDrawingReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case DELETE_USER_PRIVATE_DRAWING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_USER_PRIVATE_DRAWING_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DELETE_USER_PRIVATE_DRAWING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case DELETE_USER_PRIVATE_DRAWING_RESET:
            return initialState;

        default:
            return state;
    }
};

export const deleteDrawingReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DELETE_DRAWING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_DRAWING_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DELETE_DRAWING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case DELETE_DRAWING_RESET:
            return initialState;

        default:
            return state;
    }
};

export const restoreDrawingReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case RESTORE_DRAWING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case RESTORE_DRAWING_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case RESTORE_DRAWING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case RESTORE_DRAWING_RESET:
            return initialState;

        default:
            return state;
    }
};

export const pinUnpinDrawingSpaceReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case PIN_UNPIN_DRAWING_SPACE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case PIN_UNPIN_DRAWING_SPACE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case PIN_UNPIN_DRAWING_SPACE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case PIN_UNPIN_DRAWING_SPACE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const pasteDrawingReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case PASTE_DRAWING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case PASTE_DRAWING_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case PASTE_DRAWING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case PASTE_DRAWING_RESET:
            return initialState;

        default:
            return state;
    }
};

export const inviteUserDrawingReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case INVITE_USER_DRAWING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case INVITE_USER_DRAWING_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case INVITE_USER_DRAWING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case INVITE_USER_DRAWING_RESET:
            return initialState;

        default:
            return state;
    }
};

export const allDrawingCompareFileReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_DRAWING_COMPARE_FILE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_DRAWING_COMPARE_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_DRAWING_COMPARE_FILE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ALL_DRAWING_COMPARE_FILE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const sendDrawingReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case SEND_DRAWING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case SEND_DRAWING_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case SEND_DRAWING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case SEND_DRAWING_RESET:
            return initialState;

        default:
            return state;
    }
};

export const shareDrawingReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case SHARE_DRAWING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case SHARE_DRAWING_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case SHARE_DRAWING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case SHARE_DRAWING_RESET:
            return initialState;

        default:
            return state;
    }
};

export const createShareLinkDrawingReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case CREATE_SHARE_LINK_DRAWING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CREATE_SHARE_LINK_DRAWING_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case CREATE_SHARE_LINK_DRAWING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CREATE_SHARE_LINK_DRAWING_RESET:
            return initialState;

        default:
            return state;
    }
};

export const getShareLinkDrawingReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case GET_SHARE_LINK_DRAWING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case GET_SHARE_LINK_DRAWING_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case GET_SHARE_LINK_DRAWING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case GET_SHARE_LINK_DRAWING_RESET:
            return initialState;

        default:
            return state;
    }
};
