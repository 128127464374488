import { useEffect, useState } from 'react';

interface TimerCountdownProps {
    minute: number;
    second: number;
    textCount: string;
    textButton: string;
    textDesc?: string;
    onClick?: () => void;
}

export default function TimerCountdown(props: TimerCountdownProps): JSX.Element {
    const { minute, second, textCount, textButton, textDesc, onClick } = props;

    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    // Function for get the storage
    useEffect(() => {
        const storedMinutes = sessionStorage.getItem('minutes');
        const storedSeconds = sessionStorage.getItem('seconds');

        if (storedMinutes != null && storedSeconds != null) {
            setMinutes(parseInt(storedMinutes, 10));
            setSeconds(parseInt(storedSeconds, 10));
        } else {
            setMinutes(minute);
            setSeconds(second);
        }
    }, []);

    // Function for countdown
    useEffect(() => {
        /**
         * Function for Update the storage
         *
         * @returns {void}
         */
        const updateStorage = (): void => {
            sessionStorage.setItem('minutes', minutes.toString());
            sessionStorage.setItem('seconds', seconds.toString());
        };

        /**
         * Funtion for Interval for countdown
         *
         * @returns {void}
         */
        const interval = setInterval(() => {
            updateStorage();

            // TODO: FIX THIS MORE CLEAN
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds, minutes]);

    /**
     * Function for Handle on click
     *
     * @returns {void}
     */
    const handleOnClick = (): void => {
        onClick?.();
        setMinutes(minute);
        setSeconds(second);
    };

    return (
        <div className="countdown-text">
            {seconds > 0 || minutes > 0 ? (
                <span>
                    {textCount}&nbsp;
                    {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                </span>
            ) : (
                <>
                    <p className="text-muted mb-0">{textDesc}</p>
                    <span
                        style={{
                            cursor: 'pointer',
                            WebkitUserSelect: 'none',
                        }}
                        className="link-primary"
                        onClick={handleOnClick}
                    >
                        {textButton}
                    </span>
                </>
            )}
        </div>
    );
}
