import { Modal } from 'react-bootstrap';

import DropFile from '@/components/molecules/DropFile';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';

import styled from 'styled-components';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';

export default function UploadDocument({
    modal,
    setModal,
    files,
    setFiles,
    handleNext,
}: {
    modal: boolean;
    setModal: Dispatch<SetStateAction<boolean>>;
    files: Array<{
        names: string;
        descriptions: string;
        files: File;
        progress: number;
    }>;
    setFiles: Dispatch<
        SetStateAction<
            Array<{
                names: string;
                descriptions: string;
                files: File;
                progress: number;
            }>
        >
    >;
    handleNext: () => void;
}): JSX.Element {
    // max size per upload 4GB
    const maxUploadSize = 4300000000; // bytes
    const [isMaxUpload, setIsMaxUpload] = useState(false);

    useEffect(() => {
        if (files.length > 0) {
            const totalSize = files.reduce((acc, file) => acc + file.files.size, 0);

            if (totalSize > maxUploadSize) {
                setIsMaxUpload(true);
            } else {
                setIsMaxUpload(false);
            }
        }
    }, [files]);

    return (
        <ModalWrapper
            show={modal}
            onHide={() => setModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <h6 className="fw-semibold mb-0 px-2">Upload File</h6>
            </Modal.Header>
            <Modal.Body className="p-3">
                <DropFile
                    files={files}
                    setFiles={setFiles}
                    acceptFile={{
                        'image/png': ['.png'],
                        'image/jpeg': ['.jpg', '.jpeg'],
                        'image/gif': ['.gif'],
                        'image/bmp': ['.bmp'],
                        'image/webp': ['.webp'],
                        'application/pdf': ['.pdf'],
                        'application/msexcel': ['.xls', '.xlsx'],
                        'application/msword': ['.doc', '.docx'],
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                            ['.docx'],
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                            ['.xlsx'],
                        'application/vnd.ms-excel': ['.xls'],
                        'application/vnd.ms-word': ['.doc'],
                        'application/vnd.ms-powerpoint': ['.ppt'],
                        'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                            ['.pptx'],
                        'text/plain': ['.txt'],
                        'application/zip': ['.zip'],
                    }}
                    multiple={true}
                    maxFiles={20}
                    isMaxUpload={isMaxUpload}
                    maxUploadSize={maxUploadSize}
                />
            </Modal.Body>
            <Modal.Footer className="p-3 d-flex flex-column">
                <ButtonBasic
                    text="Upload File"
                    type="button"
                    className="btn btn-primary btn-sm mb-3 w-25"
                    onClick={() => handleNext()}
                    disabled={files.length === 0 || isMaxUpload}
                />
            </Modal.Footer>
        </ModalWrapper>
    );
}

const ModalWrapper = styled(Modal)`
    hr {
        color: #bdbdbd;
    }
    .modal-content {
        font-family: 'Montserrat', sans-serif;
        border-radius: 20px;
    }

    .modal-header {
        border: none;
        border-bottom: 1px solid #e9ecef;
        padding: 1rem;

        .btn-close {
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 10px;
        }
    }

    .modal-body {
        text-align: center;
        padding: 0;

        .form-floating {
            font-size: 14px;
        }

        .label-active {
            padding: 1.5rem 0.5rem;
        }

        input {
            padding: 0 0 0px 4px !important;
        }
    }
    .modal-footer {
        justify-content: center;
        border: none;
        flex-wrap: initial;
    }
`;
