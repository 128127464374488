import { useMemo, ReactNode, useEffect, SetStateAction, Dispatch } from 'react';
import { useDropzone } from 'react-dropzone';

import FileIcon from '@/assets/svg/icons/file-arrow-up-alt.svg';

import styled from 'styled-components';

import {
    AiFillFilePdf,
    AiFillFileZip,
    AiFillFileWord,
    AiFillFile,
    AiFillFileImage,
    AiFillFileText,
    AiFillFileExcel,
} from 'react-icons/ai';

import { readableBytes } from '@/utility/Utils';
import InputBasic from '@/components/atoms/Inputs/InputBasic';
import { ProgressBar } from 'react-bootstrap';

interface ParamsProps {
    files: Array<{
        names: string;
        descriptions: string;
        files: File;
        progress: number;
    }>;
    setFiles: Dispatch<
        SetStateAction<
            Array<{ names: string; descriptions: string; files: File; progress: number }>
        >
    >;
    isRename?: boolean;
    acceptFile?: {};
    isMaxUpload?: boolean;
    maxUploadSize: number;
    title?: string;
    maxFiles: number;
    multiple: boolean;
}

export default function DropFile(params: ParamsProps): JSX.Element {
    const {
        files,
        setFiles,
        isRename,
        acceptFile,
        isMaxUpload,
        maxUploadSize,
        title,
        maxFiles,
        multiple,
    } = params;

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        multiple,
        maxSize: 210000000, // max per file 210mb
        accept: acceptFile,
        // maxFiles,
        disabled: files.length >= maxFiles,
    });

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fff',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
    };

    useEffect(() => {
        const newFiles = acceptedFiles.map(file => {
            return {
                names: file.name,
                descriptions: '',
                files: file,
                progress: 0,
            };
        });

        if (files.length + newFiles.length > maxFiles) {
            // delete newFiles
            newFiles.splice(maxFiles - files.length, newFiles.length);
        }
        setFiles(prev => [...prev, ...newFiles]);
    }, [acceptedFiles]);

    const focusedStyle = {
        borderColor: '#2196f3',
    };

    const acceptStyle = {
        borderColor: '#00e676',
    };

    const rejectStyle = {
        borderColor: '#ff1744',
    };

    const style: any = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject],
    );

    const handleIcons = (type: string): ReactNode => {
        switch (type) {
            case 'application/pdf':
                return <AiFillFilePdf size={80} color="#E2574C" />;

            case 'image/png':
            case 'image/jpg':
            case 'image/jpeg':
                return <AiFillFileImage size={80} color="#f06909" />;

            case 'application/zip':
                return <AiFillFileZip size={80} color="#ffaa00" />;

            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return <AiFillFileWord size={80} color="#006ae3" />;

            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return <AiFillFileExcel size={80} color="#2cbe00" />;

            case 'text/csv':
            case 'text/plain':
                return <AiFillFileText size={80} color="#ffaa00" />;

            default:
                return <AiFillFile size={80} color="#bdbdbd" />;
        }
    };

    const handleMessage = (message: string): string => {
        return `File must ${Object.values(acceptFile as any[]).join(',')}`;
    };

    return isRename !== true ? (
        <ListFiles className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <BodyWrapper className="d-flex justify-content-center flex-column align-items-center">
                    <IconContent
                        icon={FileIcon}
                        style={{
                            backgroundColor:
                                files.length >= maxFiles ? '#d3d3d3' : '#5648fb',
                        }}
                    />
                    <h5
                        className="mb-0 title mt-3"
                        style={{
                            color: files.length >= maxFiles ? '#d3d3d3' : '#000',
                        }}
                    >
                        Drag & Drop
                    </h5>
                    <p className="mb-3 descriptions">
                        {title ?? 'Your files here or Browse to upload'}
                    </p>
                    <span
                        style={{
                            fontSize: '0.8rem',
                            color: files.length >= maxFiles ? '#d3d3d3' : '#8db4df',
                        }}
                    >
                        Max file {maxFiles} file, per size 200MB and{' '}
                        {readableBytes(maxUploadSize)} per upload
                    </span>
                </BodyWrapper>
            </div>
            <div className="mt-4 text-start ">
                <h6 className="fw-semibold">Files Uploading</h6>
                <ul>
                    {fileRejections.map(({ file, errors }: any) => (
                        <li key={file.path} className="text-danger">
                            <p
                                className="fw-bold mb-0"
                                style={{
                                    fontSize: 14,
                                }}
                            >
                                ({file.path} - {readableBytes(file.size)})
                            </p>
                            {errors.map((e: any) => (
                                <div key={e.code} className="mb-3">
                                    <span style={{ fontSize: 14 }}>
                                        {e.message ===
                                        'File is larger than 210000000 bytes'
                                            ? "File size can't be more than 200MB"
                                            : handleMessage(e.message)}
                                    </span>
                                    <br />
                                </div>
                            ))}
                        </li>
                    ))}
                </ul>
                {(isMaxUpload ?? false) && (
                    <span
                        className="text-danger mb-2"
                        style={{
                            fontSize: 14,
                        }}
                    >
                        Your total upload size is more than 4GB, please remove some files
                    </span>
                )}
                <ul>
                    {files.map((file: any, index: number) => (
                        <li key={index}>
                            <div className="d-flex align-items-center">
                                {handleIcons(file.files.type)}
                                <div className="d-flex flex-column w-100">
                                    <p
                                        className="mb-1"
                                        style={{
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            maxWidth: '300px',
                                            fontSize: 14,
                                        }}
                                    >
                                        {file.files.path}
                                    </p>
                                    <div className="d-flex justify-content-between">
                                        <span
                                            className="fw-semibold mb-1"
                                            style={{
                                                fontSize: 12,
                                            }}
                                        >
                                            {readableBytes(file.files.size)}
                                        </span>
                                        <span
                                            className="text-decoration-none text-danger"
                                            style={{ fontSize: 12, cursor: 'pointer' }}
                                            onClick={() => {
                                                setFiles((prev: any) =>
                                                    prev.filter(
                                                        (item: any, key: number) =>
                                                            key !== index,
                                                    ),
                                                );
                                            }}
                                        >
                                            Cancel
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </ListFiles>
    ) : (
        <ListFiles>
            <ul>
                {files.map((file: any, index: number) => (
                    <li key={index}>
                        <div className="d-flex align-items-center mb-5">
                            <div className="d-flex flex-column justify-content-between mt-3">
                                {handleIcons(file.files.type)}
                                <span
                                    className="fw-semibold mb-1"
                                    style={{
                                        fontSize: 12,
                                    }}
                                >
                                    {readableBytes(file.files.size)}
                                </span>
                                <ProgressBar
                                    animated
                                    now={file.progress}
                                    min={0}
                                    max={1}
                                />
                            </div>
                            <div className="d-flex flex-column ms-5 w-100">
                                <InputBasic
                                    placeholder="File Name"
                                    value={file.names}
                                    outerClassName="my-0"
                                    onChange={(e: any) => {
                                        setFiles((prev: any) => {
                                            const newFiles = [...prev];
                                            newFiles[index].names = e.target.value;
                                            return newFiles;
                                        });
                                    }}
                                    disabled={file.progress > 0}
                                />
                                <p
                                    className="text-danger mb-0 text-start"
                                    style={{
                                        fontSize: 12,
                                    }}
                                >
                                    {file.names.length > 0 ? '' : 'File name is required'}
                                </p>
                                <InputBasic
                                    placeholder="Description"
                                    value={file.descriptions}
                                    outerClassName="my-0"
                                    onChange={(e: any) => {
                                        setFiles((prev: any) => {
                                            const newFiles = [...prev];
                                            newFiles[index].descriptions = e.target.value;
                                            return newFiles;
                                        });
                                    }}
                                    disabled={file.progress > 0}
                                />
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </ListFiles>
    );
}

const IconContent = styled.span<{ icon: string; errors?: boolean }>`
    mask: url(${props => props.icon}) no-repeat center;
    mask-size: 100%;
    -webkit-mask: url(${props => props.icon}) no-repeat center;
    -webkit-mask-size: 80%;
    height: 3rem;
    width: 3rem;
`;

const BodyWrapper = styled.div`
    .title {
        color: #000;
    }
    .descriptions {
        color: #cfcfcf;
        font-size: 16px;
    }
`;

const ListFiles = styled.div`
    ul {
        list-style: none;
        padding-left: 0;
    }
`;
