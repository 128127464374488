import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import { RootState } from '@/redux/store';

export default function AccountSuspend(): JSX.Element {
    const navigate = useNavigate();

    const currentProfileState = useSelector((state: RootState) => state.currentProfile);
    const suspendedStatus: boolean = currentProfileState?.response?.suspended;

    // Redirect to home page if subscription is active
    useEffect(() => {
        if (suspendedStatus !== undefined && !suspendedStatus) {
            navigate('/');
        }
    }, [suspendedStatus]);

    return (
        <main className="container-fluid container-xs container-lg">
            <div
                className="d-flex align-items-center justify-content-center"
                style={{
                    height: '90vh',
                }}
            >
                <div className="text-center px-5">
                    <h1 className="h5 mb-4 fw-semibold">
                        Your Account has Been Suspend by Admin
                    </h1>
                    <p className="px-5 mb-4">
                        Please contact admin to activate your account.
                    </p>
                    <ButtonBasic
                        text="Chat Now"
                        className="btn-primary w-25"
                        onClick={() => navigate('/chat')}
                    />
                </div>
            </div>
        </main>
    );
}
