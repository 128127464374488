import ConAppLogo from '@/assets/svg/new_conapp_logo_transaprent.webp';
import { Link } from 'react-router-dom';

interface HeaderProps {
    text?: string;
    link?: string;
    withContent?: boolean;
}

export default function Header(props: HeaderProps): JSX.Element {
    const { text } = props;

    const clearStorage = (): void => {
        sessionStorage?.clear();
    };

    return (
        <div className="row mt-3 mt-md-5 gx-lg-5">
            <div className="col-5 col-md-6 align-self-center">
                <img src={ConAppLogo} alt="logo" className="img-fluid" width={150} />
            </div>
            <div className="col-7 col-md-6 text-end align-self-center">
                <Link
                    to="/login"
                    className="text-decoration-none"
                    style={{ fontSize: '0.95rem' }}
                    onClick={clearStorage}
                >
                    {text}
                </Link>
            </div>
        </div>
    );
}
