import { Tab, Tabs } from 'react-bootstrap';

import ManageUser from './TabSettings/ManageUser';
import ManageNicheTrade from './TabSettings/ManageNicheTrade';
import ManageProject from './TabSettings/ManageProject';

import styled from 'styled-components';
import { currentProjectAccess } from '@/utility/Utils';

export default function ProjectSetting(): JSX.Element {
    const projectAccess = currentProjectAccess();

    const summaryAccess = projectAccess?.summary;

    return (
        <WrapperTabs
            defaultActiveKey={'manageUser'}
            id="uncontrolled-tab-example"
            className="mt-5 border-0"
        >
            <Tab eventKey="manageUser" title="Manage User">
                <ManageUser />
            </Tab>
            <Tab eventKey="manageNicheTrade" title="Manage Niche Trade">
                <ManageNicheTrade />
            </Tab>
            {summaryAccess?.collaborate && (
                <Tab eventKey="manageProject" title="Manage Project">
                    <ManageProject />
                </Tab>
            )}
        </WrapperTabs>
    );
}

const WrapperTabs = styled(Tabs)`
    .nav-item {
        padding: 1rem;
    }
    .nav-link {
        border-radius: 20px;
        background: rgba(86, 72, 251, 0.5);
        color: #fff !important;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        padding: 0.2rem 1rem;

        &.active {
            color: #fff !important;
            background-color: #5648fb;
        }
    }
`;
