export const ALL_PROJECT_REQUEST = 'ALL_PROJECT_REQUEST';
export const ALL_PROJECT_SUCCESS = 'ALL_PROJECT_SUCCESS';
export const ALL_PROJECT_FAIL = 'ALL_PROJECT_FAIL';

export const DETAIL_PROJECT_REQUEST = 'DETAIL_PROJECT_REQUEST';
export const DETAIL_PROJECT_SUCCESS = 'DETAIL_PROJECT_SUCCESS';
export const DETAIL_PROJECT_FAIL = 'DETAIL_PROJECT_FAIL';
export const DETAIL_PROJECT_RESET = 'DETAIL_PROJECT_RESET';

export const ALL_TENDER_REQUEST = 'ALL_TENDER_REQUEST';
export const ALL_TENDER_SUCCESS = 'ALL_TENDER_SUCCESS';
export const ALL_TENDER_FAIL = 'ALL_TENDER_FAIL';

export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAIL = 'CREATE_PROJECT_FAIL';
export const CREATE_PROJECT_RESET = 'CREATE_PROJECT_RESET';

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAIL = 'UPDATE_PROJECT_FAIL';
export const UPDATE_PROJECT_RESET = 'UPDATE_PROJECT_RESET';

export const INVITE_PROJECT_MEMBER_REQUEST = 'INVITE_PROJECT_MEMBER_REQUEST';
export const INVITE_PROJECT_MEMBER_SUCCESS = 'INVITE_PROJECT_MEMBER_SUCCESS';
export const INVITE_PROJECT_MEMBER_FAIL = 'INVITE_PROJECT_MEMBER_FAIL';
export const INVITE_PROJECT_MEMBER_RESET = 'INVITE_PROJECT_MEMBER_RESET';

export const PIN_PROJECT_REQUEST = 'PIN_PROJECT_REQUEST';
export const PIN_PROJECT_SUCCESS = 'PIN_PROJECT_SUCCESS';
export const PIN_PROJECT_FAIL = 'PIN_PROJECT_FAIL';
export const PIN_PROJECT_RESET = 'PIN_PROJECT_RESET';

export const UNPIN_PROJECT_REQUEST = 'UNPIN_PROJECT_REQUEST';
export const UNPIN_PROJECT_SUCCESS = 'UNPIN_PROJECT_SUCCESS';
export const UNPIN_PROJECT_FAIL = 'UNPIN_PROJECT_FAIL';
export const UNPIN_PROJECT_RESET = 'UNPIN_PROJECT_RESET';

export const LEAVE_PROJECT_REQUEST = 'LEAVE_PROJECT_REQUEST';
export const LEAVE_PROJECT_SUCCESS = 'LEAVE_PROJECT_SUCCESS';
export const LEAVE_PROJECT_FAIL = 'LEAVE_PROJECT_FAIL';
export const LEAVE_PROJECT_RESET = 'LEAVE_PROJECT_RESET';

export const ALL_MEMBER_INVITED_REQUEST = 'ALL_MEMBER_INVITED_REQUEST';
export const ALL_MEMBER_INVITED_SUCCESS = 'ALL_MEMBER_INVITED_SUCCESS';
export const ALL_MEMBER_INVITED_FAIL = 'ALL_MEMBER_INVITED_FAIL';

export const DETAIL_MEMBER_INVITED_REQUEST = 'DETAIL_MEMBER_INVITED_REQUEST';
export const DETAIL_MEMBER_INVITED_SUCCESS = 'DETAIL_MEMBER_INVITED_SUCCESS';
export const DETAIL_MEMBER_INVITED_FAIL = 'DETAIL_MEMBER_INVITED_FAIL';
export const DETAIL_MEMBER_INVITED_RESET = 'DETAIL_MEMBER_INVITED_RESET';

export const ALL_MEMBER_INHOUSE_REQUEST = 'ALL_MEMBER_INHOUSE_REQUEST';
export const ALL_MEMBER_INHOUSE_SUCCESS = 'ALL_MEMBER_INHOUSE_SUCCESS';
export const ALL_MEMBER_INHOUSE_FAIL = 'ALL_MEMBER_INHOUSE_FAIL';

export const DETAIL_MEMBER_INHOUSE_REQUEST = 'DETAIL_MEMBER_INHOUSE_REQUEST';
export const DETAIL_MEMBER_INHOUSE_SUCCESS = 'DETAIL_MEMBER_INHOUSE_SUCCESS';
export const DETAIL_MEMBER_INHOUSE_FAIL = 'DETAIL_MEMBER_INHOUSE_FAIL';
export const DETAIL_MEMBER_INHOUSE_RESET = 'DETAIL_MEMBER_INHOUSE_RESET';

export const KICK_MEMBER_REQUEST = 'KICK_MEMBER_REQUEST';
export const KICK_MEMBER_SUCCESS = 'KICK_MEMBER_SUCCESS';
export const KICK_MEMBER_FAIL = 'KICK_MEMBER_FAIL';
export const KICK_MEMBER_RESET = 'KICK_MEMBER_RESET';

export const CREATE_BUG_REPORT_REQUEST = 'CREATE_BUG_REPORT_REQUEST';
export const CREATE_BUG_REPORT_SUCCESS = 'CREATE_BUG_REPORT_SUCCESS';
export const CREATE_BUG_REPORT_FAIL = 'CREATE_BUG_REPORT_FAIL';
export const CREATE_BUG_REPORT_RESET = 'CREATE_BUG_REPORT_RESEt';

export const CHECK_USER_EMAIL_REQUEST = 'CHECK_USER_EMAIL_REQUEST';
export const CHECK_USER_EMAIL_SUCCESS = 'CHECK_USER_EMAIL_SUCCESS';
export const CHECK_USER_EMAIL_FAIL = 'CHECK_USER_EMAIL_FAIL';
export const CHECK_USER_EMAIL_RESET = 'CHECK_USER_EMAIL_RESET';

export const RESEND_PROJECT_INVITATION_REQUEST = 'RESEND_PROJECT_INVITATION_REQUEST';
export const RESEND_PROJECT_INVITATION_SUCCESS = 'RESEND_PROJECT_INVITATION_SUCCESS';
export const RESEND_PROJECT_INVITATION_FAIL = 'RESEND_PROJECT_INVITATION_FAIL';
export const RESEND_PROJECT_INVITATION_RESET = 'RESEND_PROJECT_INVITATION_RESET';

export const CANCEL_PROJECT_INVITATION_REQUEST = 'CANCEL_PROJECT_INVITATION_REQUEST';
export const CANCEL_PROJECT_INVITATION_SUCCESS = 'CANCEL_PROJECT_INVITATION_SUCCESS';
export const CANCEL_PROJECT_INVITATION_FAIL = 'CANCEL_PROJECT_INVITATION_FAIL';
export const CANCEL_PROJECT_INVITATION_RESET = 'CANCEL_PROJECT_INVITATION_RESET';

export const CLEAR_STATE = 'CLEAR_STATE';

const initialState = {
    loading: false,
    response: null,
    error: null,
    status: null,
};

export const allProjectReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_PROJECT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const detailProjectReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DETAIL_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DETAIL_PROJECT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case DETAIL_PROJECT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const allTenderReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_TENDER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_TENDER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_TENDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const createProjectReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case CREATE_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CREATE_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case CREATE_PROJECT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CREATE_PROJECT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const updateProjectReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case UPDATE_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case UPDATE_PROJECT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case UPDATE_PROJECT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const inviteProjectMemberReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case INVITE_PROJECT_MEMBER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case INVITE_PROJECT_MEMBER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case INVITE_PROJECT_MEMBER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case INVITE_PROJECT_MEMBER_RESET:
            return initialState;

        default:
            return state;
    }
};

export const pinUnpinProjectReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case PIN_PROJECT_REQUEST:
        case UNPIN_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case PIN_PROJECT_SUCCESS:
        case UNPIN_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case PIN_PROJECT_FAIL:
        case UNPIN_PROJECT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case PIN_PROJECT_RESET:
        case UNPIN_PROJECT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const leaveProjectReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case LEAVE_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case LEAVE_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case LEAVE_PROJECT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case LEAVE_PROJECT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const allMemberInvitedReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_MEMBER_INVITED_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_MEMBER_INVITED_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_MEMBER_INVITED_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const allMemberInhouseReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_MEMBER_INHOUSE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_MEMBER_INHOUSE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_MEMBER_INHOUSE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const detailMemberInvitedReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DETAIL_MEMBER_INVITED_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_MEMBER_INVITED_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DETAIL_MEMBER_INVITED_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case DETAIL_MEMBER_INVITED_RESET:
            return initialState;

        default:
            return state;
    }
};

export const detailMemberInhouseReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DETAIL_MEMBER_INHOUSE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_MEMBER_INHOUSE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DETAIL_MEMBER_INHOUSE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case DETAIL_MEMBER_INHOUSE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const createBugReportReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case CREATE_BUG_REPORT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CREATE_BUG_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case CREATE_BUG_REPORT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CREATE_BUG_REPORT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const checkUserEmailReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case CHECK_USER_EMAIL_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CHECK_USER_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case CHECK_USER_EMAIL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CHECK_USER_EMAIL_RESET:
            return initialState;

        default:
            return state;
    }
};

export const resendProjectInvitationReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case RESEND_PROJECT_INVITATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case RESEND_PROJECT_INVITATION_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case RESEND_PROJECT_INVITATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case RESEND_PROJECT_INVITATION_RESET:
            return initialState;

        default:
            return state;
    }
};

export const cancelProjectInvitationReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case CANCEL_PROJECT_INVITATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CANCEL_PROJECT_INVITATION_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case CANCEL_PROJECT_INVITATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CANCEL_PROJECT_INVITATION_RESET:
            return initialState;

        default:
            return state;
    }
};
