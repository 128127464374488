// import Accordion from '@/components/molecules/Accordion';
import AccordionBasic from '@/components/molecules/AccordionBasic';
import HowToJoinCompanyUser from './Sub/Authentication/HowToJoinCompanyUser';
import HowToJoinInhouseUser from './Sub/Authentication/HowToJoinInhouseUser';
import HowToLogin from './Sub/Authentication/HowToLogin';
import HowToResetPassword from './Sub/Authentication/HowToResetPassword';

export default function HelpAuthentication(): JSX.Element {
    const lists = [
        {
            id: 'howToLogin',
            title: 'How to login',
            content: <HowToLogin />,
        },
        {
            id: 'joinAsOwner',
            title: 'How to join Construction Application (As a Company User/Owner)',
            content: <HowToJoinCompanyUser />,
        },
        {
            id: 'joinAsInhouse',
            title: 'How to join Construction Application (As a Inhouse User)',
            content: <HowToJoinInhouseUser />,
        },
        {
            id: 'howToResetPassword',
            title: 'How to reset password if forgot',
            content: <HowToResetPassword />,
        },
    ];

    return <AccordionBasic lists={lists} defaultActiveKey="howToLogin" />;
}
