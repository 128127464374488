import projectImage1 from '@/assets/images/project/help-project-image-1.webp';
import projectImage2 from '@/assets/images/project/help-project-image-2.webp';
import projectImage3 from '@/assets/images/project/help-project-image-3.webp';

import { Fragment } from 'react';

export default function HowToCreateProject(): JSX.Element {
    const contents = [
        {
            id: 1,
            text: 'To create a new project, you can quickly click the create project button on the left sidebar, then modal will appear.',
            alt: 'projectPage',
            image: projectImage1,
        },
        {
            id: 2,
            text: 'After that you can fill the project form',
            alt: 'projectPage',
            image: projectImage2,
        },
        {
            id: 3,
            text: 'After successfully creating a project, you will redirect to the project page dashboard',
            alt: 'projectPage',
            image: projectImage3,
        },
    ];

    return (
        <Fragment>
            {contents.map((content, index) => (
                <div key={index} className="text-grey-300 mb-10">
                    {content.image.length > 0 && (
                        <img
                            src={content.image}
                            alt={content.alt}
                            className="img-fluid"
                        />
                    )}
                    <p className="mt-4 pl-2">{content.text}</p>
                </div>
            ))}
        </Fragment>
    );
}
