import { axiosClient } from '@/services/GatewayService';

export const createDocumentSpaceLib = async (
    params: {
        project_id: string;
        public: number;
    },
    formData: FormData,
): Promise<any> => {
    try {
        const res = await axiosClient.post('project/documents/folders', formData, {
            params: {
                ...params,
            },
        });

        return res?.data;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const requestMultipleDocumentLib = async (
    params: {
        project_id: string;
    },
    formData: FormData,
): Promise<any> => {
    try {
        const res = await axiosClient.post('project/documents/download', formData, {
            params: {
                ...params,
            },
        });

        return res?.data;
    } catch (error: any) {
        throw new Error(error);
    }
};
