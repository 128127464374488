import authImage1 from '@/assets/images/auth/help-auth-image-13.webp';
import authImage2 from '@/assets/images/auth/help-auth-image-2.webp';
import authImage3 from '@/assets/images/auth/help-auth-image-3.webp';
import authImage4 from '@/assets/images/auth/help-auth-image-4.webp';
import authImage5 from '@/assets/images/auth/help-auth-image-5.webp';
import authImage6 from '@/assets/images/auth/help-auth-image-6.webp';
import authImage7 from '@/assets/images/auth/help-auth-image-7.webp';
import authImage8 from '@/assets/images/auth/help-auth-image-8.webp';

import { Fragment } from 'react';

export default function HowToJoinCompanyUser(): JSX.Element {
    const contents = [
        {
            id: 1,
            text: 'On the login page you can click the sign up link which is at the bottom.',
            alt: 'loginPage',
            image: authImage1,
        },
        {
            id: 2,
            text: 'After that the page will switch and you are asked to fill in several forms such as your email address, username and password, if everything is correct you can proceed to the next step.',
            alt: 'registerPage',
            image: authImage2,
        },
        {
            id: 3,
            text: 'The next step is for you to verify your email, the system has sent a verification link to your email, you can open the inbox or spam folder on your email account, then you can verify.',
            alt: 'registerPage',
            image: authImage3,
        },
        {
            id: 4,
            text: 'After you verify, you will automatically return to the application, and you will be asked to fill in a few more forms in the first step, such as uploading your profile photo, full name and company name, then clicking next if all data is correct.',
            alt: 'registerPage',
            image: authImage4,
        },
        {
            id: 5,
            text: 'In the next step, you are asked to choose your line of business, if your field of business is not on the list, you can input it manually on the input form which is located below the list.',
            alt: 'registerPage',
            image: authImage5,
        },
        {
            id: 6,
            text: 'The next step is that you are asked to choose the number of users who will join your company.',
            alt: 'registerPage',
            image: authImage6,
        },
        {
            id: 7,
            text: "The next step is to invite users who will later join your company, you are asked to input the user's email, you can also skip this step and can invite users whenever you want through the settings menu.",
            alt: 'registerPage',
            image: authImage7,
        },
        {
            id: 8,
            text: 'After you click the finish button, the page will switch and display information if all the steps you have done have been completed, and you are asked to return to the login page and log in to access the application.',
            alt: 'registerPage',
            image: authImage8,
        },
    ];

    return (
        <Fragment>
            {contents.map((content, index) => (
                <div key={index} className="">
                    <img src={content.image} alt={content.alt} className="img-fluid" />
                    <p className="mt-4 pl-2">{content.text}</p>
                </div>
            ))}
        </Fragment>
    );
}
