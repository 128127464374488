import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import ModalAction from '@/components/atoms/Modals/ModalAction';
import InputBasic from '@/components/atoms/Inputs/InputBasic';
import InputArea from '@/components/atoms/Inputs/InputArea';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';

import { RootState } from '@/redux/store';

import {
    getAllNicheTrade,
    updateNicheTrade,
    clearActionNicheTrade,
} from '../../redux/actions';
import { Placeholder } from 'react-bootstrap';

interface ParamsProps {
    modal: {
        add: boolean;
        success: boolean;
        edit: boolean;
        danger: boolean;
    };
    setModal: React.Dispatch<
        React.SetStateAction<{
            add: boolean;
            success: boolean;
            edit: boolean;
            danger: boolean;
        }>
    >;
    params: {
        project_id: string;
        page?: number;
        per_page?: number;
        search: string;
        sort_by: string;
        sort_asc: number;
    };
}

export default function EditNicheTrade(props: ParamsProps): JSX.Element {
    const { modal, setModal, params } = props;

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
    });

    const dispatch = useDispatch();

    const { id } = useParams();
    const projectId: string = id ?? '';

    const detailNicheTradeState = useSelector(
        (state: RootState) => state.detailNicheTrade,
    );

    const actionNicheTradeState = useSelector(
        (state: RootState) => state.actionNicheTrade,
    );

    /**
     * Function on submit
     *
     * @param any data
     * @return void
     * @see cypress/e2e/dashboard/Project/project.cy.ts
     *      To cypress unit tester
     */
    const handleUpdateNiche = (data: any): void => {
        // initialize form data
        const payload: FormData = new FormData();

        const idNiche: string = data.id;

        const params = {
            project_id: projectId,
            id: idNiche,
        };

        // append data to form data
        for (const key in data) {
            payload.append(key, data[key as keyof typeof data]);
        }

        if (data.description === null) {
            payload.delete('description');
        }

        // Dispatch login action with form data as payload
        dispatch(updateNicheTrade(params, payload));
    };

    // Function for show success modal
    useEffect(() => {
        if (actionNicheTradeState.status === 200) {
            dispatch(getAllNicheTrade(params));

            setModal(prev => ({
                ...prev,
                edit: false,
                success: true,
            }));

            dispatch(clearActionNicheTrade());
        }
    }, [actionNicheTradeState]);

    // Function to load on input
    useEffect(() => {
        if (detailNicheTradeState.status === 200) {
            const { id, name, description } = detailNicheTradeState.response;
            setValue('id', id);
            setValue('name', name);
            setValue('description', description);
        }
    }, [detailNicheTradeState]);

    return (
        <ModalAction
            modal={modal.edit}
            setModal={setModal}
            aria-labelledby="contained-modal-title-vcenter"
            title="Edit Niche trade"
            size="sm"
        >
            <form action="#" onSubmit={handleSubmit(handleUpdateNiche)} method="post">
                {detailNicheTradeState.loading !== false ? (
                    <div className="px-4">
                        <Placeholder bg="secondary" size="lg" xs={12} className="mt-4" />
                        <Placeholder bg="secondary" size="lg" xs={12} className="my-4" />
                    </div>
                ) : (
                    <>
                        <InputBasic
                            id="niche name"
                            type="text"
                            placeholder="Niche Trade Name"
                            innerClassName="mb-3"
                            outerClassName="px-4 mt-3"
                            rules={{
                                function: register,
                                name: 'name',
                                rules: {
                                    required: 'Niche trade name is required',
                                },
                                errors,
                            }}
                        />
                        <InputArea
                            id="description"
                            placeholder="Description"
                            outerClassName="px-4 mb-4"
                            rows={5}
                            rules={{
                                function: register,
                                name: 'description',
                                rules: {
                                    maxLength: {
                                        value: 2000,
                                        message: 'Maximum length of 2000 characters',
                                    },
                                },
                                errors,
                            }}
                        />
                    </>
                )}

                <ButtonBasic
                    text="Confirm"
                    type="submit"
                    className="btn btn-primary"
                    loading={actionNicheTradeState.loading}
                />
            </form>
        </ModalAction>
    );
}
