import { ThreeDots } from 'react-loader-spinner';
import styled from 'styled-components';

import { Title } from '@/components/molecules/Typography';

export default function Loader(): JSX.Element {
    return (
        <Portal>
            <div
                className="d-flex flex-column justify-content-center align-items-center"
                data-testid="three-dots-loading"
            >
                <Title text="Please wait a moment" />
                <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#5648FB"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    // wrapperClassName=""
                    visible={true}
                />
            </div>
        </Portal>
    );
}

const Portal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
`;
