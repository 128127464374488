import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@/components/molecules/Card';
import InputBasic from '@/components/atoms/Inputs/InputBasic';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import PasswordValidation from '@/components/molecules/PasswordValidation';
import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';

import { HiOutlineMail } from 'react-icons/hi';
import { FiPhone } from 'react-icons/fi';

import {
    getCurrentProfile,
    updateCurrentPassword,
    clearStateUpdatePasswordProfile,
    resetCurrentPasswordEmail,
    resetCurrentPasswordPhone,
    clearStateResetPasswordPhone,
    clearStateResetPasswordEmail,
    clearStatePhone,
} from '../redux/actions';

import { formatDate } from '@/utility/Utils';

import styled from 'styled-components';
import ModalOtp from '@/components/atoms/Modals/ModalOtp';
import { RootState } from '@/redux/store';
import ModalNewPassword from './ModalNewPassword';
interface ValidationListsProps {
    id: string;
    text: string;
    isValidate: boolean;
}

interface MethodListsProps {
    id: number;
    title: string;
    icon: JSX.Element;
}

interface CardMethodProps {
    selected: number;
    index: number;
}

export default function ManagePassword(): JSX.Element {
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        criteriaMode: 'all',
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [modal, setModal] = useState({
        caution: false,
        success: false,
        reset: false,
        otpPhone: false,
        newPassword: false,
        successPhone: false,
    });
    const [selectedMethod, setSelectedMethod] = useState<number>(0);

    const updateCurrentPasswordState = useSelector(
        (state: RootState) => state.updateCurrentPassword,
    );
    const resetProfilePasswordEmailState = useSelector(
        (state: RootState) => state.resetProfilePasswordEmail,
    );
    const resetProfilePasswordPhoneState = useSelector(
        (state: RootState) => state.resetProfilePasswordPhone,
    );

    const verifyOTPState = useSelector((state: RootState) => state.verifyOTP);

    const currentProfileState = useSelector((state: RootState) => state.currentProfile);

    const passwordCheck = watch('new_password_confirmation');

    const methodLists: MethodListsProps[] = [
        {
            id: 1,
            title: 'Email',
            icon: (
                <HiOutlineMail
                    size={25}
                    color={selectedMethod === 1 ? '#5648fb' : '#838383'}
                />
            ),
        },
        {
            id: 2,
            title: 'Phone Number',
            icon: (
                <FiPhone size={25} color={selectedMethod === 2 ? '#5648fb' : '#838383'} />
            ),
        },
    ];

    const [validationLists, setValidationLists] = useState<ValidationListsProps[]>([
        {
            id: 'isMaxLength',
            text: 'At least 10 characters',
            isValidate: false,
        },
        {
            id: 'isUppercase',
            text: 'An uppercase character',
            isValidate: false,
        },
        {
            id: 'isLowercase',
            text: 'A lowercase character',
            isValidate: false,
        },
        {
            id: 'isNumber',
            text: 'A number',
            isValidate: false,
        },
        {
            id: 'isSpecialCharacter',
            text: 'A special character',
            isValidate: false,
        },
    ]);

    // if update current password success
    useEffect(() => {
        if (updateCurrentPasswordState.status === 200) {
            setModal(prev => ({
                ...prev,
                caution: false,
            }));
            dispatch(clearStateUpdatePasswordProfile());

            setTimeout(() => {
                dispatch(getCurrentProfile());
                setModal(prev => ({
                    ...prev,
                    success: true,
                }));
                reset();
            }, 1000);
        }
    }, [updateCurrentPasswordState]);

    useEffect(() => {
        if (resetProfilePasswordEmailState.status === 200) {
            dispatch(clearStateResetPasswordEmail());

            setTimeout(() => {
                setModal(prev => ({
                    ...prev,
                    reset: true,
                }));
            }, 1000);
        }
    }, [resetProfilePasswordEmailState]);

    // if reset password phone success
    useEffect(() => {
        if (resetProfilePasswordPhoneState.status === 200) {
            // set otp to local storage
            dispatch(clearStateResetPasswordPhone());

            setTimeout(() => {
                setModal(prev => ({
                    ...prev,
                    otpPhone: true,
                }));
            }, 1000);
        }
    }, [resetProfilePasswordPhoneState]);

    useEffect(() => {
        if (verifyOTPState.status === 200) {
            dispatch(clearStateResetPasswordPhone());
            dispatch(clearStatePhone());

            setTimeout(() => {
                setModal(prev => ({
                    ...prev,
                    otpPhone: false,
                    newPassword: true,
                }));
            }, 1000);
        }
    }, [verifyOTPState]);

    // For password validation
    useEffect(() => {
        if (passwordCheck !== undefined) {
            const isMaxLength = passwordCheck.length >= 10;
            const isUppercase = /[A-Z]/.test(passwordCheck);
            const isLowercase = /[a-z]/.test(passwordCheck);
            const isNumber = /[0-9]/.test(passwordCheck);
            const isSpecialCharacter = /[^a-zA-Z0-9]/.test(passwordCheck);

            setValidationLists(prevState => [
                {
                    ...prevState[0],
                    isValidate: isMaxLength,
                },
                {
                    ...prevState[1],
                    isValidate: isUppercase,
                },
                {
                    ...prevState[2],
                    isValidate: isLowercase,
                },
                {
                    ...prevState[3],
                    isValidate: isNumber,
                },
                {
                    ...prevState[4],
                    isValidate: isSpecialCharacter,
                },
            ]);
        }
    }, [passwordCheck]);

    /**
     * Function on submit change password
     *
     * @param any data
     * @return void
     * @see cypress/e2e/authentication/login/login.cy.ts
     *      To cypress unit tester
     */
    const onSubmit = (data: any): void => {
        // initialize form data
        const payload = new FormData();

        // append data to form data
        for (const key in data) {
            payload.append(key, data[key as keyof typeof data]);
        }

        // Dispatch login action with form data as payload
        dispatch(updateCurrentPassword(payload));
    };

    /**
     * Function for reset password
     *
     * @param any data
     * @return void
     * @see cypress/e2e/authentication/login/login.cy.ts
     *      To cypress unit tester
     */
    const handleResetPassword = (): void => {
        selectedMethod === 1
            ? dispatch(resetCurrentPasswordEmail())
            : dispatch(resetCurrentPasswordPhone());
    };

    return (
        <>
            <Card>
                <form action="#" onSubmit={handleSubmit(onSubmit)} method="post">
                    <div className="row mb-3">
                        <div className="col-md-12 mb-5">
                            <h6 className="card-title fw-semibold">Manage Password</h6>
                            <span className="text-muted" style={{ fontSize: 14 }}>
                                {currentProfileState?.response?.password_updated_at !==
                                null
                                    ? `Last Change ${formatDate(
                                          currentProfileState?.response
                                              ?.password_updated_at,
                                          'DD MMMM YYYY HH:mm',
                                      )}`
                                    : "Password hasn't been changed"}
                            </span>
                            {currentProfileState?.response?.has_no_password !== false && (
                                <p className="mt-2 text-muted" style={{ fontSize: 12 }}>
                                    You was registered with google, to change your
                                    password in first time, you must change password
                                    with&nbsp;
                                    <b>Reset Password</b> method
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="row g-5 mb-5">
                        <div className="col-md-12 mb-3">
                            <h6 className="card-title fw-semibold">Change Password</h6>
                        </div>
                        <div className="col-lg-6 mt-0">
                            <InputBasic
                                id="password"
                                placeholder="Old Password"
                                type="password"
                                rules={{
                                    function: register,
                                    name: 'old_password',
                                    rules: {
                                        required: 'Old Password is required',
                                    },
                                    errors,
                                }}
                            />
                        </div>
                        <div className="col-lg-6 mt-0">
                            <InputBasic
                                id="newPassword"
                                placeholder="New Password"
                                type="password"
                                rules={{
                                    function: register,
                                    name: 'new_password_confirmation',
                                    rules: {
                                        required: 'Password is required',
                                        validate: {
                                            isMaxLength: (value: string) =>
                                                value.length >= 10 ||
                                                'At least 10 characters required',
                                            isUppercase: (value: string) =>
                                                /[A-Z]/.test(value) ||
                                                'An uppercase character required',
                                            isLowercase: (value: string) =>
                                                /[a-z]/.test(value) ||
                                                'A lowercase character required',
                                            isNumber: (value: string) =>
                                                /[0-9]/.test(value) ||
                                                'A number required',
                                            isSpecialCharacter: (value: string) =>
                                                /[^a-zA-Z0-9]/.test(value) ||
                                                'A special character required',
                                        },
                                    },
                                    errors,
                                }}
                            />
                            <InputBasic
                                id="confirmPassword"
                                placeholder="Confirm Password"
                                type="password"
                                rules={{
                                    function: register,
                                    name: 'new_password',
                                    rules: {
                                        required: 'Confirm Password is required',
                                        validate: {
                                            isMatch: (value: string) =>
                                                value ===
                                                    watch('new_password_confirmation') ||
                                                'Password does not match with new password',
                                        },
                                    },
                                    errors,
                                }}
                            />
                            <span
                                className="card-title fw-semibold"
                                style={{
                                    fontSize: 14,
                                }}
                            >
                                New password must be :
                            </span>
                            <PasswordValidation lists={validationLists} />
                        </div>
                        <div className="row mt-5">
                            <div className="col-lg-3 ms-auto mb-3">
                                <ButtonBasic
                                    text="Cancel"
                                    className="btn-outline-primary w-100"
                                    type="button"
                                    onClick={() => {
                                        navigate('/');
                                    }}
                                />
                            </div>
                            <div className="col-lg-3 mb-3">
                                <ButtonBasic
                                    text="Save Changes"
                                    className="btn-primary w-100"
                                    type="button"
                                    onClick={() =>
                                        errors.old_password == null &&
                                        errors.new_password == null &&
                                        errors.new_password_confirmation == null &&
                                        setModal(prev => ({
                                            ...prev,
                                            caution: true,
                                        }))
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row g-5 mb-5">
                        <div className="col-md-12">
                            <h6 className="card-title fw-semibold mb-4">
                                Change Password
                            </h6>
                            <span style={{ fontSize: 14 }}>
                                Select method to reset your password :
                            </span>
                        </div>
                        <div className="row ms-1">
                            {methodLists.map(item => (
                                <div
                                    id={item.id.toString()}
                                    key={item.id}
                                    className="col-md-3 my-3"
                                    onClick={() => setSelectedMethod(item.id)}
                                >
                                    <CardMethod
                                        className="card px-1 py-3 text-center"
                                        index={item.id}
                                        selected={selectedMethod}
                                    >
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            {item.icon}
                                            <span
                                                className={`mt-3 ${
                                                    selectedMethod === item.id
                                                        ? 'text-primary fw-semibold'
                                                        : 'text-muted'
                                                }`}
                                                style={{ fontSize: 13 }}
                                            >
                                                {item.title}
                                            </span>
                                        </div>
                                    </CardMethod>
                                </div>
                            ))}
                            {selectedMethod !== 0 && (
                                <Link
                                    to="#"
                                    style={{
                                        textDecoration: 'none',
                                    }}
                                    onClick={handleResetPassword}
                                >
                                    Reset Password
                                </Link>
                            )}
                        </div>
                    </div>
                </form>
            </Card>

            <ModalNewPassword modal={modal} setModal={setModal} />

            <ModalDashboard
                modal={modal.caution}
                setModal={setModal}
                variant="info"
                type="caution"
                title="Save Changes?"
                body="Are you sure want to change your password?"
                withCancel
                onConfirm={handleSubmit(onSubmit)}
                loading={updateCurrentPasswordState?.loading}
            />
            <ModalDashboard
                modal={modal.success}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body="Successfully change password"
                onConfirm={() => {
                    setModal({ ...modal, success: false });
                }}
            />

            <ModalDashboard
                modal={modal.successPhone}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body="Successfully reset your phone number"
                onConfirm={() => {
                    setModal({ ...modal, successPhone: false });
                }}
            />

            <ModalDashboard
                modal={modal.reset}
                setModal={setModal}
                variant="success"
                type="success"
                title="Verification Sent"
                body="We just sent the verification to your email, please check to your inbox"
                onConfirm={() => {
                    setModal({ ...modal, reset: false });
                }}
            />
            <ModalOtp
                modal={modal.otpPhone}
                setModal={setModal}
                phoneNumber={currentProfileState?.response?.phone}
            />
        </>
    );
}

const CardMethod = styled.div<CardMethodProps>`
    border: ${props =>
        props.selected === props.index ? '2px solid #5648fb' : '2px solid #838383'};
    border-radius: 10px;
    cursor: pointer;
    background-color: transparent;
`;
