import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { isValidPhoneNumber } from 'react-phone-number-input';

import Card from '@/components/molecules/Card';
import InputFile from '@/components/atoms/Inputs/InputFile';
import InputBasic from '@/components/atoms/Inputs/InputBasic';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import BadgeBasic from '@/components/atoms/Badges/BadgeBasic';
import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';
import InputPhone from '@/components/atoms/Inputs/InputPhone';

import {
    getCurrentProfile,
    updateCurrentProfile,
    updateProfilePicture,
    sendPhoneVerification,
    resendEmailVerification,
    clearStatePhone,
    clearStateUpdateProfile,
    clearStateUpdatePictureProfile,
    clearStateEmailResend,
} from '../redux/actions';

import ModalOtp from '@/components/atoms/Modals/ModalOtp';
import { RootState } from '@/redux/store';
import TimerCountdown from '@/components/molecules/TimerCountdown';

interface DataProps {
    id: number;
    title: string;
}

export default function PersonalInformationSetting(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
    });

    const [modal, setModal] = useState({
        caution: false,
        success: false,
        cautionEmail: false,
        cautionPhone: false,
        otp: false,
        phone: false,
        resend: false,
    });
    const [isUpdatePicture, setIsUpdatePicture] = useState(false);

    const currentProfileState = useSelector((state: RootState) => state.currentProfile);
    const updateCurrentProfileState = useSelector(
        (state: RootState) => state.updateCurrentProfile,
    );
    const updatePictureProfileState = useSelector(
        (state: RootState) => state.updatePictureProfile,
    );
    const sendPhoneVerificationState = useSelector(
        (state: RootState) => state.sendPhoneVerification,
    );
    const resendEmailVerificationState = useSelector(
        (state: RootState) => state.resendEmailVerification,
    );
    const acceptOtpProfileState = useSelector(
        (state: RootState) => state.acceptOtpProfile,
    );

    const userRole = currentProfileState?.response?.role?.name;

    const currentPhone = watch('phone')?.toString();

    const jobLists: DataProps[] = [
        {
            id: 1,
            title: 'Project Manager',
        },
        {
            id: 2,
            title: 'Site Foreman',
        },
        {
            id: 3,
            title: 'Senior Designer',
        },
        {
            id: 4,
            title: 'Estimator',
        },
        {
            id: 5,
            title: 'Other',
        },
    ];

    // TODO: Delete this function because already get profile in the layout
    // get profile setting in the first time
    // useEffect(() => {
    //     dispatch(getCurrentProfile());
    // }, []);

    // set default value to the state
    useEffect(() => {
        if (currentProfileState.status === 200) {
            const { name, email, job_title, phone } = currentProfileState?.response;

            if (!isUpdatePicture) {
                setValue('name', name);
                setValue('email', email);
                setValue('phone', phone);
                handleJobTitle(job_title);
            }
        }
    }, [currentProfileState]);

    // if update profile success
    useEffect(() => {
        if (updateCurrentProfileState.status === 200) {
            setIsUpdatePicture(false);

            setModal(prev => ({
                ...prev,
                caution: false,
            }));

            setTimeout(() => {
                // dispatch(
                //     getCurrentProfile({
                //         force: 1,
                //     }),
                // );
                dispatch(clearStateUpdateProfile());
                setModal(prev => ({
                    ...prev,
                    success: true,
                }));
            }, 1000);
        }
    }, [updateCurrentProfileState]);

    // if profile picture is updated
    useEffect(() => {
        if (updatePictureProfileState.status === 200) {
            setIsUpdatePicture(true);

            setModal(prev => ({
                ...prev,
                caution: false,
            }));

            setTimeout(() => {
                dispatch(
                    getCurrentProfile({
                        force: 1,
                    }),
                );
                dispatch(clearStateUpdatePictureProfile());
                setModal(prev => ({
                    ...prev,
                    success: true,
                }));
            }, 1000);
        }
    }, [updatePictureProfileState]);

    // if phone verification is sent
    useEffect(() => {
        if (sendPhoneVerificationState.status === 200) {
            setModal(prev => ({
                ...prev,
                cautionPhone: false,
            }));
            dispatch(clearStatePhone());

            setTimeout(() => {
                setModal(prev => ({
                    ...prev,
                    otp: true,
                }));
            }, 1000);
        }
    }, [sendPhoneVerificationState]);

    // if otp verification is sent
    useEffect(() => {
        if (acceptOtpProfileState.status === 200) {
            setModal(prev => ({
                ...prev,
                otp: false,
            }));
            dispatch(clearStatePhone());

            setTimeout(() => {
                setModal(prev => ({
                    ...prev,
                    phone: true,
                }));
                dispatch(getCurrentProfile());
            }, 1000);
        }
    }, [acceptOtpProfileState]);

    useEffect(() => {
        if (resendEmailVerificationState.status === 200) {
            setModal(prev => ({
                ...prev,
                cautionEmail: false,
            }));
            dispatch(clearStateEmailResend());

            setTimeout(() => {
                setModal(prev => ({
                    ...prev,
                    resend: true,
                }));
            }, 1000);
        }
    }, [resendEmailVerificationState]);

    /**
     * Function handle job title
     *
     * @param string job_title
     * @return any
     */
    const handleJobTitle = (job: string): any => {
        switch (job) {
            case 'Project Manager':
                return setValue('job_title', job);
            case 'Site Foreman':
                return setValue('job_title', job);
            case 'Senior Designer':
                return setValue('job_title', job);
            case 'Estimator':
                return setValue('job_title', job);
            default:
                if (job !== null) {
                    setValue('job_title', 'Other');
                    return setValue('user_job_title_other', job);
                } else {
                    return setValue('job_title', '');
                }
        }
    };

    /**
     * Function on submit
     *
     * @param any data
     * @return void
     * @see cypress/e2e/authentication/login/login.cy.ts
     *      To cypress unit tester
     */
    const onSubmit = (data: any): void => {
        // initialize form data
        const payload = new FormData();

        if (data?.job_title === 'Other') {
            data.job_title = data?.user_job_title_other;
        }

        if (data?.phone === '') {
            payload.delete('phone');
        }

        // append data to form data
        for (const key in data) {
            payload.append(key, data[key as keyof typeof data]);
        }

        // if job title is null
        if (data.job_title === null) {
            payload.delete('job_title');
        }

        // if email is same with current email
        if (data.email === currentProfileState?.response?.email) {
            payload.delete('email');
        }

        // if phone is same with current phone
        if (data.phone === currentProfileState?.response?.phone) {
            payload.delete('phone');
        }

        // Dispatch login action with form data as payload
        dispatch(updateCurrentProfile(payload));
    };

    return (
        <>
            <Card>
                <div className="row mb-3">
                    <div className="col-md-6 mb-5">
                        <h6 className="card-title fw-semibold">Personal Information</h6>
                    </div>
                    <div className="col-md-6 text-end">
                        {/* TODO: Fix after backend fixed */}
                        {/* {currentProfileState?.response?.email_verified !== true &&
                            currentProfileState?.response?.email_verified !==
                                undefined && (
                                <BadgeBasic
                                    variant="danger"
                                    text="Your email is not verified"
                                    className="mb-2"
                                />
                            )} */}

                        {currentProfileState?.response?.phone_verified !== true &&
                            currentProfileState?.response?.phone_verified !== undefined &&
                            currentProfileState?.response?.phone !== null && (
                                <div>
                                    <BadgeBasic
                                        variant="danger"
                                        text="Your phone is not verified"
                                    />
                                    <p
                                        className="link-primary"
                                        role="button"
                                        style={{
                                            fontSize: 12,
                                        }}
                                        onClick={() =>
                                            setModal(prev => ({
                                                ...prev,
                                                cautionPhone: true,
                                            }))
                                        }
                                    >
                                        Send verification to phone
                                    </p>
                                </div>
                            )}

                        {currentProfileState?.response?.account_email_update_request !==
                            null &&
                            currentProfileState?.response
                                ?.account_email_update_request !== undefined && (
                                <p
                                    className="text-muted"
                                    style={{
                                        fontSize: 12,
                                    }}
                                >
                                    We have sent a verification email to{' '}
                                    <b>
                                        {
                                            currentProfileState?.response
                                                ?.account_email_update_request?.new_email
                                        }
                                    </b>
                                    . Please check your email and click the link to verify
                                    your new email address.
                                    <TimerCountdown
                                        minute={5}
                                        second={0}
                                        textCount="Resend email in"
                                        textButton="Resend email"
                                        textDesc="Don't receive the verification mail?"
                                        onClick={() =>
                                            setModal(prev => ({
                                                ...prev,
                                                cautionEmail: true,
                                            }))
                                        }
                                    />
                                </p>
                            )}
                    </div>
                </div>
                <form action="#" onSubmit={handleSubmit(onSubmit)} method="post">
                    <div className="row mb-5">
                        <div className="col-lg-12 mb-2">
                            <InputFile
                                file={
                                    updateCurrentProfileState?.response?.avatar ??
                                    currentProfileState?.response?.avatar
                                }
                                action={updateProfilePicture}
                                payload="avatar"
                                loading={
                                    updatePictureProfileState.loading ??
                                    currentProfileState?.loading
                                }
                                name={currentProfileState?.response?.name}
                            />
                        </div>
                    </div>
                    <div className="row g-5 mb-5">
                        <div className="col-lg-6">
                            <InputBasic
                                id="fullName"
                                placeholder="Full Name"
                                rules={{
                                    function: register,
                                    name: 'name',
                                    rules: {
                                        required: 'Name is required',
                                    },
                                    errors,
                                }}
                            />
                            <label
                                id="companyPeopleRange"
                                className="form-label my-3"
                                style={{ color: '#838383' }}
                            >
                                Job Title
                            </label>
                            {jobLists.map(list => (
                                <Form.Check
                                    id={list.title}
                                    key={list.id}
                                    type="radio"
                                    label={list.title}
                                    className="mb-3"
                                    value={list.title}
                                    {...register('job_title')}
                                />
                            ))}
                            {errors.job_title != null && (
                                <p
                                    className="invalid-feedback d-block text-start"
                                    style={{ fontSize: '0.75rem' }}
                                >
                                    {errors.job_title.message as string}
                                </p>
                            )}
                            {watch('job_title') ===
                                jobLists.find(list => list.title === 'Other')?.title && (
                                <InputBasic
                                    id="jobTitleOther"
                                    type="text"
                                    placeholder="Enter Job Title"
                                    outerClassName="mb-0"
                                    innerClassName="mb-0"
                                    rules={{
                                        function: register,
                                        name: 'user_job_title_other',
                                        rules: {
                                            required: 'Please enter your job title',
                                        },
                                        errors,
                                    }}
                                />
                            )}
                        </div>
                        <div className="col-lg-6">
                            <InputBasic
                                id="workEmail"
                                placeholder="Work Email"
                                type="email"
                                disabled={userRole !== 'Company User'}
                                rules={{
                                    function: register,
                                    name: 'email',
                                    rules: {
                                        required: 'Email is required',
                                        pattern: {
                                            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                            message: 'Email is not valid',
                                        },
                                    },
                                    errors,
                                }}
                            />
                            <InputBasic
                                id="companyName"
                                placeholder="Company Name"
                                type="text"
                                value={
                                    currentProfileState?.response?.company?.company_name
                                }
                                disabled
                            />
                            <InputBasic
                                id="companyNiche"
                                placeholder="Company Niche"
                                type="text"
                                value={
                                    currentProfileState?.response?.company?.company_niche
                                        ?.name ??
                                    currentProfileState?.response?.company
                                        ?.company_niche_other
                                }
                                disabled
                            />
                            <InputPhone
                                id="phoneNumber"
                                placeholder="Phone Number"
                                country="AU"
                                rules={{
                                    function: register,
                                    name: 'phone',
                                    rules: {
                                        validate: (value: any) =>
                                            value === '' ||
                                            isValidPhoneNumber(value ?? '') ||
                                            'Phone Number is not valid. If you wanna remove your phone number, please leave it blank',
                                    },
                                    errors,
                                }}
                                defaultValue={
                                    currentPhone !== undefined ? currentPhone : ''
                                }
                            />
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-4 col-lg-3 ms-auto mb-3">
                            <ButtonBasic
                                text="Cancel"
                                className="btn-outline-primary w-100"
                                type="button"
                                onClick={() => {
                                    navigate('/');
                                }}
                            />
                        </div>
                        <div className="col-md-4 col-lg-3 mb-3">
                            <ButtonBasic
                                id="btnSubmit"
                                text="Save Changes"
                                className="btn-primary w-100"
                                type="button"
                                onClick={() =>
                                    errors.email == null &&
                                    errors.name == null &&
                                    setModal({ ...modal, caution: true })
                                }
                            />
                        </div>
                    </div>
                </form>
            </Card>
            <ModalDashboard
                modal={modal.caution}
                setModal={setModal}
                variant="info"
                type="caution"
                title="Save Changes?"
                body="Are you sure want to save this changes?"
                withCancel
                onConfirm={handleSubmit(onSubmit)}
                loading={updateCurrentProfileState.loading}
            />
            <ModalDashboard
                modal={modal.success}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body="Successfully save change"
                onConfirm={() => {
                    setModal({ ...modal, success: false });
                }}
            />
            <ModalDashboard
                modal={modal.cautionEmail}
                setModal={setModal}
                variant="info"
                type="caution"
                title="Save Changes?"
                body="Are you sure want to resend your email verification?"
                withCancel
                onConfirm={() => dispatch(resendEmailVerification())}
                loading={resendEmailVerificationState.loading}
            />
            <ModalDashboard
                modal={modal.cautionPhone}
                setModal={setModal}
                variant="info"
                type="caution"
                title="Save Changes?"
                body="Are you sure want to verify your phone?"
                withCancel
                onConfirm={() => dispatch(sendPhoneVerification())}
                loading={sendPhoneVerificationState.loading}
            />
            <ModalDashboard
                modal={modal.phone}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body="Successfully verify your phone number"
                onConfirm={() => {
                    setModal({ ...modal, phone: false });
                }}
            />
            <ModalDashboard
                modal={modal.resend}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body="Successfully resend your email verification"
                onConfirm={() => {
                    setModal({ ...modal, resend: false });
                }}
            />

            <ModalOtp
                modal={modal.otp}
                setModal={setModal}
                phoneNumber={currentProfileState?.response?.phone}
                state="changeNumber"
            />
        </>
    );
}
