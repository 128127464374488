import { Fragment } from 'react';
import { Offcanvas } from 'react-bootstrap';

import ConAppLogo from '@/assets/svg/new_conapp_logo_transaprent.webp';

import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';

import styled from 'styled-components';

interface StepsProps {
    component?: JSX.Element;
    title?: string;
    subtitle?: string;
}

interface VerticalStepperProps {
    steps: StepsProps[];
    onFinish: () => void;
    onNext: () => void;
    onPrev: () => void;
    activeStep: number;
    setActiveStep: (activeStep: number) => void;
    loading?: boolean;
}

interface OffCanvasProps {
    steps: StepsProps[];
    scroll: boolean;
    backdrop: boolean;
    activeStep: number;
}

function OffCanvas({ steps, activeStep, ...props }: OffCanvasProps): JSX.Element {
    return (
        <CustomCanvas show={true} {...props}>
            <Offcanvas.Header className="ms-lg-5 mt-2 pt-lg-5">
                <Offcanvas.Title>
                    <img src={ConAppLogo} alt="logo" className="img-fluid" width={250} />
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="responsive">
                {steps?.map((step, index) => (
                    <StepContainer key={index + 1} active={index === activeStep}>
                        <div className="d-flex align-items-center my-md-5 ms-lg-5">
                            <div
                                className="number"
                                style={{
                                    backgroundColor: activeStep > index ? '#5648FB' : '',
                                }}
                            >
                                <span
                                    className="count"
                                    style={{
                                        color: activeStep > index ? '#fff' : '',
                                    }}
                                >
                                    {index + 1}
                                </span>
                            </div>
                            <div className="ms-3">
                                <p className="title">{step.title}</p>
                                <p className="subtitle">{step.subtitle}</p>
                            </div>
                        </div>
                    </StepContainer>
                ))}
            </Offcanvas.Body>
        </CustomCanvas>
    );
}

/**
 * Function component to vertical stepper
 *
 * @param {array} steps
 * @param {() => void} onFinish
 * @param {() => void} onNext
 * @return {JSX.Element}
 */
export default function VerticalStepper({
    steps,
    onFinish,
    onNext,
    onPrev,
    activeStep,
    setActiveStep,
    loading,
}: VerticalStepperProps): JSX.Element {
    return (
        <Fragment>
            <OffCanvas
                steps={steps}
                scroll={true}
                backdrop={false}
                activeStep={activeStep}
            />

            {steps != null ? steps[activeStep].component : null}

            <ButtonWrapper className="row mt-5 mx-0 mx-md-5">
                <div className="col-md-6 col-lg-4 col-xxl-4 ms-auto">
                    <div className="d-flex justify-content-end">
                        <ButtonBasic
                            type="button"
                            text="Previous"
                            className="my-3 w-100 mx-1 btn-outline-primary"
                            onClick={onPrev}
                            disabled={activeStep === 0}
                        />
                        <ButtonBasic
                            type="button"
                            text="Next"
                            className="my-3 w-100 mx-1 btn-primary"
                            onClick={onNext}
                            disabled={activeStep === steps.length - 1}
                        />
                        <ButtonBasic
                            type="button"
                            text="Finish"
                            className="my-3 w-100 mx-1 btn-outline-success"
                            onClick={onFinish}
                            disabled={activeStep !== steps.length - 1}
                            loading={loading}
                        />
                    </div>
                </div>
            </ButtonWrapper>
        </Fragment>
    );
}

const ButtonWrapper = styled.div`
    background: #ffff;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
`;

const StepContainer = styled.div<{ active: boolean }>`
    margin-bottom: 1rem;
    .number {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background-color: ${props => (props.active ? '#5648FB' : '#ffff')};
        border: 1px solid #ffff;
        animation: ${props => (props.active ? 'fill-bg 1s ease forwards' : 'none')};
        @webkit-animation: ${props =>
            props.active ? 'fill-bg 1s ease forwards' : 'none'};

        @keyframes fill-bg {
            0% {
                background-color: #ffff;
            }
            25% {
                background-color: #c5b9f9;
            }
            50% {
                background-color: #8f87ee;
            }
            100% {
                background-color: #5648fb;
            }
        }

        @-webkit-keyframes fill-bg {
            0% {
                background-color: #ffff;
            }
            25% {
                background-color: #c5b9f9;
            }
            50% {
                background-color: #8f87ee;
            }
            100% {
                background-color: #5648fb;
            }
        }
    }

    .count {
        color: ${props => (props.active ? '#fff' : '#838383')};
        font-size: 20px;
        font-weight: 600;
        position: relative;
        top: 10px;
        left: 15px;
        width: 20px;
        display: flex;
        justify-content: center;

        animation: ${props =>
            props.active ? 'fill 0.5s ease forwards, scale 0.5s ease forwards' : 'none'};
        @webkit-animation: ${props =>
            props.active ? 'fill 0.5s ease forwards, scale 0.5s ease forwards' : 'none'};

        @keyframes fill {
            0% {
                width: 0;
                height: 0;
                border-radius: 50%;
                opacity: 0;
            }
            100% {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                opacity: 1;
            }
        }

        @keyframes scale {
            0% {
                transform: scale(0);
            }
            100% {
                transform: scale(1);
            }
        }

        @-webkit-keyframes fill {
            0% {
                width: 0;
                height: 0;
                border-radius: 50%;
                opacity: 0;
            }
            100% {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                opacity: 1;
            }
        }

        @-webkit-keyframes scale {
            0% {
                transform: scale(0);
            }
            100% {
                transform: scale(1);
            }
        }
    }

    .title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
    }

    .subtitle {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 0;
    }

    @media (max-width: 569px) {
        .title,
        .subtitle {
            display: ${props => (props.active ? 'block' : 'none')};
        }

        display: ${props => (props.active ? 'block' : 'none')};
        left: 20px;
    }

    @media (max-width: 767px) {
        .title,
        .subtitle {
            display: ${props => (props.active ? 'block' : 'none')};
        }
    }
`;

const CustomCanvas = styled(Offcanvas)`
    width: 30% !important;

    @media (max-width: 569px) {
        height: 10rem !important;
        .responsive {
            display: flex;
            justify-content: start !important;
        }
    }

    @media (max-width: 767px) {
        width: 100% !important;
        height: 10rem;

        .responsive {
            display: flex;
            justify-content: space-around;
        }
    }
`;

export const HeaderStepper = styled.header`
    @media (max-width: 569px) {
        display: none;
    }

    @media (max-width: 767px) {
        display: none;
    }
`;

export const BodyStepper = styled.section`
    @media (max-width: 569px) {
        margin-top: 12rem;
    }

    @media (max-width: 767px) {
        margin-top: 12rem;
    }
`;
