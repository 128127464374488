export const SETTING_COMPANY_REQUEST = 'SETTING_COMPANY_REQUEST';
export const SETTING_COMPANY_SUCCESS = 'SETTING_COMPANY_SUCCESS';
export const SETTING_COMPANY_FAIL = 'SETTING_COMPANY_FAIL';

export const PENDING_INHOUSE_REQUEST = 'PENDING_INHOUSE_REQUEST';
export const PENDING_INHOUSE_SUCCESS = 'PENDING_INHOUSE_SUCCESS';
export const PENDING_INHOUSE_FAIL = 'PENDING_INHOUSE_FAIL';

export const ACTIVE_INHOUSE_REQUEST = 'ACTIVE_INHOUSE_REQUEST';
export const ACTIVE_INHOUSE_SUCCESS = 'ACTIVE_INHOUSE_SUCCESS';
export const ACTIVE_INHOUSE_FAIL = 'ACTIVE_INHOUSE_FAIL';

export const INACTIVE_INHOUSE_REQUEST = 'INACTIVE_INHOUSE_REQUEST';
export const INACTIVE_INHOUSE_SUCCESS = 'INACTIVE_INHOUSE_SUCCESS';
export const INACTIVE_INHOUSE_FAIL = 'INACTIVE_INHOUSE_FAIL';

export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL';
export const UPDATE_COMPANY_RESET = 'UPDATE_COMPANY_RESET';

export const UPDATE_COMPANY_PICTURE_REQUEST = 'UPDATE_COMPANY_PICTURE_REQUEST';
export const UPDATE_COMPANY_PICTURE_SUCCESS = 'UPDATE_COMPANY_PICTURE_SUCCESS';
export const UPDATE_COMPANY_PICTURE_FAIL = 'UPDATE_COMPANY_PICTURE_FAIL';
export const UPDATE_COMPANY_PICTURE_RESET = 'UPDATE_COMPANY_PICTURE_RESET';

export const ACTIVATED_INHOUSE_REQUEST = 'ACTIVATED_INHOUSE_REQUEST';
export const ACTIVATED_INHOUSE_SUCCESS = 'ACTIVATED_INHOUSE_SUCCESS';
export const ACTIVATED_INHOUSE_FAIL = 'ACTIVATED_INHOUSE_FAIL';
export const ACTIVATED_INHOUSE_RESET = 'ACTIVATED_INHOUSE_RESET';

export const TERMINATED_INHOUSE_REQUEST = 'TERMINATED_INHOUSE_REQUEST';
export const TERMINATED_INHOUSE_SUCCESS = 'TERMINATED_INHOUSE_SUCCESS';
export const TERMINATED_INHOUSE_FAIL = 'TERMINATED_INHOUSE_FAIL';
export const TERMINATED_INHOUSE_RESET = 'TERMINATED_INHOUSE_RESET';

export const DETAIL_INHOUSE_REQUEST = 'DETAIL_INHOUSE_REQUEST';
export const DETAIL_INHOUSE_SUCCESS = 'DETAIL_INHOUSE_SUCCESS';
export const DETAIL_INHOUSE_FAIL = 'DETAIL_INHOUSE_FAIL';
export const DETAIL_INHOUSE_RESET = 'DETAIL_INHOUSE_RESET';

export const INVITED_INHOUSE_REQUEST = 'INVITE_INHOUSE_REQUEST';
export const INVITED_INHOUSE_SUCCESS = 'INVITE_INHOUSE_SUCCESS';
export const INVITED_INHOUSE_FAIL = 'INVITE_INHOUSE_FAIL';
export const INVITED_INHOUSE_RESET = 'INVITE_INHOUSE_RESET';

export const RESEND_INVITATION_REQUEST = 'RESEND_INVITATION_REQUEST';
export const RESEND_INVITATION_SUCCESS = 'RESEND_INVITATION_SUCCESS';
export const RESEND_INVITATION_FAIL = 'RESEND_INVITATION_FAIL';

export const CANCEL_INVITATION_REQUEST = 'CANCEL_INVITATION_REQUEST';
export const CANCEL_INVITATION_SUCCESS = 'CANCEL_INVITATION_SUCCESS';
export const CANCEL_INVITATION_FAIL = 'CANCEL_INVITATION_FAIL';

export const CLEAR_STATE = 'CLEAR_STATE';

const initialState = {
    loading: false,
    response: null,
    error: null,
    status: null,
};

export const allPendingInhouseReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case PENDING_INHOUSE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case PENDING_INHOUSE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case PENDING_INHOUSE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const settingCompanyReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case SETTING_COMPANY_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case SETTING_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case SETTING_COMPANY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const allActiveInhouseReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ACTIVE_INHOUSE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ACTIVE_INHOUSE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ACTIVE_INHOUSE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const allInactiveInhouseReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case INACTIVE_INHOUSE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case INACTIVE_INHOUSE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case INACTIVE_INHOUSE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const detailInhouseReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DETAIL_INHOUSE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_INHOUSE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DETAIL_INHOUSE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case DETAIL_INHOUSE_RESET:
            return {};

        default:
            return state;
    }
};

export const updateCompanyReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case UPDATE_COMPANY_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case UPDATE_COMPANY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case UPDATE_COMPANY_RESET:
            return initialState;

        default:
            return state;
    }
};

export const updateCompanyPictureReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case UPDATE_COMPANY_PICTURE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_COMPANY_PICTURE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case UPDATE_COMPANY_PICTURE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case UPDATE_COMPANY_PICTURE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const activatedInhouseReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ACTIVATED_INHOUSE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ACTIVATED_INHOUSE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ACTIVATED_INHOUSE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ACTIVATED_INHOUSE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const terminatedInhouseReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case TERMINATED_INHOUSE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case TERMINATED_INHOUSE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case TERMINATED_INHOUSE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case TERMINATED_INHOUSE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const invitedInhouseReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case INVITED_INHOUSE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case INVITED_INHOUSE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case INVITED_INHOUSE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case INVITED_INHOUSE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const resendInvitationReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case RESEND_INVITATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case RESEND_INVITATION_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case RESEND_INVITATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const cancelInvitationReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case CANCEL_INVITATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CANCEL_INVITATION_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case CANCEL_INVITATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};
