import { axiosClient } from '@/services/GatewayService';
import { AppDispatch } from '@/redux/store';

import {
    ALL_PROJECT_HISTORY_REQUEST,
    ALL_PROJECT_HISTORY_SUCCESS,
    ALL_PROJECT_HISTORY_FAIL,
    DETAIL_PROJECT_HISTORY_REQUEST,
    DETAIL_PROJECT_HISTORY_SUCCESS,
    DETAIL_PROJECT_HISTORY_FAIL,
} from '../reducers';

/**
 * Function to get all history of project
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param number params.per_page - number of project per page
 * @param string params.search - search of project
 * @param string params.sort_by - sort by of project
 * @param number params.sort_asc - sort asc of project
 * @return Promise<void>
 */
export const getAllProjectHistory = (params: {
    project_id?: string;
    page: number;
    per_page: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_PROJECT_HISTORY_REQUEST });
        try {
            const res = await axiosClient.get('project/histories', {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: ALL_PROJECT_HISTORY_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_PROJECT_HISTORY_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all history of project
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param number params.per_page - number of project per page
 * @param string params.search - search of project
 * @param string params.sort_by - sort by of project
 * @param number params.sort_asc - sort asc of project
 * @return Promise<void>
 */
export const getDetailHistory = (params: { project_id?: string; id: number }): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_PROJECT_HISTORY_REQUEST });
        try {
            const res = await axiosClient.get('project/history', {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: DETAIL_PROJECT_HISTORY_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DETAIL_PROJECT_HISTORY_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};
