import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

export default function LayoutGlobal(): JSX.Element {
    return (
        <Container>
            <Outlet />
        </Container>
    );
}

const Container = styled.section`
    padding: 20px;
    background-color: #5648fb;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    h1 {
        font-size: 2rem;
    }
`;
