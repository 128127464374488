import AccordionBasic from '@/components/molecules/AccordionBasic';
import HowToChat from './Sub/Chat/HowToChat';
import HowToCreateGroupChat from './Sub/Chat/HowToCreateGroupChat';

export default function HelpChat(): JSX.Element {
    const lists = [
        {
            id: 'howToChat',
            title: 'How to start chat with other user',
            content: <HowToChat />,
        },
        {
            id: 'howToGroupChat',
            title: 'How to create group chat',
            content: <HowToCreateGroupChat />,
        },
    ];

    return <AccordionBasic lists={lists} />;
}
