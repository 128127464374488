import { useEffect, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { createDirectMessage } from '@/routes/Dashboard/Chat/redux/actions';

import { toast } from 'react-toastify';
import { RootState } from '@/redux/store';
import { generateInitialImageUrl } from '@/utility/Utils';

interface ParamProps {
    show: boolean;
    setShow: any;
    handleClose: () => void;
}

export default function NewChat(params?: ParamProps): JSX.Element {
    const { show, setShow, handleClose } = params as any;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [inhouseList, setInhouseList] = useState([]);

    const allUserChatState = useSelector((state: RootState) => state.allUserChat);
    const newDirectMessageState = useSelector(
        (state: RootState) => state.newDirectMessage,
    );

    // Function to set inhouse list
    useEffect(() => {
        if (allUserChatState?.status === 200) {
            setInhouseList(allUserChatState?.response?.data);
        }
    }, [allUserChatState]);

    // Function to handle new direct message
    useEffect(() => {
        if (
            newDirectMessageState?.status === 200 &&
            newDirectMessageState?.isRedirected === true
        ) {
            const chId = newDirectMessageState?.response?.id;

            setShow((prev: any) => ({
                ...prev,
                list: false,
                new: false,
            }));
            navigate('/chat', {
                state: {
                    id: chId,
                },
            });
        }
    }, [newDirectMessageState]);

    /**
     * Function to handle change input
     *
     * @param e any
     * @returns void
     */
    const handleChange = (e: any): void => {
        const value = e.target.value;
        // filter inhouse list per string
        if (allUserChatState?.response?.data.length > 0) {
            const filtered = allUserChatState?.response?.data?.filter((item: any) => {
                return item.name.toLowerCase().includes(value.toLowerCase());
            });

            // if not found add new in top
            if (filtered.length === 0) {
                filtered.push({
                    name: value,
                    email: value,
                    company_name: 'Other Company',
                    avatar: generateInitialImageUrl(value),
                });
            }

            return setInhouseList(filtered);
        }

        const data = [];

        data.push({
            name: value,
            email: value,
            company_name: 'Other Company',
            avatar: generateInitialImageUrl(value),
        });

        return setInhouseList(data as []);
    };

    /**
     * Function to handle new direct message
     *
     * @param email string
     * @returns any
     */
    const handleNewDirectMessage = (email: string): any => {
        // if not email
        if (!email.includes('@')) {
            return toast.error('enter valid email');
        }

        const payload = new FormData();
        payload.append('interlocutor_email', email);

        dispatch(createDirectMessage(payload));

        dispatch({
            type: 'NEW_DIRECT_REDIRECTED',
            payload: true,
        });
    };

    return (
        <NewMessageCanvas
            show={show}
            onHide={() =>
                setShow((prev: any) => ({
                    ...prev,
                    new: false,
                }))
            }
            placement="end"
            // backdrop={false}
            style={{
                borderBottomLeftRadius: '20px',
                borderTopLeftRadius: '20px',
                width: '350px',
            }}
        >
            <Offcanvas.Header className="chat-header pb-0 flex-column" closeButton>
                <h6 className="fw-semibold">New Direct Message</h6>
                <input
                    type="text"
                    placeholder="Search username or company..."
                    className="form-control search-chat mt-3"
                    onChange={event => handleChange(event)}
                />
                {/* <BiSearch
                            className="search-icon"
                            color="rgba(131, 131, 131, 1)"
                            size={20}
                        /> */}
            </Offcanvas.Header>
            <Offcanvas.Body className="chat-body px-0">
                <div className="wrapper-list px-3">
                    {inhouseList.map((list: any, index: number) => (
                        <div
                            key={index}
                            className="chat-list d-flex align-items-center"
                            onClick={() => handleNewDirectMessage(list.email)}
                        >
                            <NewProfilePicture
                                src={list.avatar ?? generateInitialImageUrl(list.name)}
                                alt="avatar"
                                className=""
                            />
                            <div className="d-flex flex-column justify-content-center ms-2">
                                <NameList className="chat-name mb-1" status={0}>
                                    {list.name}
                                </NameList>
                                <MessageList className="chat-message mb-0" status={0}>
                                    {list.company_name}
                                </MessageList>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="bottom-link text-center">
                    <Link to="/chat" onClick={() => handleClose()}>
                        Go to full page
                    </Link>
                </div>
            </Offcanvas.Body>
        </NewMessageCanvas>
    );
}

const NewProfilePicture = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50px;
    object-fit: cover;
    object-position: center;
    border: none;
`;

const NewMessageCanvas = styled(Offcanvas)`
    .chat-header {
        justify-content: space-around;

        .dropdown-item {
            font-size: 13px;
            font-weight: 500;
            padding: 0.5rem;

            &:hover {
                background-color: #edecfe;
                color: rgba(86, 72, 251, 1);
                border-radius: 5px;

                .dropdown-active {
                    background-color: rgba(86, 72, 251, 1);
                }
            }
        }

        .dropdown-menu {
            border: 1px solid rgba(131, 131, 131, 0.2);
            padding: 0.5rem;
        }
    }

    .chat-body {
        justify-content: space-around;
        /* overflow-y: unset; */
        /* padding-bottom: 5rem; */
    }

    .chat-name {
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 222px;
    }

    .chat-job {
        font-size: 13px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 175px;
    }

    .chat-company {
        font-size: 13px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 175px;
    }

    .chat-count {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        background-color: #5648fb;
        color: #fff;
        font-size: 13px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .chat-list {
        border-bottom: 1px solid #e5e5e5;
        padding: 0.5rem 0;
        cursor: pointer;

        &:hover {
            background-color: #f5f5f5;
        }
    }

    .chat-message {
        font-size: 13px;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 220px;
    }

    .icon-new-chat {
        width: 23px;
        height: 23px;
        vertical-align: top;
    }

    .btn-close {
        position: absolute;
        left: 1rem;
        top: 1.3rem;
        font-size: 13px;
    }

    .search-icon {
        position: absolute;
        top: 4.7rem;
        right: 1.7rem;
    }

    .online-circle {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        background-color: rgba(6, 189, 128, 1);
        position: absolute;
        left: 55px;
        border: 3px solid #fff;
        margin-top: 35px;
    }

    .search-chat {
        height: 45px;
        border-radius: 50px;
        border: 1.132px solid rgba(131, 131, 131, 0.1);
        background-color: #ffff;

        &:focus {
            box-shadow: none;
        }

        &::placeholder {
            font-size: 13px;
            font-weight: 400;
        }

        &,
        .form-control {
            padding: 0 2.3rem 0 1rem;
        }
    }

    .bottom-link {
        font-size: 13px;
        font-weight: 500;
        margin-top: 1rem;
    }

    .wrapper-list {
        overflow-y: scroll;
        height: 90%;

        // hidden scrollbar
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    }
`;

const NameList = styled.h6<{ status: number }>`
    font-size: 14px;
    font-weight: ${props => (props.status === 0 ? '400' : '500')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 222px;
`;

const MessageList = styled.p<{ status: number }>`
    font-size: 12px;
    color: ${props => (props.status === 0 ? 'rgba(131, 131, 131, 1)' : '#000')};
    font-weight: ${props => (props.status === 0 ? '400' : '500')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 220px;
`;
