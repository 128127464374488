import { axiosClient } from '@/services/GatewayService';
import { AppDispatch } from '@/redux/store';

import {
    ADVERTISEMENT_GENERAL_DASHBOARD_REQUEST,
    ADVERTISEMENT_GENERAL_DASHBOARD_SUCCESS,
    ADVERTISEMENT_GENERAL_DASHBOARD_FAIL,
    ADVERTISEMENT_PROJECT_DASHBOARD_REQUEST,
    ADVERTISEMENT_PROJECT_DASHBOARD_SUCCESS,
    ADVERTISEMENT_PROJECT_DASHBOARD_FAIL,
    ADVERTISEMENT_LANDING_REQUEST,
    ADVERTISEMENT_LANDING_SUCCESS,
    ADVERTISEMENT_LANDING_FAIL,
    WEATHER_REQUEST,
    WEATHER_SUCCESS,
    WEATHER_FAIL,
    IP_REQUEST,
    IP_SUCCESS,
    IP_FAIL,
    CLEAR_STATE,
} from '../reducers';
import axios from 'axios';

/**
 * Function to get background in general dashboard
 *
 * @return Promise<void>
 */
export const getWallpaperGeneralDashboard = (ip: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ADVERTISEMENT_GENERAL_DASHBOARD_REQUEST });
        try {
            const res = await axiosClient.get('advertisement/general-dashboard', {
                params: {
                    ip,
                },
            });

            dispatch({
                type: ADVERTISEMENT_GENERAL_DASHBOARD_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ADVERTISEMENT_GENERAL_DASHBOARD_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get background in project dashboard
 *
 * @return Promise<void>
 */
export const getWallpaperProjectDashboard = (ip: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ADVERTISEMENT_PROJECT_DASHBOARD_REQUEST });
        try {
            const res = await axiosClient.get('advertisement/project-summary', {
                params: {
                    ip,
                },
            });

            dispatch({
                type: ADVERTISEMENT_PROJECT_DASHBOARD_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ADVERTISEMENT_PROJECT_DASHBOARD_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get background in landing page or login form
 *
 * @return Promise<void>
 */
export const getWallpaperLanding = (ip: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ADVERTISEMENT_LANDING_REQUEST });
        try {
            const res = await axiosClient.get('advertisement/landing-page', {
                params: {
                    ip,
                },
            });

            dispatch({
                type: ADVERTISEMENT_LANDING_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ADVERTISEMENT_LANDING_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get ip address
 *
 * @returns
 */
export const getIpAddress = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: IP_REQUEST });
        try {
            const res = await axios.get('https://api-bdc.net/data/client-ip');

            dispatch({
                type: IP_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: IP_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get weather in landing page or login form
 *
 * @return Promise<void>
 */
export const getWeather = (ip: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: WEATHER_REQUEST });
        try {
            const res = await axiosClient.get('user/weather', {
                params: {
                    ip,
                    user_agent: navigator.userAgent,
                },
            });

            dispatch({
                type: WEATHER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: WEATHER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

export const clearState = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CLEAR_STATE });
    };
};

/**
 * Function for handle laravel vapor to aws s3
 *
 * @param file
 * @param options
 * @returns
 */
export const storeVaporFile = async (
    file: any,
    options: {
        bucket: string;
        visibility?: string;
        contentType?: string;
        expires?: string;
        progress: (progress: any) => void;
    },
): Promise<any> => {
    const response = await axiosClient.post('/vapor/signed-storage-url', {
        // bucket: options.bucket,
        content_type: options.contentType ?? file.type,
        expires: options.expires ?? '',
    });

    if (typeof options.progress === 'undefined') {
        options.progress = () => {};
    }

    // This is the fix for the headers. Instance just for the S3 PUT
    const instance = axios.create();

    instance.defaults.headers.common = {};

    await instance.put(response.data.url, file, {
        headers: response.data.headers,
        onUploadProgress: (progressEvent: any) => {
            options.progress(progressEvent.loaded / progressEvent.total);
        },
    });

    response.data.extension = file.name.split('.').pop();

    return response.data;
};
