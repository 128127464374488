import { Fragment, MutableRefObject } from 'react';

import BuildingIcons from '@/assets/svg/icons/building.svg';
import UserIcons from '@/assets/svg/icons/user-alt-1.svg';
import MailIcons from '@/assets/svg/icons/mail-alt-3.svg';

import InputBasic from '@/components/atoms/Inputs/InputBasic';
import LinkBack from '@/components/molecules/LinkBack';

import { HeaderContent } from '@/components/molecules/Typography';
import { HeaderStepper, BodyStepper } from '@/components/molecules/VerticalStepper';

import InputPicture from '../InputPicture';
import { Controller } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import InputPhoneNew from '@/components/atoms/Inputs/InputPhoneNew';
// import AutoComplete from '@/components/organism/GoogleMap/AutoComplete';
import MiniMaps from '@/components/organism/MapBox/MiniMaps';
import { MapRef } from 'react-map-gl';

export default function CompanyDetails(params: {
    register: any;
    errors: any;
    control: any;
    defaultMap: { lat: number; lng: number; zoom: number; address: string };
    setDefaultMap: (value: {
        lat: number;
        lng: number;
        zoom: number;
        address: string;
    }) => void;
    mapRef: MutableRefObject<MapRef | undefined>;
}): JSX.Element {
    const { register, errors, control, defaultMap, setDefaultMap, mapRef } = params;

    return (
        <Fragment>
            <HeaderStepper className="row fade-in mt-5">
                <div className="col-md-4 ms-auto mt-2 mb-5">
                    <HeaderContent
                        text="Personal Details"
                        subtext="Enter your personal details to create account"
                    />
                </div>
                <div className="col-md-4 text-end">
                    <LinkBack text="Return to home screen" />
                </div>
            </HeaderStepper>
            <BodyStepper className="row fade-in">
                <div className="col-md-4 mx-auto">
                    <InputPicture
                        payload="user_avatar"
                        title="Upload your profile picture (Optional)"
                    />
                    <InputBasic
                        id="fullName"
                        type="text"
                        placeholder="Full Name"
                        outerClassName="my-3"
                        icon={UserIcons}
                        rules={{
                            function: register,
                            name: 'user_name',
                            rules: {
                                required: 'Name is required',
                            },
                            errors,
                        }}
                    />
                    <InputBasic
                        id="emailAddress"
                        type="text"
                        placeholder="Email Address"
                        outerClassName="mb-3"
                        icon={MailIcons}
                        disabled
                        rules={{
                            function: register,
                            name: 'user_email',
                            rules: {
                                required: 'Email Address is required',
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: 'Entered value does not match email format',
                                },
                            },
                            errors,
                        }}
                    />
                    <InputBasic
                        id="companyName"
                        type="text"
                        placeholder="Company Name"
                        outerClassName="my-3"
                        icon={BuildingIcons}
                        rules={{
                            function: register,
                            name: 'company_name',
                            rules: {
                                required: 'Company Name is required',
                            },
                            errors,
                        }}
                    />
                    <Controller
                        control={control}
                        name="user_phone"
                        rules={{
                            required: 'Phone Number is required',
                            validate: {
                                validPhone: (value: string) =>
                                    isValidPhoneNumber(value) || 'Invalid phone number',
                            },
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <InputPhoneNew
                                id="user_phone"
                                placeholder="Phone Number"
                                country="AU"
                                onChange={onChange}
                                value={value}
                                customRef={ref}
                            />
                        )}
                    />
                    {Boolean(errors.user_phone) && (
                        <span
                            className="invalid-feedback d-block text-start"
                            style={{
                                fontSize: '0.75rem',
                            }}
                        >
                            {errors.user_phone.message}
                        </span>
                    )}
                    <MiniMaps
                        defaultMap={defaultMap}
                        setDefaultMap={setDefaultMap}
                        withRef={mapRef}
                    />
                </div>
            </BodyStepper>
        </Fragment>
    );
}
