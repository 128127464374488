import styled from 'styled-components';
import { ThreeDots } from 'react-loader-spinner';

interface CardProps {
    children?: any;
    classOuter?: string;
    classBody?: string;
    loading?: boolean;
    style?: any;
}

export default function Card({
    children,
    classBody,
    classOuter,
    loading,
    style,
}: CardProps): JSX.Element {
    return (
        <WrapperCard className={`card ${classOuter ?? 'p-4'}`} style={style}>
            {loading !== undefined && loading ? (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: '50vh' }}
                >
                    {/* <h4 className="fw-semibold me-3">Loading</h4> */}
                    <ThreeDots
                        height="80"
                        width="80"
                        radius="9"
                        color="#5648FB"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        // wrapperClassName=""
                        visible={true}
                    />
                </div>
            ) : (
                <div className={`card-body ${classBody ?? ''}`}>{children}</div>
            )}
        </WrapperCard>
    );
}

const WrapperCard = styled.div`
    border-radius: 10px;
    background-color: #ffff;
    .card-header {
        background-color: #ffff;
        border: none;
    }
`;
