export const chatTypeEnums = [
    {
        id: 1,
        name: 'General chat',
        color: '#2DCD81',
    },
    {
        id: 2,
        name: 'Site instruction',
        color: '#3050F8',
    },
    {
        id: 3,
        name: 'Variation',
        color: '#9029F8',
    },
    {
        id: 4,
        name: 'Request for information',
        color: '#F53D3D',
    },
    {
        id: 5,
        name: 'Request for quotation',
        color: '#F8C615',
    },
];

export const filterTypeEnums = [
    {
        id: 1,
        label: 'General chat',
        value: 1,
        color: '#2DCD81',
    },
    {
        id: 2,
        label: 'Site instruction',
        value: 2,
        color: '#3050F8',
    },
    {
        id: 3,
        label: 'Variation',
        value: 3,
        color: '#9029F8',
    },
    {
        id: 4,
        label: 'Request for information',
        value: 4,
        color: '#F53D3D',
    },
    {
        id: 5,
        label: 'Request for quotation',
        value: 5,
        color: '#F8C615',
    },
];
