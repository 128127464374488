import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';

import ModalAction from '@/components/atoms/Modals/ModalAction';

import { Placeholder } from 'react-bootstrap';
import { Dispatch, SetStateAction } from 'react';
import moment from 'moment';
import { formatDate } from '@/utility/Utils';

export default function InformationFolder({
    modal,
    setModal,
}: {
    modal: boolean;
    setModal: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
    const detailDrawingState = useSelector((state: RootState) => state.detailDrawing);
    const historyDrawingState = useSelector((state: RootState) => state.historyDrawing);

    return (
        <ModalAction
            modal={modal}
            setModal={setModal}
            aria-labelledby="contained-modal-title-vcenter"
            title="Folder Information"
            size="md"
        >
            <>
                {detailDrawingState?.loading !== false ? (
                    <div
                        className="row text-start px-4 mt-3"
                        style={{
                            fontSize: 14,
                        }}
                    >
                        <div className="col-12 col-md-4">
                            <p
                                className="mb-0"
                                style={{
                                    wordBreak: 'break-all',
                                }}
                            >
                                Name
                            </p>
                        </div>
                        <div className="col-12 col-md-7">
                            : <Placeholder xs={10} />
                        </div>

                        <div className="col-12 col-md-4">
                            <p
                                className="mb-0"
                                style={{
                                    wordBreak: 'break-all',
                                }}
                            >
                                Description
                            </p>
                        </div>
                        <div className="col-12 col-md-7">
                            : <Placeholder xs={10} />
                        </div>

                        <div className="col-12 col-md-4">
                            <p className="mb-0">Size</p>
                        </div>
                        <div className="col-12 col-md-7">
                            : <Placeholder xs={10} />
                        </div>

                        <div className="col-12 col-md-4">
                            <p className="mb-0">Created By</p>
                        </div>
                        <div className="col-12 col-md-7">
                            : <Placeholder xs={10} />
                        </div>

                        <div className="col-12 col-md-4">
                            <p className="mb-0">Created At</p>
                        </div>
                        <div className="col-12 col-md-7">
                            : <Placeholder xs={10} />
                        </div>

                        <div className="col-12 col-md-4">
                            <p className="mb-0">Updated At</p>
                        </div>
                        <div className="col-12 col-md-7">
                            : <Placeholder xs={10} />
                        </div>
                    </div>
                ) : (
                    <div
                        className="row text-start px-4 mt-3"
                        style={{
                            fontSize: 14,
                        }}
                    >
                        <div className="col-12 col-md-4">
                            <p className="mb-0">Name</p>
                        </div>
                        <div className="col-12 col-md-7 mb-3 mb-md-0">
                            <p className="mb-0">
                                <span className="d-none d-md-inline">:&nbsp;</span>
                                {detailDrawingState?.response?.name}
                            </p>
                        </div>

                        <div className="col-12 col-md-4">
                            <p className="mb-0">Description</p>
                        </div>
                        <div className="col-12 col-md-7 mb-3 mb-md-0">
                            <p className="mb-0">
                                <span className="d-none d-md-inline">:&nbsp;</span>

                                {detailDrawingState?.response?.description !== null
                                    ? detailDrawingState?.response?.description
                                    : '-'}
                            </p>
                        </div>

                        <div className="col-12 col-md-4">
                            <p className="mb-0">Size</p>
                        </div>
                        <div className="col-12 col-md-7 mb-3 mb-md-0">
                            <p className="mb-0">
                                <span className="d-none d-md-inline">:&nbsp;</span>

                                {detailDrawingState?.response?.size ?? '0 kb'}
                            </p>
                        </div>

                        <div className="col-12 col-md-4">
                            <p className="mb-0">Created By</p>
                        </div>
                        <div className="col-12 col-md-7 mb-3 mb-md-0">
                            <p className="mb-0">
                                <span className="d-none d-md-inline">:&nbsp;</span>

                                {detailDrawingState?.response?.creator?.user_email ?? '-'}
                            </p>
                        </div>

                        <div className="col-12 col-md-4">
                            <p className="mb-0">Created At</p>
                        </div>
                        <div className="col-12 col-md-7 mb-3 mb-md-0">
                            <p className="mb-0">
                                <span className="d-none d-md-inline">:&nbsp;</span>

                                {moment(detailDrawingState?.response?.created_at).format(
                                    'DD MMMM YYYY',
                                )}
                            </p>
                        </div>

                        <div className="col-12 col-md-4">
                            <p className="mb-0">Updated At</p>
                        </div>
                        <div className="col-12 col-md-7 mb-3 mb-md-0">
                            <p className="mb-0">
                                <span className="d-none d-md-inline">:&nbsp;</span>

                                {moment(detailDrawingState?.response?.created_at).format(
                                    'DD MMMM YYYY',
                                )}
                            </p>
                        </div>
                    </div>
                )}

                {historyDrawingState?.loading !== false ? (
                    <div
                        className="row text-start px-4 mt-3"
                        style={{
                            fontSize: 14,
                        }}
                    >
                        <div className="col-12">
                            <p className="mb-0">History</p>
                        </div>
                        <div className="col-12">
                            <Placeholder xs={10} />
                            <Placeholder xs={10} />
                            <Placeholder xs={10} />
                        </div>
                    </div>
                ) : historyDrawingState.response?.data?.length === 0 ? (
                    <div
                        className="row text-start px-4 mt-3"
                        style={{
                            fontSize: 14,
                        }}
                    >
                        <div className="col-12">
                            <p className="mb-0">History</p>
                        </div>
                        <div className="col-12">
                            <p className="mb-0">No History</p>
                        </div>
                    </div>
                ) : (
                    <div
                        className="row text-start px-4 mt-3"
                        style={{
                            fontSize: 14,
                        }}
                    >
                        <div className="col-12">
                            <p className="mb-0">History</p>
                        </div>
                        <div className="col-12">
                            {historyDrawingState?.response?.data?.map(
                                (
                                    item: {
                                        description: string;
                                        created_at: number;
                                    },
                                    index: number,
                                ) => {
                                    return (
                                        <div key={index} className="row mb-3 mb-md-0">
                                            <p className="mb-0 col-md-9 order-2 order-md-1">
                                                {item?.description}
                                            </p>
                                            <p className="mb-0 col-md-3 order-1 order-md-2 text-md-end">
                                                {formatDate(item?.created_at)}
                                            </p>
                                        </div>
                                    );
                                },
                            )}
                        </div>
                    </div>
                )}
            </>
        </ModalAction>
    );
}
