import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import { Title } from '@/components/molecules/Typography';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AiOutlineSearch } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import TableAccordion from '@/components/organism/TableAccordion';
import {
    getAllProjectLocation,
    getAllProjectRangeValue,
    getAllProjectSector,
    getAllTenderBox,
    getAllTenderIntention,
    sendTender,
    clearSendTenderState,
} from '../redux/actions';
import { RootState } from '@/redux/store';
import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';
import { getCredential } from '@/utility/Utils';
import moment from 'moment';

interface ListProps {
    value: string;
    label: string;
}
export default function TenderBox(): JSX.Element {
    const dispatch = useDispatch();
    const credential = getCredential();

    const allTenderBoxState = useSelector((state: RootState) => state.allTenderBox);
    const allProjectLocationState = useSelector(
        (state: RootState) => state.allProjectLocation,
    );
    const allProjectSectorState = useSelector(
        (state: RootState) => state.allProjectSector,
    );
    const allProjectRangeValueState = useSelector(
        (state: RootState) => state.allProjectRangeValue,
    );
    const sendTenderState = useSelector((state: RootState) => state.sendTender);

    const [sectorLists, setSectorLists] = useState<ListProps[]>([]);
    const [locationLists, setLocationLists] = useState<ListProps[]>([]);
    const [rangeValueLists, setRangeValueLists] = useState<ListProps[]>([]);
    const [modal, setModal] = useState({
        requestTender: false,
        success: false,
    });
    const [modalData, setModalData] = useState({
        id: 0,
        name: '',
    });

    const [selectedQuote, setSelectedQuote] = useState<number>(0);
    const [searchValue, setSearchValue] = useState('');
    const [params, setParams] = useState({
        page: 1,
        per_page: 10,
        search: '',
        // min_value: 0,
        // max_value: 0,
        location: '',
        sector: '',
        sort_by: 'created_at',
        sort_asc: 0,
        range: '',
    });

    const sortByLists = [
        {
            value: 'created_at',
            label: 'Default',
        },
        {
            value: 'projects.name',
            label: 'Project Name',
        },
        {
            value: 'projects.location',
            label: 'Location',
        },
        {
            value: 'projects.value',
            label: 'Estimated Value',
        },
        {
            value: 'projects.sector',
            label: 'Sector',
        },
        {
            value: 'due_date',
            label: 'Due Date',
        },
        // {
        //     value: 'created_at',
        //     label: 'Created At',
        // },
    ];

    const columns = [
        {
            title: 'Project Name',
            style: {
                width: '150px',
            },
            Cell: (
                row: {
                    project: {
                        name: string;
                    };
                },
                index: number,
            ) => (
                <span key={index} className="">
                    {row.project.name}
                </span>
            ),
        },
        {
            title: 'Location',
            style: {
                width: '150px',
            },
            Cell: (
                row: {
                    project: {
                        location: string;
                    };
                },
                index: number,
            ) => (
                <span key={index} className="">
                    {row.project.location}
                </span>
            ),
        },
        {
            title: 'Estimated Value',
            style: {
                width: '200px',
            },
            Cell: (
                row: {
                    project: {
                        value: string;
                    };
                },
                index: number,
            ) => (
                <span key={index} className="">
                    {row.project.value}
                </span>
            ),
        },
        {
            title: 'Sector',
            style: {
                width: '150px',
            },
            Cell: (
                row: {
                    project: {
                        sector: string;
                    };
                },
                index: number,
            ) => <span key={index}>{row.project.sector}</span>,
        },
        {
            title: 'Due Date',
            style: {
                width: '150px',
            },
            // selector: 'due_date',
            Cell: (
                row: {
                    due_date: string;
                },
                index: number,
            ) => (
                <span key={index}>
                    {row?.due_date !== null
                        ? moment(row.due_date).format('DD-MM-YYYY')
                        : 'No Due Date'}
                </span>
            ),
        },
        {
            title: 'Request to Quote as',
            selector: 'request_to_quote_as',
            // style: {
            //     // width: '300px',
            //     textAlign: 'right',
            // },
            className: 'text-center',
            Cell: (
                row: {
                    id: string;
                    posted_by: {
                        email: string;
                        company_name: string;
                    };
                    user_status: string;
                },
                index: number,
                activeKey: string | null,
            ): JSX.Element =>
                row?.user_status === 'open' ? (
                    <>
                        {activeKey === row.id ? (
                            <div className="d-flex justify-content-end w-full">
                                <span
                                    className="fw-semibold"
                                    style={{
                                        fontSize: 14,
                                    }}
                                >
                                    {selectedQuote === 1
                                        ? 'Sub Contractor'
                                        : 'Principle Contractor'}
                                </span>
                            </div>
                        ) : (
                            <div className="d-flex justify-content-center gap-2">
                                <CustomButton onClick={() => setSelectedQuote(2)}>
                                    Principle Contractor
                                </CustomButton>
                                <CustomButton
                                    onClick={() => setSelectedQuote(2)}
                                    disabled
                                >
                                    Sub Contractor
                                </CustomButton>
                            </div>
                        )}
                    </>
                ) : (
                    <div
                        style={{
                            fontSize: 14,
                        }}
                    >
                        {handleStatus(
                            row.user_status,
                            row?.posted_by?.email === credential?.email,
                        )}
                    </div>
                ),
        },
    ];

    /**
     * Function to handle status
     *
     * @param status string
     * @param isOwner boolean
     * @returns ReactNode
     */
    const handleStatus = (status: string, isOwner: boolean): ReactNode => {
        if (isOwner) {
            return <span className="text-info">You are the owner of this project</span>;
        }

        switch (status) {
            case 'sent':
                return <span className="text-success">Your request has been sent</span>;
            case 'rejected':
                return (
                    <span className="text-danger">
                        Your request has been rejected, try sending it back tomorrow
                    </span>
                );
            case 'joined':
                return <span className="text-warning">You have joined this project</span>;
            default:
                return 'Open';
        }
    };

    // Function to get all tender intention
    useEffect(() => {
        dispatch(getAllTenderIntention());
        dispatch(getAllProjectSector());
        dispatch(getAllProjectLocation());
        dispatch(getAllProjectRangeValue());
    }, []);

    // Function to get all project sector
    useEffect(() => {
        if (allProjectSectorState?.status === 200) {
            const sectorList = allProjectSectorState?.response?.map((sector: string) => ({
                value: sector,
                label: sector,
            }));

            setSectorLists(sectorList);
        }
    }, [allProjectSectorState]);

    // Function to get all project location
    useEffect(() => {
        if (allProjectLocationState?.status === 200) {
            const locationList = allProjectLocationState?.response?.map(
                (location: string) => ({
                    value: location,
                    label: location,
                }),
            );

            setLocationLists(locationList);
        }
    }, [allProjectLocationState]);

    // Function to get all project range value
    useEffect(() => {
        if (allProjectRangeValueState?.status === 200) {
            const rangeValueList = allProjectRangeValueState?.response?.map(
                (rangeValue: string) => ({
                    value: rangeValue,
                    label: rangeValue,
                }),
            );

            setRangeValueLists(rangeValueList);
        }
    }, [allProjectRangeValueState]);

    // Function if send application tender success
    useEffect(() => {
        if (sendTenderState?.status === 200) {
            setModal(prev => ({
                ...prev,
                requestTender: false,
            }));

            setTimeout(() => {
                setModal((prev: any) => ({
                    ...prev,
                    success: true,
                }));

                dispatch(clearSendTenderState());
                dispatch(getAllTenderBox(params));
            }, 1000);
        }
    }, [sendTenderState]);

    /**
     * Function to handle search
     *
     * @param string value
     * @returns void
     */
    const handleSearch = useCallback((value: string): void => {
        setSearchValue(value);
    }, []);

    /**
     * Function to handle debounce search
     *
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleDebouncedSearch = useCallback(() => {
        setParams({
            ...params,
            search: searchValue,
            page: 1,
        });

        const payload = {
            ...params,
            search: searchValue,
            page: 1,
        };

        dispatch(getAllTenderBox(payload));
    }, [searchValue]);

    /**
     * Function to handle pagination
     *
     * @param number page
     * @param string type
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handlePagination = (page: number, type: string): void => {
        if (type === 'page') {
            setParams({
                ...params,
                page,
            });

            const payload = {
                ...params,
                page,
            };

            dispatch(getAllTenderBox(payload));
        }

        if (type === 'next') {
            setParams({
                ...params,
                page: params.page + 1,
            });

            const payload = {
                ...params,
                page: params.page + 1,
            };

            dispatch(getAllTenderBox(payload));
        }

        if (type === 'prev') {
            setParams({
                ...params,
                page: params.page - 1,
            });

            const payload = {
                ...params,
                page: params.page - 1,
            };

            dispatch(getAllTenderBox(payload));
        }
    };

    // Function for handle debounce search
    useEffect(() => {
        const debounce = setTimeout(() => {
            handleDebouncedSearch();
        }, 500);

        return () => {
            clearTimeout(debounce);
        };
    }, [handleDebouncedSearch]);

    /**
     * Function to handle filter location
     *
     * @param string value
     * @returns void
     */
    const handleFilterLocation = (value: string): void => {
        setParams({
            ...params,
            location: value,
            page: 1,
        });

        const payload = {
            ...params,
            location: value,
            page: 1,
        };

        dispatch(getAllTenderBox(payload));
    };

    /**
     * Function to handle filter sector
     *
     * @param string value
     * @returns void
     */
    const handleFilterSector = (value: string): void => {
        setParams({
            ...params,
            sector: value,
            page: 1,
        });

        const payload = {
            ...params,
            sector: value,
            page: 1,
        };

        dispatch(getAllTenderBox(payload));
    };

    /**
     * Function to handle filter sort by
     *
     * @param string value
     * @returns void
     */
    const handleFilterSortBy = (value: string): void => {
        setParams({
            ...params,
            sort_by: value,
            page: 1,
        });

        const payload = {
            ...params,
            sort_by: value,
            page: 1,
        };

        dispatch(getAllTenderBox(payload));
    };

    /**
     * Function to handle filter range value
     *
     * @param string value
     * @returns void
     */
    const handleFilterRangeValue = (value: string): void => {
        setParams({
            ...params,
            // min_value: value,
            // max_value: value,
            range: value,
            page: 1,
        });

        const payload = {
            ...params,
            // min_value: value,
            // max_value: value,
            range: value,
            page: 1,
        };

        dispatch(getAllTenderBox(payload));
    };

    /**
     * Function to handle request tender
     *
     * @param number id
     * @returns void
     */
    const handleRequestTender = (id: number): void => {
        const formData = new FormData();

        formData.append('tender_id', modalData.id.toString());

        dispatch(sendTender(formData));
    };

    /**
     * Function for handle open modal request tender
     *
     * @param row { id: number, project: { name: string }
     * @returns void
     */
    const handleOpenModalRequestTender = (row: {
        id: number;
        project: {
            name: string;
        };
    }): void => {
        setModalData({
            id: row.id,
            name: row?.project?.name,
        });

        setModal({
            ...modal,
            requestTender: true,
        });
    };

    /**
     * Function to handle sort asc
     *
     * @returns void
     */
    const handleSortAsc = (): void => {
        setParams({
            ...params,
            sort_asc: params?.sort_asc === 0 ? 1 : 0,
            page: 1,
        });

        const payload = {
            ...params,
            sort_asc: params?.sort_asc === 0 ? 1 : 0,
            page: 1,
        };

        dispatch(getAllTenderBox(payload));
    };

    return (
        <>
            <Title text="Tender Box" />

            <TableAccordion
                extraHeader={
                    <div className="row align-items-center mt-5 gap-3 gap-xl-0">
                        <div className="col-md-12 col-lg-12 col-xl-4">
                            <div
                                className="d-flex align-items-center"
                                style={{ paddingLeft: 14 }}
                            >
                                <AiOutlineSearch
                                    size={20}
                                    color="rgba(131, 131, 131, 1)"
                                />
                                <InputSearch
                                    type="text"
                                    className=""
                                    placeholder="Search"
                                    value={searchValue}
                                    onChange={e => handleSearch(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-8">
                            <div className="row gap-3 justify-content-center justify-content-md-end px-0">
                                <div className="col-auto">
                                    <InputSelect
                                        className="form-select"
                                        onChange={e =>
                                            handleFilterLocation(e.target.value)
                                        }
                                    >
                                        <option value="" selected disabled>
                                            Location
                                        </option>
                                        <option value="">All</option>
                                        {locationLists?.map((location, index) => (
                                            <option key={index} value={location.value}>
                                                {location.label}
                                            </option>
                                        ))}
                                    </InputSelect>
                                </div>
                                <div className="col-auto">
                                    <InputSelect
                                        className="form-select"
                                        onChange={e =>
                                            handleFilterRangeValue(e.target.value)
                                        }
                                    >
                                        <option value="" selected disabled>
                                            Value
                                        </option>
                                        <option value="">All</option>
                                        {rangeValueLists?.map((rangeValue, index) => (
                                            <option key={index} value={rangeValue.value}>
                                                {rangeValue.label}
                                            </option>
                                        ))}
                                    </InputSelect>
                                </div>
                                <div className="col-auto">
                                    <InputSelect
                                        className="form-select"
                                        onChange={e => handleFilterSector(e.target.value)}
                                    >
                                        <option value="" selected disabled>
                                            Sector
                                        </option>
                                        <option value="">All</option>
                                        {sectorLists?.map((sector, index) => (
                                            <option key={index} value={sector.value}>
                                                {sector.label}
                                            </option>
                                        ))}
                                    </InputSelect>
                                </div>
                                <div className="col-auto">
                                    <InputSelect
                                        className="form-select"
                                        onChange={e => handleFilterSortBy(e.target.value)}
                                    >
                                        <option value="" selected disabled>
                                            Sort By
                                        </option>
                                        {sortByLists.map((option, index) => (
                                            <option key={index} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </InputSelect>
                                </div>
                                <div className="col-auto align-self-center">
                                    <FilterButton>
                                        {params?.sort_asc === 0 ? (
                                            <svg
                                                width="28"
                                                height="28"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                role="button"
                                                onClick={handleSortAsc}
                                            >
                                                <path
                                                    d="M13 12H21M13 8H21M13 16H21M6 7V17M6 17L3 14M6 17L9 14"
                                                    stroke="currentColor"
                                                    strokeWidth="1"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                width="28"
                                                height="28"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                role="button"
                                                onClick={handleSortAsc}
                                            >
                                                <path
                                                    d="M13 12H21M13 8H21M13 16H21M6 7V17M6 7L3 10M6 7L9 10"
                                                    stroke="currentColor"
                                                    strokeWidth="1"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        )}
                                    </FilterButton>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                columns={columns}
                items={allTenderBoxState?.response ?? []}
                handlePagination={handlePagination}
                loading={allTenderBoxState?.loading}
                tableName="Tender Box"
                collapseContent={row => {
                    return (
                        <>
                            <td
                                colSpan={columns?.length - 1}
                                className="px-3 pb-3"
                                style={{
                                    fontSize: 14,
                                    width: 'auto',
                                }}
                            >
                                <span className="fw-semibold">Project Details</span>
                                <p>{row.project_detail}</p>
                            </td>

                            <td
                                className="px-3 pb-3 text-end"
                                colSpan={1}
                                style={{
                                    fontSize: 14,
                                    width: 'auto',
                                }}
                            >
                                <span className="fw-semibold">Posted By</span>
                                <p className="">{row?.posted_by?.company_name}</p>
                                {row?.user_status === 'open' ? (
                                    <div className="text-end">
                                        <ButtonBasic
                                            text="Send Request"
                                            className="btn-success text-end"
                                            onClick={() =>
                                                handleOpenModalRequestTender(row)
                                            }
                                        />
                                    </div>
                                ) : null}
                            </td>
                        </>
                    );
                }}
            />

            <ModalDashboard
                modal={modal.requestTender}
                setModal={setModal}
                variant="info"
                type="resend"
                title="Principle Contractor"
                body={`Are you sure want to request to quote as Principle Contractor to ${modalData.name}?`}
                withCancel
                onConfirm={() => handleRequestTender(modalData.id)}
                loading={sendTenderState?.loading}
            />

            <ModalDashboard
                modal={modal.success}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body={`Successfully send request to ${modalData.name}!`}
                onConfirm={() => {
                    setModal({ ...modal, success: false });
                }}
            />
        </>
    );
}

const CustomButton = styled.button`
    background-color: #edebff;
    font-size: 13px;
    border-radius: 20px;
    color: #5648fb;
    border: none;
    padding: 8px 20px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        background-color: #4637e3;
        color: #fff;
    }

    &:disabled {
        background-color: #dddddd;
        color: #ffffff;
        pointer-events: none;
    }
`;

const InputSelect = styled.select`
    padding: 10px;
    border: 1px solid #5648fb;
    color: #5648fb;
    transition: 0.3s;
    padding: 5px 10px;
    background-color: transparent;
    border-radius: 20px;
    font-size: 14px;
    min-width: 120px;

    &:focus {
        border: 1px solid #5648fb;
        box-shadow: none;
    }

    /* icon */
    &::after {
        content: 'asd';
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #5648fb;
    }
`;

const InputSearch = styled.input`
    width: 100%;
    padding: 10px;
    border: none;
    transition: 0.3s;
    padding: 0 15px;
    &:focus {
        box-shadow: none;
        border: none;
    }

    &:focus-visible {
        outline: none;
    }
`;

const FilterButton = styled.button`
    background-color: #5648fb;
    color: #fff;
    border: none;
    padding: 5px;
    border-radius: 20px;
    transition: 0.3s;
    cursor: pointer;
    &:hover {
        background-color: #4637e3;
    }
`;
