import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AiOutlinePlus } from 'react-icons/ai';

import InputSearchSelect from '@/components/atoms/Inputs/InputSearchSelect';
import InputBasic from '@/components/atoms/Inputs/InputBasic';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';

import styled from 'styled-components';
import { RootState } from '@/redux/store';

import { AddTaskProps } from '../../interfaces';
import { generateInitialImageUrl, getCredential } from '@/utility/Utils';

import InputArea from '@/components/atoms/Inputs/InputArea';
import BasicTooltip from '@/components/atoms/Tooltips';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import InputDateRangeHeaderNew from '@/components/atoms/Inputs/InputDateRangeHeaderNew';
import InputColor from '@/components/atoms/Inputs/InputColor';

export default function AddTask({
    subtask,
    modal,
    projectId,
    assignees,
    type,
    setModal,
    setModalType,
    setAssignees,
    handleSuccessAddTask,
    backTasks,
    setBackTasks,
    handleDetailTask,
}: AddTaskProps): JSX.Element {
    const {
        register,
        handleSubmit,
        reset,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
    });

    const allNicheTradeState = useSelector((state: RootState) => state.allNicheTrade);

    const [nicheLists, setNicheLists] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const defaultColor = '#5648fb';
    const defaultDate: [Date | null, Date | null] = [
        new Date(new Date().setDate(new Date().getDate())),
        new Date(new Date().setDate(new Date().getDate() + 10)),
    ];
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>(defaultDate);

    // Set to to state niche trade
    useEffect(() => {
        if (allNicheTradeState?.status === 200) {
            const niche = allNicheTradeState?.response?.data;

            const nicheLists = niche.map((item: { id: number; name: string }) => {
                return {
                    value: item.id,
                    label: item.name,
                };
            });

            setNicheLists(nicheLists);
        }
    }, [allNicheTradeState]);

    // if date change, then calculate the duration
    useEffect(() => {
        if (dateRange[0] !== null && dateRange[1] !== null) {
            setValue(
                'total_duration',
                moment(dateRange[1]).diff(moment(dateRange[0]), 'days') + 1,
            );
        }
    }, [dateRange]);

    /**
     * Function on submit
     *
     * @param object data - any
     * @return void
     */
    const onSubmit = (data: any): void => {
        const apiUrl: string = import.meta.env.VITE_BASE_URL;
        const credential = getCredential();
        const { token } = credential as any;

        setModalType(subtask.id !== null ? 'create subtask' : 'create task');
        setIsLoading(true);

        const newData = {
            ...data,
            project_id: projectId,
            color: data.color ?? defaultColor,
            type,
            parent_id: null,
            start_at: moment(dateRange[0]).format('YYYY-MM-DD'),
            end_at: moment(dateRange[1]).format('YYYY-MM-DD'),
            assignees: assignees.map((item: { id: number }) => item.id),
            trade: data.trade,
        };

        if (subtask.id !== null) {
            newData.parent_id = subtask.id;
        }

        // Dispatch login action with form data as payload
        axios
            .post(`${apiUrl}/v2/project/tasks`, newData, {
                headers: {
                    Authorization: `Bearer ${token as string}`,
                },
            })
            .then(
                (res: {
                    data: {
                        id: number;
                        name: string;
                        start_at: string;
                        end_at: string;
                        color: string;
                    };
                }) => {
                    const item = res?.data;

                    handleSuccessAddTask({
                        ...item,
                        startDate: moment(item.start_at).format('YYYY-MM-DD'),
                        endDate: moment(item.end_at).format('YYYY-MM-DD'),
                        backgroundColor: item.color,
                        borderColor: item.color,
                        level: subtask.id !== null ? subtask.level + 1 : 0,
                    });

                    reset();
                    // setSelectedColor(defaultColor);
                    setDateRange(defaultDate);
                    setIsLoading(false);
                },
            )
            .catch(err => {
                setIsLoading(false);
                toast.error(err.response.data.message);
            });
    };

    /**
     * Function for handle change on date range
     *
     * @param dateRange [Date | null, Date | null]
     * @returns void
     */
    const handleChangeDateRange = (dateRange: [Date | null, Date | null]): void => {
        setDateRange(dateRange);
    };

    return (
        <ModalWrapper
            show={modal.addTask}
            onHide={() => {
                setModal(prev => ({ ...prev, addTask: false }));
                setBackTasks(null);
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header
                closeButton
                style={{
                    padding: '1.5rem 2.3rem',
                }}
            >
                <h6 className="fw-semibold mb-0">
                    Add New
                    {subtask.id !== null ? ' Sub Task' : ' Task'}
                </h6>
            </Modal.Header>
            <Modal.Body className="p-4">
                <form onSubmit={handleSubmit(onSubmit)} method="post">
                    <div className="row">
                        <div className="col-md-7 mb-3 px-4 py-3">
                            {subtask.id !== null && (
                                <InputBasic
                                    id="taskName"
                                    type="text"
                                    placeholder="Task Parent"
                                    value={subtask.name}
                                    disabled
                                />
                            )}
                            <InputBasic
                                id="taskName"
                                type="text"
                                placeholder={
                                    subtask.id !== null ? 'Sub Task Name' : 'Task Name'
                                }
                                rules={{
                                    function: register,
                                    name: 'name',
                                    rules: {
                                        required: 'Please enter your task name',
                                        maxLength: {
                                            value: 255,
                                            message: 'Maximum 255 characters',
                                        },
                                    },
                                    errors,
                                }}
                            />
                            <InputArea
                                id="taskDescription"
                                placeholder="Task Description"
                                defaultHeight={115}
                                rules={{
                                    function: register,
                                    name: 'description',
                                    rules: {
                                        maxLength: {
                                            value: 2000,
                                            message: 'Maximum length of 2000 characters',
                                        },
                                    },
                                    errors,
                                }}
                            />

                            <InputColor
                                rules={{
                                    function: register,
                                    name: 'color',
                                    rules: {
                                        required: 'Please select color',
                                    },
                                    errors,
                                    control,
                                }}
                                value={watch('color') as string}
                            />
                        </div>
                        <div className="col-md-5 mb-3 px-4 py-3 border-start">
                            <InputDateRangeHeaderNew
                                onChange={val => handleChangeDateRange(val)}
                                value={dateRange as [Date, Date]}
                                required
                            />

                            <InputBasic
                                id="totalDays"
                                type="text"
                                placeholder="Total Days"
                                outerClassName="mt-3"
                                rules={{
                                    function: register,
                                    name: 'total_duration',
                                    rules: {
                                        maxLength: {
                                            value: 2000,
                                            message: 'Maximum length of 2000 characters',
                                        },
                                    },
                                    errors,
                                }}
                                disabled
                            />
                            <div className="form-group">
                                <Controller
                                    control={control}
                                    name="trade"
                                    rules={{ required: true }}
                                    render={({ field: { onChange } }) => (
                                        <div className="text-start">
                                            <InputSearchSelect
                                                id="nicheTrade"
                                                placeholder="Niche Trade"
                                                options={nicheLists}
                                                isMulti={false}
                                                placeholderCreatable="Create"
                                                onChange={(value: {
                                                    value: string;
                                                    label: string;
                                                }) => {
                                                    onChange(value.value);
                                                }}
                                                // defaultValue={nicheLists[0]}
                                            />
                                            {errors.trade != null && (
                                                <span
                                                    className="text-danger"
                                                    style={{
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    Please select niche trade
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="form-group mt-3">
                                <label
                                    htmlFor="assigneeId"
                                    className="text-start mb-2 mt-3 w-100"
                                >
                                    Assignee
                                </label>
                                <div className="row">
                                    <div className="col-auto pe-1">
                                        <ButtonAssignee
                                            id="btnAddAssignee"
                                            onClick={() =>
                                                setModal(prev => ({
                                                    ...prev,
                                                    addAssignee: true,
                                                }))
                                            }
                                        >
                                            <AiOutlinePlus className="icon-assignee" />
                                        </ButtonAssignee>
                                    </div>
                                    {assignees.map((user, index) => (
                                        <div key={index} className="col-auto px-1">
                                            <BasicTooltip
                                                text={user.user_name ?? user.user_email}
                                            >
                                                <UserAvatar
                                                    src={
                                                        user.user_avatar ??
                                                        generateInitialImageUrl(
                                                            user.user_name ??
                                                                user.user_email,
                                                        )
                                                    }
                                                />
                                            </BasicTooltip>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex px-4 mt-2">
                            <ButtonBasic
                                id="btnCreateTask"
                                text="Create Task"
                                type="submit"
                                className="btn btn-primary btn-sm me-3"
                                loading={isLoading}
                            />
                            <ButtonBasic
                                id="btnCancel"
                                text="Cancel"
                                type="button"
                                className="btn btn-outline-primary btn-sm"
                                onClick={() => {
                                    if (backTasks !== null) {
                                        handleDetailTask(
                                            backTasks[backTasks.length - 1],
                                            true,
                                        );
                                    } else {
                                        setBackTasks(null);
                                    }

                                    setModal(prev => ({
                                        ...prev,
                                        addTask: false,
                                    }));
                                }}
                            />
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </ModalWrapper>
    );
}

const ModalWrapper = styled(Modal)``;

const ButtonAssignee = styled.span`
    width: 50px;
    height: 50px;
    background-color: #dddafe;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #5648fb;

    .icon-assignee {
        color: #5648fb;
    }

    &:hover {
        border: 1px solid #5648fb;

        .icon-assignee {
            // color: #dddafe;
        }
    }
`;

const UserAvatar = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
`;
