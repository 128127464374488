import { OpenFile, Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { SelectionMode } from '@react-pdf-viewer/selection-mode';
import packageJson from '@/../package.json';
import { StructureCompareProps } from '@/routes/ProjectDashboard/Drawing/components/Explorer/interface';

export default function ComparedPDF({
    compareItem,
    setCompareItem,
}: {
    compareItem: StructureCompareProps;
    setCompareItem: (item: StructureCompareProps) => void;
}): JSX.Element {
    const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];
    const pdfWorker = `https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`;

    const toolbarPluginInstance = toolbarPlugin({
        getFilePlugin: {
            fileNameGenerator: (file: OpenFile) => {
                const fileName = file.name.substring(file.name.lastIndexOf('/') + 1);
                return fileName;
            },
        },
        searchPlugin: {
            keyword: 'PDF',
        },
        selectionModePlugin: {
            selectionMode: SelectionMode.Hand,
        },
    });
    const { Toolbar } = toolbarPluginInstance;

    return (
        <div className="col-md-6">
            <Worker workerUrl={pdfWorker}>
                <div
                    className="p-3"
                    style={{
                        backgroundColor: 'rgb(213, 213, 213)',
                    }}
                >
                    <Toolbar />
                </div>
                <Viewer
                    fileUrl={compareItem.file ?? ''}
                    plugins={[toolbarPluginInstance as any]}
                />
            </Worker>
        </div>
    );
}
