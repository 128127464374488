export const INTENT_STRIPE_REQUEST = 'INTENT_STRIPE_REQUEST';
export const INTENT_STRIPE_SUCCESS = 'INTENT_STRIPE_SUCCESS';
export const INTENT_STRIPE_FAIL = 'INTENT_STRIPE_FAIL';

export const ADD_PAYMENT_METHOD_REQUEST = 'ADD_PAYMENT_METHOD_REQUEST';
export const ADD_PAYMENT_METHOD_SUCCESS = 'ADD_PAYMENT_METHOD_SUCCESS';
export const ADD_PAYMENT_METHOD_FAIL = 'ADD_PAYMENT_METHOD_FAIL';
export const ADD_PAYMENT_METHOD_RESET = 'ADD_PAYMENT_METHOD_RESET';

export const DETAIL_PAYMENT_METHOD_REQUEST = 'DETAIL_PAYMENT_METHOD_REQUEST';
export const DETAIL_PAYMENT_METHOD_SUCCESS = 'DETAIL_PAYMENT_METHOD_SUCCESS';
export const DETAIL_PAYMENT_METHOD_FAIL = 'DETAIL_PAYMENT_METHOD_FAIL';

export const ALL_INVOICE_REQUEST = 'ALL_INVOICE_REQUEST';
export const ALL_INVOICE_SUCCESS = 'ALL_INVOICE_SUCCESS';
export const ALL_INVOICE_FAIL = 'ALL_INVOICE_FAIL';

export const DETAIL_INVOICE_REQUEST = 'DETAIL_INVOICE_REQUEST';
export const DETAIL_INVOICE_SUCCESS = 'DETAIL_INVOICE_SUCCESS';
export const DETAIL_INVOICE_FAIL = 'DETAIL_INVOICE_FAIL';

export const CHECK_COUPON_REQUEST = 'CHECK_COUPON_REQUEST';
export const CHECK_COUPON_SUCCESS = 'CHECK_COUPON_SUCCESS';
export const CHECK_COUPON_FAIL = 'CHECK_COUPON_FAIL';
export const CHECK_COUPON_RESET = 'CHECK_COUPON_RESET';

export const APPLY_COUPON_REQUEST = 'APPLY_COUPON_REQUEST';
export const APPLY_COUPON_SUCCESS = 'APPLY_COUPON_SUCCESS';
export const APPLY_COUPON_FAIL = 'APPLY_COUPON_FAIL';
export const APPLY_COUPON_RESET = 'APPLY_COUPON_RESET';

export const SWAP_BILLING_REQUEST = 'SWAP_BILLING_REQUEST';
export const SWAP_BILLING_SUCCESS = 'SWAP_BILLING_SUCCESS';
export const SWAP_BILLING_FAIL = 'SWAP_BILLING_FAIL';
export const SWAP_BILLING_RESET = 'SWAP_BILLING_RESET';

export const CLEAR_STATE = 'CLEAR_STATE';

const initialState = {
    loading: false,
    response: null,
    error: null,
    status: null,
};

export const intentStripeReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case INTENT_STRIPE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case INTENT_STRIPE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case INTENT_STRIPE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const addPaymentMethodReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ADD_PAYMENT_METHOD_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ADD_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ADD_PAYMENT_METHOD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ADD_PAYMENT_METHOD_RESET:
            return initialState;

        default:
            return state;
    }
};

export const detailPaymentMethodReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DETAIL_PAYMENT_METHOD_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DETAIL_PAYMENT_METHOD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const allInvoiceReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_INVOICE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_INVOICE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_INVOICE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const detailInvoiceReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DETAIL_INVOICE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_INVOICE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DETAIL_INVOICE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const checkCouponReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case CHECK_COUPON_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CHECK_COUPON_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case CHECK_COUPON_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CHECK_COUPON_RESET:
            return initialState;

        default:
            return state;
    }
};

export const applyCouponReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case APPLY_COUPON_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case APPLY_COUPON_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case APPLY_COUPON_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case APPLY_COUPON_RESET:
            return initialState;

        default:
            return state;
    }
};

export const swapBillingReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case SWAP_BILLING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case SWAP_BILLING_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case SWAP_BILLING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case SWAP_BILLING_RESET:
            return initialState;

        default:
            return state;
    }
};
