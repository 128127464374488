import { RootState } from '@/redux/store';
import { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

export default function AdvertisementBanner(): JSX.Element {
    const { id } = useParams();

    const advertisementWallpaperDashboardState = useSelector(
        (state: RootState) => state.advertisementWallpaperDashboard,
    );

    const advertisement = (): any => {
        if (id !== undefined) {
            return advertisementWallpaperDashboardState?.project;
        }
        return advertisementWallpaperDashboardState?.general;
    };

    /**
     * Handle project notification
     *
     * @return {void}
     */
    const handleRedirectAdvertisement = (): void => {
        window.open(advertisement()?.redirect_uri, '_blank');
    };

    const [src, setSrc] = useState<string>('');

    useEffect(() => {
        const handleResize = (): void => {
            if (advertisement()?.images?.length > 0) {
                if (window.innerWidth <= 991) {
                    setSrc(advertisement()?.images[2].picture);
                } else if (window.innerWidth >= 992 && window.innerWidth <= 1199) {
                    setSrc(advertisement()?.images[0].picture);
                } else {
                    setSrc(advertisement()?.images[1].picture);
                }
            }
        };

        // Initial setup
        handleResize();

        // Event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [advertisement()]);

    return (
        // <Advertisement
        //     backgroundCommon={advertisement()?.images[0].picture}
        //     backgroundLarge={advertisement()?.images[1].picture}
        //     backgroundMobile={advertisement()?.images[2].picture}
        //     isProject={id !== undefined}
        // >
        //     {advertisement()?.loading !== true && (
        //         <AdvertisementText className="">
        //             <h1
        //                 className="fw-bold shadow-md h3"
        //                 style={{
        //                     color: advertisement()?.text_color,
        //                     textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        //                 }}
        //             >
        //                 {advertisement()?.title}
        //             </h1>
        //             <h2
        //                 className="h5"
        //                 style={{
        //                     color: advertisement()?.text_color,
        //                     textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        //                 }}
        //             >
        //                 {advertisement()?.description}
        //             </h2>
        //             <CustomButton
        //                 className="btn mt-3 rounded-pill"
        //                 style={{
        //                     backgroundColor: advertisement()?.button_color,
        //                     color: advertisement()?.button_text_color,
        //                 }}
        //                 onClick={handleRedirectAdvertisement}
        //             >
        //                 {advertisement()?.button_text}
        //             </CustomButton>
        //         </AdvertisementText>
        //     )}

        // </Advertisement>
        <Advertisement
            className="position-relative"
            style={{
                borderRadius: 20,
            }}
        >
            <LazyLoadImage
                alt={'advertisement'}
                src={src}
                // src={bgExample}
                width={'100%'}
                height={'100%'}
                style={{
                    // width: '100%',
                    // height: '80%',
                    objectFit: 'cover',
                }}
                effect="blur"
                className="rounded-3"
            />
            <TextAdvertisement
                className="fw-semibold"
                color={advertisement()?.text_color}
            >
                <h1
                    className="fw-bold shadow-md h3"
                    style={{
                        color: advertisement()?.text_color,
                        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }}
                >
                    {advertisement()?.title}
                </h1>
                <h2
                    className="h5"
                    style={{
                        color: advertisement()?.text_color,
                        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    }}
                >
                    {advertisement()?.description}
                </h2>
                <CustomButton
                    className="btn mt-3 rounded-pill"
                    style={{
                        backgroundColor: advertisement()?.button_color,
                        color: advertisement()?.button_text_color,
                    }}
                    onClick={handleRedirectAdvertisement}
                >
                    {advertisement()?.button_text}
                </CustomButton>
            </TextAdvertisement>
        </Advertisement>
    );
}

const Advertisement = styled.div<{}>`
    height: 40rem;

    @media (max-width: 991px) {
        height: 20rem;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        height: 30rem;
    }
`;

const TextAdvertisement = styled.div<{ color?: string }>`
    position: absolute;
    bottom: 20px;
    left: 20px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    z-index: 1;
    color: ${props => props.color ?? 'white'};

    h1 {
        font-size: 1.5rem;
        text-shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)';
    }

    h2 {
        font-size: 1rem;
        text-shadow: '0px 4px 4px rgba(0, 0, 0, 0.25)';
    }
`;

const CustomButton = styled.button`
    border: 2px solid transparent;
    font-size: 0.75rem;
    font-weight: 500;

    &:hover {
        border: 2px solid #aba3ff;
    }
`;
