/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable array-callback-return */
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Table, Pagination } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';

import Card from '@/components/molecules/Card';
import { TitleWithBack } from '@/components/molecules/Typography';

import { AiOutlineSearch } from 'react-icons/ai';

import MailIcon from '@/assets/svg/icons/mail-alt-3.svg';
import MailOpenIcon from '@/assets/svg/icons/mail-open.svg';
import TrashIcon from '@/assets/svg/icons/trash.svg';
import BellIcon from '@/assets/svg/icons/bells.svg';

import styled from 'styled-components';

import collect from 'collect.js';
import { RootState } from '@/redux/store';

import {
    getAllProjectNotifications,
    getAllUnreadProjectNotifications,
    markAsReadProjectNotifications,
    markAsUnReadProjectNotifications,
    deleteProjectNotifications,
    clearActionsNotifications,
} from '../redux/actions';
import BasicTooltip from '@/components/atoms/Tooltips';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import PaginationView from '@/components/organism/TableBorderless/PaginationView';

interface DataTempProps {
    key: string;
    isChecked: boolean;
    data: any;
    created_at: number;
    readed: boolean;
    fixed_created_at: string;
    labeled_created_at: string;
}

export default function ProjectNotification(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();

    const [searchValue, setSearchValue] = useState('');

    const allProjectNotificationState = useSelector(
        (state: RootState) => state.allProjectNotification,
    );
    const projectNotificationActionState = useSelector(
        (state: RootState) => state.projectNotificationAction,
    );
    const detailProjectState = useSelector((state: RootState) => state.detailProject);

    const metaNotification = allProjectNotificationState?.response?.meta;
    const { current_page: currentPage, last_page: lastPage } = metaNotification || {};

    const [dataTemp, setDataTemp] = useState<DataTempProps[]>([]);
    const [selected, setSelected] = useState<any>([]);
    const [params, setParams] = useState({
        project_id: id,
        page: 1,
        per_page: 10,
        search: '',
        // sort_by: 'created_at',
        sort_asc: 0,
    });

    /**
     * Function to handle search
     *
     * @param string value
     * @returns void
     */
    const handleSearch = useCallback((value: string): void => {
        setSearchValue(value);
    }, []);

    /**
     * Function to handle search with callback
     *
     * @returns void
     */
    const handleDebouncedSearch = useCallback(() => {
        setParams({
            ...params,
            search: searchValue,
            page: 1,
        });

        const payload = {
            ...params,
            search: searchValue,
            page: 1,
        };

        dispatch(getAllProjectNotifications(payload));
    }, [searchValue]);

    /**
     * Function for handle check all checkbox
     *
     * @param boolean checked
     * @returns void
     */
    const handleCheckAll = (checked: boolean): void => {
        const temp: any = dataTemp.map(
            (item: { isChecked: boolean; data: { items: [] } }) => {
                item?.data?.items?.map(
                    (item: { isChecked: boolean }) => (item.isChecked = checked),
                );

                return {
                    ...item,
                    isChecked: checked,
                };
            },
        );
        setDataTemp(temp);
        setSelected(checked ? (dataTemp as any) : []);
    };

    /**
     * Function for handle check on group by date
     *
     * @param string key
     * @param boolean checked
     * @returns void
     */
    const handleCheckKey = (key: string, checked: boolean): void => {
        const temp: any = dataTemp.map(
            // eslint-disable-next-line array-callback-return
            (list: { key: string; isChecked: boolean; data: { items: [] } }) => {
                if (list.key === key) {
                    list?.data?.items?.map(
                        (item: { isChecked: boolean }) => (item.isChecked = checked),
                    );

                    setSelected(
                        checked
                            ? [...selected, list]
                            : selected.filter((item: any) => item.key !== key),
                    );

                    return {
                        ...list,
                        isChecked: checked,
                    };
                }
                return list;
            },
        );
        setDataTemp(temp);
    };

    /**
     * Function for handle check on group by date on outside
     *
     * @param string key
     * @param boolean checked
     * @returns void
     */
    const handleOutsideKey = (key: string): void => {
        const temp: any = dataTemp.map(
            (list: { key: string; isChecked: boolean; data: { items: [] } }) => {
                if (list.key === key) {
                    list?.data?.items?.map(
                        (item: { isChecked: boolean }) =>
                            (item.isChecked = !list.isChecked),
                    );

                    setSelected(
                        list.isChecked
                            ? selected.filter((item: any) => item.key !== key)
                            : [...selected, list],
                    );

                    return {
                        ...list,
                        isChecked: !list.isChecked,
                    };
                }

                return list;
            },
        );
        setDataTemp(temp);
    };

    /**
     * Function for handle check on 1 item
     *
     */
    const handleCheckItems = (key: string, id: string, value: boolean): void => {
        const temp: any = dataTemp.map(
            (list: { key: string; isChecked: boolean; data: { items: [] } }) => {
                if (list.key === key) {
                    list?.data?.items?.map((item: { isChecked: boolean; id: string }) => {
                        if (item.id === id) {
                            item.isChecked = value;

                            // add per id in key
                            setSelected(
                                value
                                    ? ([
                                          ...selected.filter(
                                              (selectedList: any) =>
                                                  selectedList.key !== key,
                                          ),
                                          {
                                              ...list,
                                              data: {
                                                  items: [
                                                      ...(selected.find(
                                                          (selectedList: any) =>
                                                              selectedList.key === key,
                                                      )?.data?.items || []),
                                                      item,
                                                  ],
                                              },
                                          },
                                      ] as any)
                                    : ([
                                          ...selected.filter(
                                              (selectedList: any) =>
                                                  selectedList.key !== key,
                                          ),
                                          {
                                              ...list,
                                              data: {
                                                  items: [
                                                      ...(
                                                          selected.find(
                                                              (selectedList: any) =>
                                                                  selectedList.key ===
                                                                  key,
                                                          )?.data?.items || []
                                                      ).filter(
                                                          (item: any) => item.id !== id,
                                                      ),
                                                  ],
                                              },
                                          },
                                      ] as any),
                            );
                        }
                    });
                }
                return list;
            },
        );
        setDataTemp(temp);
    };

    /**
     * Function for handle check on 1 item
     *
     * @param string key
     * @param string id
     * @returns void
     */
    const handleOutsideItems = (key: string, id: string): void => {
        const temp: any = dataTemp.map(
            (list: { key: string; isChecked: boolean; data: { items: [] } }) => {
                if (list.key === key) {
                    list?.data?.items?.map((item: { isChecked: boolean; id: string }) => {
                        if (item.id === id) {
                            item.isChecked = !item.isChecked;

                            // add per id in key
                            setSelected(
                                item.isChecked
                                    ? ([
                                          ...selected.filter(
                                              (selectedList: any) =>
                                                  selectedList.key !== key,
                                          ),
                                          {
                                              ...list,
                                              data: {
                                                  items: [
                                                      ...(selected.find(
                                                          (selectedList: any) =>
                                                              selectedList.key === key,
                                                      )?.data?.items || []),
                                                      ...list.data.items.filter(
                                                          (item: any) => item.id === id,
                                                      ),
                                                  ],
                                              },
                                          },
                                      ] as any)
                                    : ([
                                          ...selected.filter(
                                              (selectedList: any) =>
                                                  selectedList.key !== key,
                                          ),
                                          {
                                              ...list,
                                              data: {
                                                  items: [
                                                      ...(
                                                          selected.find(
                                                              (selectedList: any) =>
                                                                  selectedList.key ===
                                                                  key,
                                                          )?.data?.items || []
                                                      ).filter(
                                                          (selectedItem: any) =>
                                                              selectedItem.id !== id,
                                                      ),
                                                  ],
                                              },
                                          },
                                      ] as any),
                            );
                        }
                    });

                    return {
                        ...list,
                        isChecked: list.data.items.every(
                            (item: { isChecked: boolean }) => item.isChecked,
                        ),
                    };
                }

                return list;
            },
        );
        setDataTemp(temp);
    };

    /**
     * Function for handle pagination
     *
     * @param number page
     * @param string type
     * @returns void
     */
    const handlePagination = (page: number, type: string): void => {
        if (type === 'page') {
            setParams({
                ...params,
                page,
            });

            const payload = {
                ...params,
                page,
            };

            dispatch(getAllProjectNotifications(payload));
        }

        if (type === 'next') {
            setParams({
                ...params,
                page: params.page + 1,
            });

            const payload = {
                ...params,
                page: params.page + 1,
            };

            dispatch(getAllProjectNotifications(payload));
        }

        if (type === 'prev') {
            setParams({
                ...params,
                page: params.page - 1,
            });

            const payload = {
                ...params,
                page: params.page - 1,
            };

            dispatch(getAllProjectNotifications(payload));
        }
    };

    /**
     * Function for handle pagination
     *
     * @param page number
     * @returns boolean
     */
    const shouldDisplayPage = (page: number): boolean => {
        if (
            page === 1 ||
            page === lastPage ||
            (page >= currentPage - 1 && page <= currentPage + 1) ||
            (page === currentPage - 2 && currentPage > 3) ||
            (page === currentPage + 2 && currentPage < lastPage - 2)
        ) {
            return true;
        }

        return false;
    };

    /**
     * Function for handle actions by type
     * 1 = mark as unread
     * 2 = mark as read
     * 3 = delete
     * @param type number
     * @returns void
     */
    const handleActions = (type: number): void => {
        const ids = selected
            .map((item: any) => item.data.items.map((i: any) => i.id))
            .flat();

        const projectId: string = id ?? '';

        switch (type) {
            case 1:
                dispatch(markAsUnReadProjectNotifications(ids.toString(), projectId));
                break;
            case 2:
                dispatch(markAsReadProjectNotifications(ids.toString(), projectId));
                break;
            case 3:
                dispatch(deleteProjectNotifications(ids.toString(), projectId));
                break;

            default:
                break;
        }
    };

    // Function for handle debounce search
    useEffect(() => {
        const debounce = setTimeout(() => {
            handleDebouncedSearch();
        }, 500);

        return () => {
            clearTimeout(debounce);
        };
    }, [handleDebouncedSearch]);

    // Function for set to state and add is checked attribute
    useEffect(() => {
        if (allProjectNotificationState?.response?.data !== undefined) {
            const temp: Array<{
                id?: number;
                isChecked: boolean;
            }> = allProjectNotificationState?.response?.data?.map((item: any) => {
                return {
                    ...item,
                    isChecked: false,
                };
            });

            // grouping by fixed_created_at as key
            const newData: any = collect(temp)
                .groupBy('fixed_created_at')
                .map((data: any, key: any) => ({
                    key,
                    label: data?.items[0]?.labeled_created_at,
                    isChecked: false,
                    data,
                }))
                .toArray();

            setDataTemp(newData);
        }
    }, [allProjectNotificationState]);

    // Function for handle actions on success
    useEffect(() => {
        if (projectNotificationActionState?.status === 200) {
            dispatch(getAllProjectNotifications({ ...params, page: 1 }));
            dispatch(getAllUnreadProjectNotifications({ ...params, page: 1 }));
            dispatch(clearActionsNotifications());
            setSelected([]);
        }
    }, [projectNotificationActionState]);

    /**
     * Handle project notification
     *
     * @param number type
     * @returns JSX.Element
     */
    const handleProjectNotification = (data: {
        id: string;
        actions: Array<{
            name: string;
            method: string;
            accept: string;
            uri: string;
        }>;
    }): JSX.Element => {
        const name = data?.actions[0]?.name;
        const baseUrl = import.meta.env.VITE_FE_URL;
        const uri = data?.actions[0]?.uri?.replace(baseUrl, '');

        switch (name) {
            case 'Reply Now':
                return (
                    <ButtonBasic
                        className="btn btn-success text-white rounded-5 px-4 py-0"
                        text={'Reply'}
                        onClick={() => {
                            navigate(uri);
                        }}
                    />
                );
            case 'go to':
                return (
                    <ButtonBasic
                        className="btn btn-success text-white rounded-5 px-4 py-0"
                        text={'Go to'}
                        onClick={() => {
                            navigate(uri);
                        }}
                    />
                );
            default:
                return <></>;
        }
    };

    return (
        <>
            <div className="row mb-5">
                <div className="col-md-12 mt-3">
                    <TitleWithBack
                        text={`All Notification (${
                            detailProjectState?.response?.name as string
                        })`}
                    />
                </div>
            </div>
            <Card classOuter="p-0 border-0" classBody="p-0">
                <SearchWrapper className="row g-0">
                    <div className="col-lg-12 p-2">
                        <div
                            className="d-flex align-items-center"
                            style={{ paddingLeft: 14 }}
                        >
                            <AiOutlineSearch size={20} color="rgba(131, 131, 131, 1)" />
                            <TableSearch
                                type="text"
                                id="searchNotification"
                                placeholder={`Search ${
                                    detailProjectState?.response?.name as string
                                }...`}
                                className="w-100 ms-2"
                                onChange={e => {
                                    handleSearch?.(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </SearchWrapper>
                <div className="row g-0 p-0">
                    <div className="col-lg-12">
                        {allProjectNotificationState?.loading === true ? (
                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{
                                    height: '70vh',
                                }}
                            >
                                <ThreeDots
                                    height="80"
                                    width="80"
                                    radius="9"
                                    color="#5648FB"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    visible={true}
                                />
                            </div>
                        ) : (
                            <>
                                <TableWrapper>
                                    {dataTemp?.length > 0 ? (
                                        <Table
                                            borderless
                                            hover
                                            responsive
                                            id="notificationTable"
                                        >
                                            <TableHeader>
                                                <tr>
                                                    <th
                                                        className="ps-4"
                                                        style={{
                                                            width: '2.5rem',
                                                        }}
                                                    >
                                                        <Form.Check
                                                            type="checkbox"
                                                            id="checkboxAll"
                                                            onChange={(e: any) =>
                                                                handleCheckAll(
                                                                    e?.target?.checked,
                                                                )
                                                            }
                                                        />
                                                    </th>
                                                    <td>
                                                        {/* // if every key has item length > 0 */}
                                                        {selected.some(
                                                            (item: any) =>
                                                                item.data?.items?.length >
                                                                0,
                                                        ) && (
                                                            <div
                                                                className="d-flex align-items-center justify-content-between"
                                                                style={{ width: '5rem' }}
                                                            >
                                                                {' '}
                                                                <BasicTooltip
                                                                    text={'Mark as read'}
                                                                >
                                                                    <IconContent
                                                                        id="btnMarkAsRead"
                                                                        className="icon-active"
                                                                        icon={
                                                                            MailOpenIcon
                                                                        }
                                                                        onClick={() =>
                                                                            handleActions(
                                                                                2,
                                                                            )
                                                                        }
                                                                    />
                                                                </BasicTooltip>
                                                                <BasicTooltip
                                                                    text={
                                                                        'Mark as unread'
                                                                    }
                                                                >
                                                                    <IconContent
                                                                        id="btnMarkAsUnRead"
                                                                        className="icon-active"
                                                                        icon={MailIcon}
                                                                        onClick={() =>
                                                                            handleActions(
                                                                                1,
                                                                            )
                                                                        }
                                                                    />
                                                                </BasicTooltip>
                                                                <BasicTooltip
                                                                    text={'Delete'}
                                                                >
                                                                    <IconContent
                                                                        id="btnDelete"
                                                                        className="icon-active"
                                                                        icon={TrashIcon}
                                                                        onClick={() =>
                                                                            handleActions(
                                                                                3,
                                                                            )
                                                                        }
                                                                    />
                                                                </BasicTooltip>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <th className="text-end fw-semibold pe-4">
                                                        {metaNotification?.from}
                                                        &nbsp;-&nbsp;
                                                        {metaNotification?.to}
                                                        &nbsp;from&nbsp;
                                                        {metaNotification?.total}
                                                    </th>
                                                </tr>
                                            </TableHeader>
                                            <tbody>
                                                {dataTemp.map(
                                                    (list: any, index: number) => (
                                                        <Fragment key={index}>
                                                            <tr
                                                                className="disable-selection"
                                                                onClick={() =>
                                                                    handleOutsideKey(
                                                                        list.key,
                                                                    )
                                                                }
                                                            >
                                                                <td
                                                                    className="ps-4"
                                                                    style={{
                                                                        width: '2.5rem',
                                                                    }}
                                                                >
                                                                    <Form.Check
                                                                        id={`checkbox_${
                                                                            list?.key as string
                                                                        }`}
                                                                        type="checkbox"
                                                                        checked={
                                                                            list?.isChecked
                                                                        }
                                                                        onChange={(
                                                                            e: any,
                                                                        ) =>
                                                                            handleCheckKey(
                                                                                list.key,
                                                                                e?.target
                                                                                    ?.checked,
                                                                            )
                                                                        }
                                                                    />
                                                                </td>

                                                                <th className="fw-semibold">
                                                                    {list?.label}
                                                                </th>
                                                                <td></td>
                                                            </tr>
                                                            {list?.data?.items?.map(
                                                                (
                                                                    item: any,
                                                                    index: number,
                                                                ) => (
                                                                    <tr
                                                                        key={index}
                                                                        className="disable-selection"
                                                                        onClick={() =>
                                                                            handleOutsideItems(
                                                                                list.key,
                                                                                item.id,
                                                                            )
                                                                        }
                                                                    >
                                                                        <td
                                                                            className="ps-4"
                                                                            style={{
                                                                                width: '2.5rem',
                                                                            }}
                                                                        >
                                                                            <Form.Check
                                                                                type="checkbox"
                                                                                checked={
                                                                                    item?.isChecked
                                                                                }
                                                                                onChange={(
                                                                                    e: any,
                                                                                ) =>
                                                                                    handleCheckItems(
                                                                                        list.key,
                                                                                        item.id,
                                                                                        e
                                                                                            ?.target
                                                                                            ?.checked,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </td>

                                                                        <td>
                                                                            <div className="display-block">
                                                                                <span
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: item?.title,
                                                                                    }}
                                                                                ></span>
                                                                                &nbsp;-&nbsp;
                                                                                <span
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: item?.description,
                                                                                    }}
                                                                                ></span>
                                                                            </div>
                                                                            {handleProjectNotification(
                                                                                item,
                                                                            )}
                                                                        </td>
                                                                        <td className="text-end">
                                                                            <span
                                                                                className={`me-4 ${
                                                                                    item?.readed !==
                                                                                    true
                                                                                        ? 'fw-semibold'
                                                                                        : ''
                                                                                }`}
                                                                                style={{
                                                                                    fontSize: 12,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    item?.diff_for_humans_created_at
                                                                                }
                                                                            </span>
                                                                            {item?.readed !==
                                                                                true && (
                                                                                <CircleRed id="circleRed" />
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                ),
                                                            )}
                                                        </Fragment>
                                                    ),
                                                )}
                                            </tbody>
                                        </Table>
                                    ) : (
                                        <div
                                            className="d-flex justify-content-center align-items-center flex-column"
                                            style={{
                                                height: '50vh',
                                            }}
                                        >
                                            <PlaceholderContent icon={BellIcon} />
                                            <h6
                                                className="text-muted"
                                                style={{ fontSize: 14 }}
                                            >
                                                No Notification found
                                            </h6>
                                        </div>
                                    )}
                                </TableWrapper>
                                <div className="col-lg-12">
                                    <PaginationView
                                        items={allProjectNotificationState?.response}
                                        handlePagination={handlePagination}
                                        withoutPagination={false}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Card>
        </>
    );
}

const TableSearch = styled.input`
    background-color: transparent;
    border: none;
    height: 2rem;
    font-family: 'Montserrat', sans-serif;

    &::placeholder {
        color: #303030;
        font-size: 14px;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }
`;

const TableWrapper = styled.div`
    color: #303030;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;

    th,
    td {
        vertical-align: middle;
        height: 50px;
    }

    .table {
        margin-bottom: 0;
    }

    @media (min-width: 768px) {
        .table-responsive {
            overflow-x: inherit;
            overflow-y: inherit !important;
        }
    }
`;

const TableHeader = styled.thead`
    font-weight: 500;
`;

const SearchWrapper = styled.div``;

const IconContent = styled.span<{ icon: string; errors?: boolean }>`
    display: block;
    width: 20px;
    height: 20px;
    mask: url(${props => props.icon}) no-repeat center;
    mask-size: 80%;
    -webkit-mask: url(${props => props.icon}) no-repeat center;
    -webkit-mask-size: 80%;
    background-color: rgba(131, 131, 131, 1);

    cursor: pointer;

    &:hover {
        background-color: rgba(86, 72, 251, 1);
    }
`;

const CircleRed = styled.span`
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #ff0000;
    display: inline-block;
    margin-right: 5px;
    position: absolute;
    right: 10px;
`;

const PlaceholderContent = styled.span<{ icon: string; errors?: boolean }>`
    display: block;
    width: 40px;
    height: 40px;
    mask: url(${props => props.icon}) no-repeat center;
    mask-size: 80%;
    -webkit-mask: url(${props => props.icon}) no-repeat center;
    -webkit-mask-size: 80%;
    background-color: rgba(131, 131, 131, 1);
`;
