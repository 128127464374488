import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import BadgeCheck from '@/assets/svg/icons/badge-check.svg';

import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import Header from '@/components/molecules/Header';
import { Title } from '@/components/molecules/Typography';
import { getCredential } from '@/utility/Utils';

export default function SuccessfullyChanged(): JSX.Element {
    const navigate = useNavigate();

    /**
     * Function for handle confirm
     *
     * @returns {void}
     */
    const handleConfirm = (): void => {
        navigate('/');
    };

    const checkToken = getCredential() !== null;

    return (
        <Fragment>
            <div className="container-fluid container-xs container-lg">
                <header>
                    <Header text="Return to home screen" link="/" />
                </header>
                <main>
                    <div className="row my-5">
                        <div className="col-md-12 text-center my-5">
                            <Title text="Email Successfully Changed" />
                            <img
                                src={BadgeCheck}
                                alt="badge-check"
                                className="img-fluid mt-5"
                            />
                        </div>
                        <div className="col-md-8 col-lg-6 col-xxl-4 mx-auto text-center mb-3">
                            <span className="text-muted">
                                Your email has been successfully change,
                                {!checkToken &&
                                    ' return to login page to continue access your account.'}
                            </span>
                        </div>
                    </div>
                    <div className="row my-5">
                        <div className="col-md-8 col-lg-6 col-xxl-4 mx-auto text-center">
                            <ButtonBasic
                                type="button"
                                text={checkToken ? 'Return' : 'Return to Login Page'}
                                className="my-3 w-75 btn-primary"
                                onClick={handleConfirm}
                            />
                        </div>
                    </div>
                </main>
            </div>
        </Fragment>
    );
}
