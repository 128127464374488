import { Tab, Tabs } from 'react-bootstrap';

import styled from 'styled-components';

interface ItemsProps {
    id: string;
    title: string;
    page: JSX.Element;
}

interface TabVerticalProps {
    defaultActive: string;
    items: ItemsProps[];
}

export default function TabVertical(props: TabVerticalProps): JSX.Element {
    const { defaultActive, items } = props;

    return (
        <>
            <WrapperTabs
                defaultActiveKey={defaultActive}
                id="uncontrolled-tab-example"
                className="mt-5 border-0"
            >
                {items.map(item => (
                    <Tab key={item.id} eventKey={item.id} title={item.title}>
                        {item.page}
                    </Tab>
                ))}
            </WrapperTabs>
        </>
    );
}

const WrapperTabs = styled(Tabs)`
    .nav-item {
        padding: 1rem;
    }
    .nav-link {
        border-radius: 20px;
        background: rgba(86, 72, 251, 0.5);
        color: #fff !important;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        padding: 0.2rem 1rem;

        &.active {
            color: #fff !important;
            background-color: #5648fb;
        }
    }
`;
