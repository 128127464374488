import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import { useNavigate } from 'react-router-dom';
import ErrorIcons from '@/assets/svg/errors/ErrorLinkExpired.svg';

export default function ErrorLinkExpired(): JSX.Element {
    const navigate = useNavigate();

    /**
     * Function for handle back to login page
     *
     * @returns {void}
     */
    const handleBack = (): void => {
        navigate('/login');
    };

    return (
        <main className="container-fluid container-xs container-lg">
            <div className="d-flex align-items-center justify-content-center vh-100">
                <div className="row">
                    <div className="col-md-6 text-center">
                        <img src={ErrorIcons} alt="404" className="img-fluid" />
                    </div>

                    <div className="col-md-6 p-5">
                        <div className="mt-5">
                            <h1 className="fw-bold mb-5">Constructapp</h1>
                            <h2 className="fw-semibold h6 mb-3">
                                <span className="text-primary">Error 404. </span>
                                Your token has expired or invalid
                            </h2>
                            <p className="">
                                The link you’re looking for might have been removed had
                                its name changed or is temporarily unavailable.
                            </p>
                            <ButtonBasic
                                type="button"
                                text="Back to Main Page"
                                className="my-3 w-30 btn-primary"
                                onClick={handleBack}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
