import documentsImage2 from '@/assets/images/document-drawing/help-documents-drawings-image-2.webp';
import documentsImage3 from '@/assets/images/document-drawing/help-documents-drawings-image-3.webp';

import { Fragment } from 'react';

export default function WhatIsDifferentSpace(): JSX.Element {
    const contents = [
        {
            id: 1,
            text: 'Public are space that can be accessed by all users.',
            alt: 'documentsFolderPage',
            image: documentsImage3,
        },
        {
            id: 2,
            text: 'While private are space that can only be accessed by the owner only. Private space can be accessed by other users if the owner share and gives access to other users.',
            alt: 'documentsFolderPage',
            image: documentsImage2,
        },
    ];

    return (
        <Fragment>
            {contents.map((content, index) => (
                <div key={index} className="text-grey-300 mb-10">
                    {content.image.length > 0 && (
                        <img
                            src={content.image}
                            alt={content.alt}
                            className="img-fluid"
                        />
                    )}
                    <p className="mt-4 pl-2">{content.text}</p>
                </div>
            ))}
        </Fragment>
    );
}
