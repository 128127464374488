import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Link,
    NavLink,
    Outlet,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';
import {
    ButtonGroup,
    Dropdown,
    DropdownButton,
    Nav,
    Navbar,
    NavDropdown,
    NavItem,
} from 'react-bootstrap';

import { BiBookmark } from 'react-icons/bi';
import { FiBell, FiMessageCircle } from 'react-icons/fi';

import {
    AdditionalListing,
    LogoutButton,
    ContainerWrapper,
    CollapseButton,
    DropdownWrapper,
    Hr,
    IconContent,
    DropdownIcon,
    MenuItem,
    MenuText,
    MenuListing,
    MainContent,
    PinnedText,
    PinnedItem,
    PinnedWrapperIcon,
    PinnedListing,
    PinnedIcon,
    SidebarWrapper,
    SidebarHeader,
    SidebarBody,
    SidebarFooter,
    PinnedTitle,
    PinnedTitleListing,
    MobileNavbar,
    CustomToggle,
    DropdownMobile,
} from './styles';

import DirectoryIcon from '@/assets/svg/icons/directory.svg';
import ProjectIcon from '@/assets/svg/icons/project.svg';

import ChevronRightIcon from '@/assets/svg/icons/chevron-right-double.svg';
import ChevronLeftIcon from '@/assets/svg/icons/chevron-left-double.svg';
import SignOutIcon from '@/assets/svg/icons/arrow-right-from-bracket.svg';
import CircleQuestionIcon from '@/assets/svg/icons/circle-question.svg';
import GearIcon from '@/assets/svg/icons/gear.svg';
import ExpandWideIcon from '@/assets/svg/icons/expand-wide.svg';
import TagsIcon from '@/assets/svg/icons/tags.svg';
import PinIcon from '@/assets/svg/icons/pin.svg';
import TenderBox from '@/assets/svg/icons/tender-box.svg';

import RightSidebar from '@/components/organism/RightSidebar';
import CountdownDeleteAccount from '@/components/organism/CountdownDeleteAccount';
import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';
import {
    getNotificationsToast,
    getNotifications,
    getUnreadNotifications,
} from '@/routes/Dashboard/Notification/redux/actions';

import AddProject from './Project/components/AddProject';
import AddBug from './Project/components/AddBug';

import { logout } from '../Authentication/Login/redux/actions';
import { getCurrentProfile } from './Setting/PersonalSetting/redux/actions';
import {
    getAllProjectNotificationFloat,
    getAllProjectNotifications,
    getAllUnreadProjectNotifications,
} from '../ProjectDashboard/Notification/redux/actions';

import { ChannelsProvider, useGeneralNotification } from '@/utility/Channels';
import { getToken, getId } from '@/utility/Utils';
import { RootState } from '@/redux/store';
import BasicTooltip from '@/components/atoms/Tooltips';
import {
    getIpAddress,
    getWallpaperGeneralDashboard,
    getWallpaperProjectDashboard,
} from './Home/redux/actions';
import { Slide, toast } from 'react-toastify';
import CustomToast from '@/components/molecules/ToastBasic';
import { getAllUserChat, getTotalOnlineChat, setOnline } from './Chat/redux/actions';

import favicon from '@/assets/logo/apple-icon.png';
import ConAppLogo from '@/assets/svg/new_conapp_logo_transaprent.webp';
// import { getCurrentProfileOnProject } from '../ProjectDashboard/Settings/redux/actions';

import { meiliGetChatLists } from '@/utility/MeiliSearch';
import { AiOutlineAim } from 'react-icons/ai';

interface DropDownItemsProps {
    id: string;
    label: string;
    icon: string | JSX.Element;
    to: string;
    active?: boolean;
    roles?: string[];
    onClick?: () => void;
}

interface ItemProps {
    id: string;
    label: string;
    icon: string;
    to: string;
    isDropdown?: boolean;
    dropdownItems?: DropDownItemsProps[];
    onClick?: () => void;
    active?: boolean;
    disabled: boolean;
    roles?: string[];
}

export default function Layout(): JSX.Element {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const innerRef = useRef(null);
    const { id } = useParams();

    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const [isExpand, setIsExpand] = useState<boolean>(false);

    const [modal, setModal] = useState({
        add: false,
        bug: false,
        logout: false,
    });

    const unreadNotificationState = useSelector(
        (state: RootState) => state.unreadNotifications,
    );
    const allUnreadProjectNotificationState = useSelector(
        (state: RootState) => state.allUnreadProjectNotification,
    );
    const loginState = useSelector((state: RootState) => state.login);
    const currentProfileState = useSelector((state: RootState) => state.currentProfile);

    const ipState = useSelector((state: RootState) => state.ip);

    const pinnedProjects = currentProfileState?.response?.pinned_projects;
    const deletedStatus = currentProfileState?.response?.account_deletion_request;
    const subscriptionStatus: boolean =
        currentProfileState?.response?.subscription?.active;
    const suspendedStatus: boolean = currentProfileState?.response?.suspended;
    const userRole = currentProfileState?.response?.role?.name;

    const active = location.pathname;
    const totalNotification =
        id !== undefined
            ? allUnreadProjectNotificationState?.totalUnread
            : unreadNotificationState?.totalUnread;

    const notificationParams = {
        page: 1,
        per_page: 10,
        search: '',
    };

    const menuItems: ItemProps[] = [
        {
            id: 'dashboard',
            label: 'Dashboard',
            icon: ExpandWideIcon,
            to: '/',
            active: true,
            disabled: false,
            roles: ['Company User', 'InHouse User'],
            onClick: () => {
                setIsCollapsed(false);
                setIsExpand(false);
            },
        },
        {
            id: 'projects',
            label: 'Projects',
            icon: ProjectIcon,
            to: '#',
            isDropdown: true,
            active: active.includes('/project'),
            disabled: false,
            roles: ['Company User', 'InHouse User'],
            dropdownItems: [
                {
                    id: 'newProject',
                    label: 'New Project',
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            height={26}
                            width={26}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 10.5v6m3-3H9m4.06-7.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                            />
                        </svg>
                    ),
                    to: '#',
                    onClick: () => {
                        setIsCollapsed(false);
                        setIsExpand(false);
                        setModal({ ...modal, add: true });
                    },
                    roles: ['Company User', 'InHouse User'],
                },
                {
                    id: 'projectList',
                    label: 'Project List',
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            height={26}
                            width={26}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                            />
                        </svg>
                    ),
                    to: '/project',
                    onClick: () => {
                        setIsCollapsed(false);
                        setIsExpand(false);
                    },
                    roles: ['Company User', 'InHouse User'],
                    active: active.includes('/project'),
                },
            ],
        },
        {
            id: 'tenderBox',
            label: 'Tender Box',
            icon: TenderBox,
            to: '/tender-box',
            active: true,
            disabled: false,
            roles: ['Company User'],
            onClick: () => {
                setIsCollapsed(false);
                setIsExpand(false);
            },
        },
        {
            id: 'directory',
            label: 'Directory',
            icon: DirectoryIcon,
            to: '/directory',
            active: true,
            disabled: false,
            onClick: () => {
                setIsCollapsed(false);
                setIsExpand(false);
            },
            roles: ['Company User', 'InHouse User'],
        },
    ];

    const additionalItems: ItemProps[] = [
        {
            id: 'help',
            label: 'Help',
            icon: CircleQuestionIcon,
            to: '/help',
            active: true,
            disabled: false,
            onClick: () => {
                setIsCollapsed(false);
                setIsExpand(false);
            },
        },
        {
            id: 'settings',
            label: 'Settings',
            icon: GearIcon,
            to: '',
            isDropdown: true,
            active: active.includes('/setting'),
            disabled: false,
            dropdownItems: [
                {
                    id: 'subscription',
                    label: 'Subscription',
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            height={26}
                            width={26}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 6h.008v.008H6V6z"
                            />
                        </svg>
                    ),
                    to: '/setting/subscription',
                    active: active === '/setting/subscription',
                    roles: ['Company User'],
                },
                {
                    id: 'personalInfo',
                    label: 'Personal Info',
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            height={26}
                            width={26}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                            />
                        </svg>
                    ),
                    to: '/setting/profile',
                    active: active === '/setting/profile',
                    roles: ['Company User', 'InHouse User'],
                },
                {
                    id: 'companyInfo',
                    label: 'Company Info',
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            height={26}
                            width={26}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                            />
                        </svg>
                    ),
                    to: '/setting/company',
                    active: active === '/setting/company',
                    roles: ['Company User'],
                },
                {
                    id: 'notification',
                    label: 'Notification',
                    icon: (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            height={26}
                            width={26}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                            />
                        </svg>
                    ),
                    to: '/setting/notification',
                    active: active === '/setting/notification',
                    roles: ['Company User', 'InHouse User'],
                },
            ],
        },
    ];

    // Function for get on mount
    useEffect(() => {
        dispatch(getCurrentProfile());
        dispatch(setOnline());
        dispatch(getIpAddress());
        dispatch(getTotalOnlineChat());
        dispatch(
            getAllUserChat({
                page: 1,
                per_page: 9999,
                search: '',
                sort_by: 'name',
                sort_asc: 1,
            }),
        );
    }, []);

    // TODO: MEILIs GET CHAT LIST
    useEffect(() => {
        meiliGetChatLists(results => {
            // dispatch to redux
            dispatch({
                type: 'CHAT_LIST_BROADCAST',
                payload: results?.hits,
            });
        });
    }, []);

    useEffect(() => {
        const intervalFetch = (): void => {
            dispatch(getTotalOnlineChat());
        };
        const setInter = setInterval(intervalFetch, 30000);

        return () => clearInterval(setInter);
    }, []);

    useEffect(() => {
        if (ipState?.status === 200) {
            dispatch(getWallpaperGeneralDashboard(ipState?.response?.ipString));
            dispatch(getWallpaperProjectDashboard(ipState?.response?.ipString));
        }
    }, [ipState]);

    /**
     * Function for get notification if account status not  deleted
     */
    useEffect(() => {
        if (deletedStatus === null && deletedStatus !== undefined) {
            dispatch(getNotificationsToast(notificationParams));
            dispatch(getUnreadNotifications(notificationParams));
        }
    }, [deletedStatus]);

    /**
     * Function for if account is suspended then redirect to account suspend page
     */
    useEffect(() => {
        if (!subscriptionStatus && subscriptionStatus !== undefined) {
            active !== '/setting/subscription' && navigate('/account-suspend');
        }
    }, [subscriptionStatus]);

    /**
     * Function if account is suspended then redirect to account suspend page
     */
    useEffect(() => {
        if (suspendedStatus !== undefined && suspendedStatus) {
            navigate('/account-terminated');
        }
    }, [suspendedStatus]);

    /**
     * Function for handle notification
     *
     * @returns void
     */
    const handleNotification = (): void => {
        // setNotification(!notification);
        if (
            active === '/notification' ||
            active === `/project/${id as string}/notification`
        ) {
            return navigate(-1);
        }

        id !== undefined
            ? navigate(`/project/${id}/notification`)
            : navigate('/notification');
    };

    /**
     * Function for open modal add bug
     *
     * @returns void
     */
    const handleOpenModalAddBug = (): void => {
        setModal({ ...modal, bug: true });
    };

    /**
     * Function for handle logout
     *
     * @return
     */
    const handleLogout = (): void => {
        dispatch(logout());
    };

    return (
        <Fragment>
            <ContainerWrapper id="general-main-background" active={active}>
                <MobileNavbar
                    expanded={isExpand}
                    fixed="top"
                    bg="white"
                    expand="lg"
                    style={{
                        padding: '5px 11px',
                    }}
                >
                    <CustomToggle
                        aria-controls="basic-navbar-nav"
                        onClick={() => setIsExpand(!isExpand)}
                    />
                    <div
                        className="d-flex justify-content-end justify-content-md-between align-items-center"
                        style={{
                            width: '57%',
                        }}
                    >
                        <div className="d-none d-md-block">
                            <img
                                src={ConAppLogo}
                                alt="logo"
                                style={{ width: '140px', height: 'auto' }}
                                onClick={() => navigate('/')}
                                role="button"
                            />
                        </div>
                        {subscriptionStatus ? (
                            <div className="d-flex">
                                <button
                                    type="button"
                                    className={`btn position-relative btn-mobile ${
                                        active === '/chat' ? 'active' : ''
                                    }`}
                                    onClick={() => {
                                        setIsExpand(false);
                                        navigate('/chat');
                                    }}
                                >
                                    <FiMessageCircle
                                        size={20}
                                        color={active === '/chat' ? '#fff' : '#737373'}
                                    />
                                </button>
                                <button
                                    type="button"
                                    className={`btn position-relative btn-mobile 
                                    ${
                                        active === '/notification' ||
                                        active === `/project/${id as string}/notification`
                                            ? 'active'
                                            : ''
                                    }
                                `}
                                    onClick={() => {
                                        setIsExpand(false);
                                        handleNotification();
                                    }}
                                >
                                    <FiBell
                                        size={20}
                                        color={
                                            active === '/notification'
                                                ? '#fff'
                                                : '#737373'
                                        }
                                    />
                                    {totalNotification > 0 && (
                                        <span
                                            className="position-absolute translate-middle badge rounded-pill bg-danger"
                                            style={{
                                                top: 10,
                                                fontSize: 10,
                                            }}
                                        >
                                            {totalNotification}
                                            <span className="visually-hidden">
                                                unread notification
                                            </span>
                                        </span>
                                    )}
                                </button>
                                <button
                                    type="button"
                                    className="btn position-relative btn-mobile"
                                    onClick={() => handleOpenModalAddBug()}
                                >
                                    <AiOutlineAim size={20} color="#737373" />
                                </button>
                            </div>
                        ) : (
                            <div>
                                {/* Empty dif,this is for, it the bell and message icon was gone, then the logo will fixed on middle */}
                            </div>
                        )}
                    </div>

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto mt-2 mb-3">
                            {!suspendedStatus &&
                                subscriptionStatus &&
                                menuItems.map((item, index) =>
                                    item.isDropdown !== true ? (
                                        item.disabled ? (
                                            <div key={index}>
                                                <div
                                                    className="p-2 text-decoration-none"
                                                    style={{
                                                        cursor: 'default',
                                                    }}
                                                >
                                                    {item.label}
                                                </div>
                                            </div>
                                        ) : (
                                            <NavLink
                                                key={index}
                                                to={item.to}
                                                className={`p-2 text-decoration-none link-dark link-mobile ${
                                                    item.active ?? false ? '' : 'active'
                                                } ${
                                                    item.roles?.includes(userRole) ??
                                                    false
                                                        ? 'd-block'
                                                        : 'd-none'
                                                }
                                                `}
                                                onClick={item.onClick}
                                            >
                                                {item.label}
                                            </NavLink>
                                        )
                                    ) : (
                                        <DropdownMobile
                                            key={index}
                                            title={item.label}
                                            className=""
                                        >
                                            {item.dropdownItems?.map(dropdownItem => (
                                                <NavDropdown.Item
                                                    key={dropdownItem.id}
                                                    as={Link}
                                                    to={dropdownItem.to}
                                                    active={dropdownItem.active}
                                                    onClick={dropdownItem.onClick}
                                                    className={
                                                        dropdownItem.roles?.includes(
                                                            userRole,
                                                        ) ?? false
                                                            ? 'd-block'
                                                            : 'd-none'
                                                    }
                                                >
                                                    {dropdownItem.label}
                                                </NavDropdown.Item>
                                            ))}
                                        </DropdownMobile>
                                    ),
                                )}

                            {!suspendedStatus &&
                                subscriptionStatus &&
                                pinnedProjects?.length > 0 && (
                                    <DropdownMobile title="Pinned Projects" className="">
                                        {pinnedProjects?.map((item: any, index: any) => (
                                            <NavDropdown.Item
                                                key={index}
                                                as={Link}
                                                to={`/project/${
                                                    item.id as string
                                                }/summary`}
                                                active={item.active}
                                                onClick={() => setIsExpand(!isExpand)}
                                            >
                                                {item.name}
                                            </NavDropdown.Item>
                                        ))}
                                    </DropdownMobile>
                                )}
                            {!suspendedStatus &&
                                additionalItems.map((item, index) =>
                                    item.isDropdown !== true ? (
                                        <NavLink
                                            key={index}
                                            to={item.to}
                                            className="p-2 text-decoration-none link-dark"
                                            onClick={item.onClick}
                                        >
                                            {item.label}
                                        </NavLink>
                                    ) : (
                                        <DropdownMobile
                                            key={index}
                                            title={item.label}
                                            className=""
                                        >
                                            {subscriptionStatus ? (
                                                item.dropdownItems?.map(dropdownItem => (
                                                    <NavDropdown.Item
                                                        key={dropdownItem.id}
                                                        as={Link}
                                                        to={dropdownItem.to}
                                                        active={dropdownItem.active}
                                                        onClick={() =>
                                                            setIsExpand(!isExpand)
                                                        }
                                                        className={
                                                            dropdownItem.roles?.includes(
                                                                userRole,
                                                            ) ?? false
                                                                ? 'd-block'
                                                                : 'd-none'
                                                        }
                                                    >
                                                        {dropdownItem.label}
                                                    </NavDropdown.Item>
                                                ))
                                            ) : (
                                                <NavDropdown.Item
                                                    as={Link}
                                                    to="/setting/subscription"
                                                    onClick={() => setIsExpand(!isExpand)}
                                                >
                                                    Subscription
                                                </NavDropdown.Item>
                                            )}
                                        </DropdownMobile>
                                    ),
                                )}
                            <NavItem
                                role="button"
                                onClick={() => {
                                    setModal(prev => ({ ...prev, logout: true }));
                                    setIsCollapsed(false);
                                }}
                                className="p-2 text-decoration-none link-dark"
                            >
                                Logout
                            </NavItem>
                        </Nav>
                    </Navbar.Collapse>
                </MobileNavbar>
                {/* End small and medium */}
                {/* ----------------- */}
                {/* Large nav */}

                <SidebarWrapper isCollapsed={isCollapsed.toString()} active={active}>
                    <SidebarHeader isCollapsed={isCollapsed.toString()}>
                        {/* Button collapse */}
                        <CollapseButton
                            id="collapse-sidebar"
                            onClick={(): void => setIsCollapsed(!isCollapsed)}
                        >
                            <img
                                src={isCollapsed ? ChevronLeftIcon : ChevronRightIcon}
                                alt="collapse-icon"
                                style={{ width: '12px' }}
                            />
                        </CollapseButton>
                        {/* End button collapse */}
                    </SidebarHeader>

                    {/* Sidebar body */}
                    {!suspendedStatus && (
                        <SidebarBody isCollapsed={isCollapsed.toString()}>
                            <Hr />
                            {/* Main menu */}
                            {subscriptionStatus &&
                                menuItems.map((item, index) => (
                                    <MenuListing
                                        key={index}
                                        isCollapsed={isCollapsed.toString()}
                                        className={`${
                                            item.roles?.includes(userRole) ?? false
                                                ? 'd-block'
                                                : 'd-none'
                                        }`}
                                    >
                                        {/* Dropdown */}
                                        {item.isDropdown === true ? (
                                            <DropdownButton
                                                as={ButtonGroup}
                                                key={item.id}
                                                id={`dropdown-menu-${item.id}`}
                                                drop="end"
                                                className={`
                                                   ${
                                                       item.active ?? false ? 'show' : ''
                                                   } gap-0
                                                `}
                                                title={
                                                    <DropdownWrapper>
                                                        <BasicTooltip text={item.label}>
                                                            <Fragment>
                                                                <IconContent
                                                                    className="icon-active"
                                                                    icon={item.icon}
                                                                />
                                                                <MenuText className="menu-text">
                                                                    {item.label}
                                                                </MenuText>
                                                            </Fragment>
                                                        </BasicTooltip>
                                                    </DropdownWrapper>
                                                }
                                            >
                                                {subscriptionStatus &&
                                                    item.dropdownItems?.map(
                                                        dropdownItem => (
                                                            <Dropdown.Item
                                                                key={dropdownItem.id}
                                                                as={Link}
                                                                to={dropdownItem.to}
                                                                active={
                                                                    dropdownItem.active
                                                                }
                                                                onClick={
                                                                    dropdownItem.onClick
                                                                }
                                                                className={
                                                                    dropdownItem.roles?.includes(
                                                                        userRole,
                                                                    ) ?? false
                                                                        ? 'd-block'
                                                                        : 'd-none'
                                                                }
                                                            >
                                                                <span className="me-2">
                                                                    {dropdownItem.icon}
                                                                </span>
                                                                <span>
                                                                    {dropdownItem.label}
                                                                </span>
                                                            </Dropdown.Item>
                                                        ),
                                                    )}
                                            </DropdownButton>
                                        ) : (
                                            <MenuItem
                                                to={item.to}
                                                className={'menu-link'}
                                                onClick={item.onClick}
                                                id={`menu-item-${item.id}`}
                                                // isCollapsed={isCollapsed.toString()}
                                            >
                                                <BasicTooltip text={item.label}>
                                                    <Fragment>
                                                        <IconContent
                                                            className="icon-active"
                                                            icon={item.icon}
                                                        />
                                                        <MenuText className="menu-text">
                                                            {item.label}
                                                        </MenuText>
                                                    </Fragment>
                                                </BasicTooltip>
                                            </MenuItem>
                                        )}
                                    </MenuListing>
                                ))}
                            {/* End main menu */}
                        </SidebarBody>
                    )}
                    {/* End sidebar body */}

                    {/* Sidebar Footer */}
                    <SidebarFooter isCollapsed={isCollapsed.toString()}>
                        {/* Pinned Project or circle image on left sidebar */}
                        {subscriptionStatus && pinnedProjects?.length > 0 && (
                            <PinnedTitleListing className="mb-3">
                                <PinnedTitle isCollapsed={isCollapsed.toString()}>
                                    <PinnedWrapperIcon>
                                        <BiBookmark size={22} color="#838383" />
                                    </PinnedWrapperIcon>
                                    <PinnedText className="pinned-text">
                                        <span className="ps-1">Pinned Projects</span>
                                    </PinnedText>
                                </PinnedTitle>
                            </PinnedTitleListing>
                        )}

                        {/* List Pinned */}
                        {subscriptionStatus &&
                            pinnedProjects?.map((item: any, index: any) => (
                                <PinnedListing
                                    key={index}
                                    isCollapsed={isCollapsed.toString()}
                                    className="pinned-list-large"
                                >
                                    <PinnedItem
                                        to={`/project/${item.id as string}/summary`}
                                        className="menu-link"
                                        id={`pinned-project-${item.id as string}`}
                                        onClick={() => setIsCollapsed(false)}
                                        active={active
                                            .includes(`/project/${item.id as string}`)
                                            .toString()}
                                    >
                                        <BasicTooltip text={item.name}>
                                            <PinnedWrapperIcon>
                                                <PinnedIcon src={item.picture} alt="PW" />
                                            </PinnedWrapperIcon>
                                        </BasicTooltip>
                                        <PinnedText className="pinned-text">
                                            {item.name}
                                        </PinnedText>
                                    </PinnedItem>
                                </PinnedListing>
                            ))}

                        {/* Dropdown Pinned Project */}
                        {pinnedProjects?.length > 0 && subscriptionStatus && (
                            <AdditionalListing
                                isCollapsed={isCollapsed.toString()}
                                className="pinned-list-small"
                            >
                                <DropdownButton
                                    as={ButtonGroup}
                                    drop="end"
                                    className="gap-0"
                                    title={
                                        <DropdownWrapper>
                                            <BasicTooltip text={'Pinned Project'}>
                                                <Fragment>
                                                    <IconContent
                                                        className="icon-active"
                                                        icon={PinIcon}
                                                    />
                                                    <MenuText className="menu-text ">
                                                        Pinned Project
                                                    </MenuText>
                                                </Fragment>
                                            </BasicTooltip>
                                        </DropdownWrapper>
                                    }
                                >
                                    {pinnedProjects?.map((item: any, index: any) => (
                                        <Dropdown.Item
                                            key={index}
                                            as={Link}
                                            to={`/project/${item.id as string}/summary`}
                                            active={active.includes(
                                                `/project/${item.id as string}`,
                                            )}
                                            onClick={() => setIsCollapsed(false)}
                                        >
                                            <span>{item.name}</span>
                                        </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            </AdditionalListing>
                        )}
                        {/* End pinned project */}

                        <Hr />

                        <li className="my-2">
                            <span
                                className="bg-primary text-white fw-semibold px-2 py-1 w-100 text-center"
                                style={{
                                    display: 'inline-block',
                                    fontSize: 10,
                                    borderRadius: 20,
                                }}
                            >
                                BETA
                            </span>
                        </li>

                        {/* Menu setting and other */}
                        {!suspendedStatus &&
                            additionalItems.map((item, index) => (
                                <AdditionalListing
                                    key={index}
                                    isCollapsed={isCollapsed.toString()}
                                >
                                    {/* Dropdown */}
                                    {item.isDropdown === true ? (
                                        <DropdownButton
                                            as={ButtonGroup}
                                            key={item.id}
                                            id={`dropdown-additional-${item.id}`}
                                            drop="end"
                                            className={`${
                                                item.active ?? false ? 'show' : ''
                                            } gap-0`}
                                            title={
                                                <DropdownWrapper>
                                                    <BasicTooltip text={item.label}>
                                                        <Fragment>
                                                            <IconContent
                                                                className="icon-active"
                                                                icon={item.icon}
                                                            />
                                                            <MenuText className="menu-text ">
                                                                {item.label}
                                                            </MenuText>
                                                        </Fragment>
                                                    </BasicTooltip>
                                                </DropdownWrapper>
                                            }
                                        >
                                            {subscriptionStatus ? (
                                                item.dropdownItems?.map(
                                                    (dropdownItem, index) => (
                                                        <Dropdown.Item
                                                            key={dropdownItem.id}
                                                            as={Link}
                                                            to={dropdownItem.to}
                                                            active={dropdownItem.active}
                                                            onClick={() =>
                                                                setIsCollapsed(false)
                                                            }
                                                            className={
                                                                dropdownItem.roles?.includes(
                                                                    userRole,
                                                                ) ?? false
                                                                    ? 'd-block'
                                                                    : 'd-none'
                                                            }
                                                        >
                                                            <span className="me-2">
                                                                {dropdownItem.icon}
                                                            </span>
                                                            <span>
                                                                {dropdownItem.label}
                                                            </span>
                                                        </Dropdown.Item>
                                                    ),
                                                )
                                            ) : (
                                                <Dropdown.Item
                                                    as={Link}
                                                    to="/setting/subscription"
                                                    active={
                                                        active === '/setting/subscription'
                                                    }
                                                    onClick={() => setIsCollapsed(false)}
                                                >
                                                    <DropdownIcon
                                                        className="dropdown-active"
                                                        icon={TagsIcon}
                                                    />
                                                    <span className="">Subscription</span>
                                                </Dropdown.Item>
                                            )}
                                        </DropdownButton>
                                    ) : (
                                        <MenuItem
                                            to={item.to}
                                            className="menu-link"
                                            onClick={item.onClick}
                                            // isCollapsed={isCollapsed.toString()}
                                        >
                                            <BasicTooltip text={item.label}>
                                                <Fragment>
                                                    <IconContent
                                                        className="icon-active"
                                                        icon={item.icon}
                                                    />
                                                    <MenuText className="menu-text">
                                                        {item.label}
                                                    </MenuText>
                                                </Fragment>
                                            </BasicTooltip>
                                        </MenuItem>
                                    )}
                                </AdditionalListing>
                            ))}
                        {/* End menu setting */}

                        {/* Logout Button */}
                        <AdditionalListing isCollapsed={isCollapsed.toString()}>
                            <LogoutButton
                                type="button"
                                onClick={() =>
                                    setModal(prev => ({ ...prev, logout: true }))
                                }
                                className="menu-link btn"
                                isCollapsed={isCollapsed.toString()}
                            >
                                <BasicTooltip text={'Logout'}>
                                    <Fragment>
                                        <IconContent
                                            className="icon-active"
                                            icon={SignOutIcon}
                                        />
                                        <MenuText className="menu-text">Logout</MenuText>
                                    </Fragment>
                                </BasicTooltip>
                            </LogoutButton>
                        </AdditionalListing>
                        {/* Logout Button */}
                    </SidebarFooter>
                    {/* End footer sidebar */}
                </SidebarWrapper>

                {/* Main content of routes */}
                <MainContent isCollapsed={isCollapsed.toString()} className="">
                    <Outlet />
                </MainContent>
                {/* End main content */}
                {/* Notification toast */}

                {/* End notification toast */}
                {/* Modal Deleted Account */}
                <CountdownDeleteAccount />
                {/* End Modal deleted account */}
                <ChannelsProvider authUser={getId()} authToken={getToken()}>
                    <BroadcastGeneralNotificationList authUserId={getId()} />
                    {subscriptionStatus && (
                        <RightSidebar
                            handleNotification={handleNotification}
                            handleOpenModalAddBug={handleOpenModalAddBug}
                            innerRef={innerRef}
                            count={
                                id !== undefined
                                    ? allUnreadProjectNotificationState?.totalUnread
                                    : unreadNotificationState?.totalUnread
                            }
                        />
                    )}
                </ChannelsProvider>

                {/* Modal add project */}
                <AddProject modal={modal.add} setModal={setModal} />
                <AddBug modal={modal.bug} setModal={setModal} />

                {/* Modal logout */}
                <ModalDashboard
                    modal={modal.logout}
                    setModal={setModal}
                    variant="danger"
                    type="logout"
                    title="Logout?"
                    body="Are you sure want to logout?"
                    withCancel
                    onConfirm={handleLogout}
                    countdownButton={true}
                    loading={loginState?.loading}
                />

                {/* End modal add project */}
            </ContainerWrapper>
        </Fragment>
    );
}

function BroadcastGeneralNotificationList({
    authUserId,
}: {
    authUserId: number;
}): JSX.Element {
    const mainURL: string = import.meta.env.VITE_MAIN_URL;
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();

    const [notificationData, setNotificationData] = useState<{
        redirect_url: string;
        id: number;
        title: string;
        description: string;
        created_at: number;
        actions: Array<{
            name: string;
            method: string;
            accept: string;
            uri: string;
        }>;
    } | null>(null);

    const notificationParams = {
        page: 1,
        per_page: 10,
        search: '',
    };

    /**
     * Passing one income channel to state
     *
     * @param notification
     * @returns void
     */
    const handleNotificationsEvent = useCallback((result: any) => {
        const notification = result?.notification;

        setNotificationData(notification);
    }, []);

    // Get notification on live in app notification
    useEffect(() => {
        if (notificationData !== null) {
            // General
            dispatch(getNotifications(notificationParams));
            // dispatch(getNotificationsToast(notificationParams));
            dispatch(getUnreadNotifications(notificationParams));

            // Project
            if (id !== undefined) {
                dispatch(
                    getAllProjectNotifications({
                        project_id: id,
                        ...notificationParams,
                    }),
                );
                dispatch(
                    getAllUnreadProjectNotifications({
                        project_id: id,
                        ...notificationParams,
                    }),
                );
                dispatch(
                    getAllProjectNotificationFloat({
                        project_id: id,
                        ...notificationParams,
                    }),
                );

                // TODO: Delete because after notification uppear, it will refresh the page
                // if (notificationData?.actions[0]?.name !== 'Download') {
                //     dispatch(getCurrentProfileOnProject(id));
                // }
            }

            const name = notificationData?.actions[0]?.name;
            const baseUrl = import.meta.env.VITE_FE_URL;
            const uri = notificationData?.actions[0]?.uri?.replace(baseUrl, '');

            let chatId = 0;

            if (name === 'Reply Now') {
                const url = new URL(notificationData?.actions[0]?.uri);
                const params = new URLSearchParams(url.search);
                chatId = parseInt(params.get('chat_id') ?? '0');
            } else if (name === 'Download') {
                window.open(notificationData?.actions[0]?.uri, '_blank');
            }

            setTimeout(() => {
                toast(
                    <CustomToast
                        title={notificationData.title ?? 'Notification'}
                        body={notificationData.description ?? ''}
                        date={notificationData.created_at ?? 0}
                        redirect={
                            notificationData.redirect_url ?? mainURL + '/notification'
                        }
                    />,
                    {
                        toastId: notificationData.id,
                        type: 'info',
                        position: 'bottom-right',
                        autoClose: 5000,
                        hideProgressBar: true,
                        icon: false,
                        transition: Slide,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    },
                );

                const notification = new Notification(
                    notificationData.title ?? 'Notification',
                    {
                        tag: 'ConstructApp',
                        body: notificationData.description,
                        icon: favicon,
                    },
                );

                notification.onclick = () => {
                    switch (name) {
                        case 'Reply Now':
                            return navigate(uri, {
                                state: {
                                    id: chatId,
                                },
                            });
                        case 'go to':
                            return navigate(uri);
                        case 'Accept Invitation':
                            return navigate(uri);
                        default:
                            return <></>;
                    }
                };
            }, 500);
        }
    }, [notificationData]);

    // Get notification on live
    useGeneralNotification(authUserId, handleNotificationsEvent);

    return <section></section>;
}
