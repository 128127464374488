import { loginReducer } from '@/routes/Authentication/Login/redux/reducers';
import {
    googleRedirectReducer,
    registerReducer,
    registerCompanyCompletedReducer,
    registerInhouseCompletedReducer,
    companyUserCredentialReducer,
    inhouseUserCredentialReducer,
    allNicheSubContractorReducer,
} from '@/routes/Authentication/Register/redux/reducers';

import {
    forgotPasswordEmailReducer,
    forgotPasswordPhoneReducer,
    resetPasswordEmailReducer,
    resetPasswordPhoneReducer,
    verifyOTPReducer,
} from '@/routes/Authentication/ForgotPassword/redux/reducers';

import {
    settingCompanyReducer,
    updateCompanyReducer,
    updateCompanyPictureReducer,
    allActiveInhouseReducer,
    allInactiveInhouseReducer,
    detailInhouseReducer,
    activatedInhouseReducer,
    terminatedInhouseReducer,
    invitedInhouseReducer,
    allPendingInhouseReducer,
    resendInvitationReducer,
    cancelInvitationReducer,
} from '@/routes/Dashboard/Setting/CompanySetting/redux/reducers';

import {
    allNotificationsReducer,
    notificationsToastReducer,
    unreadNotificationsReducer,
    actionsNotificationsReducer,
    acceptDeclineProjectInvitationReducer,
} from '@/routes/Dashboard/Notification/redux/reducers';

import {
    currentProfileReducer,
    updateCurrentProfileReducer,
    updatePictureProfileReducer,
    updateCurrentPasswordReducer,
    resetProfilePasswordEmailReducer,
    resetProfilePasswordPhoneReducer,
    deleteAccountReducer,
    undoDeleteAccountReducer,
    sendPhoneVerificationReducer,
    resendPhoneVerificationReducer,
    resendOtpVerificationReducer,
    verifyOtpProfileReducer,
    acceptOtpProfileReducer,
    resendEmailVerificationReducer,
} from '@/routes/Dashboard/Setting/PersonalSetting/redux/reducers';

import {
    notificationSettingReducer,
    updateNotificationSettingReducer,
} from '@/routes/Dashboard/Setting/NotificationSetting/redux/reducers';

import {
    chatListReducer,
    chatDetailsReducer,
    chatPinReducer,
    chatUnpinReducer,
    chatSendMessageReducer,
    chatChannelDeleteReducer,
    newDirectMessageReducer,
    newGroupMessageReducer,
    exitGroupReducer,
    kickGroupParticipantReducer,
    promoteGroupReducer,
    inviteToCurrentGroupReducer,
    allUserChatReducer,
    editGroupReducer,
    deleteChatReducer,
    totalOnlineChatReducer,
    setOnlineReducer,
    chatReceiveMessageReducer,
    chatMarkAsReadReducer,
} from '@/routes/Dashboard/Chat/redux/reducers';
import {
    allCompanyReducer,
    allCompanyMapReducer,
    detailCompanyReducer,
} from '@/routes/Dashboard/Directory/redux/reducers';

import {
    allProjectReducer,
    allTenderReducer,
    createProjectReducer,
    detailProjectReducer,
    inviteProjectMemberReducer,
    leaveProjectReducer,
    pinUnpinProjectReducer,
    updateProjectReducer,
    allMemberInvitedReducer,
    allMemberInhouseReducer,
    detailMemberInvitedReducer,
    detailMemberInhouseReducer,
    createBugReportReducer,
    checkUserEmailReducer,
    resendProjectInvitationReducer,
    cancelProjectInvitationReducer,
} from '@/routes/Dashboard/Project/redux/reducers';

import {
    intentStripeReducer,
    addPaymentMethodReducer,
    detailPaymentMethodReducer,
    allInvoiceReducer,
    detailInvoiceReducer,
    checkCouponReducer,
    applyCouponReducer,
    swapBillingReducer,
} from '@/routes/Dashboard/Setting/SubscriptionSetting/redux/reducers';

import {
    allProjectNotificationReducer,
    allUnreadProjectNotificationReducer,
    floatProjectNotificationReducer,
    projectNotificationActionReducer,
    notificationDownloadReducer,
} from '@/routes/ProjectDashboard/Notification/redux/reducers';

import { allProjectHistoryReducer } from '@/routes/ProjectDashboard/History/redux/reducers';

import {
    allPendingInvitationReducer,
    kickMemberProjectReducer,
    allNicheTradeReducer,
    actionNicheTradeReducer,
    detailNicheTradeReducer,
    awardingNullifyMemberReducer,
    currentProfileProjectReducer,
    updateMemberPermissionReducer,
} from '@/routes/ProjectDashboard/Settings/redux/reducers';

import {
    restoreTaskReducer,
    deleteTaskReducer,
    allTaskDesignReducer,
    allTaskConstructionReducer,
    allTaskPrivateReducer,
    allTaskArchiveReducer,
    searchPublicTaskReducer,
    searchPrivateTaskReducer,
    addTaskReducer,
    detailTaskReducer,
    shareTaskReducer,
    archiveTaskReducer,
    addSubtaskScheduleReducer,
    updateTaskOrderingReducer,
    updateTaskRangeReducer,
    allProjectMemberScheduleReducer,
    findPermissionsByEmailReducer,
    updatePermissionAssigneeReducer,
    addPermissionAssigneeDirectlyReducer,
    detailTaskHistoryReducer,
    detailTaskCommentReducer,
    postTaskCommentReducer,
    editTaskCommentReducer,
    deleteTaskCommentReducer,
    removeTaskAssigneeReducer,
    collapseTaskReducer,
} from '@/routes/ProjectDashboard/Schedule/redux/reducers';

import {
    advertisementWallpaperDashboardReducer,
    ipReducer,
    weatherReducer,
} from '@/routes/Dashboard/Home/redux/reducers';
import {
    allDrawingPrivateFolderReducer,
    allDrawingPrivateSpaceReducer,
    allDrawingPublicFolderReducer,
    allDrawingPublicSpaceReducer,
    createDrawingFolderReducer,
    deleteDrawingReducer,
    detailDrawingReducer,
    pinUnpinDrawingSpaceReducer,
    updateDrawingReducer,
    uploadDrawingFileReducer,
    allDrawingCompareFileReducer,
    shareDrawingReducer,
    sendDrawingReducer,
    restoreDrawingReducer,
    inviteUserDrawingReducer,
    pasteDrawingReducer,
    historyDrawingReducer,
    sharedUserPrivateDrawingReducer,
    updateUserPrivateDrawingReducer,
    deleteUserPrivateDrawingReducer,
    allDrawingRestoreSpaceReducer,
    createShareLinkDrawingReducer,
    getShareLinkDrawingReducer,
} from '@/routes/ProjectDashboard/Drawing/redux/reducers';
import {
    allDocumentPublicSpaceReducer,
    allDocumentPrivateSpaceReducer,
    allDocumentRestoreSpaceReducer,
    allDocumentPublicFolderReducer,
    allDocumentPrivateFolderReducer,
    createDocumentFolderReducer,
    pinUnpinDocumentSpaceReducer,
    detailDocumentReducer,
    historyDocumentReducer,
    updateDocumentReducer,
    deleteDocumentReducer,
    restoreDocumentReducer,
    uploadDocumentFileReducer,
    sharedUserPrivateDocumentReducer,
    updateUserPrivateDocumentReducer,
    deleteUserPrivateDocumentReducer,
    allDocumentCompareFileReducer,
    sendDocumentReducer,
    shareDocumentReducer,
    inviteUserDocumentReducer,
    pasteDocumentReducer,
    getShareLinkDocumentReducer,
    createShareLinkDocumentReducer,
} from '@/routes/ProjectDashboard/Document/redux/reducers';

import {
    allTenderBoxReducer,
    allProjectLocationReducer,
    allProjectSectorReducer,
    openProjectToTenderReducer,
    closeProjectToTenderReducer,
    allTenderIntentionReducer,
    allProjectRangeValueReducer,
    sendTenderReducer,
    approveTenderReducer,
    rejectTenderReducer,
    editProjectTenderReducer,
} from '@/routes/Dashboard/TenderBox/redux/reducers';
import { getShareLinkSpaceDrawingReducer } from '@/routes/Global/DrawingGlobal/redux/reducers';
import { getShareLinkSpaceDocumentReducer } from '@/routes/Global/DocumentGlobal/redux/reducers';

const rootReducer = {
    // Authentications
    login: loginReducer,

    // Register
    register: registerReducer,
    googleRedirect: googleRedirectReducer,
    registerCompanyCompleted: registerCompanyCompletedReducer,
    registerInhouseCompleted: registerInhouseCompletedReducer,
    companyUserCredential: companyUserCredentialReducer,
    inhouseUserCredential: inhouseUserCredentialReducer,
    allNicheSubContractor: allNicheSubContractorReducer,

    // Settings - Company
    settingCompany: settingCompanyReducer,
    updateCompany: updateCompanyReducer,
    updateCompanyPicture: updateCompanyPictureReducer,
    allActiveInhouse: allActiveInhouseReducer,
    allInactiveInhouse: allInactiveInhouseReducer,
    allPendingInhouse: allPendingInhouseReducer,
    detailInhouse: detailInhouseReducer,
    activatedInhouse: activatedInhouseReducer,
    terminatedInhouse: terminatedInhouseReducer,
    invitedInhouse: invitedInhouseReducer,
    resendInvitation: resendInvitationReducer,
    cancelInvitation: cancelInvitationReducer,

    // Notifications
    allNotifications: allNotificationsReducer,
    notificationsToast: notificationsToastReducer,
    unreadNotifications: unreadNotificationsReducer,
    actionsNotifications: actionsNotificationsReducer,
    acceptDeclineProjectInvitation: acceptDeclineProjectInvitationReducer,

    // Settings - Notification
    notificationSetting: notificationSettingReducer,
    updateNotificationSetting: updateNotificationSettingReducer,

    // Settings - Profile
    currentProfile: currentProfileReducer,
    updateCurrentProfile: updateCurrentProfileReducer,
    updatePictureProfile: updatePictureProfileReducer,
    updateCurrentPassword: updateCurrentPasswordReducer,

    deleteAccount: deleteAccountReducer,
    undoDeleteAccount: undoDeleteAccountReducer,
    sendPhoneVerification: sendPhoneVerificationReducer,
    resendPhoneVerification: resendPhoneVerificationReducer,

    // Chat
    chatList: chatListReducer,
    chatDetails: chatDetailsReducer,
    chatSendMessage: chatSendMessageReducer,
    chatReceiveMessage: chatReceiveMessageReducer,
    chatPin: chatPinReducer,
    chatUnpin: chatUnpinReducer,

    chatChannelDelete: chatChannelDeleteReducer,
    newDirectMessage: newDirectMessageReducer,
    newGroupMessage: newGroupMessageReducer,
    exitGroup: exitGroupReducer,
    editGroup: editGroupReducer,
    kickGroupParticipant: kickGroupParticipantReducer,
    promoteGroup: promoteGroupReducer,
    inviteToCurrentGroup: inviteToCurrentGroupReducer,
    allUserChat: allUserChatReducer,
    deleteChat: deleteChatReducer,
    totalOnlineChat: totalOnlineChatReducer,
    setOnline: setOnlineReducer,
    chatMarkAsRead: chatMarkAsReadReducer,

    // Directory
    allCompany: allCompanyReducer,
    allCompanyMap: allCompanyMapReducer,
    detailCompany: detailCompanyReducer,

    // Project - Tender
    allProject: allProjectReducer,
    detailProject: detailProjectReducer,
    allTender: allTenderReducer,
    createProject: createProjectReducer,
    updateProject: updateProjectReducer,
    inviteProjectMember: inviteProjectMemberReducer,
    pinUnpinProject: pinUnpinProjectReducer,
    leaveProject: leaveProjectReducer,
    allMemberInvited: allMemberInvitedReducer,
    allMemberInhouse: allMemberInhouseReducer,
    detailMemberInvited: detailMemberInvitedReducer,
    detailMemberInhouse: detailMemberInhouseReducer,
    resendProjectInvitation: resendProjectInvitationReducer,
    cancelProjectInvitation: cancelProjectInvitationReducer,

    // Subscription
    intentStripe: intentStripeReducer,
    addPaymentMethod: addPaymentMethodReducer,
    detailPaymentMethod: detailPaymentMethodReducer,
    allInvoice: allInvoiceReducer,
    detailInvoice: detailInvoiceReducer,
    checkCoupon: checkCouponReducer,
    applyCoupon: applyCouponReducer,
    swapBilling: swapBillingReducer,

    // Project Summary
    allProjectNotification: allProjectNotificationReducer,
    allUnreadProjectNotification: allUnreadProjectNotificationReducer,
    floatProjectNotification: floatProjectNotificationReducer,
    projectNotificationAction: projectNotificationActionReducer,

    // Project History
    allProjectHistory: allProjectHistoryReducer,

    // Project Settings
    allPendingInvitation: allPendingInvitationReducer,
    kickMemberProject: kickMemberProjectReducer,
    allNicheTrade: allNicheTradeReducer,
    detailNicheTrade: detailNicheTradeReducer,
    actionNicheTrade: actionNicheTradeReducer,
    awardingNullifyMember: awardingNullifyMemberReducer,
    currentProfileProject: currentProfileProjectReducer,
    updateMemberPermission: updateMemberPermissionReducer,

    // Project Schedule
    allTaskDesign: allTaskDesignReducer,
    allTaskConstruction: allTaskConstructionReducer,
    allTaskPrivate: allTaskPrivateReducer,
    allTaskArchive: allTaskArchiveReducer,
    searchPublicTask: searchPublicTaskReducer,
    searchPrivateTask: searchPrivateTaskReducer,
    addTask: addTaskReducer,
    detailTask: detailTaskReducer,
    detailTaskHistory: detailTaskHistoryReducer,
    detailTaskComment: detailTaskCommentReducer,
    shareTask: shareTaskReducer,
    deleteTask: deleteTaskReducer,
    restoreTask: restoreTaskReducer,
    archiveTask: archiveTaskReducer,
    addSubtaskSchedule: addSubtaskScheduleReducer,
    updateTaskOrdering: updateTaskOrderingReducer,
    updateTaskRange: updateTaskRangeReducer,
    allProjectMemberSchedule: allProjectMemberScheduleReducer,
    findPermissionsByEmail: findPermissionsByEmailReducer,
    updatePermissionAssignee: updatePermissionAssigneeReducer,
    addPermissionAssigneeDirectly: addPermissionAssigneeDirectlyReducer,
    postTaskComment: postTaskCommentReducer,
    editTaskComment: editTaskCommentReducer,
    deleteTaskComment: deleteTaskCommentReducer,
    removeTaskAssignee: removeTaskAssigneeReducer,
    collapseTask: collapseTaskReducer,

    // Document Drawing V2
    allDocumentPublicSpace: allDocumentPublicSpaceReducer,
    allDocumentPrivateSpace: allDocumentPrivateSpaceReducer,
    allDocumentRestoreSpace: allDocumentRestoreSpaceReducer,
    allDocumentPublicFolder: allDocumentPublicFolderReducer,
    allDocumentPrivateFolder: allDocumentPrivateFolderReducer,
    createDocumentFolder: createDocumentFolderReducer,
    pinUnpinDocumentSpace: pinUnpinDocumentSpaceReducer,
    detailDocument: detailDocumentReducer,
    historyDocument: historyDocumentReducer,
    updateDocument: updateDocumentReducer,
    deleteDocument: deleteDocumentReducer,
    restoreDocument: restoreDocumentReducer,
    uploadDocumentFile: uploadDocumentFileReducer,
    sharedUserPrivateDocument: sharedUserPrivateDocumentReducer,
    updateUserPrivateDocument: updateUserPrivateDocumentReducer,
    deleteUserPrivateDocument: deleteUserPrivateDocumentReducer,
    allDocumentCompareFile: allDocumentCompareFileReducer,
    sendDocument: sendDocumentReducer,
    shareDocument: shareDocumentReducer,
    inviteUserDocument: inviteUserDocumentReducer,
    pasteDocument: pasteDocumentReducer,
    createShareLinkDocument: createShareLinkDocumentReducer,
    getShareLinkDocument: getShareLinkDocumentReducer,
    getShareLinkSpaceDocument: getShareLinkSpaceDocumentReducer,

    // Project Drawing V2
    allDrawingPublicSpace: allDrawingPublicSpaceReducer,
    allDrawingPrivateSpace: allDrawingPrivateSpaceReducer,
    allDrawingRestoreSpace: allDrawingRestoreSpaceReducer,
    allDrawingPublicFolder: allDrawingPublicFolderReducer,
    allDrawingPrivateFolder: allDrawingPrivateFolderReducer,
    createDrawingFolder: createDrawingFolderReducer,
    pinUnpinDrawingSpace: pinUnpinDrawingSpaceReducer,
    detailDrawing: detailDrawingReducer,
    historyDrawing: historyDrawingReducer,
    updateDrawing: updateDrawingReducer,
    deleteDrawing: deleteDrawingReducer,
    restoreDrawing: restoreDrawingReducer,
    uploadDrawingFile: uploadDrawingFileReducer,
    sharedUserPrivateDrawing: sharedUserPrivateDrawingReducer,
    updateUserPrivateDrawing: updateUserPrivateDrawingReducer,
    deleteUserPrivateDrawing: deleteUserPrivateDrawingReducer,
    allDrawingCompareFile: allDrawingCompareFileReducer,
    sendDrawing: sendDrawingReducer,
    shareDrawing: shareDrawingReducer,
    inviteUserDrawing: inviteUserDrawingReducer,
    pasteDrawing: pasteDrawingReducer,
    createShareLinkDrawing: createShareLinkDrawingReducer,
    getShareLinkDrawing: getShareLinkDrawingReducer,
    getShareLinkSpaceDrawing: getShareLinkSpaceDrawingReducer,

    // Reset password
    resetPasswordEmail: resetPasswordEmailReducer,
    resetPasswordPhone: resetPasswordPhoneReducer,
    resetProfilePasswordEmail: resetProfilePasswordEmailReducer,
    resetProfilePasswordPhone: resetProfilePasswordPhoneReducer,

    // Forgot password
    forgotPasswordEmail: forgotPasswordEmailReducer,
    forgotPasswordPhone: forgotPasswordPhoneReducer,
    resendEmailVerification: resendEmailVerificationReducer,
    verifyOTP: verifyOTPReducer,
    resendOtpVerification: resendOtpVerificationReducer,
    verifyOtpProfile: verifyOtpProfileReducer,
    acceptOtpProfile: acceptOtpProfileReducer,

    // Bug Report
    createBugReport: createBugReportReducer,

    // Check Email
    checkUserEmail: checkUserEmailReducer,

    // Dashboard wallpaper
    advertisementWallpaperDashboard: advertisementWallpaperDashboardReducer,

    // weather
    weather: weatherReducer,
    ip: ipReducer,

    // Notification download
    notificationDownload: notificationDownloadReducer,

    // Tender Box
    allTenderBox: allTenderBoxReducer,
    allProjectLocation: allProjectLocationReducer,
    allProjectSector: allProjectSectorReducer,
    allTenderIntention: allTenderIntentionReducer,
    editProjectTender: editProjectTenderReducer,
    openProjectToTender: openProjectToTenderReducer,
    closeProjectToTender: closeProjectToTenderReducer,
    allProjectRangeValue: allProjectRangeValueReducer,
    sendTender: sendTenderReducer,
    approveTender: approveTenderReducer,
    rejectTender: rejectTenderReducer,
};

export default rootReducer;
