import * as React from 'react';
import { Form } from 'react-bootstrap';

function ControlPanel({
    value,
    setValue,
}: {
    value: boolean;
    setValue: (value: boolean) => void;
}): JSX.Element {
    return (
        <div className="d-flex align-items-center w-100 p-3 pb-2 control-panel bg-white">
            <Form.Check
                id="show-overlay"
                type="checkbox"
                label="Search as I move the map"
                checked={value}
                onChange={e => setValue(e.target.checked)}
            />
        </div>
    );
}

export default React.memo(ControlPanel);
