import { useCallback, useEffect, useState } from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import CircleChatIcon from '@/assets/svg/icons/message-circle-plus.svg';
import PinIcon from '@/assets/svg/icons/pin.svg';

import Card from '@/components/molecules/Card';
import styled from 'styled-components';
import {
    meiliGetChatDetails,
    meiliGetChatDetailsCS,
    meiliGetChatLists,
} from '@/utility/MeiliSearch';

import {
    getToken,
    getId,
    getInitialName,
    handleTypeFileByName,
    generateInitialImageUrl,
} from '@/utility/Utils';

import {
    clearSendNewMessage,
    clearStateNewMessage,
    chatMarkAsRead,
    clearChatDetails,
} from '../redux/actions';
import { ChannelsProvider, useMessageSent } from '@/utility/Channels';
import { RootState } from '@/redux/store';

import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';

declare global {
    interface Window {
        Echo: any;
        Pusher: any;
    }
}

const ChatDetails = (params: {
    channelId: number;
    chatUserList: any;
    setChatUserList: any;
    setChannelId: (channelId: number) => void;
    setIsLoading: (isLoading: boolean) => void;
    setMessage?: any;
    setRestructureMessage?: any;
    search: { value: string; show: boolean };
    setSearch: (search: { value: string; show: boolean }) => void;
}): JSX.Element => {
    const {
        channelId,
        setChannelId,
        chatUserList,
        setMessage,
        setChatUserList,
        setSearch,
        // setRestructureMessage,
    } = params;
    const location = useLocation();

    const dispatch = useDispatch();
    const userId = getId();

    // const chatDetailsState = useSelector((state: RootState) => state.chatDetails);
    const chatListState = useSelector((state: RootState) => state.chatList);

    const [modal, setModal] = useState({
        success: false,
    });

    /**
     * Function to handle chat event
     *
     * @param data
     * @returns void
     */
    // const handleChatEvent = useCallback((data: any) => {
    //     dispatch({
    //         type: 'CHAT_DETAILS_BROADCAST',
    //         payload: data,
    //     });
    // }, []);

    /**
     * Function to handle send event
     *
     * @param data {id: number; messageChannel: {id: number;}
     * @returns void
     */
    const handleSendEvent = useCallback(
        (data: {
            message: {
                id: number;
                sent_by: {
                    id: number;
                };
                data: string;
            };
            messageChannel: {
                id: number;
                message_channel_category_id: number;
            };
        }) => {
            // dispatch({
            //     type: 'CHAT_DETAILS_NEW_MESSAGE',
            //     payload: data,
            // });

            dispatch(clearSendNewMessage());

            if (data?.message?.sent_by?.id !== getId()) {
                dispatch({
                    type: 'RECEIVE_MESSAGE_SUCCESS',
                    payload: {
                        ...data?.message,
                        message_channel_id: data?.messageChannel?.id,
                        message_channel_category_id:
                            data?.messageChannel?.message_channel_category_id,
                    },
                });
            }
            // TODO: FIX FOR LATEST MESSAGE UPDATE
            setTimeout(() => {
                if (data?.messageChannel?.message_channel_category_id === 3) {
                    const broadcast = chatListState?.broadcast;
                    const adminIndex = broadcast.findIndex(
                        (item: any) => item.is_administrator === 1,
                    );

                    if (adminIndex !== -1) {
                        const updatedAdmin = {
                            ...broadcast[adminIndex],
                            latest_message: {
                                ...broadcast[adminIndex]?.latest_message,
                                data: data?.message?.data,
                            },
                        };

                        const newList = [...broadcast];
                        newList[adminIndex] = updatedAdmin;

                        // update on redux
                        dispatch({
                            type: 'CHAT_LIST_BROADCAST',
                            payload: newList,
                        });
                    }
                } else {
                    meiliGetChatLists(results => {
                        // dispatch to redux
                        dispatch({
                            type: 'CHAT_LIST_BROADCAST',
                            payload: results?.hits,
                        });
                    });
                }
            }, 1000);
        },
        [],
    );

    // Function for handle navigate from floating right
    useEffect(() => {
        const chId = location?.state?.id;

        dispatch(clearChatDetails());

        if (chId !== null) {
            setChannelId(chId);
            dispatch({
                type: 'CHAT_DETAILS_CHANNEL',
                payload: chId,
            });
            setTimeout(() => {
                meiliGetChatLists(results => {
                    // dispatch to redux
                    dispatch({
                        type: 'CHAT_DETAILS_DATA',
                        payload: results?.hits.find((item: any) => item.id === chId),
                    });
                    dispatch({
                        type: 'CHAT_LIST_BROADCAST',
                        payload: results?.hits,
                    });
                });

                meiliGetChatDetails(chId, response => {
                    dispatch({
                        type: 'CHAT_DETAILS_BROADCAST',
                        payload: response?.hits,
                    });
                });
                dispatch(clearStateNewMessage());
            }, 1000);
        }

        return () => {
            window.history.replaceState(null, '', window.location.pathname);
        };
    }, [location.state]);

    /**
     * Function to handle show chat
     * TODO: per page 9999 is temporary, will be changed after backend ready
     *
     * @param channelId number
     * @returns void
     */
    const handleShowChat = (channelId: number, type: number, csId: string): void => {
        setMessage(null);
        setChannelId(channelId);
        setSearch({ value: '', show: false });
        // dispatch(clearChatDetails());
        // dispatch(clearSendNewMessage());

        if (type === 0) {
            meiliGetChatDetails(channelId, response => {
                dispatch({
                    type: 'CHAT_DETAILS_BROADCAST',
                    payload: response?.hits,
                });

                dispatch({
                    type: 'CHAT_DETAILS_CHANNEL',
                    payload: channelId,
                });

                if (chatListState?.broadcast?.length > 0) {
                    dispatch({
                        type: 'CHAT_DETAILS_DATA',
                        payload: chatListState?.broadcast.find(
                            (item: any) => item.id === channelId,
                        ),
                    });
                }
            });
        } else {
            meiliGetChatDetailsCS(channelId, csId, response => {
                dispatch({
                    type: 'CHAT_DETAILS_BROADCAST',
                    payload: response?.hits,
                });

                dispatch({
                    type: 'CHAT_DETAILS_CHANNEL',
                    payload: channelId,
                });

                if (chatListState?.broadcast?.length > 0) {
                    dispatch({
                        type: 'CHAT_DETAILS_DATA',
                        payload: chatListState?.broadcast.find(
                            (item: any) => item.id === channelId,
                        ),
                    });
                }
            });
        }

        dispatch(chatMarkAsRead(channelId));

        // update mark as read
        const userChatList = chatUserList?.find((item: any) => item?.id === channelId);
        const index = chatUserList?.indexOf(userChatList);

        const newChatList = [...chatUserList];

        newChatList[index] = {
            ...userChatList,
            unread_counts: [
                ...userChatList?.unread_counts?.filter(
                    (item: any) => item?.id !== userId,
                ),
            ],
        };

        setChatUserList(newChatList as any);
    };

    // useMessageRequest(userId, channelId, handleChatEvent);
    useMessageSent(userId, channelId, handleSendEvent);

    const handleLastMessage = (message: string): string => {
        switch (handleTypeFileByName(message)) {
            case 'image':
                return 'Sent an image';

            case 'word':
            case 'excel':
            case 'powerpoint':
            case 'pdf':
                return 'Sent a document';

            case 'video':
                return 'Sent a video';

            case 'archive':
                return 'Sent a file';

            default:
                return message;
        }
    };

    return (
        <>
            <div className="wrapper-list mt-3">
                {chatUserList
                    .filter((item: any) => item?.is_administrator === 0)
                    .map?.((list: any, index: number) => (
                        <div
                            onClick={() =>
                                list.id !== channelId && handleShowChat(list.id, 0, '')
                            }
                            key={index}
                            className={`chat-list d-flex justify-content-between align-items-center mb-2 ${
                                channelId === list.id ? 'active' : ''
                            }`}
                            role="button"
                        >
                            {list.message_channel_category_name === 'Direct Message' ? (
                                list.owner_id === userId ? (
                                    <div className="d-flex px-3">
                                        <ProfilePicture
                                            className="chat-profile"
                                            src={
                                                list?.participants[0]?.avatar ??
                                                generateInitialImageUrl(
                                                    list?.participants[0]?.name,
                                                )
                                            }
                                            status={list?.participants[0]?.online}
                                        >
                                            <OnlineCircle
                                                status={list?.participants[0]?.online}
                                            />
                                        </ProfilePicture>
                                        <div className="d-flex flex-column justify-content-center ms-2">
                                            <NameList
                                                className="chat-name mb-1"
                                                status={list?.latest_message?.readed}
                                            >
                                                {list?.participants[0]?.name ??
                                                    list?.unregistered_participants[0]
                                                        ?.email}
                                            </NameList>
                                            <MessageList
                                                className="chat-message mb-0"
                                                status={list?.latest_message?.readed}
                                            >
                                                {handleLastMessage(
                                                    list?.latest_message?.data,
                                                )}
                                            </MessageList>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="d-flex px-3">
                                        <ProfilePicture
                                            className="chat-profile"
                                            src={
                                                list?.owner_avatar ??
                                                generateInitialImageUrl(list?.owner_name)
                                            }
                                            status={list?.owner_online}
                                        >
                                            <OnlineCircle status={list?.owner_online} />
                                        </ProfilePicture>
                                        <div className="d-flex flex-column justify-content-center ms-2">
                                            <NameList
                                                className="chat-name mb-1"
                                                status={list?.latest_message?.readed}
                                            >
                                                {list?.owner_name}
                                            </NameList>
                                            <MessageList
                                                className="chat-message mb-0"
                                                status={list?.latest_message?.readed}
                                            >
                                                {handleLastMessage(
                                                    list?.latest_message?.data,
                                                )}
                                            </MessageList>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div className="d-flex px-3">
                                    <GroupPicture className="chat-profile">
                                        <span className="initial-name">
                                            {getInitialName(list.name)}
                                        </span>
                                    </GroupPicture>
                                    <div className="d-flex flex-column justify-content-center ms-2">
                                        <NameList
                                            className="chat-name mb-1"
                                            status={list?.latest_message?.readed}
                                        >
                                            {list?.name}
                                        </NameList>
                                        <MessageList
                                            className="chat-message mb-0"
                                            status={list?.latest_message?.readed}
                                        >
                                            {handleLastMessage(
                                                list?.latest_message?.data,
                                            )}
                                        </MessageList>
                                    </div>
                                </div>
                            )}
                            <div className="d-flex justify-content-between align-items-center me-2">
                                {/* Check condition if array */}
                                {list?.unread_counts?.length > 0 && (
                                    <span
                                        className="chat-count"
                                        style={{
                                            opacity:
                                                list?.unread_counts?.find(
                                                    (item: any) => item.id === userId,
                                                )?.unread_count > 0
                                                    ? 1
                                                    : 0,
                                        }}
                                    >
                                        {
                                            list?.unread_counts?.find(
                                                (item: any) => item.id === userId,
                                            )?.unread_count
                                        }
                                    </span>
                                )}
                                {list?.pinned_by?.length > 0 &&
                                    Boolean(
                                        list?.pinned_by.find(
                                            (item: any) => item.id === userId,
                                        ),
                                    ) && (
                                        <PinnedIcons
                                            src={PinIcon}
                                            style={{
                                                background:
                                                    channelId === list.id
                                                        ? '#edecfe'
                                                        : '',
                                            }}
                                        />
                                    )}
                            </div>
                        </div>
                    ))}
            </div>
            {chatUserList
                .filter((item: any) => item?.is_administrator === 1)
                .map?.((list: any, index: number) => (
                    <div
                        onClick={() =>
                            list.id !== channelId &&
                            handleShowChat(
                                list.id,
                                1,
                                list?.members.find(
                                    (item: any) => item?.role_name === 'Administrator',
                                )?.id,
                            )
                        }
                        key={index}
                        className={`chat-list d-flex justify-content-between align-items-center ${
                            channelId === list.id ? 'active' : ''
                        }`}
                        role="button"
                    >
                        <div className="d-flex px-3">
                            <ProfilePicture
                                className="chat-profile"
                                src={
                                    list?.participants[0]?.avatar ??
                                    generateInitialImageUrl(list?.participants[0]?.name)
                                }
                                status={list?.participants[0]?.online}
                            >
                                <OnlineCircle status={list?.participants[0]?.online} />
                            </ProfilePicture>
                            <div className="d-flex flex-column justify-content-center ms-2">
                                <NameList
                                    className="chat-name mb-1"
                                    status={list?.latest_message?.readed}
                                >
                                    {list?.participants[0]?.name ??
                                        list?.unregistered_participants[0]?.email}
                                </NameList>
                                <MessageList
                                    className="chat-message mb-0"
                                    status={list?.latest_message?.readed}
                                >
                                    {handleLastMessage(list?.latest_message?.data)}
                                </MessageList>
                                {list?.unread_counts?.length > 0 && (
                                    <span
                                        className="chat-count position-absolute"
                                        style={{
                                            right: 5,
                                            opacity:
                                                list?.unread_counts?.find(
                                                    (item: any) => item.id === userId,
                                                )?.unread_count > 0
                                                    ? 1
                                                    : 0,
                                        }}
                                    >
                                        {
                                            list?.unread_counts?.find(
                                                (item: any) => item.id === userId,
                                            )?.unread_count
                                        }
                                    </span>
                                )}
                            </div>
                            <div className="d-flex justify-content-between align-items-center me-2"></div>
                        </div>
                    </div>
                ))}
            <ModalDashboard
                modal={modal.success}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success"
                body="Success unpin chat"
                onConfirm={() => setModal(prev => ({ ...prev, success: false }))}
            />
        </>
    );
};

interface ParamsProps {
    setShow: (show: number) => void;
    channelId: number;
    setChannelId: (channelId: number) => void;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    setMessage: any;
    setRestructureMessage: any;
    chatUserList: any;
    setChatUserList: any;
    search: { value: string; show: boolean };
    setSearch: (search: { value: string; show: boolean }) => void;
}

export default function ListChatting(params: ParamsProps): JSX.Element {
    const {
        setShow,
        channelId,
        setChannelId,
        setIsLoading,
        setMessage,
        setRestructureMessage,
        chatUserList,
        setChatUserList,
        search,
        setSearch,
    } = params;

    const currentProfileState = useSelector((state: RootState) => state.currentProfile);
    const chatListState = useSelector((state: RootState) => state.chatList);

    useEffect(() => {
        if (chatListState?.broadcast?.length > 0) {
            // filter where have pinned
            const pinned = chatListState?.broadcast?.filter((item: any) =>
                item?.pinned_by.find((item: any) => item.id === getId()),
            );

            const allData = chatListState?.broadcast;

            const mergeSort = [...pinned, ...allData];

            // delete duplicate data from merge sort
            const unique = mergeSort.filter(
                (v, i, a) => a.findIndex(t => t.id === v.id) === i,
            );

            // set chat list
            setChatUserList(unique);
        }
    }, [chatListState]);

    /**
     * Function to search chat on list
     *  TODO: Search
     * @param e
     */
    const handleChange = (e: any): void => {
        const value = e.target.value;
        const list = chatListState?.broadcast;

        if (value !== '') {
            const filteredMember = list.filter((item: any) => {
                return item?.members.find((item: any) => {
                    return (
                        Boolean(
                            item?.name?.toLowerCase().includes(value.toLowerCase()),
                        ) || item?.email?.toLowerCase().includes(value.toLowerCase())
                    );
                });
            });

            const filterGroupName = list.filter((item: any) => {
                return Boolean(item?.name?.toLowerCase().includes(value.toLowerCase()));
            });

            const mergeFilter = [...filteredMember, ...filterGroupName];

            setChatUserList(mergeFilter);
        }

        if (value === '') {
            setChatUserList(chatListState?.broadcast);
        }
    };

    return (
        <ChatList className="col-lg-4 col-xl-3 mb-4 mb-md-0">
            <Card classOuter="py-2 bg-white border-0" classBody="p-0">
                <div className="chat-header px-0 pb-0 mt-2">
                    <div className="chat-profile">
                        {currentProfileState.loading !== false ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <div
                                    className="spinner-border text-primary"
                                    role="status"
                                />
                            </div>
                        ) : (
                            <div className="row gy-3 align-items-center">
                                <div className="col-md-9">
                                    <div
                                        className="d-flex pe-3"
                                        style={{ paddingLeft: 15 }}
                                    >
                                        <DropdownButton
                                            as={ButtonGroup}
                                            variant="transparent"
                                            title={
                                                <ProfilePictureDropdown
                                                    className="chat-profile"
                                                    src={
                                                        currentProfileState?.response
                                                            ?.avatar ??
                                                        generateInitialImageUrl(
                                                            currentProfileState?.response
                                                                ?.name,
                                                        )
                                                    }
                                                    status={true}
                                                >
                                                    <OnlineCircle status={true} />
                                                </ProfilePictureDropdown>
                                            }
                                        >
                                            <Dropdown.Item
                                                as={Link}
                                                to="/setting/profile"
                                            >
                                                <span className="me-2">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        height={20}
                                                        width={20}
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                                                        />
                                                    </svg>
                                                </span>
                                                <span className="">
                                                    Go to Personal Information Settings
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                as={Link}
                                                to="/setting/notification"
                                            >
                                                <span className="me-2">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        height={20}
                                                        width={20}
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                                                        />
                                                    </svg>
                                                </span>
                                                <span className="">
                                                    Go to Notification Settings
                                                </span>
                                            </Dropdown.Item>
                                        </DropdownButton>
                                        <div className="d-flex flex-column justify-content-center ms-2">
                                            <NameList
                                                className="chat-name mb-1"
                                                status={false}
                                                style={{
                                                    fontSize: 16,
                                                }}
                                                isProfile={true}
                                            >
                                                {currentProfileState?.response?.name}
                                            </NameList>
                                            <MessageList
                                                className="chat-message mb-0"
                                                status={true}
                                            >
                                                <span className="chat-company">
                                                    {
                                                        currentProfileState?.response
                                                            ?.company?.company_name
                                                    }
                                                    &nbsp;•&nbsp;
                                                </span>
                                                <span className="chat-job mb-0">
                                                    {currentProfileState?.response
                                                        ?.job_title ?? 'No Job Title'}
                                                </span>
                                            </MessageList>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="d-flex justify-content-end">
                                        <DropdownButton
                                            as={ButtonGroup}
                                            className="btn-new"
                                            title={
                                                <img
                                                    alt="new-message"
                                                    className="icon-new-chat"
                                                    src={CircleChatIcon}
                                                />
                                            }
                                        >
                                            <Dropdown.Item
                                                as={Link}
                                                to="#"
                                                onClick={() => setShow(1)}
                                            >
                                                <span className="me-2">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        height={20}
                                                        width={20}
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 011.037-.443 48.282 48.282 0 005.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                                                        />
                                                    </svg>
                                                </span>
                                                <span className="">
                                                    New Direct Message
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                as={Link}
                                                to="#"
                                                onClick={() => setShow(2)}
                                            >
                                                <span className="me-2">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        height={20}
                                                        width={20}
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                                                        />
                                                    </svg>
                                                </span>
                                                <span className="">
                                                    New Group Message
                                                </span>
                                            </Dropdown.Item>
                                        </DropdownButton>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-md-12 px-3">
                    <input
                        type="text"
                        placeholder="Search chat..."
                        className="form-control search-chat  border-0"
                        onChange={event => handleChange(event)}
                    />
                    {/* <BiSearch
                                    className="search-icon"
                                    color="rgba(131, 131, 131, 1)"
                                    size={20}
                                /> */}
                </div>
                <div className="chat-body">
                    <ChannelsProvider authUser={getId()} authToken={getToken()}>
                        <ChatDetails
                            channelId={channelId}
                            setChannelId={setChannelId}
                            chatUserList={chatUserList}
                            setChatUserList={setChatUserList}
                            setIsLoading={setIsLoading}
                            setMessage={setMessage}
                            setRestructureMessage={setRestructureMessage}
                            search={search}
                            setSearch={setSearch}
                        />
                    </ChannelsProvider>
                </div>
            </Card>
        </ChatList>
    );
}

const OnlineCircle = styled.span<{ status: boolean }>`
    background-color: ${props =>
        props.status ? 'rgba(6, 189, 128, 1)' : 'rgba(207, 207, 207, 1)'};
    width: 20px;
    height: 20px;
    border-radius: 50px;

    display: block;
    border: 3px solid #fff;
    position: relative;
    right: ${props => (props.status ? '-2rem' : '-2.2rem')};
    top: 1.3rem;
`;

const ProfilePicture = styled.div<{ src: string; status: boolean }>`
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-image: url('${props => props.src}');
    background-size: cover;
    background-position: center;
    border: ${props => (props.status ? '3px solid #5648fb' : 'none')};
`;

const GroupPicture = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #5648fb;

    .initial-name {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
    }
`;

const ProfilePictureDropdown = styled.div<{ src: string; status: boolean }>`
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-image: url('${props => props.src}');
    background-size: cover;
    background-position: center;
    border: ${props => (props.status ? '3px solid #5648fb' : 'none')};
`;

const NameList = styled.h6<{ status: boolean; isProfile?: boolean }>`
    font-size: 14px;
    font-weight: ${props => (props.status ? '500' : '600')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: ${props => (props.isProfile ?? false ? '80%' : '222px')};

    /* @media (min-width: 992px) and (max-width: 1200px) {
        .chat-name-profile {
            width: 60% !important;
        }
    }
    @media (min-width: 1200px) and (max-width: 1600px) {
        .chat-name-profile {
            width: 65% !important;
        }
    } */
`;

const MessageList = styled.div<{ status: boolean }>`
    font-size: 12px;
    font-weight: ${props => (props.status ? '500' : '600')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 220px;

    @media (min-width: 442px) and (max-width: 991px) {
        width: 100%;
    }

    @media (min-width: 992px) and (max-width: 1046px) {
        width: 100px;
    }

    @media (min-width: 1047px) and (max-width: 1528px) {
        width: 140px;
    }
`;

const ChatList = styled.div`
    .btn-group {
        flex-grow: 0;

        button {
            background-color: #5648fb;
            border-radius: 50% !important;
            border: 2px solid transparent;

            &:hover {
                border: 2px solid rgb(171, 163, 255);
            }
        }
    }

    .chat-header {
        justify-content: space-around;
        padding-left: 30px;
        padding-right: 25px;

        .dropdown-item {
            font-size: 13px;
            padding: 0.5rem;

            &:hover {
                background-color: #edecfe;
                color: rgba(86, 72, 251, 1);
                border-radius: 5px;

                .dropdown-active {
                    background-color: rgba(86, 72, 251, 1);
                }
            }

            //active
            &:active {
                background-color: #edecfe;
                color: rgba(86, 72, 251, 1);
                border-radius: 5px;

                .dropdown-active {
                    background-color: rgba(86, 72, 251, 1);
                }
            }

            // focus
            &:focus {
                background-color: #edecfe;
                color: rgba(86, 72, 251, 1);
                border-radius: 5px;

                .dropdown-active {
                    background-color: rgba(86, 72, 251, 1);
                }
            }

            // focus visible
            &:focus-visible {
                box-shadow: none;
                border: none;
                outline: none;
            }
        }

        .dropdown-menu {
            border: 1px solid rgba(131, 131, 131, 0.2);
            padding: 0.5rem;
            border-radius: 10px !important;
        }
    }

    .chat-body {
        justify-content: space-around;
        overflow-y: unset;
        /* padding-bottom: 5rem; */
    }

    .chat-job {
        font-size: 13px;
        font-weight: 500;
    }

    .chat-company {
        font-size: 13px;
        font-weight: 500;
    }

    .chat-count {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        background-color: #5648fb;
        color: #fff;
        font-size: 13px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .chat-list {
        /* border-bottom: 1px solid #e5e5e5; */
        padding: 0.5rem 0;
        border-radius: 20px;

        &:hover {
            background-color: #edecfe;
        }
    }

    .active {
        background: #edecfe;
    }

    .chat-profile {
        padding: 0.5rem 0;
    }

    .dropdown-toggle {
        /* height: 35px;
        width: 35px; */
        border-radius: 50px;
        padding: 0;

        /* After */
        &:after {
            display: none;
        }
    }

    .dropdown-toggle ~ .btn ~ .btn-primary {
        height: 35px;
        width: 35px;
        border-radius: 50px;
        padding: 0;
        opacity: 0;

        /* After */
        &:after {
            display: none;
        }
    }

    .icon-new-chat {
        width: 23px;
        height: 23px;
        vertical-align: top;
    }

    .search-icon {
        position: relative;
        top: 7.8rem;
        right: 2.5rem;
    }

    .online-circle {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        background-color: rgba(6, 189, 128, 1);
        position: absolute;
        left: 55px;
        border: 3px solid #fff;
        margin-top: 35px;
    }

    .search-chat {
        height: 45px;
        border-radius: 50px;
        border: 1.132px solid rgba(131, 131, 131, 0.1);
        background-color: #ffff;

        &:focus {
            box-shadow: none;
        }

        &::placeholder {
            font-size: 13px;
            font-weight: 400;
        }

        &,
        .form-control {
            padding: 0 2.3rem 0 1rem;
        }
    }

    .bottom-link {
        font-size: 13px;
        font-weight: 500;
        margin-top: 1rem;
    }

    .wrapper-list {
        overflow-y: scroll;
        overflow-x: hidden;
        height: calc(100vh - 290px);

        // hidden scrollbar
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    }

    .btn-new {
        .btn-primary {
            border: 2px solid transparent;
            border-radius: 50% !important;
            padding: 0.375rem;

            &:hover {
                border: 2px solid rgb(171, 163, 255);
            }
        }
    }
`;

const PinnedIcons = styled.span<{ src: string }>`
    mask: url(${props => props.src}) no-repeat center;
    mask-size: 60%;
    -webkit-mask: url(${props => props.src}) no-repeat center;
    -webkit-mask-size: 60%;
    background-color: rgba(48, 48, 48, 1);
    padding: 13px;
    cursor: pointer;
`;
