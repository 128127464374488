import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import InputOtp from '@/components/atoms/Inputs/InputOtp';
import TimerCountdown from '@/components/molecules/TimerCountdown';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { verifyOTP } from '@/routes/Authentication/ForgotPassword/redux/actions';

import {
    acceptPhoneOtp,
    resetCurrentPasswordPhone,
    sendPhoneVerification,
} from '@/routes/Dashboard/Setting/PersonalSetting/redux/actions';

interface ModalBasicProps {
    modal: boolean;
    phoneNumber?: string;
    setModal: (value: any) => void;
    onConfirm?: () => void;
    state?: string;
}

export default function ModalOtp(props: ModalBasicProps): JSX.Element {
    const { modal, setModal, phoneNumber, state } = props;

    const dispatch = useDispatch();

    const [otp, setOtp] = useState<string>('');

    // Reset otp when modal is closed
    useEffect(() => {
        if (!modal) {
            setOtp('');
        }
    }, [modal]);

    /**
     * Function for handle change after otp length is 4
     *
     * @returns {void}
     */
    useEffect(() => {
        if (otp.length > 3) {
            state === 'changeNumber'
                ? dispatch(acceptPhoneOtp(parseInt(otp)))
                : dispatch(verifyOTP(parseInt(otp)));

            sessionStorage.setItem('num', otp);
        }
    }, [otp]);

    /**
     * Function for handle change on otp and call dispatch
     *
     * @param {number} otp
     * @returns {void}
     */
    const handleChange = (otp: string): void => {
        setOtp(otp);
    };

    /**
     * Function for resend otp
     *
     * @returns {void}
     */
    const resendOTP = (): void => {
        state === 'changeNumber'
            ? dispatch(sendPhoneVerification())
            : dispatch(resetCurrentPasswordPhone());
    };

    return (
        <>
            <ModalWrapper
                show={modal}
                onHide={() => setModal(false)}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <h5 className="my-3 text-center">Enter OTP Number</h5>
                </Modal.Header>
                <Modal.Body>
                    <p className="mb-0 text-center">
                        Enter the code we just send on your mobile phone number
                    </p>

                    <p className="text-center fw-semibold">{phoneNumber}</p>

                    <InputOtp
                        value={otp}
                        length={4}
                        onChange={(value: string) => handleChange(value)}
                        containerStyle="mb-5"
                        inputStyle="w-100 otp-input mx-2"
                    />
                    <TimerCountdown
                        minute={5}
                        second={0}
                        textCount="Resent code in"
                        textButton="Resent"
                        textDesc="Didn't receive the code?"
                        onClick={resendOTP}
                    />
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </ModalWrapper>
        </>
    );
}

const ModalWrapper = styled(Modal)`
    .modal-content {
        font-family: 'Montserrat', sans-serif;
        padding: 0.5rem 3.5rem;
        border-radius: 10px;
    }

    .modal-header {
        border: none;
        justify-content: center;

        .btn-close {
            position: absolute;
            right: 20px;
            top: 25px;
            font-size: 10px;
        }
    }

    .modal-body {
        text-align: center;
        font-size: 13px;
        padding: 0;
    }

    .modal-footer {
        justify-content: center;
        border: none;
        flex-wrap: initial;
    }
`;
