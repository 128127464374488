import { useEffect, useState } from 'react';
import { Outlet, useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Nav } from 'react-bootstrap';

import styled from 'styled-components';

import { getDetailProject } from '../Dashboard/Project/redux/actions';
import { getCurrentProfileOnProject } from './Settings/redux/actions';
import { currentProjectAccess } from '@/utility/Utils';

import { RootState } from '@/redux/store';
import {
    clearActionsNotifications,
    getAllProjectNotificationFloat,
    getAllUnreadProjectNotifications,
} from './Notification/redux/actions';
import { getAllProjectHistory } from './History/redux/actions';
import Loader from '@/components/molecules/Loader';

export default function ProjectCenter(): JSX.Element {
    const { id } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const projectAccess = currentProjectAccess();

    const detailProjectState = useSelector((state: RootState) => state.detailProject);

    const currentProfileProjectState = useSelector(
        (state: RootState) => state.currentProfileProject,
    );

    const projectNotificationActionState = useSelector(
        (state: RootState) => state.projectNotificationAction,
    );

    const initialNavItems = [
        {
            id: 'summary',
            title: 'Summary',
            link: 'summary',
            children: [],
        },
        {
            id: 'schedule',
            title: 'Schedule',
            link: 'schedule',
            children: [],
        },
        {
            id: 'drawing',
            title: 'Drawings',
            link: 'drawings',
            children: ['public', 'private'],
        },
        {
            id: 'document',
            title: 'Documents',
            link: 'documents',
            children: ['public', 'private'],
        },
    ];

    const [navItems, setNavItems] = useState(initialNavItems);

    // const [tabKey, setTabKey] = useState<string | number>('');

    const historyParams = {
        project_id: id,
        page: 1,
        per_page: 10,
        search: '',
        summary: 1,
        schedule: 1,
        drawing: 1,
        document: 1,
        invited_user: 1,
        inhouse_user: 1,
    };

    const notificationParams = {
        project_id: id,
        page: 1,
        per_page: 10,
        sort_asc: 0,
    };

    const hiddenOnPage =
        location.pathname.includes('notification') ||
        location.pathname.includes('history');

    // Function for hidden if user not have access
    useEffect(() => {
        if (
            currentProfileProjectState.status === 200 &&
            currentProfileProjectState.loading !== true
        ) {
            const accessUserOnProject = currentProfileProjectState?.response?.access;

            // if (
            //     accessUserOnProject.summary.collaborate === false &&
            //     accessUserOnProject.summary.view === false
            // ) {
            //     setNavItems(
            //         (
            //             prevState: Array<{
            //                 id: string;
            //                 title: string;
            //                 link: string;
            //                 children: string[];
            //             }>,
            //         ) => prevState.filter(item => item.id !== 'summary'),
            //     );
            // }

            // remove from nav
            if (
                accessUserOnProject.drawing.collaborate === false &&
                accessUserOnProject.drawing.view === false
            ) {
                setNavItems(
                    (
                        prevState: Array<{
                            id: string;
                            title: string;
                            link: string;
                            children: string[];
                        }>,
                    ) => prevState.filter(item => item.id !== 'drawing'),
                );
            } else if (
                accessUserOnProject.drawing.collaborate === true ||
                accessUserOnProject.drawing.view === true
            ) {
                if (navItems.find(item => item.id === 'drawing') === undefined) {
                    setNavItems(
                        (
                            prevState: Array<{
                                id: string;
                                title: string;
                                link: string;
                                children: string[];
                            }>,
                        ) => [
                            ...prevState,
                            {
                                id: 'drawing',
                                title: 'Drawings',
                                link: 'drawings',
                                children: ['public', 'drawing'],
                            },
                        ],
                    );
                }
            }

            if (
                accessUserOnProject.document.collaborate === false &&
                accessUserOnProject.document.view === false
            ) {
                setNavItems(
                    (
                        prevState: Array<{
                            id: string;
                            title: string;
                            link: string;
                            children: string[];
                        }>,
                    ) => prevState.filter(item => item.id !== 'document'),
                );
            } else if (
                accessUserOnProject.document.collaborate === true ||
                accessUserOnProject.document.view === true
            ) {
                if (navItems.find(item => item.id === 'document') === undefined) {
                    setNavItems(
                        (
                            prevState: Array<{
                                id: string;
                                title: string;
                                link: string;
                                children: string[];
                            }>,
                        ) => [
                            ...prevState,
                            {
                                id: 'document',
                                title: 'Documents',
                                link: 'documents',
                                children: ['public', 'document'],
                            },
                        ],
                    );
                }
            }

            setTimeout(() => {
                // For redirect if user force access from url
                if (location.pathname.includes('summary')) {
                    if (
                        !projectAccess?.summary?.collaborate &&
                        !projectAccess?.summary?.view
                    ) {
                        navigate('/not-found');
                    }
                }

                if (location.pathname.includes('drawings')) {
                    if (
                        !projectAccess?.drawing?.collaborate &&
                        !projectAccess?.drawing?.view
                    ) {
                        navigate('/not-found');
                    }
                }

                if (location.pathname.includes('documents')) {
                    if (
                        !projectAccess?.document?.collaborate &&
                        !projectAccess?.document?.view
                    ) {
                        navigate('/not-found');
                    }
                }
            }, 1000);
        }
    }, [currentProfileProjectState]);

    // On mount action
    useEffect(() => {
        dispatch(getDetailProject(id as string));
        dispatch(getCurrentProfileOnProject(id as string));
        dispatch(getAllProjectNotificationFloat(notificationParams));
        dispatch(getAllProjectHistory(historyParams));
        dispatch(getAllUnreadProjectNotifications(notificationParams));
        // setIsLoading(true);
    }, [id]);

    // Function to handle action on notification
    useEffect(() => {
        if (projectNotificationActionState?.status === 200) {
            dispatch(clearActionsNotifications());

            dispatch(getAllProjectNotificationFloat(notificationParams));
            dispatch(getAllUnreadProjectNotifications(notificationParams));
        }
    }, [projectNotificationActionState]);

    // useEffect(() => {
    //     if (tabKey !== '') {
    //     // dispatch(getAllUnreadProjectNotifications(notificationParams));
    //         const nav = navItems.find(item => item.link === tabKey);

    //         if ((nav?.children?.length ?? 0) > 0) {
    //             return navigate(`${tabKey as string}/${nav?.children[0] as string}`);
    //         }

    //         navigate(tabKey as To);
    //     }
    // }, [tabKey]);

    // if location
    // useEffect(() => {
    //     navItems.forEach(item => {
    //         if (location.pathname.includes(item.link)) {
    //             setTabKey(item.link);
    //         }
    //     });
    // }, [location.pathname]);

    // redirect to not found page if project not found
    useEffect(() => {
        if (detailProjectState?.status === 400) {
            setTimeout(() => {
                navigate('/not-found');
            }, 2000);
        }
    }, [detailProjectState]);

    return currentProfileProjectState.loading === true ? (
        <Loader />
    ) : (
        <>
            <WrapperNav
                location={location.pathname}
                className={hiddenOnPage ? 'd-none' : ''}
            >
                <Nav defaultActiveKey={navItems[0].link} className="">
                    {navItems.map(
                        (item: {
                            id: string;
                            title: string;
                            link: string;
                            children: string[];
                        }) => (
                            <Nav.Item key={item.id}>
                                <Nav.Link
                                    // eventKey={item.link}
                                    as={Link}
                                    to={
                                        item?.link === 'documents' ||
                                        item?.link === 'drawings'
                                            ? `${item.link}/${item.children[0]}`
                                            : `${item.link}`
                                    }
                                    active={location.pathname.includes(
                                        navItems.find(nav => nav.link === item.link)
                                            ?.link ?? '',
                                    )}
                                >
                                    {item.title}
                                </Nav.Link>
                            </Nav.Item>
                        ),
                    )}
                </Nav>
            </WrapperNav>

            <Outlet />
        </>
    );
}

const WrapperNav = styled.div<{ location: string }>`
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 600;

    .nav {
        justify-content: center;
    }

    .nav-link {
        color: #fff;
        background-color: #675ce333;
        border-radius: 20px;
        padding: 0.3rem 1rem;

        @media (min-width: 768px) {
            margin: 0 2rem;
        }

        &.active {
            background-color: #5648fb;
            text-decoration-color: ${props =>
                props.location.includes('summary') ? '#fff' : '#5648fb'};
        }

        &:hover {
            color: #ffffff;
            background-color: #5648fb;
            text-decoration-color: #5648fb;
        }
    }

    @media (max-width: 768px) {
        .nav-item {
            width: 40%;
            margin: 5px;
            text-align: center;
        }
    }
`;
