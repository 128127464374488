import { useEffect, useRef, useState } from 'react';
import { CloseButton, Dropdown, Form } from 'react-bootstrap';
import { BsFillPinAngleFill } from 'react-icons/bs';
import { HiDotsVertical } from 'react-icons/hi';
import styled from 'styled-components';
import { useAppDispatch } from '@/redux/hook';
import { useParams } from 'react-router-dom';
import { BreadCrumbProps, IExplorer, PermissionProps, StructureProps } from './interface';
import SkeletonSpace from './components/SkeletonSpace';
import { clearAllDrawingFolder } from '@/routes/ProjectDashboard/Drawing/redux/actions';
import {
    AiOutlineInfoCircle,
    AiOutlineEdit,
    AiOutlineDownload,
    AiOutlineDelete,
    AiOutlineShareAlt,
    AiOutlineSend,
    AiOutlineReload,
    AiOutlineCloud,
    AiOutlineLink,
    AiOutlineSearch,
} from 'react-icons/ai';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import {
    currentProjectAccess,
    formatDateIso,
    generateInitialImageUrl,
    handleIcons,
} from '@/utility/Utils';

import './style.scss';
import { BiTrash, BiCopy, BiCut } from 'react-icons/bi';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import BasicTooltip from '@/components/atoms/Tooltips';

import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import MultipleAction from './components/MultipleAction';

const maxSelect = 40;

export default function Explorer({
    reduxSpace,
    reduxFolder,
    moduleType,
    tab,
    handleOpenSpace,
    handleOpenFolder,
    handlePrevious,
    handleCreateSpace,
    handlePagination,
    handlePaginationFolder,
    handleModal,
    handleSearch,
    handlePreviewFile,
    selectedCheckbox,
    setSelectedCheckbox,
    handleActionMultiple,
    handleActionRightClick,
    handlePreviewRevision,
}: IExplorer): JSX.Element {
    const dispatch = useAppDispatch();
    const { id, urlTypeId, urlSpaceId, urlFolderId } = useParams();

    const projectId = id?.toString() ?? '';
    const currentPermission = currentProjectAccess();
    const pasteDrawingState = useSelector((state: RootState) => state.pasteDrawing);
    const deleteDrawingState = useSelector((state: RootState) => state.deleteDrawing);

    const drawingAccess = currentPermission?.drawing;

    const inputCreateSpaceRef = useRef<HTMLInputElement>(null);
    const [activeDropdown, setActiveDropdown] = useState<string>('');
    const isMobileScreen = useMediaQuery({
        query: '(max-width: 991px)',
    });

    const [clicked, setClicked] = useState<{
        x: number;
        y: number;
        status: boolean;
        type: string;
        data: StructureProps[] | null;
    }>({
        x: 0,
        y: 0,
        status: false,
        type: '',
        data: [],
    });

    const [space, setSpace] = useState<StructureProps[]>([]);
    const [isSpaceCreated, setIsSpaceCreated] = useState({
        name: '',
        status: false,
    });
    const [loadingCreateSpace, setLoadingCreateSpace] = useState(false);
    const [contextData, setContextData] = useState<{
        type: string;
        data: StructureProps[];
    }>({
        type: '',
        data: [],
    });

    const [items, setItems] = useState<StructureProps[] | null>([]);

    // if selected all
    // const checkboxAllChecked =
    //     selectedCheckbox.length === items?.filter(item => item?.type !== 'Prev').length;

    const checkboxAllChecked =
        items !== null && items?.length > 40
            ? selectedCheckbox.length === maxSelect
            : selectedCheckbox.length ===
              items?.filter(item => item?.type !== 'Prev').length;

    const spaceDropdowns = [
        {
            key: 1,
            name: 'Information Folder',
            icon: <AiOutlineInfoCircle className="custom-icon me-2" size={18} />,
            onclick: (rowData: StructureProps) =>
                // set to false because this is not modal
                handleModal(false, 'information', 'Information Folder', rowData),
            only: () => true,
        },
        // {
        //     key: 2,
        //     name: 'Edit Folder',
        //     icon: <AiOutlineEdit className="custom-icon me-2" size={18} />,
        //     onclick: (rowData: StructureProps) =>
        //         // set to false because this is not modal
        //         handleModal(false, 'edit', 'Edit Folder', rowData),
        //     only: (permission: PermissionProps) => {
        //         if (permission?.can_collaborate_drawing) {
        //             return true;
        //         }
        //         return false;
        //     },
        // },
        {
            key: 3,
            name: 'Download',
            icon: <AiOutlineDownload className="custom-icon me-2" size={18} />,
            onclick: (rowData: StructureProps) =>
                // set to false because this is not modal
                handleModal(false, 'download', 'Download Folder', rowData),
            only: () => true,
        },
        // {
        //     key: 4,
        //     name: 'Share',
        //     icon: <AiOutlineShareAlt className="custom-icon me-2" size={18} />,
        //     onclick: (rowData: StructureProps) =>
        //         // set to false because this is not modal
        //         handleModal(false, 'share', 'Share Folder', rowData),
        //     // only: urlTypeId !== 'public' && urlSpaceId !== 'trash',
        //     only: (permission: PermissionProps) => {
        //         if (
        //             permission?.can_collaborate_drawing &&
        //             urlTypeId !== 'public' &&
        //             urlSpaceId !== 'trash'
        //         ) {
        //             return true;
        //         }
        //         return false;
        //     },
        // },
        // {
        //     key: 9,
        //     name: 'Create Public Link',
        //     icon: <AiOutlineLink className="custom-icon me-2" size={18} />,
        //     onclick: (rowData: StructureProps) =>
        //         // set to false because this is not modal
        //         handleModal(false, 'share link', 'Share Link', rowData),
        //     only: (permission: PermissionProps) => {
        //         if (urlTypeId !== 'private' && urlSpaceId !== 'trash') {
        //             return true;
        //         }
        //         return false;
        //     },
        // },
        // {
        //     key: 5,
        //     name: 'Send',
        //     icon: <AiOutlineSend className="custom-icon me-2" size={18} />,
        //     onclick: (rowData: StructureProps) =>
        //         // set to false because this is not modal
        //         handleModal(false, 'send', 'Send Folder', rowData),
        //     only: () => true,
        // },
        // {
        //     key: 6,
        //     name: 'Copy',
        //     icon: <BiCopy className="custom-icon me-2" size={18} />,
        //     onclick: (rowData: StructureProps) =>
        //         handleSetContext('copy folder', [rowData]),
        //     only: (permission: PermissionProps) =>
        //         isMobileScreen &&
        //         urlSpaceId !== 'trash' &&
        //         permission?.can_post_publicly_drawing,
        // },
        // {
        //     key: 7,
        //     name: 'Cut',
        //     icon: <BiCut className="custom-icon me-2" size={18} />,
        //     onclick: (rowData: StructureProps) =>
        //         handleSetContext('move folder', [rowData]),
        //     only: (permission: PermissionProps) =>
        //         isMobileScreen &&
        //         urlSpaceId !== 'trash' &&
        //         permission?.can_post_publicly_drawing,
        // },
        // {
        //     key: 8,
        //     name: 'Delete Folder',
        //     icon: (
        //         <AiOutlineDelete className="custom-icon me-2" size={18} color="#fb6056" />
        //     ),
        //     variant: 'danger',
        //     // only: documentAccess?.collaborate,
        //     onclick: (rowData: StructureProps) =>
        //         // set to false because this is not modal
        //         handleModal(true, 'delete', 'Delete Folder', rowData),
        //     only: (permission: PermissionProps) => {
        //         if (permission?.can_collaborate_drawing) {
        //             return true;
        //         }
        //         return false;
        //     },
        // },
    ];

    const folderDropdowns = [
        {
            key: 1,
            name: 'Information',
            icon: <AiOutlineInfoCircle className="custom-icon me-2" size={18} />,
            onclick: (rowData: StructureProps) =>
                // set to false because this is not modal
                handleModal(false, 'information', `Information ${rowData.type}`, rowData),
            // only: urlSpaceId !== 'trash',
            only: () => true,
        },
        // {
        //     key: 2,
        //     name: 'Edit',
        //     icon: <AiOutlineEdit className="custom-icon me-2" size={18} />,
        //     onclick: (rowData: StructureProps) =>
        //         // set to false because this is not modal
        //         handleModal(false, 'edit', `Edit / Add ${rowData.type}`, rowData),
        //     // only: urlSpaceId !== 'trash',
        //     only: (permission: PermissionProps) => {
        //         if (permission?.can_collaborate_drawing && urlSpaceId !== 'trash') {
        //             return true;
        //         }
        //         return false;
        //     },
        // },
        // {
        //     key: 3,
        //     name: 'Share',
        //     icon: <AiOutlineShareAlt className="custom-icon me-2" size={18} />,
        //     onclick: (rowData: StructureProps) =>
        //         // set to false because this is not modal
        //         handleModal(false, 'share', 'Share Folder', rowData),
        //     // only: urlTypeId !== 'public' && urlSpaceId !== 'trash',
        //     only: (permission: PermissionProps) => {
        //         if (
        //             permission?.can_collaborate_drawing &&
        //             urlTypeId !== 'public' &&
        //             urlSpaceId !== 'trash'
        //         ) {
        //             return true;
        //         }
        //         return false;
        //     },
        // },
        // {
        //     key: 10,
        //     name: 'Create Public Link',
        //     icon: <AiOutlineLink className="custom-icon me-2" size={18} />,
        //     onclick: (rowData: StructureProps) =>
        //         // set to false because this is not modal
        //         handleModal(false, 'share link', 'Share Link', rowData),
        //     only: (permission: PermissionProps) => {
        //         if (urlTypeId !== 'private' && urlSpaceId !== 'trash') {
        //             return true;
        //         }
        //         return false;
        //     },
        // },
        // {
        //     key: 4,
        //     name: 'Send',
        //     icon: <AiOutlineSend className="custom-icon me-2" size={18} />,
        //     onclick: (rowData: StructureProps) =>
        //         // set to false because this is not modal
        //         handleModal(false, 'send', 'Send Folder', rowData),
        //     // only: urlSpaceId !== 'trash',
        //     only: () => {
        //         if (urlSpaceId !== 'trash') {
        //             return true;
        //         }
        //         return false;
        //     },
        // },
        // {
        //     key: 5,
        //     name: 'Delete',
        //     icon: (
        //         <AiOutlineDelete className="custom-icon me-2" size={18} color="#fb6056" />
        //     ),
        //     variant: 'danger',
        //     // only: documentAccess?.collaborate,
        //     onclick: (rowData: StructureProps) =>
        //         // set to false because this is not modal
        //         handleModal(true, 'delete', `Delete ${rowData.type}`, rowData),
        //     // only: urlSpaceId !== 'trash',
        //     only: (permission: PermissionProps) => {
        //         if (permission?.can_collaborate_drawing && urlSpaceId !== 'trash') {
        //             return true;
        //         }
        //         return false;
        //     },
        // },
        // {
        //     key: 6,
        //     name: 'Copy',
        //     icon: <BiCopy className="custom-icon me-2" size={18} />,
        //     onclick: (rowData: StructureProps) =>
        //         handleSetContext('copy folder', [rowData]),
        //     only: (permission: PermissionProps) =>
        //         isMobileScreen &&
        //         urlSpaceId !== 'trash' &&
        //         permission?.can_post_publicly_drawing,
        // },
        // {
        //     key: 7,
        //     name: 'Cut',
        //     icon: <BiCut className="custom-icon me-2" size={18} />,
        //     onclick: (rowData: StructureProps) =>
        //         handleSetContext('move folder', [rowData]),
        //     only: (permission: PermissionProps) =>
        //         isMobileScreen &&
        //         urlSpaceId !== 'trash' &&
        //         permission?.can_post_publicly_drawing,
        // },
        // {
        //     key: 8,
        //     name: 'Restore',
        //     icon: <AiOutlineReload className="custom-icon me-2" size={18} />,
        //     variant: 'restore',
        //     // only: documentAccess?.collaborate,
        //     onclick: (rowData: StructureProps) =>
        //         // set to false because this is not modal
        //         handleModal(true, 'restore', `Restore ${rowData.type}`, rowData),
        //     // only: urlSpaceId === 'trash',
        //     only: (permission: PermissionProps) => {
        //         if (permission?.can_collaborate_drawing && urlSpaceId === 'trash') {
        //             return true;
        //         }
        //         return false;
        //     },
        // },
        // {
        //     key: 9,
        //     name: 'Delete Permanently',
        //     icon: (
        //         <AiOutlineDelete className="custom-icon me-2" size={18} color="#fb6056" />
        //     ),
        //     variant: 'danger',
        //     // only: documentAccess?.collaborate,
        //     onclick: (rowData: StructureProps) =>
        //         // set to false because this is not modal
        //         handleModal(true, 'delete permanent', `Delete ${rowData.type}`, rowData),
        //     // only: urlSpaceId === 'trash',
        //     only: (permission: PermissionProps) => {
        //         if (permission?.can_collaborate_drawing && urlSpaceId === 'trash') {
        //             return true;
        //         }
        //         return false;
        //     },
        // },
    ];

    // Load Data Space
    useEffect(() => {
        if (reduxSpace?.status === 200) {
            const page = reduxSpace?.response?.meta?.current_page;
            if (page === 1) {
                return setSpace(reduxSpace?.response?.data);
            }

            if (page > 1) {
                return setSpace(prev => {
                    const newSpace = [...prev, ...reduxSpace?.response?.data];
                    return newSpace;
                });
            }
        }
    }, [reduxSpace?.response?.data]);

    // Load data folder
    useEffect(() => {
        if (reduxFolder?.status === 200) {
            const parentSpaceId = reduxFolder?.response?.drawing_folder_id;
            const parentFolderId = reduxFolder?.response?.parent_drawing_id;
            const page = reduxFolder?.response?.meta?.current_page;

            if (parentFolderId === null || parentSpaceId === undefined) {
                // if page === 1
                setItems(prevItems => {
                    const newItems =
                        page === 1
                            ? reduxFolder?.response?.data
                            : [...(prevItems ?? []), ...reduxFolder?.response?.data];
                    return newItems;
                });
                return;
            }

            // add for previous folder
            const previousFolder = {
                id: parentFolderId ?? parentSpaceId ?? 0,
                uuid: parentFolderId ?? parentSpaceId ?? 0,
                name: '..',
                type: 'Prev',
                size: '',
                contents: null,
            };

            setItems(prevItems => {
                const newItems =
                    page === 1
                        ? [previousFolder, ...reduxFolder?.response?.data]
                        : [...(prevItems ?? []), ...reduxFolder?.response?.data];

                return newItems;
            });
        }
    }, [reduxFolder]);

    // Handle Click Outside Context Menu
    useEffect(() => {
        const handleClick = (): void => setClicked({ ...clicked, status: false });
        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, []);

    // Clear All Folder
    useEffect(() => {
        if (urlSpaceId === undefined) {
            dispatch(clearAllDrawingFolder());
            setItems([]);
        }
    }, [urlSpaceId]);

    // If paste success
    useEffect(() => {
        if (pasteDrawingState.status === 200) {
            // reload data
            setContextData({ type: '', data: [] });
        }
    }, [pasteDrawingState]);

    // If delete success
    useEffect(() => {
        if (deleteDrawingState.status === 200) {
            // reload data
            setContextData({ type: '', data: [] });
        }
    }, [deleteDrawingState]);

    /**
     * Function for handle show invite
     *
     * @param uuid string[]
     * @returns void
     */
    const handleSelectCheckbox = (
        e: React.ChangeEvent<HTMLInputElement>,
        uuid?: string,
    ): void => {
        const checked = e.target.checked;

        // if uuid = checkAll
        if (uuid === 'checkAll') {
            if (checked) {
                // set all checkbox to selected, but ignore previous folder
                const allFiles = items?.filter(item => item?.type !== 'Prev') ?? [];
                const allFilesUuid = allFiles?.map(item => item.uuid) ?? [];
                const allFilesUuidFiltered = allFilesUuid.filter(
                    item => !selectedCheckbox.includes(item),
                );

                const selection = allFilesUuidFiltered.slice(
                    0,
                    maxSelect - selectedCheckbox.length,
                );

                setSelectedCheckbox(prevState => [...prevState, ...selection]);

                return;
            }

            setSelectedCheckbox([]);
        } else {
            if (selectedCheckbox.length >= maxSelect && checked) {
                toast.warn(`Maximum ${maxSelect} items for select`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2000,
                    hideProgressBar: true,
                });

                return;
            }

            setSelectedCheckbox(prevState => {
                if (checked) {
                    return [...prevState, uuid as string];
                }

                return prevState.filter(item => item !== uuid);
            });
        }
    };

    /**
     * Function for handle right click
     *
     * @param e any
     * @param data StructureProps[] | null
     * @param type string
     * @returns void
     */
    const handleRightClick = (
        e: any,
        data: StructureProps[] | null,
        type: string,
    ): void => {
        e.preventDefault();
        e.stopPropagation();

        if (selectedCheckbox.length > 0) {
            const selectedData = items?.filter(item =>
                selectedCheckbox.includes(item.uuid),
            );

            return setClicked({
                x: e.clientX,
                y: e.clientY,
                status: true,
                type,
                data: selectedData ?? [],
            });
        }

        setClicked({
            x: e.clientX,
            y: e.clientY,
            status: true,
            type,
            data,
        });
    };

    /**
     * Function for handle set context
     *
     * @param type string
     * @returns void
     */
    const handleSetContext = (type: string, rowData?: StructureProps[]): void => {
        setContextData({
            data: rowData ?? clicked.data ?? [],
            type,
        });

        toast.warn(`${rowData?.length ?? clicked.data?.length ?? 0} Item selected `, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 500,
            hideProgressBar: true,
        });
    };

    /**
     * Function for handle promise when create a space
     *
     * @param event any
     * @returns Promise<void>
     */
    const [creatingSpace, setCreatingSpace] = useState(false);
    const handleBlurCreateSpace = async (event: any): Promise<void> => {
        if (creatingSpace || event.target.value === '') {
            return setIsSpaceCreated(prev => ({ ...prev, status: false }));
        }

        setCreatingSpace(true);
        setLoadingCreateSpace(true);

        await handleCreateSpace(event)
            .then((res: any) => {
                setSpace((prev: any) => {
                    const newSpace = [res, ...prev];
                    return newSpace;
                });

                setIsSpaceCreated(prev => ({ ...prev, status: false }));
                setLoadingCreateSpace(false);
            })
            .catch(() => {
                setLoadingCreateSpace(false);
            })
            .finally(() => {
                setCreatingSpace(false);
            });
    };

    /**
     * Function for handle double click
     * @param e React.MouseEvent<HTMLElement>
     * @param projectId string
     * @param moduleType string
     * @param tab string
     * @param urlSpaceId string
     * @param folderId string
     * @param type string
     * @param rowData StructureProps
     * @returns void
     */
    const handleDoubleClick = (
        e: React.MouseEvent<HTMLElement>,
        projectId: string,
        moduleType: string,
        tab: string,
        urlSpaceId: string,
        folderId: string,
        type: string,
        rowData: StructureProps,
    ): void => {
        if (type === 'File') {
            const allFiles = items?.filter(item => item?.type === 'File') ?? [];

            return handlePreviewFile(e, rowData, allFiles);
        }

        if (urlSpaceId?.includes('search=') ?? false) {
            return handleOpenFolder(
                e,
                projectId,
                moduleType,
                tab,
                rowData?.parent_drawing_id === null
                    ? rowData?.uuid
                    : rowData?.drawing_folder_id,
                rowData?.parent_drawing_id === null ? '' : folderId,
            );
        }

        // For previous folder
        if (type === 'Prev') {
            return handlePrevious(e, projectId, moduleType, tab, urlSpaceId, folderId);
        }

        handleOpenFolder(e, projectId, moduleType, tab, urlSpaceId, folderId);
    };

    /**
     * Function for handle action right click
     *
     * @returns void
     */
    const handleAddNewSpace = (): void => {
        setIsSpaceCreated({ name: '', status: true });
        // focus input
        setTimeout(() => {
            inputCreateSpaceRef?.current?.focus();
        }, 0);
    };

    /**
     * Function for handle breadcrumb
     *
     * @param e React.MouseEvent<HTMLElement>
     * @param item BreadCrumbProps
     * @param index number
     * @returns void
     */
    const handleBreadcrumb = (
        e: React.MouseEvent<HTMLElement>,
        item: BreadCrumbProps,
        index: number,
    ): void => {
        if (item?.uuid === urlFolderId) {
            return;
        }

        if (index === 0) {
            return handleOpenSpace(e, projectId, moduleType, tab, urlSpaceId ?? '');
        }

        handleOpenFolder(e, projectId, moduleType, tab, urlSpaceId ?? '', item?.uuid);
    };

    const handleActiveBreadcrumb = (
        item: BreadCrumbProps,
        type: 'class' | 'role',
    ): string => {
        // Check if breadcrumb length is more than 1
        const isSingleBreadcrumb = reduxFolder?.response?.breadcrumbs?.length === 1;

        // Logic for 'class' and 'role' based on conditions
        if (type === 'class') {
            // No class if only 1 breadcrumb
            if (isSingleBreadcrumb) return '';

            return item?.uuid === urlFolderId ? '' : 'link-secondary';
        }

        if (type === 'role') {
            // No role if only 1 breadcrumb
            if (isSingleBreadcrumb) return '';

            return item?.uuid === urlFolderId ? '' : 'button';
        }

        return '';
    };

    // on escape key
    useEffect(() => {
        const handleEscape = (e: KeyboardEvent): void => {
            if (e.key === 'Escape') {
                setIsSpaceCreated({ name: '', status: false });
            }
        };

        window.addEventListener('keydown', handleEscape);

        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, [clicked]);

    return (
        <>
            <div
                className={`row mx-0 WrapperExplorer pt-3 px-3 ${
                    reduxFolder?.loading === true ? 'loading' : ''
                } ${loadingCreateSpace ? 'loading' : ''}
                `}
            >
                <div className="col-lg-3">
                    <div className="d-flex align-items-center gap-1">
                        <AiOutlineSearch size={20} />
                        <input
                            type="text"
                            className="input-search form-control ps-0"
                            placeholder="Search"
                            onChange={e => handleSearch(e)}
                        />
                    </div>

                    <div
                        className="space-wrapper mt-3"
                        // onContextMenu={e => handleRightClick(e, null, 'outside-space')}
                    >
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <h6 className="mb-0">Folder</h6>

                            {/* {urlTypeId !== 'public' || drawingAccess?.post_publicly ? (
                                <ButtonBasic
                                    onClick={handleAddNewSpace}
                                    text={'New Folder'}
                                    className="btn btn-success btn-sm px-3 py-1"
                                />
                            ) : null} */}
                        </div>

                        {isSpaceCreated.status && (
                            <input
                                id="input-create-space"
                                ref={inputCreateSpaceRef}
                                type="text"
                                className="form-control input-create-space"
                                placeholder="Press enter to create, esc to cancel"
                                value={isSpaceCreated.name}
                                onClick={e => e.stopPropagation()}
                                onKeyDown={async e => {
                                    if (e.key === 'Enter') {
                                        await handleBlurCreateSpace(e);
                                    }
                                }}
                                onChange={e => {
                                    setIsSpaceCreated(prev => ({
                                        ...prev,
                                        name: e.target.value,
                                    }));
                                }}
                            />
                        )}

                        <div className={'space-container'}>
                            {space.length > 0 ? (
                                <InfiniteScroll
                                    next={() =>
                                        handlePagination(
                                            reduxSpace?.response?.meta?.current_page,
                                        )
                                    }
                                    hasMore={
                                        reduxSpace?.response?.meta?.current_page <
                                        reduxSpace?.response?.meta?.last_page
                                    }
                                    loader={<SkeletonSpace />}
                                    dataLength={space?.length ?? 0}
                                    height={'50vh'}
                                    className="space-scroll hidden-scroll"
                                >
                                    {space?.map((rowData, index) => (
                                        <div
                                            key={index}
                                            className={`disable-selection space-li mb-2 ${
                                                urlSpaceId === rowData?.uuid
                                                    ? 'space-active'
                                                    : ''
                                            }
                                                ${
                                                    activeDropdown === rowData?.uuid
                                                        ? 'space-active'
                                                        : ''
                                                }
                                                `}
                                            // onContextMenu={e =>
                                            //     !isMobileScreen &&
                                            //     handleRightClick(e, [rowData], 'space')
                                            // }
                                            onDoubleClick={e => {
                                                handleOpenSpace(
                                                    e,
                                                    projectId,
                                                    moduleType,
                                                    tab,
                                                    rowData?.uuid?.toString(),
                                                );
                                            }}
                                        >
                                            <span className="space-a">
                                                {rowData?.name}
                                            </span>

                                            <div className="d-flex align-items-center">
                                                {rowData?.pinned && (
                                                    <BsFillPinAngleFill
                                                        className="me-2"
                                                        color="#212529"
                                                        size="16px"
                                                    />
                                                )}

                                                <Dropdown
                                                    show={activeDropdown === rowData.uuid}
                                                    onToggle={isOpen => {
                                                        if (isOpen) {
                                                            return setActiveDropdown(
                                                                rowData.uuid,
                                                            );
                                                        }
                                                        setActiveDropdown('');
                                                    }}
                                                >
                                                    <Dropdown.Toggle
                                                        variant="none"
                                                        id="dropdown-autoclose-true"
                                                    >
                                                        <HiDotsVertical
                                                            size={14}
                                                            color="#000"
                                                        />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        {/* <Dropdown.Item
                                                            key={rowData?.uuid}
                                                            href="#"
                                                            style={{
                                                                fontSize: 13,
                                                            }}
                                                            onClick={() =>
                                                                rowData.pinned
                                                                    ? handleModal(
                                                                          true,
                                                                          'unpin',
                                                                          'Unpin Space',
                                                                          rowData,
                                                                      )
                                                                    : handleModal(
                                                                          true,
                                                                          'pin',
                                                                          'Pin Space',
                                                                          rowData,
                                                                      )
                                                            }
                                                        >
                                                            <BsFillPinAngleFill
                                                                className="custom-icon me-2"
                                                                size={18}
                                                            />
                                                            {rowData.pinned
                                                                ? 'Unpin'
                                                                : 'Pin'}
                                                            &nbsp;Folder
                                                        </Dropdown.Item> */}
                                                        {spaceDropdowns.map(
                                                            (item: any) =>
                                                                Boolean(
                                                                    item?.only(
                                                                        rowData?.permission,
                                                                    ),
                                                                ) && (
                                                                    <Dropdown.Item
                                                                        key={item?.key}
                                                                        href="#"
                                                                        style={{
                                                                            fontSize: 13,
                                                                        }}
                                                                        onClick={() =>
                                                                            item.onclick(
                                                                                rowData,
                                                                            )
                                                                        }
                                                                        className={`text-${
                                                                            item.variant as string
                                                                        }`}
                                                                    >
                                                                        {item.icon}
                                                                        {item.name}
                                                                    </Dropdown.Item>
                                                                ),
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    ))}
                                    {/* </div> */}
                                </InfiniteScroll>
                            ) : (
                                <div className="text-muted">No space found</div>
                            )}
                            {/* <div
                                className={`space-li disable-selection space-trash mt-3 ${
                                    urlSpaceId === 'trash' ? 'space-active' : ''
                                }`}
                                onDoubleClick={e =>
                                    handleOpenSpace(
                                        e,
                                        projectId,
                                        moduleType,
                                        tab,
                                        'trash',
                                    )
                                }
                            >
                                <BiTrash className="space-icon-pin" size={18} />
                                <span className="space-a">Trash</span>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="row px-0 py-2">
                        <div className="col-md-9">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0">
                                    {reduxFolder?.response?.breadcrumbs?.map(
                                        (item: any, index: number) => (
                                            <li
                                                key={index}
                                                className={`breadcrumb-item disable-selection ${handleActiveBreadcrumb(
                                                    item,
                                                    'class',
                                                )}`}
                                                role={handleActiveBreadcrumb(
                                                    item,
                                                    'role',
                                                )}
                                                // role={
                                                //     reduxFolder?.response?.breadcrumbs
                                                //         .length === 1
                                                //         ? ''
                                                //         : item?.uuid === urlFolderId ??
                                                //           urlSpaceId
                                                //         ? ''
                                                //         : 'button'
                                                // }
                                            >
                                                <span
                                                    onClick={e =>
                                                        handleBreadcrumb(e, item, index)
                                                    }
                                                >
                                                    {item?.name}
                                                </span>
                                            </li>
                                        ),
                                    )}
                                </ol>
                            </nav>
                        </div>
                        <div className="col-md-3">
                            {contextData.data.length > 0 && (
                                <div className="d-flex align-items-center justify-content-end">
                                    {contextData.data.length} to{' '}
                                    {contextData.type === 'copy folder' ? 'copy' : 'cut'}
                                    <CloseButton
                                        style={{
                                            fontSize: 10,
                                            marginLeft: 5,
                                        }}
                                        onClick={() =>
                                            setContextData({ type: '', data: [] })
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        {reduxFolder?.response !== null ? (
                            <div className="col align-self-center">
                                <span>
                                    {reduxFolder?.response?.total_folders ?? 0} folder
                                </span>
                                &nbsp;|&nbsp;
                                <span>
                                    {reduxFolder?.response?.total_files ?? 0} file
                                </span>
                                {selectedCheckbox.length > 0 && (
                                    <>
                                        &nbsp;|&nbsp;
                                        <span>
                                            {selectedCheckbox.length ?? 0} selected
                                        </span>
                                    </>
                                )}
                            </div>
                        ) : (
                            <div className="col-md-12"></div>
                        )}

                        {reduxFolder?.loading !== true ? (
                            <MultipleAction
                                items={items}
                                drawingAccess={drawingAccess}
                                selectedCheckbox={selectedCheckbox}
                                urlSpaceId={urlSpaceId}
                                urlTypeId={urlTypeId}
                                contextData={contextData}
                                reduxFolder={reduxFolder}
                                handleModal={handleModal}
                                handleActionMultiple={handleActionMultiple}
                                handleSetContext={handleSetContext}
                                handleActionRightClick={handleActionRightClick}
                            />
                        ) : null}
                    </div>
                    <div
                        className="wrapper-table mt-3"
                        // onContextMenu={e =>
                        //     !isMobileScreen &&
                        //     contextData.data.length > 0 &&
                        //     urlSpaceId !== 'trash' &&
                        //     urlSpaceId?.includes('search=') !== true &&
                        //     reduxFolder?.response?.permission?.can_collaborate_drawing ===
                        //         true &&
                        //     handleRightClick(e, null, 'outside-folder')
                        // }
                    >
                        <InfiniteScroll
                            next={() =>
                                handlePaginationFolder(
                                    reduxFolder?.response?.meta?.current_page,
                                )
                            }
                            hasMore={
                                reduxFolder?.response?.meta?.current_page <
                                reduxFolder?.response?.meta?.last_page
                            }
                            loader={<div />}
                            dataLength={items?.length ?? 0}
                            height={'60vh'}
                            className="space-scroll hidden-scroll"
                        >
                            <table className="table table-hover table-borderless mb-0">
                                <thead>
                                    <tr>
                                        <th className="td-checkbox">
                                            <BasicTooltip
                                                text={`Maximum ${maxSelect} items for select`}
                                            >
                                                <Form.Check
                                                    type="checkbox"
                                                    id="checkboxAll"
                                                    onChange={e =>
                                                        handleSelectCheckbox(
                                                            e,
                                                            'checkAll',
                                                        )
                                                    }
                                                    checked={checkboxAllChecked}
                                                />
                                            </BasicTooltip>
                                        </th>
                                        <th>File Name</th>
                                        <th className="text-center">Date Added</th>
                                        <th>Size</th>
                                        <th className="text-center">Revision Count</th>
                                        {urlTypeId === 'private' && <th>Created By</th>}
                                        <th>Download</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody className={'items-container'}>
                                    {items?.length === 0 ? (
                                        <tr>
                                            <td
                                                colSpan={7}
                                                className="text-muted text-center"
                                            >
                                                No data found
                                            </td>
                                        </tr>
                                    ) : (
                                        items?.map((rowData, index) => (
                                            <tr
                                                className={`tr-items disable-selection 
                                            ${
                                                selectedCheckbox.includes(rowData?.uuid)
                                                    ? 'tr-selected'
                                                    : ''
                                            } 
                                            ${
                                                activeDropdown === rowData?.uuid
                                                    ? 'tr-selected'
                                                    : ''
                                            } ${
                                                    contextData.data?.includes(rowData)
                                                        ? 'tr-copied'
                                                        : ''
                                                }`}
                                                // onContextMenu={e =>
                                                //     !isMobileScreen &&
                                                //     rowData?.type !== 'Prev' &&
                                                //     urlSpaceId !== 'trash' &&
                                                //     handleRightClick(
                                                //         e,
                                                //         [rowData],
                                                //         'folder',
                                                //     )
                                                // }
                                                key={index}
                                                onDoubleClick={e =>
                                                    handleDoubleClick(
                                                        e,
                                                        projectId,
                                                        moduleType,
                                                        tab,
                                                        urlSpaceId ?? '',
                                                        rowData?.uuid,
                                                        rowData?.type,
                                                        rowData,
                                                    )
                                                }
                                            >
                                                <td className="td-checkbox">
                                                    {rowData?.type !== 'Prev' && (
                                                        <Form.Check
                                                            type="checkbox"
                                                            id={`checkbox${rowData?.uuid}`}
                                                            onChange={e =>
                                                                handleSelectCheckbox(
                                                                    e,
                                                                    rowData?.uuid,
                                                                )
                                                            }
                                                            checked={selectedCheckbox.includes(
                                                                rowData?.uuid,
                                                            )}
                                                        />
                                                    )}
                                                </td>
                                                <td className="word-break">
                                                    {rowData?.type !== 'Prev' &&
                                                        handleIcons(rowData?.format)}
                                                    {rowData?.name}
                                                </td>
                                                <td className="text-center">
                                                    {rowData?.type !== 'Prev' &&
                                                        formatDateIso(
                                                            rowData?.created_at,
                                                        )}
                                                </td>
                                                <td>{rowData?.size}</td>
                                                <td className="text-center">
                                                    {rowData?.type !== 'Prev' &&
                                                    (rowData?.versions?.length ?? 0) > 1
                                                        ? rowData?.versions
                                                              ?.slice(0, -1)
                                                              .map((version, index) => (
                                                                  <span
                                                                      key={index}
                                                                      className="link-primary"
                                                                      role="button"
                                                                      onClick={e =>
                                                                          handlePreviewRevision(
                                                                              e,
                                                                              version,
                                                                              rowData?.versions,
                                                                          )
                                                                      }
                                                                  >
                                                                      {index > 0 && ', '}
                                                                      {version?.version_count +
                                                                          1}
                                                                  </span>
                                                              ))
                                                        : '-'}
                                                </td>
                                                {urlTypeId === 'private' &&
                                                    rowData?.type !== 'Prev' && (
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    src={
                                                                        rowData?.creator
                                                                            ?.user_avatar ??
                                                                        generateInitialImageUrl(
                                                                            rowData
                                                                                ?.creator
                                                                                .user_name ??
                                                                                rowData
                                                                                    ?.creator
                                                                                    .user_email,
                                                                        )
                                                                    }
                                                                    alt={
                                                                        rowData?.creator
                                                                            ?.user_name
                                                                    }
                                                                    className="avatar me-2"
                                                                />
                                                                {rowData?.creator
                                                                    ?.user_name ?? '-'}
                                                            </div>
                                                        </td>
                                                    )}

                                                <td>
                                                    {rowData?.type !== 'Prev' && (
                                                        <span
                                                            className="link-primary"
                                                            role="button"
                                                            onClick={() =>
                                                                handleModal(
                                                                    false,
                                                                    'download single',
                                                                    'Download',
                                                                    rowData,
                                                                )
                                                            }
                                                        >
                                                            Download
                                                        </span>
                                                    )}
                                                </td>
                                                <td>
                                                    {rowData?.type !== 'Prev' && (
                                                        <Dropdown
                                                            show={
                                                                activeDropdown ===
                                                                rowData.uuid
                                                            }
                                                            onToggle={isOpen => {
                                                                if (isOpen) {
                                                                    return setActiveDropdown(
                                                                        rowData.uuid,
                                                                    );
                                                                }
                                                                setActiveDropdown('');
                                                            }}
                                                        >
                                                            <Dropdown.Toggle
                                                                variant="none"
                                                                id="dropdown-autoclose-true"
                                                            >
                                                                <HiDotsVertical
                                                                    size={14}
                                                                    color="#000"
                                                                />
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                {/* {rowData?.type ===
                                                                    'File' &&
                                                                    urlSpaceId !==
                                                                        'trash' && (
                                                                        <BasicTooltip
                                                                            text={
                                                                                'Coming Soon'
                                                                            }
                                                                        >
                                                                            <Dropdown.Item
                                                                                style={{
                                                                                    fontSize: 13,
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleModal(
                                                                                        false,
                                                                                        'compare',
                                                                                        'Compare',
                                                                                        rowData,
                                                                                    )
                                                                                }
                                                                                disabled
                                                                            >
                                                                                <AiOutlineCloud
                                                                                    className="custom-icon me-2"
                                                                                    size={
                                                                                        18
                                                                                    }
                                                                                />
                                                                                Overlay
                                                                                With
                                                                            </Dropdown.Item>
                                                                        </BasicTooltip>
                                                                    )} */}
                                                                {folderDropdowns.map(
                                                                    (item: any) =>
                                                                        Boolean(
                                                                            item.only(
                                                                                rowData?.permission,
                                                                            ),
                                                                        ) && (
                                                                            <Dropdown.Item
                                                                                key={
                                                                                    item?.key
                                                                                }
                                                                                href="#"
                                                                                style={{
                                                                                    fontSize: 13,
                                                                                }}
                                                                                onClick={() =>
                                                                                    item.onclick(
                                                                                        rowData,
                                                                                    )
                                                                                }
                                                                                className={`text-${
                                                                                    item.variant as string
                                                                                }`}
                                                                            >
                                                                                {
                                                                                    item.icon
                                                                                }
                                                                                {
                                                                                    item.name
                                                                                }
                                                                                {item.name ===
                                                                                    'Edit' &&
                                                                                    rowData.type ===
                                                                                        'File' &&
                                                                                    '/Add Revision'}{' '}
                                                                                {
                                                                                    rowData?.type
                                                                                }
                                                                            </Dropdown.Item>
                                                                        ),
                                                                )}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    )}
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                            {/* </div> */}
                        </InfiniteScroll>
                    </div>
                </div>
            </div>

            {clicked.status && (
                <ContextWrapper
                    className="bg-white"
                    style={{
                        top: clicked.y,
                        left: clicked.x,
                        zIndex: 1,
                    }}
                >
                    {clicked.type === 'space' && (
                        <div className="context-menu">
                            <div
                                className="context-menu-item"
                                role="button"
                                onClick={() => handleSetContext('copy space')}
                            >
                                Copy
                            </div>
                            <div
                                className="context-menu-item"
                                role="button"
                                onClick={() => handleSetContext('move space')}
                            >
                                Cut
                            </div>
                        </div>
                    )}

                    {clicked.type === 'folder' && (
                        <div className="context-menu">
                            <div
                                className="context-menu-item"
                                role="button"
                                onClick={() => handleSetContext('copy folder')}
                            >
                                Copy
                            </div>
                            <div
                                className="context-menu-item"
                                role="button"
                                onClick={() => handleSetContext('move folder')}
                            >
                                Cut
                            </div>
                        </div>
                    )}

                    {clicked.type === 'outside-folder' && (
                        <div className="context-menu">
                            <div
                                className="context-menu-item"
                                role="button"
                                onClick={e => handleActionRightClick(e, contextData)}
                            >
                                Paste
                            </div>
                        </div>
                    )}
                </ContextWrapper>
            )}
        </>
    );
}

const theme = {
    red: '#651515',
    primary: 'rgb(86, 72, 251)',
    secondary: 'rgb(237, 236, 254)',
    grey: 'rgb(131, 131, 131)',
    info: '#7d76ca',
};

const ContextWrapper = styled.div`
    position: absolute;
    border-radius: 10px !important;
    padding: 0.3rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    font-size: 14px !important;

    /* .context-menu {
    } */

    .context-menu-item {
        padding: 0.5rem;

        &:hover {
            color: ${theme.primary};
            background-color: ${theme.secondary};
        }

        &:hover {
            color: ${theme.primary};
        }
    }
`;
