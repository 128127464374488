import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { AiFillCloseCircle } from 'react-icons/ai';

import InputSelect from '@/components/atoms/Inputs/InputSelect';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';

import styled from 'styled-components';

import {
    kickAdminFromGroup,
    kickMemberFromGroup,
    promoteAdminFromGroup,
    promoteMemberFromGroup,
    inviteToCurrentGroup,
    clearStateParticipantsGroup,
    clearStatePromote,
    clearStateInviteToCurrentGroup,
} from '../redux/actions';

import { generateInitialImageUrl, getId } from '@/utility/Utils';
import { RootState } from '@/redux/store';
import InputSearchSelect from '@/components/atoms/Inputs/InputSearchSelect';
import { meiliGetChatDetails, meiliGetChatLists } from '@/utility/MeiliSearch';
import BasicTooltip from '@/components/atoms/Tooltips';
import { toast } from 'react-toastify';

export default function ModalManageUser(params: {
    modal: boolean;
    setModal: (value: any) => void;
    setChannelId: (value: any) => void;
}): JSX.Element {
    const { modal, setModal, setChannelId } = params;
    const dispatch = useDispatch();

    const userId = getId();

    const [inviteUser, setInviteUser] = useState<any>([]);
    const [userList, setUserList] = useState([]);
    const [modalChild, setModalChild] = useState({
        remove: false,
        success: false,
        successInvite: false,
    });
    const [selectedUser, setSelectedUser] = useState({
        type: 0,
        email: '',
    });

    const promoteGroupState = useSelector((state: RootState) => state.promoteGroup);
    const chatDetailsState = useSelector((state: RootState) => state.chatDetails);
    const allUserChatState = useSelector((state: RootState) => state.allUserChat);
    const inviteToCurrentGroupState = useSelector(
        (state: RootState) => state.inviteToCurrentGroup,
    );
    const kickGroupParticipantState = useSelector(
        (state: RootState) => state.kickGroupParticipant,
    );

    const permissionList = [
        {
            id: '1',
            label: 'Admin',
            value: 'admin',
        },
        {
            id: '2',
            label: 'Member',
            value: 'member',
        },
    ];

    // Function for Fetch all user
    useEffect(() => {
        if (allUserChatState?.status === 200) {
            const data = allUserChatState?.response?.data;

            setUserList(
                data.map(
                    (item: {
                        name: string;
                        email: string;
                        company_name: string;
                        role_name: string;
                    }) => {
                        return {
                            label: `${item.name} - ${item.company_name ?? ''} (${
                                item.role_name
                            })`,
                            value: item.email,
                        };
                    },
                ),
            );
        }
    }, [allUserChatState]);

    // Function for Kick member
    useEffect(() => {
        if (kickGroupParticipantState?.status === 200) {
            const chnlId = kickGroupParticipantState?.response?.id;

            setModalChild(prev => ({
                ...prev,
                remove: false,
            }));

            meiliGetChatDetails(chnlId, response => {
                dispatch({
                    type: 'CHAT_DETAILS_BROADCAST',
                    payload: response?.hits,
                });
            });

            setTimeout(() => {
                meiliGetChatLists(results => {
                    // dispatch to redux
                    dispatch({
                        type: 'CHAT_LIST_BROADCAST',
                        payload: results?.hits,
                    });

                    dispatch({
                        type: 'CHAT_DETAILS_DATA',
                        payload: results?.hits.find((item: any) => item.id === chnlId),
                    });
                });
                dispatch(clearStateParticipantsGroup());
                setChannelId(chnlId);
            }, 2000);
        }
    }, [kickGroupParticipantState]);

    // Function for Invite user
    useEffect(() => {
        if (inviteToCurrentGroupState?.status === 200) {
            const chnlId = inviteToCurrentGroupState?.response?.id;

            meiliGetChatDetails(chnlId, response => {
                dispatch({
                    type: 'CHAT_DETAILS_BROADCAST',
                    payload: response?.hits,
                });
            });

            setTimeout(() => {
                meiliGetChatLists(results => {
                    // dispatch to redux
                    dispatch({
                        type: 'CHAT_LIST_BROADCAST',
                        payload: results?.hits,
                    });

                    dispatch({
                        type: 'CHAT_DETAILS_DATA',
                        payload: results?.hits.find((item: any) => item.id === chnlId),
                    });
                });
                dispatch(clearStateInviteToCurrentGroup());
                dispatch(clearStateParticipantsGroup());
                setChannelId(chnlId);
                setInviteUser([]);
            }, 2000);

            toast.info('Success invite user to group');
        }
    }, [inviteToCurrentGroupState]);

    // Function for Promote member
    useEffect(() => {
        if (promoteGroupState.status === 200) {
            const chnlId = promoteGroupState?.response?.id;

            meiliGetChatDetails(chnlId, response => {
                dispatch({
                    type: 'CHAT_DETAILS_BROADCAST',
                    payload: response?.hits,
                });
            });

            setTimeout(() => {
                meiliGetChatLists(results => {
                    // dispatch to redux
                    dispatch({
                        type: 'CHAT_LIST_BROADCAST',
                        payload: results?.hits,
                    });

                    dispatch({
                        type: 'CHAT_DETAILS_DATA',
                        payload: results?.hits.find((item: any) => item.id === chnlId),
                    });
                });
                dispatch(clearStatePromote());
                setChannelId(chnlId);
            }, 2000);
        }
    }, [promoteGroupState]);

    /**
     * Function for handle remove user
     *
     * @param email string
     * @param type number
     * @returns void
     */
    const handleRemoveUser = (email: string, type: number): void => {
        setSelectedUser({
            type,
            email,
        });
        setModalChild({
            ...modalChild,
            remove: true,
        });
    };

    /**
     * Function for handle add member
     *
     * @param email string
     * @return void
     */
    const handleMember = (email: string): void => {
        const payload = new FormData();
        payload.append('group_member_emails[0]', email);

        dispatch(
            promoteMemberFromGroup({ channel_id: chatDetailsState.channelId }, payload),
        );

        toast.info('Save Permission', {
            position: 'top-right',
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    /**
     * Function for handle add admin
     *
     * @param email string
     * @returns void
     */
    const handleAdmin = (email: string): void => {
        const payload = new FormData();
        payload.append('group_member_emails[0]', email);

        dispatch(
            promoteAdminFromGroup({ channel_id: chatDetailsState.channelId }, payload),
        );

        toast.info('Save Permission', {
            position: 'top-right',
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    /**
     * Function for handle invite member
     *
     * @returns void
     */
    const handleInviteUserGroup = (): void => {
        const payload = new FormData();
        inviteUser.forEach((item: any, index: number) => {
            payload.append(`group_member_emails[${index}]`, item.value);
        });

        dispatch(
            inviteToCurrentGroup({ channel_id: chatDetailsState.channelId }, payload),
        );
    };

    // const formatOptionLabel = ({
    //     value,
    //     label,
    //     customAbbreviation,
    // }: {
    //     value: string;
    //     label: string;
    //     customAbbreviation: string;
    // }): JSX.Element => (
    //     <div className="d-flex flex-column">
    //         <div>{label}</div>
    //         <div>{value}</div>
    //     </div>
    // );

    return (
        <>
            <ModalWrapper
                show={modal}
                onHide={() => setModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="justify-content-start px-4">
                    <h6 className="fw-semibold text-center mb-0">Manage User</h6>
                </Modal.Header>
                <Modal.Body className="mb-4">
                    <p className="fw-semibold text-start mb-0">Invite User</p>
                    <div className="d-flex justify-content-between align-items-center">
                        <InputSearchSelect
                            placeholder="Type or select email"
                            innerClassName="mb-1"
                            outerClassName="mt-3 w-100"
                            options={userList}
                            isMulti={true}
                            withCreatable
                            placeholderCreatable="Invite"
                            value={inviteUser}
                            onBlur={e => {
                                // validation email
                                const regex =
                                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/g;
                                const isValid = regex.test(e.target.value);
                                if (e.target.value !== '' && isValid) {
                                    setInviteUser((prevData: any) =>
                                        prevData.concat({
                                            label: e.target.value,
                                            value: e.target.value,
                                        }),
                                    );
                                }
                            }}
                            // TODO:VALIDATION EMAIL
                            onChange={(value: { value: string; label: string }) => {
                                setInviteUser(value as any);
                            }}
                        />
                    </div>
                    <ButtonBasic
                        className="my-3 btn-primary w-25"
                        text="Invite"
                        type="button"
                        onClick={() => handleInviteUserGroup()}
                        loading={inviteToCurrentGroupState.loading}
                        disabled={inviteUser.length === 0}
                    />
                    <p className="fw-semibold mt-4 mb-2 text-start">Project Member</p>

                    <div className="border-top text-start">
                        <p className="mt-2">Owner</p>
                        <div className="d-flex align-items-center justify-content-between my-2">
                            <div className="d-flex align-items-center">
                                <ProfilePicture
                                    className="chat-profile me-2"
                                    src={
                                        chatDetailsState?.data?.owner_avatar ??
                                        `'${generateInitialImageUrl(
                                            chatDetailsState?.data?.owner_name,
                                        )}'`
                                    }
                                    status={0}
                                />
                                <div className="d-flex flex-column">
                                    <span
                                        className="fw-semibold"
                                        style={{
                                            fontSize: 14,
                                        }}
                                    >
                                        {chatDetailsState?.data?.owner_name ??
                                            'Other Company'}
                                        &nbsp;({chatDetailsState?.data?.owner_email})
                                    </span>
                                    <span
                                        className="sender-status text-muted"
                                        style={{
                                            fontSize: 12,
                                        }}
                                    >
                                        {chatDetailsState?.data?.members[0]?.company_name}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <p className="mt-5">Admin</p>
                        {chatDetailsState?.data?.administrators.length > 0 ? (
                            chatDetailsState?.data?.administrators?.map(
                                (administrator: any, index: number) => (
                                    <div
                                        key={index}
                                        className="d-flex align-items-center justify-content-between my-2"
                                    >
                                        <div className="d-flex align-items-center">
                                            <ProfilePicture
                                                className="chat-profile me-2"
                                                src={
                                                    administrator?.avatar ??
                                                    `'${generateInitialImageUrl(
                                                        administrator?.name,
                                                    )}'`
                                                }
                                                status={0}
                                            />
                                            <div className="d-flex flex-column">
                                                <span
                                                    className="fw-semibold"
                                                    style={{
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    {administrator?.name ??
                                                        'Other Company'}
                                                    &nbsp;({administrator?.email})
                                                </span>
                                                <span
                                                    className="sender-status text-muted"
                                                    style={{
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    {administrator?.company_name}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            {chatDetailsState?.data?.owner_id ===
                                                userId && (
                                                <InputSelect
                                                    options={permissionList}
                                                    outerClassName="me-2"
                                                    withoutborderbottom
                                                    placeholder={permissionList[0].label}
                                                    onChange={() =>
                                                        handleMember(administrator.email)
                                                    }
                                                />
                                            )}
                                            <BasicTooltip text="Remove from group">
                                                <AiFillCloseCircle
                                                    color="rgba(251, 96, 86, 1)"
                                                    size={20}
                                                    className="me-2"
                                                    style={{
                                                        cursor: 'pointer',
                                                        opacity:
                                                            chatDetailsState?.data
                                                                ?.owner_id !== userId
                                                                ? 0
                                                                : 1,
                                                    }}
                                                    onClick={() =>
                                                        handleRemoveUser(
                                                            administrator.email,
                                                            1,
                                                        )
                                                    }
                                                />
                                            </BasicTooltip>
                                        </div>
                                    </div>
                                ),
                            )
                        ) : (
                            <div className="text-center">
                                <span
                                    className=""
                                    style={{
                                        fontSize: 14,
                                    }}
                                >
                                    No admin in this group
                                </span>
                            </div>
                        )}

                        <p className="mt-5">Member</p>
                        {chatDetailsState?.data?.participants?.length > 0 ? (
                            chatDetailsState?.data?.participants?.map(
                                (participant: any, index: number) => (
                                    <div
                                        key={index}
                                        className="d-flex align-items-center justify-content-between my-2"
                                    >
                                        <div className="d-flex align-items-center">
                                            <ProfilePicture
                                                className="chat-profile me-2"
                                                src={
                                                    participant?.avatar ??
                                                    `'${generateInitialImageUrl(
                                                        participant?.name,
                                                    )}'`
                                                }
                                                status={0}
                                            />
                                            <div className="d-flex flex-column">
                                                <span
                                                    className="fw-semibold"
                                                    style={{
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    {participant?.name ?? 'Other Company'}
                                                    &nbsp;({participant?.email})
                                                </span>
                                                <span
                                                    className="sender-status text-muted"
                                                    style={{
                                                        fontSize: 12,
                                                    }}
                                                >
                                                    {participant?.company_name}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            {chatDetailsState?.data?.owner_id ===
                                                userId && (
                                                <InputSelect
                                                    options={permissionList}
                                                    withoutborderbottom
                                                    outerClassName="me-2"
                                                    placeholder={permissionList[1].label}
                                                    onChange={() =>
                                                        handleAdmin(participant.email)
                                                    }
                                                />
                                            )}
                                            {participant?.id !== userId && (
                                                <BasicTooltip text="Remove from group">
                                                    <AiFillCloseCircle
                                                        color="rgba(251, 96, 86, 1)"
                                                        size={20}
                                                        className="me-2"
                                                        role="button"
                                                        onClick={() =>
                                                            handleRemoveUser(
                                                                participant.email,
                                                                2,
                                                            )
                                                        }
                                                    />
                                                </BasicTooltip>
                                            )}
                                        </div>
                                    </div>
                                ),
                            )
                        ) : (
                            <div className="text-center">
                                <span
                                    className=""
                                    style={{
                                        fontSize: 14,
                                    }}
                                >
                                    No member in this group
                                </span>
                            </div>
                        )}

                        {chatDetailsState?.data?.unregistered_participants.length > 0 && (
                            <p className="mt-5">Unregistered Member</p>
                        )}
                        {chatDetailsState?.data?.unregistered_participants.length > 0 &&
                            chatDetailsState?.data?.unregistered_participants?.map(
                                (unregisterUser: any, index: number) => (
                                    <div
                                        key={index}
                                        className="d-flex align-items-center justify-content-between my-2"
                                    >
                                        <div className="d-flex align-items-center">
                                            <ProfilePicture
                                                className="chat-profile me-2"
                                                src={
                                                    unregisterUser?.avatar ??
                                                    `'${generateInitialImageUrl(
                                                        unregisterUser?.name,
                                                    )}'`
                                                }
                                                status={0}
                                            />
                                            <div className="d-flex flex-column">
                                                <span
                                                    className="fw-semibold"
                                                    style={{
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    {unregisterUser?.email ??
                                                        'Other Company'}
                                                    &nbsp;(Not registered)
                                                </span>
                                            </div>
                                        </div>
                                        <BasicTooltip text="Remove from group">
                                            <AiFillCloseCircle
                                                color="rgba(251, 96, 86, 1)"
                                                size={20}
                                                className="me-2"
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() =>
                                                    handleRemoveUser(
                                                        unregisterUser.email,
                                                        2,
                                                    )
                                                }
                                            />
                                        </BasicTooltip>
                                    </div>
                                ),
                            )}

                        {/* {chatDetailsState?.data?.administrators?.length === 0 &&
                            chatDetailsState?.data?.participants?.length === 0 &&
                            chatDetailsState?.data?.unregistered_participants?.length ===
                                0 && (
                                <div className="text-center">
                                    <span
                                        className="fw-semibold"
                                        style={{
                                            fontSize: 14,
                                        }}
                                    >
                                        No member
                                    </span>
                                </div>
                            )} */}
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <ButtonBasic onClick={() => setModal(false)} text="Confirm" />
                </Modal.Footer> */}
            </ModalWrapper>
            <ModalDashboard
                modal={modalChild.remove}
                setModal={setModalChild}
                overlay={1}
                variant="danger"
                type="cancel"
                title="Remove User?"
                body={`Are you sure want to remove ${selectedUser.email} from group?`}
                withCancel
                onConfirm={() => {
                    selectedUser.type === 1
                        ? dispatch(
                              kickAdminFromGroup({
                                  channel_id: chatDetailsState?.data?.id,
                                  email: selectedUser.email,
                              }),
                          )
                        : dispatch(
                              kickMemberFromGroup({
                                  channel_id: chatDetailsState?.data?.id,
                                  email: selectedUser.email,
                              }),
                          );
                }}
                loading={kickGroupParticipantState?.loading}
            />
            {/* <ModalDashboard
                modal={modalChild.exitGroup}
                setModal={setModal}
                variant="danger"
                type="cancel"
                title="Exit from Group?"
                body="Are you sure want to exit from this group chat?"
                withCancel
                onConfirm={() => handleExitGroupChat(chatDetailsState.channelId)}
                loading={exitGroupState?.loading}
            /> */}
            <ModalDashboard
                modal={modalChild.success}
                setModal={setModalChild}
                variant="success"
                type="success"
                title="Success"
                body={`Success remove ${selectedUser.email} from group`}
                onConfirm={() => setModalChild(prev => ({ ...prev, success: false }))}
            />
            <ModalDashboard
                modal={modalChild.successInvite}
                setModal={setModalChild}
                variant="success"
                type="success"
                title="Success"
                body={'Success invite user to group'}
                onConfirm={() =>
                    setModalChild(prev => ({ ...prev, successInvite: false }))
                }
            />
        </>
    );
}

const ModalWrapper = styled(Modal)`
    font-size: 14px;

    hr {
        color: #bdbdbd;
    }

    .modal-content {
        font-family: 'Montserrat', sans-serif;
        border-radius: 10px;
    }

    .form-floating > label {
        padding: 1.4rem;
    }

    .form-select {
        font-size: 14px;
        height: 35px !important;
    }

    .modal-header {
        border: none;
        border-bottom: 1px solid #e9ecef;
        padding: 1rem;

        .btn-close {
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 10px;
        }
    }

    .modal-body {
        text-align: center;
        padding: 1.5rem;

        .form-floating {
            font-size: 14px;
        }

        .label-active {
            padding: 1.5rem 0.5rem;
        }

        #taskName {
            padding: 10px 0 0 2px !important;
        }

        select {
            padding: 0 0 0 2px !important;
            font-size: 15px;
            box-shadow: none !important;
        }
    }
    .modal-footer {
        justify-content: center;
        border: none;
        flex-wrap: initial;
    }
`;

const ProfilePicture = styled.div<{ src: string; status: number }>`
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-image: url(${props => props.src});
    background-size: cover;
    background-position: center;
    border: ${props => (props.status !== 0 ? '3px solid #5648fb' : 'none')};
`;
