import { Fragment } from 'react';
import { Form } from 'react-bootstrap';

import InputBasic from '@/components/atoms/Inputs/InputBasic';
import LinkBack from '@/components/molecules/LinkBack';
import { HeaderContent } from '@/components/molecules/Typography';
import { HeaderStepper, BodyStepper } from '@/components/molecules/VerticalStepper';
import { v4 } from 'uuid';

interface DataProps {
    id: number;
    name: string;
    trades: DataProps[];
}

export default function CompanyNiche(params: {
    register: any;
    errors: any;
    watch: any;
    nicheLists: DataProps[];
    subContractorLists: DataProps[];
}): JSX.Element {
    const { register, errors, watch, nicheLists, subContractorLists } = params;

    const SubTrades = (): JSX.Element | null => {
        const nicheId = Number(watch('company_niche_id'));
        const findNiche: any = nicheLists?.find(list => list.id === nicheId);

        if (findNiche?.trades?.length > 0) {
            return (
                <div className="col-md-4">
                    {findNiche?.trades?.map((list: any) => (
                        <Form.Check
                            id={v4()}
                            key={list.id}
                            type="radio"
                            label={list.name}
                            className="mb-3"
                            value={list.id}
                            checked={
                                list.id === parseInt(watch('company_niche_trade_id'))
                            }
                            {...register('company_niche_trade_id', {
                                required: 'Niche trade is required',
                            })}
                        />
                    ))}
                    {errors.company_niche_trade_id != null && (
                        <p
                            className="invalid-feedback d-block text-start"
                            style={{ fontSize: '0.75rem' }}
                        >
                            {errors.company_niche_trade_id.message as string}
                        </p>
                    )}
                </div>
            );
        }

        return null;
    };
    return (
        <Fragment>
            <HeaderStepper className="row fade-in mt-5">
                <div className="col-md-4 ms-auto mt-2 mb-5">
                    <HeaderContent
                        text="Construction Company Niche"
                        subtext="Select construction company niche"
                    />
                </div>
                <div className="col-md-4 text-end">
                    <LinkBack text="Return to home screen" />
                </div>
            </HeaderStepper>
            <BodyStepper className="row fade-in" style={{ marginBottom: '10rem' }}>
                {/* Niche list */}
                <div
                    className={`col-md-4 mb-3 ${
                        Number(watch('company_niche_id')) ===
                            nicheLists?.find(list => list.name === 'Sub Contractor')
                                ?.id ||
                        Number(watch('company_niche_id')) ===
                            nicheLists?.find(list => list.id === 1)?.id ||
                        Number(watch('company_niche_id')) ===
                            nicheLists?.find(list => list.id === 2)?.id
                            ? 'ms-auto'
                            : 'mx-auto'
                    }`}
                >
                    {nicheLists?.map(list => (
                        <Form.Check
                            id={list.name}
                            key={list.id}
                            type="radio"
                            label={list.name}
                            className="mb-3"
                            name="company_niche_id"
                            value={list.id}
                            {...register('company_niche_id', {
                                required: 'Niche is required',
                            })}
                        />
                    ))}
                    {errors.company_niche_id != null && (
                        <p
                            className="invalid-feedback d-block text-start"
                            style={{ fontSize: '0.75rem' }}
                        >
                            {errors.company_niche_id.message as string}
                        </p>
                    )}
                    {Number(watch('company_niche_id')) ===
                        nicheLists?.find(list => list.name === 'Other')?.id && (
                        <InputBasic
                            id="nicheIdOther"
                            type="text"
                            placeholder="Enter Construction Company Niche"
                            outerClassName="mb-2"
                            rules={{
                                function: register,
                                name: 'company_niche_other',
                                rules: {
                                    required: 'Please enter your niche',
                                },
                                errors,
                            }}
                        />
                    )}
                </div>
                {/* Subcontractor List */}
                <SubTrades />
                {Number(watch('company_niche_id')) ===
                    nicheLists?.find(list => list.name === 'Sub Contractor')?.id && (
                    <div className="col-md-4">
                        {subContractorLists?.map(list => (
                            <Form.Check
                                id={`subcontractor-${list.name}`}
                                key={list.id}
                                type="radio"
                                label={list.name}
                                className="mb-3"
                                name="company_sub_contract_trade_id"
                                value={list.id}
                                {...register('company_sub_contract_trade_id', {
                                    required: 'Niche is required',
                                })}
                            />
                        ))}
                        {errors.company_sub_contract_trade_id != null && (
                            <p
                                className="invalid-feedback d-block text-start"
                                style={{ fontSize: '0.75rem' }}
                            >
                                {errors.company_sub_contract_trade_id.message as string}
                            </p>
                        )}
                        {Number(watch('company_sub_contract_trade_id')) ===
                            subContractorLists?.find(list => list.name === 'Other')
                                ?.id && (
                            <InputBasic
                                id="subContractTradeOther"
                                type="text"
                                placeholder="Enter Sub Contract Trade"
                                outerClassName="mb-2"
                                rules={{
                                    function: register,
                                    name: 'company_sub_contract_trade_other',
                                    rules: {
                                        required: 'Please enter sub contractor trade',
                                    },
                                    errors,
                                }}
                            />
                        )}
                    </div>
                )}
            </BodyStepper>
        </Fragment>
    );
}
