export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const GOOGLE_REDIRECT_REQUEST = 'GOOGLE_REDIRECT_REQUEST';
export const GOOGLE_REDIRECT_SUCCESS = 'GOOGLE_REDIRECT_SUCCESS';
export const GOOGLE_REDIRECT_FAIL = 'GOOGLE_REDIRECT_FAIL';

export const REGISTER_COMPANY_COMPLETED_REQUEST = 'REGISTER_COMPANY_COMPLETED_REQUEST';
export const REGISTER_COMPANY_COMPLETED_SUCCESS = 'REGISTER_COMPANY_COMPLETED_SUCCESS';
export const REGISTER_COMPANY_COMPLETED_FAIL = 'REGISTER_COMPANY_COMPLETED_FAIL';

export const REGISTER_INHOUSE_COMPLETED_REQUEST = 'REGISTER_INHOUSE_COMPLETED_REQUEST';
export const REGISTER_INHOUSE_COMPLETED_SUCCESS = 'REGISTER_INHOUSE_COMPLETED_SUCCESS';
export const REGISTER_INHOUSE_COMPLETED_FAIL = 'REGISTER_INHOUSE_COMPLETED_FAIL';

export const COMPANY_USER_CREDENTIALS_REQUEST = 'COMPANY_USER_CREDENTIALS_REQUEST';
export const COMPANY_USER_CREDENTIALS_SUCCESS = 'COMPANY_USER_CREDENTIALS_SUCCESS';
export const COMPANY_USER_CREDENTIALS_FAIL = 'COMPANY_USER_CREDENTIALS_FAIL';

export const INHOUSE_USER_CREDENTIALS_REQUEST = 'INHOUSE_USER_CREDENTIALS_REQUEST';
export const INHOUSE_USER_CREDENTIALS_SUCCESS = 'INHOUSE_USER_CREDENTIALS_SUCCESS';
export const INHOUSE_USER_CREDENTIALS_FAIL = 'INHOUSE_USER_CREDENTIALS_FAIL';

export const ALL_NICHE_REQUEST = 'ALL_NICHE_REQUEST';
export const ALL_NICHE_SUCCESS = 'ALL_NICHE_SUCCESS';
export const ALL_NICHE_SUB_SUCCESS = 'ALL_NICHE_SUB_SUCCESS';
export const ALL_NICHE_FAIL = 'ALL_NICHE_FAIL';

export const ALL_SUBCONTRACTOR_REQUEST = 'ALL_SUBCONTRACTOR_REQUEST';
export const ALL_SUBCONTRACTOR_SUCCESS = 'ALL_SUBCONTRACTOR_SUCCESS';
export const ALL_SUBCONTRACTOR_FAIL = 'ALL_SUBCONTRACTOR_FAIL';

export const CLEAR_STATES = 'CLEAR_STATES';

const initialState = {
    loading: false,
    response: null,
    error: null,
    status: null,
};

export const registerReducer = (state: object = initialState, action: any): any => {
    switch (action.type) {
        case REGISTER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case REGISTER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATES:
            return initialState;

        default:
            return state;
    }
};

export const googleRedirectReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case GOOGLE_REDIRECT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case GOOGLE_REDIRECT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
            };

        case GOOGLE_REDIRECT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export const registerCompanyCompletedReducer = (
    state: object = initialState,
    action: any,
): any => {
    switch (action.type) {
        case REGISTER_COMPANY_COMPLETED_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case REGISTER_COMPANY_COMPLETED_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case REGISTER_COMPANY_COMPLETED_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATES:
            return initialState;

        default:
            return state;
    }
};

export const registerInhouseCompletedReducer = (
    state: object = initialState,
    action: any,
): any => {
    switch (action.type) {
        case REGISTER_INHOUSE_COMPLETED_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case REGISTER_INHOUSE_COMPLETED_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case REGISTER_INHOUSE_COMPLETED_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATES:
            return initialState;

        default:
            return state;
    }
};

export const companyUserCredentialReducer = (
    state: object = initialState,
    action: any,
): any => {
    switch (action.type) {
        case COMPANY_USER_CREDENTIALS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case COMPANY_USER_CREDENTIALS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case COMPANY_USER_CREDENTIALS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATES:
            return initialState;

        default:
            return state;
    }
};

export const inhouseUserCredentialReducer = (
    state: object = initialState,
    action: any,
): any => {
    switch (action.type) {
        case INHOUSE_USER_CREDENTIALS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case INHOUSE_USER_CREDENTIALS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case INHOUSE_USER_CREDENTIALS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATES:
            return initialState;

        default:
            return state;
    }
};

export const allNicheSubContractorReducer = (
    state: object = initialState,
    action: any,
): any => {
    switch (action.type) {
        case ALL_NICHE_REQUEST:
        case ALL_SUBCONTRACTOR_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_NICHE_SUCCESS:
            return {
                ...state,
                loading: false,
                niche: action.payload,
                status: 200,
            };

        case ALL_NICHE_SUB_SUCCESS:
            return {
                ...state,
                loading: false,
                nicheSub: action.payload,
                status: 200,
            };

        case ALL_SUBCONTRACTOR_SUCCESS:
            return {
                ...state,
                loading: false,
                subcontractor: action.payload,
                status: 200,
            };

        case ALL_NICHE_FAIL:
        case ALL_SUBCONTRACTOR_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATES:
            return initialState;

        default:
            return state;
    }
};
