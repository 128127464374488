import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    CoverSelected,
    IconContent,
    ProfileSelected,
    WrapperNotSelected,
    WrapperRowRight,
    WrapperSelected,
} from './styles';

import CoverBackground from '@/assets/images/CoverProfile.png';
import BuildingXmark from '@/assets/svg/icons/building-xmark.svg';

import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import { ThreeDots } from 'react-loader-spinner';
import { createDirectMessage, getChatList } from '@/routes/Dashboard/Chat/redux/actions';
import { RootState } from '@/redux/store';

export default function CompanyDetailView(props: {
    detailsState: any;
    moduleType: string;
    detailsLoading?: boolean;
}): JSX.Element {
    const { detailsState, moduleType, detailsLoading } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const newDirectMessageState = useSelector(
        (state: RootState) => state.newDirectMessage,
    );
    const currentProfileState = useSelector((state: RootState) => state.currentProfile);

    // Function to handle new direct message
    useEffect(() => {
        if (newDirectMessageState?.status === 200) {
            dispatch(getChatList({ page: 1, per_page: 9999 }));
            navigate('/chat', {
                state: {
                    id: newDirectMessageState?.response?.id,
                },
            });
        }
    }, [newDirectMessageState]);

    /**
     * Function to handle new direct message
     *
     * @param email string
     * @returns any
     */
    const handleNewDirectMessage = (email: string): any => {
        const payload = new FormData();
        payload.append('interlocutor_email', email);

        return dispatch(createDirectMessage(payload));
    };

    return (
        <div
            className="col-lg-5 col-xl-4 order-md-2 px-0"
            style={{
                borderBottom: '1px solid #e5e5e5',
                borderLeft: '1px solid #e5e5e5',
            }}
        >
            <WrapperRowRight className="row g-0">
                {detailsLoading ?? false ? (
                    <WrapperSelected>
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color="#5648FB"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                visible={detailsLoading}
                            />
                        </div>
                    </WrapperSelected>
                ) : detailsState !== null ? (
                    <WrapperSelected>
                        <CoverSelected cover={CoverBackground}>
                            <ProfileSelected src={detailsState?.company_picture} />
                        </CoverSelected>
                        <div className="row pt-5 px-4 mb-4">
                            <div className="col-lg-12">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h6 className="mb-0 fw-semibold">
                                            {detailsState?.company_name}
                                        </h6>
                                        <span
                                            className="text-muted"
                                            style={{
                                                fontSize: 14,
                                            }}
                                        >
                                            {detailsState?.company_niche_other ??
                                                detailsState?.company_niche?.name}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row px-4 mb-4">
                            <div className="col-lg-12">
                                <h6 className="fw-semibold" style={{ fontSize: 14 }}>
                                    Company Address
                                </h6>
                            </div>
                            <div className="col-lg-12">
                                <p
                                    className="text-muted mb-0"
                                    style={{
                                        fontSize: 12,
                                    }}
                                >
                                    {detailsState?.company_address ?? '-'}
                                </p>
                            </div>
                        </div>
                        <div
                            className="row px-4 mb-4 text-muted"
                            style={{ fontSize: 12 }}
                        >
                            <div className="col-md-12 mb-1">
                                <h6
                                    className="fw-semibold text-black"
                                    style={{ fontSize: 14 }}
                                >
                                    Company Contact Information
                                </h6>
                            </div>
                            <div className="col-md-12 mb-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    width={16}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                                    />
                                </svg>
                                <span className="ms-2">
                                    {detailsState?.company_email ??
                                        detailsState?.company_user?.email}
                                </span>
                            </div>
                            <div className="col-md-12 mb-1">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    width={16}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                                    />
                                </svg>
                                <span className="ms-2">
                                    {detailsState?.company_user?.phone ?? '-'}
                                </span>
                            </div>
                            <div className="col-lg-12">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    width={18}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                                    />
                                </svg>
                                <span className="ms-2">
                                    {detailsState?.company_website_url ?? '-'}
                                </span>
                            </div>
                        </div>

                        {currentProfileState?.response?.id !==
                            detailsState?.company_user?.id && (
                            <div className="row px-4 mb-4">
                                <div className="col-md-12 text-end">
                                    <ButtonBasic
                                        text="Start Chat"
                                        onClick={() =>
                                            handleNewDirectMessage(
                                                detailsState?.company_user?.email,
                                            )
                                        }
                                        loading={newDirectMessageState.loading}
                                    />
                                </div>
                            </div>
                        )}
                    </WrapperSelected>
                ) : (
                    <WrapperNotSelected className="col-lg-6">
                        <IconContent className="icon-selected" icon={BuildingXmark} />
                        <p className="text-muted text-center text-selected">
                            No {moduleType.toLowerCase()} selected
                        </p>
                    </WrapperNotSelected>
                )}
            </WrapperRowRight>
        </div>
    );
}
