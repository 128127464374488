import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import useImage from 'use-image';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { KonvaEventObject } from 'konva/lib/Node';
import styled from 'styled-components';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { debounce } from '@/utility/Utils';
import { OpenFile, Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { SelectionMode } from '@react-pdf-viewer/selection-mode';

import packageJson from '@/../package.json';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { ThreeDots } from 'react-loader-spinner';
import ComparedPDF from './ComparePDF';
import CompareImage from './CompareImage';
import { StructureCompareProps } from '@/routes/ProjectDashboard/Drawing/components/Explorer/interface';

interface ParamsProps {
    initialItem: StructureCompareProps | null;
    setInitialItem: Dispatch<SetStateAction<any>>;
    projectId: string;
    lockItems: boolean;
    handleSelectItem: (evt: KonvaEventObject<any>) => void;
    handleUnselectItem: (target: any) => void;
    selectedItem: string;
    stageRef: any;
}

export default function CompareCanvas(props: ParamsProps): JSX.Element {
    const {
        initialItem,
        setInitialItem,
        lockItems,
        handleSelectItem,
        handleUnselectItem,
        selectedItem,
        stageRef,
    } = props;

    const allDrawingCompareFileState = useSelector(
        (state: RootState) => state.allDrawingCompareFile,
    );

    const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];
    const pdfWorker = `https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`;

    const [compareItem, setCompareItem] = useState<StructureCompareProps | null>(null);

    const [image] = useImage(initialItem?.file ?? '');
    const imageRef = useRef<any>(null);

    const [newImage] = useImage(compareItem?.file ?? '');
    const newImageRef = useRef<any>(null);

    const [itemList, setItemList] = useState<StructureCompareProps[]>([]);

    const divRef = useRef<any>(null);
    const trRef = useRef<any>(null);

    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0,
    });

    useEffect(() => {
        if (
            Boolean(divRef.current?.offsetHeight) &&
            Boolean(divRef.current?.offsetWidth)
        ) {
            setDimensions({
                width: divRef.current.offsetWidth,
                height: divRef.current.offsetHeight,
            });
        }
    }, []);

    const toolbarPluginInstance = toolbarPlugin({
        getFilePlugin: {
            fileNameGenerator: (file: OpenFile) => {
                const fileName = file.name.substring(file.name.lastIndexOf('/') + 1);
                return fileName;
            },
        },
        searchPlugin: {
            keyword: 'PDF',
        },
        selectionModePlugin: {
            selectionMode: SelectionMode.Hand,
        },
    });
    const { Toolbar } = toolbarPluginInstance;

    // Function to handle the resize of the window
    useEffect(() => {
        if (selectedItem === initialItem?.uuid) {
            trRef.current?.nodes([imageRef.current]);
            trRef.current?.getLayer().batchDraw();
        }

        if (selectedItem === compareItem?.uuid) {
            trRef.current?.nodes([newImageRef.current]);
            trRef.current?.getLayer().batchDraw();
        }
    }, [selectedItem]);

    useEffect(() => {
        if (allDrawingCompareFileState?.status === 200) {
            setItemList(allDrawingCompareFileState?.response);
        }
    }, [allDrawingCompareFileState]);
    /**
     * Function to handle the start of the drag
     * @param e
     */
    const handleDragEnd = (e: any): void => {
        if (selectedItem === initialItem?.uuid) {
            setInitialItem({
                ...initialItem,
                offset: {
                    x: e.target.offsetX(),
                    y: e.target.offsetY(),
                },
            });
        }

        if (selectedItem === compareItem?.uuid) {
            setCompareItem({
                ...compareItem,
                offset: {
                    x: e.target.offsetX(),
                    y: e.target.offsetY(),
                },
            });
        }
    };

    const handleTransformEnd = (e: any): void => {
        if (selectedItem === initialItem?.uuid) {
            setInitialItem({
                ...initialItem,
                offset: {
                    x: e.target.offsetX(),
                    y: e.target.offsetY(),
                },
                scale: {
                    x: e.target.scaleX(),
                    y: e.target.scaleY(),
                },
            });
        }

        if (selectedItem === compareItem?.uuid) {
            setCompareItem({
                ...compareItem,
                offset: {
                    x: e.target.offsetX(),
                    y: e.target.offsetY(),
                },
                scale: {
                    x: e.target.scaleX(),
                    y: e.target.scaleY(),
                },
            });
        }
    };

    const handleSearch = debounce((e: any): void => {
        const value = e.target.value;

        if (value === '') {
            setItemList(allDrawingCompareFileState?.response);
        } else {
            const filter = allDrawingCompareFileState?.response.filter((item: any) =>
                item.name.toLowerCase().includes(value.toLowerCase()),
            );

            setItemList(filter);
        }
    }, 500);

    const renderTooltip = (props: any, item: any): JSX.Element => (
        <Popover id="popover-basic" {...props} className="w-100">
            <Popover.Header
                as="h6"
                className="text-break"
                style={{
                    fontSize: '13px',
                    fontWeight: 500,
                    backgroundColor: '#eeecff',
                }}
            >
                {item.name}
            </Popover.Header>
            <Popover.Body
                className="text-break"
                style={{
                    fontSize: '12px',
                    fontWeight: 400,
                }}
            >
                Path:{' '}
                {item.paths.map((path: string, index: number) => (
                    <span key={index} className="mb-0">
                        /{path}
                    </span>
                ))}
                /{item.name}
            </Popover.Body>
        </Popover>
    );

    const renderView = (): JSX.Element => {
        if (initialItem?.format === 'pdf') {
            return (
                <div className="row g-0">
                    <div className="col-md-6">
                        <Worker workerUrl={pdfWorker}>
                            <div
                                className="p-3"
                                style={{
                                    backgroundColor: 'rgb(213, 213, 213)',
                                }}
                            >
                                <Toolbar />
                            </div>
                            <Viewer
                                fileUrl={initialItem?.file ?? ''}
                                plugins={[toolbarPluginInstance as any]}
                            />
                        </Worker>
                    </div>

                    {compareItem !== null && (
                        <ComparedPDF
                            compareItem={compareItem}
                            setCompareItem={setCompareItem}
                        />
                    )}
                </div>
            );
        }

        return (
            <CompareImage
                stageRef={stageRef}
                image={image}
                imageRef={imageRef}
                newImage={newImage}
                newImageRef={newImageRef}
                trRef={trRef}
                initialItem={initialItem}
                compareItem={compareItem}
                selectedItem={selectedItem}
                dimensions={dimensions}
                handleSelectItem={handleSelectItem}
                handleDragEnd={handleDragEnd}
                handleTransformEnd={handleTransformEnd}
                handleUnselectItem={handleUnselectItem}
                lockItems={lockItems}
            />
        );
    };

    const getCenterX = (scale: number): number => {
        return (dimensions.width * scale) / 2;
    };

    const getCenterY = (scale: number): number => {
        return (dimensions.height * scale) / 2;
    };

    return (
        <div id="container" className="row g-0 h-100">
            <div className="col-md-2">
                {allDrawingCompareFileState?.loading === true ? (
                    <div className="d-flex flex-column align-items-center  bg-white h-100 border-end">
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#5648FB"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            visible={true}
                        />
                        <span className="text-muted">Loading list</span>
                    </div>
                ) : (
                    <ListFile
                        className="border bg-white p-3 overflow-scroll hidden-scroll"
                        style={{
                            height:
                                initialItem?.format === 'pdf'
                                    ? '100%'
                                    : 'calc(100vh - 56px)',
                        }}
                    >
                        {/* Search */}
                        <div className="mb-3">
                            <input
                                type="text"
                                className="input-search"
                                placeholder="Search"
                                onChange={handleSearch}
                            />
                        </div>
                        {itemList?.map((item, index: number) => (
                            <div
                                key={index}
                                className={`p-2 d-flex flex-column align-items-center item-file ${
                                    compareItem?.uuid === item?.uuid ? 'active-item' : ''
                                }`}
                                role="button"
                                onClick={() => {
                                    setCompareItem({
                                        ...item,
                                        offset: {
                                            x: getCenterX(0.4),
                                            y: getCenterY(0.4),
                                        },
                                        scale: { x: 0.4, y: 0.4 },
                                        opacity: 0.6,
                                    });
                                }}
                            >
                                <div
                                    className="p-2 d-flex align-items-center"
                                    style={{
                                        height: '100px',
                                    }}
                                >
                                    {item.format === 'pdf' ? (
                                        <AiOutlineFilePdf
                                            color="rgb(226, 87, 76)"
                                            size="30px"
                                        />
                                    ) : (
                                        <img
                                            src={item.file}
                                            alt="image"
                                            className="img-fluid"
                                            style={{
                                                objectFit: 'contain',
                                                height: '100%',
                                                width: '100%',
                                            }}
                                        />
                                    )}
                                </div>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={e => renderTooltip(e, item)}
                                >
                                    <div
                                        className=" mt-1"
                                        style={{
                                            fontSize: '10px',
                                        }}
                                    >
                                        <p className="mb-0 text-break">{item.name}</p>
                                    </div>
                                </OverlayTrigger>
                            </div>
                        ))}
                    </ListFile>
                )}
            </div>
            <div ref={divRef} className="col-md-10">
                {renderView()}
            </div>
        </div>
    );
}

const ListFile = styled.div`
    .item-file {
        border-radius: 10px;
        border: 1px solid #e5e5e5;
        margin-bottom: 8px;

        &:hover {
            background-color: #eeecff;
        }
    }

    .active-item {
        background-color: #eeecff;
    }

    .input-search {
        border: 1px solid #e5e5e5;
        border-radius: 8px;
        padding: 10px;
        width: 100%;
        font-size: 14px;

        &:focus {
            outline: none;
        }
    }
`;
