import { Dropdown } from 'react-bootstrap';

import { CustomDropdownMenu, FilterDropdown } from './styles';

export default function FilterView({
    children,
}: {
    children?: JSX.Element;
}): JSX.Element {
    return (
        <Dropdown
            className="d-inline"
            // drop="down-centered"
            autoClose="outside"
        >
            <FilterDropdown id="dropdown-autoclose-true" variant="success">
                Distance&nbsp;
                <span className="text-dark">within VIC</span>
            </FilterDropdown>

            <CustomDropdownMenu>{children}</CustomDropdownMenu>
        </Dropdown>
    );
}
