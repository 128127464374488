import { FiCheck, FiX } from 'react-icons/fi';
interface ListsProps {
    id: string;
    text: string;
    isValidate: boolean;
}

interface ParamsProps {
    lists: ListsProps[];
}

export default function PasswordValidation(props: ParamsProps): JSX.Element {
    const { lists } = props;

    return (
        <>
            {lists.map((list, index) => (
                <div key={index} className="text-start my-3">
                    <span
                        className={`${list.isValidate ? 'text-success' : 'text-muted'}`}
                    >
                        {list.isValidate ? (
                            <FiCheck className="me-1" />
                        ) : (
                            <FiX className="me-1" />
                        )}{' '}
                        {list.text}
                    </span>
                </div>
            ))}
        </>
    );
}
