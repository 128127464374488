export const CURRENT_PROFILE_REQUEST = 'CURRENT_PROFILE_REQUEST';
export const CURRENT_PROFILE_SUCCESS = 'CURRENT_PROFILE_SUCCESS';
export const CURRENT_PROFILE_FAIL = 'CURRENT_PROFILE_FAIL';

export const UPDATE_CURRENT_PROFILE_REQUEST = 'UPDATE_CURRENT_PROFILE_REQUEST';
export const UPDATE_CURRENT_PROFILE_SUCCESS = 'UPDATE_CURRENT_PROFILE_SUCCESS';
export const UPDATE_CURRENT_PROFILE_FAIL = 'UPDATE_CURRENT_PROFILE_FAIL';
export const UPDATE_CURRENT_PROFILE_RESET = 'UPDATE_CURRENT_PROFILE_RESET';

export const UPDATE_PROFILE_PICTURE_REQUEST = 'UPDATE_PROFILE_PICTURE_REQUEST';
export const UPDATE_PROFILE_PICTURE_SUCCESS = 'UPDATE_PROFILE_PICTURE_SUCCESS';
export const UPDATE_PROFILE_PICTURE_FAIL = 'UPDATE_PROFILE_PICTURE_FAIL';
export const UPDATE_PROFILE_PICTURE_RESET = 'UPDATE_PROFILE_PICTURE_RESET';

export const UPDATE_CURRENT_PASSWORD_REQUEST = 'UPDATE_CURRENT_PASSWORD_REQUEST';
export const UPDATE_CURRENT_PASSWORD_SUCCESS = 'UPDATE_CURRENT_PASSWORD_SUCCESS';
export const UPDATE_CURRENT_PASSWORD_FAIL = 'UPDATE_CURRENT_PASSWORD_FAIL';
export const UPDATE_CURRENT_PASSWORD_RESET = 'UPDATE_CURRENT_PASSWORD_RESET';

export const RESET_CURRENT_PASSWORD_PHONE_REQUEST =
    'RESET_CURRENT_PASSWORD_PHONE_REQUEST';
export const RESET_CURRENT_PASSWORD_PHONE_SUCCESS =
    'RESET_CURRENT_PASSWORD_PHONE_SUCCESS';
export const RESET_CURRENT_PASSWORD_PHONE_FAIL = 'RESET_CURRENT_PASSWORD_PHONE_FAIL';
export const RESET_CURRENT_PASSWORD_PHONE_RESET = 'RESET_CURRENT_PASSWORD_PHONE_RESET';

export const RESET_PASSWORD_EMAIL_REQUEST = 'RESET_PASSWORD_EMAIL_REQUEST';
export const RESET_PASSWORD_EMAIL_SUCCESS = 'RESET_PASSWORD_EMAIL_SUCCESS';
export const RESET_PASSWORD_EMAIL_FAIL = 'RESET_PASSWORD_EMAIL_FAIL';
export const RESET_PASSWORD_EMAIL_RESET = 'RESET_PASSWORD_EMAIL_RESET';

export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAIL = 'DELETE_ACCOUNT_FAIL';
export const DELETE_ACCOUNT_RESET = 'DELETE_ACCOUNT_RESET';

export const UNDO_DELETE_ACCOUNT_REQUEST = 'UNDO_DELETE_ACCOUNT_REQUEST';
export const UNDO_DELETE_ACCOUNT_SUCCESS = 'UNDO_DELETE_ACCOUNT_SUCCESS';
export const UNDO_DELETE_ACCOUNT_FAIL = 'UNDO_DELETE_ACCOUNT_FAIL';
export const UNDO_DELETE_ACCOUNT_RESET = 'UNDO_DELETE_ACCOUNT_RESET';

export const SEND_PHONE_VERIFICATION_REQUEST = 'SEND_PHONE_VERIFICATION_REQUEST';
export const SEND_PHONE_VERIFICATION_SUCCESS = 'SEND_PHONE_VERIFICATION_SUCCESS';
export const SEND_PHONE_VERIFICATION_FAIL = 'SEND_PHONE_VERIFICATION_FAIL';
export const SEND_PHONE_VERIFICATION_RESET = 'SEND_PHONE_VERIFICATION_RESET';

export const RESEND_PHONE_VERIFICATION_REQUEST = 'RESEND_PHONE_VERIFICATION_REQUEST';
export const RESEND_PHONE_VERIFICATION_SUCCESS = 'RESEND_PHONE_VERIFICATION_SUCCESS';
export const RESEND_PHONE_VERIFICATION_FAIL = 'RESEND_PHONE_VERIFICATION_FAIL';
export const RESEND_PHONE_VERIFICATION_RESET = 'RESEND_PHONE_VERIFICATION_RESET';

export const RESEND_OTP_VERIFICATION_REQUEST = 'RESEND_OTP_VERIFICATION_REQUEST';
export const RESEND_OTP_VERIFICATION_SUCCESS = 'RESEND_OTP_VERIFICATION_SUCCESS';
export const RESEND_OTP_VERIFICATION_FAIL = 'RESEND_OTP_VERIFICATION_FAIL';
export const RESEND_OTP_VERIFICATION_RESET = 'RESEND_OTP_VERIFICATION_RESET';

export const VERIFY_OTP_PROFILE_REQUEST = 'VERIFY_OTP_PROFILE_REQUEST';
export const VERIFY_OTP_PROFILE_SUCCESS = 'VERIFY_OTP_PROFILE_SUCCESS';
export const VERIFY_OTP_PROFILE_FAIL = 'VERIFY_OTP_PROFILE_FAIL';
export const VERIFY_OTP_PROFILE_RESET = 'VERIFY_OTP_PROFILE_RESET';

export const ACCEPT_OTP_PROFILE_REQUEST = 'ACCEPT_OTP_PROFILE_REQUEST';
export const ACCEPT_OTP_PROFILE_SUCCESS = 'ACCEPT_OTP_PROFILE_SUCCESS';
export const ACCEPT_OTP_PROFILE_FAIL = 'ACCEPT_OTP_PROFILE_FAIL';
export const ACCEPT_OTP_PROFILE_RESET = 'ACCEPT_OTP_PROFILE_RESET';

export const RESEND_EMAIL_VERIFICATION_REQUEST = 'RESEND_EMAIL_VERIFICATION_REQUEST';
export const RESEND_EMAIL_VERIFICATION_SUCCESS = 'RESEND_EMAIL_VERIFICATION_SUCCESS';
export const RESEND_EMAIL_VERIFICATION_FAIL = 'RESEND_EMAIL_VERIFICATION_FAIL';
export const RESEND_EMAIL_VERIFICATION_RESET = 'RESEND_EMAIL_VERIFICATION_RESET';

export const CLEAR_STATE = 'CLEAR_STATE';

const initialState = {
    loading: false,
    response: null,
    error: null,
    status: null,
};

export const currentProfileReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case CURRENT_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CURRENT_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case CURRENT_PROFILE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const updateCurrentProfileReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case UPDATE_CURRENT_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_CURRENT_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case UPDATE_CURRENT_PROFILE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case UPDATE_CURRENT_PROFILE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const updatePictureProfileReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case UPDATE_PROFILE_PICTURE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_PROFILE_PICTURE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case UPDATE_PROFILE_PICTURE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case UPDATE_PROFILE_PICTURE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const updateCurrentPasswordReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case UPDATE_CURRENT_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_CURRENT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case UPDATE_CURRENT_PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case UPDATE_CURRENT_PASSWORD_RESET:
            return initialState;

        default:
            return state;
    }
};

export const resetProfilePasswordEmailReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case RESET_PASSWORD_EMAIL_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case RESET_PASSWORD_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case RESET_PASSWORD_EMAIL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case RESET_PASSWORD_EMAIL_RESET:
            return initialState;

        default:
            return state;
    }
};

export const resetProfilePasswordPhoneReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case RESET_CURRENT_PASSWORD_PHONE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case RESET_CURRENT_PASSWORD_PHONE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case RESET_CURRENT_PASSWORD_PHONE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case RESET_CURRENT_PASSWORD_PHONE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const deleteAccountReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DELETE_ACCOUNT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_ACCOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DELETE_ACCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case DELETE_ACCOUNT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const undoDeleteAccountReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case UNDO_DELETE_ACCOUNT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UNDO_DELETE_ACCOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case UNDO_DELETE_ACCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case UNDO_DELETE_ACCOUNT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const sendPhoneVerificationReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case SEND_PHONE_VERIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case SEND_PHONE_VERIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case SEND_PHONE_VERIFICATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case SEND_PHONE_VERIFICATION_RESET:
            return initialState;

        default:
            return state;
    }
};

export const resendPhoneVerificationReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case RESEND_PHONE_VERIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case RESEND_PHONE_VERIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case RESEND_PHONE_VERIFICATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case RESEND_PHONE_VERIFICATION_RESET:
            return initialState;

        default:
            return state;
    }
};

export const resendOtpVerificationReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case RESEND_OTP_VERIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case RESEND_OTP_VERIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case RESEND_OTP_VERIFICATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case RESEND_OTP_VERIFICATION_RESET:
            return initialState;

        default:
            return state;
    }
};

export const verifyOtpProfileReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case VERIFY_OTP_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case VERIFY_OTP_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case VERIFY_OTP_PROFILE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case VERIFY_OTP_PROFILE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const acceptOtpProfileReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ACCEPT_OTP_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ACCEPT_OTP_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ACCEPT_OTP_PROFILE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ACCEPT_OTP_PROFILE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const resendEmailVerificationReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case RESEND_EMAIL_VERIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case RESEND_EMAIL_VERIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case RESEND_EMAIL_VERIFICATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case RESEND_EMAIL_VERIFICATION_RESET:
            return initialState;

        default:
            return state;
    }
};
