import { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import BadgeCheck from '@/assets/svg/icons/badge-check.svg';

import Header from '@/components/molecules/Header';
import TimerCountdown from '@/components/molecules/TimerCountdown';
import { Title } from '@/components/molecules/Typography';

import {
    clearRegisterState,
    resendRegisterEmail,
} from '@/routes/Authentication/Register/redux/actions';
import { useDispatch } from 'react-redux';

export default function EmailVerification(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const emailStorage = sessionStorage.getItem('email');

    /**
     * Function to handle redirect on confirm button
     *
     * @returns void
     */
    const handleChangeEmail = async (): Promise<void> => {
        // Remove email and phone from session storage,
        // if user go back to this page after from email sended page

        sessionStorage.clear();
        await dispatch(clearRegisterState());

        navigate('/register', {
            state: { nextStep: false },
        });
    };

    /**
     * Function for resend email
     *
     * @returns void
     */
    const resendEmail = (): void => {
        // initialize form data
        const formData = new FormData();

        // append data to form data
        formData.append('email', emailStorage ?? '');

        // call action dispatch
        dispatch(resendRegisterEmail(formData));
    };

    return (
        <Fragment>
            <div className="container-fluid container-xs container-lg">
                <header>
                    <Header text="Return to home screen" link="/" />
                </header>
                <main>
                    <div className="row my-5">
                        <div className="col-md-12 text-center my-5">
                            <Title text="Verification Sent" />
                            <img
                                src={BadgeCheck}
                                alt="badge-check"
                                className="img-fluid mt-5"
                            />
                        </div>
                        <div className="col-md-8 col-lg-6 col-xxl-4 mx-auto text-center mb-3">
                            <p className="text-muted">
                                We just sent the verification to your email&nbsp;
                                <b>&quot;{emailStorage}&quot;</b>. Please check to your
                                inbox or spam to continue the process
                            </p>
                            <TimerCountdown
                                minute={5}
                                second={0}
                                textCount="Resend email in"
                                textButton="Resend"
                                textDesc="Don't receive the verification mail?"
                                onClick={resendEmail}
                            />
                            <p className="mb-0">Or</p>
                            <Link
                                to="#"
                                onClick={handleChangeEmail}
                                className="text-decoration-none"
                            >
                                Change Email Address
                            </Link>
                        </div>
                    </div>
                </main>
            </div>
        </Fragment>
    );
}
