import chatImage1 from '@/assets/images/chat/help-chat-image-1.webp';
import chatImage4 from '@/assets/images/chat/help-chat-image-4.webp';
import chatImage5 from '@/assets/images/chat/help-chat-image-5.webp';

import { Fragment } from 'react';

export default function HowToCreateGroupChat(): JSX.Element {
    const contents = [
        {
            id: 1,
            text: 'You can chat with other user by clicking the chat button on the sidebar, and click new group message',
            alt: 'chatPage',
            image: chatImage1,
        },
        {
            id: 2,
            text: 'After that you can select the multiple user that you want to chat with, then click the create button',
            alt: 'chatPage',
            image: chatImage4,
        },
        {
            id: 3,
            text: 'You will be redirected to the group chat page, and you can start chat in group',
            alt: 'chatPage',
            image: chatImage5,
        },
    ];

    return (
        <Fragment>
            {contents.map((content, index) => (
                <div key={index} className="">
                    {content.image.length > 0 && (
                        <img
                            src={content.image}
                            alt={content.alt}
                            className="img-fluid"
                        />
                    )}
                    <p className="mt-4 pl-2">{content.text}</p>
                </div>
            ))}
        </Fragment>
    );
}
