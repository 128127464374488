import { Modal, OverlayTrigger, Placeholder, Tooltip } from 'react-bootstrap';

import styled from 'styled-components';

import { SearchModalProps } from '../../interfaces';

import BasicTooltip from '@/components/atoms/Tooltips';
import { generateInitialImageUrl } from '@/utility/Utils';
import uuid from 'react-uuid';
import { AiOutlineSearch } from 'react-icons/ai';

export default function SearchTask({
    data,
    modal,
    reducer,
    setModal,
    handleHighlightTask,
    handleSearchTask,
}: SearchModalProps): JSX.Element {
    return (
        <ModalWrapper
            show={modal.searchTask}
            onHide={() => setModal(prev => ({ ...prev, searchTask: false }))}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header
                closeButton
                style={{
                    padding: '1.5rem 2.3rem',
                }}
            >
                <h6 className="fw-semibold mb-0">Search Tasks</h6>
            </Modal.Header>
            <Modal.Body className="p-4">
                <SearchWrapper className="position-relative">
                    <AiOutlineSearch
                        size={20}
                        color="rgba(131, 131, 131, 1)"
                        className="position-absolute"
                        style={{
                            top: 10,
                            left: 10,
                        }}
                    />
                    <input
                        type="text"
                        className="form-control border-0 border-bottom ps-5"
                        placeholder="Search task"
                        onChange={e => handleSearchTask(e.target.value)}
                    />
                </SearchWrapper>
                <div className="mt-4">
                    {reducer?.loading === true ? (
                        Array.from(Array(10).keys()).map((_, index) => (
                            <div key={index} className="p-2">
                                <Placeholder animation="glow">
                                    <Placeholder xs={12} />
                                </Placeholder>
                            </div>
                        ))
                    ) : reducer?.response?.length > 0 ? (
                        reducer?.response?.map(
                            (item: {
                                id: number;
                                name: string;
                                assignees: Array<{
                                    user_name: string;
                                    user_avatar: string;
                                    user_email: string;
                                }>;
                                total_duration: number;
                                duration: number;
                            }) => (
                                <div
                                    key={uuid()}
                                    className="d-flex justify-content-between p-2 list align-items-center"
                                    role="button"
                                    onClick={() => handleHighlightTask(item.id)}
                                >
                                    {item.name.length > 50 ? (
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={props => (
                                                <Tooltip id="button-tooltip" {...props}>
                                                    {item.name}
                                                </Tooltip>
                                            )}
                                        >
                                            <span
                                                style={{
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    maxWidth: '70%',
                                                }}
                                            >
                                                {item.name}
                                            </span>
                                        </OverlayTrigger>
                                    ) : (
                                        <span>{item.name}</span>
                                    )}

                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            {item?.assignees
                                                ?.slice(0, 4)
                                                .map((assignee, key) => (
                                                    <BasicTooltip
                                                        key={uuid()}
                                                        text={
                                                            assignee.user_name ??
                                                            assignee?.user_email
                                                        }
                                                        // style={{
                                                        //     right:
                                                        //         key ===
                                                        //         item.assignees.length - 1
                                                        //             ? 0
                                                        //             : -10,
                                                        //     position: 'relative',
                                                        // }}
                                                    >
                                                        <img
                                                            src={
                                                                assignee?.user_avatar ??
                                                                generateInitialImageUrl(
                                                                    assignee?.user_name ??
                                                                        assignee?.user_email,
                                                                )
                                                            }
                                                            alt="assignee"
                                                            style={{
                                                                width: 30,
                                                                height: 30,
                                                            }}
                                                            className="rounded-circle img-fluid border border-white"
                                                        />
                                                    </BasicTooltip>
                                                ))}
                                            {item?.assignees?.length > 4 && (
                                                <BasicTooltip
                                                    placement="right"
                                                    text={item?.assignees
                                                        ?.slice(4)
                                                        .map(
                                                            (assignee: {
                                                                user_name: string;
                                                                user_email: string;
                                                            }) => (
                                                                <p
                                                                    key={
                                                                        assignee.user_email
                                                                    }
                                                                    className="my-1"
                                                                >
                                                                    {assignee?.user_name ??
                                                                        assignee?.user_email}
                                                                </p>
                                                            ),
                                                        )}
                                                >
                                                    <div
                                                        className="rounded-circle bg-primary text-white border border-white d-flex justify-content-center align-items-center"
                                                        style={{
                                                            width: 30,
                                                            height: 30,
                                                            fontSize: 12,
                                                        }}
                                                    >
                                                        <span className="">
                                                            +{item?.assignees?.length - 4}
                                                        </span>
                                                    </div>
                                                </BasicTooltip>
                                            )}
                                        </div>
                                        <span
                                            className="text-end"
                                            style={{
                                                minWidth: 70,
                                            }}
                                        >
                                            {item.duration}&nbsp;days
                                        </span>
                                    </div>
                                </div>
                            ),
                        )
                    ) : (
                        <div className="d-flex justify-content-center align-items-center py-5">
                            <span className="text-muted">Tasks not found</span>
                        </div>
                    )}
                </div>
            </Modal.Body>
        </ModalWrapper>
    );
}

const ModalWrapper = styled(Modal)`
    .list:hover {
        background-color: #dfdfdf;
        border-radius: 10px;
    }
`;

const SearchWrapper = styled.div`
    .form-control:focus {
        box-shadow: none;
    }
`;
