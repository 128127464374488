import { axiosClient } from '@/services/GatewayService';
import { AppDispatch } from '@/redux/store';

import {
    CURRENT_PROFILE_REQUEST,
    CURRENT_PROFILE_SUCCESS,
    CURRENT_PROFILE_FAIL,
    UPDATE_CURRENT_PROFILE_REQUEST,
    UPDATE_CURRENT_PROFILE_SUCCESS,
    UPDATE_CURRENT_PROFILE_FAIL,
    UPDATE_CURRENT_PROFILE_RESET,
    UPDATE_PROFILE_PICTURE_REQUEST,
    UPDATE_PROFILE_PICTURE_SUCCESS,
    UPDATE_PROFILE_PICTURE_FAIL,
    UPDATE_PROFILE_PICTURE_RESET,
    UPDATE_CURRENT_PASSWORD_REQUEST,
    UPDATE_CURRENT_PASSWORD_SUCCESS,
    UPDATE_CURRENT_PASSWORD_FAIL,
    UPDATE_CURRENT_PASSWORD_RESET,
    RESET_CURRENT_PASSWORD_PHONE_REQUEST,
    RESET_CURRENT_PASSWORD_PHONE_SUCCESS,
    RESET_CURRENT_PASSWORD_PHONE_FAIL,
    RESET_CURRENT_PASSWORD_PHONE_RESET,
    RESET_PASSWORD_EMAIL_REQUEST,
    RESET_PASSWORD_EMAIL_SUCCESS,
    RESET_PASSWORD_EMAIL_FAIL,
    RESET_PASSWORD_EMAIL_RESET,
    DELETE_ACCOUNT_REQUEST,
    DELETE_ACCOUNT_SUCCESS,
    DELETE_ACCOUNT_FAIL,
    DELETE_ACCOUNT_RESET,
    UNDO_DELETE_ACCOUNT_REQUEST,
    UNDO_DELETE_ACCOUNT_SUCCESS,
    UNDO_DELETE_ACCOUNT_FAIL,
    UNDO_DELETE_ACCOUNT_RESET,
    SEND_PHONE_VERIFICATION_REQUEST,
    SEND_PHONE_VERIFICATION_SUCCESS,
    SEND_PHONE_VERIFICATION_FAIL,
    SEND_PHONE_VERIFICATION_RESET,
    RESEND_PHONE_VERIFICATION_REQUEST,
    RESEND_PHONE_VERIFICATION_SUCCESS,
    RESEND_PHONE_VERIFICATION_FAIL,
    RESEND_PHONE_VERIFICATION_RESET,
    RESEND_OTP_VERIFICATION_REQUEST,
    RESEND_OTP_VERIFICATION_SUCCESS,
    RESEND_OTP_VERIFICATION_FAIL,
    RESEND_OTP_VERIFICATION_RESET,
    VERIFY_OTP_PROFILE_REQUEST,
    VERIFY_OTP_PROFILE_SUCCESS,
    VERIFY_OTP_PROFILE_FAIL,
    VERIFY_OTP_PROFILE_RESET,
    ACCEPT_OTP_PROFILE_REQUEST,
    ACCEPT_OTP_PROFILE_SUCCESS,
    ACCEPT_OTP_PROFILE_FAIL,
    ACCEPT_OTP_PROFILE_RESET,
    RESEND_EMAIL_VERIFICATION_REQUEST,
    RESEND_EMAIL_VERIFICATION_SUCCESS,
    RESEND_EMAIL_VERIFICATION_FAIL,
    RESEND_EMAIL_VERIFICATION_RESET,
    CLEAR_STATE,
} from '../reducers';

/**
 * Function to get of user setting
 *
 * @return Promise<void>
 */
export const getCurrentProfile = (params?: { force: number }): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CURRENT_PROFILE_REQUEST });
        try {
            const res = await axiosClient.get('user/profile', {
                params,
            });

            dispatch({
                type: CURRENT_PROFILE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: CURRENT_PROFILE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to update profile of user
 *
 * @param {FormData} formData
 * @return Promise<void>
 */
export const updateCurrentProfile = (formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_CURRENT_PROFILE_REQUEST });
        try {
            const res = await axiosClient.post('user/profile', formData);

            dispatch({
                type: UPDATE_CURRENT_PROFILE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UPDATE_CURRENT_PROFILE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to update profile of user
 *
 * @param {FormData} formData
 * @return Promise<void>
 */
export const updateProfilePicture = (formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_PROFILE_PICTURE_REQUEST });
        try {
            const res = await axiosClient.post('user/profile/avatar', formData);

            dispatch({
                type: UPDATE_PROFILE_PICTURE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UPDATE_PROFILE_PICTURE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to update profile of user
 *
 * @param {FormData} formData
 * @return Promise<void>
 */
export const updateCurrentPassword = (formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_CURRENT_PASSWORD_REQUEST });
        try {
            const res = await axiosClient.post('user/profile/password', formData);

            dispatch({
                type: UPDATE_CURRENT_PASSWORD_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UPDATE_CURRENT_PASSWORD_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to reset password by phone
 *
 * @return Promise<void>
 */
export const resetCurrentPasswordPhone = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESET_CURRENT_PASSWORD_PHONE_REQUEST });
        try {
            const res = await axiosClient.post('user/account/reset-password/phone');

            dispatch({
                type: RESET_CURRENT_PASSWORD_PHONE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: RESET_CURRENT_PASSWORD_PHONE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to reset password by email
 *
 * @param formData
 * @return Promise<void>
 */
export const resetCurrentPasswordEmail = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESET_PASSWORD_EMAIL_REQUEST });
        try {
            const res = await axiosClient.post('user/account/reset-password/email');

            dispatch({
                type: RESET_PASSWORD_EMAIL_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: RESET_PASSWORD_EMAIL_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to delete account
 *
 * @return Promise<void>
 */
export const deleteCurrentAccount = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_ACCOUNT_REQUEST });
        try {
            const res = await axiosClient.delete('user/account');

            dispatch({
                type: DELETE_ACCOUNT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DELETE_ACCOUNT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to delete account
 *
 * @param formData
 * @return Promise<void>
 */
export const undoDeleteCurrentUser = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UNDO_DELETE_ACCOUNT_REQUEST });
        try {
            const res = await axiosClient.put('user/account');

            dispatch({
                type: UNDO_DELETE_ACCOUNT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UNDO_DELETE_ACCOUNT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to send phone verification
 *
 * @return Promise<void>
 */
export const sendPhoneVerification = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SEND_PHONE_VERIFICATION_REQUEST });
        try {
            const res = await axiosClient.post('user/account/verify/phone');

            dispatch({
                type: SEND_PHONE_VERIFICATION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: SEND_PHONE_VERIFICATION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to resend phone verification
 *
 * @return Promise<void>
 */
export const resendPhoneVerification = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESEND_PHONE_VERIFICATION_REQUEST });
        try {
            const res = await axiosClient.post('user/account/verify/phone/resend');

            dispatch({
                type: RESEND_PHONE_VERIFICATION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: RESEND_PHONE_VERIFICATION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to resend otp verification
 *
 * @return Promise<void>
 */
export const resendOtpVerification = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESEND_OTP_VERIFICATION_REQUEST });
        try {
            const res = await axiosClient.put('user/account/verify/phone');

            dispatch({
                type: RESEND_OTP_VERIFICATION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: RESEND_OTP_VERIFICATION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to verify phone otp
 *
 * @return Promise<void>
 */
export const verifyPhoneOtp = (otp: number): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: VERIFY_OTP_PROFILE_REQUEST });
        try {
            const res = await axiosClient.get('user/account/verify/phone', {
                params: {
                    reset_otp: otp,
                },
            });

            dispatch({
                type: VERIFY_OTP_PROFILE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: VERIFY_OTP_PROFILE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to Accept phone otp
 *
 * @return Promise<void>
 */
export const acceptPhoneOtp = (otp: number): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ACCEPT_OTP_PROFILE_REQUEST });
        try {
            const res = await axiosClient.put('user/account/verify/phone', null, {
                params: {
                    verification_otp: otp,
                },
            });

            dispatch({
                type: ACCEPT_OTP_PROFILE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ACCEPT_OTP_PROFILE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to resend verify by email
 *
 * @return Promise<void>
 */
export const resendEmailVerification = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESEND_EMAIL_VERIFICATION_REQUEST });
        try {
            const res = await axiosClient.post('user/profile/email');

            dispatch({
                type: RESEND_EMAIL_VERIFICATION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: RESEND_EMAIL_VERIFICATION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to clear state
 *
 * @returns Promise<void>
 */
export const clearStateProfileSetting = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CLEAR_STATE });
    };
};

export const clearStateUpdateProfile = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_CURRENT_PROFILE_RESET });
    };
};

export const clearStateUpdatePictureProfile = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_PROFILE_PICTURE_RESET });
    };
};

export const clearStateUpdatePasswordProfile = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_CURRENT_PASSWORD_RESET });
    };
};

export const clearStateResetPasswordPhone = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESET_CURRENT_PASSWORD_PHONE_RESET });
    };
};

export const clearStateResetPasswordEmail = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESET_PASSWORD_EMAIL_RESET });
    };
};

export const clearStateDeleteAccount = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_ACCOUNT_RESET });
    };
};

export const clearStateUndoDeleteAccount = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UNDO_DELETE_ACCOUNT_RESET });
    };
};

export const clearStatePhone = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SEND_PHONE_VERIFICATION_RESET });
        dispatch({ type: RESEND_PHONE_VERIFICATION_RESET });
        dispatch({ type: RESEND_OTP_VERIFICATION_RESET });
        dispatch({ type: VERIFY_OTP_PROFILE_RESET });
        dispatch({ type: ACCEPT_OTP_PROFILE_RESET });
    };
};

export const clearStateEmailResend = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESEND_EMAIL_VERIFICATION_RESET });
    };
};
