import HowToCreateTask from './Sub/Schedule/HowToCreateTask';
import WhatIsSchedule from './Sub/Schedule/WhatIsSchedule';

import AccordionBasic from '@/components/molecules/AccordionBasic';

export default function HelpSchedule(): JSX.Element {
    const lists = [
        {
            id: 'whatIsSchedule',
            title: 'What is Schedule?',
            content: <WhatIsSchedule />,
        },
        {
            id: 'createTask',
            title: 'Create a task and assign to other user',
            content: <HowToCreateTask />,
        },
    ];

    return <AccordionBasic lists={lists} />;
}
