/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Offcanvas, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import CircleChatIcon from '@/assets/svg/icons/message-circle-plus.svg';
import PinIcon from '@/assets/svg/icons/pin.svg';

import {
    generateInitialImageUrl,
    getId,
    getInitialName,
    handleTypeFileByName,
} from '@/utility/Utils';

import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { RootState } from '@/redux/store';

interface ParamProps {
    show: boolean;
    setShow: any;
    handleClose: () => void;
}

export default function ChatList(params: ParamProps): JSX.Element {
    const { show, setShow, handleClose } = params;

    const navigate = useNavigate();

    const userId = getId();

    const [chatUserList, setChatUserList] = useState<any>([]);

    const currentProfileState = useSelector((state: RootState) => state.currentProfile);
    const chatListState = useSelector((state: RootState) => state.chatList);

    // Function to set chat list
    useEffect(() => {
        if (chatListState?.broadcast?.length > 0) {
            // filter where have pinned
            const pinned = chatListState?.broadcast?.filter((item: any) =>
                item?.pinned_by.find((item: any) => item.id === getId()),
            );

            const allData = chatListState?.broadcast;

            const mergeSort = [...pinned, ...allData];

            // delete duplicate data from merge sort
            const unique = mergeSort.filter(
                (v, i, a) => a.findIndex(t => t.id === v.id) === i,
            );

            // set chat list
            setChatUserList(unique);
        }
    }, [chatListState?.broadcast]);

    /**
     * Function to handle redirect
     *
     * @param id string
     * @returns void
     */
    const handleRedirect = (id: string): void => {
        // dispatch(clearBroadcastChatDetails());
        // dispatch(
        //     getChatDetailsById({ channel_id: parseInt(id), page: 1, per_page: 9999 }),
        // );

        navigate('/chat', {
            state: {
                id,
            },
        });

        setShow((prev: object) => ({
            ...prev,
            list: false,
        }));
    };

    /**
     * Function to handle change input
     *
     * @param e target value string
     * @returns void
     */
    const handleChange = (e: any): void => {
        const value = e.target.value;
        const list = chatListState?.broadcast;

        if (value !== '') {
            const filteredMember = list.filter((item: any) => {
                return item?.members.find((item: any) => {
                    return (
                        Boolean(
                            item?.name?.toLowerCase().includes(value.toLowerCase()),
                        ) || item?.email?.toLowerCase().includes(value.toLowerCase())
                    );
                });
            });

            const filterGroupName = list.filter((item: any) => {
                return Boolean(item?.name?.toLowerCase().includes(value.toLowerCase()));
            });

            const mergeFilter = [...filteredMember, ...filterGroupName];

            setChatUserList(mergeFilter);
        }

        if (value === '') {
            setChatUserList(chatListState?.broadcast);
        }
    };
    const List = (): JSX.Element => {
        const handleLastMessage = (message: string): string => {
            switch (handleTypeFileByName(message)) {
                case 'image':
                    return 'Sent an image';

                case 'word':
                case 'excel':
                case 'powerpoint':
                case 'pdf':
                    return 'Sent a document';

                case 'video':
                    return 'Sent a video';

                case 'archive':
                    return 'Sent a file';

                default:
                    return message;
            }
        };

        return (
            <div className="wrapper-list">
                {chatUserList.map?.((list: any) => (
                    <div
                        key={list.id}
                        className="chat-list d-flex  align-items-center"
                        onClick={() => handleRedirect(list.id)}
                    >
                        {list.message_channel_category_name === 'Direct Message' ? (
                            list.owner_id === userId ? (
                                <div className="d-flex ps-3">
                                    <ProfilePicture
                                        className="chat-profile"
                                        src={
                                            list?.participants[0]?.avatar ??
                                            generateInitialImageUrl(
                                                list?.participants[0]?.name,
                                            )
                                        }
                                        status={list?.participants[0]?.online}
                                    >
                                        <OnlineCircle
                                            status={list?.participants[0]?.online}
                                        />
                                    </ProfilePicture>
                                    <div className="d-flex flex-column justify-content-center ms-2">
                                        <NameList
                                            className="chat-name mb-1"
                                            status={list?.latest_message?.readed}
                                        >
                                            {list?.participants[0]?.name ??
                                                list?.unregistered_participants[0]?.email}
                                        </NameList>
                                        <MessageList
                                            className="chat-message mb-0"
                                            status={list?.latest_message?.readed}
                                        >
                                            {handleLastMessage(
                                                list?.latest_message?.data,
                                            )}
                                        </MessageList>
                                    </div>
                                </div>
                            ) : (
                                <div className="d-flex ps-3">
                                    <ProfilePicture
                                        className="chat-profile"
                                        src={
                                            list.owner_avatar ??
                                            generateInitialImageUrl(list.owner_name)
                                        }
                                        status={list?.owner_online}
                                    >
                                        <OnlineCircle status={list?.owner_online} />
                                    </ProfilePicture>
                                    <div className="d-flex flex-column justify-content-center ms-2">
                                        <NameList
                                            className="chat-name mb-1"
                                            status={list?.latest_message?.readed}
                                        >
                                            {list?.owner_name}
                                        </NameList>
                                        <MessageList
                                            className="chat-message mb-0"
                                            status={list?.latest_message?.readed}
                                        >
                                            {handleLastMessage(
                                                list?.latest_message?.data,
                                            )}
                                        </MessageList>
                                    </div>
                                </div>
                            )
                        ) : (
                            <div className="d-flex ps-3">
                                <GroupPicture className="chat-profile">
                                    <span className="initial-name">
                                        {getInitialName(list.name)}
                                    </span>
                                </GroupPicture>
                                <div className="d-flex flex-column justify-content-center ms-2">
                                    <NameList
                                        className="chat-name mb-1"
                                        status={list?.latest_message?.readed}
                                    >
                                        {list?.name}
                                    </NameList>
                                    <MessageList
                                        className="chat-message mb-0"
                                        status={list?.latest_message?.readed}
                                    >
                                        {handleLastMessage(list?.latest_message?.data)}
                                    </MessageList>
                                </div>
                            </div>
                        )}
                        <div className="d-flex justify-content-start">
                            <span
                                className="chat-count"
                                style={{
                                    opacity: list.unread_count > 0 ? 1 : 0,
                                }}
                            >
                                {list.unread_count}
                            </span>
                            {list?.pinned_by?.length > 0 &&
                                Boolean(
                                    list?.pinned_by.find(
                                        (item: any) => item.id === userId,
                                    ),
                                ) && <PinnedIcons src={PinIcon} />}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <MessageCanvas
            show={show}
            onHide={() =>
                setShow((prev: object) => ({
                    ...prev,
                    list: false,
                }))
            }
            placement="end"
            // backdrop={false}
            style={{
                borderBottomLeftRadius: '20px',
                borderTopLeftRadius: '20px',
                width: '350px',
            }}
        >
            <Offcanvas.Header className="chat-header pb-0 flex-column">
                <div className="w-100 d-flex align-items-center">
                    <div className="d-flex w-100">
                        <ProfilePicture
                            className="chat-profile"
                            src={
                                currentProfileState?.response?.avatar ??
                                generateInitialImageUrl(
                                    currentProfileState?.response?.name,
                                )
                            }
                            status={true}
                        >
                            <span className="online-circle" />
                        </ProfilePicture>
                        <div
                            className="d-flex flex-column justify-content-center"
                            style={{ marginLeft: 8 }}
                        >
                            <h6
                                className="chat-name mb-1"
                                style={{
                                    fontWeight: 600,
                                    fontSize: 16,
                                }}
                            >
                                {currentProfileState?.response?.name}
                            </h6>
                            <span className="chat-company">
                                {currentProfileState?.response?.company?.company_name}
                            </span>
                        </div>
                    </div>
                    <DropdownButton
                        as={ButtonGroup}
                        className="btn-new-chat"
                        title={
                            <img
                                alt="new-message"
                                className="icon-new-chat"
                                src={CircleChatIcon}
                            />
                        }
                    >
                        <Dropdown.Item
                            as={Link}
                            to="#"
                            onClick={() =>
                                setShow((prev: object) => ({
                                    ...prev,
                                    new: true,
                                }))
                            }
                        >
                            <span className="me-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.2}
                                    stroke="currentColor"
                                    height={25}
                                    width={25}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 011.037-.443 48.282 48.282 0 005.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                                    />
                                </svg>
                            </span>
                            <span>New Direct Message</span>
                        </Dropdown.Item>
                        <Dropdown.Item
                            as={Link}
                            to="#"
                            onClick={() =>
                                setShow((prev: object) => ({
                                    ...prev,
                                    group: true,
                                }))
                            }
                        >
                            <span className="me-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.2}
                                    stroke="currentColor"
                                    height={25}
                                    width={25}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                                    />
                                </svg>
                            </span>
                            <span>New Group Message</span>
                        </Dropdown.Item>
                    </DropdownButton>
                </div>
                <input
                    type="text"
                    placeholder="Search username or company..."
                    className="form-control search-chat mt-3"
                    onChange={event => handleChange(event)}
                />
                {/* <BiSearch
                            className="search-icon"
                            color="rgba(131, 131, 131, 1)"
                            size={20}
                        /> */}
            </Offcanvas.Header>
            <Offcanvas.Body className="chat-body px-0">
                <List />

                <div className="bottom-link text-center">
                    <Link to="/chat" onClick={() => handleClose()}>
                        Go to full page
                    </Link>
                </div>
            </Offcanvas.Body>
        </MessageCanvas>
    );
}

const OnlineCircle = styled.span<{ status: boolean }>`
    background-color: ${props => (props.status ? 'rgba(6, 189, 128, 1)' : '#cfcfcf')};
    width: 20px;
    height: 20px;
    border-radius: 50px;

    display: block;
    border: 3px solid #fff;
    position: relative;
    left: ${props => (props.status ? '2rem' : '2.2rem')};
    top: 1.7rem;
`;

const ProfilePicture = styled.div<{ src: string; status: boolean }>`
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-image: url('${props => props.src}');
    background-size: cover;
    background-position: center;
    border: ${props => (props.status ? '3px solid #5648fb' : 'none')};
`;

const NameList = styled.h6<{ status: number }>`
    font-size: 14px;
    font-weight: ${props => (props.status === 0 ? '500' : '600')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
`;

const MessageList = styled.p<{ status: number }>`
    font-size: 12px;
    color: ${props => (props.status === 0 ? 'rgba(131, 131, 131, 1)' : '#000')};
    font-weight: ${props => (props.status === 0 ? '500' : '600')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
`;

const MessageCanvas = styled(Offcanvas)`
    .btn-group button {
        background-color: #5648fb;
        border-radius: 50% !important;

        &:hover {
            border: 2px solid rgb(171, 163, 255);
        }
    }

    .chat-header {
        justify-content: space-around;

        .dropdown-item {
            font-size: 13px;
            padding: 0.5rem;

            &:hover {
                background-color: #edecfe;
                color: rgba(86, 72, 251, 1);
                border-radius: 5px;

                .dropdown-active {
                    background-color: rgba(86, 72, 251, 1);
                }
            }

            //active
            &:active {
                background-color: #edecfe;
                color: rgba(86, 72, 251, 1);
                border-radius: 5px;

                .dropdown-active {
                    background-color: rgba(86, 72, 251, 1);
                }
            }

            // focus
            &:focus {
                background-color: #edecfe;
                color: rgba(86, 72, 251, 1);
                border-radius: 5px;

                .dropdown-active {
                    background-color: rgba(86, 72, 251, 1);
                }
            }

            // focus visible
            &:focus-visible {
                box-shadow: none;
                border: none;
                outline: none;
            }
        }

        .dropdown-menu {
            border: 1px solid rgba(131, 131, 131, 0.2);
            padding: 0.5rem;
            border-radius: 10px !important;
        }
    }

    .chat-body {
        justify-content: space-around;
        /* overflow-y: unset; */
        /* padding-bottom: 5rem; */
    }

    .chat-job {
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 175px;
    }

    .chat-company {
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 175px;
        font-weight: 500;
    }

    .chat-count {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        background-color: #5648fb;
        color: #fff;
        font-size: 13px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .chat-list {
        border-bottom: 1px solid #e5e5e5;
        padding: 0.5rem 0;
        cursor: pointer;

        &:hover {
            background-color: #f5f5f5;
        }
    }

    .btn-new-chat,
    .dropdown-toggle {
        height: 35px;
        width: 35px;
        border-radius: 50px;
        padding: 0;

        /* After */
        &:after {
            display: none;
        }
    }

    .icon-new-chat {
        width: 23px;
        height: 23px;
        vertical-align: top;
    }

    .search-icon {
        position: absolute;
        top: 6.9rem;
        right: 1.7rem;
    }

    .online-circle {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        background-color: rgba(6, 189, 128, 1);
        position: absolute;
        left: 3.2rem;
        border: 3px solid #fff;
        margin-top: 30px;
    }

    .search-chat {
        height: 45px;
        border-radius: 50px;
        border: 1.132px solid rgba(131, 131, 131, 0.1);
        background-color: #ffff;

        &:focus {
            box-shadow: none;
        }

        &::placeholder {
            font-size: 13px;
            font-weight: 400;
        }

        &,
        .form-control {
            padding: 0 2.3rem 0 1rem;
        }
    }

    .bottom-link {
        font-size: 13px;
        font-weight: 500;
        margin-top: 1rem;
    }

    .wrapper-list {
        overflow-y: scroll;
        height: 90%;

        // hidden scrollbar
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    }
`;

const GroupPicture = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #5648fb;

    .initial-name {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
    }
`;
const PinnedIcons = styled.span<{ src: string }>`
    mask: url(${props => props.src}) no-repeat center;
    mask-size: 60%;
    -webkit-mask: url(${props => props.src}) no-repeat center;
    -webkit-mask-size: 60%;
    background-color: rgba(48, 48, 48, 1);
    padding: 13px;
    cursor: pointer;
`;
