import { ReactNode } from 'react';

import { Dropdown } from 'react-bootstrap';

import { CustomDropdownMenu, FilterDropdown, SelectOption } from './styles';

import { formatKebabCase } from '@/utility/Utils';

export default function SortView(props: {
    header: Array<{
        selector?: string;
        Cell?: (props: any, index: any) => ReactNode;
        title: string;
        withAction?: boolean;
        only?: (row: any) => any;
        actions?: Array<{
            text?: string;
            type?: string;
            link?: string;
            onClick?: (props: any) => void;
            variant?: string;
        }>;
    }>;
    handleFilter?: (value: string, type: number) => void;
}): JSX.Element {
    const { header, handleFilter } = props;

    return (
        <Dropdown className="d-inline ms-2" autoClose="outside">
            <FilterDropdown id="dropdown-autoclose-true" variant="success">
                Sort By
            </FilterDropdown>

            <CustomDropdownMenu>
                <div className="p-2">
                    <label htmlFor="By" className="label-filter">
                        By
                    </label>

                    <SelectOption
                        className="form-select"
                        onChange={e => {
                            handleFilter?.(e.target.value, 1);
                        }}
                    >
                        {header.map((item: any) => (
                            <option key={item.title} value={formatKebabCase(item.title)}>
                                {item.title}
                            </option>
                        ))}
                    </SelectOption>
                </div>
                <div className="p-2">
                    <label htmlFor="By" className="label-filter">
                        Type
                    </label>
                    <SelectOption
                        className="form-select"
                        onChange={e => {
                            handleFilter?.(e.target.value, 2);
                        }}
                    >
                        <option value={1}>Ascending (A-Z)</option>
                        <option value={0}>Descending (Z-A)</option>
                    </SelectOption>
                </div>
            </CustomDropdownMenu>
        </Dropdown>
    );
}
