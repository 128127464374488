import scheduleImage1 from '@/assets/images/schedule/help-schedule-image-1.webp';
import scheduleImage2 from '@/assets/images/schedule/help-schedule-image-2.webp';
import scheduleImage3 from '@/assets/images/schedule/help-schedule-image-3.webp';

import { Fragment } from 'react';

export default function HowToCreateTask(): JSX.Element {
    const contents = [
        {
            id: 1,
            text: 'You can create new task, by clicking the add task button, then the modal will appear.',
            alt: 'schedulePage',
            image: scheduleImage1,
        },
        {
            id: 2,
            text: 'After that you can fill the task form, you can add color to the task, assign the task to other user, and set the due date of the task.',
            alt: 'schedulePage',
            image: scheduleImage2,
        },
        {
            id: 3,
            text: 'You can also add assignee to the task, by clicking the add assignee button.',
            alt: 'schedulePage',
            image: scheduleImage3,
        },
    ];

    return (
        <Fragment>
            {contents.map((content, index) => (
                <div key={index} className="text-grey-300 mb-10">
                    {content.image.length > 0 && (
                        <img
                            src={content.image}
                            alt={content.alt}
                            className="img-fluid"
                        />
                    )}
                    <p className="mt-4 pl-2">{content.text}</p>
                </div>
            ))}
        </Fragment>
    );
}
