import { axiosClient } from '@/services/GatewayService';
import { AppDispatch } from '@/redux/store';

import {
    DETAIL_DOCUMENT_REQUEST,
    DETAIL_DOCUMENT_SUCCESS,
    DETAIL_DOCUMENT_FAIL,
    DETAIL_DOCUMENT_RESET,
    HISTORY_DOCUMENT_REQUEST,
    HISTORY_DOCUMENT_SUCCESS,
    HISTORY_DOCUMENT_FAIL,
    HISTORY_DOCUMENT_RESET,
    DELETE_DOCUMENT_REQUEST,
    DELETE_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_FAIL,
    DELETE_DOCUMENT_RESET,
    RESTORE_DOCUMENT_REQUEST,
    RESTORE_DOCUMENT_SUCCESS,
    RESTORE_DOCUMENT_FAIL,
    RESTORE_DOCUMENT_RESET,
    PIN_UNPIN_DOCUMENT_SPACE_REQUEST,
    PIN_UNPIN_DOCUMENT_SPACE_SUCCESS,
    PIN_UNPIN_DOCUMENT_SPACE_FAIL,
    PIN_UNPIN_DOCUMENT_SPACE_RESET,
    UPLOAD_DOCUMENT_FILE_REQUEST,
    UPLOAD_DOCUMENT_FILE_SUCCESS,
    UPLOAD_DOCUMENT_FILE_FAIL,
    UPLOAD_DOCUMENT_FILE_RESET,
    UPDATE_DOCUMENT_REQUEST,
    UPDATE_DOCUMENT_SUCCESS,
    UPDATE_DOCUMENT_FAIL,
    UPDATE_DOCUMENT_RESET,
    INVITE_USER_DOCUMENT_REQUEST,
    INVITE_USER_DOCUMENT_SUCCESS,
    INVITE_USER_DOCUMENT_FAIL,
    INVITE_USER_DOCUMENT_RESET,
    ALL_DOCUMENT_PUBLIC_SPACE_REQUEST,
    ALL_DOCUMENT_PUBLIC_SPACE_SUCCESS,
    ALL_DOCUMENT_PUBLIC_SPACE_FAIL,
    ALL_DOCUMENT_PRIVATE_SPACE_REQUEST,
    ALL_DOCUMENT_PRIVATE_SPACE_SUCCESS,
    ALL_DOCUMENT_PRIVATE_SPACE_FAIL,
    ALL_DOCUMENT_RESTORE_SPACE_REQUEST,
    ALL_DOCUMENT_RESTORE_SPACE_SUCCESS,
    ALL_DOCUMENT_RESTORE_SPACE_FAIL,
    ALL_DOCUMENT_PUBLIC_FOLDER_REQUEST,
    ALL_DOCUMENT_PUBLIC_FOLDER_SUCCESS,
    ALL_DOCUMENT_PUBLIC_FOLDER_FAIL,
    ALL_DOCUMENT_PUBLIC_FOLDER_RESET,
    ALL_DOCUMENT_PRIVATE_FOLDER_REQUEST,
    ALL_DOCUMENT_PRIVATE_FOLDER_SUCCESS,
    ALL_DOCUMENT_PRIVATE_FOLDER_FAIL,
    ALL_DOCUMENT_PRIVATE_FOLDER_RESET,
    CREATE_DOCUMENT_FOLDER_REQUEST,
    CREATE_DOCUMENT_FOLDER_SUCCESS,
    CREATE_DOCUMENT_FOLDER_FAIL,
    CREATE_DOCUMENT_FOLDER_RESET,
    ALL_DOCUMENT_COMPARE_FILE_REQUEST,
    ALL_DOCUMENT_COMPARE_FILE_SUCCESS,
    ALL_DOCUMENT_COMPARE_FILE_FAIL,
    ALL_DOCUMENT_COMPARE_FILE_RESET,
    SEND_DOCUMENT_REQUEST,
    SEND_DOCUMENT_SUCCESS,
    SEND_DOCUMENT_FAIL,
    SEND_DOCUMENT_RESET,
    SHARE_DOCUMENT_REQUEST,
    SHARE_DOCUMENT_SUCCESS,
    SHARE_DOCUMENT_FAIL,
    SHARE_DOCUMENT_RESET,
    PASTE_DOCUMENT_REQUEST,
    PASTE_DOCUMENT_SUCCESS,
    PASTE_DOCUMENT_FAIL,
    PASTE_DOCUMENT_RESET,
    SHARED_USER_PRIVATE_DOCUMENT_REQUEST,
    SHARED_USER_PRIVATE_DOCUMENT_SUCCESS,
    SHARED_USER_PRIVATE_DOCUMENT_FAIL,
    SHARED_USER_PRIVATE_DOCUMENT_RESET,
    UPDATE_USER_PRIVATE_DOCUMENT_REQUEST,
    UPDATE_USER_PRIVATE_DOCUMENT_SUCCESS,
    UPDATE_USER_PRIVATE_DOCUMENT_FAIL,
    UPDATE_USER_PRIVATE_DOCUMENT_RESET,
    DELETE_USER_PRIVATE_DOCUMENT_REQUEST,
    DELETE_USER_PRIVATE_DOCUMENT_SUCCESS,
    DELETE_USER_PRIVATE_DOCUMENT_FAIL,
    DELETE_USER_PRIVATE_DOCUMENT_RESET,
    CREATE_SHARE_LINK_DOCUMENT_REQUEST,
    CREATE_SHARE_LINK_DOCUMENT_SUCCESS,
    CREATE_SHARE_LINK_DOCUMENT_FAIL,
    CREATE_SHARE_LINK_DOCUMENT_RESET,
    GET_SHARE_LINK_DOCUMENT_REQUEST,
    GET_SHARE_LINK_DOCUMENT_SUCCESS,
    GET_SHARE_LINK_DOCUMENT_FAIL,
    GET_SHARE_LINK_DOCUMENT_RESET,
    GET_SHARE_LINK_SPACE_DOCUMENT_REQUEST,
    GET_SHARE_LINK_SPACE_DOCUMENT_SUCCESS,
    GET_SHARE_LINK_SPACE_DOCUMENT_FAIL,
    GET_SHARE_LINK_SPACE_DOCUMENT_RESET,
} from '../reducers';

/**
 * Function to get all document folders
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param number params.per_page - number of project per page
 * @param string params.search - search of project
 * @param string params.sort_by - sort by of project
 * @param number params.sort_asc - sort asc of project
 * @return Promise<void>
 */
export const getAllDocumentPublicSpace = (params: {
    project_id: string;
    page: number;
    per_page: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_DOCUMENT_PUBLIC_SPACE_REQUEST });
        try {
            const res = await axiosClient.get('project/documents/folders', {
                params: {
                    ...params,
                    public: 1,
                },
            });

            dispatch({
                type: ALL_DOCUMENT_PUBLIC_SPACE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_DOCUMENT_PUBLIC_SPACE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all document folders
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param number params.per_page - number of project per page
 * @param string params.search - search of project
 * @param string params.sort_by - sort by of project
 * @param number params.sort_asc - sort asc of project
 * @return Promise<void>
 */
export const getAllDocumentPrivateSpace = (params: {
    project_id: string;
    page: number;
    per_page: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_DOCUMENT_PRIVATE_SPACE_REQUEST });
        try {
            const res = await axiosClient.get('project/documents/folders', {
                params: {
                    ...params,
                    public: 0,
                },
            });

            dispatch({
                type: ALL_DOCUMENT_PRIVATE_SPACE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_DOCUMENT_PRIVATE_SPACE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all document folders
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param number params.per_page - number of project per page
 * @param string params.search - search of project
 * @param string params.sort_by - sort by of project
 * @param number params.sort_asc - sort asc of project
 * @return Promise<void>
 */
export const getAllDocumentRestoreSpace = (params: {
    project_id: string;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
    public: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_DOCUMENT_RESTORE_SPACE_REQUEST });
        try {
            const res = await axiosClient.get('project/documents/folders', {
                params: {
                    ...params,
                    is_restore: 1,
                },
            });

            dispatch({
                type: ALL_DOCUMENT_RESTORE_SPACE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_DOCUMENT_RESTORE_SPACE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get inside space
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param number params.per_page - number of project per page
 * @param string params.search - search of project
 * @param string params.sort_by - sort by of project
 * @param number params.sort_asc - sort asc of project
 * @return Promise<void>
 */
export const getAllDocumentPublicFolder = (
    params: {
        project_id: string;
        id?: number;
        uuid?: string;
        folder_id?: string;
        folder_uuid?: string;
        page: number;
        per_page: number;
        search?: string;
        sort_by?: string;
        sort_asc?: number;
    },
    is_trash: boolean,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_DOCUMENT_PUBLIC_FOLDER_REQUEST });
        try {
            const res = await axiosClient.get(
                `project/documents${is_trash ? '/trash' : ''}`,
                {
                    params: {
                        ...params,
                        public: 1,
                    },
                },
            );

            dispatch({
                type: ALL_DOCUMENT_PUBLIC_FOLDER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_DOCUMENT_PUBLIC_FOLDER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get inside space
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param number params.per_page - number of project per page
 * @param string params.search - search of project
 * @param string params.sort_by - sort by of project
 * @param number params.sort_asc - sort asc of project
 * @return Promise<void>
 */
export const getAllDocumentPrivateFolder = (
    params: {
        project_id: string;
        id?: number;
        uuid?: string;
        folder_id?: string;
        folder_uuid?: string;
        page: number;
        per_page: number;
        search?: string;
        sort_by?: string;
        sort_asc?: number;
    },
    is_trash: boolean,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_DOCUMENT_PRIVATE_FOLDER_REQUEST });
        try {
            const res = await axiosClient.get(
                `project/documents${is_trash ? '/trash' : ''}`,
                {
                    params: {
                        ...params,
                        public: 0,
                    },
                },
            );

            dispatch({
                type: ALL_DOCUMENT_PRIVATE_FOLDER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_DOCUMENT_PRIVATE_FOLDER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get inside space
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param number params.per_page - number of project per page
 * @param string params.search - search of project
 * @param string params.sort_by - sort by of project
 * @param number params.sort_asc - sort asc of project
 * @return Promise<void>
 */
export const searchDocumentAll = (
    id: string,
    params: {
        page: number;
        per_page: number;
        search: string;
        sort_by?: string;
        sort_asc?: number;
    },
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({
            type: GET_SHARE_LINK_DOCUMENT_REQUEST,
        });
        try {
            const res = await axiosClient.get(`documents/sharelink/search/${id}`, {
                params,
            });

            dispatch({
                type: GET_SHARE_LINK_DOCUMENT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: GET_SHARE_LINK_DOCUMENT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get inside folder
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param number params.per_page - number of project per page
 * @param string params.search - search of project
 * @param string params.sort_by - sort by of project
 * @param number params.sort_asc - sort asc of project
 * @return Promise<void>
 * TODO:DEPRECATED
 */
export const getDocumentSubFolderInsidePublicFolder = (params: {
    project_id: string;
    // folder_id: string;
    document_id: string;
    page: number;
    per_page: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
    public: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({
            type:
                params.public === 0
                    ? ALL_DOCUMENT_PRIVATE_FOLDER_REQUEST
                    : ALL_DOCUMENT_PUBLIC_FOLDER_REQUEST,
        });
        try {
            const res = await axiosClient.get('project/documents', {
                params,
            });

            dispatch({
                type:
                    params.public === 0
                        ? ALL_DOCUMENT_PRIVATE_FOLDER_SUCCESS
                        : ALL_DOCUMENT_PUBLIC_FOLDER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type:
                    params.public === 0
                        ? ALL_DOCUMENT_PRIVATE_FOLDER_FAIL
                        : ALL_DOCUMENT_PUBLIC_FOLDER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get detail document folders
 *
 * @param string params.id - id of document folders
 * @param string params.project_id - id of project
 * @return Promise<void>
 */
export const detailDocument = (id: string, shareId: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_DOCUMENT_REQUEST });
        try {
            const res = await axiosClient.get(`documents/sharelink/detail/${shareId}`, {
                params: {
                    id,
                },
            });

            dispatch({
                type: DETAIL_DOCUMENT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DETAIL_DOCUMENT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get history document folders
 *
 * @param string params.id - id of document folders
 * @param string params.project_id - id of project
 * @return Promise<void>
 */
export const historyDocument = (id: string, shareId: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: HISTORY_DOCUMENT_REQUEST });
        try {
            const res = await axiosClient.get(
                `documents/sharelink/histories/${shareId}`,
                {
                    params: {
                        id,
                    },
                },
            );

            dispatch({
                type: HISTORY_DOCUMENT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: HISTORY_DOCUMENT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to create document space
 *
 * @param string params.project_id - id of project
 * @param FormData formData- body data of document folders
 * @return Promise<void>
 */
export const createDocumentFolder = (
    params: {
        project_id: string;
        folder_id: string;
    },
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CREATE_DOCUMENT_FOLDER_REQUEST });
        try {
            const res = await axiosClient.post('project/documents/subfolders', formData, {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: CREATE_DOCUMENT_FOLDER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: CREATE_DOCUMENT_FOLDER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to delete document folders
 *
 * @param string params.ids - ids of document folders
 * @param string params.project_id - id of project
 * @param FormData formData- body data of document folders
 * @return Promise<void>
 */
export const deleteDocument = (
    params: { project_id: string; permanently: number },
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_DOCUMENT_REQUEST });

        try {
            const res = await axiosClient.post('project/documents/delete', formData, {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: DELETE_DOCUMENT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DELETE_DOCUMENT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

export const restoreDocument = (
    params: { project_id: string; folder_id?: string },
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESTORE_DOCUMENT_REQUEST });
        const sessionRestoreFolderId = sessionStorage.getItem('restoreFolderId');

        try {
            const res = await axiosClient.post('project/documents/restore', formData, {
                params: {
                    ...params,
                    folder_id: sessionRestoreFolderId,
                },
            });

            dispatch({
                type: RESTORE_DOCUMENT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: RESTORE_DOCUMENT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get detail document folders
 *
 * @param string params.id - id of document folders
 * @param string params.project_id - id of project
 * @return Promise<void>
 */
export const getSharedUserPrivateDocument = (params: {
    document_id?: string;
    project_id?: string;
    // page: number;
    // per_page: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SHARED_USER_PRIVATE_DOCUMENT_REQUEST });
        try {
            const res = await axiosClient.get('project/documents/assignees', {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: SHARED_USER_PRIVATE_DOCUMENT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: SHARED_USER_PRIVATE_DOCUMENT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get detail document folders
 *
 * @param string params.id - id of document folders
 * @param string params.project_id - id of project
 * @return Promise<void>
 */
export const updateSharedUserPrivateDocument = (
    params: {
        document_id?: string;
        project_id?: string;
    },
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_USER_PRIVATE_DOCUMENT_REQUEST });
        try {
            const res = await axiosClient.post(
                'project/documents/assignees/permissions',
                formData,
                {
                    params: {
                        ...params,
                    },
                },
            );

            dispatch({
                type: UPDATE_USER_PRIVATE_DOCUMENT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UPDATE_USER_PRIVATE_DOCUMENT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get detail document folders
 *
 * @param string params.id - id of document folders
 * @param string params.project_id - id of project
 * @return Promise<void>
 */
export const deleteSharedUserPrivateDocument = (
    params: {
        document_id?: string;
        project_id?: string;
    },
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_USER_PRIVATE_DOCUMENT_REQUEST });
        try {
            const res = await axiosClient.post(
                'project/documents/assignees/delete',
                formData,
                {
                    params: {
                        ...params,
                    },
                },
            );

            dispatch({
                type: DELETE_USER_PRIVATE_DOCUMENT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DELETE_USER_PRIVATE_DOCUMENT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to pin document folders
 *
 * @param string params.ids - ids of document folders
 * @param string params.project_id - id of project
 * @return Promise<void>
 */
export const pinUnpinDocumentSpace = (
    params: { id?: string; uuid?: string; project_id?: string },
    isPin: boolean,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: PIN_UNPIN_DOCUMENT_SPACE_REQUEST });
        try {
            const res = await axiosClient.put(
                `project/documents/folders/${isPin ? 'pin' : 'unpin'}`,
                null,
                {
                    params: {
                        ...params,
                    },
                },
            );

            dispatch({
                type: PIN_UNPIN_DOCUMENT_SPACE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: PIN_UNPIN_DOCUMENT_SPACE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to upload document file
 *
 * @param string params.project_id - id of project
 * @param FormData formData- body data of document folders
 * @return Promise<void>
 */
export const uploadDocumentFile = (
    params: {
        project_id: string;
        folder_id?: string;
    },
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPLOAD_DOCUMENT_FILE_REQUEST });
        try {
            const res = await axiosClient.post('project/documents', formData, {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: UPLOAD_DOCUMENT_FILE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UPLOAD_DOCUMENT_FILE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to send document file to email
 *
 * @param string params.project_id - id of project
 * @param FormData formData- body data of document folders
 * @return Promise<void>
 */
export const sendDocumentToEmail = (
    params: {
        project_id: string;
    },
    data: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SEND_DOCUMENT_REQUEST });
        try {
            const res = await axiosClient.post('project/documents/send', data, {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: SEND_DOCUMENT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: SEND_DOCUMENT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

export const shareDocumentToEmail = (
    params: {
        project_id: string;
    },
    data: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SHARE_DOCUMENT_REQUEST });
        try {
            const res = await axiosClient.post('project/documents/share', data, {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: SHARE_DOCUMENT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: SHARE_DOCUMENT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to upload document file
 *
 * @param string params.project_id - id of project
 * @param FormData formData- body data of document folders
 * @return Promise<void>
 */
export const updateDocument = (
    params: {
        id: string;
        project_id?: string;
    },
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_DOCUMENT_REQUEST });
        try {
            const res = await axiosClient.post('project/documents/meta', formData, {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: UPDATE_DOCUMENT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UPDATE_DOCUMENT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to share folder
 *
 * @param string params.ids - ids of document folders
 * @param string params.project_id - id of project
 * @param FormData formData- body data of document folders
 * @return Promise<void>
 */
export const inviteUserDocument = (
    params: {
        project_id: string;
    },
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: INVITE_USER_DOCUMENT_REQUEST });
        try {
            const res = await axiosClient.post('project/documents/invite', formData, {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: INVITE_USER_DOCUMENT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: INVITE_USER_DOCUMENT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all document compare file
 *
 * @param params
 * @returns
 */
export const getAllDocumentCompareFile = (params: {
    project_id: string;
    format?: string;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_DOCUMENT_COMPARE_FILE_REQUEST });
        try {
            const res = await axiosClient.get('project/documents/files', {
                params,
            });

            dispatch({
                type: ALL_DOCUMENT_COMPARE_FILE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_DOCUMENT_COMPARE_FILE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to paste document
 *
 * @param params
 * @param type
 * @param formData
 * @returns
 */
export const pasteDocument = (
    params: { project_id: string; folder_id: string },
    type: string,
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: PASTE_DOCUMENT_REQUEST });
        try {
            const res = await axiosClient.post(`project/documents/${type}`, formData, {
                params,
            });

            dispatch({
                type: PASTE_DOCUMENT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: PASTE_DOCUMENT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to create share link document
 *
 * @param params
 * @param type
 * @param formData
 * @returns
 */
export const createShareLinkDocument = (formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CREATE_SHARE_LINK_DOCUMENT_REQUEST });
        try {
            const res = await axiosClient.post(
                'project/documents/sharelink/create',
                formData,
            );

            dispatch({
                type: CREATE_SHARE_LINK_DOCUMENT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: CREATE_SHARE_LINK_DOCUMENT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get share link document
 *
 * @param params
 * @param type
 * @param formData
 * @returns
 */
export const getShareLinkDocument = (
    id: string,
    params: {
        page: number;
        per_page: number;
        search?: string;
        sort_by?: string;
        sort_asc?: number;
        folder_id?: string;
    },
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: GET_SHARE_LINK_DOCUMENT_REQUEST });
        try {
            const res = await axiosClient.get(`documents/sharelink/${id}`, {
                params,
            });

            dispatch({
                type: GET_SHARE_LINK_DOCUMENT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: GET_SHARE_LINK_DOCUMENT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get share link drawing
 *
 * @param params
 * @param type
 * @param formData
 * @returns
 */
export const getShareLinkSpaceDocument = (
    id: string,
    params: {
        page: number;
        per_page: number;
        search?: string;
        sort_by?: string;
        sort_asc?: number;
    },
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: GET_SHARE_LINK_SPACE_DOCUMENT_REQUEST });
        try {
            const res = await axiosClient.get(`documents/sharelink/folders/${id}`, {
                params,
            });

            dispatch({
                type: GET_SHARE_LINK_SPACE_DOCUMENT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: GET_SHARE_LINK_SPACE_DOCUMENT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

export const clearAllDocumentFolder = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_DOCUMENT_PUBLIC_FOLDER_RESET });
        dispatch({ type: ALL_DOCUMENT_PRIVATE_FOLDER_RESET });
    };
};

export const clearCreateDocumentSpace = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CREATE_DOCUMENT_FOLDER_RESET });
    };
};

export const clearDetailDocument = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_DOCUMENT_RESET });
    };
};

export const clearUpdateDocument = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_DOCUMENT_RESET });
    };
};

export const clearUploadDocumentFile = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPLOAD_DOCUMENT_FILE_RESET });
    };
};

export const clearPinUnpinDocumentSpace = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: PIN_UNPIN_DOCUMENT_SPACE_RESET });
    };
};

export const clearDeleteDocument = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_DOCUMENT_RESET });
    };
};

export const clearAllDocumentCompareFile = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_DOCUMENT_COMPARE_FILE_RESET });
    };
};

export const clearSendDocumentToEmail = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SEND_DOCUMENT_RESET });
    };
};

export const clearShareDocumentToEmail = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SHARE_DOCUMENT_RESET });
    };
};

export const clearRestoreDocument = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESTORE_DOCUMENT_RESET });
    };
};

export const clearInviteUserDocument = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: INVITE_USER_DOCUMENT_RESET });
    };
};

export const clearPasteDocument = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: PASTE_DOCUMENT_RESET });
    };
};

export const clearHistoryDocument = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: HISTORY_DOCUMENT_RESET });
    };
};

export const clearSharedUserPrivateDocument = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SHARED_USER_PRIVATE_DOCUMENT_RESET });
    };
};

export const clearUpdateSharedUserPrivateDocument = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_USER_PRIVATE_DOCUMENT_RESET });
    };
};

export const clearDeleteSharedUserPrivateDocument = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_USER_PRIVATE_DOCUMENT_RESET });
    };
};

export const clearCreateShareLinkDocument = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CREATE_SHARE_LINK_DOCUMENT_RESET });
    };
};

export const clearGetShareLinkDocument = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: GET_SHARE_LINK_DOCUMENT_RESET });
    };
};
