import authImage14 from '@/assets/images/auth/help-auth-image-13.webp';
import authImage15 from '@/assets/images/auth/help-auth-image-14.webp';
import authImage16 from '@/assets/images/auth/help-auth-image-16.webp';
import authImage17 from '@/assets/images/auth/help-auth-image-17.webp';
import authImage18 from '@/assets/images/auth/help-auth-image-18.webp';
import authImage19 from '@/assets/images/auth/help-auth-image-19.webp';
import authImage20 from '@/assets/images/auth/help-auth-image-20.webp';
import authImage21 from '@/assets/images/auth/help-auth-image-21.webp';

import { Fragment } from 'react';

export default function HowToResetPassword(): JSX.Element {
    const contents = [
        {
            id: 1,
            text: 'You can click the forgot password button then the page will move to the display as below.',
            alt: 'loginPage',
            image: authImage14,
        },
        {
            id: 2,
            text: 'There are 2 methods that can be used to reset the password, namely by email or telephone number.',
            alt: 'loginPage',
            image: authImage15,
        },
        {
            id: 3,
            text: 'If you choose email, you will be asked to fill in your email address registered in the construction application.',
            alt: 'loginPage',
            image: authImage21,
        },
        {
            id: 4,
            text: 'After you input your email address, the system will send a verification to the email address you previously entered, and you are asked to check your email inbox, and click the verify button then the page will change as shown below.',
            alt: 'loginPage',
            image: authImage16,
        },
        {
            id: 5,
            text: 'If you choose phone number, you will be asked to fill in your phone number registered in the construction application.',
            alt: 'loginPage',
            image: authImage17,
        },
        {
            id: 6,
            text: 'After you input your phone number, the system will send a OTP (One Time Password) to the phone number you previously entered, and you are asked to check your phone inbox, and input the OTP (One Time Password) then the page will change as shown below.',
            alt: 'loginPage',
            image: authImage18,
        },
        {
            id: 7,
            text: 'After you verify, you will be redirected back into the application and you are asked to enter your new password, make sure your new password meets the requirements required by the application so you can continue with the process.',
            alt: 'loginPage',
            image: authImage19,
        },
        {
            id: 8,
            text: 'After successfully entering the new password and the process is complete, you will be redirected to the login page to re-login with your new password.',
            alt: 'loginPage',
            image: authImage20,
        },
    ];

    return (
        <Fragment>
            {contents.map((content, index) => (
                <div key={index} className="text-grey-300 mb-10">
                    <img src={content.image} alt={content.alt} className="img-fluid" />
                    <p className="mt-4 pl-2">{content.text}</p>
                </div>
            ))}
        </Fragment>
    );
}
