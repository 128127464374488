import SubscriptionList from '../components/SubscriptionList';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function SubscriptionSetting(): JSX.Element {
    const currentProfileState = useSelector((state: RootState) => state.currentProfile);
    const navigate = useNavigate();

    // Check if user is company user or not to access this page
    useEffect(() => {
        if (currentProfileState?.response?.role?.name !== 'Company User') {
            navigate('/');
        }
    }, [currentProfileState?.response?.role?.name, navigate]);

    return <SubscriptionList />;
}
