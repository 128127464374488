export const ALL_PENDING_INVITATION_REQUEST = 'ALL_PENDING_INVITATION_REQUEST';
export const ALL_PENDING_INVITATION_SUCCESS = 'ALL_PENDING_INVITATION_SUCCESS';
export const ALL_PENDING_INVITATION_FAIL = 'ALL_PENDING_INVITATION_FAIL';

export const CURRENT_PROFILE_PROJECT_REQUEST = 'CURRENT_PROFILE_PROJECT_REQUEST';
export const CURRENT_PROFILE_PROJECT_SUCCESS = 'CURRENT_PROFILE_PROJECT_SUCCESS';
export const CURRENT_PROFILE_PROJECT_FAIL = 'CURRENT_PROFILE_PROJECT_FAIL';

export const KICK_MEMBER_REQUEST = 'KICK_MEMBER_REQUEST';
export const KICK_MEMBER_SUCCESS = 'KICK_MEMBER_SUCCESS';
export const KICK_MEMBER_FAIL = 'KICK_MEMBER_FAIL';
export const KICK_MEMBER_RESET = 'KICK_MEMBER_RESET';

export const ALL_NICHE_TRADE_REQUEST = 'ALL_NICHE_TRADE_REQUEST';
export const ALL_NICHE_TRADE_SUCCESS = 'ALL_NICHE_TRADE_SUCCESS';
export const ALL_NICHE_TRADE_FAIL = 'ALL_NICHE_TRADE_FAIL';

export const DETAIL_NICHE_TRADE_REQUEST = 'DETAIL_NICHE_TRADE_REQUEST';
export const DETAIL_NICHE_TRADE_SUCCESS = 'DETAIL_NICHE_TRADE_SUCCESS';
export const DETAIL_NICHE_TRADE_FAIL = 'DETAIL_NICHE_TRADE_FAIL';

export const ADD_NICHE_TRADE_REQUEST = 'ADD_NICHE_TRADE_REQUEST';
export const ADD_NICHE_TRADE_SUCCESS = 'ADD_NICHE_TRADE_SUCCESS';
export const ADD_NICHE_TRADE_FAIL = 'ADD_NICHE_TRADE_FAIL';
// export const ADD_NICHE_TRADE_RESET = 'ADD_NICHE_TRADE_RESET';

export const UPDATE_NICHE_TRADE_REQUEST = 'UPDATE_NICHE_TRADE_REQUEST';
export const UPDATE_NICHE_TRADE_SUCCESS = 'UPDATE_NICHE_TRADE_SUCCESS';
export const UPDATE_NICHE_TRADE_FAIL = 'UPDATE_NICHE_TRADE_FAIL';
// export const UPDATE_NICHE_TRADE_RESET = 'UPDATE_NICHE_TRADE_RESET';

export const DELETE_NICHE_TRADE_REQUEST = 'DELETE_NICHE_TRADE_REQUEST';
export const DELETE_NICHE_TRADE_SUCCESS = 'DELETE_NICHE_TRADE_SUCCESS';
export const DELETE_NICHE_TRADE_FAIL = 'DELETE_NICHE_TRADE_FAIL';

export const AWARDING_MEMBER_REQUEST = 'AWARDING_MEMBER_REQUEST';
export const AWARDING_MEMBER_SUCCESS = 'AWARDING_MEMBER_SUCCESS';
export const AWARDING_MEMBER_FAIL = 'AWARDING_MEMBER_FAIL';

export const NULLIFY_MEMBER_REQUEST = 'NULLIFY_MEMBER_REQUEST';
export const NULLIFY_MEMBER_SUCCESS = 'NULLIFY_MEMBER_SUCCESS';
export const NULLIFY_MEMBER_FAIL = 'NULLIFY_MEMBER_FAIL';

export const UPDATE_MEMBER_PERMISSION_REQUEST = 'UPDATE_MEMBER_PERMISSION_REQUEST';
export const UPDATE_MEMBER_PERMISSION_SUCCESS = 'UPDATE_MEMBER_PERMISSION_SUCCESS';
export const UPDATE_MEMBER_PERMISSION_FAIL = 'UPDATE_MEMBER_PERMISSION_FAIL';
export const UPDATE_MEMBER_PERMISSION_RESET = 'UPDATE_MEMBER_PERMISSION_RESET';

export const CLEAR_AWARDING_NULLIFY_MEMBER = 'CLEAR_AWARDING_NULLIFY_MEMBER';

export const CLEAR_ACTION_NICHE_TRADE = 'CLEAR_ACTION_NICHE_TRADE';

export const CLEAR_STATE = 'CLEAR_STATE';

const initialState = {
    loading: false,
    response: null,
    error: null,
    status: null,
};

export const allPendingInvitationReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_PENDING_INVITATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_PENDING_INVITATION_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_PENDING_INVITATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const currentProfileProjectReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case CURRENT_PROFILE_PROJECT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CURRENT_PROFILE_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case CURRENT_PROFILE_PROJECT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const kickMemberProjectReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case KICK_MEMBER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case KICK_MEMBER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case KICK_MEMBER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case KICK_MEMBER_RESET:
            return initialState;

        default:
            return state;
    }
};

export const allNicheTradeReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_NICHE_TRADE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_NICHE_TRADE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_NICHE_TRADE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const detailNicheTradeReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DETAIL_NICHE_TRADE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_NICHE_TRADE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DETAIL_NICHE_TRADE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const actionNicheTradeReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ADD_NICHE_TRADE_REQUEST:
        case UPDATE_NICHE_TRADE_REQUEST:
        case DELETE_NICHE_TRADE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ADD_NICHE_TRADE_SUCCESS:
        case UPDATE_NICHE_TRADE_SUCCESS:
        case DELETE_NICHE_TRADE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ADD_NICHE_TRADE_FAIL:
        case UPDATE_NICHE_TRADE_FAIL:
        case DELETE_NICHE_TRADE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_ACTION_NICHE_TRADE:
            return initialState;

        default:
            return state;
    }
};

export const awardingNullifyMemberReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case AWARDING_MEMBER_REQUEST:
        case NULLIFY_MEMBER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case AWARDING_MEMBER_SUCCESS:
        case NULLIFY_MEMBER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case AWARDING_MEMBER_FAIL:
        case NULLIFY_MEMBER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_AWARDING_NULLIFY_MEMBER:
            return initialState;

        default:
            return state;
    }
};

export const updateMemberPermissionReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case UPDATE_MEMBER_PERMISSION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_MEMBER_PERMISSION_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case UPDATE_MEMBER_PERMISSION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case UPDATE_MEMBER_PERMISSION_RESET:
            return initialState;

        default:
            return state;
    }
};
