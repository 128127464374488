import { Placeholder } from 'react-bootstrap';

export default function SkeletonSpace(): JSX.Element {
    return (
        <div className={''}>
            {Array.from({ length: 10 }).map((_, idx) => (
                <Placeholder
                    key={idx}
                    animation="glow"
                    xs={12}
                    style={{ padding: '0.5rem 0.8rem' }}
                >
                    <Placeholder
                        xs={10}
                        size="lg"
                        style={{
                            borderRadius: '10px',
                            marginBottom: '1rem',
                        }}
                    />
                </Placeholder>
            ))}
        </div>
    );
}
