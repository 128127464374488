import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, OverlayTrigger, Placeholder, Spinner, Tooltip } from 'react-bootstrap';

import Card from '@/components/molecules/Card';
import { Title } from '@/components/molecules/Typography';
import PillBadge from '@/components/atoms/Badges/BadgePill';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import TableBasic from '@/components/organism/TableBasic';
import InputBasic from '@/components/atoms/Inputs/InputBasic';

import amexLogo from '@/assets/svg/card/amex.svg';
import discoverLogo from '@/assets/svg/card/discover.svg';
import generalLogo from '@/assets/svg/card/generic.svg';
import mcLogo from '@/assets/svg/card/mastercard.svg';
import visaLogo from '@/assets/svg/card/visa.svg';
import NoCardIcon from '@/assets/svg/icons/credit-card-plus.svg';
import TagIcon from '@/assets/svg/icons/tag.svg';

import { AiOutlineInfoCircle } from 'react-icons/ai';
import { FiRefreshCw } from 'react-icons/fi';

import styled from 'styled-components';

import { capitalizeFirstLetter, formatDate } from '@/utility/Utils';

import {
    getAllInvoice,
    getPaymentMethod,
    applyCoupon,
    checkActiveCoupon,
    clearStateApplyCoupon,
    clearStateCheckCoupon,
} from '../redux/actions';
import { useForm } from 'react-hook-form';
import { getCurrentProfile } from '../../PersonalSetting/redux/actions';
import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';
import { RootState } from '@/redux/store';
import BasicTooltip from '@/components/atoms/Tooltips';

export default function SubscriptionList(): JSX.Element {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentProfileState = useSelector((state: RootState) => state.currentProfile);
    const detailPaymentMethodState = useSelector(
        (state: RootState) => state.detailPaymentMethod,
    );
    const checkCouponState = useSelector((state: RootState) => state.checkCoupon);
    const applyCouponState = useSelector((state: RootState) => state.applyCoupon);

    const allInvoiceState = useSelector((state: RootState) => state.allInvoice);

    const detailSubscription = currentProfileState?.response?.subscription;
    const detailPayment = detailPaymentMethodState?.response;
    const allInvoiceList = allInvoiceState.response;

    const [searchValue, setSearchValue] = useState('');
    const [params, setParams] = useState({
        page: 1,
        per_page: 10,
        search: '',
        sort_by: 'name',
        sort_asc: 1,
    });

    const [modal, setModal] = useState({
        coupon: false,
        success: false,
    });

    // Get Payment Method
    useEffect(() => {
        if (
            detailSubscription?.has_payment_method !== false &&
            detailSubscription?.has_payment_method !== undefined
        ) {
            dispatch(getPaymentMethod());
        }
    }, [detailSubscription?.has_payment_method]);

    useEffect(() => {
        if (applyCouponState?.status === 200) {
            setModal(prev => ({
                ...prev,
                coupon: false,
            }));

            setTimeout(() => {
                setModal(prev => ({
                    ...prev,
                    success: true,
                }));
            }, 500);

            dispatch(getCurrentProfile());
        }
    }, [applyCouponState]);

    useEffect(() => {
        if (!modal.coupon) {
            dispatch(clearStateApplyCoupon());
            dispatch(clearStateCheckCoupon());
            reset();
        }
    }, [modal.coupon]);
    /**
     * Function to handle search
     *
     * @param string value
     * @returns void
     */
    const handleSearch = useCallback((value: string): void => {
        setSearchValue(value.toUpperCase());
    }, []);

    /**
     * Function to handle debounce search
     *
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleDebouncedSearch = useCallback(() => {
        setParams({
            ...params,
            search: searchValue,
            page: 1,
        });

        const payload = {
            ...params,
            search: searchValue,
            page: 1,
        };

        dispatch(getAllInvoice(payload));
    }, [searchValue]);

    /**
     * Function to handle pagination
     *
     * @param number page
     * @param string type
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handlePagination = (page: number, type: string): void => {
        if (type === 'page') {
            setParams({
                ...params,
                page,
            });

            const payload = {
                ...params,
                page,
            };

            dispatch(getAllInvoice(payload));
        }

        if (type === 'next') {
            setParams({
                ...params,
                page: params.page + 1,
            });

            const payload = {
                ...params,
                page: params.page + 1,
            };

            dispatch(getAllInvoice(payload));
        }

        if (type === 'prev') {
            setParams({
                ...params,
                page: params.page - 1,
            });

            const payload = {
                ...params,
                page: params.page - 1,
            };

            dispatch(getAllInvoice(payload));
        }
    };

    /**
     * Function to handle card image logo
     *
     * @param string type
     * @returns string
     */
    const handleCard = (type: string): string => {
        switch (type) {
            case 'visa':
                return visaLogo;
            case 'mastercard':
                return mcLogo;
            case 'amex':
                return amexLogo;
            case 'discover':
                return discoverLogo;
            default:
                return generalLogo;
        }
    };

    const tableItems = [
        {
            title: 'Start Date',
            Cell: (row: any) => <span>{formatDate(row?.period_start_at)}</span>,
        },
        {
            title: 'End Date',
            Cell: (row: any) => <span>{formatDate(row?.period_end_at)}</span>,
        },

        {
            title: 'Price',
            selector: 'amount_due',
        },
        {
            title: 'Invoice',
            Cell: (row: { invoice_number: string; invoice_pdf: string }) => (
                <Link to="#" onClick={() => window.open(row?.invoice_pdf, '_blank')}>
                    {row?.invoice_number}
                </Link>
            ),
        },
        {
            title: 'Payment Status',
            Cell: (row: any) => (
                <PillBadge
                    id="inactive"
                    variant={handleStatus(row?.payment_status)}
                    text={capitalizeFirstLetter(row?.payment_status)}
                    className="px-3 py-2"
                />
            ),
        },
    ];

    /**
     * Function to handle status
     *
     * @param string status
     * @returns
     */
    const handleStatus = (status: string): string => {
        switch (status) {
            case 'paid':
                return 'success';
            case 'failed':
                return 'danger';
            default:
                return 'danger';
        }
    };

    // Function for handle debounce search
    useEffect(() => {
        const debounce = setTimeout(() => {
            handleDebouncedSearch();
        }, 500);

        return () => {
            clearTimeout(debounce);
        };
    }, [handleDebouncedSearch]);

    /**
     * Function on submit
     *
     * @param any data
     * @return void
     * @see cypress/e2e/authentication/login/login.cy.ts
     *      To cypress unit tester
     */
    const onSubmit = (data: any, type: number): void => {
        // initialize form data
        const payload = new FormData();

        // append data to form data
        for (const key in data) {
            payload.append(key, data[key as keyof typeof data]);
        }

        // Dispatch login action with form data as payload
        type !== 0
            ? dispatch(applyCoupon(payload))
            : dispatch(checkActiveCoupon(data.code));
    };

    const handleCloseModalSuccess = (): void => {
        setModal(prev => ({
            ...prev,
            success: false,
            coupon: false,
        }));
    };

    return (
        <>
            <Title text="Subscription and Billing" />

            <div className="row mb-2">
                <div className="col-md-12 mt-5 mb-3">
                    <Title text="Active Subscription" />
                </div>
                <div className="col-md-6 mb-3">
                    <Card
                        classOuter="p-2"
                        style={{
                            minHeight: 165,
                        }}
                    >
                        <div className="row mb-2">
                            <div className="col-md-8 mb-2">
                                <span className="card-title fw-semibold me-2">
                                    {detailSubscription?.subscription_plan?.name}
                                </span>
                                {detailSubscription?.trial !== false && (
                                    <PillBadge
                                        variant="grey"
                                        text="Trial"
                                        className="me-2"
                                    />
                                )}

                                {detailSubscription?.active !== false ? (
                                    <PillBadge
                                        id="active"
                                        variant="success"
                                        text="Active"
                                    />
                                ) : (
                                    <PillBadge
                                        id="inactive"
                                        variant="danger"
                                        text="Inactive"
                                    />
                                )}
                            </div>
                            <div className="col-md-4 mb-2 text-md-end">
                                {detailSubscription?.has_payment_method === true && (
                                    <ButtonBasic
                                        text="Renew"
                                        onClick={() =>
                                            navigate('/setting/subscription/renew')
                                        }
                                        withTooltip="Renewing your subscription at trial period will not end the trial"
                                    />
                                )}
                            </div>
                            <div className="col-md-12">
                                {detailSubscription !== undefined ? (
                                    <>
                                        <TextList className="mb-1">
                                            {detailSubscription?.current_inhouse_user_amount ??
                                                0}
                                            &nbsp;Inhouse User
                                        </TextList>
                                        <TextList className="mb-1">
                                            Plan ended at&nbsp;
                                            {formatDate(
                                                detailSubscription
                                                    ?.subscription_billing_cycle
                                                    ?.current_period?.end_at,
                                                'DD MMMM YYYY',
                                            ) ?? '-'}
                                        </TextList>
                                        <TextList className="mb-1">
                                            Next payment at&nbsp;
                                            {formatDate(
                                                detailSubscription
                                                    ?.subscription_billing_cycle
                                                    ?.current_period?.end_at,
                                                'DD MMMM YYYY',
                                            ) ?? '-'}
                                            &nbsp;
                                        </TextList>

                                        {detailSubscription?.has_payment_due_warning !==
                                            false && (
                                            <p
                                                className="text-warning mb-0"
                                                style={{ fontSize: 12 }}
                                            >
                                                Your payment will be due soon
                                            </p>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <Placeholder bg="secondary" xs={2} />
                                        <br />
                                        <Placeholder bg="secondary" xs={4} />
                                        <br />
                                        <Placeholder bg="secondary" xs={6} />
                                    </>
                                )}
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="col-md-6 mb-3">
                    <Card
                        classOuter="p-2"
                        style={{
                            minHeight: 165,
                        }}
                    >
                        {detailSubscription?.has_payment_method !== true ? (
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="d-flex flex-column align-items-center justify-content-center mt-4">
                                        <img
                                            src={NoCardIcon}
                                            alt="card"
                                            style={{
                                                height: 40,
                                                width: 40,
                                            }}
                                        />
                                        <span className="text-muted ">No card set</span>
                                    </div>
                                </div>
                                <div className="col-md-6 text-md-end">
                                    <ButtonBasic
                                        id="addCard"
                                        text="Add Card"
                                        className="btn-outline-primary"
                                        onClick={() =>
                                            navigate('/setting/subscription/change-card')
                                        }
                                    />
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="row mb-2">
                                    <div className="col-md-6 mb-2">
                                        <span className="card-title fw-semibold me-2">
                                            {detailPayment?.card?.brand.toUpperCase()}
                                        </span>
                                        <img
                                            src={handleCard(detailPayment?.card?.brand)}
                                            alt="MasterCard"
                                            style={{
                                                width: 30,
                                                height: 30,
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-2 text-md-end">
                                        <ButtonBasic
                                            id="addCard"
                                            text="Change Card"
                                            className="btn-outline-primary"
                                            onClick={() =>
                                                navigate(
                                                    '/setting/subscription/change-card',
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <TextList className="mb-1">
                                        Card Number&nbsp;
                                        <b>
                                            **** **** ****{' '}
                                            {detailPayment?.card?.last4 ?? '****'}
                                        </b>
                                    </TextList>
                                    <TextList className="mb-1">
                                        Exp
                                        <b>
                                            &nbsp;
                                            {detailPayment?.card?.exp_month ?? '**'}/
                                            {detailPayment?.card?.exp_year ?? '**'}
                                        </b>
                                    </TextList>
                                    <TextList className="mb-1">
                                        CVV <b>***</b>
                                    </TextList>
                                </div>
                            </>
                        )}
                    </Card>
                </div>
                <div className="col-md-12">
                    <Card classOuter="p-1">
                        <WrapperCard className="row">
                            {detailSubscription !== undefined ? (
                                <div className="col-md-4 p-3">
                                    <p
                                        className="text-muted"
                                        style={{
                                            fontSize: 12,
                                        }}
                                    >
                                        Next Billing Estimation Amount
                                    </p>
                                    {detailSubscription?.predicted_billable_amount !==
                                    detailSubscription?.predicted_discounted_billable_amount ? (
                                        <div className="d-flex">
                                            <p className="text-decoration-line-through text-muted">
                                                {
                                                    detailSubscription?.predicted_billable_amount
                                                }
                                            </p>
                                            <p className="mb-0 fw-semibold ms-3">
                                                {
                                                    detailSubscription?.predicted_discounted_billable_amount
                                                }
                                            </p>
                                        </div>
                                    ) : (
                                        <p className="mb-0 fw-semibold">
                                            {
                                                detailSubscription?.predicted_billable_amount
                                            }
                                        </p>
                                    )}
                                </div>
                            ) : (
                                <div className="col-md-4 p-3">
                                    <p
                                        className="text-muted"
                                        style={{
                                            fontSize: 12,
                                        }}
                                    >
                                        Next Billing Estimation Amount
                                    </p>
                                    <Placeholder bg="secondary" xs="6" />
                                </div>
                            )}
                            <div className="col-md-4 p-3 border-vertical">
                                <p
                                    className="text-muted"
                                    style={{
                                        fontSize: 12,
                                    }}
                                >
                                    Next Periods{' '}
                                    <b>
                                        (
                                        {detailSubscription?.subscription_billing_cycle
                                            ?.type ?? '-'}
                                        )
                                    </b>
                                </p>

                                {detailSubscription !== undefined ? (
                                    <p className="mb-0 fw-semibold">
                                        {formatDate(
                                            detailSubscription?.subscription_billing_cycle
                                                ?.next_period?.start_at,
                                            'DD MMMM YYYY',
                                        )}
                                        &nbsp;-&nbsp;
                                        {formatDate(
                                            detailSubscription?.subscription_billing_cycle
                                                ?.next_period?.end_at,
                                            'DD MMMM YYYY',
                                        )}
                                    </p>
                                ) : (
                                    <Placeholder bg="secondary" xs="6" />
                                )}
                            </div>
                            {detailSubscription?.subscription_billing_cycle
                                ?.next_billing_cycle_coupon !== null ? (
                                <div className="col-md-4 p-3">
                                    <p
                                        className="mb-3"
                                        style={{
                                            fontSize: 12,
                                        }}
                                    >
                                        Coupon Code
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={
                                                <Tooltip id="top-tooltip-2">
                                                    Coupon will be applied on next billing
                                                </Tooltip>
                                            }
                                        >
                                            {({ ref, ...triggerHandler }) => (
                                                <>
                                                    <span ref={ref} className="ms-1">
                                                        <AiOutlineInfoCircle
                                                            size={16}
                                                            {...triggerHandler}
                                                        />
                                                    </span>
                                                </>
                                            )}
                                        </OverlayTrigger>
                                    </p>
                                    {detailSubscription !== undefined ? (
                                        <>
                                            <span className="mb-0 text-primary fw-semibold">
                                                {
                                                    detailSubscription
                                                        ?.subscription_billing_cycle
                                                        ?.next_billing_cycle_coupon?.name
                                                }
                                                &nbsp; - &nbsp;
                                                {
                                                    detailSubscription
                                                        ?.subscription_billing_cycle
                                                        ?.next_billing_cycle_coupon
                                                        ?.percent_off
                                                }
                                                %
                                            </span>
                                            &nbsp;
                                            <span
                                                className="mb-0"
                                                style={{ fontSize: 12 }}
                                            >
                                                (
                                                {
                                                    detailSubscription
                                                        ?.subscription_billing_cycle
                                                        ?.next_billing_cycle_coupon?.code
                                                }
                                                )
                                            </span>
                                        </>
                                    ) : (
                                        <Placeholder bg="secondary" xs={6} />
                                    )}
                                </div>
                            ) : (
                                <div
                                    className="col-md-4 p-3 text-md-center"
                                    role={
                                        detailSubscription?.has_payment_method === true
                                            ? 'button'
                                            : ''
                                    }
                                    onClick={() =>
                                        detailSubscription?.has_payment_method === true &&
                                        setModal(prev => ({ ...prev, coupon: true }))
                                    }
                                >
                                    <img
                                        src={TagIcon}
                                        alt=""
                                        style={{
                                            height: 40,
                                            width: 40,
                                        }}
                                        className="mb-2"
                                    />
                                    <div
                                        className="text-muted mb-0 d-flex align-items-center justify-content-center"
                                        style={{
                                            fontSize: 12,
                                        }}
                                    >
                                        {detailSubscription?.has_payment_method ===
                                        true ? (
                                            <div>
                                                <span className="me-1">
                                                    + Add coupon code
                                                </span>
                                                <BasicTooltip text="Coupon will be applied on next billing">
                                                    <AiOutlineInfoCircle size={16} />
                                                </BasicTooltip>
                                            </div>
                                        ) : (
                                            <div>
                                                <span className="me-1">
                                                    For adding coupon code, please add
                                                    card first
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </WrapperCard>
                    </Card>
                </div>
            </div>

            <div className="row mb-5 g-0">
                <div className="col-md-12 mt-5 mb-3">
                    <Title text="Subscription History" />
                </div>
                <div className="col-lg-12 px-0">
                    <TableBasic
                        header={tableItems as any}
                        items={allInvoiceList ?? []}
                        moduleType="History Plan"
                        handleSearch={handleSearch}
                        handlePagination={handlePagination}
                        loading={allInvoiceState?.loading}
                        withoutCheckbox
                    />
                </div>
            </div>

            <ModalWrapper
                show={modal.coupon}
                onHide={() => setModal(prev => ({ ...prev, coupon: false }))}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="px-4">
                    <h6 className="fw-semibold mb-0">Add Coupon</h6>
                </Modal.Header>
                <form>
                    <Modal.Body className="mb-4 p-4 text-start">
                        <div className="box text-start p-3 mb-3">
                            <p className="mb-0 text-primary">
                                Coupon will be applied on next billing
                            </p>
                        </div>
                        <InputBasic
                            type="text"
                            placeholder="Coupon code"
                            rules={{
                                function: register,
                                name: 'code',
                                rules: {
                                    required: 'Coupon code is required',
                                },
                                errors,
                            }}
                        />
                        <div className="d-flex justify-content-between">
                            {checkCouponState?.loading !== false ? (
                                <Spinner animation="border" size="sm" variant="primary" />
                            ) : (
                                <Link
                                    to="#"
                                    className="text-decoration-none"
                                    style={{ fontSize: 14 }}
                                    onClick={handleSubmit(data => onSubmit(data, 0))}
                                >
                                    <FiRefreshCw className="me-1" />
                                    Check coupon code
                                </Link>
                            )}
                            {checkCouponState?.status === 200 ? (
                                <span className="text-success" style={{ fontSize: 14 }}>
                                    {checkCouponState?.response?.message}
                                </span>
                            ) : (
                                <span className="text-danger" style={{ fontSize: 14 }}>
                                    {checkCouponState?.error}
                                </span>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonBasic
                            text="Apply Coupon"
                            className="btn btn-primary btn-sm"
                            onClick={handleSubmit(data => onSubmit(data, 1))}
                            loading={applyCouponState?.loading}
                        />
                    </Modal.Footer>
                </form>
            </ModalWrapper>
            <ModalDashboard
                modal={modal.success}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body="Successfully applied coupon code"
                onConfirm={() => handleCloseModalSuccess()}
            />
        </>
    );
}

const TextList = styled.p`
    margin-bottom: 1rem;
    font-size: 14px;
`;

const ModalWrapper = styled(Modal)`
    .modal-header {
        border: none;
        border-bottom: 1px solid #e9ecef;

        .btn-close {
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 10px;
        }
    }

    .modal-body {
        .box {
            background-color: #bbb6fd;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 500;
        }

        .form-floating {
            font-size: 14px;
        }

        .label-active {
            padding: 1.5rem 0.5rem;
        }
        /* .form-control {
            color: #8e8e8e;
        } */

        input {
            padding: 0 0 0px 4px !important;
        }
    }

    .modal-footer {
        justify-content: center;
        border: none;
        flex-wrap: initial;
    }
`;

const WrapperCard = styled.div`
    .border-vertical {
        border-left: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
    }

    @media (max-width: 767px) {
        text-align: center;
        .border-vertical {
            border-left: none;
            border-right: none;
            border-top: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
        }
    }
`;
