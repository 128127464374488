import InputBasic from '@/components/atoms/Inputs/InputBasic';

import { InformationTaskProps } from '../../interfaces';

import InputArea from '@/components/atoms/Inputs/InputArea';
import SubTaskComment from './SubTaskComment';
import SubTaskList from './SubTaskList';
import InputColor from '@/components/atoms/Inputs/InputColor';

export default function InformationTask({
    subtask,
    register,
    errors,
    control,
    watch,
    selectedTask,
    commentData,
    defaultColorLists,
    handleSendComment,
    handleEditComment,
    handleDeleteComment,
    setModal,
    handleSelectSubtask,
    handleAddSubtask,
    handleDetailTask,
    permissions,
    handleDragDropUpdateState,
}: InformationTaskProps): JSX.Element {
    return (
        <div className="">
            {subtask?.id !== null && (
                <InputBasic
                    id="taskName"
                    type="text"
                    placeholder="Task Parent"
                    rules={{
                        function: register,
                        name: 'parent_task_name',
                        rules: {},
                        errors,
                    }}
                    disabled
                />
            )}
            <InputBasic
                id="subtaskName"
                type="text"
                placeholder={subtask.id !== null ? 'Sub Task Name' : 'Task Name'}
                rules={{
                    function: register,
                    name: 'name',
                    rules: {
                        required: `Please enter ${
                            subtask !== null ? 'subtask' : 'task'
                        } name`,
                        maxLength: {
                            value: 255,
                            message: 'Maximum 255 characters',
                        },
                    },
                    errors,
                }}
                disabled={!permissions.collaborate || selectedTask?.deleted_at !== null}
            />
            <InputArea
                id="taskDescription"
                placeholder={
                    subtask.id !== null ? 'Subtask Description' : 'Task Description'
                }
                defaultHeight={115}
                rules={{
                    function: register,
                    name: 'description',
                    rules: {
                        maxLength: {
                            value: 2000,
                            message: 'Maximum length of 2000 characters',
                        },
                    },
                    errors,
                }}
                disabled={!permissions.collaborate || selectedTask?.deleted_at !== null}
            />

            <InputColor
                rules={{
                    function: register,
                    name: 'color',
                    rules: {
                        required: 'Please select color',
                    },
                    errors,
                    control,
                }}
                value={watch('color') as string}
            />

            <hr />

            <SubTaskList
                selectedTask={selectedTask}
                setModal={setModal}
                handleSelectSubtask={handleSelectSubtask}
                handleAddSubtask={handleAddSubtask}
                permissions={permissions}
                handleDetailTask={handleDetailTask}
                handleDragDropUpdateState={handleDragDropUpdateState}
            />

            <hr />

            <SubTaskComment
                selectedTask={selectedTask}
                data={commentData}
                handleSendComment={handleSendComment}
                handleEditComment={handleEditComment}
                handleDeleteComment={handleDeleteComment}
            />
        </div>
    );
}
