import { useEffect, useState } from 'react';

import InputBasic from '@/components/atoms/Inputs/InputBasic';

import styled from 'styled-components';

import { AdditionalInformationProps } from '../../interfaces';

import InputDateRange from '@/components/atoms/Inputs/InputDateRange';
import InputSearchSelect from '@/components/atoms/Inputs/InputSearchSelect';
import BasicTooltip from '@/components/atoms/Tooltips';
import { generateInitialImageUrl } from '@/utility/Utils';
import { Controller } from 'react-hook-form';
import { AiOutlinePlus } from 'react-icons/ai';
import { RootState } from '@/redux/store';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import InputDateRangeHeaderNew from '@/components/atoms/Inputs/InputDateRangeHeaderNew';

export default function AdditionalInformation({
    register,
    errors,
    control,
    assignees,
    nicheLists,
    dateRange,
    selectedTask,
    setDateRange,
    setModal,
    setNicheLists,
    setSelectedTask,
    statusLists,
    handleLabel,
    permissions,
    handleOpenModalRemoveAssignee,
    isLoadingAssignee,
}: AdditionalInformationProps): JSX.Element {
    const allNicheTradeState = useSelector((state: RootState) => state.allNicheTrade);

    const [hoverDelete, setHoverDelete] = useState({
        id: 0,
        status: false,
    });

    // get niche and set to to state
    useEffect(() => {
        if (allNicheTradeState?.status === 200) {
            const niche = allNicheTradeState?.response?.data;

            const nicheLists = niche.map((item: { id: number; name: string }) => {
                return {
                    value: item.id,
                    label: item.name,
                };
            });

            setNicheLists(nicheLists);
        }
    }, [allNicheTradeState]);

    /**
     * Function for handle change on date range
     *
     * @param dateRange [Date | null, Date | null]
     * @returns void
     */
    const handleChangeDateRange = (dateRange: [Date | null, Date | null]): void => {
        setDateRange(dateRange);
        // setMaxMinDate(dateRange);
    };

    return (
        <div className="p-4">
            <div className="form-group mt-2">
                <h6 className="fw-semibold">Task Creator</h6>
                <div className="d-flex align-items-center mt-2">
                    <UserCreator
                        src={
                            selectedTask?.creator?.user_avatar ??
                            generateInitialImageUrl(
                                selectedTask?.creator?.user_name as string,
                            )
                        }
                        alt="John Doe"
                    />
                    <div
                        className="ms-2 fw-normal"
                        style={{
                            whiteSpace: 'break-spaces',
                            wordBreak: 'break-word',
                        }}
                    >
                        <p className="mb-0">
                            {selectedTask?.creator?.user_name ??
                                selectedTask?.creator?.user_email}
                        </p>
                    </div>
                </div>
            </div>
            <div className="form-group">
                <Controller
                    control={control}
                    name="status"
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => (
                        <div className="text-start">
                            <InputSearchSelect
                                id="status"
                                placeholder="Status"
                                options={statusLists}
                                isMulti={false}
                                placeholderCreatable="Create"
                                onChange={(value: { value: string; label: string }) => {
                                    onChange(value.value);
                                }}
                                disabled={
                                    !permissions.collaborate ||
                                    selectedTask?.deleted_at !== null
                                }
                                defaultValue={(() => {
                                    const item = selectedTask?.status.find(
                                        (item: {
                                            selected: boolean;
                                            name: string;
                                            value: string;
                                        }) => item.selected,
                                    );

                                    return item != null
                                        ? { label: handleLabel(item), value: item.value }
                                        : undefined;
                                })()}
                            />
                            {errors.status != null && (
                                <span
                                    className="text-danger"
                                    style={{
                                        fontSize: 12,
                                    }}
                                >
                                    Please select status
                                </span>
                            )}
                        </div>
                    )}
                />
            </div>

            <InputDateRangeHeaderNew
                onChange={val => handleChangeDateRange(val)}
                value={dateRange as [Date, Date]}
                required
                disabled={!permissions.collaborate || selectedTask?.deleted_at !== null}
            />

            <InputBasic
                id="totalDays"
                type="text"
                placeholder="Total Days"
                // innerClassName="my-0"
                outerClassName="mt-3"
                rules={{
                    function: register,
                    name: 'total_duration',
                    rules: {
                        maxLength: {
                            value: 2000,
                            message: 'Maximum length of 2000 characters',
                        },
                    },
                    errors,
                }}
                disabled
            />
            <div className="form-group">
                <Controller
                    control={control}
                    name="trade"
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => (
                        <div className="text-start">
                            <InputSearchSelect
                                id="nicheTrade"
                                placeholder="Niche Trade"
                                options={nicheLists}
                                isMulti={false}
                                placeholderCreatable="Create"
                                onChange={(value: { value: string; label: string }) => {
                                    onChange(value.value);
                                }}
                                defaultValue={{
                                    value: selectedTask?.niche.id,
                                    label: selectedTask?.niche.name,
                                }}
                                disabled={
                                    !permissions.collaborate ||
                                    selectedTask?.deleted_at !== null
                                }
                            />
                            {errors.niche != null && (
                                <span
                                    className="text-danger"
                                    style={{
                                        fontSize: 12,
                                    }}
                                >
                                    Please select niche trade
                                </span>
                            )}
                        </div>
                    )}
                />
            </div>

            <div className="form-group mt-3">
                <label htmlFor="assigneeId" className="text-start mb-2 mt-3 w-100">
                    Assignee
                </label>
                <div className="row">
                    {permissions.collaborate && selectedTask?.deleted_at === null && (
                        <div className="col-auto col-md-2">
                            <ButtonAssignee
                                onClick={() =>
                                    setModal(prev => ({
                                        ...prev,
                                        addAssignee: true,
                                    }))
                                }
                            >
                                <AiOutlinePlus className="icon-assignee" />
                            </ButtonAssignee>
                        </div>
                    )}
                    {isLoadingAssignee
                        ? Array.from(Array(2).keys()).map((item, index: number) => (
                              <div key={item} className="col-auto col-md-2">
                                  <Skeleton
                                      count={1}
                                      baseColor="#cfd0d1"
                                      height={50}
                                      width={50}
                                      circle
                                      style={{
                                          lineHeight: 2,
                                          left: (index + 1) * -25,
                                      }}
                                  />
                              </div>
                          ))
                        : assignees.map((user, index) => (
                              <div
                                  key={index}
                                  className="col-md-2 position-relative"
                                  onMouseEnter={() =>
                                      setHoverDelete({ id: user.id, status: true })
                                  }
                                  onMouseLeave={() =>
                                      setHoverDelete({ id: user.id, status: false })
                                  }
                              >
                                  <BasicTooltip text={user.user_name ?? user.user_email}>
                                      <UserAvatar
                                          src={
                                              user.user_avatar ??
                                              generateInitialImageUrl(
                                                  user.user_name ?? user.user_email,
                                              )
                                          }
                                      />
                                  </BasicTooltip>
                                  {permissions.collaborate &&
                                      selectedTask?.deleted_at === null &&
                                      hoverDelete.id === user.id &&
                                      hoverDelete.status && (
                                          <svg
                                              role="button"
                                              width="18"
                                              height="19"
                                              viewBox="0 0 18 19"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                              style={{
                                                  position: 'absolute',
                                                  right: '-5px',
                                                  top: '-3px',
                                              }}
                                              onClick={() =>
                                                  handleOpenModalRemoveAssignee(user.id)
                                              }
                                          >
                                              <circle
                                                  cx="9"
                                                  cy="9.5"
                                                  r="8.5"
                                                  fill="#FB6056"
                                                  stroke="white"
                                              />
                                              <path
                                                  d="M6.375 6.875L11.625 12.125M11.625 6.875L6.375 12.125"
                                                  stroke="white"
                                                  strokeWidth="1.5"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                              />
                                          </svg>
                                      )}
                              </div>
                          ))}
                </div>
            </div>
        </div>
    );
}

const ButtonAssignee = styled.span`
    width: 50px;
    height: 50px;
    background-color: #dddafe;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #5648fb;

    .icon-assignee {
        color: #5648fb;
    }

    &:hover {
        border: 1px solid #5648fb;

        .icon-assignee {
            // color: #dddafe;
        }
    }
`;

const UserAvatar = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
`;

const UserCreator = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
`;
