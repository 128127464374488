import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import InputBasic from '@/components/atoms/Inputs/InputBasic';
import ModalAction from '@/components/atoms/Modals/ModalAction';

import { RootState } from '@/redux/store';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import DropFile from '@/components/molecules/DropFile';
import { toast } from 'react-toastify';
import { storeVaporFile } from '@/routes/Dashboard/Home/redux/actions';
import { updateDrawing } from '../redux/actions';
import { useParams } from 'react-router-dom';

export default function ModalDrawingRename({
    modal,
    setModal,
}: {
    modal: boolean;
    setModal: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
    const { id } = useParams();

    const projectId = id ?? '';

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
    });

    const dispatch = useDispatch();
    const detailDrawingState = useSelector((state: RootState) => state.detailDrawing);
    const updateDrawingState = useSelector((state: RootState) => state.updateDrawing);

    const [files, setFiles] = useState<
        Array<{
            names: string;
            descriptions: string;
            files: File;
            progress: number;
        }>
    >([]);

    // max size per upload 4GB
    const maxUploadSize = 4100000000; // bytes
    const [isMaxUpload, setIsMaxUpload] = useState(false);
    const [uploading, setUploading] = useState(false);

    // Check if total size of files exceed max upload size
    useEffect(() => {
        if (files.length > 0) {
            const totalSize = files.reduce((acc, file) => acc + file.files.size, 0);

            if (totalSize > maxUploadSize) {
                setIsMaxUpload(true);
            } else {
                setIsMaxUpload(false);
            }
        }
    }, [files]);

    // Load data to form
    useEffect(() => {
        if (detailDrawingState?.status === 200) {
            const { name, description } = detailDrawingState?.response;

            setValue('name', name);
            setValue('description', description);
        }
    }, [detailDrawingState]);

    // If update success
    useEffect(() => {
        if (updateDrawingState.status === 200) {
            reset();
            setFiles([]);
            setUploading(false);
        }
        if (updateDrawingState.status === 400) {
            setUploading(false);
        }
    }, [updateDrawingState]);

    /**
     * Function on submit
     *
     * @param any data
     * @return void
     * @see cypress/e2e/dashboard/Project/project.cy.ts
     *      To cypress unit tester
     */
    const onSubmit = async (data: any): Promise<void> => {
        const bucketName = import.meta.env.VITE_BUCKET_NAME;

        setUploading(true);

        // initialize form data
        const payload: any = new FormData();
        // append data to form data
        for (const key in data) {
            payload.append(key, data[key as keyof typeof data]);
        }
        // delete description if empty
        if (data.description === '' || data.description === null) {
            payload.delete('description');
        }
        const uploadImage = async (
            file: {
                names: string;
                descriptions: string;
                files: File;
                progress: number;
            },
            index: number,
        ): Promise<void> => {
            // eslint-disable-next-line no-async-promise-executor
            return await new Promise(async (resolve, reject) => {
                const response = await storeVaporFile(file.files, {
                    bucket: bucketName,
                    visibility: 'public-read',
                    progress: progress => {
                        setFiles(prev => {
                            const newFiles = [...prev];
                            newFiles[index].progress = progress;
                            return newFiles;
                        });
                    },
                });
                if (response !== undefined) {
                    const { uuid, bucket, key, extension } = response;
                    payload.append('file_uuid', uuid);
                    payload.append('file_bucket', bucket);
                    payload.append('file_key', key);
                    payload.append('file_type', extension);
                    payload.append('file_size', file.files.size);
                    resolve();
                } else {
                    reject(new Error('Failed to upload image'));
                }
            });
        };

        for (const [index, file] of files.entries()) {
            // Assuming have a type definition for File, use it for type assertion
            const typedFile = file as {
                names: string;
                descriptions: string;
                files: File;
                progress: number;
            };
            try {
                await uploadImage(typedFile, index);
            } catch (error) {
                toast.error('Failed to upload image, please try again later');
            }
        }

        // Dispatch action
        dispatch(
            updateDrawing(
                {
                    id: detailDrawingState?.response?.uuid,
                    project_id: projectId,
                },
                payload,
            ),
        );
    };

    return (
        <ModalAction
            modal={modal}
            setModal={setModal}
            aria-labelledby="contained-modal-title-vcenter"
            title={`Edit ${(detailDrawingState?.response?.type as string) ?? ''}`}
            size={detailDrawingState?.response?.type === 'File' ? 'lg' : 'sm'}
        >
            <form action="#" onSubmit={handleSubmit(onSubmit)} method="post">
                <InputBasic
                    id="folderName"
                    type="text"
                    placeholder={`${detailDrawingState?.response?.type as string} Name`}
                    innerClassName="my-0"
                    outerClassName="px-4 mt-3"
                    loading={detailDrawingState.loading}
                    rules={{
                        function: register,
                        name: 'name',
                        rules: {
                            required: 'File name is required',
                        },
                        errors,
                    }}
                />
                <InputBasic
                    id="descriptionFolder"
                    type="text"
                    placeholder="Description"
                    innerClassName="my-0"
                    outerClassName="px-4 mt-3"
                    loading={detailDrawingState.loading}
                    rules={{
                        function: register,
                        name: 'description',
                        rules: {
                            maxLength: {
                                value: 2000,
                                message: 'Maximum length of 2000 characters',
                            },
                        },
                        errors,
                    }}
                />
                {detailDrawingState?.response?.type === 'File' && (
                    <div className="mt-3 px-3">
                        <small
                            className="text-start w-100 p-2"
                            style={{
                                display: 'inline-block',
                            }}
                        >
                            Revision Count: {detailDrawingState?.response?.revision_count}
                        </small>
                        <DropFile
                            files={files}
                            setFiles={setFiles}
                            acceptFile={{
                                'image/png': ['.png'],
                                'image/jpeg': ['.jpg', '.jpeg'],
                                'image/gif': ['.gif'],
                                'image/bmp': ['.bmp'],
                                'image/webp': ['.webp'],
                                'application/pdf': ['.pdf'],
                                'application/msexcel': ['.xls', '.xlsx'],
                            }}
                            isMaxUpload={isMaxUpload}
                            maxFiles={1}
                            multiple={false}
                            title="Reupload your file here"
                            maxUploadSize={4100000000}
                        />
                    </div>
                )}
                <ButtonBasic
                    text={`Update ${detailDrawingState?.response?.type as string}`}
                    type="submit"
                    className="btn btn-primary btn-sm mt-4"
                    loading={uploading}
                />
            </form>
        </ModalAction>
    );
}
