import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

interface ModalBasicProps {
    modal: boolean;
    setModal: (value: any) => void;
    title?: string;
    body?: string;
    children: JSX.Element;
    size?: 'sm' | 'md' | 'lg' | 'xl';
    withClose?: boolean;
    handleSubmit?: () => void;
    withoutHeader?: boolean;
}

/**
 * Create action component boilerplate.
 *
 * @param ModalBasicProps
 * @constructor
 */
export default function ModalAction({
    modal,
    setModal,
    title,
    children,
    size,
    withoutHeader,
}: ModalBasicProps): JSX.Element {
    return (
        <ModalWrapper
            show={modal}
            onHide={() => setModal(false)}
            size={size}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {withoutHeader ?? false ? null : (
                <Modal.Header closeButton className="px-4">
                    <h6 className="fw-semibold mb-0">{title}</h6>
                </Modal.Header>
            )}
            <Modal.Body className="mb-4">{children}</Modal.Body>
        </ModalWrapper>
    );
}

const ModalWrapper = styled(Modal)`
    hr {
        color: #bdbdbd;
    }
    .modal-content {
        font-family: 'Montserrat', sans-serif;
        border-radius: 10px;
    }

    .form-floating > label {
        padding: 1.4rem;
    }

    .modal-header {
        border: none;
        border-bottom: 1px solid #e9ecef;
        padding: 1rem;

        .btn-close {
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 10px;
        }
    }

    .modal-body {
        text-align: center;
        padding: 0;

        .form-floating {
            font-size: 14px;
        }

        .label-active {
            padding: 1.5rem 0.5rem;
        }

        input {
            padding: 0 0 0px 4px !important;
        }
    }
    .modal-footer {
        justify-content: center;
        border: none;
        flex-wrap: initial;
    }
`;
