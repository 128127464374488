import { useEffect, useState } from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import InputSearchSelect from '@/components/atoms/Inputs/InputSearchSelect';

import styled from 'styled-components';

import { RootState } from '@/redux/store';

import {
    getAllMemberInhouse,
    getAllMemberInvited,
} from '@/routes/Dashboard/Project/redux/actions';

import { FiChevronDown } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { inviteUserDocument } from '../redux/actions';
import ModalAction from '@/components/atoms/Modals/ModalAction';

export default function ModalDocumentInvite({
    modal,
    setModal,
}: {
    modal: boolean;
    setModal: (value: boolean) => void;
}): JSX.Element {
    const { id } = useParams();

    const projectId = id ?? '';
    const dispatch = useDispatch();

    const allMemberInvitedState = useSelector(
        (state: RootState) => state.allMemberInvited,
    );
    const allMemberInhouseState = useSelector(
        (state: RootState) => state.allMemberInhouse,
    );
    const inviteUserDocumentState = useSelector(
        (state: RootState) => state.inviteUserDocument,
    );

    const [isValidate, setIsValidate] = useState<boolean>(false);

    const [selectedUser, setSelectedUser] = useState<
        Array<{
            label: string;
            value: string;
            assignee_emails: string;
            assignee_can_collaborates: number;
            assignee_can_invites: number;
            assignee_can_post_publics: number;
        }>
    >([
        {
            label: 'Type or select email',
            value: '',
            assignee_emails: '',
            assignee_can_collaborates: 0,
            assignee_can_invites: 0,
            assignee_can_post_publics: 0,
        },
    ]);

    const [userList, setUserList] = useState<any>([]);

    const params = {
        project_id: projectId,
        page: 1,
        per_page: 9999,
    };

    // Get all member inhouse
    useEffect(() => {
        if (modal) {
            dispatch(getAllMemberInvited(params));
            dispatch(getAllMemberInhouse(params));
        }
        if (!modal) {
            setSelectedUser([
                {
                    label: 'Select user',
                    value: 'Select user',
                    assignee_emails: '',
                    assignee_can_collaborates: 0,
                    assignee_can_invites: 0,
                    assignee_can_post_publics: 0,
                },
            ]);
            setIsValidate(false);
        }
    }, [modal]);

    useEffect(() => {
        if (inviteUserDocumentState?.status === 200) {
            setSelectedUser([
                {
                    label: 'Select user',
                    value: 'Select user',
                    assignee_emails: '',
                    assignee_can_collaborates: 0,
                    assignee_can_invites: 0,
                    assignee_can_post_publics: 0,
                },
            ]);

            setIsValidate(false);
        }
    }, [inviteUserDocumentState]);

    // // Reset state
    // useEffect(() => {
    //     if (!modal) {
    //         setSelectedUser([
    //             {
    //                 label: '',
    //                 value: '',
    //                 assignee_emails: '',
    //                 assignee_can_collaborates: 0,
    //                 assignee_can_invites: 0,
    //                 assignee_can_post_publics: 0,
    //             },
    //         ]);
    //         setIsValidate(false);
    //     }
    // }, [modal]);

    // Function to set user list
    useEffect(() => {
        if (allMemberInvitedState?.status === 200) {
            const data = allMemberInvitedState?.response?.data;
            const newData = data.map((item: any) => {
                return {
                    label: item.name,
                    value: item.email,
                };
            });

            setUserList((prevData: any) => {
                const mergedData = prevData.concat(newData);
                const filteredData = mergedData.filter(
                    (item: any, index: number, self: any) =>
                        index === self.findIndex((t: any) => t.value === item.value),
                );
                return filteredData;
            });
        }

        if (allMemberInhouseState?.status === 200) {
            const data = allMemberInhouseState?.response?.data;
            const newData = data.map((item: any) => {
                return {
                    label: item.name,
                    value: item.email,
                };
            });

            setUserList((prevData: any) => {
                const mergedData = prevData.concat(newData);
                const filteredData = mergedData.filter(
                    (item: any, index: number, self: any) =>
                        index === self.findIndex((t: any) => t.value === item.value),
                );
                return filteredData;
            });
        }
    }, [allMemberInvitedState, allMemberInhouseState]);

    /**
     * Function to handle add users
     *
     * @returns void
     */
    const handleAddUser = (): void => {
        setSelectedUser((prevData: any) => {
            const newData = prevData.concat({
                label: 'Type or select email',
                value: '',
                assignee_emails: '',
                assignee_can_collaborates: 0,
                assignee_can_invites: 0,
                assignee_can_post_publics: 0,
            });
            return newData;
        });
    };

    /**
     * Function to handle delete user
     *
     * @param number index
     * @returns void
     */
    const handleDeleteUser = (index: number): void => {
        // delete user from index and refresh state
        setSelectedUser((prevData: any) => {
            const newData = prevData.filter((item: any, key: number) => key !== index);
            return newData;
        });

        // update user list with new data after deleting user
        // setUserList((prevData: any) => {
        //     const newOptions = prevData.filter(
        //         (item: any) =>
        //             selectedUser.findIndex(user => user.value === item.value) === -1,
        //     );
        //     return newOptions;
        // });
    };

    /**
     * Function to switch access
     *
     * @param boolean value
     * @param string type
     * @param number index
     * @returns void
     */
    const handleAccess = (value: boolean, type: string, index: number): void => {
        const newData = selectedUser.map((item: any, key: number) => {
            if (key === index) {
                if (type === 'collaborate') {
                    return {
                        ...item,
                        assignee_can_collaborates: value ? 1 : 0,
                    };
                } else if (type === 'view') {
                    return {
                        ...item,
                        assignee_can_collaborates: value ? 0 : 1,
                    };
                } else if (type === 'invite') {
                    return {
                        ...item,
                        assignee_can_invites: value ? 1 : 0,
                    };
                } else if (type === 'public') {
                    return {
                        ...item,
                        assignee_can_post_publics: value ? 1 : 0,
                    };
                }
            }
            return item;
        });

        setSelectedUser(newData);
    };

    /**
     * Function for handle share to user email
     *
     * @param object data
     * @return void
     * @see cypress/e2e/authentication/login/login.cy.ts
     *      To cypress unit tester
     */
    const handleInviteUser = (): void => {
        const payload = new FormData();

        selectedUser.forEach((item: any, key: number) => {
            payload.append(`assignee_emails[${key}]`, item.value);
            payload.append(
                `assignee_can_collaborates[${key}]`,
                item.assignee_can_collaborates,
            );
            payload.append(`assignee_can_invites[${key}]`, item.assignee_can_invites);
            payload.append(
                `assignee_can_post_publics[${key}]`,
                item.assignee_can_post_publics,
            );
        });

        if (selectedUser[0].value !== '') {
            dispatch(
                inviteUserDocument(
                    {
                        project_id: projectId,
                    },
                    payload,
                ),
            );
        } else {
            setIsValidate(true);
        }
    };

    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/g;

    return (
        <>
            <ModalAction
                modal={modal}
                setModal={setModal}
                aria-labelledby="contained-modal-title-vcenter"
                title={'Invite Document'}
                size={'md'}
            >
                <div className="p-3 pb-0">
                    <div className="text-start">
                        <span
                            className="link-primary"
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => handleAddUser()}
                        >
                            + Add User
                        </span>
                    </div>

                    <div className="mb-3">
                        {selectedUser.map((item: any, key: number) => (
                            <div
                                key={key}
                                className="d-flex align-items-center text-start"
                            >
                                <div
                                    style={{
                                        width: '75%',
                                    }}
                                >
                                    <InputSearchSelect
                                        placeholder="Email"
                                        innerClassName=""
                                        outerClassName=""
                                        options={userList}
                                        isMulti={false}
                                        withCreatable
                                        placeholderCreatable="Invite"
                                        value={{ value: item.value, label: item.label }}
                                        onBlur={e => {
                                            // validation email
                                            const isValid = regex.test(e.target.value);
                                            if (e.target.value !== '' && isValid) {
                                                setSelectedUser((prevData: any) => {
                                                    const newData = prevData.map(
                                                        (item: any, index: number) => {
                                                            if (index === key) {
                                                                return {
                                                                    ...item,
                                                                    label: e.target.value,
                                                                    value: e.target.value,
                                                                    assignee_emails:
                                                                        e.target.value,
                                                                };
                                                            }
                                                            return item;
                                                        },
                                                    );
                                                    return newData;
                                                });
                                            }
                                        }}
                                        onChange={(value: {
                                            value: string;
                                            label: string;
                                        }) => {
                                            const isValid = regex.test(value.value);
                                            if (isValid) {
                                                setSelectedUser((prevData: any) => {
                                                    const newData = prevData.map(
                                                        (item: any, index: number) => {
                                                            if (index === key) {
                                                                return {
                                                                    ...item,
                                                                    label: value.label,
                                                                    value: value.value,
                                                                    assignee_emails:
                                                                        value.value,
                                                                };
                                                            }
                                                            return item;
                                                        },
                                                    );
                                                    return newData;
                                                });
                                            }
                                        }}
                                    />
                                    {selectedUser[0].value === '' && isValidate && (
                                        <span
                                            className="text-danger"
                                            style={{
                                                fontSize: 12,
                                            }}
                                        >
                                            Please select user at least one
                                        </span>
                                    )}
                                </div>
                                <Dropdown className="mt-4 ms-2">
                                    <CustomDropdown
                                        variant="none"
                                        id="dropdown-autoclose-true"
                                    >
                                        <span
                                            style={{
                                                fontSize: 14,
                                            }}
                                        >
                                            {item.assignee_can_collaborates === 1 &&
                                                'Collaborator'}
                                            {item.assignee_can_collaborates === 0 &&
                                                'View Only'}
                                        </span>
                                        <FiChevronDown size={20} />
                                    </CustomDropdown>
                                    <CustomDropdownAccess>
                                        <div className="">
                                            <div className="d-flex justify-content-between align-items-center px-3 py-1">
                                                <Form.Check
                                                    type="radio"
                                                    label="Collaborator"
                                                    id={`collaborator${key}`}
                                                    className="my-1"
                                                    style={{
                                                        fontSize: 14,
                                                    }}
                                                    checked={
                                                        item.assignee_can_collaborates ===
                                                        1
                                                    }
                                                    onChange={e =>
                                                        handleAccess(
                                                            e.target.checked,
                                                            'collaborate',
                                                            key,
                                                        )
                                                    }
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="View Only"
                                                    id={`viewOnly${key}`}
                                                    className="my-1"
                                                    style={{
                                                        fontSize: 14,
                                                    }}
                                                    checked={
                                                        item.assignee_can_collaborates ===
                                                        0
                                                    }
                                                    onChange={e =>
                                                        handleAccess(
                                                            e.target.checked,
                                                            'view',
                                                            key,
                                                        )
                                                    }
                                                />
                                            </div>
                                            <hr className="m-0" />
                                            <div className="d-flex flex-column px-3 py-1">
                                                <Form.Check
                                                    type="checkbox"
                                                    label="Post Publicly"
                                                    id={`postPublicly${key}`}
                                                    className="mt-2"
                                                    style={{
                                                        fontSize: 14,
                                                    }}
                                                    checked={
                                                        item.assignee_can_post_publics ===
                                                        1
                                                    }
                                                    onChange={e =>
                                                        handleAccess(
                                                            e.target.checked,
                                                            'public',
                                                            key,
                                                        )
                                                    }
                                                />

                                                <Form.Check
                                                    type="checkbox"
                                                    label="Invite User"
                                                    id={`inviteUser${key}`}
                                                    className="mt-2"
                                                    style={{
                                                        fontSize: 14,
                                                    }}
                                                    checked={
                                                        item.assignee_can_invites === 1
                                                    }
                                                    onChange={e =>
                                                        handleAccess(
                                                            e.target.checked,
                                                            'invite',
                                                            key,
                                                        )
                                                    }
                                                />
                                                {key !== 0 && (
                                                    <span
                                                        role="button"
                                                        style={{
                                                            fontSize: 14,
                                                        }}
                                                        className="link-danger text-end"
                                                        onClick={() =>
                                                            handleDeleteUser(key)
                                                        }
                                                    >
                                                        Remove User
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </CustomDropdownAccess>
                                </Dropdown>
                            </div>
                        ))}
                    </div>
                    <ButtonBasic
                        onClick={() => handleInviteUser()}
                        text="Invite"
                        className="w-25 btn-primary"
                        loading={inviteUserDocumentState.loading}
                        disabled={
                            selectedUser.filter(
                                (item: any) => item.assignee_emails === '',
                            ).length > 0
                        }
                    />
                </div>
            </ModalAction>
        </>
    );
}

const CustomDropdown = styled(Dropdown.Toggle)`
    background: transparent;
    border: none;
    display: flex;
    padding: 0;

    &:focus {
        box-shadow: none;
        background: transparent;
        border: none;
    }

    &:hover {
        box-shadow: none;
        background: transparent;
        border: none;
    }

    &:active {
        box-shadow: none;
        background-color: transparent !important;
        border: none;
    }

    &:focus-visible {
        box-shadow: none;
        background-color: transparent;
        border: none;
    }

    &:after {
        display: none;
    }
`;

const CustomDropdownAccess = styled(Dropdown.Menu)`
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 17rem;

    a {
        text-decoration: none;
        color: #000;
    }

    .label-filter {
        font-size: 14px;
    }

    .dropdown-item {
        font-size: 14px;
        vertical-align: middle;
        padding: 0.3rem 0.5rem;
        border-radius: 5px;

        &:hover {
            background: #dddafe;
            border-radius: 5px;
            a {
                color: #000;
            }
        }
    }
`;
