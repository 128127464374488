import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import ButtonBasic from '../Buttons/ButtonBasic';
import { FiEdit, FiSend } from 'react-icons/fi';
import {
    AiOutlineCheckCircle,
    AiOutlineLogout,
    AiOutlineUserSwitch,
} from 'react-icons/ai';
import { RiIndeterminateCircleLine } from 'react-icons/ri';
import { MdOutlineCancel } from 'react-icons/md';
import { SlHome } from 'react-icons/sl';
import { BsFillPinAngleFill, BsFillPinFill } from 'react-icons/bs';
import { HiOutlineXCircle } from 'react-icons/hi';
import { useEffect, useState } from 'react';

interface ModalBasicProps {
    modal: any;
    state: string;
    setModal: any;
    variant: string;
    type: string;
    title: string;
    body: string;
    subBody?: string;
    withCancel?: boolean;
    onConfirm?: () => void;
    loading?: boolean;
    countdownButton?: boolean;
    countdown?: number;
    bodyClass?: string;
    overlay?: 1 | 2 | 3;
    outsideClick?: () => void;
}

export default function ModalSchedule(props: ModalBasicProps): JSX.Element {
    const {
        modal,
        setModal,
        type,
        variant,
        title,
        body,
        withCancel,
        onConfirm,
        subBody,
        loading,
        countdownButton,
        countdown,
        bodyClass,
        overlay,
        outsideClick,
        state,
    } = props;

    const [time, setTime] = useState<number>(countdown ?? 0);

    const handleIcon = (type: string): JSX.Element => {
        switch (type) {
            case 'caution':
                return <FiEdit className="text-info fs-1" size={80} />;
            case 'success':
                return <AiOutlineCheckCircle className="text-success fs-1" size={80} />;
            case 'activate':
                return <AiOutlineUserSwitch className="text-info fs-1" size={80} />;
            case 'terminate':
                return (
                    <RiIndeterminateCircleLine className="text-danger fs-1" size={80} />
                );
            case 'resend':
                return <FiSend className="text-info fs-1" size={80} />;
            case 'cancel':
                return <MdOutlineCancel className="text-danger fs-1" size={80} />;
            case 'back':
                return <SlHome className="text-danger fs-1" size={80} />;
            case 'pin':
                return <BsFillPinFill className="text-info fs-1" size={80} />;
            case 'unpin':
                return <BsFillPinAngleFill className="text-danger fs-1" size={80} />;
            case 'leave':
                return <HiOutlineXCircle className="text-danger fs-1" size={80} />;
            case 'delete':
                return (
                    <svg
                        width="81"
                        height="80"
                        viewBox="0 0 81 80"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M13.8333 20H67.1667M53.8333 20L52.9312 17.2936C52.0569 14.6708 51.6198 13.3595 50.809 12.3899C50.0931 11.5338 49.1736 10.8711 48.135 10.4626C46.9588 10 45.5765 10 42.8119 10H38.1881C35.4235 10 34.0412 10 32.865 10.4626C31.8264 10.8711 30.9069 11.5338 30.191 12.3899C29.3802 13.3595 28.943 14.6708 28.0688 17.2936L27.1667 20M60.5 20V54C60.5 59.6005 60.5 62.4008 59.4101 64.5399C58.4513 66.4215 56.9215 67.9513 55.0399 68.9101C52.9008 70 50.1005 70 44.5 70H36.5C30.8995 70 28.0992 70 25.9601 68.9101C24.0785 67.9513 22.5487 66.4215 21.5899 64.5399C20.5 62.4008 20.5 59.6005 20.5 54V20M47.1667 33.3333V56.6667M33.8333 33.3333V56.6667"
                            stroke="#FB6056"
                            strokeWidth="4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                );
            case 'restore':
                return (
                    <svg
                        width="81"
                        height="80"
                        viewBox="0 0 81 80"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10.4996 10V26.6667M10.4996 26.6667H27.1662M10.4996 26.6667L20.4996 17.6389C25.8071 12.8885 32.816 10 40.4996 10C57.0681 10 70.4996 23.4315 70.4996 40C70.4996 56.5685 57.0681 70 40.4996 70C26.2223 70 14.2745 60.0266 11.243 46.6667"
                            stroke="#4070FF"
                            strokeWidth="4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                );
            case 'logout':
                return <AiOutlineLogout className="text-danger fs-1" size={80} />;
            default:
                return <div></div>;
        }
    };

    const handleConfirmText = (type: string): string => {
        switch (type) {
            case 'caution':
                return 'Yes';
            case 'success':
                return 'Confirm';
            case 'terminate':
                return 'Terminate';
            case 'activate':
                return 'Activate';
            case 'pin':
                return 'Pin';
            case 'unpin':
                return 'Unpin';
            case 'leave':
                return 'Leave';
            case 'delete':
                return 'Delete';
            case 'logout':
                return 'Logout';
            default:
                return 'Confirm';
        }
    };

    const handleButtonStyle = (variant: string): string => {
        switch (variant) {
            case 'caution':
                return 'btn-outline-info';
            case 'success':
                return 'btn-outline-success';
            case 'danger':
                return 'btn-outline-danger';
            case 'info':
                return 'btn-outline-info';
            case 'warning':
                return 'btn-outline-warning';
            default:
                return 'btn-outline-info';
        }
    };

    const handleOverlay = (overlay: 0 | 1 | 2 | 3): string => {
        switch (overlay) {
            case 1:
                return '#0000009c';
            case 2:
                return '#000000b8';
            case 3:
                return '#000000d4';
            default:
                return 'rgba(0, 0, 0, 0.5)';
        }
    };

    useEffect(() => {
        if (countdownButton ?? false) {
            const interval = setInterval(() => {
                setTime(prevTime => prevTime - 1);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [countdownButton]);

    // useEffect(() => {
    //     if (modal[name]) {
    //         setTime(countdown ?? 0);
    //     }
    // }, [modal]);

    const handleCountdownDisable = (time: number): boolean => {
        if (time > 0) {
            if (loading ?? false) {
                return true;
            }
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            <ModalWrapper
                show={modal[state]}
                onHide={() => {
                    setModal((prev: any) => ({ ...prev, [state]: false }));
                    outsideClick?.();
                }}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{
                    backgroundColor: handleOverlay(overlay ?? 0),
                }}
            >
                <Modal.Header closeButton>
                    <h5
                        className="fw-semibold text-center"
                        style={{
                            maxWidth: '95%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'pre',
                        }}
                    >
                        {title}
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    {handleIcon(type)}
                    <p className={`mt-3 text-center ${bodyClass as string}`}>{body}</p>
                    <p
                        className="mt-3 text-center fw-semibold"
                        style={{ wordBreak: 'break-word' }}
                    >
                        {subBody}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    {(withCancel ?? false) && (
                        <ButtonBasic
                            text="Cancel"
                            className={`${handleButtonStyle(variant)} w-100`}
                            onClick={() =>
                                setModal((prev: any) => ({ ...prev, [state]: false }))
                            }
                        />
                    )}
                    {countdownButton ?? false ? (
                        <ButtonBasic
                            className={`btn-${variant} ${
                                withCancel ?? false ? 'w-100' : 'w-50'
                            }`}
                            text={time > 0 ? `${time} s` : handleConfirmText(type)}
                            onClick={() => onConfirm?.()}
                            loading={loading}
                            disabled={handleCountdownDisable(time)}
                        />
                    ) : (
                        <ButtonBasic
                            className={`btn-${variant} ${
                                withCancel ?? false ? 'w-100' : 'w-50'
                            }`}
                            type="button"
                            text={handleConfirmText(type)}
                            onClick={() => onConfirm?.()}
                            loading={loading}
                        />
                    )}
                </Modal.Footer>
            </ModalWrapper>
        </>
    );
}

const ModalWrapper = styled(Modal)`
    .modal-content {
        font-family: 'Montserrat', sans-serif;
        padding: 0.5rem 1rem;
        border-radius: 10px;
    }

    .modal-header {
        border: none;
        justify-content: center;

        .btn-close {
            position: absolute;
            right: 20px;
            top: 25px;
            font-size: 10px;
        }
    }
    .modal-body {
        text-align: center;
        font-size: 14px;
        padding: 0;
    }
    .modal-footer {
        justify-content: center;
        border: none;
        flex-wrap: initial;
    }
`;
