import InputAreaNew from '@/components/atoms/Inputs/InputAreaNew';
import BasicTooltip from '@/components/atoms/Tooltips';
import { RootState } from '@/redux/store';
import { formatDateIsoAgo, generateInitialImageUrl, getId } from '@/utility/Utils';
import { useEffect, useRef, useState } from 'react';
import { Placeholder } from 'react-bootstrap';
import {
    AiOutlineClose,
    AiOutlineDelete,
    AiOutlineEdit,
    AiOutlineFilePdf,
    AiOutlineUpload,
} from 'react-icons/ai';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { SubTaskCommentProps } from '../../interfaces';

export default function SubTaskComment({
    data,
    handleSendComment,
    handleEditComment,
    handleDeleteComment,
    selectedTask,
}: SubTaskCommentProps): JSX.Element {
    const postTaskCommentState = useSelector((state: RootState) => state.postTaskComment);
    const editTaskCommentState = useSelector((state: RootState) => state.editTaskComment);
    const detailTaskCommentState = useSelector(
        (state: RootState) => state.detailTaskComment,
    );

    const authId = getId();

    const bottomRef = useRef<HTMLDivElement>(null);

    const [inputState, setInputState] = useState('');
    const [inputImage, setInputImage] = useState<File[]>([]);
    const [editImage, setEditImage] = useState<File[]>([]);
    const [editState, setEditState] = useState({
        id: 0,
        comment: '',
    });

    const uploadRef = useRef<HTMLInputElement>(null);
    const uploadEditRef = useRef<HTMLInputElement>(null);

    const maxSize = 4500000;
    const acceptedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

    const [errorUpload, setErrorUpload] = useState({
        status: false,
        message: '',
    });
    const [errorEditUpload, setErrorEditUpload] = useState({
        status: false,
        message: '',
    });

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        setTimeout(() => {
            bottomRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }, 1000);
    }, [postTaskCommentState, editTaskCommentState]);

    useEffect(() => {
        if (editTaskCommentState?.status === 200) {
            setEditState({
                id: 0,
                comment: '',
            });
            setEditImage([]);
        }
    }, [editTaskCommentState]);

    const handleSubmitComment = (
        e: React.KeyboardEvent<HTMLInputElement>,
        isEdit: boolean,
    ): void => {
        const value: string = (e.target as HTMLInputElement).value;

        // if enter
        if (e.key === 'Enter') {
            // if shift + enter
            if (e.shiftKey) {
                return;
            }

            // if not empty
            if (value !== '') {
                isEdit
                    ? handleEditComment(editState.id, value, editImage)
                    : handleSendComment(value, inputImage);
            }

            e.preventDefault();
            setInputState('');
            setInputImage([]);
            setErrorUpload({
                status: false,
                message: '',
            });
            setErrorEditUpload({
                status: false,
                message: '',
            });
        }
    };

    const handleUploadImage = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const files = e.target.files;

        if (files != null && files.length > 0) {
            const file = files[0];

            if (file.size > maxSize) {
                setErrorUpload({
                    status: true,
                    message: 'File is too large. Max 4.5MB',
                });
                return;
            }

            if (!acceptedTypes.includes(file.type)) {
                setErrorUpload({
                    status: true,
                    message: 'Invalid file type. Only png, jpeg, jpg, pdf',
                });
                return;
            }

            setErrorUpload({
                status: false,
                message: '',
            });

            setInputImage(Array.from(e.target.files ?? []).map((item: File) => item));
        }
    };

    const handleUploadEditImage = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const files = e.target.files;

        if (files != null && files.length > 0) {
            const file = files[0];

            if (file.size > maxSize) {
                setErrorEditUpload({
                    status: true,
                    message: 'File is too large. Max 4.5MB',
                });
                return;
            }

            if (!acceptedTypes.includes(file.type)) {
                setErrorEditUpload({
                    status: true,
                    message: 'Invalid file type. Only png, jpeg, jpg, pdf',
                });
                return;
            }

            setErrorEditUpload({
                status: false,
                message: '',
            });

            setEditImage(Array.from(e.target.files ?? []).map((item: File) => item));
        }
    };

    return (
        <div>
            <h6 className="fw-normal me-2 mb-0">Comment</h6>
            {detailTaskCommentState?.loading === true ? (
                Array.from(Array(2).keys()).map(item => (
                    <div key={item} className="mb-3">
                        <Placeholder animation="glow">
                            <Placeholder xs={3} /> <Placeholder xs={2} />
                            <br />
                            <Placeholder xs={4} />
                            &nbsp;
                            <Placeholder xs={3} />
                            &nbsp;
                            <Placeholder xs={4} />
                            &nbsp;
                            <Placeholder xs={9} />
                        </Placeholder>
                    </div>
                ))
            ) : data?.length === 0 ? (
                <div className="d-flex justify-content-center my-4">
                    <p className="mb-0 text-muted" style={{ fontSize: 14 }}>
                        No comment found
                    </p>
                </div>
            ) : (
                <div
                    className="hidden-scroll"
                    style={{
                        overflowY: 'scroll',
                        maxHeight: 'calc(100vh - 400px)',
                    }}
                >
                    {data?.map(
                        (
                            item: {
                                id: number;
                                comment: string;
                                document_url: string;
                                creator_id: number;
                                creator: {
                                    id: number;
                                    user_name: string;
                                    user_avatar: string;
                                };
                                updated_at: string;
                            },
                            key: number,
                        ) => (
                            <div key={key} className="d-flex flex-column my-4">
                                <div className="d-flex align-items-center mb-2">
                                    <AssigneeAvatar
                                        src={
                                            item?.creator?.user_avatar ??
                                            generateInitialImageUrl(
                                                item?.creator?.user_name,
                                            )
                                        }
                                        alt={'profile'}
                                    />
                                    <h6 className="mb-0 mx-1">
                                        {item?.creator?.user_name}
                                    </h6>

                                    {authId === item?.creator_id &&
                                        selectedTask?.deleted_at === null && (
                                            <BasicTooltip
                                                text={`${
                                                    editState.id === item?.id
                                                        ? 'Cancel Edit'
                                                        : 'Edit Comment'
                                                }`}
                                                className="mx-1"
                                            >
                                                {editState.id !== item?.id ? (
                                                    <AiOutlineEdit
                                                        className="text-primary"
                                                        onClick={async () => {
                                                            setEditState({
                                                                id: item?.id,
                                                                comment: item?.comment,
                                                            });
                                                            setEditImage([]);
                                                            setErrorEditUpload({
                                                                status: false,
                                                                message: '',
                                                            });
                                                        }}
                                                        style={{
                                                            fontSize: 20,
                                                        }}
                                                        role="button"
                                                    />
                                                ) : (
                                                    <AiOutlineClose
                                                        className="text-primary"
                                                        onClick={() => {
                                                            setEditState({
                                                                id: 0,
                                                                comment: '',
                                                            });
                                                            setEditImage([]);
                                                        }}
                                                        style={{
                                                            fontSize: 20,
                                                        }}
                                                        role="button"
                                                    />
                                                )}
                                            </BasicTooltip>
                                        )}
                                    {authId === item?.creator_id &&
                                        selectedTask?.deleted_at === null && (
                                            <BasicTooltip
                                                text={'Delete Comment'}
                                                className="mx-1"
                                            >
                                                <AiOutlineDelete
                                                    className="text-danger"
                                                    onClick={() =>
                                                        handleDeleteComment(item?.id)
                                                    }
                                                    style={{
                                                        fontSize: 20,
                                                    }}
                                                    role="button"
                                                />
                                            </BasicTooltip>
                                        )}
                                    <span className="mx-1">
                                        {formatDateIsoAgo(item?.updated_at)}
                                    </span>
                                </div>

                                {editState.id === item?.id ? (
                                    <div className="row gx-0">
                                        <CustomTextArea
                                            defaultValue={item?.comment}
                                            className="form-control"
                                            onKeyDown={(e: any) =>
                                                handleSubmitComment(e, true)
                                            }
                                            onChange={e =>
                                                setEditState({
                                                    ...editState,
                                                    comment: e.target.value,
                                                })
                                            }
                                        />
                                        <div
                                            className="col-md-6 col-lg-3 col-xl-2 text-center my-2 me-2"
                                            style={{
                                                height: 100,
                                            }}
                                        >
                                            <ImageWrapper
                                                className="d-flex flex-column align-items-center justify-content-center p-3 rounded-3"
                                                role="button"
                                                tabIndex={0}
                                                onClick={() =>
                                                    uploadEditRef?.current?.click()
                                                }
                                            >
                                                <AiOutlineUpload
                                                    size={18}
                                                    color="#9b9b9b"
                                                />
                                                <span
                                                    style={{
                                                        fontSize: 12,
                                                        color: '#9b9b9b',
                                                    }}
                                                >
                                                    Max 4.5MB
                                                </span>
                                            </ImageWrapper>
                                            <input
                                                type="file"
                                                hidden
                                                ref={uploadEditRef}
                                                accept="image/* , application/pdf"
                                                onChange={e => handleUploadEditImage(e)}
                                            />
                                        </div>

                                        {editImage?.length > 0
                                            ? editImage?.map((item, key) => (
                                                  <div
                                                      key={key}
                                                      className="col-md-6 col-lg-3 col-xl-2 my-2 me-2"
                                                      style={{
                                                          height: 100,
                                                      }}
                                                  >
                                                      {item.type === 'application/pdf' ? (
                                                          <ImageWrapper className="d-flex flex-column justify-content-center align-items-center p-1 rounded-3">
                                                              <AiOutlineFilePdf
                                                                  size={50}
                                                                  color="#9b9b9b"
                                                              />
                                                              <span
                                                                  style={{
                                                                      fontSize: 12,
                                                                      color: '#9b9b9b',
                                                                      textOverflow:
                                                                          'ellipsis',
                                                                      overflow: 'hidden',
                                                                      whiteSpace:
                                                                          'nowrap',
                                                                      width: '100%',
                                                                      textAlign: 'center',
                                                                  }}
                                                              >
                                                                  {item.name}
                                                              </span>
                                                          </ImageWrapper>
                                                      ) : (
                                                          <ImageWrapper className="p-1 rounded-3">
                                                              <ImageSection
                                                                  src={URL.createObjectURL(
                                                                      item,
                                                                  )}
                                                                  alt="image-section"
                                                              />
                                                          </ImageWrapper>
                                                      )}
                                                  </div>
                                              ))
                                            : item?.document_url !== null && (
                                                  <div className="col-md-6 col-lg-3 col-xl-2">
                                                      <div className="mt-2">
                                                          <ImageWrapper
                                                              className="rounded-3"
                                                              style={{
                                                                  height: 100,
                                                                  border: 'none',
                                                              }}
                                                          >
                                                              {item?.document_url
                                                                  ?.split('.')
                                                                  .pop() === 'pdf' ? (
                                                                  <ImageWrapper className="d-flex flex-column justify-content-center align-items-center p-1 rounded-3">
                                                                      <AiOutlineFilePdf
                                                                          size={50}
                                                                          color="#9b9b9b"
                                                                      />
                                                                      <span
                                                                          style={{
                                                                              fontSize: 12,
                                                                              color: '#9b9b9b',
                                                                              textOverflow:
                                                                                  'ellipsis',
                                                                              overflow:
                                                                                  'hidden',
                                                                              whiteSpace:
                                                                                  'nowrap',
                                                                              width: '100%',
                                                                              textAlign:
                                                                                  'center',
                                                                          }}
                                                                      >
                                                                          {
                                                                              item.document_url
                                                                          }
                                                                      </span>
                                                                  </ImageWrapper>
                                                              ) : (
                                                                  <ImageSection
                                                                      src={
                                                                          item?.document_url
                                                                      }
                                                                      alt="image-section"
                                                                  />
                                                              )}
                                                          </ImageWrapper>
                                                      </div>
                                                  </div>
                                              )}

                                        {errorEditUpload?.status && (
                                            <div className="mt-2">
                                                <p
                                                    className="mb-0 text-danger"
                                                    style={{ fontSize: 12 }}
                                                >
                                                    {errorEditUpload?.message}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="">
                                        <p className="mb-0" style={{ fontSize: 14 }}>
                                            {item?.comment.split('\n').map((str, key) => (
                                                <span key={key}>
                                                    {str}
                                                    <br />
                                                </span>
                                            ))}
                                        </p>
                                        {item?.document_url !== null && (
                                            <div className="row gx-0">
                                                <div className="col-md-6 col-lg-3 col-xl-2">
                                                    <div className="mt-2">
                                                        <ImageWrapper
                                                            className="rounded-3"
                                                            style={{
                                                                height: 100,
                                                                border: 'none',
                                                            }}
                                                        >
                                                            {item?.document_url
                                                                .split('.')
                                                                .pop() === 'pdf' ? (
                                                                <ImageWrapper
                                                                    className="d-flex flex-column justify-content-center align-items-center p-1 rounded-3"
                                                                    role="button"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            item?.document_url,
                                                                            '_blank',
                                                                        );
                                                                    }}
                                                                >
                                                                    <AiOutlineFilePdf
                                                                        size={50}
                                                                        color="#9b9b9b"
                                                                    />
                                                                    <span
                                                                        style={{
                                                                            fontSize: 12,
                                                                            color: '#9b9b9b',
                                                                            textOverflow:
                                                                                'ellipsis',
                                                                            overflow:
                                                                                'hidden',
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                            width: '100%',
                                                                            textAlign:
                                                                                'center',
                                                                        }}
                                                                    >
                                                                        {
                                                                            item.document_url
                                                                        }
                                                                    </span>
                                                                </ImageWrapper>
                                                            ) : (
                                                                <ImageSection
                                                                    src={
                                                                        item?.document_url
                                                                    }
                                                                    role="button"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            item?.document_url,
                                                                            '_blank',
                                                                        );
                                                                    }}
                                                                    alt="image-section"
                                                                />
                                                            )}
                                                        </ImageWrapper>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        ),
                    )}
                    <div ref={bottomRef} />
                </div>
            )}

            {selectedTask?.deleted_at === null && (
                <div className="row gx-0 mb-5">
                    <div className="col-md-12">
                        <InputAreaNew
                            id="taskDescription"
                            placeholder="Add Comment"
                            onKeyDown={e => handleSubmitComment(e, false)}
                            onChange={e => setInputState(e.target.value)}
                            maxLength={2000}
                            outerClassName=""
                            value={inputState}
                        />
                    </div>
                    <div
                        className="col-md-6 col-lg-3 col-xl-2 text-center my-2 me-2"
                        style={{
                            height: 100,
                        }}
                    >
                        <ImageWrapper
                            className="d-flex flex-column align-items-center justify-content-center p-3 rounded-3"
                            role="button"
                            tabIndex={0}
                            onClick={() => uploadRef?.current?.click()}
                        >
                            <AiOutlineUpload size={18} color="#9b9b9b" />
                            <span
                                style={{
                                    fontSize: 12,
                                    color: '#9b9b9b',
                                }}
                            >
                                Max 4.5MB
                            </span>
                        </ImageWrapper>
                        <input
                            type="file"
                            hidden
                            ref={uploadRef}
                            // multiple
                            accept="image/* , application/pdf"
                            onChange={e => handleUploadImage(e)}
                        />
                    </div>
                    {inputImage?.length > 0 &&
                        inputImage?.map((item, key) => (
                            <div
                                key={key}
                                className="col-md-6 col-lg-3 col-xl-2 my-2 me-2"
                                style={{
                                    height: 100,
                                }}
                            >
                                {item.type === 'application/pdf' ? (
                                    <ImageWrapper className="d-flex flex-column justify-content-center align-items-center p-1 rounded-3">
                                        <AiOutlineFilePdf size={50} color="#9b9b9b" />
                                        <span
                                            style={{
                                                fontSize: 12,
                                                color: '#9b9b9b',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                width: '100%',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {item.name}
                                        </span>
                                    </ImageWrapper>
                                ) : (
                                    <ImageWrapper className="p-1 rounded-3">
                                        <ImageSection
                                            src={URL.createObjectURL(item)}
                                            alt="image-section"
                                        />
                                    </ImageWrapper>
                                )}
                            </div>
                        ))}
                    {errorUpload?.status && (
                        <div className="mt-2">
                            <p className="mb-0 text-danger" style={{ fontSize: 12 }}>
                                {errorUpload?.message}
                            </p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

const AssigneeAvatar = styled.img`
    width: 30px;
    height: 30px;

    border-radius: 50%;
    border: 1px solid #fff;
`;

const ImageWrapper = styled.div`
    border: 1px dashed #9b9b9b;
    height: 100%;

    &:hover {
        border: 1px dashed #000;
    }
`;

const ImageSection = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
`;

const CustomTextArea = styled.textarea`
    &:focus {
        outline: none;
        box-shadow: none;
    }
`;
