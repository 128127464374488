import subscriptionImage1 from '@/assets/images/subscription/help-subscription-image-1.webp';
import subscriptionImage5 from '@/assets/images/subscription/help-subscription-image-5.webp';

import { Fragment } from 'react';

export default function HowToRenewSubscription(): JSX.Element {
    const contents = [
        {
            id: 1,
            text: 'In the active subscription section of the subscription information section, you can click the renew button, then the page will change.',
            alt: 'loginPage',
            image: subscriptionImage1,
        },
        {
            id: 2,
            text: 'On this page you are asked to choose your payment term in making a subscription.',
            alt: '',
            image: subscriptionImage5,
        },
    ];

    return (
        <Fragment>
            {contents.map((content, index) => (
                <div key={index} className="text-grey-300 mb-10">
                    <img src={content.image} alt={content.alt} className="img-fluid" />
                    <p className="mt-4 pl-2">{content.text}</p>
                </div>
            ))}
        </Fragment>
    );
}
