import subscriptionImage1 from '@/assets/images/subscription/help-subscription-image-1.webp';

import { Fragment } from 'react';

export default function WhatFeaturesSubscription(): JSX.Element {
    const contents = [
        {
            id: 1,
            text: 'There are 2 sections on the subscription menu: Active Subscriptions and History Subscriptions',
            alt: 'subscriptionPage',
            image: subscriptionImage1,
        },
        {
            id: 2,
            text: 'Active Subscription contains the status of your current subscription, as well as detailed information such as the number of users, subscription expiration date and next payment date, this section also contains information on registered credit cards, as well as information about the card, such as card number, expiration date and cvv number.In this section there is also information regarding the estimated costs that will be charged in the next period and there is a column for entering coupons if you have them.',
            alt: 'subscriptionPage',
            image: null,
        },
        {
            id: 2,
            text: 'Subscription History contains a list of past subscriptions up to your current subscription, here it also displays information such as total users, subscription prices and subscription payment status.',
            alt: 'subscriptionPage',
            image: null,
        },
    ];

    return (
        <Fragment>
            {contents.map((content, index) => (
                <div key={index} className="text-grey-300 mb-10">
                    {content.image != null && (
                        <img
                            src={content.image}
                            alt={content.alt}
                            className="img-fluid"
                        />
                    )}
                    <p className="mt-4 pl-2">{content.text}</p>
                </div>
            ))}
        </Fragment>
    );
}
