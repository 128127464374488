import { useState } from 'react';

import HelpAuthentication from '../components/HelpAuthentication';
import HelpChat from '../components/HelpChat';
import HelpDirectory from '../components/HelpDirectory';
import HelpDocumentsNDrawings from '../components/HelpDocumentsNDrawings';
import HelpProject from '../components/HelpProject';
import HelpSchedule from '../components/HelpSchedule';
import HelpSubscription from '../components/HelpSubscription';
import { Title } from '@/components/molecules/Typography';
import styled from 'styled-components';

export const helpLists = [
    {
        id: 1,
        title: 'Authentication',
        description: 'Sign up or login',
        page: <HelpAuthentication />,
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className=""
                width={24}
                height={24}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                />
            </svg>
        ),
    },
    {
        id: 2,
        title: 'Subscription',
        description: 'Subscribe to a plan',
        page: <HelpSubscription />,
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                width={24}
                height={24}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                />
            </svg>
        ),
    },
    {
        id: 3,
        title: 'Project',
        description: 'Manage your team with project',
        page: <HelpProject />,
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                width={24}
                height={24}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                />
            </svg>
        ),
    },
    {
        id: 4,
        title: 'Schedule',
        description: 'Help your team to manage their task',
        page: <HelpSchedule />,
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                width={24}
                height={24}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                />
            </svg>
        ),
    },
    {
        id: 5,
        title: "Document's & Drawing's",
        description: "Quickly access your team's files",
        page: <HelpDocumentsNDrawings />,
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                width={24}
                height={24}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                />
            </svg>
        ),
    },
    {
        id: 6,
        title: 'Directory',
        description: "Collect your team's contact",
        page: <HelpDirectory />,
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                width={24}
                height={24}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                />
            </svg>
        ),
    },
    {
        id: 7,
        title: 'Chat & Messaging',
        description: 'Easily communicate with your team',
        page: <HelpChat />,
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                width={24}
                height={24}
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
                />
            </svg>
        ),
    },
];

export default function Help(): JSX.Element {
    const [selectedList, setSelectedList] = useState(1);

    return (
        <div className="row">
            <div className="col-lg-4">
                <ListWrapper className="">
                    <Title text="Help" />
                    <div className="list-wrapper mt-5">
                        {helpLists.map(list => (
                            <div
                                key={list.id}
                                className={`d-flex align-items-center mb-3 list-item ${
                                    selectedList === list.id ? 'list-active' : ''
                                }`}
                                role="button"
                                onClick={() => setSelectedList(list.id)}
                            >
                                <div
                                    className={`rounded-circle d-flex text-white align-items-center justify-content-center
                                                ${
                                                    selectedList === list.id
                                                        ? 'bg-primary'
                                                        : 'bg-secondary'
                                                }`}
                                    style={{
                                        width: 60,
                                        height: 60,
                                    }}
                                >
                                    {list.icon}
                                </div>

                                <div
                                    className={`ms-2 list-text ${
                                        selectedList === list.id
                                            ? 'text-primary'
                                            : 'text-secondary'
                                    }`}
                                >
                                    <h6 className="mb-0">{list.title}</h6>
                                    <span
                                        className="list-description"
                                        style={{
                                            fontSize: 14,
                                        }}
                                    >
                                        {list.description}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </ListWrapper>
            </div>
            <div className="col-lg-8 mt-5 overflow-scroll">
                {/* accordion start  */}
                {helpLists.find(list => list.id === selectedList)?.page}
                {/* accordion end  */}
            </div>
            {/* Section Joins */}
            {/* End Section Join */}
        </div>
    );
}

const ListWrapper = styled.div`
    position: fixed;

    .list-description {
        // break text
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-width: 10rem;
    }

    .list-item {
        cursor: pointer;
        padding: 1rem;
        &:hover {
            border-radius: 20px;
            background-color: #edecfe;
        }
    }

    .list-active {
        border-radius: 20px;
        background-color: #edecfe;
    }

    .list-wrapper {
        overflow-y: scroll;
        max-height: 80vh;
    }

    @media (max-width: 991px) {
        position: relative;

        .list-wrapper {
            display: flex;
            overflow-y: scroll;
            padding-bottom: 10px;
            align-items: center;

            .list-text {
                width: 10rem;
            }
        }
    }
`;
