import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getCredential } from '@/utility/Utils';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';

/**
 * Function to handle private routes
 *
 * @param string title
 * @param JSX.Element children
 * @returns
 */
export const PrivateRoute = ({
    title,
    children,
    roles,
}: {
    title?: string;
    children: JSX.Element;
    roles?: string[];
}): any => {
    const checkToken: boolean = getCredential() !== null;
    const currentProfileState = useSelector((state: RootState) => state.currentProfile);

    const location = useLocation();

    useEffect(() => {
        document.title = `${title ?? 'Home'} - Constructapp`;
    }, [title]);

    if (checkToken) {
        if (roles !== undefined) {
            const userRole = currentProfileState?.response?.role?.name;
            if (currentProfileState?.response !== null && !roles.includes(userRole)) {
                return <Navigate to="/not-found" />;
            }
        }

        return children;
    }

    if (location.pathname !== undefined) {
        sessionStorage.setItem('redirectUrl', location.pathname + location.search);
    }

    return <Navigate to="/login" />;
};

/**
 * Function to handle public routes
 *
 * @param string title
 * @param JSX.Element children
 * @returns
 */
export const PublicRoute = ({
    title,
    children,
}: {
    title?: string;
    children: JSX.Element;
}): any => {
    useEffect(() => {
        document.title = `${title ?? 'Home'} - Constructapp`;
    }, [title]);

    const checkToken: boolean = getCredential() !== null;

    if (!checkToken) {
        return children;
    }

    return <Navigate to="/" />;
};

/**
 * Function to handle stepper routes
 *
 * @param string title
 * @param JSX.Element children
 * @returns
 */
export const StepperRoute = ({
    title,
    children,
}: {
    title?: string;
    children: JSX.Element;
}): JSX.Element => {
    const location = useLocation();
    const { state } = useLocation();

    useEffect(() => {
        document.title = `${title ?? 'Home'} - Constructapp`;
    }, [title]);

    const firstUrl = location.pathname.split('/')[1];

    if (state?.nextStep !== true) {
        return <Navigate to={`/${firstUrl}`} />;
    }

    return children;
};

/**
 * Global function to handle user authentication or not it will show the page
 *
 * @param string title
 * @param JSX.Element children
 * @returns
 */
export const GlobalRoute = ({
    title,
    children,
}: {
    title?: string;
    children: JSX.Element;
}): JSX.Element => {
    useEffect(() => {
        document.title = `${title ?? 'Home'} - Constructapp`;
    }, [title]);

    return children;
};
