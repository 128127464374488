import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import styled from 'styled-components';
import { onlyNumber } from '@/utility/Utils';

interface InputTimeProps {
    id?: string;
    label?: string;
    hoursValue?: number;
    minutesValue?: number;
    withTooltip?: string;
    onChangeMinutes?: (val: any) => any;
    onChangeHours?: (val: any) => any;
}

/**
 * Input time component boilerplate.
 *
 * @param props
 * @constructor
 */
export default function InputTime(props: InputTimeProps): JSX.Element {
    const {
        id,
        label,
        hoursValue,
        minutesValue,
        withTooltip,
        onChangeHours,
        onChangeMinutes,
    } = props;

    return (
        <>
            <InputWrapper className="d-flex align-items-center mb-3">
                <Label htmlFor="label" className="me-2">
                    {label}
                </Label>
                {withTooltip != null && (
                    <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="top-tooltip-2">{withTooltip}</Tooltip>}
                    >
                        {({ ref, ...triggerHandler }) => (
                            <>
                                <span ref={ref}>
                                    <AiOutlineInfoCircle
                                        color="rgba(86, 72, 251, 1)"
                                        size={18}
                                        {...triggerHandler}
                                    />
                                </span>
                            </>
                        )}
                    </OverlayTrigger>
                )}
            </InputWrapper>
            <DivWrapper className="row mb-3">
                <div className="col-6 col-md-4 col-lg-2 mb-3">
                    <Label htmlFor="label" className="mb-2">
                        Hours
                    </Label>
                    <Input
                        id={id}
                        type="text"
                        className="form-control"
                        value={hoursValue}
                        onKeyDown={onlyNumber}
                        onChange={onChangeHours}
                        maxLength={3}
                    />
                </div>
                <div className="col-6 col-md-4 col-lg-2 mb-3">
                    <Label htmlFor="label" className="mb-2">
                        Minutes
                    </Label>
                    <Input
                        id={id}
                        type="text"
                        className="form-control"
                        value={minutesValue}
                        maxLength={2}
                        onChange={onChangeMinutes}
                    />
                </div>
            </DivWrapper>
        </>
    );
}

const Input = styled.input`
    border-radius: 100px;
    height: 45px;
    width: 75px;
    &:focus {
        box-shadow: none;
    }
    padding: 0 1.5rem;
    background-color: transparent;
`;

const Label = styled.label`
    font-size: 14px;
    font-weight: 500;
`;

const InputWrapper = styled.div`
    .tooltip-inner {
        background: red !important;
    }
`;

const DivWrapper = styled.div`
    @media (min-width: 992px) and (max-width: 1190px) {
        .col-lg-2 {
            width: 25.667%;
        }
    }
`;
