import { axiosClient } from '@/services/GatewayService';
import { AppDispatch } from '@/redux/store';

import {
    ALL_COMPANY_REQUEST,
    ALL_COMPANY_SUCCESS,
    ALL_COMPANY_FAIL,
    ALL_COMPANY_MAP_REQUEST,
    ALL_COMPANY_MAP_SUCCESS,
    ALL_COMPANY_MAP_FAIL,
    DETAIL_COMPANY_REQUEST,
    DETAIL_COMPANY_SUCCESS,
    DETAIL_COMPANY_FAIL,
    DETAIL_COMPANY_RESET,
    CLEAR_STATE,
} from '../reducers';

/**
 * Function to get all company
 *
 * @param number params.page - page of company
 * @param number params.per_page - number of company per page
 * @param string params.search - search of company
 * @param string params.sort_by - sort by of company
 * @param number params.sort_asc - sort asc of company
 * @return Promise<void>
 */
export const getAllCompany = (params: {
    page: number;
    per_page: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
    vic_distance?: string;
    company_id?: string;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_COMPANY_REQUEST });
        try {
            const res = await axiosClient.get('directory/companies', {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: ALL_COMPANY_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_COMPANY_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all company maps
 *
 * @param number params.page - page of company
 * @param number params.per_page - number of company per page
 * @param string params.search - search of company
 * @param string params.sort_by - sort by of company
 * @param number params.sort_asc - sort asc of company
 * @return Promise<void>
 */
export const getAllCompanyMap = (params: {
    page: number;
    per_page: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
    vic_distance?: string;
    company_id?: string;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_COMPANY_MAP_REQUEST });
        try {
            const res = await axiosClient.get('directory/companies', {
                params: {
                    ...params,
                    pagination: 0,
                },
            });

            dispatch({
                type: ALL_COMPANY_MAP_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_COMPANY_MAP_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get detail of company
 *
 *
 * @param number params.id - id of company
 * @return Promise<void>
 */
export const getDetailCompany = (id: number): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_COMPANY_REQUEST });
        try {
            const res = await axiosClient.get('directory/companies', {
                params: {
                    id,
                },
            });

            dispatch({
                type: DETAIL_COMPANY_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DETAIL_COMPANY_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to clear state of company
 *
 * @returns Promise<void>
 */
export const clearStateCompany = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CLEAR_STATE });
    };
};

export const clearDetailCompany = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_COMPANY_RESET });
    };
};
