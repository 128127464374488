import CurrencyInput from 'react-currency-input-field';
import styled from 'styled-components';

import { FaStarOfLife } from 'react-icons/fa';

interface ParamsProps {
    id?: string;
    type?: string;
    placeholder?: string;
    outerClassName?: string;
    innerClassName?: string;
    icon?: JSX.Element | string;
    isRequired?: boolean;
    value?: number;
    name?: string;
    disabled?: boolean;
    maxLength?: number;
    minLength?: number;
    rules?: {
        function: (name: any, rules: any) => any;
        name: string;
        rules: any;
        errors?: any;
    };
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    autoComplete?: string;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    format?: {
        locale: string;
        currency: string;
    };
    decimalsLimit?: number;
    prefix?: string;
    suffix?: string;
    onValueChange?: (value: number | undefined) => void;
}

export default function InputCurrency(props: ParamsProps): JSX.Element {
    const {
        id,
        placeholder,
        outerClassName,
        innerClassName,
        value,
        disabled,
        rules,
        format,
        decimalsLimit,
        prefix,
        suffix,
        onValueChange,
    } = props;

    return (
        <div className={`input-group ${outerClassName ?? 'mb-3'}`}>
            <InputWrapper
                className={`form-floating ${innerClassName ?? ''}`}
                errors={rules?.errors[rules?.name] !== undefined}
            >
                <Input
                    id={id}
                    name="input-name"
                    placeholder={placeholder}
                    defaultValue={value}
                    decimalsLimit={decimalsLimit ?? 2}
                    className="form-control"
                    intlConfig={format}
                    prefix={prefix}
                    suffix={suffix}
                    disabled={disabled}
                    onValueChange={onValueChange}
                    {...rules?.function(rules?.name, rules?.rules)}
                    style={{
                        // borderBottom:
                        //     rules?.errors[rules?.name] !== undefined
                        //         ? '1px solid #FB6056'
                        //         : '1px solid #cfcfcf',
                        '&:focus': {
                            borderBottom:
                                rules?.errors[rules?.name] !== undefined
                                    ? '1px solid #FB6056'
                                    : '1px solid #5648fb',
                        },
                    }}
                />
                <LabelWithoutIcon
                    htmlFor={`htmlFor${id ?? 'input'}`}
                    errors={rules?.errors[rules?.name] !== undefined}
                    className="label-active"
                >
                    {placeholder}
                    {rules?.rules?.required !== undefined && (
                        <FaStarOfLife
                            color="#FB6056"
                            size={7}
                            style={{
                                position: 'relative',
                                top: -6,
                                left: 3,
                            }}
                        />
                    )}
                </LabelWithoutIcon>
                {rules?.errors[rules?.name] !== undefined && (
                    <span
                        className="invalid-feedback d-block text-start"
                        style={{
                            fontSize: '0.75rem',
                        }}
                    >
                        {rules?.errors[rules?.name].message}
                    </span>
                )}
            </InputWrapper>
        </div>
    );
}

const LabelWithoutIcon = styled.label<{ errors?: boolean }>`
    padding-left: 0 !important;
    color: #838383;
    top: -2px !important;

    &:focus {
        box-shadow: none;
        border-bottom: 1px solid
            ${props => (props.errors ?? false ? '#FB6056' : '#5648fb')};
        background-color: transparent;
    }

    .form-control:focus + & {
        top: -10px !important;
        font-size: 12px !important;
    }

    .form-control:not(:placeholder-shown) + & {
        top: -10px !important;
        font-size: 12px !important;
    }
`;

const InputWrapper = styled.div<{ errors?: boolean }>`
    &:focus-within {
        .icon-active {
            background-color: ${props => (props.errors ?? false ? '#FB6056' : '#5648fb')};
        }

        .label-active {
            color: ${props => (props.errors ?? false ? '#FB6056' : '#5648fb')};
        }
    }
`;

const Input = styled(CurrencyInput)`
    border: none;
    border-bottom: 1px solid #cfcfcf;
    background-color: transparent;
    border-radius: 0;
    margin-top: 10px;
    padding: 0 0 10px 5px !important;
    height: 45px !important;

    &:focus {
        box-shadow: none;
        background-color: transparent;
    }

    &:disabled {
        background-color: transparent;
        color: #cfcfcf;
    }

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px #ffff inset;
    }
`;
