import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';
interface LinkBackProps {
    text: string;
}

export default function LinkBack(props: LinkBackProps): JSX.Element {
    const { text } = props;
    const navigate = useNavigate();

    const [show, setShow] = useState(false);

    const handleRedirect = (): void => {
        // clear storage
        sessionStorage.clear();

        navigate('/login');
    };
    return (
        <>
            <Link
                to="#"
                className="text-decoration-none"
                style={{ fontSize: '0.95rem' }}
                onClick={() => setShow(true)}
            >
                {text}
            </Link>
            <ModalDashboard
                modal={show}
                setModal={setShow}
                variant="danger"
                type="back"
                title="Back to Home?"
                body="Are you sure want to back to home screen?"
                withCancel
                onConfirm={handleRedirect}
            />
        </>
    );
}
