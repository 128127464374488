import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { RootState } from '@/redux/store';

import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';

import {
    clearStateLeaveProject,
    leaveProject,
} from '@/routes/Dashboard/Project/redux/actions';

import { getCurrentProfile } from '@/routes/Dashboard/Setting/PersonalSetting/redux/actions';
import AdvertisementBanner from '@/components/molecules/AdvertisementBanner';
import NotificationHistoryBanner from '@/components/molecules/NotificationHistoryBanner';
import ProjectBanner from '@/components/molecules/ProjectBanner';
import styled from 'styled-components';

export default function Summary(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const projectId: string = id ?? '';

    const leaveProjectState = useSelector((state: RootState) => state.leaveProject);
    const [modal, setModal] = useState({
        success: false,
        leave: false,
    });
    const detailProjectState = useSelector((state: RootState) => state.detailProject);

    // Function to handle modal, when pin or unpin project
    useEffect(() => {
        if (leaveProjectState?.status === 200) {
            dispatch(getCurrentProfile());

            dispatch(clearStateLeaveProject());

            setModal(prev => ({
                ...prev,
                leave: false,
                success: true,
            }));
        }
    }, [leaveProjectState]);

    /**
     * Function to handle leave project
     *
     * @param number id
     * @return void
     */
    const handleLeaveProject = (id: number): void => {
        dispatch(leaveProject(id));
    };
    return (
        <WrapperRow>
            {detailProjectState?.response !== undefined &&
                detailProjectState?.response?.upperline === null && (
                    <div className="col-md-12 col-xl-8 px-2 px-xl-5 mt-5">
                        <span
                            className="badge p-2 mb-3 bg-danger rounded fw-semibold"
                            style={{ fontSize: 12 }}
                        >
                            Your project upper-line has left the project. Do you want to
                            leave?
                            <Link
                                to="#"
                                className="link-info fw-semibold text-decoration-none"
                                onClick={() =>
                                    setModal(prev => ({
                                        ...prev,
                                        leave: true,
                                    }))
                                }
                            >
                                &nbsp;leave now
                            </Link>
                        </span>
                    </div>
                )}
            <div className="row equal-cols mt-2">
                <div className="col-md-12 col-xl-8 px-2 px-xl-5 mt-3">
                    <AdvertisementBanner />
                </div>
                <div className="col-md-12 col-xl-4 px-2 px-xl-5 mt-4">
                    <ProjectBanner />
                    <NotificationHistoryBanner />
                </div>
                <ModalDashboard
                    modal={modal.leave}
                    setModal={setModal}
                    variant="danger"
                    type="leave"
                    title="Leave Project?"
                    body={`Are you sure want to leave from ${
                        detailProjectState?.response?.name as string
                    } project?`}
                    withCancel
                    onConfirm={() => handleLeaveProject(parseInt(projectId))}
                    loading={leaveProjectState?.loading}
                />
                <ModalDashboard
                    modal={modal.success}
                    setModal={setModal}
                    variant="success"
                    type="success"
                    title="Success!"
                    body={'Successfully leave project'}
                    onConfirm={() => {
                        setModal({ ...modal, success: false });
                        navigate('/');
                    }}
                />
            </div>
        </WrapperRow>
    );
}

const WrapperRow = styled.div`
    .row.equal-cols {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .row.equal-cols:before,
    .row.equal-cols:after {
        display: block;
    }

    .row.equal-cols > [class*='col-'] {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .row.equal-cols > [class*='col-'] > * {
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    }

    @media (min-width: 1200px) and (max-width: 1800px) {
        .px-xl-5 {
            padding: 0 15px !important;
        }
    }
`;
