import { Modal, Tab, Tabs } from 'react-bootstrap';

import styled from 'styled-components';

import { ArchiveTaskProps } from '../../interfaces';

import { AiOutlineLock } from 'react-icons/ai';
import ScheduleTable from '../ScheduleTable';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { useCallback, useEffect, useState } from 'react';
import { getAllArchiveTask } from '../../redux/actions';
import { useAppDispatch } from '@/redux/hook';

export default function ArchiveTask({
    modal,
    setModal,
    projectId,
    setModalType,
    type,
    handleOpenModalRestore,
    handleOpenModalDelete,
    handleDetailTask,
    permissions,
}: ArchiveTaskProps): JSX.Element {
    const dispatch = useAppDispatch();
    const allTaskArchiveState = useSelector((state: RootState) => state.allTaskArchive);

    const [params, setParams] = useState({
        project_id: projectId,
        search: '',
        type,
        page: 1,
        per_page: 10,
    });

    const tabs = [
        {
            key: 'design',
            title: 'Design',
        },
        {
            key: 'construction',
            title: 'Construction',
        },
        {
            key: 'private',
            title: (
                <>
                    <AiOutlineLock className="me-2" />
                    <span>Private</span>
                </>
            ),
        },
    ];

    const [selectedTab, setSelectedTab] = useState('');
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        setSelectedTab(type);
    }, [type]);

    const handleSelectedTab = (tab: string): void => {
        setSelectedTab(tab);

        dispatch(
            getAllArchiveTask({
                ...params,
                type: tab,
                page: 1,
            }),
        );
    };

    /**
     * Function to handle search
     *
     * @param string value
     * @returns void
     */
    const handleSearch = useCallback((value: string): void => {
        setSearchValue(value);
    }, []);

    /**
     * Function to handle debounce search
     *
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleDebouncedSearch = useCallback(() => {
        const payload = {
            ...params,
            search: searchValue,
            page: 1,
            type: selectedTab,
        };

        setParams(payload);

        if (selectedTab !== '') {
            dispatch(getAllArchiveTask(payload));
        }
    }, [searchValue]);

    // Function for handle debounce search
    useEffect(() => {
        const debounce = setTimeout(() => {
            handleDebouncedSearch();
        }, 500);

        return () => {
            clearTimeout(debounce);
        };
    }, [handleDebouncedSearch]);
    /**
     * Function to handle pagination
     *
     * @param number page
     * @param string type
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handlePagination = (page: number, type: string): void => {
        if (type === 'page') {
            setParams({
                ...params,
                page,
                type: selectedTab,
            });

            const payload = {
                ...params,
                page,
                type: selectedTab,
            };

            dispatch(getAllArchiveTask(payload));
        }

        if (type === 'next') {
            setParams({
                ...params,
                page: params.page + 1,
                type: selectedTab,
            });

            const payload = {
                ...params,
                page: params.page + 1,
                type: selectedTab,
            };

            dispatch(getAllArchiveTask(payload));
        }

        if (type === 'prev') {
            setParams({
                ...params,
                page: params.page - 1,
                type: selectedTab,
            });

            const payload = {
                ...params,
                page: params.page - 1,
                type: selectedTab,
            };

            dispatch(getAllArchiveTask(payload));
        }
    };

    return (
        <>
            <ModalWrapper
                show={modal.archiveTask}
                onHide={() => setModal(prev => ({ ...prev, archiveTask: false }))}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header
                    closeButton
                    style={{
                        padding: '1.5rem 2.3rem',
                    }}
                >
                    <h6 className="fw-semibold mb-0">Archived Tasks</h6>
                </Modal.Header>
                <Modal.Body className="py-0 px-3">
                    <WrapperTabs
                        id="uncontrolled-tab-example"
                        activeKey={selectedTab}
                        onSelect={(tab: any) => handleSelectedTab(tab)}
                        className="mt-0"
                        justify
                    >
                        {tabs.map(tab => (
                            <Tab eventKey={tab.key} title={tab.title} key={tab.key}>
                                <div className="p-3">
                                    <ScheduleTable
                                        state={allTaskArchiveState}
                                        handlePagination={handlePagination}
                                        handleSearch={handleSearch}
                                        handleOpenModalRestore={handleOpenModalRestore}
                                        handleOpenModalDelete={handleOpenModalDelete}
                                        handleDetailTask={handleDetailTask}
                                        permissions={permissions}
                                    />
                                </div>
                            </Tab>
                        ))}
                    </WrapperTabs>
                    <div className="d-flex justify-content-between px-3 pb-4">
                        <ButtonBasic
                            id="btnSubmit"
                            text="Close"
                            type="button"
                            className="btn btn-outline-primary btn-sm"
                            onClick={() =>
                                setModal(prev => ({
                                    ...prev,
                                    archiveTask: false,
                                }))
                            }
                        />
                    </div>
                </Modal.Body>
            </ModalWrapper>
        </>
    );
}

const ModalWrapper = styled(Modal)``;

const WrapperTabs = styled(Tabs)`
    border-bottom: 0 !important;

    .nav-link {
        border: none;
        border-bottom: 2px solid rgb(230, 230, 230);
        background: none !important;
        color: rgba(207, 207, 207, 1);
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        padding-left: 0;
        padding-right: 0;
        padding: 1rem 1.5rem;
        &.active {
            color: #5648fb !important;
            border-bottom: 2px solid #5648fb;
        }
    }
`;
