import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';

import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';

import {
    getActiveInhouse,
    getInactiveInhouse,
    activatedInhouse,
    getDetailInhouse,
    clearDetailInhouse,
    clearActivatedTerminateInhouse,
} from '../../redux/actions';

import { useNavigate } from 'react-router-dom';
import { RootState } from '@/redux/store';

import { kickMemberProject } from '@/routes/ProjectDashboard/Settings/redux/actions';
import { generateInitialImageUrl } from '@/utility/Utils';
import TableBorderless from '@/components/organism/TableBorderless';

export default function InactiveInhouse(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const inactiveInhouseState = useSelector(
        (state: RootState) => state.allInactiveInhouse,
    );
    const activatedInhouseState = useSelector(
        (state: RootState) => state.activatedInhouse,
    );
    const detailInhouseState = useSelector((state: RootState) => state.detailInhouse);
    const kickMemberProjectState = useSelector(
        (state: RootState) => state.kickMemberProject,
    );

    const [searchValue, setSearchValue] = useState('');
    const [selectedInactiveInhouse, setSelectedInactiveInhouse] = useState<[]>([]);
    const [modal, setModal] = useState({
        caution: false,
        success: false,
        removeUserProject: false,
        successRemoveUserProject: false,
    });
    const [params, setParams] = useState({
        page: 1,
        per_page: 10,
        search: '',
        sort_by: 'name',
        sort_asc: 1,
    });

    const [detailProject, setDetailProject] = useState({
        id: '',
        project_name: '',
        user_name: '',
        email: '',
    });

    const tableItems = [
        {
            title: 'Name',
            Cell: (row: { name: string; avatar: string; email: string }) => (
                <div className="d-flex align-items-center">
                    <div className="me-3">
                        <img
                            src={row.avatar ?? generateInitialImageUrl(row.name)}
                            alt="cover"
                            style={{
                                width: 40,
                                height: 40,
                                borderRadius: 100,
                                objectFit: 'cover',
                            }}
                        />
                    </div>
                    <div>
                        <p className="mb-0">{row.name}</p>
                        <p
                            className="text-muted mb-0"
                            style={{
                                fontSize: 12,
                            }}
                        >
                            {row.email}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Company',
            selector: '',
            Cell: (row: any) => <span>{row?.company_name}</span>,
        },
        {
            title: 'Phone Number',
            selector: 'phone',
        },
    ];

    /**
     * Function to handle search
     *
     * @param string value
     * @returns void
     */
    const handleSearch = useCallback((value: string): void => {
        setSearchValue(value);
    }, []);

    /**
     * Function to handle debounce search
     *
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleDebouncedSearch = useCallback(() => {
        setParams({
            ...params,
            search: searchValue,
            page: 1,
        });

        const payload = {
            ...params,
            search: searchValue,
            page: 1,
        };

        dispatch(getInactiveInhouse(payload));
    }, [searchValue]);

    /**
     * Function to handle pagination
     *
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleActivated = (): void => {
        const payload = selectedInactiveInhouse.map((item: any) => item.id);

        dispatch(activatedInhouse(payload.toString()));
    };

    /**
     * Function to handle pagination
     *
     * @param number page
     * @param string type
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handlePagination = (page: number, type: string): void => {
        setSelectedInactiveInhouse([]);
        if (type === 'page') {
            setParams({
                ...params,
                page,
            });

            const payload = {
                ...params,
                page,
            };

            dispatch(getInactiveInhouse(payload));
        }

        if (type === 'next') {
            setParams({
                ...params,
                page: params.page + 1,
            });

            const payload = {
                ...params,
                page: params.page + 1,
            };

            dispatch(getInactiveInhouse(payload));
        }

        if (type === 'prev') {
            setParams({
                ...params,
                page: params.page - 1,
            });

            const payload = {
                ...params,
                page: params.page - 1,
            };

            dispatch(getInactiveInhouse(payload));
        }
    };

    // Function for reset state when component unmount
    useEffect(() => {
        return () => {
            setSelectedInactiveInhouse([]);
            dispatch(clearDetailInhouse());
        };
    }, []);

    // Function for handle if activated inhouse is clicked then get detail
    useEffect(() => {
        if (selectedInactiveInhouse.length > 0) {
            const payload = selectedInactiveInhouse.map((item: any) => item.id).pop();

            dispatch(getDetailInhouse(payload.toString()));
        }

        if (selectedInactiveInhouse.length === 0) {
            dispatch(clearDetailInhouse());
        }
    }, [selectedInactiveInhouse]);

    // Function for handle if activated inhouse success, then refresh data
    useEffect(() => {
        if (activatedInhouseState?.status === 200) {
            setModal(prev => ({
                ...prev,
                caution: false,
            }));

            setTimeout(() => {
                setSelectedInactiveInhouse([]);
                dispatch(clearActivatedTerminateInhouse());
                dispatch(getInactiveInhouse({ ...params, page: 1 }));
                dispatch(getActiveInhouse({ ...params, page: 1 }));

                setModal(prev => ({
                    ...prev,
                    success: true,
                }));
            }, 1000);
        }
    }, [activatedInhouseState]);

    // Function for handle debounce search
    useEffect(() => {
        const debounce = setTimeout(() => {
            handleDebouncedSearch();
        }, 500);

        return () => {
            clearTimeout(debounce);
        };
    }, [handleDebouncedSearch]);

    /**
     * Function to handle open modal remove user from project
     *
     * @param projectDetail Object
     * @returns void
     */
    const handleOpenModalRemove = (projectDetail: { id: string; name: string }): void => {
        setDetailProject({
            id: projectDetail.id,
            project_name: projectDetail.name,
            user_name: detailInhouseState?.response?.name,
            email: detailInhouseState?.response?.email,
        });

        setModal((prevState: any) => ({
            ...prevState,
            removeUserProject: true,
        }));
    };

    /**
     * Function to handle remove user from projects
     *
     * @returns void
     */
    const handleRemoveUserFromProject = (): void => {
        dispatch(
            kickMemberProject(detailProject.id, detailInhouseState?.response?.email),
        );
    };

    // If success kick member project
    useEffect(() => {
        if (kickMemberProjectState?.status === 200) {
            setModal((prev: any) => ({
                ...prev,
                removeUserProject: false,
            }));
            dispatch(getDetailInhouse(detailInhouseState?.response?.id));
        }
    }, [kickMemberProjectState]);

    return (
        <>
            <TableBorderless
                withoutBorder
                header={tableItems}
                items={inactiveInhouseState?.response ?? []}
                handleSearch={handleSearch}
                loading={inactiveInhouseState?.loading}
                handleAction={() => setModal(prev => ({ ...prev, caution: true }))}
                moduleType="Terminated Inhouse"
                actionTitle="Activate"
                selected={selectedInactiveInhouse}
                setSelected={setSelectedInactiveInhouse}
                detailsState={detailInhouseState?.response ?? null}
                detailsLoading={detailInhouseState?.loading}
                handlePagination={handlePagination}
                handleAdd={() => navigate('#invite')}
                addTitle="Invite User"
                withAdd
                withDetail="user"
                withAction
                withListProject
                handleRemoveUserFromProject={handleOpenModalRemove}
            />
            <ModalDashboard
                modal={modal.caution}
                setModal={setModal}
                variant="info"
                type="activate"
                title="Activate Inhouse?"
                body="Are you sure want to activate this inhouse?"
                withCancel
                onConfirm={handleActivated}
                loading={activatedInhouseState?.loading}
            />
            <ModalDashboard
                modal={modal.success}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body="Successfully activated inhouse"
                onConfirm={() => {
                    setModal({ ...modal, success: false });
                }}
            />

            <ModalDashboard
                modal={modal.removeUserProject}
                setModal={setModal}
                variant="danger"
                type="delete"
                title="Remove User?"
                body={`Are you sure want to remove ${detailProject?.user_name} from ${detailProject?.project_name}?`}
                withCancel
                onConfirm={handleRemoveUserFromProject}
                loading={kickMemberProjectState?.loading}
            />
        </>
    );
}
