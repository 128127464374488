import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';
import Card from '@/components/molecules/Card';

import InputBasic from '@/components/atoms/Inputs/InputBasic';
import InputSelect from '@/components/atoms/Inputs/InputSelect';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import InputSearchSelect from '@/components/atoms/Inputs/InputSearchSelect';
import {
    invitedInhouse,
    getCompanySetting,
    clearUpdateCompany,
    getAllPendingInhouse,
} from '../../redux/actions';

import { useNavigate } from 'react-router-dom';
import { RootState } from '@/redux/store';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';

import { getAllProject, getAllTender } from '@/routes/Dashboard/Project/redux/actions';
import { CSSObjectWithLabel } from 'react-select';
import { getCurrentProfileOnProject } from '@/routes/ProjectDashboard/Settings/redux/actions';

interface PermissionProps {
    summary: {
        collaborate: boolean;
        invite: boolean;
    };
    schedule: {
        design: {
            can_collaborate_schedule: boolean;
            can_view_schedule: boolean;
        };
        construction: {
            can_collaborate_schedule: boolean;
            can_view_schedule: boolean;
        };
        can_invite_schedule: boolean;
    };
    drawing: {
        collaborate: boolean;
        view: boolean;
        invite: boolean;
        post_publicly: boolean;
    };
    document: {
        collaborate: boolean;
        view: boolean;
        invite: boolean;
        post_publicly: boolean;
    };
}

export default function InviteInhouse(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        reset,
        watch,
        control,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
    });

    const invitedInhouseState = useSelector((state: RootState) => state.invitedInhouse);
    const allProjectState = useSelector((state: RootState) => state.allProject);
    const allTenderState = useSelector((state: RootState) => state.allTender);
    const currentProfileProjectState = useSelector(
        (state: RootState) => state.currentProfileProject,
    );

    const [projectLists, setProjectLists] = useState<any[]>([]);
    const [withProject, setWithProject] = useState(false);
    const [modal, setModal] = useState({
        caution: false,
        success: false,
    });

    const [selectedProject, setSelectedProject] = useState<{
        value: string;
        label: string;
    }>({
        value: '',
        label: '',
    });

    const roleOptions = [
        { value: '1', label: 'Collaborator' },
        { value: '2', label: 'View Only' },
    ];

    const roleInviteOptions = [{ value: '2', label: 'View Only' }];

    const params = {
        page: 1,
        per_page: 9999,
        search: '',
        sort_by: 'name',
        sort_asc: 1,
    };

    const projectPermissions: PermissionProps =
        currentProfileProjectState?.response?.access;

    // Get all project
    useEffect(() => {
        dispatch(getAllProject(params));
        dispatch(getAllTender(params));
    }, []);

    // Add all tender and all project to project list
    useEffect(() => {
        if (allProjectState?.status === 200 && allTenderState?.status === 200) {
            const projectList = allProjectState?.response?.data.map((project: any) => ({
                value: project.id,
                label: project.name,
            }));

            const tenderList = allTenderState?.response?.data.map((tender: any) => ({
                value: tender.id,
                label: tender.name,
            }));

            const allLists = [...projectList, ...tenderList];

            // remove duplicate
            const uniqueList = allLists.filter(
                (v, i, a) => a.findIndex(t => t.value === v.value) === i,
            );

            setProjectLists(uniqueList);
        }
    }, [allProjectState, allTenderState]);

    // Handle modal, reset form, and get all pending inhouse after invited inhouse success
    useEffect(() => {
        if (invitedInhouseState?.status === 200) {
            setModal(prev => ({
                ...prev,
                caution: false,
            }));

            dispatch(clearUpdateCompany());
            setWithProject(false);

            setTimeout(() => {
                reset();
                dispatch(
                    getAllPendingInhouse({
                        page: 1,
                        per_page: 10,
                        search: '',
                        sort_by: 'created_at',
                        sort_asc: 0,
                    }),
                );
                dispatch(getCompanySetting());

                setModal(prev => ({
                    ...prev,
                    success: true,
                }));
            }, 1000);
        }
    }, [invitedInhouseState]);

    const handleDetailProject = (id: string): void => {
        dispatch(getCurrentProfileOnProject(id));
    };

    /**
     * Function on submit
     *
     * @param {object} data
     * @return {void}
     * @see cypress/e2e/authentication/login/login.cy.ts
     *      To cypress unit tester
     */
    const onSubmit = (data: any): void => {
        const newPayload = {
            email: data.email,
            can_collaborate_summary: data.summary_role === '1' ? '1' : '0',
            can_view_summary: data.summary_role === '2' ? '1' : '0',
            can_invite_summary: data.can_invite_summary === '1' ? '1' : '0',
            can_collaborate_document: data.document_role === '1' ? '1' : '0',
            can_view_document: data.document_role === '2' ? '1' : '0',
            can_invite_document: data.can_invite_document === '1' ? '1' : '0',
            can_post_publicly_document:
                data.can_post_publicly_document === '1' ? '1' : '0',
            can_collaborate_drawing: data.drawing_role === '1' ? '1' : '0',
            can_view_drawing: data.drawing_role === '2' ? '1' : '0',
            can_invite_drawing: data.can_invite_drawing === '1' ? '1' : '0',
            can_post_publicly_drawing: data.can_post_publicly_drawing === '1' ? '1' : '0',
            can_invite_schedule: data.can_invite_schedule === '1' ? '1' : '0',
            member_space_schedules: [
                {
                    can_collaborate_schedule:
                        data.schedule_role_design === '1' ? '1' : '0',
                    can_view_schedule: data.schedule_role_design === '2' ? '1' : '0',
                    space_type: 'design',
                },
                {
                    can_collaborate_schedule:
                        data.schedule_role_construction === '1' ? '1' : '0',
                    can_view_schedule:
                        data.schedule_role_construction === '2' ? '1' : '0',
                    space_type: 'construction',
                },
            ],
            comment: data.comment ?? "You've been invited to join the project",
        };

        // // Dispatch login action with form data as payload
        dispatch(invitedInhouse(newPayload, selectedProject?.value));
    };

    const basicStyles = {
        control: (
            baseStyles: CSSObjectWithLabel,
            state: { isFocused: boolean; isDisabled: boolean; isHover: boolean },
        ) => ({
            ...baseStyles,
            boxShadow: 'none',
            border: 'none',
            borderBottom: state.isFocused ? '1px solid #cfcfcf' : '1px solid #cfcfcf',
            borderRadius: 0,
            backgroundColor: state?.isDisabled ? 'transparent' : 'transparent',
            minHeight: 55,
            // margin: '2px 2px 22px',
            padding: '5px 0',
        }),
        valueContainer: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            padding: '5px 0 0',
            fontSize: '1rem',
        }),
        option: (baseStyles: CSSObjectWithLabel, state: { isFocused: boolean }) => ({
            ...baseStyles,
            backgroundColor: state.isFocused ? '#5648fb' : 'white',
            color: state.isFocused ? 'white' : 'black',
            fontSize: '14px',
        }),
        placeholder: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            fontSize: '1rem',
        }),
        singleValue: (
            baseStyles: CSSObjectWithLabel,
            state: { isDisabled: boolean; isFocused: boolean },
        ) => ({
            ...baseStyles,
            color: state.isDisabled && '#cfcfcf',
        }),
        menuPortal: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            zIndex: 9999,
        }),
        menu: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            zIndex: 9999,
        }),
    };

    return (
        <>
            <Card>
                <form action="#" onSubmit={handleSubmit(onSubmit)} method="post">
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <h1 className="card-title fw-semibold h6">Invite Inhouse</h1>

                            <div className="d-flex flex-column flex-md-row justify-content-between gap-5">
                                <div className="w-100">
                                    <InputBasic
                                        placeholder="Inhouse Email Address"
                                        id="inhouseEmail"
                                        type="email"
                                        rules={{
                                            function: register,
                                            name: 'email',
                                            rules: {
                                                required: 'Email is required',
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: 'invalid email address',
                                                },
                                            },
                                            errors,
                                        }}
                                    />

                                    {allProjectState?.status === 200 &&
                                        allTenderState?.status === 200 && (
                                            <Form.Check
                                                type="checkbox"
                                                id="addToProject"
                                                label="Add to project"
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>,
                                                ) => {
                                                    setWithProject(e.target.checked);

                                                    if (!e.target.checked) {
                                                        setSelectedProject({
                                                            value: '',
                                                            label: '',
                                                        });
                                                    }
                                                }}
                                            />
                                        )}
                                </div>
                                <div className="d-flex flex-column w-100">
                                    {withProject && (
                                        <Controller
                                            control={control}
                                            name="getAll"
                                            render={({ field: { onChange } }) => (
                                                <div className="text-start">
                                                    <InputSearchSelect
                                                        placeholder="Select Project"
                                                        styles={basicStyles}
                                                        options={projectLists}
                                                        isMulti={false}
                                                        loading={
                                                            currentProfileProjectState?.loading
                                                        }
                                                        // defaultValue={selectedProject}
                                                        onChange={(value: {
                                                            value: string;
                                                            label: string;
                                                        }) => {
                                                            setSelectedProject(value);
                                                            handleDetailProject(
                                                                value.value,
                                                            );
                                                            onChange(value.value);
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            {withProject &&
                                selectedProject.value !== '' &&
                                currentProfileProjectState?.loading !== true && (
                                    <AccessWrapper className="d-flex flex-column justify-content-between gap-4">
                                        <div className="d-flex flex-column">
                                            <label className="mt-3 fw-semibold">
                                                Select Access{' '}
                                            </label>
                                            <small
                                                style={{
                                                    fontSize: 12,
                                                    color: 'rgb(141, 180, 223)',
                                                }}
                                            >
                                                You can only grant equivalent or lower
                                                permissions based on the access you have
                                                on the project
                                            </small>
                                        </div>

                                        {/* Summary */}
                                        <div>
                                            <label>Summary</label>
                                            <div className="d-flex justify-content-between align-items-center gap-5">
                                                <InputSelect
                                                    options={
                                                        projectPermissions?.summary
                                                            ?.collaborate
                                                            ? roleOptions
                                                            : roleInviteOptions
                                                    }
                                                    rules={{
                                                        function: register,
                                                        name: 'summary_role',
                                                        rules: {
                                                            required:
                                                                'Summary role is required',
                                                        },
                                                        errors,
                                                    }}
                                                    outerClassName="w-50"
                                                />
                                                <div className="d-flex flex-column justify-content-center w-50">
                                                    {projectPermissions?.summary
                                                        ?.invite && (
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Ability to invite users"
                                                            id="inviteUserCheckboxSummary"
                                                            className=""
                                                            {...register(
                                                                'can_invite_summary',
                                                            )}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {/* Schedule */}
                                        {projectPermissions?.schedule?.design
                                            ?.can_collaborate_schedule ||
                                        projectPermissions?.schedule?.design
                                            ?.can_view_schedule ||
                                        projectPermissions?.schedule?.construction
                                            ?.can_collaborate_schedule ||
                                        projectPermissions?.schedule?.construction
                                            ?.can_view_schedule ||
                                        projectPermissions?.schedule
                                            ?.can_invite_schedule ? (
                                            <div>
                                                <label>Schedule</label>
                                                <div className="d-flex justify-content-between align-items-center gap-5">
                                                    <div className="d-flex w-50 gap-3">
                                                        {projectPermissions?.schedule
                                                            .design
                                                            ?.can_collaborate_schedule ||
                                                        projectPermissions?.schedule
                                                            .design?.can_view_schedule ? (
                                                            <div className="w-100">
                                                                <label className="">
                                                                    Design
                                                                </label>
                                                                <InputSelect
                                                                    options={
                                                                        projectPermissions
                                                                            ?.schedule
                                                                            .design
                                                                            ?.can_collaborate_schedule
                                                                            ? [
                                                                                  {
                                                                                      value: '0',
                                                                                      label: 'No Access',
                                                                                  },
                                                                                  ...roleOptions,
                                                                              ]
                                                                            : [
                                                                                  {
                                                                                      value: '0',
                                                                                      label: 'No Access',
                                                                                  },
                                                                                  ...roleInviteOptions,
                                                                              ]
                                                                    }
                                                                    rules={{
                                                                        function:
                                                                            register,
                                                                        name: 'schedule_role_design',
                                                                        rules: {
                                                                            required:
                                                                                'Schedule role is required',
                                                                        },
                                                                        errors,
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : null}
                                                        {projectPermissions?.schedule
                                                            .construction
                                                            ?.can_collaborate_schedule ||
                                                        projectPermissions?.schedule
                                                            .construction
                                                            ?.can_view_schedule ? (
                                                            <div className="w-100">
                                                                <label className="">
                                                                    Construction
                                                                </label>
                                                                <InputSelect
                                                                    options={
                                                                        projectPermissions
                                                                            ?.schedule
                                                                            .construction
                                                                            ?.can_collaborate_schedule
                                                                            ? [
                                                                                  {
                                                                                      value: '0',
                                                                                      label: 'No Access',
                                                                                  },
                                                                                  ...roleOptions,
                                                                              ]
                                                                            : [
                                                                                  {
                                                                                      value: '0',
                                                                                      label: 'No Access',
                                                                                  },
                                                                                  ...roleInviteOptions,
                                                                              ]
                                                                    }
                                                                    rules={{
                                                                        function:
                                                                            register,
                                                                        name: 'schedule_role_construction',
                                                                        rules: {
                                                                            required:
                                                                                'Schedule role is required',
                                                                        },
                                                                        errors,
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : null}
                                                    </div>

                                                    <div className="w-50">
                                                        {projectPermissions?.schedule
                                                            .can_invite_schedule && (
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="schedule_group"
                                                                label="Ability to invite users"
                                                                {...register(
                                                                    'can_invite_schedule',
                                                                )}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}

                                        {/* Document */}
                                        <div>
                                            {projectPermissions?.document?.collaborate ||
                                            projectPermissions?.document?.view ? (
                                                <Form.Check
                                                    type="checkbox"
                                                    id="document_group"
                                                    label="Document"
                                                    {...register('document')}
                                                />
                                            ) : null}
                                            {watch('document') !== false &&
                                                watch('document') !== undefined && (
                                                    <div className="d-flex justify-content-between align-items-center gap-5">
                                                        <InputSelect
                                                            options={
                                                                projectPermissions
                                                                    ?.document
                                                                    ?.collaborate
                                                                    ? roleOptions
                                                                    : roleInviteOptions
                                                            }
                                                            rules={{
                                                                function: register,
                                                                name: 'document_role',
                                                                rules: {
                                                                    required:
                                                                        'Document access is required',
                                                                },
                                                                errors,
                                                            }}
                                                            outerClassName="w-50"
                                                        />

                                                        <div className="d-flex flex-column justify-content-center w-50">
                                                            {projectPermissions?.document
                                                                ?.invite && (
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    label="Ability to invite users"
                                                                    id="inviteUserCheckboxDocument"
                                                                    className=""
                                                                    {...register(
                                                                        'can_invite_document',
                                                                    )}
                                                                />
                                                            )}
                                                            {projectPermissions?.document
                                                                ?.post_publicly && (
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    label="Post Publicly"
                                                                    id="postCheckboxDocument"
                                                                    className=""
                                                                    {...register(
                                                                        'can_post_publicly_document',
                                                                    )}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                        </div>

                                        {/* Drawing */}
                                        <div>
                                            {projectPermissions?.drawing?.collaborate ||
                                            projectPermissions?.drawing?.view ? (
                                                <Form.Check
                                                    type="checkbox"
                                                    id="drawing_group"
                                                    label="Drawing"
                                                    {...register('drawing')}
                                                />
                                            ) : null}
                                            {watch('drawing') !== false &&
                                                watch('drawing') !== undefined && (
                                                    <div className="d-flex justify-content-between align-items-center gap-5">
                                                        <InputSelect
                                                            options={
                                                                projectPermissions
                                                                    ?.drawing?.collaborate
                                                                    ? roleOptions
                                                                    : roleInviteOptions
                                                            }
                                                            rules={{
                                                                function: register,
                                                                name: 'drawing_role',
                                                                rules: {
                                                                    required:
                                                                        'Drawing access is required',
                                                                },
                                                                errors,
                                                            }}
                                                            outerClassName="w-50"
                                                        />
                                                        <div className="d-flex flex-column justify-content-center w-50">
                                                            {projectPermissions?.drawing
                                                                ?.invite && (
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    label="Ability to invite users"
                                                                    id="inviteUserCheckboxDrawing"
                                                                    className=""
                                                                    {...register(
                                                                        'can_invite_drawing',
                                                                    )}
                                                                />
                                                            )}
                                                            {projectPermissions?.drawing
                                                                ?.post_publicly && (
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    label="Post Publicly"
                                                                    id="postCheckboxDrawing"
                                                                    className=""
                                                                    {...register(
                                                                        'can_post_publicly_drawing',
                                                                    )}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    </AccessWrapper>
                                )}
                        </div>
                        <div className="col-md-12 text-end mt-5">
                            <ButtonBasic
                                type="button"
                                className="btn btn-outline-primary me-4 mb-2"
                                text="Cancel"
                                onClick={() => {
                                    navigate('/setting/company');
                                }}
                            />
                            <ButtonBasic
                                type="button"
                                className="btn btn-primary mb-2"
                                text="Invite Inhouse"
                                onClick={() => {
                                    setModal({ ...modal, caution: true });
                                }}
                            />
                        </div>
                    </div>
                </form>
            </Card>
            <ModalDashboard
                modal={modal.caution}
                setModal={setModal}
                variant="info"
                type="caution"
                title="Invite Now?"
                body="Make sure all data is right, do you want to continue?"
                withCancel
                onConfirm={handleSubmit(onSubmit)}
                loading={invitedInhouseState?.loading}
            />

            <ModalDashboard
                modal={modal.success}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body="Successfully invite inhouse"
                onConfirm={() => {
                    setModal({ ...modal, success: false });
                }}
            />
        </>
    );
}

const AccessWrapper = styled.div`
    .form-select {
        /* font-size: 14px; */
        /* padding: 0 !important; */
    }
`;
