import { Form, Modal } from 'react-bootstrap';

import { BiArrowBack } from 'react-icons/bi';
import { HiOutlineDocumentDownload } from 'react-icons/hi';
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs';

import { OpenFile, Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { SelectionMode } from '@react-pdf-viewer/selection-mode';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

import packageJson from '@/../package.json';

import styled from 'styled-components';
import BasicTooltip from '@/components/atoms/Tooltips';
import { MdCompare } from 'react-icons/md';

import { useRef, useState } from 'react';
import { handleTypeFileByFormat } from '@/utility/Utils';
import { StructureProps } from '@/routes/ProjectDashboard/Drawing/components/Explorer/interface';
import { useParams } from 'react-router-dom';
import CompareCanvas from './CompareCanvas';

interface ParamsProps {
    modal: boolean;
    setModal: (value: boolean) => void;
    state: {
        selected: StructureProps | null;
        data: StructureProps[] | null;
    };
    setState: (value: {
        selected: StructureProps | null;
        data: StructureProps[] | null;
    }) => void;
    compare: boolean;
    setCompare: (value: boolean) => void;
    handleDownloadSingle: (selected: StructureProps | null) => void;
}

export default function ModalDrawingPreview({
    modal,
    setModal,
    state,
    setState,
    compare,
    setCompare,
    handleDownloadSingle,
}: ParamsProps): JSX.Element {
    const { id } = useParams();
    // const dispatch = useAppDispatch();

    const projectId = id ?? '';

    const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];
    const pdfWorker = `https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`;

    const [lockItems, setLockItems] = useState(false);
    const [selectedItem, setSelectedItem] = useState('');

    const [initialItem, setInitialItem] = useState({
        ...state.selected,
        offset: { x: 0.0, y: 0.0 },
        scale: { x: 0.0, y: 0.0 },
        opacity: 1,
    });

    const stageRef = useRef<any>(null);

    // TODO: Coming soon
    // useEffect(() => {
    //     if (state.selected !== null) {
    //         setInitialItem({
    //             ...state.selected,
    //             offset: { x: 0.0, y: 0.0 },
    //             scale: { x: 0.4, y: 0.4 },
    //             opacity: 1,
    //         });

    //         // dispatch action to get all drawing compare file
    //         dispatch(
    //             getAllDrawingCompareFile({
    //                 project_id: projectId ?? '',
    //                 format: handleTypeFileByFormat(state?.selected.format),
    //             }),
    //         );
    //     }
    // }, [state.selected]);

    /**
     * Function for handle back
     * @param id  number
     * @returns void
     */
    const handleBack = (uuid: string): void => {
        // get index of current selected
        const index = state.data?.findIndex(item => item.uuid === uuid) ?? 0;

        // if index is 0, then set selected to null
        if (index === 0) {
            return;
        }

        // get previous item
        const previousItem = state.data?.[index - 1];

        // set selected to previous item
        setState({
            ...state,
            selected: previousItem ?? null,
        });
    };

    /**
     * Function for handle next
     * @param id  number
     * @returns void
     */
    const handleNext = (uuid: string): void => {
        // get index of current selected
        const index = state.data?.findIndex(item => item.uuid === uuid) ?? 0;

        // if index is 0, then set selected to null
        if (index === (state.data?.length ?? 0) - 1) {
            return;
        }

        // get next item
        const nextItem = state.data?.[index + 1];

        // set selected to next item
        setState({
            ...state,
            selected: nextItem ?? null,
        });
    };

    /**
     * Function for handle lock object
     *
     * @param value
     * @returns void
     */
    const handleLockObject = (value: boolean): void => {
        setLockItems(value);
    };

    /**
     * Function for handle compare
     *
     * @returns void
     */
    const handleCompare = (): void => {
        setCompare(!compare);
    };

    /**
     * Function for handle select item
     *
     * @param e
     * @returns void
     */
    const handleSelectItem = (e: any): void => {
        setSelectedItem(e.target.id());
    };

    /**
     * Function for handle unselect item
     *
     * @param e
     * @returns void
     */
    const handleUnselectItem = (e: any): void => {
        if (e.target === e.target.getStage()) {
            setSelectedItem('');
        }
    };

    const handleCloseModal = (): void => {
        setModal(false);
        setCompare(false);
    };

    const toolbarPluginInstance = toolbarPlugin({
        getFilePlugin: {
            fileNameGenerator: (file: OpenFile) => {
                const fileName = file.name.substring(file.name.lastIndexOf('/') + 1);
                return fileName;
            },
        },
        searchPlugin: {
            keyword: 'PDF',
        },
        selectionModePlugin: {
            selectionMode: SelectionMode.Hand,
        },
    });

    const { Toolbar } = toolbarPluginInstance;

    /**
     * Function for handle header
     *
     * @param selected object
     * @returns void
     */
    const handleHeader = (selected: StructureProps | null): JSX.Element => {
        const { uuid, name, format } = selected ?? {};

        return (
            <div className="d-flex flex-column w-100">
                {compare ? (
                    <div
                        className="d-flex justify-content-between text-white p-3"
                        style={{
                            backgroundColor: 'rgb(48, 48, 48)',
                        }}
                    >
                        <div className="d-flex align-items-center">
                            <BasicTooltip text="Back" placement="bottom">
                                <BiArrowBack
                                    onClick={handleCloseModal}
                                    size={20}
                                    color="#fff"
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                />
                            </BasicTooltip>
                            <h6 className="fw-semibold mb-0 ms-2">{selected?.name}</h6>
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                            {handleTypeFileByFormat(format ?? '') === 'image' && (
                                <BasicTooltip
                                    text={`${lockItems ? 'Unlock' : 'Lock'} object`}
                                    placement="bottom"
                                >
                                    <Form.Switch
                                        id="custom-switch"
                                        onChange={(e: any) =>
                                            handleLockObject(e.target.checked)
                                        }
                                        checked={lockItems}
                                    />
                                </BasicTooltip>
                            )}
                            <BasicTooltip text="Uncompare" placement="bottom">
                                <MdCompare
                                    size={20}
                                    color="#fff"
                                    className="mx-3"
                                    role="button"
                                    onClick={handleCompare}
                                />
                            </BasicTooltip>
                        </div>
                    </div>
                ) : (
                    <>
                        <div
                            className="d-flex justify-content-between text-white p-3"
                            style={{
                                backgroundColor: 'rgb(48, 48, 48)',
                            }}
                        >
                            <div className="d-flex align-items-center">
                                <BasicTooltip text="Back" placement="bottom">
                                    <BiArrowBack
                                        onClick={() => setModal(false)}
                                        size={20}
                                        color="#fff"
                                        role="button"
                                    />
                                </BasicTooltip>
                                <h6 className="fw-semibold mb-0 ms-2">{name}</h6>
                            </div>

                            <div className="d-flex align-items-center justify-content-between">
                                {handleTypeFileByFormat(format ?? '') === 'pdf' ||
                                handleTypeFileByFormat(format ?? '') === 'image' ? (
                                    <BasicTooltip
                                        text="Compare (coming soon)"
                                        placement="bottom"
                                    >
                                        <MdCompare
                                            size={20}
                                            color="#fff"
                                            className="mx-3"
                                            // TODO: Coming soon
                                            // role="button"
                                            // onClick={handleCompare}
                                        />
                                    </BasicTooltip>
                                ) : null}
                                <BasicTooltip text="Previous" placement="bottom">
                                    <BsFillArrowLeftCircleFill
                                        size={20}
                                        color="#fff"
                                        onClick={() => handleBack(uuid ?? '')}
                                        role="button"
                                        className="mx-3"
                                    />
                                </BasicTooltip>
                                <BasicTooltip text="Download" placement="bottom">
                                    <HiOutlineDocumentDownload
                                        size={20}
                                        color="#fff"
                                        className="mx-3"
                                        role="button"
                                        onClick={() => handleDownloadSingle(selected)}
                                    />
                                </BasicTooltip>
                                <BasicTooltip text="Next" placement="bottom">
                                    <BsFillArrowRightCircleFill
                                        size={20}
                                        color="#fff"
                                        onClick={() => handleNext(uuid ?? '')}
                                        className="mx-3"
                                        role="button"
                                    />
                                </BasicTooltip>
                            </div>
                        </div>

                        {format === 'pdf' && (
                            <div
                                className="p-3"
                                style={{
                                    backgroundColor: 'rgb(213, 213, 213)',
                                }}
                            >
                                <Toolbar />
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    };

    /**
     * Function for handle view
     *
     * @param selected
     * @returns JSX.Element
     */
    const handleViewer = (selected: StructureProps | null): JSX.Element => {
        const { format, file } = selected ?? {};

        switch (handleTypeFileByFormat(format ?? '')) {
            case 'pdf':
                return (
                    <Viewer
                        fileUrl={file ?? ''}
                        plugins={[toolbarPluginInstance as any]}
                    />
                );
            case 'image':
                return (
                    <div className="d-flex justify-content-center align-items-center w-100 h-100">
                        <img
                            src={file ?? ''}
                            alt="preview"
                            style={{
                                width: '90%',
                                height: '80%',
                                objectFit: 'contain',
                            }}
                        />
                    </div>
                );
            case null:
                return (
                    <div className="text-center w-100 text-white">
                        Folder cannot be previewed
                    </div>
                );
            default:
                return (
                    <DocViewer
                        pluginRenderers={DocViewerRenderers}
                        className="w-100 h-100"
                        config={{
                            header: {
                                disableHeader: true,
                                disableFileName: true,
                                retainURLParams: true,
                            },
                            csvDelimiter: ',',
                        }}
                        documents={[{ uri: file ?? '' }]}
                    />
                );
        }
    };

    return (
        <ModalViewer
            show={modal}
            onHide={handleCloseModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            fullscreen
        >
            {compare ? (
                <>
                    <Modal.Header className="p-0">
                        {handleHeader(state.selected)}
                    </Modal.Header>
                    <Modal.Body className={'p-0'}>
                        <CompareCanvas
                            initialItem={initialItem}
                            setInitialItem={setInitialItem}
                            projectId={projectId}
                            lockItems={lockItems}
                            handleSelectItem={handleSelectItem}
                            handleUnselectItem={handleUnselectItem}
                            selectedItem={selectedItem}
                            stageRef={stageRef}
                        />
                    </Modal.Body>
                </>
            ) : (
                <Worker workerUrl={pdfWorker}>
                    <Modal.Header className="p-0">
                        {handleHeader(state.selected)}
                    </Modal.Header>
                    <Modal.Body
                        className={
                            'p-0 d-flex align-items-center justify-content-between '
                        }
                    >
                        {handleViewer(state.selected)}
                    </Modal.Body>
                </Worker>
            )}
        </ModalViewer>
    );
}

const ModalViewer = styled(Modal)<{ format: string }>`
    .img-preview {
        width: 90%;
        height: 80%;
        object-fit: cover;
    }

    background-color: ${({ format }) => (format === 'pdf' ? '#ffff' : '')};

    .modal-content {
        background: transparent;
        border: none;
    }

    .modal-dialog {
        margin: 0;
    }

    .modal-header {
        border-radius: 0;
        border: none;
    }
`;
