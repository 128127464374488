/* eslint-disable @typescript-eslint/no-dynamic-delete */
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import VerticalStepper from '@/components/molecules/VerticalStepper';
import Loader from '@/components/molecules/Loader';

import { useDispatch, useSelector } from 'react-redux';
import PersonalDetailsInhouse from './InhouseUser/PersonalDetailsInhouse';
import CompanyDetailsInhouse from './InhouseUser/CompanyDetailsInhouse';

import {
    getInhouseUserCredentialsToken,
    registerInhouseCompleted,
    clearRegisterState,
} from '../redux/actions';
import Cookies from 'js-cookie';
import { dataURLtoFile, decryptData } from '@/utility/Utils';
import { RootState } from '@/redux/store';

export default function SetAccountInhouse(): JSX.Element {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);

    const cookiesInhouse = decryptData(Cookies?.get('inhouse') as any);
    const parseCookies = JSON.parse(cookiesInhouse);

    const [activeStep, setActiveStep] = useState(0);
    const [loadParameters, setLoadParameter] = useState({
        invitationToken: '',
    });

    const registerInhouseCompletedState = useSelector(
        (state: RootState) => state.registerInhouseCompleted,
    );
    const inhouseUserCredentialState = useSelector(
        (state: RootState) => state.inhouseUserCredential,
    );

    // get the value from the local storage
    const stepStorage = sessionStorage.getItem('step');
    const phoneStorage = sessionStorage.getItem('phone');
    const jobTitleStorage = sessionStorage.getItem('job_title');
    const userJobTitleOtherStorage = sessionStorage.getItem('user_job_title_other');

    // handle register completed
    useEffect(() => {
        if (registerInhouseCompletedState.status === 200) {
            navigate('/register/complete');
            dispatch(clearRegisterState());
            Cookies?.remove('inhouse');

            sessionStorage?.clear();
        }
    }, [registerInhouseCompletedState]);

    // set params value to the state
    useEffect(() => {
        // loop through query params
        for (const [key, value] of queryParams) {
            // change the key name to camel case
            const camelCaseKey = key.replace(/_([a-z])/g, g => g[1].toUpperCase());

            // set the value to the state
            setLoadParameter(prev => ({
                ...prev,
                [camelCaseKey]: value,
            }));
        }
    }, [search]);

    // local storage
    useEffect(() => {
        // if the step is not null
        if (stepStorage !== null) {
            // set the value to the state
            setActiveStep(parseInt(stepStorage));

            setValue('job_title', jobTitleStorage ?? '');
            setValue('user_job_title_other', userJobTitleOtherStorage ?? '');
        }
    }, [stepStorage]);

    // get the value from the url
    useEffect(() => {
        if (loadParameters.invitationToken !== '') {
            // set the value to the form
            dispatch(getInhouseUserCredentialsToken(loadParameters.invitationToken));
        }
    }, [loadParameters.invitationToken]);

    // set the value to the form if the status is 200
    useEffect(() => {
        if (inhouseUserCredentialState.status === 200) {
            setValue(
                'company_name',
                inhouseUserCredentialState?.response?.invited_by?.company?.company_name,
            );

            setValue('name', parseCookies.user_name);
            setValue('phone', phoneStorage ?? '');

            setValue('user_email', inhouseUserCredentialState?.response?.email);
            setValue(
                'company_email',
                inhouseUserCredentialState?.response?.invited_by?.company
                    ?.company_email ??
                    inhouseUserCredentialState?.response?.invited_by?.email,
            );
        }

        if (inhouseUserCredentialState.status === 400) {
            navigate('/token-expired');
        }
    }, [inhouseUserCredentialState]);

    // set the value to the form from session storage
    useEffect(() => {
        // set phone from session storage
        if (watch('phone') !== undefined) {
            sessionStorage.setItem('phone', watch('phone'));
        }

        // set job title from session storage
        if (watch('job_title') !== undefined) {
            sessionStorage.setItem('job_title', watch('job_title'));
        }

        if (watch('user_job_title_other') !== undefined) {
            // set session storage
            sessionStorage.setItem('user_job_title_other', watch('user_job_title_other'));
        }
    }, [watch('phone'), watch('job_title'), watch('user_job_title_other')]);

    /**
     * Initialize steps
     */
    const steps = [
        {
            title: 'Company Details',
            subtitle: 'Add company details',
            component: <CompanyDetailsInhouse register={register} errors={errors} />,
        },
        {
            title: 'Personal Profile',
            subtitle: 'Your personal profile details',
            component: (
                <PersonalDetailsInhouse
                    register={register}
                    control={control}
                    errors={errors}
                    watch={watch}
                />
            ),
        },
    ];

    /**
     * Function to handle back button
     *
     * @return {void}
     */
    const handleBack = (): void => {
        setActiveStep(activeStep - 1);
    };

    /**
     * Function on submit and handle next and previous button
     *
     * @param {object} data
     * @return {void}
     */
    const onSubmit = (data: any, type: string): void => {
        if (type === 'next') {
            setActiveStep(activeStep + 1);

            // add step to local storage
            sessionStorage.setItem('step', (activeStep + 1).toString());
        }

        if (type === 'prev') {
            setActiveStep(activeStep - 1);

            // add step to session storage
            sessionStorage.setItem('step', (activeStep - 1).toString());
        }

        if (type === 'finish') {
            // initialize form data
            const newPayload = new FormData();

            // append data to form data
            newPayload.append('password', parseCookies.user_password);
            newPayload.append(
                'password_confirmation',
                parseCookies.user_password_confirmation,
            );

            // change number format
            // data.phone = data.phone.replace(/\s/g, '');

            // // replace +
            // data.phone = data.phone.replace('+', '');

            // TODO: Check if this is needed
            if (data?.job_title === 'Other') {
                data.job_title = data?.user_job_title_other;
            }

            // append data to form data
            for (const key in data) {
                newPayload.append(key, data[key as keyof typeof data]);
            }

            // append user_avatar from session storage to file
            const avatar = sessionStorage.getItem('avatar');

            if (avatar != null) {
                const file = dataURLtoFile(avatar, 'avatar');
                newPayload.append('avatar', file);
            }

            // if job title is null
            if (newPayload.get('job_title') === null) {
                newPayload.delete('job_title');
            }

            dispatch(
                registerInhouseCompleted(newPayload, loadParameters.invitationToken),
            );
        }
    };

    return (
        <Fragment>
            {registerInhouseCompletedState.loading === true && <Loader />}
            <div className="container-fluid container-xs container-lg">
                <main>
                    <VerticalStepper
                        steps={steps}
                        onNext={handleSubmit(data => onSubmit(data, 'next'))}
                        onPrev={handleBack}
                        onFinish={handleSubmit(data => onSubmit(data, 'finish'))}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                    />
                </main>
            </div>
        </Fragment>
    );
}
