import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import InputBasic from '@/components/atoms/Inputs/InputBasic';
import ModalAction from '@/components/atoms/Modals/ModalAction';
import PasswordValidation from '@/components/molecules/PasswordValidation';
import { RootState } from '@/redux/store';
import {
    resetPasswordPhone,
    clearForgotPasswordPhoneState,
} from '@/routes/Authentication/ForgotPassword/redux/actions';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { clearStatePhone, clearStateResetPasswordPhone } from '../redux/actions';

interface ValidationListsProps {
    id: string;
    text: string;
    isValidate: boolean;
}

export default function ModalNewPassword({
    modal,
    setModal,
}: {
    modal: {
        caution: boolean;
        success: boolean;
        reset: boolean;
        otpPhone: boolean;
        newPassword: boolean;
    };
    setModal: (modal: any) => void;
}): JSX.Element {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
    });
    const dispatch = useDispatch();
    const passwordCheck = watch('new_password_confirmation');

    const resetPasswordPhoneState = useSelector(
        (state: RootState) => state.resetPasswordPhone,
    );

    const [validationLists, setValidationLists] = useState<ValidationListsProps[]>([
        {
            id: 'isMaxLength',
            text: 'At least 10 characters',
            isValidate: false,
        },
        {
            id: 'isUppercase',
            text: 'An uppercase character',
            isValidate: false,
        },
        {
            id: 'isLowercase',
            text: 'A lowercase character',
            isValidate: false,
        },
        {
            id: 'isNumber',
            text: 'A number',
            isValidate: false,
        },
        {
            id: 'isSpecialCharacter',
            text: 'A special character',
            isValidate: false,
        },
    ]);

    // For password validation
    useEffect(() => {
        if (passwordCheck !== undefined) {
            const isMaxLength = passwordCheck.length >= 10;
            const isUppercase = /[A-Z]/.test(passwordCheck);
            const isLowercase = /[a-z]/.test(passwordCheck);
            const isNumber = /[0-9]/.test(passwordCheck);
            const isSpecialCharacter = /[^a-zA-Z0-9]/.test(passwordCheck);

            setValidationLists(prevState => [
                {
                    ...prevState[0],
                    isValidate: isMaxLength,
                },
                {
                    ...prevState[1],
                    isValidate: isUppercase,
                },
                {
                    ...prevState[2],
                    isValidate: isLowercase,
                },
                {
                    ...prevState[3],
                    isValidate: isNumber,
                },
                {
                    ...prevState[4],
                    isValidate: isSpecialCharacter,
                },
            ]);
        }
    }, [passwordCheck]);

    /**
     * Function on submit
     *
     * @param any data
     * @return void
     * @see cypress/e2e/dashboard/Project/project.cy.ts
     *      To cypress unit tester
     */
    const onSubmit = (data: any): void => {
        // initialize form data
        const payload = new FormData();

        // get otp from session storage
        const otp = sessionStorage.getItem('num') ?? '';

        // append data to form data
        for (const key in data) {
            payload.append(key, data[key as keyof typeof data]);
        }

        // Dispatch login action with form data as payload
        dispatch(resetPasswordPhone(payload, otp));

        // remove otp from session storage
        sessionStorage.removeItem('num');
    };

    useEffect(() => {
        if (resetPasswordPhoneState.status === 200) {
            setModal((prev: any) => ({
                ...prev,
                otpPhone: false,
                newPassword: false,
            }));

            dispatch(clearForgotPasswordPhoneState());
            dispatch(clearStatePhone());
            dispatch(clearStateResetPasswordPhone());

            setTimeout(() => {
                setModal((prev: any) => ({
                    successPhone: true,
                }));
            }, 1000);
        }
    }, [resetPasswordPhoneState]);

    return (
        <ModalAction
            modal={modal.newPassword}
            setModal={setModal}
            aria-labelledby="contained-modal-title-vcenter"
            title="Create New Project"
            size="lg"
        >
            <div className="px-3 pt-3">
                <form action="#" onSubmit={handleSubmit(onSubmit)} method="post">
                    <div className="row">
                        <div className="col-lg-6 mt-0">
                            <InputBasic
                                id="newPassword"
                                placeholder="New Password"
                                type="password"
                                rules={{
                                    function: register,
                                    name: 'new_password_confirmation',
                                    rules: {
                                        required: 'Password is required',
                                        validate: {
                                            isMaxLength: (value: string) =>
                                                value.length >= 10 ||
                                                'At least 10 characters required',
                                            isUppercase: (value: string) =>
                                                /[A-Z]/.test(value) ||
                                                'An uppercase character required',
                                            isLowercase: (value: string) =>
                                                /[a-z]/.test(value) ||
                                                'A lowercase character required',
                                            isNumber: (value: string) =>
                                                /[0-9]/.test(value) ||
                                                'A number required',
                                            isSpecialCharacter: (value: string) =>
                                                /[^a-zA-Z0-9]/.test(value) ||
                                                'A special character required',
                                        },
                                    },
                                    errors,
                                }}
                            />
                        </div>
                        <div className="col-lg-6 mt-0 text-start">
                            <InputBasic
                                id="confirmPassword"
                                placeholder="Confirm Password"
                                type="password"
                                rules={{
                                    function: register,
                                    name: 'new_password',
                                    rules: {
                                        required: 'Confirm Password is required',
                                        validate: {
                                            isMatch: (value: string) =>
                                                value ===
                                                    watch('new_password_confirmation') ||
                                                'Password does not match with new password',
                                        },
                                    },
                                    errors,
                                }}
                            />
                            <span
                                className="card-title fw-semibold"
                                style={{
                                    fontSize: 14,
                                }}
                            >
                                New password must be :
                            </span>
                            <PasswordValidation lists={validationLists} />
                        </div>
                    </div>
                    <div className="row my-3">
                        <div className="col-lg-3 ms-auto">
                            <ButtonBasic
                                text="Cancel"
                                className="btn-outline-primary w-100 mb-2"
                                type="button"
                                // onClick={() => {
                                //     navigate('/');
                                // }}
                            />
                        </div>
                        <div className="col-lg-3">
                            <ButtonBasic
                                text="Confirm"
                                className="btn-primary w-100 mb-2"
                                type="submit"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </ModalAction>
    );
}
