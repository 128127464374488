import { useSelector } from 'react-redux';
import Explorer from '@/routes/Global/DocumentGlobal/components/Explorer';
import { RootState } from '@/redux/store';
import { ISpace } from '@/routes/Global/DocumentGlobal/components/Explorer/interface';

export default function PublicDocument({
    handleOpenSpace,
    handleOpenFolder,
    handlePrevious,
    handleCreateSpace,
    handlePagination,
    handlePaginationFolder,
    handleModal,
    handleSearch,
    handlePreviewFile,
    handleRemoveSelected,
    selectedCheckbox,
    setSelectedCheckbox,
    handleActionMultiple,
    rightClick,
    handleActionRightClick,
    handlePreviewRevision,
    documentSpaceParams,
    documentFolderParams,
}: ISpace): JSX.Element {
    const allDocumentPublicSpaceState = useSelector(
        (state: RootState) => state.allDocumentPublicSpace,
    );

    const allDocumentPublicFolderState = useSelector(
        (state: RootState) => state.allDocumentPublicFolder,
    );

    const getShareLinkDocumentState = useSelector(
        (state: RootState) => state.getShareLinkDocument,
    );
    const getShareLinkSpaceDocumentState = useSelector(
        (state: RootState) => state.getShareLinkSpaceDocument,
    );

    return (
        <>
            <Explorer
                reduxSpace={getShareLinkSpaceDocumentState}
                reduxFolder={getShareLinkDocumentState}
                moduleType={'documents'}
                tab="public"
                handleOpenSpace={handleOpenSpace}
                handleOpenFolder={handleOpenFolder}
                handlePrevious={handlePrevious}
                handleCreateSpace={handleCreateSpace}
                handlePagination={handlePagination}
                handlePaginationFolder={handlePaginationFolder}
                handleModal={handleModal}
                handleSearch={handleSearch}
                handlePreviewFile={handlePreviewFile}
                handleRemoveSelected={handleRemoveSelected}
                selectedCheckbox={selectedCheckbox}
                setSelectedCheckbox={setSelectedCheckbox}
                handleActionMultiple={handleActionMultiple}
                rightClick={rightClick}
                handleActionRightClick={handleActionRightClick}
                handlePreviewRevision={handlePreviewRevision}
                documentSpaceParams={documentSpaceParams}
                documentFolderParams={documentFolderParams}
            />
        </>
    );
}
