import { Fragment } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Form } from 'react-bootstrap';

import MailIcons from '@/assets/svg/icons/mail-alt-3.svg';
import UserIcons from '@/assets/svg/icons/user-alt-1.svg';

// import InputPhone from '@/components/atoms/Inputs/InputPhone';
import InputBasic from '@/components/atoms/Inputs/InputBasic';
import LinkBack from '@/components/molecules/LinkBack';
import { HeaderContent } from '@/components/molecules/Typography';
import { HeaderStepper, BodyStepper } from '@/components/molecules/VerticalStepper';
import InputPicture from '../InputPicture';
import { Controller } from 'react-hook-form';
import InputPhoneNew from '@/components/atoms/Inputs/InputPhoneNew';

interface DataProps {
    id: number;
    title: string;
}

export default function PersonalDetailsInhouse(params: any): JSX.Element {
    const { register, errors, watch, control } = params;

    const jobLists: DataProps[] = [
        {
            id: 1,
            title: 'Project Manager',
        },
        {
            id: 2,
            title: 'Site Foreman',
        },
        {
            id: 3,
            title: 'Senior Designer',
        },
        {
            id: 4,
            title: 'Estimator',
        },
        {
            id: 5,
            title: 'Other',
        },
    ];

    return (
        <Fragment>
            <HeaderStepper className="row fade-in mt-5">
                <div className="col-md-4 ms-auto mt-2 mb-5">
                    <HeaderContent
                        text="Personal Profile"
                        subtext="Your personal profile details"
                    />
                </div>
                <div className="col-md-4 text-end">
                    <LinkBack text="Return to home screen" />
                </div>
            </HeaderStepper>
            <BodyStepper className="row fade-in">
                <div className="col-md-4 mx-auto" style={{ marginBottom: '8rem' }}>
                    <InputPicture
                        payload="avatar"
                        title="Upload your profile picture (Optional)"
                    />
                    <InputBasic
                        id="fullName"
                        type="text"
                        placeholder="Full Name"
                        outerClassName="my-3"
                        icon={UserIcons}
                        rules={{
                            function: register,
                            name: 'name',
                            rules: {
                                required: 'Full name is required',
                            },
                            errors,
                        }}
                    />
                    <InputBasic
                        id="emailAddress"
                        type="text"
                        placeholder="Email Address"
                        outerClassName="mb-3"
                        icon={MailIcons}
                        disabled
                        rules={{
                            function: register,
                            name: 'user_email',
                            rules: {
                                required: 'Email Address is required',
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: 'Entered value does not match email format',
                                },
                            },
                            errors,
                        }}
                    />
                    <div className="mb-3">
                        <Controller
                            control={control}
                            name="phone"
                            rules={{
                                required: 'Phone Number is required',
                                validate: {
                                    validPhone: (value: string) =>
                                        isValidPhoneNumber(value) ||
                                        'Invalid phone number',
                                },
                            }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <InputPhoneNew
                                    id="phone"
                                    placeholder="Phone Number"
                                    country="AU"
                                    onChange={onChange}
                                    value={value}
                                    customRef={ref}
                                />
                            )}
                        />

                        {errors.phone != null && (
                            <p
                                className="invalid-feedback d-block text-start"
                                style={{ fontSize: '0.75rem' }}
                            >
                                {errors.phone.message as string}
                            </p>
                        )}
                    </div>
                    {jobLists.map(list => (
                        <Form.Check
                            id={list.title}
                            key={list.id}
                            type="radio"
                            label={list.title}
                            className="mb-3"
                            name="useJobTitle"
                            value={list.title}
                            {...register('job_title')}
                        />
                    ))}
                    {errors.job_title != null && (
                        <p
                            className="invalid-feedback d-block text-start"
                            style={{ fontSize: '0.75rem' }}
                        >
                            {errors.job_title.message as string}
                        </p>
                    )}
                    {watch('job_title') ===
                        jobLists.find(list => list.title === 'Other')?.title && (
                        <InputBasic
                            id="jobTitleOther"
                            type="text"
                            placeholder="Enter Job Title"
                            outerClassName="mb-5"
                            innerClassName="mb-5"
                            rules={{
                                function: register,
                                name: 'user_job_title_other',
                                rules: {
                                    required: 'Please enter your job title',
                                },
                                errors,
                            }}
                        />
                    )}
                </div>
            </BodyStepper>
        </Fragment>
    );
}
