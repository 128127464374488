import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';

import UserPenIcon from '@/assets/svg/icons/user-pen.svg';
import LockIcon from '@/assets/svg/icons/lock-alt.svg';
import TrashIcon from '@/assets/svg/icons/trash.svg';

import Card from '@/components/molecules/Card';
import { Title } from '@/components/molecules/Typography';

import ManagePassword from '../components/ManagePassword';
import PersonalInformationSetting from '../components/PersonalInformationSetting';
import DeleteAccount from '../components/DeleteAccount';

import styled from 'styled-components';

import { RootState } from '@/redux/store';
import { generateInitialImageUrl } from '@/utility/Utils';

export default function PersonalSetting(): JSX.Element {
    const currentProfileState = useSelector((state: RootState) => state.currentProfile);
    const userRole = currentProfileState?.response?.role?.name;

    const [modal, setModal] = useState({
        deleteAccount: false,
        success: false,
    });

    const [count, setCount] = useState(0);
    const [defaultKey, setDefaultKey] = useState('personalInformation');

    const handleModalDeleteAccount = (key: string): void => {
        if (key === 'deleteAccount') {
            setModal(prev => ({
                ...prev,
                deleteAccount: true,
            }));

            setCount(prev => prev + 1);
        }
        setDefaultKey(key);
    };

    // if modal delete closed, then set default key to personal information
    useEffect(() => {
        if (count > 0 && !modal.deleteAccount) {
            setDefaultKey('personalInformation');
        }
    }, [modal]);

    return (
        <>
            <Title text="Personal Information" />

            <Tab.Container
                id="left-tabs"
                defaultActiveKey="personalInformation"
                onSelect={(key: any) => handleModalDeleteAccount(key)}
            >
                <Row className="mt-5">
                    <Col lg={4} className="mb-5">
                        <Card>
                            <Row>
                                <div className="d-flex align-items-center">
                                    {currentProfileState?.loading !== false ? (
                                        <CircleWhite />
                                    ) : (
                                        <ProfileImage
                                            src={
                                                currentProfileState?.response?.avatar ??
                                                generateInitialImageUrl(
                                                    currentProfileState?.response?.name,
                                                )
                                            }
                                            alt="profile-icon"
                                            className="mb-1"
                                        />
                                    )}
                                    <div className="ms-3">
                                        <h6 className="fw-semibold mb-2">
                                            {currentProfileState?.response?.name}
                                        </h6>
                                        <span className="mb-2">
                                            {currentProfileState?.response?.job_title ===
                                            'Other'
                                                ? currentProfileState?.response
                                                      ?.other_job_title
                                                : currentProfileState?.response
                                                      ?.job_title}
                                        </span>
                                    </div>
                                </div>
                            </Row>
                            <WrapperNav variant="pills" className="flex-column mt-5">
                                <Nav.Item>
                                    <Nav.Link eventKey="personalInformation">
                                        <IconContent
                                            className="icon-active me-2"
                                            icon={UserPenIcon}
                                        />
                                        <span className="text-title">
                                            Personal Information
                                        </span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="managePassword">
                                        <IconContent
                                            className="icon-active"
                                            icon={LockIcon}
                                        />
                                        <span className="text-title">
                                            Manage Password
                                        </span>
                                    </Nav.Link>
                                </Nav.Item>
                                {userRole === 'Company User' && (
                                    <Nav.Item>
                                        <Nav.Link eventKey="deleteAccount">
                                            <IconContent
                                                className="icon-active"
                                                icon={TrashIcon}
                                            />
                                            <span className="text-title">
                                                Delete Account
                                            </span>
                                        </Nav.Link>
                                    </Nav.Item>
                                )}
                            </WrapperNav>
                        </Card>
                    </Col>
                    <Col lg={8}>
                        <Tab.Content>
                            <Tab.Pane
                                eventKey="personalInformation"
                                active={defaultKey === 'personalInformation'}
                            >
                                <PersonalInformationSetting />
                            </Tab.Pane>
                            <Tab.Pane
                                eventKey="managePassword"
                                active={defaultKey === 'managePassword'}
                            >
                                <ManagePassword />
                            </Tab.Pane>
                            <Tab.Pane
                                eventKey="deleteAccount"
                                active={defaultKey === 'deleteAccount'}
                            >
                                <DeleteAccount modal={modal} setModal={setModal} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </>
    );
}

const CircleWhite = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ProfileImage = styled.img`
    border-radius: 50%;
    height: 5rem;
    width: 5rem;
    object-fit: cover;
`;

const IconContent = styled.span<{ icon: string; errors?: boolean }>`
    width: 1.5rem;
    height: 1.5rem;
    mask: url(${props => props.icon}) no-repeat center;
    mask-size: 80%;
    -webkit-mask: url(${props => props.icon}) no-repeat center;
    -webkit-mask-size: 80%;
    background-color: #000;
    position: absolute;
`;

const WrapperNav = styled(Nav)`
    .nav-item {
        margin-bottom: 0.5rem;
    }
    .nav-link {
        color: #000;
        padding: 1rem 1rem;
    }

    .nav-link:hover {
        background-color: rgba(86, 72, 251, 0.1);
        color: rgba(86, 72, 251, 1);
        border-radius: 10px;

        .icon-active {
            background-color: rgba(86, 72, 251, 1);
        }
    }

    .nav-link.active {
        background-color: rgba(86, 72, 251, 0.1);
        color: rgba(86, 72, 251, 1);
        border-radius: 10px;
    }

    .nav-link.active .icon-active {
        background-color: rgba(86, 72, 251, 1);
    }

    .text-title {
        margin-left: 2rem;
    }
`;
