/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
    Button,
    ButtonGroup,
    Dropdown,
    DropdownButton,
    OverlayTrigger,
    Popover,
} from 'react-bootstrap';

import CoverBackground from '@/assets/images/CoverProfile.png';
// import PlaceholderMessage from '@/assets/svg/icons/message-circle-question.svg';

import { HiDotsVertical, HiOutlinePaperAirplane } from 'react-icons/hi';

import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';
import Card from '@/components/molecules/Card';

import ListChatting from '../components/ListChatting';
import NewChatting from '../components/NewChatting';
import NewGroupChatting from '../components/NewGroupChatting';
import ModalManageUser from '../components/ModalManageUser';
import ModalEditGroup from '../components/ModalEditGroup';
import Select, {
    CSSObjectWithLabel,
    ValueContainerProps,
    components,
} from 'react-select';
import {
    pinChatById,
    sendMessageText,
    deleteChatChannel,
    // deleteMessage,
    exitFromGroup,
    getAllUserChat,
    clearStatePinUnpin,
    clearStateDeleteChatChannel,
    clearStateNewMessage,
    clearStateExitGroup,
    sendFileMessage,
    clearStateEditGroup,
    clearStateDeleteMessage,
    clearChatDetails,
    unpinChatById,
    // clearChatDetails,
} from '../redux/actions';

import styled from 'styled-components';
import {
    generateInitialImageUrl,
    getCredential,
    // formatDate,
    // getAustraliaToday,
    // getAustraliaYesterdayDate,
    getId,
    getInitialName,
    handleTypeFileByName,
} from '@/utility/Utils';
import moment from 'moment-timezone';
import { RootState } from '@/redux/store';
import {
    AiOutlineArrowDown,
    AiOutlineArrowUp,
    AiOutlineClose,
    AiOutlineFile,
    AiOutlineUpload,
} from 'react-icons/ai';

import BasicTooltip from '@/components/atoms/Tooltips';

import { Discuss } from 'react-loader-spinner';

import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { BiChat, BiSearch } from 'react-icons/bi';
import PreviewChatFile from '../components/PreviewChatFile';
import { meiliGetChatDetails, meiliGetChatLists } from '@/utility/MeiliSearch';
import { chatTypeEnums, filterTypeEnums } from '../enums';

export default function Chat(): JSX.Element {
    const dispatch = useDispatch();
    const userId = getId();
    const fullScreenProps = useFullScreenHandle();

    const { handleSubmit } = useForm<any>({
        mode: 'onChange',
    });
    const navigate = useNavigate();

    const chatDetailsState = useSelector((state: RootState) => state.chatDetails);
    const chatReceiveMessageState = useSelector(
        (state: RootState) => state.chatReceiveMessage,
    );

    const chatPinState = useSelector((state: RootState) => state.chatPin);
    const chatUnpinState = useSelector((state: RootState) => state.chatUnpin);

    const chatChannelDeleteState = useSelector(
        (state: RootState) => state.chatChannelDelete,
    );
    const newDirectMessageState = useSelector(
        (state: RootState) => state.newDirectMessage,
    );
    const deleteChatState = useSelector((state: RootState) => state.deleteChat);
    const newGroupMessageState = useSelector((state: RootState) => state.newGroupMessage);
    const exitGroupState = useSelector((state: RootState) => state.exitGroup);
    const editGroupState = useSelector((state: RootState) => state.editGroup);

    const messagesEndRef = useRef(null);
    const uploadRef = useRef<HTMLInputElement>(null);

    const [chatUserList, setChatUserList] = useState<any>([]);
    const [isContextMenuOpen, setContextMenuOpen] = useState(false);
    // const [selectedMessage, setSelectedMessage] = useState(null);
    const [message, setMessage] = useState<any>([]);
    const [restructureMessage, setRestructureMessage] = useState<any>([]);
    const [inputMessage, setInputMessage] = useState('');
    const [heightInput, setHeightInput] = useState(45);
    const [show, setShow] = useState(0);
    const [channelId, setChannelId] = useState<number>(0);
    const [messageSuccess, setMessageSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [modal, setModal] = useState({
        pin: false,
        unpin: false,
        archive: false,
        delete: false,
        success: false,
        createGroup: false,
        manageUserGroup: false,
        exitGroup: false,
        deleteGroup: false,
        editGroup: false,
        deleteMessage: false,
        invalidUpload: false,
    });
    // const [isPreviewImage, setIsPreviewImage] = useState<string[]>([]);
    const [filePreview, setFilePreview] = useState({
        isOpen: false,
        name: '',
        link: '',
    });

    const [selectedType, setSelectedType] = useState<{
        id: number;
        name: string;
        color: string;
    }>(chatTypeEnums[0]);
    const [participants, setParticipants] = useState<any>([]);
    const [selectedParticipants, setSelectedParticipants] = useState<any>([]);
    const [filteredAttention, setFilteredAttention] = useState<any>([]);
    const [search, setSearch] = useState<{
        value: string;
        show: boolean;
    }>({
        value: '',
        show: false,
    });

    const selectedMessage = null;

    // Function for get chat list
    useEffect(() => {
        dispatch(
            getAllUserChat({
                page: 1,
                per_page: 9999,
                search: '',
                sort_by: 'name',
                sort_asc: 1,
            }),
        );
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [message]);

    // Function for get chat details
    useEffect(() => {
        if (chatDetailsState?.broadcast !== undefined) {
            setRestructureMessage(chatDetailsState?.broadcast);
        }
    }, [chatDetailsState]);

    useEffect(() => {
        if (chatDetailsState?.data !== undefined) {
            const members = chatDetailsState?.data?.members?.filter(
                (user: any) => user?.id !== userId,
            );

            const unregisterParticipants =
                chatDetailsState?.data?.unregistered_participants?.filter(
                    (user: any) => user?.id !== userId,
                );

            // change to value and label
            const newMembers = members?.map((user: any) => ({
                value: user?.email,
                label: user?.name,
            }));

            const newUnregisterParticipants = unregisterParticipants?.map(
                (user: any) => ({
                    value: user?.email,
                    label: user?.email,
                }),
            );

            // set participants
            setParticipants([...newMembers, ...newUnregisterParticipants]);

            setSelectedParticipants([]);
            setChannelId(chatDetailsState?.data?.id);
        } else {
            setChannelId(0);
        }
    }, [chatDetailsState?.data]);

    // // TODO:SENT MESSAGE NEW
    // useEffect(() => {
    //     if (chatSendMessageState?.status === 200) {
    //         // setRestructureMessage((prevMessages: any) => [
    //         //     chatSendMessageState?.response,
    //         //     ...prevMessages,
    //         // ]);
    //         setTimeout(() => {
    //             setIsMessageSent({
    //                 channelId: chatSendMessageState?.response?.message_channel_id,
    //                 status: false,
    //                 text: '',
    //             });
    //         }, 1000);
    //     }
    // }, [chatSendMessageState]);

    useEffect(() => {
        // Get Direct Message or Group Message
        if (
            chatReceiveMessageState?.response !== null &&
            chatReceiveMessageState?.response?.message_channel_id === channelId
        ) {
            setRestructureMessage((prevMessages: any) => [
                ...prevMessages,
                chatReceiveMessageState?.response,
            ]);
        }

        // Get Cs Message
        if (
            chatReceiveMessageState?.response !== null &&
            chatReceiveMessageState?.response?.message_channel_category_id === 3 &&
            chatDetailsState?.data?.is_administrator === 1
        ) {
            setRestructureMessage((prevMessages: any) => [
                ...prevMessages,
                chatReceiveMessageState?.response,
            ]);
        }
    }, [chatReceiveMessageState]);

    // Function for convert message to state
    useEffect(() => {
        if (chatDetailsState?.broadcast !== undefined) {
            const groupMap = restructureMessage?.reduce((acc: any, list: any) => {
                const createdAt = moment.unix(list?.created_at);
                const australiaTimezone = moment.tz(createdAt, 'Australia/Sydney');

                const date = australiaTimezone.format('DD/MM/YYYY');
                const time = australiaTimezone.format('HH:mm');
                const dateHumanize = moment(australiaTimezone).fromNow();

                // create for today, yesterday, then more return date
                const today = moment().tz('Australia/Sydney').format('DD/MM/YYYY');
                const yesterday = moment()
                    .tz('Australia/Sydney')
                    .subtract(1, 'days')
                    .format('DD/MM/YYYY');

                let dateHumanizeFormat = dateHumanize;
                // let dateHumanizeFormat = list?.diff_for_humans_created_at;

                if (date === today) {
                    dateHumanizeFormat = 'Today';
                } else if (date === yesterday) {
                    dateHumanizeFormat = 'Yesterday';
                } else {
                    dateHumanizeFormat = date;
                }

                if (!acc[date]) {
                    acc[date] = {
                        id: Object.keys(acc).length + 1,
                        dateHumanizeFormat,
                        date,
                        createdAt,
                        time: [],
                    };
                }

                const timeIndex = acc[date].time.findIndex((t: any) => t.time === time);

                if (timeIndex === -1) {
                    acc[date].time.push({
                        id: acc[date].time.length + 1,
                        time,
                        createAt: createdAt,
                        data: [list],
                    });
                } else {
                    acc[date].time[timeIndex].data.push(list);
                }

                // Object.keys(acc).forEach((key: any) => {
                //     acc[key].time.sort((a: any, b: any) => {
                //         return a.createAt - b.createAt;
                //     });
                // });

                return acc;
            }, {});

            const result: any = Object?.values(groupMap);

            // TODO: Fix This
            setMessage(result);
            // if (chatDetailsState?.broadcast !== undefined) {
            //     setInputMessage('');
            // } else {
            //     setMessage((prevMessages: any) => {
            //         const currentPageMessages = result.reverse();
            //         const combinedMessages = [
            //             ...currentPageMessages,
            //             ...prevMessages.flat(),
            //         ];
            //         return combinedMessages;
            //     });
            // }
        }
    }, [restructureMessage]);

    // TODO: FIx this
    // const handleScroll = (e: {
    //     target: { scrollTop: number; scrollHeight: number; clientHeight: number };
    // }): void => {
    //     const { scrollTop, scrollHeight, clientHeight } = e.target;
    //     if (scrollTop === 0 && scrollHeight !== clientHeight) {
    //         const currentPage: number = chatDetailsState?.broadcast?.data?.current_page;
    //         const lastPage: number = chatDetailsState?.broadcast?.data?.last_page;
    //         if (currentPage !== lastPage) {
    //             dispatch(
    //                 getChatDetailsById({
    //                     channel_id: chatDetailsState?.channelId,
    //                     page: currentPage + 1,
    //                     per_page: 10,
    //                 }),
    //             );
    //         }
    //     }
    // };

    // Function for pin chat
    useEffect(() => {
        if (chatPinState?.status === 200) {
            setModal(prev => ({ ...prev, pin: false }));

            meiliGetChatDetails(channelId, response => {
                dispatch({
                    type: 'CHAT_DETAILS_BROADCAST',
                    payload: response?.hits,
                });
            });

            setTimeout(() => {
                meiliGetChatLists(results => {
                    // dispatch to redux
                    dispatch({
                        type: 'CHAT_LIST_BROADCAST',
                        payload: results?.hits,
                    });

                    dispatch({
                        type: 'CHAT_DETAILS_DATA',
                        payload: results?.hits.find((item: any) => item.id === channelId),
                    });
                });

                dispatch(clearStatePinUnpin());
                setModal(prev => ({ ...prev, success: true }));
                setChannelId(chatPinState?.response?.id);
            }, 2000);
        }
    }, [chatPinState]);

    // Function to handle unpin chat
    useEffect(() => {
        if (chatUnpinState?.status === 200) {
            setModal(prev => ({ ...prev, unpin: false }));

            meiliGetChatDetails(channelId, response => {
                dispatch({
                    type: 'CHAT_DETAILS_BROADCAST',
                    payload: response?.hits,
                });
            });

            setTimeout(() => {
                meiliGetChatLists(results => {
                    // dispatch to redux
                    dispatch({
                        type: 'CHAT_LIST_BROADCAST',
                        payload: results?.hits,
                    });

                    dispatch({
                        type: 'CHAT_DETAILS_DATA',
                        payload: results?.hits.find((item: any) => item.id === channelId),
                    });
                });
                dispatch(clearStatePinUnpin());
                setModal(prev => ({ ...prev, success: true }));
                setChannelId(chatUnpinState?.response?.id);
            }, 2000);
        }
    }, [chatUnpinState]);

    // Function for delete chat direct message
    useEffect(() => {
        if (chatChannelDeleteState?.status === 200) {
            setModal(prev => ({ ...prev, delete: false }));

            setTimeout(() => {
                dispatch(clearStateDeleteChatChannel());
                setChannelId(0);
                meiliGetChatLists(results => {
                    // dispatch to redux
                    dispatch({
                        type: 'CHAT_LIST_BROADCAST',
                        payload: results?.hits,
                    });
                });
            }, 2000);
        }
    }, [chatChannelDeleteState]);

    // Function for exit group
    useEffect(() => {
        if (exitGroupState?.status === 200) {
            setModal(prev => ({ ...prev, exitGroup: false }));

            setTimeout(() => {
                dispatch(clearStateExitGroup());
                setChannelId(0);
                meiliGetChatLists(results => {
                    // dispatch to redux
                    dispatch({
                        type: 'CHAT_LIST_BROADCAST',
                        payload: results?.hits,
                    });
                });
            }, 2000);
        }
    }, [exitGroupState]);

    // Function for edit group
    useEffect(() => {
        if (editGroupState?.status === 200) {
            setModal(prev => ({ ...prev, editGroup: false }));
            setMessageSuccess('Successfully edit group chat');
            dispatch(clearStateEditGroup());

            setTimeout(() => {
                // dispatch(getChatList({ page: 1, per_page: 9999 }));
                meiliGetChatLists(results => {
                    // dispatch to redux
                    dispatch({
                        type: 'CHAT_LIST_BROADCAST',
                        payload: results?.hits,
                    });

                    dispatch({
                        type: 'CHAT_DETAILS_DATA',
                        payload: results?.hits.find((item: any) => item.id === channelId),
                    });
                });
                setModal(prev => ({ ...prev, success: true }));
                setChannelId(editGroupState?.response?.id);
            }, 2000);
        }
    }, [editGroupState]);

    // Function for create new direct message
    useEffect(() => {
        if (
            newDirectMessageState?.status === 200 &&
            newDirectMessageState?.isRedirected === false
        ) {
            const chId = newDirectMessageState?.response?.id;

            dispatch(clearStateNewMessage());

            navigate('/chat', {
                state: {
                    id: chId,
                },
            });
            setShow(0);
        }
    }, [newDirectMessageState]);

    // Function for create new group message
    useEffect(() => {
        if (
            newGroupMessageState?.status === 200 &&
            newGroupMessageState?.isRedirected === false
        ) {
            const chId = newGroupMessageState?.response?.id;
            navigate('/chat', {
                state: {
                    id: chId,
                },
            });
            setShow(0);
        }
    }, [newGroupMessageState]);

    // Function for delete chat success
    useEffect(() => {
        if (deleteChatState?.status === 200) {
            setModal(prev => ({ ...prev, deleteMessage: false }));
            dispatch(clearChatDetails());

            // dispatch(getChatList({ page: 1, per_page: 9999 }));
            // dispatch(
            //     getChatDetailsById({ channel_id: channelId, page: 1, per_page: 9999 }),
            // );

            meiliGetChatLists(results => {
                // dispatch to redux
                dispatch({
                    type: 'CHAT_LIST_BROADCAST',
                    payload: results?.hits,
                });
            });
            meiliGetChatDetails(channelId, response => {
                dispatch({
                    type: 'CHAT_DETAILS_BROADCAST',
                    payload: response?.hits,
                });

                dispatch({
                    type: 'CHAT_DETAILS_DATA',
                    payload: response?.hits.find((item: any) => item.id === channelId),
                });
            });

            setTimeout(() => {
                dispatch(clearStateDeleteMessage());
            }, 1000);
        }
    }, [deleteChatState]);

    // Function for handle outside right click
    useEffect(() => {
        const handleOutsideClick = (event: any): void => {
            if (!event.target.closest('.context-menu')) {
                setContextMenuOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    /**
     * Function for handle delete message
     *
     * @returns void
     */
    const scrollToBottom = (): void => {
        if (!search.show) {
            (messagesEndRef.current as unknown as HTMLDivElement)?.scrollIntoView({
                behavior: 'smooth',
            });
        }
    };

    /**
     * Function for handle right click
     *
     * @params event any
     * @params message any
     * @returns void
     */
    // const handleContextMenu = (event: any, message: any): void => {
    //     event.preventDefault();
    //     setSelectedMessage(message);
    //     setContextMenuOpen(true);
    // };

    /**
     * Function for handle delete message
     *
     * @returns void
     */
    // const handleDeleteMessage = (): void => {
    //     dispatch(deleteMessage({ id: selectedMessage ?? 0 }));
    // };

    /**
     * Function for handle pin chat
     *
     * @param number channelId
     * @returns void
     */
    const handlePinChat = (channelId: number): void => {
        setMessageSuccess('Successfully pinned chat');
        // dispatch(clearDetailsNewMessage());

        dispatch(pinChatById({ channel_id: channelId }));
    };

    /**
     * Function to handle unpin chat
     *
     * @param channelId number
     * @returns void
     */
    const handleUnpinChat = (channelId: number): void => {
        setMessageSuccess('Successfully unpinned chat');

        // dispatch(clearDetailsNewMessage());
        dispatch(unpinChatById({ channel_id: channelId }));
    };

    /**
     * Function for handle unpin chat
     *
     * @param number channelId
     * @returns void
     */
    const handleDeleteChat = (channelId: number): void => {
        setMessageSuccess('Successfully deleted chat');
        // dispatch(clearDetailsNewMessage());
        dispatch(deleteChatChannel({ id: channelId }));
    };

    /**
     * Function for handle exit group chat
     *
     * @param number channelId
     * @returns void
     */
    const handleExitGroupChat = (channelId: number): void => {
        setMessageSuccess('Successfully exit group chat');
        // dispatch(clearDetailsNewMessage());
        dispatch(exitFromGroup({ channel_id: channelId }));
    };

    /**
     * Function for handle group participants
     *
     * @param any data
     * @returns string
     */
    const handleGroupParticipants = (data: []): string => {
        const owner: string =
            chatDetailsState?.data?.owner_name ?? "This group doesn't have owner";
        const administrators: string[] =
            chatDetailsState?.data?.administrators?.map(
                (user: any) => user?.name ?? user?.email,
            ) ?? [];
        const participants: string[] =
            data?.map((user: any) => user?.name ?? user?.email) ?? [];

        // eslint-disable-next-line prefer-const
        let result: string[] = [];

        if (owner) {
            result.push(owner);
        }

        if (administrators.length > 0) {
            result.push(administrators.join(', '));
        }

        if (participants.length > 0) {
            result.push(participants.join(', '));
        }

        return result.join(', ');
    };

    /**
     * Function on submit
     *
     * @param any data
     * @return void
     *      To cypress unit tester
     */
    const onSubmit = (data: any): void => {
        // initialize form data
        const payload = new FormData();
        const sendBy: any = getCredential();
        const users = selectedParticipants?.map((user: any) => user?.value);

        payload.append('message', inputMessage);
        payload.append('attention_type', selectedType?.id.toString());

        if (users?.length > 0) {
            payload.append('attention_users', users?.toString() ?? '');
        }

        // Dispatch send message action with form data as payload
        dispatch(sendMessageText({ channel_id: chatDetailsState?.channelId }, payload));

        // Chat Send Message
        setRestructureMessage((prevMessages: any) => [
            ...prevMessages,
            {
                id: 99999999,
                user_email: sendBy?.email ?? '',
                data_name: null,
                data: inputMessage,
                sent_by: {
                    id: userId,
                    name: sendBy?.name ?? '',
                    email: sendBy?.email ?? '',
                },
                message_category_id: 1,
                message_category_name: 'Text',
                message_channel_id: channelId,
                attention: {
                    id: selectedType?.id,
                    name: selectedType?.name,
                    color: selectedType?.color,
                    user_names: selectedParticipants?.map(
                        (user: any) => user?.label ?? user?.value,
                    ),
                },
                created_at: moment().unix(),
                updated_at: moment().unix(),
            },
        ]);

        // replace latest message
        const userChatList = chatUserList?.find((item: any) => item?.id === channelId);
        const index = chatUserList?.indexOf(userChatList);

        const newChatList = [...chatUserList];

        newChatList[index] = {
            ...newChatList[index],
            latest_message: {
                ...newChatList[index]?.latest_message,
                data: inputMessage,
                message_category_id: 1,
                message_category_name: 'Text',
            },
        };

        setChatUserList(newChatList);
        setInputMessage('');
    };

    /**
     * Function on submit
     *
     * @param any data
     * @return void
     */
    const handleUploadFile = (e: { target: { files: any[] } }): void => {
        // initialize form data
        const payload = new FormData();
        const sendBy: any = getCredential();

        const file = e.target.files[0];
        const fileName = e.target.files[0].name;
        const fileSize = e.target.files[0].size;

        // max file size 5mb
        if (fileSize > 4500000) {
            setMessageSuccess('File size is too large, max 4.5mb');
            setModal(prev => ({ ...prev, invalidUpload: true }));
            return;
        }

        payload.append('document_name', fileName);
        payload.append('document_file', file);
        payload.append('attention_type', selectedType?.id.toString());

        // Dispatch send message file action with form data as payload
        dispatch(sendFileMessage({ channel_id: chatDetailsState?.channelId }, payload));

        const previewFile = URL.createObjectURL(e.target.files[0]);

        // add to state
        setRestructureMessage((prevMessages: any) => [
            ...prevMessages,
            {
                id: prevMessages[length - 1]?.id + 1,
                user_email: sendBy?.email ?? '',
                data_name: fileName,
                data: previewFile,
                sent_by: {
                    id: userId,
                    name: sendBy?.name ?? '',
                    email: sendBy?.email ?? '',
                },
                message_category_id: 2,
                message_category_name: 'Document',
                message_channel_id: channelId,
                attention: {
                    id: selectedType?.id,
                    name: selectedType?.name,
                    color: selectedType?.color,
                    user_names: selectedParticipants?.map(
                        (user: any) => user?.label ?? user?.value,
                    ),
                },
                created_at: moment().unix(),
                updated_at: moment().unix(),
            },
        ]);

        // replace latest message
        const userChatList = chatUserList?.find((item: any) => item?.id === channelId);
        const index = chatUserList?.indexOf(userChatList);

        const newChatList = [...chatUserList];
        newChatList[index] = {
            ...newChatList[index],
            latest_message: {
                ...newChatList[index]?.latest_message,
                data_name: fileName,
                data: fileName,
                message_category_id: 2,
                message_category_name: 'Document',
            },
        };

        setChatUserList(newChatList);
    };

    /**
     * Function for handle keydown
     *
     * @param event { target: { scrollHeight: number }; key: string; shiftKey: any; preventDefault: () => any; }
     * @returns void
     */
    const handleKeyDown = (event: {
        target: { scrollHeight: number };
        key: string;
        shiftKey: any;
        preventDefault: () => any;
    }): void => {
        if (event.key === 'Enter' && !event.shiftKey) {
            if (inputMessage !== '') {
                onSubmit(inputMessage);
            }
            event.preventDefault();
            setHeightInput(45);
        } else if (event.key === 'Enter' && event.shiftKey) {
            // expand textarea when user presses Shift + Enter
            setHeightInput(event.target.scrollHeight);
        }
    };

    const [highlightCount, setHighlightCount] = useState({
        total: 0,
        current: 0,
    });

    const handleCloseSearch = (): void => {
        meiliGetChatDetails(channelId, response => {
            dispatch({
                type: 'CHAT_DETAILS_BROADCAST',
                payload: response?.hits,
            });
        });
        setHighlightCount({
            total: 0,
            current: 0,
        });
        setSearch(prev => ({
            value: '',
            show: false,
        }));
    };

    /**
     * Function for handle search text in chat
     *
     * @param event { target: { value: any } }
     * @returns void
     */
    const handleSearchChat = (search: string): void => {
        setSearch((prev: any) => ({
            ...prev,
            value: search,
        }));

        if (search === '') {
            meiliGetChatDetails(channelId, response => {
                dispatch({
                    type: 'CHAT_DETAILS_BROADCAST',
                    payload: response?.hits,
                });
            });
            setHighlightCount({
                total: 0,
                current: 0,
            });

            setFilteredAttention([]);

            return;
        }

        const data = restructureMessage?.map((item: any) => {
            let highlightedText = '';
            let currentText = item.data;

            while (
                typeof currentText === 'string' &&
                currentText.toLowerCase().includes(search.toLowerCase())
            ) {
                const index = currentText.toLowerCase().indexOf(search.toLowerCase());

                highlightedText +=
                    currentText.substring(0, index) +
                    `<span class="highlight-content">${currentText.substring(
                        index,
                        index + search.length,
                    )}</span>`;

                currentText = currentText.substring(index + search.length);
            }

            highlightedText += String(currentText);

            return {
                ...item,
                highlightedData: highlightedText,
            };
        });

        setRestructureMessage(data);

        // 1 - 10, length 9

        // focus on last highlight text
        setTimeout(() => {
            const highlightedText = document.querySelectorAll('.highlight-content');
            const lastHighlightedText = highlightedText[highlightedText.length - 1];

            if (highlightedText.length > 0) {
                setHighlightCount({
                    total: highlightedText.length,
                    current: highlightedText.length,
                });

                if (lastHighlightedText) {
                    lastHighlightedText.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });

                    // lastHighlightedText.classList.add('highlight-content-focus');
                }
            } else {
                setHighlightCount({
                    total: 0,
                    current: 0,
                });
            }
        }, 500);
    };

    const handleFilterByAttention = (
        data: Array<{
            label: string;
            value: string;
        }>,
    ): void => {
        setFilteredAttention(data);

        const ids = data.map((item: any) => item.value);

        if (ids.length === 0) {
            meiliGetChatDetails(channelId, response => {
                dispatch({
                    type: 'CHAT_DETAILS_BROADCAST',
                    payload: response?.hits,
                });
            });
            setHighlightCount({
                total: 0,
                current: 0,
            });

            return;
        }

        meiliGetChatDetails(channelId, response => {
            // collect all ids from attention, then filter by id
            const result = response?.hits.filter((item: any) =>
                ids.includes(item?.attention?.id),
            );

            dispatch({
                type: 'CHAT_DETAILS_BROADCAST',
                payload: result,
            });
        });
    };

    /**
     * Function for handle focus on highlight text
     * @returns
     */
    const handleNavigateUpHighlight = (current: number): void => {
        const highlightedText = document.querySelectorAll('.highlight-content');

        if (current === 1) {
            return;
        }

        highlightedText[current - 2].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });

        highlightedText[current - 2].classList.add('highlight-content-focus');

        setHighlightCount({
            total: highlightedText.length,
            current: current - 1,
        });
    };

    const handleNavigateDownHighlight = (current: number): void => {
        const highlightedText = document.querySelectorAll('.highlight-content');

        if (current === highlightedText.length) {
            return;
        }

        highlightedText[current].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });

        highlightedText[current].classList.add('highlight-content-focus');

        setHighlightCount({
            total: highlightedText.length,
            current: current + 1,
        });
    };

    /**
     * Function for handle change on input
     *
     * @param event { target: { value: any } }
     * @returns void
     */
    const handleChange = (event: { target: { value: any } }): void => {
        setInputMessage(event.target.value);
    };

    /**
     * Function for handle message body
     *
     * @param { sendBy: number; message: string; time: string}
     * @return JSX.Element
     */
    const MessageContainer = ({
        sendBy,
        sendByName,
        message,
        time,
        category,
        index,
        downloadName,
        attention,
        highlight,
    }: {
        sendBy: number;
        sendByName: string;
        message: string;
        time: string;
        category: string;
        index: number;
        downloadName: string;
        attention: {
            id: number;
            name: string;
            color: string;
            user_names: string[];
        };
        highlight: string;
    }): JSX.Element => {
        const typeUser = (type: number): string => {
            if (type === userId) {
                return 'sender';
            }
            return 'receiver';
        };

        /**
         * Function for handle picture
         *
         * @param number type
         * @returns string
         */
        const handlePicture = (type: number): string => {
            const profile = chatDetailsState?.data?.participants?.find(
                (user: any) => user.id === type,
            )?.avatar;

            if (profile === undefined) {
                return chatDetailsState?.data?.owner_avatar;
            }
            return profile;
        };

        /**
         * Function for handle type file by name
         * @param string fileName
         * @returns JSX.Element
         */
        const handleFileType = (fileName: string): JSX.Element => {
            switch (handleTypeFileByName(fileName)) {
                case 'image':
                    return (
                        <div className="image-content">
                            <img
                                src={message}
                                style={{
                                    objectFit: 'contain',
                                    borderRadius: 10,
                                    width: 150,
                                    height: 'auto',
                                }}
                                alt="image"
                                role="button"
                                onClick={() =>
                                    setFilePreview({
                                        isOpen: true,
                                        name: downloadName,
                                        link: message,
                                    })
                                }
                            />
                            <div>
                                {sendBy !== userId &&
                                    chatDetailsState?.data
                                        ?.message_channel_category_name !==
                                        'Direct Message' && (
                                        <span
                                            className="text-muted"
                                            style={{
                                                fontSize: 10,
                                            }}
                                        >
                                            {sendByName}
                                        </span>
                                    )}
                            </div>
                        </div>
                    );

                default:
                    return (
                        <div className="file-content">
                            <AiOutlineFile className="mb-1" />
                            {/* TODO: FILE VIEWER */}
                            <span
                                className="ms-2 link-file"
                                style={{
                                    maxWidth: 350,
                                    wordBreak: 'break-all',
                                }}
                                role="button"
                                onClick={() =>
                                    setFilePreview({
                                        isOpen: true,
                                        name: downloadName,
                                        link: message,
                                    })
                                }
                            >
                                {downloadName}
                            </span>
                            <div>
                                {sendBy !== userId &&
                                    chatDetailsState?.data
                                        ?.message_channel_category_name !==
                                        'Direct Message' && (
                                        <span
                                            className="text-muted"
                                            style={{
                                                fontSize: 10,
                                            }}
                                        >
                                            {sendByName}
                                        </span>
                                    )}
                            </div>
                        </div>
                    );
            }
        };

        return (
            <div ref={messagesEndRef} className={`message-container ${typeUser(sendBy)}`}>
                <div className="image-container">
                    {sendBy !== userId && (
                        <img
                            src={
                                handlePicture(sendBy) ??
                                generateInitialImageUrl(sendByName)
                            }
                            alt="avatar"
                            style={{
                                objectFit: 'cover',
                            }}
                        />
                    )}
                </div>

                <ul className="message">
                    <li>
                        <div
                            className={`${
                                category === 'Text' ? 'message-content' : 'upload-content'
                            } ${typeUser(sendBy)}`}
                        >
                            <div
                                className={`d-flex align-items-center ${
                                    typeUser(sendBy)
                                        ? 'justify-content-end'
                                        : 'justify-content-start'
                                }`}
                            >
                                {chatDetailsState?.data?.is_administrator === 0 && (
                                    <div
                                        style={{
                                            transform: 'translateY(2px)',
                                        }}
                                    >
                                        <CircleTypeChat color={attention?.color} />
                                    </div>
                                )}
                                {category === 'Text' ? (
                                    <div className="w-100">
                                        {highlight !== undefined ? (
                                            <>
                                                {attention?.user_names?.[0] !== null &&
                                                    attention?.user_names?.length > 0 && (
                                                        <span
                                                            style={{
                                                                fontSize: 14,
                                                                color: '#fff',
                                                                textDecoration:
                                                                    'underline',
                                                            }}
                                                            className="fw-semibold"
                                                        >
                                                            Att:{' '}
                                                            {attention?.user_names?.join(
                                                                ', ',
                                                            )}
                                                            <br />
                                                        </span>
                                                    )}
                                                <p
                                                    id="text-message"
                                                    className={'mb-0'}
                                                    dangerouslySetInnerHTML={{
                                                        __html: highlight,
                                                    }}
                                                ></p>
                                            </>
                                        ) : (
                                            <p id="text-message" className={'mb-0'}>
                                                {attention?.user_names?.[0] !== null &&
                                                    attention?.user_names?.length > 0 && (
                                                        <span
                                                            style={{
                                                                fontSize: 14,
                                                                color: '#fff',
                                                                textDecoration:
                                                                    'underline',
                                                            }}
                                                            className="fw-semibold"
                                                        >
                                                            Att:{' '}
                                                            {attention?.user_names?.join(
                                                                ', ',
                                                            )}
                                                            <br />
                                                        </span>
                                                    )}
                                                {message}
                                            </p>
                                        )}
                                        {sendBy !== userId &&
                                            chatDetailsState?.data
                                                ?.message_channel_category_name !==
                                                'Direct Message' && (
                                                <span className="user-name">
                                                    {sendByName}
                                                </span>
                                            )}
                                    </div>
                                ) : (
                                    handleFileType(downloadName)
                                )}
                            </div>
                        </div>
                        <span className="fw-semibold time-chat">{time}</span>
                    </li>
                </ul>

                {selectedMessage === index && isContextMenuOpen && (
                    <div
                        style={{
                            position: 'absolute',
                            zIndex: 1,
                            backgroundColor: '#fff',
                            borderRadius: 10,
                            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <button
                            className="btn btn-danger text-white"
                            style={{
                                fontSize: 12,
                            }}
                            onClick={() =>
                                setModal(prev => ({ ...prev, deleteMessage: true }))
                            }
                        >
                            Delete
                        </button>
                    </div>
                )}
            </div>
        );
    };

    const ProfilePopover = (data: any): JSX.Element => (
        <PopperWrapper
            id="popover-basic"
            style={{
                borderRadius: '1rem',
            }}
        >
            <Popover.Header as="div" className="p-0 mb-5">
                <CoverSelected cover={CoverBackground}>
                    <ProfileSelected
                        src={
                            data?.avatar ??
                            generateInitialImageUrl(data?.name ?? data?.email)
                        }
                    />
                </CoverSelected>
            </Popover.Header>
            <Popover.Body className="text-center mt-3">
                <h6 className="fw-semibold">{data?.name ?? data?.email}</h6>
                <p className="mb-1 text-muted details">
                    {data?.company_name ?? 'No Company Name'} •{' '}
                    {data?.job_title ?? 'No Job Title'}
                </p>
                <p className="mb-1 text-muted details">{data?.email}</p>
                <p className="mb-1 text-muted details">{data?.phone}</p>
            </Popover.Body>
        </PopperWrapper>
    );

    const GroupProfilePopover = (data: any): JSX.Element => (
        <PopperWrapper
            id="popover-basic"
            style={{
                borderRadius: '1rem',
                minWidth: 300,
            }}
        >
            <Popover.Header as="div" className="border-bottom px-3 py-4">
                <h6 className="fw-semibold w-100 mb-0">{data?.name}</h6>
                <p className="text-muted details mb-0">
                    {data?.description ?? 'Description'}
                </p>
            </Popover.Header>
            <Popover.Body className="">
                <div className="mb-3">
                    <h6
                        className="fw-semibold"
                        style={{
                            fontSize: 14,
                        }}
                    >
                        Owner
                    </h6>
                    <div className="d-flex">
                        <div className="d-flex flex-column">
                            <div className="d-flex align-items-center mb-2">
                                <img
                                    src={
                                        data?.owner_avatar ??
                                        generateInitialImageUrl(
                                            data?.owner_name ?? 'No Owner Name',
                                        )
                                    }
                                    alt="avatar"
                                    style={{
                                        objectFit: 'cover',
                                        width: 30,
                                        height: 30,
                                        borderRadius: '50%',
                                    }}
                                />
                                <span
                                    className="ms-2"
                                    style={{
                                        fontSize: 12,
                                    }}
                                >
                                    {data?.owner_name ?? 'No Owner Name'}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    <h6
                        className="fw-semibold"
                        style={{
                            fontSize: 14,
                        }}
                    >
                        Administrator
                    </h6>
                    <div className="d-flex">
                        <div className="d-flex flex-column">
                            {data?.administrators?.map((item: any, index: number) => (
                                <div
                                    key={index}
                                    className="d-flex align-items-center mb-2"
                                >
                                    <img
                                        src={
                                            item?.avatar ??
                                            generateInitialImageUrl(item?.name)
                                        }
                                        alt="avatar"
                                        style={{
                                            objectFit: 'cover',
                                            width: 30,
                                            height: 30,
                                            borderRadius: '50%',
                                        }}
                                    />
                                    <span
                                        className="ms-2"
                                        style={{
                                            fontSize: 12,
                                        }}
                                    >
                                        {item?.name}
                                    </span>
                                </div>
                            ))}
                            {data?.administrators?.length === 0 && (
                                <span
                                    className="ms-2"
                                    style={{
                                        fontSize: 12,
                                    }}
                                >
                                    No Administrator
                                </span>
                            )}
                        </div>
                    </div>
                </div>

                <div className="">
                    <h6
                        className="fw-semibold"
                        style={{
                            fontSize: 14,
                        }}
                    >
                        Members
                    </h6>
                    <div className="d-flex">
                        <div className="d-flex flex-column">
                            {data?.participants?.map((item: any, index: number) => (
                                <div
                                    key={index}
                                    className="d-flex align-items-center mb-2"
                                >
                                    <img
                                        src={
                                            item?.avatar ??
                                            generateInitialImageUrl(item?.name)
                                        }
                                        alt="avatar"
                                        style={{
                                            objectFit: 'cover',
                                            width: 30,
                                            height: 30,
                                            borderRadius: '50%',
                                        }}
                                    />
                                    <span
                                        className="ms-2"
                                        style={{
                                            fontSize: 12,
                                        }}
                                    >
                                        {item?.name}
                                    </span>
                                </div>
                            ))}
                            {data?.participants?.length === 0 && (
                                <span
                                    className="ms-2"
                                    style={{
                                        fontSize: 12,
                                    }}
                                >
                                    No Members
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </Popover.Body>
        </PopperWrapper>
    );

    const selectAttentionStyle = {
        control: (
            baseStyles: CSSObjectWithLabel,
            state: {
                isFocused: boolean;
                isDisabled: boolean;
                isHover: boolean;
            },
        ) => ({
            ...baseStyles,
            boxShadow: 'none',
            borderRadius: 30,
            minWidth: 150,
            height: 28,
            minHeight: 28,
            borderColor: 'transparent',
            backgroundColor: state?.isDisabled ? 'white' : 'white',
            '&:hover': {
                borderColor: 'transparent',
            },
        }),
        valueContainer: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            fontSize: '14px',
            paddingLeft: 20,
            top: -5,
        }),
        option: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            fontSize: '14px',
        }),
        placeholder: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            fontSize: '14px',
        }),
        singleValue: (
            baseStyles: CSSObjectWithLabel,
            state: {
                isDisabled: boolean;
                isFocused: boolean;
            },
        ) => ({
            ...baseStyles,
            color: state.isDisabled && '#cfcfcf',
        }),
        indicatorsSeparator: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            display: 'none',
        }),
        indicatorsContainer: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            top: -5,
            position: 'relative',
        }),
    };

    const filterAttentionStyle = {
        container: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            display: 'inline-block',
        }),
        control: (
            baseStyles: CSSObjectWithLabel,
            state: {
                isFocused: boolean;
                isDisabled: boolean;
                isHover: boolean;
            },
        ) => ({
            ...baseStyles,
            boxShadow: 'none',
            borderRadius: 30,
            minWidth: 150,
            borderColor: 'transparent',
            backgroundColor: state?.isDisabled ? 'transparent' : 'transparent',
            '&:hover': {
                borderColor: 'transparent',
            },
        }),
        valueContainer: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            fontSize: '14px',
            paddingLeft: 20,
        }),
        option: (
            baseStyles: CSSObjectWithLabel,
            // state: {
            //     isFocused: boolean;
            //     isSelected: boolean;
            //     isHover: boolean;
            // },
        ) => ({
            ...baseStyles,
            fontSize: '14px',
        }),
        placeholder: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            fontSize: '14px',
        }),
        singleValue: (
            baseStyles: CSSObjectWithLabel,
            state: {
                isDisabled: boolean;
                isFocused: boolean;
            },
        ) => ({
            ...baseStyles,
            color: state.isDisabled && '#cfcfcf',
        }),
        indicatorSeparator: (baseStyles: CSSObjectWithLabel) => ({
            display: 'none',
        }),
        indicatorContainer: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            padding: 2,
        }),
    };

    const ValueContainer = ({ children, ...props }: ValueContainerProps<any>): any => {
        let [values, input] = children as any;

        if (Array.isArray(values)) {
            // const val = (i: number): unknown => values[i].props.children;
            const { length } = values;
            values = `${length} selected`;
        }

        return (
            <components.ValueContainer {...props}>
                {values}
                {input}
            </components.ValueContainer>
        );
    };
    return (
        <WrapperRow>
            <div className="row row equal-cols">
                {show === 0 && (
                    <ListChatting
                        setShow={setShow}
                        channelId={channelId}
                        setChannelId={setChannelId}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        setMessage={setMessage}
                        setRestructureMessage={setRestructureMessage}
                        chatUserList={chatUserList}
                        setChatUserList={setChatUserList}
                        search={search}
                        setSearch={setSearch}
                    />
                )}
                {show === 1 && <NewChatting setShow={setShow} />}
                {show === 2 && <NewGroupChatting setShow={setShow} />}

                <ChatCanvas className="col-lg-8 col-xl-9 mt-3 mt-lg-0">
                    {channelId > 0 ? (
                        <div>
                            <FullScreen handle={fullScreenProps}>
                                <Card
                                    classOuter="px-0 border-0 bg-white-second"
                                    classBody="px-0"
                                >
                                    <ChatHeader className="px-4 pb-3 pt-2 d-flex justify-content-between">
                                        {isLoading ? (
                                            <Discuss
                                                height="50"
                                                width="50"
                                                wrapperStyle={{}}
                                                colors={['#5648FB', '#5648FB']}
                                                ariaLabel="comment-loading"
                                                wrapperClass="comment-wrapper"
                                                // wrapperClassName=""
                                                visible={true}
                                            />
                                        ) : chatDetailsState?.data
                                              ?.message_channel_category_name ===
                                          'Direct Message' ? (
                                            chatDetailsState?.data?.owner_id ===
                                            userId ? (
                                                <OverlayTrigger
                                                    trigger="click"
                                                    placement="bottom"
                                                    overlay={ProfilePopover(
                                                        chatDetailsState?.data
                                                            ?.participants[0] ??
                                                            chatDetailsState?.data
                                                                ?.unregistered_participants[0],
                                                    )}
                                                    rootClose={true}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <ProfilePicture
                                                            className="chat-profile me-2"
                                                            src={
                                                                chatDetailsState?.data
                                                                    ?.participants[0]
                                                                    ?.avatar ??
                                                                generateInitialImageUrl(
                                                                    chatDetailsState?.data
                                                                        ?.participants[0]
                                                                        ?.name,
                                                                )
                                                            }
                                                            status={
                                                                chatDetailsState?.data
                                                                    ?.participants[0]
                                                                    ?.online
                                                            }
                                                        />
                                                        <div className="d-flex flex-column">
                                                            <h6 className="mb-1">
                                                                {chatDetailsState?.data
                                                                    ?.participants[0]
                                                                    ?.name ??
                                                                    chatDetailsState?.data
                                                                        ?.unregistered_participants[0]
                                                                        ?.email}
                                                            </h6>
                                                            <span className="sender-status text-muted">
                                                                {chatDetailsState?.data
                                                                    ?.participants[0]
                                                                    ?.online
                                                                    ? 'Online'
                                                                    : 'Offline'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </OverlayTrigger>
                                            ) : (
                                                <OverlayTrigger
                                                    trigger="click"
                                                    placement="bottom"
                                                    overlay={ProfilePopover(
                                                        chatDetailsState?.data
                                                            ?.members[0],
                                                    )}
                                                    rootClose={true}
                                                >
                                                    <div
                                                        className="d-flex align-items-center"
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <ProfilePicture
                                                            className="chat-profile me-2"
                                                            src={
                                                                chatDetailsState?.data
                                                                    ?.owner_avatar ??
                                                                generateInitialImageUrl(
                                                                    chatDetailsState?.data
                                                                        ?.owner_name,
                                                                )
                                                            }
                                                            status={
                                                                chatDetailsState?.data
                                                                    ?.owner?.online
                                                            }
                                                        />
                                                        <div className="d-flex flex-column">
                                                            <h6 className="mb-1">
                                                                {
                                                                    chatDetailsState?.data
                                                                        ?.owner_name
                                                                }
                                                            </h6>
                                                            <span className="sender-status text-muted">
                                                                {chatDetailsState?.data
                                                                    ?.owner_online
                                                                    ? 'Online'
                                                                    : 'Offline'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </OverlayTrigger>
                                            )
                                        ) : (
                                            <div className="d-flex align-items-center">
                                                {chatDetailsState?.data?.owner_id ===
                                                userId ? (
                                                    <DropdownButton
                                                        as={Button}
                                                        className="btn-group-name"
                                                        title={
                                                            <GroupPicture className="chat-profile me-2">
                                                                <span className="initial-name">
                                                                    {getInitialName(
                                                                        chatDetailsState
                                                                            ?.data?.name,
                                                                    )}
                                                                </span>
                                                            </GroupPicture>
                                                        }
                                                    >
                                                        {fullScreenProps.active ? null : (
                                                            <Dropdown.Item
                                                                onClick={() =>
                                                                    setModal(prev => ({
                                                                        ...prev,
                                                                        editGroup: true,
                                                                    }))
                                                                }
                                                            >
                                                                <span className="me-2">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth={1.5}
                                                                        stroke="currentColor"
                                                                        className="w-6 h-6"
                                                                        height={20}
                                                                        width={20}
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span>
                                                                    Change Group Name
                                                                </span>
                                                            </Dropdown.Item>
                                                        )}
                                                    </DropdownButton>
                                                ) : (
                                                    <OverlayTrigger
                                                        trigger="click"
                                                        placement="bottom"
                                                        overlay={GroupProfilePopover(
                                                            chatDetailsState?.data,
                                                        )}
                                                        rootClose={true}
                                                    >
                                                        <GroupPicture
                                                            className="chat-profile me-2"
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            <span className="initial-name">
                                                                {getInitialName(
                                                                    chatDetailsState?.data
                                                                        ?.name,
                                                                )}
                                                            </span>
                                                        </GroupPicture>
                                                    </OverlayTrigger>
                                                )}

                                                <div className="d-flex flex-column">
                                                    <h6 className="mb-1">
                                                        {chatDetailsState?.data?.name}
                                                    </h6>
                                                    <span className="group-name text-muted">
                                                        {handleGroupParticipants(
                                                            chatDetailsState?.data
                                                                ?.participants.length !==
                                                                0
                                                                ? chatDetailsState?.data
                                                                      ?.participants
                                                                : chatDetailsState?.data
                                                                      ?.unregistered_participants,
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="align-self-center">
                                            {chatDetailsState?.data?.is_administrator ===
                                                0 && (
                                                <SelectAttention
                                                    placeholder="Filter Attention"
                                                    className="me-2"
                                                    options={filterTypeEnums}
                                                    isMulti
                                                    isClearable={false}
                                                    hideSelectedOptions={false}
                                                    components={{
                                                        ValueContainer,
                                                    }}
                                                    styles={filterAttentionStyle as any}
                                                    onChange={(value: any) =>
                                                        handleFilterByAttention(value)
                                                    }
                                                    menuPlacement="top"
                                                    value={filteredAttention}
                                                />
                                            )}

                                            {search.show ? (
                                                <AiOutlineClose
                                                    color="rgba(131, 131, 131, 1)"
                                                    size={17}
                                                    className="me-2"
                                                    role="button"
                                                    onClick={() => handleCloseSearch()}
                                                />
                                            ) : (
                                                <BiSearch
                                                    color="rgba(131, 131, 131, 1)"
                                                    size={17}
                                                    className="me-2"
                                                    role="button"
                                                    onClick={() =>
                                                        setSearch(prev => ({
                                                            ...prev,
                                                            show: true,
                                                        }))
                                                    }
                                                />
                                            )}

                                            <DropdownButton
                                                as={Button}
                                                className="btn-option"
                                                title={
                                                    <HiDotsVertical
                                                        size={14}
                                                        color="#000"
                                                    />
                                                }
                                                drop="down-centered"
                                            >
                                                {chatDetailsState?.data
                                                    ?.message_channel_category_name ===
                                                'Direct Message' ? (
                                                    chatDetailsState?.data?.owner_id ===
                                                    userId ? (
                                                        <>
                                                            {fullScreenProps.active ? null : chatDetailsState
                                                                  ?.data?.pinned_by
                                                                  ?.length > 0 &&
                                                              Boolean(
                                                                  chatDetailsState?.data?.pinned_by.find(
                                                                      (item: any) =>
                                                                          item.id ===
                                                                          userId,
                                                                  ),
                                                              ) ? (
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        setModal(
                                                                            prev => ({
                                                                                ...prev,
                                                                                unpin: true,
                                                                            }),
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="me-2">
                                                                        <svg
                                                                            fill="currentColor"
                                                                            viewBox="0 0 32 32"
                                                                            version="1.1"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            height={18}
                                                                            width={18}
                                                                        >
                                                                            <g
                                                                                id="SVGRepo_bgCarrier"
                                                                                strokeWidth="0"
                                                                            ></g>
                                                                            <g
                                                                                id="SVGRepo_tracerCarrier"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></g>
                                                                            <g id="SVGRepo_iconCarrier">
                                                                                <path d="M31.714 11.864l-11.505-11.563c-0.248-0.249-0.605-0.35-0.948-0.266-0.341 0.083-0.613 0.339-0.717 0.674-0.692 2.228-0.773 4.245-0.244 6.084-0.049 0.034-0.095 0.070-0.138 0.113l-5.347 5.346c-1.725-0.8-3.579-1.233-5.428-1.233-1.175 0-2.327 0.174-3.424 0.515-0.334 0.104-0.59 0.375-0.674 0.714s0.014 0.698 0.261 0.947l6.843 6.887-9.568 9.72-0.832 2.192 2.011-0.777 9.793-9.72 6.932 6.977c0.189 0.192 0.447 0.295 0.709 0.295 0.079 0 0.159-0.010 0.238-0.029 0.341-0.084 0.613-0.34 0.717-0.675 0.905-2.913 0.64-6.042-0.636-8.848l5.459-5.46c0.020-0.020 0.033-0.041 0.051-0.063 0.824 0.236 1.678 0.361 2.564 0.361 1.101 0 2.268-0.158 3.468-0.531 0.334-0.104 0.59-0.375 0.674-0.714s-0.015-0.697-0.262-0.945zM18.849 25.755l-12.587-12.669c3.23-0.377 6.714 0.925 9.236 3.447 2.51 2.509 3.735 5.978 3.351 9.221zM18.757 17.392c-0.526-0.804-1.14-1.568-1.845-2.274-0.702-0.702-1.469-1.321-2.28-1.854l4.504-4.503c0.459 0.799 1.052 1.563 1.782 2.291 0.745 0.745 1.534 1.348 2.363 1.814zM22.332 9.639c-1.923-1.923-2.664-4.067-2.271-6.653l8.966 9.012c-2.583 0.37-4.738-0.403-6.695-2.36z"></path>{' '}
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                    <span>
                                                                        Unpin Chat
                                                                    </span>
                                                                </Dropdown.Item>
                                                            ) : chatDetailsState?.data
                                                                  ?.is_administrator ===
                                                              0 ? (
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        setModal(
                                                                            prev => ({
                                                                                ...prev,
                                                                                pin: true,
                                                                            }),
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="me-2">
                                                                        <svg
                                                                            fill="currentColor"
                                                                            viewBox="0 0 32 32"
                                                                            version="1.1"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            height={18}
                                                                            width={18}
                                                                        >
                                                                            <g
                                                                                id="SVGRepo_bgCarrier"
                                                                                strokeWidth="0"
                                                                            ></g>
                                                                            <g
                                                                                id="SVGRepo_tracerCarrier"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></g>
                                                                            <g id="SVGRepo_iconCarrier">
                                                                                <path d="M31.714 11.864l-11.505-11.563c-0.248-0.249-0.605-0.35-0.948-0.266-0.341 0.083-0.613 0.339-0.717 0.674-0.692 2.228-0.773 4.245-0.244 6.084-0.049 0.034-0.095 0.070-0.138 0.113l-5.347 5.346c-1.725-0.8-3.579-1.233-5.428-1.233-1.175 0-2.327 0.174-3.424 0.515-0.334 0.104-0.59 0.375-0.674 0.714s0.014 0.698 0.261 0.947l6.843 6.887-9.568 9.72-0.832 2.192 2.011-0.777 9.793-9.72 6.932 6.977c0.189 0.192 0.447 0.295 0.709 0.295 0.079 0 0.159-0.010 0.238-0.029 0.341-0.084 0.613-0.34 0.717-0.675 0.905-2.913 0.64-6.042-0.636-8.848l5.459-5.46c0.020-0.020 0.033-0.041 0.051-0.063 0.824 0.236 1.678 0.361 2.564 0.361 1.101 0 2.268-0.158 3.468-0.531 0.334-0.104 0.59-0.375 0.674-0.714s-0.015-0.697-0.262-0.945zM18.849 25.755l-12.587-12.669c3.23-0.377 6.714 0.925 9.236 3.447 2.51 2.509 3.735 5.978 3.351 9.221zM18.757 17.392c-0.526-0.804-1.14-1.568-1.845-2.274-0.702-0.702-1.469-1.321-2.28-1.854l4.504-4.503c0.459 0.799 1.052 1.563 1.782 2.291 0.745 0.745 1.534 1.348 2.363 1.814zM22.332 9.639c-1.923-1.923-2.664-4.067-2.271-6.653l8.966 9.012c-2.583 0.37-4.738-0.403-6.695-2.36z"></path>{' '}
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                    <span>Pin Chat</span>
                                                                </Dropdown.Item>
                                                            ) : null}
                                                            <Dropdown.Item
                                                                onClick={
                                                                    fullScreenProps.active
                                                                        ? fullScreenProps.exit
                                                                        : fullScreenProps.enter
                                                                }
                                                            >
                                                                <span className="me-2">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth={1.5}
                                                                        stroke="currentColor"
                                                                        width={18}
                                                                        height={18}
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span>
                                                                    {fullScreenProps.active
                                                                        ? 'Exit'
                                                                        : 'Enter'}{' '}
                                                                    Fullscreen
                                                                </span>
                                                            </Dropdown.Item>
                                                            {fullScreenProps.active ? null : chatDetailsState
                                                                  ?.data
                                                                  ?.is_administrator ===
                                                              0 ? (
                                                                <Dropdown.Item
                                                                    className="text-danger"
                                                                    onClick={() =>
                                                                        setModal(
                                                                            prev => ({
                                                                                ...prev,
                                                                                delete: true,
                                                                            }),
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="me-2">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth={
                                                                                1.5
                                                                            }
                                                                            stroke="currentColor"
                                                                            width={18}
                                                                            height={18}
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    <span>
                                                                        Delete Chat
                                                                    </span>
                                                                </Dropdown.Item>
                                                            ) : null}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {fullScreenProps.active ? null : chatDetailsState
                                                                  ?.data?.pinned_by
                                                                  ?.length > 0 &&
                                                              Boolean(
                                                                  chatDetailsState?.data?.pinned_by.find(
                                                                      (item: any) =>
                                                                          item.id ===
                                                                          userId,
                                                                  ),
                                                              ) ? (
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        setModal(
                                                                            prev => ({
                                                                                ...prev,
                                                                                unpin: true,
                                                                            }),
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="me-2">
                                                                        <svg
                                                                            fill="currentColor"
                                                                            viewBox="0 0 32 32"
                                                                            version="1.1"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            height={18}
                                                                            width={18}
                                                                        >
                                                                            <g
                                                                                id="SVGRepo_bgCarrier"
                                                                                strokeWidth="0"
                                                                            ></g>
                                                                            <g
                                                                                id="SVGRepo_tracerCarrier"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></g>
                                                                            <g id="SVGRepo_iconCarrier">
                                                                                <path d="M31.714 11.864l-11.505-11.563c-0.248-0.249-0.605-0.35-0.948-0.266-0.341 0.083-0.613 0.339-0.717 0.674-0.692 2.228-0.773 4.245-0.244 6.084-0.049 0.034-0.095 0.070-0.138 0.113l-5.347 5.346c-1.725-0.8-3.579-1.233-5.428-1.233-1.175 0-2.327 0.174-3.424 0.515-0.334 0.104-0.59 0.375-0.674 0.714s0.014 0.698 0.261 0.947l6.843 6.887-9.568 9.72-0.832 2.192 2.011-0.777 9.793-9.72 6.932 6.977c0.189 0.192 0.447 0.295 0.709 0.295 0.079 0 0.159-0.010 0.238-0.029 0.341-0.084 0.613-0.34 0.717-0.675 0.905-2.913 0.64-6.042-0.636-8.848l5.459-5.46c0.020-0.020 0.033-0.041 0.051-0.063 0.824 0.236 1.678 0.361 2.564 0.361 1.101 0 2.268-0.158 3.468-0.531 0.334-0.104 0.59-0.375 0.674-0.714s-0.015-0.697-0.262-0.945zM18.849 25.755l-12.587-12.669c3.23-0.377 6.714 0.925 9.236 3.447 2.51 2.509 3.735 5.978 3.351 9.221zM18.757 17.392c-0.526-0.804-1.14-1.568-1.845-2.274-0.702-0.702-1.469-1.321-2.28-1.854l4.504-4.503c0.459 0.799 1.052 1.563 1.782 2.291 0.745 0.745 1.534 1.348 2.363 1.814zM22.332 9.639c-1.923-1.923-2.664-4.067-2.271-6.653l8.966 9.012c-2.583 0.37-4.738-0.403-6.695-2.36z"></path>{' '}
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                    <span>
                                                                        Unpin Chat
                                                                    </span>
                                                                </Dropdown.Item>
                                                            ) : (
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        setModal(
                                                                            prev => ({
                                                                                ...prev,
                                                                                pin: true,
                                                                            }),
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="me-2">
                                                                        <svg
                                                                            fill="currentColor"
                                                                            viewBox="0 0 32 32"
                                                                            version="1.1"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width={18}
                                                                            height={18}
                                                                        >
                                                                            <g
                                                                                id="SVGRepo_bgCarrier"
                                                                                strokeWidth="0"
                                                                            ></g>
                                                                            <g
                                                                                id="SVGRepo_tracerCarrier"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></g>
                                                                            <g id="SVGRepo_iconCarrier">
                                                                                {' '}
                                                                                <path d="M31.714 11.864l-11.505-11.563c-0.248-0.249-0.605-0.35-0.948-0.266-0.341 0.083-0.613 0.339-0.717 0.674-0.692 2.228-0.773 4.245-0.244 6.084-0.049 0.034-0.095 0.070-0.138 0.113l-5.347 5.346c-1.725-0.8-3.579-1.233-5.428-1.233-1.175 0-2.327 0.174-3.424 0.515-0.334 0.104-0.59 0.375-0.674 0.714s0.014 0.698 0.261 0.947l6.843 6.887-9.568 9.72-0.832 2.192 2.011-0.777 9.793-9.72 6.932 6.977c0.189 0.192 0.447 0.295 0.709 0.295 0.079 0 0.159-0.010 0.238-0.029 0.341-0.084 0.613-0.34 0.717-0.675 0.905-2.913 0.64-6.042-0.636-8.848l5.459-5.46c0.020-0.020 0.033-0.041 0.051-0.063 0.824 0.236 1.678 0.361 2.564 0.361 1.101 0 2.268-0.158 3.468-0.531 0.334-0.104 0.59-0.375 0.674-0.714s-0.015-0.697-0.262-0.945zM18.849 25.755l-12.587-12.669c3.23-0.377 6.714 0.925 9.236 3.447 2.51 2.509 3.735 5.978 3.351 9.221zM18.757 17.392c-0.526-0.804-1.14-1.568-1.845-2.274-0.702-0.702-1.469-1.321-2.28-1.854l4.504-4.503c0.459 0.799 1.052 1.563 1.782 2.291 0.745 0.745 1.534 1.348 2.363 1.814zM22.332 9.639c-1.923-1.923-2.664-4.067-2.271-6.653l8.966 9.012c-2.583 0.37-4.738-0.403-6.695-2.36z"></path>{' '}
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                    <span>Pin Chat</span>
                                                                </Dropdown.Item>
                                                            )}
                                                            <Dropdown.Item
                                                                onClick={
                                                                    fullScreenProps.active
                                                                        ? fullScreenProps.exit
                                                                        : fullScreenProps.enter
                                                                }
                                                            >
                                                                <span className="me-2">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth={1.5}
                                                                        stroke="currentColor"
                                                                        width={18}
                                                                        height={18}
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span>
                                                                    {fullScreenProps.active
                                                                        ? 'Exit'
                                                                        : 'Enter'}{' '}
                                                                    Fullscreen
                                                                </span>
                                                            </Dropdown.Item>
                                                            {/* <Dropdown.Item
                                                        as={Link}
                                                        to="#"
                                                        onClick={() =>
                                                            setModal(prev => ({
                                                                ...prev,
                                                                archive: true,
                                                            }))
                                                        }
                                                    >
                                                        <DropdownIcon
                                                            className="dropdown-active"
                                                            icon={ArchiveIcon}
                                                        />
                                                        Archive Chat
                                                    </Dropdown.Item> */}
                                                            {fullScreenProps.active ? null : (
                                                                <Dropdown.Item
                                                                    className="text-danger"
                                                                    onClick={() =>
                                                                        setModal(
                                                                            prev => ({
                                                                                ...prev,
                                                                                delete: true,
                                                                            }),
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="me-2">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth={
                                                                                1.5
                                                                            }
                                                                            stroke="currentColor"
                                                                            width={18}
                                                                            height={18}
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    Delete Chat
                                                                </Dropdown.Item>
                                                            )}
                                                        </>
                                                    )
                                                ) : (
                                                    <>
                                                        {fullScreenProps.active ? null : chatDetailsState
                                                              ?.data?.pinned_by?.length >
                                                              0 &&
                                                          Boolean(
                                                              chatDetailsState?.data?.pinned_by.find(
                                                                  (item: any) =>
                                                                      item.id === userId,
                                                              ),
                                                          ) ? (
                                                            <Dropdown.Item
                                                                onClick={() =>
                                                                    setModal(prev => ({
                                                                        ...prev,
                                                                        unpin: true,
                                                                    }))
                                                                }
                                                            >
                                                                <span className="me-2">
                                                                    <svg
                                                                        fill="currentColor"
                                                                        viewBox="0 0 32 32"
                                                                        version="1.1"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        height={18}
                                                                        width={18}
                                                                    >
                                                                        <g
                                                                            id="SVGRepo_bgCarrier"
                                                                            strokeWidth="0"
                                                                        ></g>
                                                                        <g
                                                                            id="SVGRepo_tracerCarrier"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></g>
                                                                        <g id="SVGRepo_iconCarrier">
                                                                            <path d="M31.714 11.864l-11.505-11.563c-0.248-0.249-0.605-0.35-0.948-0.266-0.341 0.083-0.613 0.339-0.717 0.674-0.692 2.228-0.773 4.245-0.244 6.084-0.049 0.034-0.095 0.070-0.138 0.113l-5.347 5.346c-1.725-0.8-3.579-1.233-5.428-1.233-1.175 0-2.327 0.174-3.424 0.515-0.334 0.104-0.59 0.375-0.674 0.714s0.014 0.698 0.261 0.947l6.843 6.887-9.568 9.72-0.832 2.192 2.011-0.777 9.793-9.72 6.932 6.977c0.189 0.192 0.447 0.295 0.709 0.295 0.079 0 0.159-0.010 0.238-0.029 0.341-0.084 0.613-0.34 0.717-0.675 0.905-2.913 0.64-6.042-0.636-8.848l5.459-5.46c0.020-0.020 0.033-0.041 0.051-0.063 0.824 0.236 1.678 0.361 2.564 0.361 1.101 0 2.268-0.158 3.468-0.531 0.334-0.104 0.59-0.375 0.674-0.714s-0.015-0.697-0.262-0.945zM18.849 25.755l-12.587-12.669c3.23-0.377 6.714 0.925 9.236 3.447 2.51 2.509 3.735 5.978 3.351 9.221zM18.757 17.392c-0.526-0.804-1.14-1.568-1.845-2.274-0.702-0.702-1.469-1.321-2.28-1.854l4.504-4.503c0.459 0.799 1.052 1.563 1.782 2.291 0.745 0.745 1.534 1.348 2.363 1.814zM22.332 9.639c-1.923-1.923-2.664-4.067-2.271-6.653l8.966 9.012c-2.583 0.37-4.738-0.403-6.695-2.36z"></path>{' '}
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                <span>
                                                                    Unpin Group Chat
                                                                </span>
                                                            </Dropdown.Item>
                                                        ) : (
                                                            <Dropdown.Item
                                                                onClick={() =>
                                                                    setModal(prev => ({
                                                                        ...prev,
                                                                        pin: true,
                                                                    }))
                                                                }
                                                            >
                                                                <span className="me-2">
                                                                    <svg
                                                                        fill="currentColor"
                                                                        viewBox="0 0 32 32"
                                                                        version="1.1"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width={18}
                                                                        height={18}
                                                                    >
                                                                        <g
                                                                            id="SVGRepo_bgCarrier"
                                                                            strokeWidth="0"
                                                                        ></g>
                                                                        <g
                                                                            id="SVGRepo_tracerCarrier"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></g>
                                                                        <g id="SVGRepo_iconCarrier">
                                                                            {' '}
                                                                            <path d="M31.714 11.864l-11.505-11.563c-0.248-0.249-0.605-0.35-0.948-0.266-0.341 0.083-0.613 0.339-0.717 0.674-0.692 2.228-0.773 4.245-0.244 6.084-0.049 0.034-0.095 0.070-0.138 0.113l-5.347 5.346c-1.725-0.8-3.579-1.233-5.428-1.233-1.175 0-2.327 0.174-3.424 0.515-0.334 0.104-0.59 0.375-0.674 0.714s0.014 0.698 0.261 0.947l6.843 6.887-9.568 9.72-0.832 2.192 2.011-0.777 9.793-9.72 6.932 6.977c0.189 0.192 0.447 0.295 0.709 0.295 0.079 0 0.159-0.010 0.238-0.029 0.341-0.084 0.613-0.34 0.717-0.675 0.905-2.913 0.64-6.042-0.636-8.848l5.459-5.46c0.020-0.020 0.033-0.041 0.051-0.063 0.824 0.236 1.678 0.361 2.564 0.361 1.101 0 2.268-0.158 3.468-0.531 0.334-0.104 0.59-0.375 0.674-0.714s-0.015-0.697-0.262-0.945zM18.849 25.755l-12.587-12.669c3.23-0.377 6.714 0.925 9.236 3.447 2.51 2.509 3.735 5.978 3.351 9.221zM18.757 17.392c-0.526-0.804-1.14-1.568-1.845-2.274-0.702-0.702-1.469-1.321-2.28-1.854l4.504-4.503c0.459 0.799 1.052 1.563 1.782 2.291 0.745 0.745 1.534 1.348 2.363 1.814zM22.332 9.639c-1.923-1.923-2.664-4.067-2.271-6.653l8.966 9.012c-2.583 0.37-4.738-0.403-6.695-2.36z"></path>{' '}
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                <span>
                                                                    Pin Group Chat
                                                                </span>
                                                            </Dropdown.Item>
                                                        )}
                                                        {chatDetailsState?.data
                                                            ?.owner_id === userId ||
                                                        chatDetailsState?.data?.administrators?.find(
                                                            (admin: any) =>
                                                                admin.id === userId,
                                                        ) ? (
                                                            fullScreenProps.active ? null : (
                                                                <Dropdown.Item
                                                                    onClick={() =>
                                                                        setModal(
                                                                            prev => ({
                                                                                ...prev,
                                                                                manageUserGroup:
                                                                                    true,
                                                                            }),
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="me-2">
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth={
                                                                                1.5
                                                                            }
                                                                            stroke="currentColor"
                                                                            width={18}
                                                                            height={18}
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    <span>
                                                                        Manage User
                                                                    </span>
                                                                </Dropdown.Item>
                                                            )
                                                        ) : null}
                                                        <Dropdown.Item
                                                            as={Link}
                                                            to="#"
                                                            onClick={
                                                                fullScreenProps.active
                                                                    ? fullScreenProps.exit
                                                                    : fullScreenProps.enter
                                                            }
                                                        >
                                                            <span className="me-2">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth={1.5}
                                                                    stroke="currentColor"
                                                                    width={18}
                                                                    height={18}
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                                                                    />
                                                                </svg>
                                                            </span>
                                                            <span>
                                                                {fullScreenProps.active
                                                                    ? 'Exit'
                                                                    : 'Enter'}{' '}
                                                                Fullscreen
                                                            </span>
                                                        </Dropdown.Item>
                                                        {fullScreenProps.active ? null : (
                                                            <Dropdown.Item
                                                                onClick={() =>
                                                                    setModal(prev => ({
                                                                        ...prev,
                                                                        exitGroup: true,
                                                                    }))
                                                                }
                                                            >
                                                                <span className="me-2">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth={1.5}
                                                                        stroke="currentColor"
                                                                        width={18}
                                                                        height={18}
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
                                                                        />
                                                                    </svg>
                                                                </span>
                                                                <span>Exit Group</span>
                                                            </Dropdown.Item>
                                                        )}
                                                        {fullScreenProps.active
                                                            ? null
                                                            : chatDetailsState?.data
                                                                  ?.owner_id ===
                                                                  userId && (
                                                                  <Dropdown.Item
                                                                      className="text-danger"
                                                                      onClick={() =>
                                                                          setModal(
                                                                              prev => ({
                                                                                  ...prev,
                                                                                  delete: true,
                                                                              }),
                                                                          )
                                                                      }
                                                                  >
                                                                      <span className="me-2">
                                                                          <svg
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              fill="none"
                                                                              viewBox="0 0 24 24"
                                                                              strokeWidth={
                                                                                  1.5
                                                                              }
                                                                              stroke="currentColor"
                                                                              width={18}
                                                                              height={18}
                                                                          >
                                                                              <path
                                                                                  strokeLinecap="round"
                                                                                  strokeLinejoin="round"
                                                                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                                                              />
                                                                          </svg>
                                                                      </span>
                                                                      <span>
                                                                          Delete Group
                                                                          Chat
                                                                      </span>
                                                                  </Dropdown.Item>
                                                              )}
                                                    </>
                                                )}
                                            </DropdownButton>
                                        </div>
                                    </ChatHeader>
                                    {search.show && (
                                        <ChatSearch className="px-4 py-2 d-flex align-items-center">
                                            <div className="w-75">
                                                <input
                                                    id="searchChat"
                                                    type="text"
                                                    placeholder="Search"
                                                    className="form-control search-chat"
                                                    value={search.value}
                                                    onChange={(e: {
                                                        target: { value: string };
                                                    }) =>
                                                        handleSearchChat(e.target.value)
                                                    }
                                                />
                                            </div>
                                            <span className="mx-2">
                                                {highlightCount.current} of{' '}
                                                {highlightCount.total} result
                                            </span>
                                            <AiOutlineArrowUp
                                                className="mx-1"
                                                size={20}
                                                role="button"
                                                onClick={() =>
                                                    handleNavigateUpHighlight(
                                                        highlightCount.current,
                                                    )
                                                }
                                            />
                                            <AiOutlineArrowDown
                                                className="mx-1"
                                                size={20}
                                                role="button"
                                                onClick={() =>
                                                    handleNavigateDownHighlight(
                                                        highlightCount.current,
                                                    )
                                                }
                                            />
                                        </ChatSearch>
                                    )}
                                    <ChatBody className="">
                                        <ChatScroll
                                            fullscreen={fullScreenProps.active}
                                            isSearch={search.show}
                                            isAdmin={
                                                chatDetailsState?.data
                                                    ?.is_administrator === 0
                                            }
                                        >
                                            {isLoading ? (
                                                <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                                    <Discuss
                                                        height="120"
                                                        width="120"
                                                        wrapperStyle={{}}
                                                        colors={['#5648FB', '#5648FB']}
                                                        ariaLabel="comment-loading"
                                                        wrapperClass="comment-wrapper"
                                                        // wrapperClassName=""
                                                        visible={true}
                                                    />
                                                    <span className="h5">Loading...</span>
                                                </div>
                                            ) : (
                                                message?.map(
                                                    (list: any, index: number) => (
                                                        <div key={index}>
                                                            <span className="time-group">
                                                                {list?.dateHumanizeFormat}
                                                            </span>
                                                            {list?.time.map(
                                                                (
                                                                    item: any,
                                                                    key: number,
                                                                ) => (
                                                                    <div key={key}>
                                                                        {item.data.map(
                                                                            (
                                                                                data: any,
                                                                                num: number,
                                                                            ) => (
                                                                                <div
                                                                                    key={
                                                                                        num
                                                                                    }
                                                                                >
                                                                                    <MessageContainer
                                                                                        key={
                                                                                            data?.id
                                                                                        }
                                                                                        message={
                                                                                            data?.data
                                                                                        }
                                                                                        time={
                                                                                            item?.time
                                                                                        }
                                                                                        downloadName={
                                                                                            data?.data_name
                                                                                        }
                                                                                        sendBy={
                                                                                            data
                                                                                                ?.sent_by
                                                                                                ?.id
                                                                                        }
                                                                                        sendByName={
                                                                                            data
                                                                                                ?.sent_by
                                                                                                ?.name
                                                                                        }
                                                                                        category={
                                                                                            data?.message_category_name
                                                                                        }
                                                                                        index={
                                                                                            data?.id
                                                                                        }
                                                                                        attention={
                                                                                            data?.attention
                                                                                        }
                                                                                        highlight={
                                                                                            data?.highlightedData
                                                                                        }
                                                                                    />
                                                                                    {
                                                                                        item.message_channel_category_name
                                                                                    }
                                                                                </div>
                                                                            ),
                                                                        )}
                                                                    </div>
                                                                ),
                                                            )}
                                                        </div>
                                                    ),
                                                )
                                            )}
                                        </ChatScroll>

                                        <form
                                            action="#"
                                            onSubmit={handleSubmit(onSubmit)}
                                            method="post"
                                        >
                                            <div className="px-4 py-2 d-flex justify-content-between align-items-center position-relative">
                                                {chatDetailsState?.data
                                                    ?.is_administrator === 0 && (
                                                    <CircleType
                                                        color={selectedType.color}
                                                    />
                                                )}
                                                <textarea
                                                    value={inputMessage}
                                                    onKeyDown={handleKeyDown as any}
                                                    onChange={handleChange}
                                                    placeholder="Type a message..."
                                                    style={{
                                                        height: heightInput,
                                                    }}
                                                    className="form-control input-chat"
                                                />
                                                <input
                                                    type="file"
                                                    name="file"
                                                    id="file"
                                                    hidden
                                                    ref={uploadRef}
                                                    onChange={(e: any) => {
                                                        handleUploadFile(e);
                                                    }}
                                                />
                                                <BasicTooltip text={'Upload File'}>
                                                    <button
                                                        className="btn btn-success btn-send ms-3"
                                                        type="button"
                                                        onClick={() =>
                                                            uploadRef.current?.click()
                                                        }
                                                    >
                                                        <AiOutlineUpload
                                                            size={18}
                                                            style={{
                                                                verticalAlign: 'sub',
                                                                color: '#fff',
                                                            }}
                                                        />
                                                    </button>
                                                </BasicTooltip>
                                                <BasicTooltip text={'Send'}>
                                                    <button
                                                        className="btn btn-primary btn-send ms-3"
                                                        type="submit"
                                                        disabled={inputMessage === ''}
                                                    >
                                                        <HiOutlinePaperAirplane
                                                            size={18}
                                                            style={{
                                                                verticalAlign: 'sub',
                                                            }}
                                                        />
                                                    </button>
                                                </BasicTooltip>
                                            </div>
                                            {chatDetailsState?.data?.is_administrator ===
                                                0 && (
                                                <div className="row g-4 mt-2 ps-4">
                                                    <div className="col-auto">
                                                        <SelectAttention
                                                            placeholder="Attention"
                                                            options={participants}
                                                            isMulti
                                                            isClearable={false}
                                                            hideSelectedOptions={false}
                                                            components={{
                                                                ValueContainer,
                                                            }}
                                                            styles={
                                                                selectAttentionStyle as any
                                                            }
                                                            onChange={(value: any) =>
                                                                setSelectedParticipants(
                                                                    value,
                                                                )
                                                            }
                                                            menuPlacement="top"
                                                            value={selectedParticipants}
                                                        />
                                                    </div>
                                                    {chatTypeEnums.map(
                                                        (
                                                            item: {
                                                                id: number;
                                                                name: string;
                                                                color: string;
                                                            },
                                                            index: number,
                                                        ) => (
                                                            <div
                                                                key={index}
                                                                className="col-auto"
                                                            >
                                                                <ButtonType
                                                                    className="btn fw-semibold py-1"
                                                                    type="button"
                                                                    color={item.color}
                                                                    onClick={() =>
                                                                        setSelectedType(
                                                                            item,
                                                                        )
                                                                    }
                                                                >
                                                                    {item.name}
                                                                </ButtonType>
                                                            </div>
                                                        ),
                                                    )}
                                                </div>
                                            )}
                                        </form>
                                    </ChatBody>
                                </Card>
                            </FullScreen>

                            <PreviewChatFile
                                filePreview={filePreview}
                                setFilePreview={setFilePreview}
                            />

                            <ModalDashboard
                                modal={modal.pin}
                                setModal={setModal}
                                variant="info"
                                type="pin"
                                title="Pin Chat?"
                                body="Are you sure want to pin this chat?"
                                withCancel
                                onConfirm={() =>
                                    handlePinChat(chatDetailsState.channelId)
                                }
                                loading={chatPinState?.loading}
                            />
                            <ModalDashboard
                                modal={modal.unpin}
                                setModal={setModal}
                                variant="danger"
                                type="unpin"
                                title="Unpin Chat?"
                                body="Are you sure want to unpin this chat?"
                                withCancel
                                onConfirm={() =>
                                    handleUnpinChat(chatDetailsState.channelId)
                                }
                                loading={chatUnpinState?.loading}
                            />
                            <ModalDashboard
                                modal={modal.archive}
                                setModal={setModal}
                                variant="info"
                                type="caution"
                                title="Archive Chat?"
                                body="Are you sure want to archive this chat?"
                                withCancel
                            />
                            <ModalDashboard
                                modal={modal.delete}
                                setModal={setModal}
                                variant="danger"
                                type="cancel"
                                title="Delete Chat?"
                                body="Are you sure want to delete this chat?"
                                withCancel
                                onConfirm={() =>
                                    handleDeleteChat(chatDetailsState.channelId)
                                }
                                loading={chatChannelDeleteState?.loading}
                            />
                            {/* <ModalDashboard
                modal={modal.deleteMessage}
                setModal={setModal}
                variant="danger"
                type="cancel"
                title="Delete Message?"
                body="Are you sure want to delete this message?"
                withCancel
                onConfirm={() => handleDeleteMessage()}
                // loading={chatChannelDeleteState?.loading}
            /> */}
                            <ModalDashboard
                                modal={modal.exitGroup}
                                setModal={setModal}
                                variant="danger"
                                type="cancel"
                                title="Exit from Group?"
                                body="Are you sure want to exit from this group chat?"
                                withCancel
                                onConfirm={() =>
                                    handleExitGroupChat(chatDetailsState.channelId)
                                }
                                loading={exitGroupState?.loading}
                            />
                            <ModalDashboard
                                modal={modal.success}
                                setModal={setModal}
                                variant="success"
                                type="success"
                                title="Success"
                                body={messageSuccess}
                                onConfirm={() =>
                                    setModal(prev => ({ ...prev, success: false }))
                                }
                            />
                            <ModalDashboard
                                modal={modal.invalidUpload}
                                setModal={setModal}
                                variant="danger"
                                type="cancel"
                                title="Invalid File Size"
                                body={messageSuccess}
                                onConfirm={() =>
                                    setModal(prev => ({ ...prev, invalidUpload: false }))
                                }
                            />
                            <ModalManageUser
                                modal={modal.manageUserGroup}
                                setModal={setModal}
                                setChannelId={setChannelId}
                            />

                            <ModalDashboard
                                modal={modal.createGroup}
                                setModal={setModal}
                                variant="success"
                                type="success"
                                title="Success"
                                body="Successfully create new group chat"
                                onConfirm={() =>
                                    setModal(prev => ({
                                        ...prev,
                                        createGroup: false,
                                    }))
                                }
                            />

                            <ModalEditGroup modal={modal.editGroup} setModal={setModal} />
                        </div>
                    ) : (
                        <Card classOuter="px-0 border-0 bg-white-second" classBody="px-0">
                            <div
                                className="d-flex flex-column justify-content-center align-items-center"
                                style={{
                                    height: 'calc(100vh - 100px)',
                                }}
                            >
                                <BiChat size={30} color="rgb(207, 207, 207)" />
                                <p className="text-muted mb-0">No Message Select</p>
                            </div>
                        </Card>
                    )}
                </ChatCanvas>
            </div>
        </WrapperRow>
    );
}

const WrapperRow = styled.div`
    .row.equal-cols {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .row.equal-cols:before,
    .row.equal-cols:after {
        display: block;
    }

    .row.equal-cols > [class*='col-'] {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .row.equal-cols > [class*='col-'] > * {
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    }

    @media (min-width: 1200px) and (max-width: 1800px) {
        .px-xl-5 {
            padding: 0 15px !important;
        }
    }
`;

const PopperWrapper = styled(Popover)`
    .popover-header {
        background: transparent;
        border: none;
        border-radius: 1rem 1rem 0 0;
    }

    .details {
        font-size: 0.8rem;
    }
`;

const ProfileSelected = styled.img`
    //center from width and height
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    border: 0.5rem solid #fff;
    object-fit: cover;
`;

const CoverSelected = styled.div<{ cover: string }>`
    background: url(${CoverBackground}) no-repeat center;
    background-size: cover;
    width: 100%;
    height: 7rem;
    border-radius: 1rem 1rem 3rem 3rem;
`;

const ChatBody = styled.div`
    font-size: 14px;

    .time-group {
        display: block;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        margin: 1rem 0;
    }

    .receiver {
        display: flex;
        align-items: flex-end;

        img {
            width: 40px;
            height: 40px;
            border-radius: 50px;
            /* margin-right: 1rem; */
        }
    }

    .message-container {
        display: flex;
        flex-direction: row;
    }

    .message-container.receiver {
        justify-content: flex-start;
        align-items: flex-start;

        .message {
            width: 40%;
        }

        .time-chat {
            display: block;
            font-size: 12px;
        }

        .file-content {
            text-align: left;
            color: #000000;
            border-radius: 1rem 0 1rem 1rem;
            display: inline-block;

            a {
                color: #000000;
            }
        }

        .image-container {
            margin-right: 1rem;
        }
    }

    .message-container.sender {
        justify-content: flex-end;

        .message {
            width: 45%;
            text-align: right;
            margin-right: 1rem;
        }

        .time-chat {
            display: block;
            font-size: 12px;
        }

        .file-content {
            text-align: left;
            background-color: #3a3a3a;
            padding: 1rem;
            color: #ffffff;
            border-radius: 1rem 0 1rem 1rem;
            display: inline-block;

            a {
                color: #ffffff;
            }
        }
    }

    .image-container {
        display: flex;
        align-items: center;
    }

    .image-container img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .image-container p {
        margin: 0;
        font-size: 12px;
        color: gray;
    }

    .message {
        display: flex;
        flex-direction: column;
        list-style: none;
    }

    ul.message {
        padding: 0;
    }

    .message-content {
        margin-bottom: 8px;
        white-space: pre-wrap;
        max-width: 22rem;
    }

    .message-content.receiver {
        text-align: left;
        background-color: #fff;
        padding: 1rem;
        border-radius: 0 1rem 1rem 1rem;
        display: inline-block;
    }

    .message-content.sender {
        text-align: left;
        background-color: #3a3a3a;
        padding: 1rem;
        color: white;
        border-radius: 1rem 0 1rem 1rem;
        display: inline-block;
    }

    .message-content span {
        font-size: 10px;
        color: gray;
        align-self: flex-end;
    }

    .upload-content {
        margin-bottom: 8px;
        white-space: pre-wrap;
        max-width: 22rem;
    }

    .upload-content.receiver {
        text-align: left;
        background-color: #fff;
        padding: 1rem;
        border-radius: 0 1rem 1rem 1rem;
        display: inline-block;
    }

    .upload-content.sender {
        text-align: left;
        background-color: #3a3a3a;
        padding: 1rem;
        color: white;
        border-radius: 1rem 0 1rem 1rem;
        display: inline-block;
    }

    .highlight-content {
        background-color: #6486f29c;
        font-size: 14px !important;
        color: red !important;
    }

    .highlight-content-focus {
        color: yellow !important;
    }

    .input-chat {
        background-color: #fff;
        border: 1px solid transparent;
        padding: 10px 20px 5px 45px;
        resize: unset;
        width: 100%;

        border-radius: 30px;
        // border-top-right-radius: 30px;

        &:focus {
            box-shadow: none;
            outline: none;
            border: 1px solid transparent;
        }

        &.active {
            box-shadow: none;
        }

        &::placeholder {
            font-size: 13px;
            font-weight: 400;
        }

        //hidden scroll
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    }

    .btn-send {
        border-radius: 50px;
        height: 45px;
    }

    textarea:focus {
        border: 1px solid #024aff;
    }

    .link-file {
        text-decoration: none;
    }

    .link-file:hover {
        text-decoration: underline;
    }
`;

const ProfilePicture = styled.div<{ src: string; status: boolean }>`
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-image: url('${props => props.src}');
    background-size: cover;
    background-position: center;
    border: ${props => (props.status ? '3px solid #5648fb' : 'none')};
`;

const calculateHeight = (props: {
    fullscreen: boolean;
    isSearch: boolean;
    isAdmin: boolean;
}): string => {
    if (props.fullscreen) {
        return 'calc(100vh - 220px)';
    }

    if (props.isSearch) {
        return 'calc(100vh - 290px)';
    }

    if (props.isAdmin) {
        return 'calc(100vh - 350px)';
    }

    return 'calc(100vh - 230px)';
};

const ChatScroll = styled.div<{
    fullscreen: boolean;
    isSearch: boolean;
    isAdmin: boolean;
}>`
    height: ${props => calculateHeight(props)};
    overflow-y: scroll;
    padding: 1rem;

    // hidden scrollbar
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
`;

const ChatCanvas = styled.div``;

const ChatHeader = styled.div`
    border-bottom: 1px solid rgba(131, 131, 131, 0.2);

    .btn-primary {
        background-color: transparent !important;
        border: none;
        outline: none;

        &:hover {
            background-color: transparent !important;
            border: none;
            outline: none;
        }
    }

    .dropdown-item {
        font-size: 13px;
        padding: 0.5rem;

        &:hover {
            background-color: #edecfe;
            color: rgba(86, 72, 251, 1);
            border-radius: 5px;

            .dropdown-active {
                background-color: rgba(86, 72, 251, 1);
            }
        }

        &:active {
            background-color: #edecfe;
            color: rgba(86, 72, 251, 1);
            border-radius: 5px;

            .dropdown-active {
                background-color: rgba(86, 72, 251, 1);
            }
        }

        &:focus {
            background-color: #edecfe;
            color: rgba(86, 72, 251, 1);
            border-radius: 5px;

            .dropdown-active {
                background-color: rgba(86, 72, 251, 1);
            }
        }

        &:focus-visible {
            box-shadow: none;
            border: none;
            outline: none;
        }
    }

    .dropdown-menu {
        border: 1px solid rgba(131, 131, 131, 0.2);
        padding: 0.5rem;
        border-radius: 10px !important;
    }

    .sender-status {
        font-size: 13px;
    }

    .group-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 300px;
        font-size: 13px;
    }

    @media (max-width: 473px) {
        .group-name {
            width: 150px;
        }
    }

    .btn-group-name {
        .btn-primary {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .dropdown-toggle {
            //after
            border-radius: 50%;
            &:after {
                display: none;
            }
        }
    }
`;

const GroupPicture = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #5648fb;

    .initial-name {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
    }
`;

const CircleType = styled.span<{ color: string }>`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    background-color: ${props => props.color};
    position: absolute;
    left: 40px;
    top: 20px;
`;

const CircleTypeChat = styled.span<{ color: string }>`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    background-color: ${props => props.color};
    margin-right: 1rem;
`;

const ButtonType = styled.button<{ color: string }>`
    background-color: ${props => props.color};
    color: #fff;
    border-radius: 50px;
    padding: 0.5rem 2rem;
    font-size: 12px;
`;

const SelectAttention = styled(Select)`
    border-radius: 50px;
    font-size: 12px;

    &:focus {
        box-shadow: none;
        outline: none;
    }
`;

const ChatSearch = styled.div`
    .search-chat {
        border-radius: 30px;
        border: 1px solid transparent;
        padding: 10px 20px;
        width: 100%;

        &:focus {
            box-shadow: none;
            outline: none;
        }

        &::placeholder {
            font-size: 13px;
            font-weight: 400;
        }
    }
`;
