export const ALL_PROJECT_NOTIFICATION_REQUEST = 'ALL_PROJECT_NOTIFICATION_REQUEST';
export const ALL_PROJECT_NOTIFICATION_SUCCESS = 'ALL_PROJECT_NOTIFICATION_SUCCESS';
export const ALL_PROJECT_NOTIFICATION_FAIL = 'ALL_PROJECT_NOTIFICATION_FAIL';

export const ALL_UNREAD_PROJECT_NOTIFICATION_REQUEST =
    'ALL_UNREAD_PROJECT_NOTIFICATION_REQUEST';
export const ALL_UNREAD_PROJECT_NOTIFICATION_SUCCESS =
    'ALL_UNREAD_PROJECT_NOTIFICATION_SUCCESS';
export const ALL_UNREAD_PROJECT_NOTIFICATION_FAIL =
    'ALL_UNREAD_PROJECT_NOTIFICATION_FAIL';

export const FLOAT_PROJECT_NOTIFICATION_REQUEST = 'FLOAT_PROJECT_NOTIFICATION_REQUEST';
export const FLOAT_PROJECT_NOTIFICATION_SUCCESS = 'FLOAT_PROJECT_NOTIFICATION_SUCCESS';
export const FLOAT_PROJECT_NOTIFICATION_FAIL = 'FLOAT_PROJECT_NOTIFICATION_FAIL';

export const MARK_READ_PROJECT_NOTIFICATION_REQUEST =
    'MARK_READ_PROJECT_NOTIFICATION_REQUEST';
export const MARK_READ_PROJECT_NOTIFICATION_SUCCESS =
    'MARK_READ_PROJECT_NOTIFICATION_SUCCESS';
export const MARK_READ_PROJECT_NOTIFICATION_FAIL = 'MARK_READ_PROJECT_NOTIFICATION_FAIL';
export const MARK_READ_PROJECT_NOTIFICATION_RESET =
    'MARK_READ_PROJECT_NOTIFICATION_RESET';

export const MARK_UNREAD_PROJECT_NOTIFICATION_REQUEST =
    'MARK_UNREAD_PROJECT_NOTIFICATION_REQUEST';
export const MARK_UNREAD_PROJECT_NOTIFICATION_SUCCESS =
    'MARK_UNREAD_PROJECT_NOTIFICATION_SUCCESS';
export const MARK_UNREAD_PROJECT_NOTIFICATION_FAIL =
    'MARK_UNREAD_PROJECT_NOTIFICATION_FAIL';
export const MARK_UNREAD_PROJECT_NOTIFICATION_RESET =
    'MARK_UNREAD_PROJECT_NOTIFICATION_RESET';

export const DELETE_PROJECT_NOTIFICATION_REQUEST = 'DELETE_PROJECT_NOTIFICATION_REQUEST';
export const DELETE_PROJECT_NOTIFICATION_SUCCESS = 'DELETE_PROJECT_NOTIFICATION_SUCCESS';
export const DELETE_PROJECT_NOTIFICATION_FAIL = 'DELETE_PROJECT_NOTIFICATION_FAIL';
export const DELETE_PROJECT_NOTIFICATION_RESET = 'DELETE_PROJECT_NOTIFICATION_RESET';

export const NOTIFICATION_DOWNLOAD_REQUEST = 'NOTIFICATION_DOWNLOAD_REQUEST';
export const NOTIFICATION_DOWNLOAD_SUCCESS = 'NOTIFICATION_DOWNLOAD_SUCCESS';
export const NOTIFICATION_DOWNLOAD_FAIL = 'NOTIFICATION_DOWNLOAD_FAIL';
export const NOTIFICATION_DOWNLOAD_RESET = 'NOTIFICATION_DOWNLOAD_RESET';

export const CLEAR_STATE = 'CLEAR_STATE';
export const RESET_ACTIONS = 'RESET_ACTIONS';

const initialState = {
    loading: false,
    response: null,
    error: null,
    status: null,
};

export const allProjectNotificationReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_PROJECT_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_PROJECT_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_PROJECT_NOTIFICATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        // case CLEAR_STATE:
        //     return initialState;

        default:
            return state;
    }
};

export const allUnreadProjectNotificationReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case ALL_UNREAD_PROJECT_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_UNREAD_PROJECT_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                totalUnread: action.payload.meta.total,
                status: 200,
            };

        case ALL_UNREAD_PROJECT_NOTIFICATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        // case CLEAR_STATE:
        //     return initialState;

        default:
            return state;
    }
};

export const floatProjectNotificationReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case FLOAT_PROJECT_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case FLOAT_PROJECT_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case FLOAT_PROJECT_NOTIFICATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        // case CLEAR_STATE:
        //     return initialState;

        default:
            return state;
    }
};

export const projectNotificationActionReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case MARK_READ_PROJECT_NOTIFICATION_REQUEST:
        case MARK_UNREAD_PROJECT_NOTIFICATION_REQUEST:
        case DELETE_PROJECT_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case MARK_READ_PROJECT_NOTIFICATION_SUCCESS:
        case MARK_UNREAD_PROJECT_NOTIFICATION_SUCCESS:
        case DELETE_PROJECT_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case MARK_READ_PROJECT_NOTIFICATION_FAIL:
        case MARK_UNREAD_PROJECT_NOTIFICATION_FAIL:
        case DELETE_PROJECT_NOTIFICATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case RESET_ACTIONS:
            return initialState;

        default:
            return state;
    }
};

export const notificationDownloadReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        // case NOTIFICATION_DOWNLOAD_REQUEST:
        //     return {
        //         ...state,
        //         loading: true,
        //     };

        case NOTIFICATION_DOWNLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case NOTIFICATION_DOWNLOAD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case NOTIFICATION_DOWNLOAD_RESET:
            return initialState;

        default:
            return state;
    }
};
