import documentsImage7 from '@/assets/images/document-drawing/help-documents-drawings-image-7.webp';
import documentsImage8 from '@/assets/images/document-drawing/help-documents-drawings-image-8.webp';
import documentsImage3 from '@/assets/images/document-drawing/help-documents-drawings-image-3.webp';

import { Fragment } from 'react';

export default function HowToShareFile(): JSX.Element {
    const contents = [
        {
            id: 1,
            text: 'To share file or folder, you can click the share button on the option file or folder that you want to share',
            alt: 'documentsFolderPage',
            image: documentsImage7,
        },
        {
            id: 2,
            text: 'After that, you can type email and you can set the permission of the user that you want to share.',
            alt: 'documentsFolderPage',
            image: documentsImage8,
        },
        {
            id: 3,
            text: 'User will receive the folder or file that you share in their private space.',
            alt: 'documentsFolderPage',
            image: documentsImage3,
        },
    ];

    return (
        <Fragment>
            {contents.map((content, index) => (
                <div key={index} className="text-grey-300 mb-10">
                    {content.image != null && (
                        <img
                            src={content.image}
                            alt={content.alt}
                            className="img-fluid"
                        />
                    )}
                    <p className="mt-4 pl-2">{content.text}</p>
                </div>
            ))}
        </Fragment>
    );
}
