import { useSelector } from 'react-redux';
import Explorer from '@/routes/ProjectDashboard/Drawing/components/Explorer';
import { RootState } from '@/redux/store';
import { ISpace } from '@/routes/ProjectDashboard/Drawing/components/Explorer/interface';

export default function PrivateDrawing({
    handleOpenSpace,
    handleOpenFolder,
    handlePrevious,
    handleCreateSpace,
    handlePagination,
    handlePaginationFolder,
    handleModal,
    handleSearch,
    handlePreviewFile,
    handleRemoveSelected,
    selectedCheckbox,
    setSelectedCheckbox,
    handleActionMultiple,
    rightClick,
    handleActionRightClick,
    handlePreviewRevision,
    drawingSpaceParams,
    drawingFolderParams,
}: ISpace): JSX.Element {
    const allDrawingPrivateSpaceState = useSelector(
        (state: RootState) => state.allDrawingPrivateSpace,
    );

    const allDrawingPrivateFolderState = useSelector(
        (state: RootState) => state.allDrawingPrivateFolder,
    );

    return (
        <>
            <Explorer
                reduxSpace={allDrawingPrivateSpaceState}
                reduxFolder={allDrawingPrivateFolderState}
                moduleType={'drawings'}
                tab="private"
                handleOpenSpace={handleOpenSpace}
                handleOpenFolder={handleOpenFolder}
                handlePrevious={handlePrevious}
                handleCreateSpace={handleCreateSpace}
                handlePagination={handlePagination}
                handlePaginationFolder={handlePaginationFolder}
                handleModal={handleModal}
                handleSearch={handleSearch}
                handlePreviewFile={handlePreviewFile}
                handleRemoveSelected={handleRemoveSelected}
                selectedCheckbox={selectedCheckbox}
                setSelectedCheckbox={setSelectedCheckbox}
                handleActionMultiple={handleActionMultiple}
                rightClick={rightClick}
                handleActionRightClick={handleActionRightClick}
                handlePreviewRevision={handlePreviewRevision}
                drawingSpaceParams={drawingSpaceParams}
                drawingFolderParams={drawingFolderParams}
            />
        </>
    );
}
