import { Fragment, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import passwordIcon from '@/assets/svg/icons/key-alt.svg';
import userIcon from '@/assets/svg/icons/user-alt-1.svg';
import MailAlt from '@/assets/svg/icons/mail-alt-3.svg';
import googleLogo from '@/assets/svg/icons/googleLogo.svg';

import Header from '@/components/molecules/Header';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import InputBasic from '@/components/atoms/Inputs/InputBasic';
import PasswordValidation from '@/components/molecules/PasswordValidation';
import Loader from '@/components/molecules/Loader';

// import Loader from '@/components/molecules/Loader';
import { HeaderContent } from '@/components/molecules/Typography';
import ModalBasic from '@/components/atoms/Modals/ModalBasic';
import styled from 'styled-components';

import { registerCompany } from '@/routes/Authentication/Register/redux/actions';
import { RootState } from '@/redux/store';

import terms from '@/assets/json/terms.json';
interface ValidationListsProps {
    id: string;
    text: string;
    isValidate: boolean;
}

export default function Register(): JSX.Element {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const value = watch('password');

    const registerState = useSelector((state: RootState) => state.register);

    const [modal, setModal] = useState({
        agreement: false,
    });

    // const [isDisabledButton, setIsDisabledButton] = useState(true);

    const [validationLists, setValidationLists] = useState<ValidationListsProps[]>([
        {
            id: 'isMaxLength',
            text: 'At least 10 characters',
            isValidate: false,
        },
        {
            id: 'isUppercase',
            text: 'An uppercase character',
            isValidate: false,
        },
        {
            id: 'isLowercase',
            text: 'A lowercase character',
            isValidate: false,
        },
        {
            id: 'isNumber',
            text: 'A number',
            isValidate: false,
        },
        {
            id: 'isSpecialCharacter',
            text: 'A special character',
            isValidate: false,
        },
    ]);

    // For redirect to next step if response is 200
    useEffect(() => {
        if (registerState.status === 200) {
            sessionStorage.setItem('email', registerState?.response?.address);
            navigate('/register/verify', {
                state: { nextStep: true },
            });
        }
    }, [registerState]);

    // For password validation
    useEffect(() => {
        if (value !== undefined) {
            const isMaxLength = value.length >= 10;
            const isUppercase = /[A-Z]/.test(value);
            const isLowercase = /[a-z]/.test(value);
            const isNumber = /[0-9]/.test(value);
            const isSpecialCharacter = /[^a-zA-Z0-9]/.test(value);

            setValidationLists(prevState => [
                {
                    ...prevState[0],
                    isValidate: isMaxLength,
                },
                {
                    ...prevState[1],
                    isValidate: isUppercase,
                },
                {
                    ...prevState[2],
                    isValidate: isLowercase,
                },
                {
                    ...prevState[3],
                    isValidate: isNumber,
                },
                {
                    ...prevState[4],
                    isValidate: isSpecialCharacter,
                },
            ]);
        }
    }, [value]);

    /**
     * Function on submit
     *
     * @param {object} data
     * @return {void}
     * @see cypress/e2e/authentication/register/register.cy.ts
     *      To cypress unit tester
     */
    const onSubmit = (data: any): void => {
        // initialize form data
        const payload = new FormData();

        // append data to form data
        for (const key in data) {
            payload.append(key, data[key as keyof typeof data]);
        }

        // Dispatch login action with form data as payload
        dispatch(registerCompany(payload));
    };

    /**
     * Function handle google redirect
     *
     * @param {string} url
     * @returns  {void}
     */
    const handleGoogleRedirect = (): void => {
        window.location.href = (import.meta.env.VITE_BASE_URL as string) + '/auth/google';
    };

    return (
        <Fragment>
            {registerState.loading === true && <Loader />}
            <div className="container-fluid container-xs container-lg">
                <header>
                    <Header text="Return to home screen" link="/" />
                </header>
                <div className="row mt-5 g-0">
                    <div className="col-md-8 col-lg-6 col-xxl-4 mx-auto mt-3 mb-3">
                        <HeaderContent
                            text="Create your account"
                            subtext="Enter your personal details to create account"
                        />
                    </div>
                </div>
                <main>
                    <div className="row g-0">
                        <form action="#" onSubmit={handleSubmit(onSubmit)} method="post">
                            <div className="col-md-8 col-lg-6 col-xxl-4 mx-auto">
                                <InputBasic
                                    id="email"
                                    type="email"
                                    placeholder="Email"
                                    outerClassName="mb-3"
                                    icon={MailAlt}
                                    rules={{
                                        function: register,
                                        name: 'email',
                                        rules: {
                                            required: 'Email is required',
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message:
                                                    'Entered value does not match email format',
                                            },
                                        },
                                        errors,
                                    }}
                                />
                                <InputBasic
                                    id="fullName"
                                    type="text"
                                    placeholder="Full Name"
                                    outerClassName="mb-3"
                                    icon={userIcon}
                                    rules={{
                                        function: register,
                                        name: 'name',
                                        rules: {
                                            required: 'Name is required',
                                        },
                                        errors,
                                    }}
                                />
                                <InputBasic
                                    id="password"
                                    type="password"
                                    placeholder="Password"
                                    outerClassName="mb-3"
                                    icon={passwordIcon}
                                    rules={{
                                        function: register,
                                        name: 'password',
                                        rules: {
                                            required: 'Password is required',
                                            validate: {
                                                isMaxLength: (value: string) =>
                                                    value.length >= 10 ||
                                                    'At least 10 characters required',
                                                isUppercase: (value: string) =>
                                                    /[A-Z]/.test(value) ||
                                                    'An uppercase character required',
                                                isLowercase: (value: string) =>
                                                    /[a-z]/.test(value) ||
                                                    'A lowercase character required',
                                                isNumber: (value: string) =>
                                                    /[0-9]/.test(value) ||
                                                    'A number required',
                                                isSpecialCharacter: (value: string) =>
                                                    /[^a-zA-Z0-9]/.test(value) ||
                                                    'A special character required',
                                            },
                                        },
                                        errors,
                                    }}
                                />
                                <InputBasic
                                    id="confirmPassword"
                                    type="password"
                                    placeholder="Confirm Password"
                                    outerClassName="mb-3"
                                    icon={passwordIcon}
                                    rules={{
                                        function: register,
                                        name: 'password_confirmation',
                                        rules: {
                                            required: 'Confirm Password is required',
                                            validate: {
                                                isMatch: (value: string) =>
                                                    value === watch('password') ||
                                                    'Password does not match',
                                            },
                                        },
                                        errors,
                                    }}
                                />
                                <Form.Check
                                    type="checkbox"
                                    inline
                                    id="agreeTerms"
                                    label="Agree to the terms of services and privacy policy"
                                    onClick={() =>
                                        watch('agreeTerms') === false &&
                                        setModal({
                                            agreement: true,
                                        })
                                    }
                                    {...register('agreeTerms', {
                                        required:
                                            'Please agree to the terms of services and privacy policy',
                                    })}
                                />
                                {errors.agreeTerms != null && (
                                    <p
                                        className="invalid-feedback d-block text-start"
                                        style={{ fontSize: '0.75rem' }}
                                    >
                                        {errors.agreeTerms.message as string}
                                    </p>
                                )}

                                <PasswordValidation lists={validationLists} />

                                <div className="col-md-12 text-center mt-3">
                                    <ButtonBasic
                                        id="btnSignUp"
                                        type="submit"
                                        text="Create Account"
                                        className="my-3 btn-primary w-100"
                                    />

                                    <p className="mb-5" style={{ fontWeight: 600 }}>
                                        Already have an account? &nbsp;
                                        <Link to="/login">Sign In</Link>
                                    </p>
                                    <ButtonGoogle
                                        type="button"
                                        className="btn mb-5"
                                        onClick={handleGoogleRedirect}
                                    >
                                        <img
                                            src={googleLogo}
                                            alt="google login"
                                            className="mr-3"
                                            style={{
                                                width: '30px',
                                                height: '30px',
                                            }}
                                        />
                                        Sign Up with Google
                                    </ButtonGoogle>
                                </div>
                            </div>
                        </form>
                    </div>
                </main>
            </div>
            <ModalBasic
                modal={modal.agreement}
                setModal={setModal}
                title="Constructapp Terms and Conditions"
                size="lg"
            >
                <StructureList
                    className="overflow-auto pe-3"
                    style={{
                        height: '50vh',
                        fontSize: 11,
                    }}
                    // onScroll={(e: any) => handleScrollBottom(e)}
                >
                    <ol className="lists text-start">
                        {terms.map((item, indexTerms) => (
                            <Fragment key={indexTerms}>
                                <li className="mb-1">{item.title}</li>
                                <ol className="subLists mb-3">
                                    {item.lists.map((list, indexItems) => (
                                        <li key={indexItems}>
                                            {list.text}
                                            {list.subLists.length > 0 && (
                                                <ol type="a" className="subSubLists">
                                                    {list.subLists.map(
                                                        (subList, indexSubList) => (
                                                            <li key={indexSubList}>
                                                                {subList}
                                                            </li>
                                                        ),
                                                    )}
                                                </ol>
                                            )}
                                        </li>
                                    ))}
                                </ol>
                            </Fragment>
                        ))}
                    </ol>
                    <ButtonBasic
                        id="btnAgree"
                        type="button"
                        text="I have read and agree to the terms of services and privacy policy"
                        className="mt-3 btn-primary text-center"
                        onClick={() => setModal({ agreement: false })}
                        // disabled={isDisabledButton}
                    />
                </StructureList>
            </ModalBasic>
        </Fragment>
    );
}

const ButtonGoogle = styled.button`
    border-radius: 32px;
    width: 100%;
    background-color: white;
    border-color: blue;
    font-size: 14px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5648fb;
    font-weight: 500;

    &:hover {
        background-color: #5648fb;
        color: white;
        border-color: #4070ff;
    }

    @media (max-width: 419px) {
        font-size: 14px;
    }
`;

const StructureList = styled.div`
    ol {
        margin: 0;
    }
    /* ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0 0 0 15px;
    }

    ol > li {
        display: table;
        counter-increment: item;
    }

    ol > li:before {
        content: counters(item, '.') ' ';
        display: table-cell;
        padding-right: 0.6em;
    }

    li ol > li:before {
        content: counters(item, '.') ' ';
    }

    //subSubLists to a,b,c
    .subSubLists {
        list-style-type: lower-alpha !important;
        counter-reset: item;
    } */
`;
