import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';

import { getIntentStripe } from '@/routes/Dashboard/Setting/SubscriptionSetting/redux/actions';
import Loader from '@/components/molecules/Loader';
import { RootState } from '@/redux/store';

const publicKey = import.meta.env.VITE_STRIPE_PUBLISH_KEY;
// const privateKey = import.meta.env.VITE_STRIPE_PRIVATE_KEY;
// const mode = import.meta.env.VITE_MODE;
// Make sure to call `loadStripe` outside a component’s render to avoid
// recreating the `Stripe` object on every render.

// TODO: Strip environment
// const stripePromise = loadStripe(mode === 'dev' ? publicKey : privateKey);
const stripePromise = loadStripe(publicKey);

export default function Stripe({ children }: { children: JSX.Element }): JSX.Element {
    const dispatch = useDispatch();

    const intentStripeState = useSelector((state: RootState) => state.intentStripe);
    const secret: string = intentStripeState?.response?.client_secret;

    const options = {
        // passing the client secret obtained from the server
        clientSecret: secret,
        appearance: {
            labels: 'floating',
            // theme: 'stripe',
            variables: {
                colorBackground: '#ffff',
                borderRadius: '0.375rem',
                fontSizeBase: '1rem',
            },
        },
    };

    useEffect(() => {
        dispatch(getIntentStripe());
    }, []);

    return intentStripeState?.loading !== true ? (
        <Elements stripe={stripePromise} options={options as StripeElementsOptions}>
            {children}
        </Elements>
    ) : (
        <Loader />
    );
}
