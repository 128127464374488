import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Card from '@/components/molecules/Card';
import InputBasic from '@/components/atoms/Inputs/InputBasic';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';
import InputCurrency from '@/components/atoms/Inputs/InputCurrency';

import { AiOutlineCloudUpload } from 'react-icons/ai';

import {
    updateProject,
    clearStateUpdateProject,
} from '@/routes/Dashboard/Project/redux/actions';

import styled from 'styled-components';
import { RootState } from '@/redux/store';
import { getCurrentProfile } from '@/routes/Dashboard/Setting/PersonalSetting/redux/actions';
import {
    getAllProjectSector,
    getAllProjectLocation,
} from '@/routes/Dashboard/TenderBox/redux/actions';
import InputSelectControlled from '@/components/atoms/Inputs/InputSelectControlled';

export default function ManageProject(): JSX.Element {
    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    // get id from params
    const { id } = useParams();

    const inputFileRef = useRef<HTMLInputElement>(null);

    const [projectName, setProjectName] = useState('Upload Project Image');
    const [projectImage, setProjectImage] = useState('');
    const [estimateValue, setEstimateValue] = useState(0);
    const [imageError, setImageError] = useState(false);
    const [modal, setModal] = useState({
        caution: false,
        success: false,
    });

    const [sectorLists, setSectorLists] = useState<
        Array<{
            value: string;
            label: string;
        }>
    >([]);
    const [locationLists, setLocationLists] = useState<
        Array<{
            value: string;
            label: string;
        }>
    >([]);
    const [projectUrl, setProjectUrl] = useState('');

    const detailProjectState = useSelector((state: RootState) => state.detailProject);
    const updateProjectState = useSelector((state: RootState) => state.updateProject);
    const currentProfileProjectState = useSelector(
        (state: RootState) => state.currentProfileProject,
    );
    const currentProfileState = useSelector((state: RootState) => state.currentProfile);
    const allProjectSectorState = useSelector(
        (state: RootState) => state.allProjectSector,
    );
    const allProjectLocationState = useSelector(
        (state: RootState) => state.allProjectLocation,
    );

    const ownerId: number =
        currentProfileProjectState?.response?.company?.company_user?.id;
    const userId: number = currentProfileState?.response?.id;

    // Function to get all tender intention, project sector and location
    useEffect(() => {
        dispatch(getAllProjectSector());
        dispatch(getAllProjectLocation());
    }, []);

    // set default value to the state
    useEffect(() => {
        if (detailProjectState?.status === 200) {
            const { name, number, raw_value, address, location, sector } =
                detailProjectState?.response;

            setValue('name', name);
            setValue('number', number);
            setValue('address', address);
            setValue('location', location);
            setValue('sector', sector);

            setEstimateValue(raw_value);
        }
    }, [detailProjectState]);

    // Function to handle if update project success
    useEffect(() => {
        if (updateProjectState.status === 200) {
            setModal(prev => ({
                ...prev,
                caution: false,
                success: true,
            }));

            dispatch(getCurrentProfile());
            dispatch(clearStateUpdateProject());
        }
    }, [updateProjectState]);

    // Function to get all project sector
    useEffect(() => {
        if (allProjectSectorState?.status === 200) {
            const sectorList = allProjectSectorState?.response?.map((sector: string) => ({
                value: sector,
                label: sector,
            }));

            setSectorLists(sectorList);
        }
    }, [allProjectSectorState]);

    // Function to get all project location
    useEffect(() => {
        if (allProjectLocationState?.status === 200) {
            const locationList = allProjectLocationState?.response?.map(
                (location: string) => ({
                    value: location,
                    label: location,
                }),
            );

            setLocationLists(locationList);
        }
    }, [allProjectLocationState]);

    /**
     * Function to handle upload image
     *
     * @return void
     */
    const handleUpload = (): void => {
        if (inputFileRef.current !== undefined && inputFileRef.current !== null) {
            inputFileRef.current.click();

            inputFileRef.current.onchange = (e: any) => {
                const file = e.target.files[0];
                const fileName = e.target.files[0].name;
                const objectUrl = URL.createObjectURL(file);

                const fileSize = file.size / 1024 / 1024; // Convert file size to MB

                if (file !== undefined && fileSize <= 2) {
                    setProjectName(fileName);
                    setProjectImage(file);
                    setImageError(false);
                    setProjectUrl(objectUrl);
                } else {
                    setImageError(true);
                }
            };
        }
    };

    /**
     * Function on submit
     *
     * @param {object} data
     * @return {void}
     * @see cypress/e2e/authentication/login/login.cy.ts
     *      To cypress unit tester
     */
    const onSubmit = (data: any): void => {
        // initialize form data
        const payload: any = new FormData();

        // append data to form data
        for (const key in data) {
            payload.append(key, data[key as keyof typeof data]);
        }

        if (estimateValue !== undefined) {
            payload.append('value', estimateValue);
        } else {
            payload.append('value', 0);
        }

        // append image to form data
        if (projectImage !== '') {
            payload.append('picture', projectImage);
        }

        if (data.number === '') {
            payload.delete('number');
        }

        if (data.address === '') {
            payload.delete('address');
        }

        // Dispatch login action with form data as payload
        dispatch(updateProject(payload, id));
    };

    return (
        <>
            <Card classOuter="mt-4 p-3 border-0">
                <div className="row mb-5">
                    <div className="col-md-12">
                        <h1 className="card-title fw-semibold h6">Project Details</h1>
                        {ownerId === userId ? (
                            <div className="d-flex align-items-center mt-5">
                                <CirclePreviewFile
                                    id="profile-picture"
                                    file={
                                        projectUrl !== ''
                                            ? projectUrl
                                            : detailProjectState?.response?.picture
                                    }
                                    onClick={handleUpload}
                                />
                                <div className="ms-2">
                                    <p className="mb-1 h6">Upload Project Image</p>
                                    <span
                                        className="fst-italic"
                                        style={{
                                            fontSize: 12,
                                            color: 'rgba(141, 180, 223, 1)',
                                        }}
                                    >
                                        Type file : .jpg, .jpeg, .png
                                        <br />
                                        Max size : 5mb
                                        <br />
                                        Recommend Ratio : (1 : 1) max 1080px : 1080px
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <div className="d-flex align-items-center mt-5">
                                <img
                                    src={detailProjectState?.response?.picture}
                                    alt=""
                                    className="img-fluid"
                                    style={{
                                        width: 100,
                                        height: 100,
                                        objectFit: 'cover',
                                        borderRadius: '50%',
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <form action="#" onSubmit={handleSubmit(onSubmit)} method="post">
                    <div className="row mb-3">
                        <div className="col-lg-4">
                            <InputBasic
                                id="projectName"
                                type="text"
                                placeholder="Project Name"
                                innerClassName="my-0"
                                outerClassName="mt-3"
                                disabled={ownerId !== userId}
                                rules={{
                                    function: register,
                                    name: 'name',
                                    rules: {
                                        required: 'Project name is required',
                                    },
                                    errors,
                                }}
                            />
                        </div>
                        <div className="col-lg-4">
                            <InputBasic
                                id="projectAddress"
                                type="text"
                                placeholder="Project Address"
                                innerClassName="my-0"
                                outerClassName="mt-3"
                                disabled={ownerId !== userId}
                                rules={{
                                    function: register,
                                    name: 'address',
                                    rules: {
                                        // required: 'Project address is required',
                                    },
                                    errors,
                                }}
                            />
                        </div>
                        <div className="col-lg-4">
                            <InputCurrency
                                id="projectEstimate"
                                placeholder="Estimate Project Value"
                                innerClassName="my-0"
                                outerClassName="mt-3"
                                value={detailProjectState?.response?.raw_value}
                                disabled={ownerId !== userId}
                                prefix="$ "
                                // rules={{
                                //     function: register,
                                //     name: 'value',
                                //     rules: {
                                //         required: 'Estimate value is required',
                                //     },
                                //     errors,
                                // }}
                                format={{
                                    locale: 'en-US',
                                    currency: 'USD',
                                }}
                                onValueChange={(value: any) => {
                                    setEstimateValue(value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-4">
                            <InputBasic
                                id="projectNumber"
                                type="text"
                                placeholder="Existing Project Number"
                                innerClassName="my-0"
                                outerClassName="mt-3"
                                disabled={ownerId !== userId}
                                rules={{
                                    function: register,
                                    name: 'number',
                                    rules: {},
                                    errors,
                                }}
                            />
                        </div>
                        <div className="col-lg-4">
                            <div
                                className="position-relative"
                                style={{
                                    marginTop: 10,
                                }}
                            >
                                <InputSelectControlled
                                    options={locationLists}
                                    innerClassName="my-0"
                                    outerClassName="mt-3"
                                    label="Location"
                                    disabled={ownerId !== userId}
                                    rules={{
                                        function: register,
                                        name: 'location',
                                        control,
                                        rules: {
                                            required: 'Location is required',
                                        },
                                        errors,
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div
                                className="position-relative"
                                style={{
                                    marginTop: 10,
                                }}
                            >
                                <InputSelectControlled
                                    options={sectorLists}
                                    innerClassName="my-0"
                                    outerClassName="mt-3"
                                    label="Sector"
                                    disabled={ownerId !== userId}
                                    rules={{
                                        function: register,
                                        name: 'sector',
                                        rules: {
                                            required: 'Sector is required',
                                        },
                                        control,
                                        errors,
                                    }}
                                />
                            </div>
                        </div>

                        <div className="col-lg-4">
                            {ownerId === userId && (
                                <FileWrapper className="my-4" onClick={handleUpload}>
                                    <input
                                        type="file"
                                        name="file"
                                        className=""
                                        hidden
                                        ref={inputFileRef}
                                    />

                                    <div className="file d-flex justify-content-between align-items-center">
                                        <span className="form-label mb-0 text-start">
                                            {projectName}{' '}
                                        </span>
                                        <AiOutlineCloudUpload
                                            color="rgba(131, 131, 131, 1)"
                                            size={20}
                                        />
                                    </div>
                                    {imageError && (
                                        <small
                                            className="invalid-feedback d-block text-start"
                                            style={{
                                                fontSize: '0.75rem',
                                            }}
                                        >
                                            Max file size 2mb
                                        </small>
                                    )}
                                </FileWrapper>
                            )}
                        </div>
                    </div>

                    {ownerId === userId && (
                        <div className="row mt-5 text-md-end">
                            <div className="col-md-12 ms-auto mb-3">
                                <ButtonBasic
                                    text="Cancel"
                                    type="button"
                                    className="btn-outline-primary mx-1 my-1"
                                    onClick={() => {
                                        navigate('/');
                                    }}
                                />
                                <ButtonBasic
                                    id="btnSubmit"
                                    type="button"
                                    text="Save Changes"
                                    className="btn-primary mx-1 my-1"
                                    onClick={() => {
                                        setModal({ ...modal, caution: true });
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </form>
            </Card>
            <ModalDashboard
                modal={modal.caution}
                setModal={setModal}
                variant="info"
                type="caution"
                title="Save Changes?"
                body="Are you sure want to save this changes?"
                withCancel
                onConfirm={handleSubmit(onSubmit)}
                loading={updateProjectState?.loading}
            />
            <ModalDashboard
                modal={modal.success}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body="Successfully save change"
                onConfirm={() => {
                    setModal({ ...modal, success: false });
                }}
            />
        </>
    );
}

const FileWrapper = styled.div`
    cursor: pointer;

    .file {
        border-bottom: 1px solid #cfcfcf;
        padding: 0.8rem 0;
    }

    .form-label {
        font-size: 14px;
        color: rgba(131, 131, 131, 1);

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        width: 13rem;
    }
`;

const CirclePreviewFile = styled.section<{ file?: string }>`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-image: url(${props => props.file});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #ffffff;

    &:hover {
        border: 1px solid #5648fb;
        transition: 1s ease;
    }
`;
