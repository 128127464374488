import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Title } from '@/components/molecules/Typography';
import TabVertical from '@/components/organism/TabVertical';
import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';

import LiveProject from '../components/LiveProject';
import Tender from '../components/Tender';
import AddProject from '../components/AddProject';

import {
    getAllProject,
    pinProject,
    unpinProject,
    leaveProject,
    clearStatePinUnpinProject,
    clearStateLeaveProject,
    getAllTender,
} from '../redux/actions';

import { getCurrentProfile } from '../../Setting/PersonalSetting/redux/actions';

import { RootState } from '@/redux/store';
import PutTender from '../components/PutTender';
import {
    clearCloseProjectToTenderState,
    clearEditProjectTenderState,
    clearOpenProjectToTenderState,
    closeProjectToTender,
} from '../../TenderBox/redux/actions';
import EditTender from '../components/EditTender';

export default function Project(): JSX.Element {
    const pinUnpinProjectState = useSelector((state: RootState) => state.pinUnpinProject);
    const leaveProjectState = useSelector((state: RootState) => state.leaveProject);
    const openProjectToTenderState = useSelector(
        (state: RootState) => state.openProjectToTender,
    );
    const editProjectTenderState = useSelector(
        (state: RootState) => state.editProjectTender,
    );
    const closeProjectToTenderState = useSelector(
        (state: RootState) => state.closeProjectToTender,
    );

    const dispatch = useDispatch();

    const [modal, setModal] = useState({
        add: false,
        pin: false,
        unpin: false,
        success: false,
        leave: false,
        invite: false,
        openTender: false,
        editTender: false,
        tenderSuccess: false,
        closeTender: false,
    });

    // const [modalData, setModalData] = useState<{
    //     id: number;
    //     name: string;
    //     type: string;
    // }>({
    //     id: 0,
    //     name: '',
    //     type: '',
    // });

    const [params, setParams] = useState({
        page: 1,
        per_page: 10,
        search: '',
        sort_by: 'number',
        sort_asc: 1,
    });

    const [selectedProject, setSelectedProject] = useState<{
        id: number;
        name: string;
        type: string;
        tender: {
            intention: string;
            project_detail: string;
            due_date: string;
        };
    }>({
        id: 0,
        name: '',
        type: '',
        tender: {
            intention: '',
            project_detail: '',
            due_date: '',
        },
    });

    /**
     * Function to handle open modal
     *
     * @param data
     */
    const handleOpenModal = (
        data: {
            id: number;
            name: string;
            tender: {
                intention: string;
                project_detail: string;
                due_date: string;
            };
        },
        type: string,
    ): void => {
        // setModalData({ id: data.id, name: data.name, type });
        setModal(prev => ({ ...prev, [type]: true }));

        let newType = '';

        switch (type) {
            case 'openTender':
                newType = 'open tender';
                break;
            case 'editTender':
                newType = 'edit tender';
                break;
            case 'closeTender':
                newType = 'close tender';
                break;
            default:
                break;
        }

        setSelectedProject({
            id: data.id,
            name: data.name,
            type: newType,
            tender: {
                intention: data?.tender?.intention,
                project_detail: data?.tender?.project_detail,
                due_date: data?.tender?.due_date,
            },
        });
    };

    /**
     * Function to handle pin project
     *
     * @param number id
     * @return void
     */
    const handlePinProject = (id: number): void => {
        dispatch(pinProject(id));
    };

    /**
     * Function to handle unpin project
     *
     * @param number id
     * @return void
     */
    const handleUnpinProject = (id: number): void => {
        dispatch(unpinProject(id));
    };

    /**
     * Function to handle leave project
     *
     * @param number id
     * @return void
     */
    const handleLeaveProject = (id: number): void => {
        dispatch(leaveProject(id));
    };

    /**
     * Function to close tender
     *
     * @param number id
     * @return void
     */
    const handleCloseTender = (id: number): void => {
        dispatch(closeProjectToTender({ project_id: id }));
    };

    // Function to handle modal, when pin or unpin project
    useEffect(() => {
        if (pinUnpinProjectState?.status === 200) {
            dispatch(getAllProject(params));
            dispatch(getAllTender(params));
            dispatch(getCurrentProfile());

            dispatch(clearStatePinUnpinProject());

            setModal(prev => ({
                ...prev,
                pin: false,
                unpin: false,
            }));

            setModal(prev => ({
                ...prev,
                success: true,
            }));
        }
    }, [pinUnpinProjectState]);

    // Function to handle modal, when pin or unpin project
    useEffect(() => {
        if (leaveProjectState?.status === 200) {
            dispatch(getAllProject({ ...params, page: 1 }));
            dispatch(getAllTender({ ...params, page: 1 }));
            dispatch(getCurrentProfile());

            dispatch(clearStateLeaveProject());

            setModal(prev => ({
                ...prev,
                leave: false,
            }));

            setModal(prev => ({
                ...prev,
                success: true,
            }));
        }
    }, [leaveProjectState]);

    // Function to handle modal, when edit project tender success
    useEffect(() => {
        if (editProjectTenderState?.status === 200) {
            setModal(prev => ({
                ...prev,
                editTender: false,
            }));

            setTimeout(() => {
                setModal(prev => ({
                    ...prev,
                    tenderSuccess: true,
                }));
                dispatch(clearEditProjectTenderState());
                dispatch(getAllProject({ ...params, page: 1 }));
            }, 1000);
        }
    }, [editProjectTenderState]);

    // Function to handle modal, when open project to tender success
    useEffect(() => {
        if (openProjectToTenderState?.status === 200) {
            setModal(prev => ({
                ...prev,
                openTender: false,
            }));

            setTimeout(() => {
                setModal(prev => ({
                    ...prev,
                    tenderSuccess: true,
                }));
                dispatch(clearOpenProjectToTenderState());
                dispatch(getAllProject({ ...params, page: 1 }));
            }, 1000);
        }
    }, [openProjectToTenderState]);

    // Function to handle modal, when close project to tender success
    useEffect(() => {
        if (closeProjectToTenderState?.status === 200) {
            setModal(prev => ({
                ...prev,
                closeTender: false,
            }));

            setTimeout(() => {
                setModal(prev => ({
                    ...prev,
                    tenderSuccess: true,
                }));
                dispatch(clearCloseProjectToTenderState());
                dispatch(getAllProject({ ...params, page: 1 }));
            }, 1000);
        }
    }, [closeProjectToTenderState]);

    const tabItems = [
        {
            id: 'liveProject',
            title: 'Live Project',
            page: (
                <LiveProject
                    params={params}
                    setParams={setParams}
                    setModal={setModal}
                    handleOpenModal={handleOpenModal}
                />
            ),
        },
        {
            id: 'tender',
            title: 'Tender',
            page: (
                <Tender
                    params={params}
                    setParams={setParams}
                    setModal={setModal}
                    handleOpenModal={handleOpenModal}
                />
            ),
        },
    ];

    return (
        <>
            <Title text="Project List" />
            <TabVertical items={tabItems} defaultActive="liveProject" />

            <AddProject modal={modal.add} setModal={setModal} />

            <PutTender
                modal={modal.openTender}
                setModal={setModal}
                selectedProject={selectedProject}
            />

            <EditTender
                modal={modal.editTender}
                setModal={setModal}
                selectedProject={selectedProject}
            />

            <ModalDashboard
                modal={modal.pin}
                setModal={setModal}
                variant="info"
                type="pin"
                title="Pin Project?"
                body={`Are you sure want to pin this ${selectedProject.name} project?`}
                withCancel
                onConfirm={() => handlePinProject(selectedProject.id)}
                loading={pinUnpinProjectState?.loading}
            />

            <ModalDashboard
                modal={modal.unpin}
                setModal={setModal}
                variant="danger"
                type="unpin"
                title="Unpin Project?"
                body={`Are you sure want to unpin this ${selectedProject.name} project?`}
                withCancel
                onConfirm={() => handleUnpinProject(selectedProject.id)}
                loading={pinUnpinProjectState?.loading}
            />

            <ModalDashboard
                modal={modal.leave}
                setModal={setModal}
                variant="danger"
                type="leave"
                title="Leave Project?"
                body={`Are you sure want to leave this ${selectedProject.name} project?`}
                withCancel
                onConfirm={() => handleLeaveProject(selectedProject.id)}
                loading={leaveProjectState?.loading}
            />

            <ModalDashboard
                modal={modal.success}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body={`Successfully ${selectedProject.type} on ${selectedProject.name} project`}
                onConfirm={() => {
                    setModal({ ...modal, success: false });
                }}
            />

            <ModalDashboard
                modal={modal.closeTender}
                setModal={setModal}
                variant="danger"
                type="close"
                title="Close Tender?"
                body={`Are you sure want to close this ${selectedProject.name} from tender?`}
                withCancel
                onConfirm={() => handleCloseTender(selectedProject.id)}
                loading={closeProjectToTenderState?.loading}
            />

            <ModalDashboard
                modal={modal.tenderSuccess}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body={`Successfully ${selectedProject.type} on ${selectedProject.name}`}
                onConfirm={() => {
                    setModal({ ...modal, tenderSuccess: false });
                }}
            />
        </>
    );
}
