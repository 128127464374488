export const ALL_DOCUMENT_PUBLIC_SPACE_REQUEST = 'ALL_DOCUMENT_PUBLIC_SPACE_REQUEST';
export const ALL_DOCUMENT_PUBLIC_SPACE_SUCCESS = 'ALL_DOCUMENT_PUBLIC_SPACE_SUCCESS';
export const ALL_DOCUMENT_PUBLIC_SPACE_FAIL = 'ALL_DOCUMENT_PUBLIC_SPACE_FAIL';

export const ALL_DOCUMENT_PRIVATE_SPACE_REQUEST = 'ALL_DOCUMENT_PRIVATE_SPACE_REQUEST';
export const ALL_DOCUMENT_PRIVATE_SPACE_SUCCESS = 'ALL_DOCUMENT_PRIVATE_SPACE_SUCCESS';
export const ALL_DOCUMENT_PRIVATE_SPACE_FAIL = 'ALL_DOCUMENT_PRIVATE_SPACE_FAIL';

export const ALL_DOCUMENT_RESTORE_SPACE_REQUEST = 'ALL_DOCUMENT_RESTORE_SPACE_REQUEST';
export const ALL_DOCUMENT_RESTORE_SPACE_SUCCESS = 'ALL_DOCUMENT_RESTORE_SPACE_SUCCESS';
export const ALL_DOCUMENT_RESTORE_SPACE_FAIL = 'ALL_DOCUMENT_RESTORE_SPACE_FAIL';

// File
export const ALL_DOCUMENT_PUBLIC_FOLDER_REQUEST = ' ALL_DOCUMENT_PUBLIC_FOLDER_REQUEST';
export const ALL_DOCUMENT_PUBLIC_FOLDER_SUCCESS = 'ALL_DOCUMENT_PUBLIC_FOLDER_SUCCESS';
export const ALL_DOCUMENT_PUBLIC_FOLDER_FAIL = 'ALL_DOCUMENT_PUBLIC_FOLDER_FAIL';
export const ALL_DOCUMENT_PUBLIC_FOLDER_RESET = 'ALL_DOCUMENT_PUBLIC_FOLDER_RESET';

export const ALL_DOCUMENT_PRIVATE_FOLDER_REQUEST = 'ALL_DOCUMENT_PRIVATE_FOLDER_REQUEST';
export const ALL_DOCUMENT_PRIVATE_FOLDER_SUCCESS = 'ALL_DOCUMENT_PRIVATE_FOLDER_SUCCESS';
export const ALL_DOCUMENT_PRIVATE_FOLDER_FAIL = 'ALL_DOCUMENT_PRIVATE_FOLDER_FAIL';
export const ALL_DOCUMENT_PRIVATE_FOLDER_RESET = 'ALL_DOCUMENT_PRIVATE_FOLDER_RESET';

export const DETAIL_DOCUMENT_REQUEST = 'DETAIL_DOCUMENT_REQUEST';
export const DETAIL_DOCUMENT_SUCCESS = 'DETAIL_DOCUMENT_SUCCESS';
export const DETAIL_DOCUMENT_FAIL = 'DETAIL_DOCUMENT_FAIL';
export const DETAIL_DOCUMENT_RESET = 'DETAIL_DOCUMENT_RESET';

export const HISTORY_DOCUMENT_REQUEST = 'HISTORY_DOCUMENT_REQUEST';
export const HISTORY_DOCUMENT_SUCCESS = 'HISTORY_DOCUMENT_SUCCESS';
export const HISTORY_DOCUMENT_FAIL = 'HISTORY_DOCUMENT_FAIL';
export const HISTORY_DOCUMENT_RESET = 'HISTORY_DOCUMENT_RESET';

export const CREATE_DOCUMENT_FOLDER_REQUEST = ' CREATE_DOCUMENT_FOLDER_REQUEST';
export const CREATE_DOCUMENT_FOLDER_SUCCESS = 'CREATE_DOCUMENT_FOLDER_SUCCESS';
export const CREATE_DOCUMENT_FOLDER_FAIL = 'CREATE_DOCUMENT_FOLDER_FAIL';
export const CREATE_DOCUMENT_FOLDER_RESET = 'CREATE_DOCUMENT_FOLDER_RESET';

export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAIL = 'DELETE_DOCUMENT_FAIL';
export const DELETE_DOCUMENT_RESET = 'DELETE_DOCUMENT_RESET';

// restore
export const RESTORE_DOCUMENT_REQUEST = 'RESTORE_DOCUMENT_REQUEST';
export const RESTORE_DOCUMENT_SUCCESS = 'RESTORE_DOCUMENT_SUCCESS';
export const RESTORE_DOCUMENT_FAIL = 'RESTORE_DOCUMENT_FAIL';
export const RESTORE_DOCUMENT_RESET = 'RESTORE_DOCUMENT_RESET';

export const PIN_UNPIN_DOCUMENT_SPACE_REQUEST = 'PIN_UNPIN_DOCUMENT_SPACE_REQUEST';
export const PIN_UNPIN_DOCUMENT_SPACE_SUCCESS = 'PIN_UNPIN_DOCUMENT_SPACE_SUCCESS';
export const PIN_UNPIN_DOCUMENT_SPACE_FAIL = 'PIN_UNPIN_DOCUMENT_SPACE_FAIL';
export const PIN_UNPIN_DOCUMENT_SPACE_RESET = 'PIN_UNPIN_DOCUMENT_SPACE_RESET';

export const UPLOAD_DOCUMENT_FILE_REQUEST = 'UPLOAD_DOCUMENT_FILE_REQUEST';
export const UPLOAD_DOCUMENT_FILE_SUCCESS = 'UPLOAD_DOCUMENT_FILE_SUCCESS';
export const UPLOAD_DOCUMENT_FILE_FAIL = 'UPLOAD_DOCUMENT_FILE_FAIL';
export const UPLOAD_DOCUMENT_FILE_RESET = 'UPLOAD_DOCUMENT_FILE_RESET';

export const SHARED_USER_PRIVATE_DOCUMENT_REQUEST =
    'SHARED_USER_PRIVATE_DOCUMENT_REQUEST';
export const SHARED_USER_PRIVATE_DOCUMENT_SUCCESS =
    'SHARED_USER_PRIVATE_DOCUMENT_SUCCESS';
export const SHARED_USER_PRIVATE_DOCUMENT_FAIL = 'SHARED_USER_PRIVATE_DOCUMENT_FAIL';
export const SHARED_USER_PRIVATE_DOCUMENT_RESET = 'SHARED_USER_PRIVATE_DOCUMENT_RESET';

export const UPDATE_USER_PRIVATE_DOCUMENT_REQUEST =
    'UPDATE_USER_PRIVATE_DOCUMENT_REQUEST';
export const UPDATE_USER_PRIVATE_DOCUMENT_SUCCESS =
    'UPDATE_USER_PRIVATE_DOCUMENT_SUCCESS';
export const UPDATE_USER_PRIVATE_DOCUMENT_FAIL = 'UPDATE_USER_PRIVATE_DOCUMENT_FAIL';
export const UPDATE_USER_PRIVATE_DOCUMENT_RESET = 'UPDATE_USER_PRIVATE_DOCUMENT_RESET';

export const DELETE_USER_PRIVATE_DOCUMENT_REQUEST =
    'DELETE_USER_PRIVATE_DOCUMENT_REQUEST';
export const DELETE_USER_PRIVATE_DOCUMENT_SUCCESS =
    'DELETE_USER_PRIVATE_DOCUMENT_SUCCESS';
export const DELETE_USER_PRIVATE_DOCUMENT_FAIL = 'DELETE_USER_PRIVATE_DOCUMENT_FAIL';
export const DELETE_USER_PRIVATE_DOCUMENT_RESET = 'DELETE_USER_PRIVATE_DOCUMENT_RESET';

export const UPDATE_DOCUMENT_REQUEST = 'UPDATE_DOCUMENT_REQUEST';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_FAIL = 'UPDATE_DOCUMENT_FAIL';
export const UPDATE_DOCUMENT_RESET = 'UPDATE_DOCUMENT_RESET';

export const INVITE_USER_DOCUMENT_REQUEST = 'INVITE_USER_DOCUMENT_REQUEST';
export const INVITE_USER_DOCUMENT_SUCCESS = 'INVITE_USER_DOCUMENT_SUCCESS';
export const INVITE_USER_DOCUMENT_FAIL = 'INVITE_USER_DOCUMENT_FAIL';
export const INVITE_USER_DOCUMENT_RESET = 'INVITE_USER_DOCUMENT_RESET';

export const ALL_DOCUMENT_COMPARE_FILE_REQUEST = 'ALL_DOCUMENT_COMPARE_FILE_REQUEST';
export const ALL_DOCUMENT_COMPARE_FILE_SUCCESS = 'ALL_DOCUMENT_COMPARE_FILE_SUCCESS';
export const ALL_DOCUMENT_COMPARE_FILE_FAIL = 'ALL_DOCUMENT_COMPARE_FILE_FAIL';
export const ALL_DOCUMENT_COMPARE_FILE_RESET = 'ALL_DOCUMENT_COMPARE_FILE_RESET';

export const SEND_DOCUMENT_REQUEST = 'SEND_DOCUMENT_REQUEST';
export const SEND_DOCUMENT_SUCCESS = 'SEND_DOCUMENT_SUCCESS';
export const SEND_DOCUMENT_FAIL = 'SEND_DOCUMENT_FAIL';
export const SEND_DOCUMENT_RESET = 'SEND_DOCUMENT_RESET';

export const SHARE_DOCUMENT_REQUEST = 'SHARE_DOCUMENT_REQUEST';
export const SHARE_DOCUMENT_SUCCESS = 'SHARE_DOCUMENT_SUCCESS';
export const SHARE_DOCUMENT_FAIL = 'SHARE_DOCUMENT_FAIL';
export const SHARE_DOCUMENT_RESET = 'SHARE_DOCUMENT_RESET';

export const PASTE_DOCUMENT_REQUEST = 'PASTE_DOCUMENT_REQUEST';
export const PASTE_DOCUMENT_SUCCESS = 'PASTE_DOCUMENT_SUCCESS';
export const PASTE_DOCUMENT_FAIL = 'PASTE_DOCUMENT_FAIL';
export const PASTE_DOCUMENT_RESET = 'PASTE_DOCUMENT_RESET';

export const CREATE_SHARE_LINK_DOCUMENT_REQUEST = 'CREATE_SHARE_LINK_DOCUMENT_REQUEST';
export const CREATE_SHARE_LINK_DOCUMENT_SUCCESS = 'CREATE_SHARE_LINK_DOCUMENT_SUCCESS';
export const CREATE_SHARE_LINK_DOCUMENT_FAIL = 'CREATE_SHARE_LINK_DOCUMENT_FAIL';
export const CREATE_SHARE_LINK_DOCUMENT_RESET = 'CREATE_SHARE_LINK_DOCUMENT_RESET';

export const GET_SHARE_LINK_DOCUMENT_REQUEST = 'GET_SHARE_LINK_DOCUMENT_REQUEST';
export const GET_SHARE_LINK_DOCUMENT_SUCCESS = 'GET_SHARE_LINK_DOCUMENT_SUCCESS';
export const GET_SHARE_LINK_DOCUMENT_FAIL = 'GET_SHARE_LINK_DOCUMENT_FAIL';
export const GET_SHARE_LINK_DOCUMENT_RESET = 'GET_SHARE_LINK_DOCUMENT_RESET';

const initialState = {
    loading: false,
    response: null,
    error: null,
    status: null,
};

export const allDocumentPublicSpaceReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_DOCUMENT_PUBLIC_SPACE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_DOCUMENT_PUBLIC_SPACE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_DOCUMENT_PUBLIC_SPACE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        default:
            return state;
    }
};

export const allDocumentPrivateSpaceReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case ALL_DOCUMENT_PRIVATE_SPACE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_DOCUMENT_PRIVATE_SPACE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_DOCUMENT_PRIVATE_SPACE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        default:
            return state;
    }
};

export const allDocumentRestoreSpaceReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case ALL_DOCUMENT_RESTORE_SPACE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_DOCUMENT_RESTORE_SPACE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_DOCUMENT_RESTORE_SPACE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        default:
            return state;
    }
};

export const allDocumentPublicFolderReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case ALL_DOCUMENT_PUBLIC_FOLDER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_DOCUMENT_PUBLIC_FOLDER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_DOCUMENT_PUBLIC_FOLDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ALL_DOCUMENT_PUBLIC_FOLDER_RESET:
            return initialState;

        default:
            return state;
    }
};

export const allDocumentPrivateFolderReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case ALL_DOCUMENT_PRIVATE_FOLDER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_DOCUMENT_PRIVATE_FOLDER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_DOCUMENT_PRIVATE_FOLDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ALL_DOCUMENT_PRIVATE_FOLDER_RESET:
            return initialState;

        default:
            return state;
    }
};

export const createDocumentFolderReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case CREATE_DOCUMENT_FOLDER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CREATE_DOCUMENT_FOLDER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case CREATE_DOCUMENT_FOLDER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CREATE_DOCUMENT_FOLDER_RESET:
            return initialState;

        default:
            return state;
    }
};

export const detailDocumentReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DETAIL_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DETAIL_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case DETAIL_DOCUMENT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const historyDocumentReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case HISTORY_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case HISTORY_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case HISTORY_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case HISTORY_DOCUMENT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const updateDocumentReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case UPDATE_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case UPDATE_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case UPDATE_DOCUMENT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const uploadDocumentFileReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case UPLOAD_DOCUMENT_FILE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPLOAD_DOCUMENT_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case UPLOAD_DOCUMENT_FILE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case UPLOAD_DOCUMENT_FILE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const sharedUserPrivateDocumentReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case SHARED_USER_PRIVATE_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case SHARED_USER_PRIVATE_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case SHARED_USER_PRIVATE_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case SHARED_USER_PRIVATE_DOCUMENT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const updateUserPrivateDocumentReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case UPDATE_USER_PRIVATE_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case UPDATE_USER_PRIVATE_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case UPDATE_USER_PRIVATE_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case UPDATE_USER_PRIVATE_DOCUMENT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const deleteUserPrivateDocumentReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case DELETE_USER_PRIVATE_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_USER_PRIVATE_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DELETE_USER_PRIVATE_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case DELETE_USER_PRIVATE_DOCUMENT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const deleteDocumentReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DELETE_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DELETE_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case DELETE_DOCUMENT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const restoreDocumentReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case RESTORE_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case RESTORE_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case RESTORE_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case RESTORE_DOCUMENT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const pinUnpinDocumentSpaceReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case PIN_UNPIN_DOCUMENT_SPACE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case PIN_UNPIN_DOCUMENT_SPACE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case PIN_UNPIN_DOCUMENT_SPACE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case PIN_UNPIN_DOCUMENT_SPACE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const pasteDocumentReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case PASTE_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case PASTE_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case PASTE_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case PASTE_DOCUMENT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const inviteUserDocumentReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case INVITE_USER_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case INVITE_USER_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case INVITE_USER_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case INVITE_USER_DOCUMENT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const allDocumentCompareFileReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_DOCUMENT_COMPARE_FILE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_DOCUMENT_COMPARE_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_DOCUMENT_COMPARE_FILE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case ALL_DOCUMENT_COMPARE_FILE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const sendDocumentReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case SEND_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case SEND_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case SEND_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case SEND_DOCUMENT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const shareDocumentReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case SHARE_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case SHARE_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case SHARE_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case SHARE_DOCUMENT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const createShareLinkDocumentReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case CREATE_SHARE_LINK_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CREATE_SHARE_LINK_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case CREATE_SHARE_LINK_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CREATE_SHARE_LINK_DOCUMENT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const getShareLinkDocumentReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case GET_SHARE_LINK_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case GET_SHARE_LINK_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case GET_SHARE_LINK_DOCUMENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case GET_SHARE_LINK_DOCUMENT_RESET:
            return initialState;

        default:
            return state;
    }
};
