import { Fragment } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import { Title } from '@/components/molecules/Typography';

import CompanyInformationSetting from '../components/CompanyInformationSetting';
import ManageInhouse from '../components/ManageInhouse';
import PendingInvitation from '../components/PendingInvitation';
import InviteInhouse from '../components/ManageInhouse/InviteInhouse';

import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

export default function CompanySetting(): JSX.Element {
    const location = useLocation();

    // get #invite from url
    const invitePage = location.hash;

    return (
        <Fragment>
            <Title text="Company Information Settings" />
            <WrapperTabs
                defaultActiveKey="companyInformation"
                id="nav"
                className="my-5 border-0"
            >
                <Tab eventKey="companyInformation" title="Company Information">
                    <CompanyInformationSetting />
                </Tab>
                <Tab eventKey="manageInhouse" title="Manage Inhouse">
                    {invitePage !== '' ? <InviteInhouse /> : <ManageInhouse />}
                </Tab>
                <Tab eventKey="pendingInvitation" title="Pending Invitation">
                    <PendingInvitation />
                </Tab>
            </WrapperTabs>
        </Fragment>
    );
}

const WrapperTabs = styled(Tabs)`
    .nav-item {
        padding: 1rem;
    }
    .nav-link {
        border-radius: 20px;
        background: rgba(86, 72, 251, 0.5);
        color: #fff !important;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        padding: 0.2rem 1rem;

        &.active {
            color: #fff !important;
            background-color: #5648fb;
        }
    }
`;
