import { RiErrorWarningLine } from 'react-icons/ri';

interface BadgeBasicProps {
    text: string;
    variant: string;
    className?: string;
}

export default function BadgeBasic(props: BadgeBasicProps): JSX.Element {
    const { text, variant, className } = props;

    /**
     * Function for set background color
     *
     * @returns string
     * @param value string
     */
    const backgroundList = (value: string): string => {
        switch (value) {
            case 'primary':
                return '#5648fb';
            case 'danger':
                return 'rgba(251, 96, 86, 0.1)';
            case 'warning':
                return '#5648fb';
            case 'info':
                return '#5648fb';
            case 'grey':
                return 'rgba(48, 48, 48, 0.2)';
            case 'success':
                return 'rgba(6, 189, 128, 0.2)';
            case 'red':
                return 'rgb(255, 50, 40)';

            default:
                return '';
        }
    };

    /**
     * Function for set color text
     *
     * @returns string
     * @param value
     */
    const colorList = (value: string): string => {
        switch (value) {
            case 'primary':
                return '#5648fb';
            case 'danger':
                return '#FB6056';
            case 'warning':
                return '#5648fb';
            case 'info':
                return '#5648fb';
            case 'grey':
                return 'rgba(48, 48, 48, 1)';
            case 'success':
                return 'rgba(6, 189, 128, 1)';
            case 'red':
                return 'rgb(255, 255, 255)';

            default:
                return '';
        }
    };

    /**
     * Function for set icon
     *
     * @returns JSX.Element
     */
    const iconList = (): JSX.Element => {
        switch (variant) {
            case 'primary':
                return <RiErrorWarningLine size={18} className="me-2" />;
            case 'danger':
                return <RiErrorWarningLine size={18} className="me-2" />;
            case 'warning':
                return <RiErrorWarningLine size={18} className="me-2" />;
            case 'info':
                return <RiErrorWarningLine size={18} className="me-2" />;
            case 'secondary':
                return <RiErrorWarningLine size={18} className="me-2" />;

            default:
                return <></>;
        }
    };

    return (
        <>
            <span
                className={`badge ${className ?? ''}`}
                style={{
                    padding: '15px',
                    backgroundColor: backgroundList(variant),
                    fontWeight: 500,
                    borderRadius: 10,
                    color: colorList(variant),
                }}
            >
                {iconList()}
                {text}
            </span>
        </>
    );
}
