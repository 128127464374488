import { NavLink } from 'react-router-dom';
import { Tabs, Navbar, NavDropdown, ButtonGroup } from 'react-bootstrap';

import styled from 'styled-components';

export const MobileNavbar = styled(Navbar)`
    .active {
        background-color: #5648fb;
        color: #fff !important;

        &:hover {
            background-color: #5648fb !important;
            color: #fff !important;
        }
    }

    a:hover {
        background-color: #5648fb !important;
        color: #ffffff !important;
    }

    .dropdown-toggle {
        padding: 0.5rem !important;
    }

    @media (min-width: 992px) {
        display: none;
    }
`;

export const DropdownMobile = styled(NavDropdown)`
    .dropdown-menu {
        border: none;
    }

    .dropdown-item {
        padding: 0.5rem 1rem;

        &:hover {
            background-color: transparent;
        }

        &:active {
            background-color: transparent;
            color: #000;
        }

        &:focus {
            background-color: transparent;
            color: #000;
        }
    }

    .nav-link {
        padding: 0;
        color: #000;
    }
`;

export const CircleRed = styled.span`
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #ff0000;
    display: inline-block;
    margin-right: 5px;
    vertical-align: text-top;
`;

export const CustomToggle = styled(Navbar.Toggle)`
    border: none;

    &:focus {
        box-shadow: none;
    }

    &:hover {
        box-shadow: none;
    }
`;

export const ContainerWrapper = styled.div<{
    active: string;
}>`
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #ffff;

    .btn-mobile:active {
        border: 1px solid #5648fb;
        box-sizing: border-box;
    }

    .link-dark:active {
        background-color: #5648fb;
        color: #ffffff;
    }

    .link-dark:hover {
        background-color: #5648fb;
        color: #ffffff;
    }

    ${(props): string =>
        props.active === '/chat' || props.active.includes('/chat')
            ? 'background-color: #fff;'
            : '#ffff'}
`;

export const SidebarWrapper = styled.aside<{ active: string; isCollapsed: string }>`
    background-color: #ffff;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    position: fixed;
    transition: width 0.4s;
    z-index: 1000;

    width: ${(props): string => (props.isCollapsed === 'false' ? '4rem' : '19rem')};
    height: 100%;
    left: 0;
    top: 0;

    .menu-text {
        display: ${(props): string => (props.isCollapsed === 'false' ? 'none' : 'block')};
        animation: ${(props): string =>
            props.isCollapsed === 'false' ? 'collapse 0.4s' : 'expand 0.4s'};

        -webkit-animation: ${(props): string =>
            props.isCollapsed === 'false' ? 'collapse 0.4s' : 'expand 0.4s'};

        @keyframes expand {
            from {
                transform: scale(0);
                opacity: 0;
            }
            to {
                transform: scale(1);
                opacity: 1;
            }
        }

        @keyframes collapse {
            from {
                transform: scale(1);
                opacity: 1;
            }
            to {
                transform: scale(0);
                opacity: 0;
            }
        }

        @-webkit-keyframes expand {
            from {
                transform: scale(0);
                opacity: 0;
            }
            to {
                transform: scale(1);
                opacity: 1;
            }
        }

        @-webkit-keyframes collapse {
            from {
                transform: scale(1);
                opacity: 1;
            }
            to {
                transform: scale(0);
                opacity: 0;
            }
        }
    }

    .pinned-text {
        display: ${(props): string => (props.isCollapsed === 'false' ? 'none' : 'block')};
        animation: ${(props): string =>
            props.isCollapsed === 'false' ? 'collapse 0.4s' : 'expand 0.4s'};

        -webkit-animation: ${(props): string =>
            props.isCollapsed === 'false' ? 'collapse 0.4s' : 'expand 0.4s'};

        @keyframes expand {
            from {
                transform: scale(0);
                opacity: 0;
            }
            to {
                transform: scale(1);
                opacity: 1;
            }
        }

        @keyframes collapse {
            from {
                transform: scale(1);
                opacity: 1;
            }
            to {
                transform: scale(0);
                opacity: 0;
            }
        }

        @-webkit-keyframes expand {
            from {
                transform: scale(0);
                opacity: 0;
            }
            to {
                transform: scale(1);
                opacity: 1;
            }
        }

        @-webkit-keyframes collapse {
            from {
                transform: scale(1);
                opacity: 1;
            }
            to {
                transform: scale(0);
                opacity: 0;
            }
        }
    }

    @media (max-width: 991px) {
        display: none;
    }
`;

export const SidebarHeader = styled.div`
    align-items: center;
    display: flex;
    height: 3rem;
    margin: 0.5rem 0.5rem 0;
    justify-content: ${(props: { isCollapsed: string }): string =>
        props.isCollapsed === 'false' ? 'center' : 'end'};
    padding: ${(props: { isCollapsed: string }): string =>
        props.isCollapsed === 'false' ? '0' : '0 1rem'};
`;

export const CollapseButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
`;

export const SidebarBody = styled.ul<{ isCollapsed: string }>`
    list-style: none;
    margin: 0;
    padding: 0.5rem;
    width: 100%;
    text-decoration: none;

    .menu-link {
        min-height: 48px;
        text-decoration: none;
        border-radius: ${(props): string =>
            props.isCollapsed === 'true' ? '10px' : '50%'};

        &:hover {
            background-color: #5648fb;
            color: #ffffff;

            .icon-active {
                background-color: #ffffff;
            }
            .dropdown-active {
                background-color: #ffffff !important;
            }
        }
    }

    .active {
        background-color: #5648fb !important;
        color: #ffffff !important;

        .icon-active {
            background-color: #ffffff !important;
        }
        .dropdown-active {
            background-color: #ffffff !important;
            color: #ffffff;
        }
    }
`;

export const SidebarFooter = styled.ul<{ isCollapsed: string }>`
    position: absolute;
    bottom: 0;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 2rem 0.5rem;

    .menu-link {
        min-height: 48px;
        text-decoration: none;
        border-radius: ${(props): string =>
            props.isCollapsed === 'true' ? '10px' : '50%'};

        &:hover {
            background-color: #5648fb;
            color: #ffffff;
            .icon-active {
                background-color: #ffffff;
            }
            .dropdown-active {
                background-color: #ffffff !important;
            }
        }
    }

    .active {
        background-color: #5648fb !important;
        color: #f6f6f6 !important;

        .icon-active {
            background-color: #ffffff !important;
            color: #ffffff;
        }
        .dropdown-active {
            background-color: #5648fb !important;
            color: #ffffff;
        }
    }
    .pinned-list-small {
        display: none;
        margin: 5px 0;
    }

    .pinned-list-large {
        display: block;
    }

    @media (max-height: 868px) {
        .pinned-list-large {
            display: none;
        }

        .pinned-list-small {
            display: block;
        }
    }
`;

export const PinnedListing = styled.li<{ isCollapsed: string }>`
    margin: 5px 0;

    &:hover {
        background-color: #5648fb;
        border-radius: ${(props): string =>
            props.isCollapsed === 'true' ? '10px' : '50%'};
        color: #ffffff;
    }

    &:active {
        background-color: #5648fb;
        border-radius: ${(props): string =>
            props.isCollapsed === 'true' ? '10px' : '50%'};
        color: #ffffff;
    }
`;

export const PinnedTitleListing = styled.li`
    margin: 5px 0;
    padding-left: 5px;

    @media (max-height: 868px) {
        display: none;
    }
`;

export const MenuListing = styled.li<{ isCollapsed: string }>`
    margin: 5px 0;

    .dropdown {
        width: 100%;
        min-height: 48px;
    }

    .dropdown-toggle {
        &:after {
            content: none;
        }
    }

    .btn-group {
        width: 100%;
    }

    .btn-group > .btn {
        text-align: left;
        padding: 0;
        min-height: 48px;
        width: 100%;
        border-radius: ${(props): string =>
            props.isCollapsed === 'true' ? '10px' : '50% !important'};
    }

    .btn-primary {
        background-color: transparent;
        color: #838383;
        font-size: 14px;
        font-weight: 500;
        border: none;
        border-radius: 50%;

        &:hover {
            border: none;
            outline: none;
            color: #ffffff;
            background-color: #5648fb;

            .icon-active {
                background-color: #ffffff;
            }
        }

        & + .show {
            box-shadow: none;
            outline: none;
        }

        &:active {
            color: #ffffff;
        }
    }

    .dropdown-menu {
        padding: 0 0.5rem;
        min-width: 250px;

        transform: ${(props): string =>
            props.isCollapsed === 'false'
                ? 'translate3d(65px, 0px, 0px) !important;'
                : 'translate3d(305px, 0px, 0px) !important;'};

        animation: fadeIn 0.5s forwards;

        @keyframes fadeIn {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }

    .dropdown-item {
        padding: 13px;
        margin: 5px 0;
        min-height: 48px;

        font-size: 14px;
        font-weight: 500;
        color: #838383;
        border-radius: 10px;

        &:hover {
            background-color: #5648fb;
            border-radius: 10px;
            color: #ffffff;

            .dropdown-active {
                background-color: #ffffff;
            }
        }
    }

    .show {
        .btn-primary {
            color: #ffffff;
            background-color: #5648fb !important;

            .icon-active {
                background-color: #ffffff;
            }
        }
    }
`;

export const DropdownWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const AdditionalListing = styled.li<{ isCollapsed: string }>`
    margin-top: 5px;

    .dropdown {
        width: 100%;
        min-height: 48px;
    }

    .dropdown-toggle {
        &:after {
            content: none;
        }
    }

    .btn-group {
        width: 100%;
    }

    .btn-group > .btn {
        text-align: left;
        padding: 0;
        min-height: 48px;
        width: 100%;
        border-radius: ${(props): string =>
            props.isCollapsed === 'true' ? '10px' : '50% !important'};
    }

    .btn-primary {
        background-color: transparent;
        color: #838383;
        font-size: 14px;
        font-weight: 500;
        border: none;
        border-radius: ${(props): string =>
            props.isCollapsed === 'true' ? '10px' : '50% !important'};

        &:hover {
            border: none;
            outline: none;
            color: #ffffff;
            background-color: #5648fb;

            .icon-active {
                background-color: #ffffff;
            }
        }
    }

    .dropdown-menu {
        padding: 0 0.5rem;
        min-width: 250px;

        transform: ${(props): string =>
            props.isCollapsed === 'false'
                ? 'translate3d(65px, 0px, 0px) !important;'
                : 'translate3d(305px, 0px, 0px) !important;'};

        animation: fadeIn 0.5s forwards;

        @keyframes fadeIn {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }

    .dropdown-item {
        padding: 13px;
        margin: 5px 0;
        min-height: 48px;

        font-size: 14px;
        font-weight: 500;
        color: #838383;
        border-radius: 10px;

        &:hover {
            background-color: #5648fb;
            border-radius: 10px;
            color: #ffffff;

            .dropdown-active {
                background-color: #ffffff;
            }
        }
    }

    .show {
        .btn-primary {
            color: #ffffff;
            background-color: #5648fb !important;

            .icon-active {
                background-color: #ffffff;
            }
        }
    }
`;

export const MenuItem = styled(NavLink)<{ isCollapsed?: string }>`
    align-items: center;
    display: flex;
    transition: background-color 0.3s;
    font-weight: 500;
    color: #838383;
    border-radius: ${(props): string =>
        props.isCollapsed === 'true' ?? true ? '10px' : '50%'};
`;

export const PinnedItem = styled(NavLink)<{ isCollapsed?: string; active: string }>`
    height: 100%;
    align-items: center;
    display: flex;
    font-weight: 500;
    color: #838383;
    border-radius: ${(props): string =>
        props.isCollapsed === 'true' ?? true ? '10px' : '50%'};

    background-color: ${(props): string =>
        props.active === 'true' ? '#5648fb' : 'transparent'};
`;

export const PinnedTitle = styled.div<{ isCollapsed: string }>`
    height: 100%;
    align-items: center;
    display: flex;
    font-weight: 500;
    color: #838383;
    border-radius: ${(props): string => (props.isCollapsed === 'true' ? '10px' : '50%')};
`;

export const MenuText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
`;

export const PinnedText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
`;

export const PinnedWrapperIcon = styled.div`
    margin: 0 8px;
`;

export const Hr = styled.hr`
    margin: 0;
`;

export const MainContent = styled.main<{ isCollapsed: string }>`
    padding: 2rem;
    margin-left: ${(props: { isCollapsed: string }): string =>
        props.isCollapsed === 'false' ? '4.1rem' : '19rem'};
    transition: margin-left 0.4s;
    min-height: 100vh;
    width: 100%;

    @media (max-width: 991px) {
        margin-left: 0;
        padding: 5rem 1rem 1rem 1rem;
    }
`;

export const PinnedIcon = styled.img`
    object-fit: cover;
    border-radius: 50%;
    width: 32px;
    height: 32px;
`;

export const IconContent = styled.span<{ icon: string; errors?: string }>`
    mask: url(${props => props.icon}) no-repeat center;
    mask-size: 80%;
    -webkit-mask: url(${props => props.icon}) no-repeat center;
    -webkit-mask-size: 80%;
    background-color: #838383;
    margin: 0px 11px;
    padding: 13px;
`;

export const DropdownIcon = styled.span<{ icon: string; errors?: string }>`
    mask: url(${props => props.icon}) no-repeat center;
    mask-size: 80%;
    -webkit-mask: url(${props => props.icon}) no-repeat center;
    -webkit-mask-size: 80%;
    background-color: #838383;
    margin-right: 10px;
    padding: 13px;
`;

export const LogoutButton = styled.button<{ isCollapsed: string }>`
    align-items: center;
    display: flex;
    transition: background-color 0.3s;
    font-weight: 500;
    color: #838383;
    border-radius: ${(props): string => (props.isCollapsed === 'true' ? '10px' : '50%')};
    padding: 0;
    width: 100%;
    outline: none;
    border: none;

    &:hover {
        color: #ffffff;
        background-color: #5648fb;
        .icon-active {
            background-color: #5648fb;
        }
    }

    &:active {
        border: none;
        outline: none;
    }
`;

export const NotificationBody = styled.div`
    font-size: 12px;
    background-color: #fff;
    border-radius: 10px;
    /* box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1); */
    .icon-gear {
        stroke: #5648fb;
        &:hover {
            stroke: #838383;
        }
    }

    .notification-list {
        list-style: none !important;
        padding: 0;
        margin-bottom: 0;
        overflow-y: scroll;
        max-height: 57vh;
    }

    .form-check-input {
        width: 1.2em;
        height: 1.2em;

        &:focus {
            box-shadow: none;
        }
    }

    .form-check-label {
        vertical-align: middle;
    }

    .text-time {
        display: contents;
        font-size: 10px;
    }

    .notification-message {
        padding: 0 1.2rem;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .notification-reply {
        margin-left: 1.2rem;
        text-decoration: none;
        font-size: 11px !important;
    }

    .unselect {
        font-size: 11px !important;
        background-color: #edecfe;
        color: #5648fb;
        border-radius: 50px;
        padding: 10px 10px;
        font-weight: 500;
    }

    .read {
        font-size: 11px !important;
        background-color: rgba(6, 189, 128, 0.2);
        color: rgba(6, 189, 128, 1);
        border-radius: 50px;
        padding: 10px 10px;
        font-weight: 500;
    }

    /* .form-check-label {
        min-width: 5.5rem !important;
    } */
`;

export const WrapperTabs = styled(Tabs)`
    .nav-link {
        border: none;
        background: none !important;
        color: rgba(207, 207, 207, 1);
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
        padding: 1rem 0;

        &.active {
            color: #5648fb !important;
            border-bottom: 2px solid #5648fb;
        }
    }
`;

export const IconNotification = styled.span<{ icon: string; errors?: string }>`
    mask: url(${props => props.icon}) no-repeat center;
    mask-size: 80%;
    -webkit-mask: url(${props => props.icon}) no-repeat center;
    -webkit-mask-size: 80%;
    background-color: #838383;
    padding: 5px 12px 10px 12px;

    &:hover {
        background-color: #5648fb;
    }
`;

export const ButtonGroupCustom = styled(ButtonGroup)`
    width: 100%;
`;
