import { Modal } from 'react-bootstrap';

import DropFile from '@/components/molecules/DropFile';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';

import styled from 'styled-components';
import { Dispatch, SetStateAction } from 'react';

// import { useSelector } from 'react-redux';
// import { RootState } from '@/redux/store';

export default function RenameDocument({
    modal,
    setModal,
    files,
    setFiles,
    handleUpload,
    uploading,
    handleBack,
}: {
    modal: boolean;
    setModal: Dispatch<SetStateAction<boolean>>;
    files: Array<{
        names: string;
        descriptions: string;
        files: File;
        progress: number;
    }>;
    setFiles: any;
    handleUpload: () => void;
    uploading: boolean;
    handleBack: () => void;
}): JSX.Element {
    return (
        <ModalWrapper
            show={modal}
            onHide={() => handleBack()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <h6 className="fw-semibold mb-0 px-2">Rename File</h6>
            </Modal.Header>
            <Modal.Body className="p-5">
                <DropFile
                    files={files}
                    setFiles={setFiles}
                    isRename
                    multiple={true}
                    maxFiles={200}
                    maxUploadSize={4100000000}
                />
            </Modal.Body>
            <Modal.Footer className="p-3 justify-content-around">
                <ButtonBasic
                    text="Back"
                    type="button"
                    className="btn btn-danger btn-sm mb-3 w-25"
                    onClick={() => handleBack()}
                />
                <ButtonBasic
                    text="Upload File"
                    type="button"
                    className="btn btn-primary btn-sm mb-3 w-25"
                    onClick={() => handleUpload()}
                    loading={
                        uploading
                        // Boolean(actionDocumentFileState.loading) ||
                        // files.some(file => file.progress > 0)
                    }
                />
            </Modal.Footer>
        </ModalWrapper>
    );
}

const ModalWrapper = styled(Modal)`
    hr {
        color: #bdbdbd;
    }
    .modal-content {
        font-family: 'Montserrat', sans-serif;
        border-radius: 20px;
    }

    .modal-header {
        border: none;
        border-bottom: 1px solid #e9ecef;
        padding: 1rem;

        .btn-close {
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 10px;
        }
    }

    .modal-body {
        text-align: center;
        padding: 0;

        .form-floating {
            font-size: 14px;
        }

        .label-active {
            padding: 1.5rem 0.5rem;
        }
        /* .form-control {
            color: #8e8e8e;
        } */

        input {
            padding: 0 0 0px 4px !important;
        }
    }
    .modal-footer {
        justify-content: center;
        border: none;
        flex-wrap: initial;
    }
`;
