import { ReactNode, useEffect, useRef, useState } from 'react';
import uuid from 'react-uuid';
import { Link } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import { Dropdown } from 'react-bootstrap';

import UserQuestionIcon from '@/assets/svg/icons/user-question.svg';
import BuildingXmark from '@/assets/svg/icons/building-xmark.svg';

import Card from '@/components/molecules/Card';
import BasicTooltip from '@/components/atoms/Tooltips';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';

// import { FiDownload, FiEye, FiSend } from 'react-icons/fi';
// import { IoMdRefresh } from 'react-icons/io';

import {
    AiOutlineCloseCircle,
    AiOutlineDelete,
    AiOutlineDownload,
    AiOutlineEdit,
    AiOutlineEye,
    AiOutlineFileDone,
    AiOutlineFileText,
    AiOutlineInfoCircle,
    AiOutlineMail,
    AiOutlineRetweet,
    AiOutlineScissor,
    AiOutlineSearch,
    AiOutlineSend,
    AiOutlineSetting,
    AiOutlineUserAdd,
} from 'react-icons/ai';
import { BsFillPinAngleFill } from 'react-icons/bs';

// import { HiOutlineXCircle, HiOutlineTrash } from 'react-icons/hi';
// import { MdOutlineRestore } from 'react-icons/md';
// import { BiEnvelope } from 'react-icons/bi';
// import { FaRegEdit } from 'react-icons/fa';

import { IconContent, RightClick, TableSearch, WrapperRowLeft } from './styles';

import PaginationView from './PaginationView';
import CompanyDetailView from './CompanyDetailView';
import UserDetailView from './UserDetailView';
import FilterView from './FilterView';
import SortView from './SortView';
import TableView from './TableView';
import { MdCompare } from 'react-icons/md';
interface TableBasicProps {
    header: Array<{
        selector?: string;
        Cell?: (props: any, index: any) => ReactNode;
        title: string;
        withAction?: boolean;
        only?: (row: any) => any;
        className?: string;
        actions?: Array<{
            text?: string;
            CellText?: (props: any, index: any) => ReactNode;
            Cell?: (props: any, index: any) => ReactNode;
            type?: string;
            link?: string;
            onClick?: (props: any) => void;
            variant?: string;
            disabled?: boolean;
        }>;
    }>;
    items: {
        data: Array<{}>;
        meta: {
            current_page: number;
            from: number;
            last_page: number;
            link: [];
            path: string;
            per_page: number;
            to: number;
            total: number;
        };
    };
    withDetail?: 'company' | 'user';
    handleSearch?: (value: string) => void;
    handleFilter?: (value: string, type: number) => void;
    handleAction?: () => void;
    handlePagination?: (page: number, type: string) => void;
    handleAdd?: () => void;
    handleAward?: () => void;
    handleNullify?: () => void;
    handleSavePermissions?: () => void;
    handleProjectAccess?: (
        value: boolean,
        type: string,
        access: string,
        space?: string,
    ) => void;
    withFilter?: JSX.Element;
    withoutBorder?: boolean;
    loading?: boolean;
    withAdd?: boolean;
    withAction?: boolean;
    withoutCheckbox?: boolean;
    withSort?: boolean;
    withListProject?: boolean;
    withAwardNullify?: boolean;
    withProjectAccess?: boolean;
    searchText?: string;
    totalText?: string;
    moduleType: string;
    addTitle?: string;
    actionTitle?: string | JSX.Element;
    actionClass?: string;
    projectAccessState?: {
        schedule: {
            can_invite_schedule: boolean;
            construction: {
                can_collaborate_schedule: boolean;
                can_view_schedule: boolean;
                disable_option: number;
            };
            design: {
                can_collaborate_schedule: boolean;
                can_view_schedule: boolean;
                disable_option: number;
            };
        };
        summary: {
            collaborate: boolean;
            view: boolean;
            invite: boolean;
        };
        document: {
            collaborate: boolean;
            view: boolean;
            invite: boolean;
            post_publicly: boolean;
        };
        drawing: {
            collaborate: boolean;
            view: boolean;
            invite: boolean;
            post_publicly: boolean;
        };
    };
    selected?: any;
    setSelected?: any;
    detailsState?: any;
    withStructure?: JSX.Element;
    loadingStructure?: boolean;
    disableClickRow?: boolean;
    customAction?: JSX.Element;
    disabledSearch?: boolean;
    withoutPagination?: boolean;
    withRightClick?: boolean;
    withActionTooltip?: string | undefined;
    searchValue?: string;
    detailsLoading?: boolean;
    hiddenCheckbox?: boolean;
    handleRemoveUserFromProject?: (projectDetail: any) => void;
    singleSelected?: boolean;
    breadcrumb?: any;
}

interface ActionProps {
    CellText: any;
    rowKey: any;
    rowValue: any;
    type: string;
    text: string;
    onClick: (row: any) => void;
    variant?: string;
    Cell?: any;
    only?: any;
    url?: string;
}

export default function TableBorderless(props: TableBasicProps): JSX.Element {
    const {
        header,
        items,
        withDetail,
        withFilter,
        withoutBorder,
        searchText,
        totalText,
        moduleType,
        handleSearch,
        loading,
        selected,
        setSelected,
        handleAction,
        handleFilter,
        actionTitle,
        detailsState,
        handlePagination,
        actionClass,
        withAction,
        addTitle,
        handleAdd,
        withAdd,
        withoutCheckbox,
        withSort,
        withStructure,
        withListProject,
        withAwardNullify,
        handleAward,
        handleNullify,
        handleSavePermissions,
        withProjectAccess,
        projectAccessState,
        handleProjectAccess,
        loadingStructure,
        disableClickRow,
        customAction,
        disabledSearch,
        withoutPagination,
        withRightClick,
        withActionTooltip,
        searchValue,
        detailsLoading,
        hiddenCheckbox,
        handleRemoveUserFromProject,
        singleSelected,
        breadcrumb,
    } = props;

    const [dataTemp, setDataTemp] = useState<Array<{ id: number; isChecked: boolean }>>(
        [],
    );

    // Function for add checkbox in list
    useEffect(() => {
        if (items?.data !== undefined) {
            const temp: Array<{
                id?: number;
                isChecked: boolean;
            }> = items?.data?.map((item: any) => {
                return {
                    ...item,
                    isChecked: false,
                };
            });
            setDataTemp(temp as any);
        }
    }, [items.data]);

    /**
     * Function for handle Check All
     *
     * @param boolean {checked}
     * @returns void
     */
    const handleCheckAll = (checked: boolean): void => {
        const temp: Array<{
            id?: number;
            isChecked: boolean;
        }> = dataTemp.map((item: any) => {
            return {
                ...item,
                isChecked: checked,
            };
        });
        setDataTemp(temp as any);
        setSelected(checked ? [...temp] : []);
    };

    /**
     * Function for handle check on checkbox
     *
     * @param number id
     * @param boolean checked
     * @returns void
     */
    const handleCheck = (id: number, checked: boolean): void => {
        const temp: any = dataTemp.map((item: any) => {
            if (item.id === id) {
                setSelected?.(
                    checked
                        ? [...selected, item]
                        : selected.filter((item: any) => item.id !== id),
                );
                return {
                    ...item,
                    isChecked: checked,
                };
            }
            return item;
        });
        setDataTemp(temp);
    };

    /**
     * Function for handle Check on row
     *
     * @param number id
     * @returns void
     */
    const handleCheckRow = (id: number): void => {
        const temp: any = dataTemp.map((item: { id: number; isChecked: boolean }) => {
            if (item.id === id) {
                setSelected?.(
                    !item.isChecked
                        ? [...selected, item]
                        : selected.filter((item: any) => item.id !== id),
                );
                return {
                    ...item,
                    isChecked: !item.isChecked,
                };
            }
            return item;
        });
        setDataTemp(temp);
    };

    /**
     * Function to define icon by type
     *
     * @param string type
     * @return string
     */
    const renderIcons = (type: string): ReactNode => {
        switch (type?.toLowerCase()) {
            case 'leave':
                return (
                    <AiOutlineCloseCircle
                        size={18}
                        className="react-icon me-2"
                        color="#fb6056"
                    />
                );
            case 'cancel':
                return (
                    <AiOutlineRetweet
                        size={18}
                        color="#fb6056"
                        className="me-2 react-icon"
                    />
                );
            case 'delete':
                return (
                    <AiOutlineDelete
                        className="react-icon me-2"
                        size={18}
                        color="#fb6056"
                    />
                );
            case 'resend':
                return <AiOutlineSend size={18} className="react-icon me-2" />;
            case 'detail':
                return <AiOutlineEye className="react-icon me-2" size={18} />;
            case 'pin':
                return <BsFillPinAngleFill className="react-icon me-2" size={17} />;
            case 'unpin':
                return <BsFillPinAngleFill className="react-icon me-2" size={17} />;
            case 'setting':
                return <AiOutlineSetting className="react-icon me-2" size={18} />;
            case 'edit':
                return <AiOutlineEdit className="react-icon me-2" size={18} />;
            case 'send':
                return <AiOutlineMail className="react-icon me-2" size={18} />;
            case 'download':
                return <AiOutlineDownload className="react-icon me-2" size={18} />;
            case 'share':
                return <AiOutlineUserAdd className="react-icon me-2" size={18} />;
            case 'restore':
                return <AiOutlineRetweet className="text-success me-2" size={18} />;
            case 'info':
                return <AiOutlineInfoCircle className="react-icon me-2" size={18} />;
            case 'copy':
                return <AiOutlineFileText className="react-icon me-2" size={18} />;
            case 'cut':
                return <AiOutlineScissor className="react-icon me-2" size={18} />;
            case 'compare':
                return <MdCompare className="me-2" size={18} color="#b8bcc0" />;
            default:
                return null;
        }
    };

    /**
     * Function for render on Row with detail value
     *
     * @param {object} row
     * @param {object} action
     * @return {any}
     * @see src/components/atoms/Tables/TableBody.js
     *    To see the usage of this function
     */
    const renderOnRow = (action: ActionProps, row: any): any => {
        if (row[action.rowKey] === action.rowValue) {
            return (
                <Dropdown.Item
                    key={uuid()}
                    style={{
                        fontSize: 14,
                    }}
                    className={`border-top-0 ${
                        action?.only?.(row) === false ? 'd-none' : ''
                    }`}
                    onClick={e => {
                        e.preventDefault();
                        action.onClick(row);
                    }}
                >
                    {renderIcons(action.type)}
                    {action.CellText !== undefined ? (
                        action.CellText(row)
                    ) : (
                        <span
                            className={`text-${action.variant ?? 'dark'}`}
                            style={{
                                fontSize: 13,
                            }}
                        >
                            {action.text}
                        </span>
                    )}
                </Dropdown.Item>
            );
        }
    };

    /**
     * Function for render only on status condition
     *
     * @param {object} action
     * @param {object} row
     * @return {any}
     */
    const renderOnStatus = (action: ActionProps, row: any): any => {
        return (
            <Dropdown.Item
                key={uuid()}
                style={{
                    fontSize: 14,
                }}
                className={`border-top-0 ${
                    action?.only?.(row) === false ? 'd-none' : ''
                }`}
                onClick={e => {
                    e.preventDefault();
                    action.onClick(row);
                }}
            >
                {renderIcons(action.type)}
                {action.CellText !== undefined ? (
                    action.CellText(row)
                ) : (
                    <span
                        className={`text-${action.variant ?? 'dark'}`}
                        style={{
                            fontSize: 13,
                        }}
                    >
                        {action.text}
                    </span>
                )}
            </Dropdown.Item>
        );
    };

    /**
     * Function to render of list action from props
     *
     * @param {object} action
     * @param {object} row
     * @return {any}
     */
    const renderActions = (action: ActionProps, row: any): JSX.Element => {
        return action !== undefined ? (
            Object.prototype.hasOwnProperty.call(action, 'onClick') ? (
                Object.prototype.hasOwnProperty.call(action, 'Cell') ? (
                    Object.prototype.hasOwnProperty.call(action, 'only') ? (
                        renderOnStatus(action, row)
                    ) : (
                        renderOnRow(row, action)
                    )
                ) : (
                    <Dropdown.Item
                        key={uuid()}
                        style={{
                            fontSize: 14,
                        }}
                        className={`border-top-0 ${
                            action?.only?.(row) === false ? 'd-none' : ''
                        }`}
                        onClick={() => action.onClick(row)}
                    >
                        {renderIcons(action.type)}
                        {action.CellText !== undefined ? (
                            action.CellText(row)
                        ) : (
                            <span
                                className={`text-${action.variant ?? 'dark'}`}
                                style={{
                                    fontSize: 13,
                                }}
                            >
                                {action.text}
                            </span>
                        )}
                    </Dropdown.Item>
                )
            ) : (
                <Dropdown.Item
                    key={uuid()}
                    as={Link}
                    style={{
                        fontSize: 14,
                        fontFamily: 'Montserrat',
                    }}
                    className={`border-top-0 ${
                        action?.only?.(row) === false ? 'd-none' : ''
                    }`}
                    to={`${row.id as string}/${action.url as string}`}
                >
                    {renderIcons(action?.type)}

                    {action.CellText !== undefined ? (
                        action.CellText(row)
                    ) : (
                        <span
                            className={`text-${action.variant ?? 'dark'}`}
                            style={{
                                fontSize: 13,
                            }}
                        >
                            {action.text}
                        </span>
                    )}
                </Dropdown.Item>
            )
        ) : null;
    };

    /**
     * Function to render of list action from props
     *
     * @param any col
     * @param any row
     * @param number index
     *
     * @return JSX.Element
     */
    const renderContent = (col: any, row: any, index: number): JSX.Element => {
        if (
            Object.prototype.hasOwnProperty.call(col, 'Cell') !== undefined &&
            col?.Cell !== undefined
        ) {
            return col?.Cell(row, index);
        } else {
            return row[col.selector];
        }
    };

    /**
     * Function to handle row with return col
     *
     * @return JSX.Element
     */
    const handleRow = (): string => {
        if (withStructure !== undefined) {
            return 'col-md-8';
        }

        if (withDetail !== undefined) {
            return 'col-lg-7 col-xl-8';
        }

        return 'col-lg-12';
    };

    /**
     * Function to handle icon selected
     *
     * @param string moduleType
     * @return string
     */
    const handleIconNotSelected = (moduleType: string): string | JSX.Element => {
        switch (moduleType.toLowerCase()) {
            case 'company':
                return BuildingXmark;
            case 'user':
                return UserQuestionIcon;
            case 'file':
                return <AiOutlineFileText color="#cfcfcf" size={35} />;
            default:
                return UserQuestionIcon;
        }
    };

    const [outsideClick, setOutsideClick] = useState<boolean>(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

    const menuRef = useRef<HTMLDivElement>(null);

    // Function to handle click outside of menu
    useEffect(() => {
        function handleClickOutside(event: any): void {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setOutsideClick(false);
            }
        }

        document.body.addEventListener('click', handleClickOutside);

        return () => {
            document.body.removeEventListener('click', handleClickOutside);
        };
    }, []);

    // TODO: After paste on document and drawing complete
    // const handleOutsideContextMenu = (event: {
    //     preventDefault: () => void;
    //     clientX: number;
    //     clientY: number;
    // }): void => {
    //     event.preventDefault();

    //     setOutsideClick(true);
    //     setMenuPosition({ x: event.clientX, y: event.clientY });
    // };

    return (
        <div className="row">
            <div className="col-lg-12">
                <Card
                    classOuter={'p-0'}
                    classBody="p-0"
                    style={{
                        border: 'none',
                    }}
                >
                    <WrapperRowLeft
                        className="row g-0"
                        loading={loadingStructure ?? false ? 'true' : 'false'}
                    >
                        <div className="col-lg-7 col-xl-8 p-2">
                            <div className="row" style={{ minHeight: '2rem' }}>
                                <div
                                    className={
                                        withAction === true ||
                                        customAction !== undefined ||
                                        withFilter !== undefined ||
                                        withSort === true
                                            ? 'col-lg-6'
                                            : 'col-lg-12'
                                    }
                                >
                                    <div
                                        className="d-flex align-items-center"
                                        style={{ paddingLeft: 14 }}
                                    >
                                        <AiOutlineSearch
                                            size={20}
                                            color="rgba(131, 131, 131, 1)"
                                        />
                                        <TableSearch
                                            type="text"
                                            id={`search${moduleType.replace(' ', '')}`}
                                            className="w-100 ms-2"
                                            disabled={disabledSearch ?? false}
                                            placeholder={
                                                searchText ??
                                                `Search ${moduleType.toLowerCase()}`
                                            }
                                            onChange={e => {
                                                handleSearch?.(e.target.value);
                                            }}
                                            value={searchValue}
                                            style={
                                                disabledSearch ?? false
                                                    ? { cursor: 'not-allowed' }
                                                    : {}
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 text-md-end">
                                    {dataTemp.find(
                                        (item: any) => item.isChecked === true,
                                    ) !== undefined &&
                                        withAction === true &&
                                        (withActionTooltip !== undefined ? (
                                            <>
                                                <BasicTooltip text={withActionTooltip}>
                                                    <ButtonBasic
                                                        className={`btn me-2 ${
                                                            actionClass ??
                                                            'btn-outline-primary'
                                                        } ${
                                                            withFilter !== undefined &&
                                                            withSort === true
                                                                ? 'mb-2'
                                                                : ''
                                                        }`}
                                                        text={actionTitle ?? 'Action'}
                                                        style={{
                                                            minHeight: 32,
                                                            padding: '0 20px',
                                                            fontSize: 13,
                                                        }}
                                                        onClick={handleAction}
                                                    />
                                                </BasicTooltip>
                                            </>
                                        ) : (
                                            <>
                                                <ButtonBasic
                                                    className={`btn me-2 ${
                                                        actionClass ??
                                                        'btn-outline-primary'
                                                    } ${
                                                        withFilter !== undefined &&
                                                        withSort === true
                                                            ? 'mb-2'
                                                            : ''
                                                    }`}
                                                    text={actionTitle ?? 'Action'}
                                                    style={{
                                                        minHeight: 32,
                                                        padding: '0 20px',
                                                        fontSize: 13,
                                                    }}
                                                    onClick={handleAction}
                                                />
                                            </>
                                        ))}
                                    {customAction}
                                    <div className="d-flex align-items-center justify-content-end">
                                        <div className="">
                                            {withFilter !== undefined && (
                                                <FilterView>{withFilter}</FilterView>
                                            )}
                                        </div>
                                        <div className="">
                                            {withSort === true && (
                                                <SortView
                                                    header={header}
                                                    handleFilter={handleFilter}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-xl-4 order-md-2 p-2">
                            <div className="row" style={{ minHeight: '2rem' }}>
                                <div
                                    className={`${
                                        withAdd === true ? 'col-lg-6' : 'col-lg-12'
                                    } align-self-center`}
                                >
                                    <span style={{ fontSize: 13 }}>
                                        {totalText !== undefined
                                            ? `${totalText} : ${items?.meta?.total ?? 0}`
                                            : `Total ${moduleType.toLowerCase()} : ${
                                                  items?.meta?.total ?? 0
                                              }`}
                                    </span>
                                </div>
                                {withAdd === true && (
                                    <div className="col-lg-6 text-end">
                                        <button
                                            id={`add${moduleType.replace(' ', '')}`}
                                            className="btn btn-primary mx-2 px-4"
                                            style={{
                                                fontSize: 12,
                                                borderRadius: 50,
                                                minHeight: 32,
                                            }}
                                            onClick={handleAdd}
                                        >
                                            {addTitle ?? 'Add'}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </WrapperRowLeft>
                    {loading !== true ? (
                        <>
                            <div className="row g-0 p-0">
                                {withStructure !== undefined && (
                                    <div className="col-md-4">{withStructure}</div>
                                )}
                                <div className={handleRow()}>
                                    {/* Breadcrumb */}
                                    {breadcrumb !== undefined && breadcrumb}
                                    {/* End Breadcrumb */}
                                    {items?.data !== undefined &&
                                    items.data.length === 0 ? (
                                        <div
                                            // {/* TODO: IF Ready to paste from backend */}
                                            // onContextMenu={handleOutsideContextMenu}
                                            className="d-flex justify-content-center align-items-center flex-column h-100"
                                            style={{ minHeight: '50vh' }}
                                        >
                                            {typeof handleIconNotSelected(moduleType) ===
                                            'string' ? (
                                                <IconContent
                                                    className="icon-selected"
                                                    icon={
                                                        handleIconNotSelected(
                                                            moduleType,
                                                        ) as string
                                                    }
                                                />
                                            ) : (
                                                handleIconNotSelected(moduleType)
                                            )}
                                            <p
                                                className="text-muted mb-0"
                                                style={{ fontSize: 14 }}
                                            >
                                                No {moduleType.toLowerCase()} found
                                            </p>
                                            {outsideClick && (
                                                <RightClick
                                                    ref={menuRef}
                                                    style={{
                                                        position: 'absolute',
                                                        top: menuPosition.y - 100,
                                                        left: menuPosition.x - 100,
                                                    }}
                                                    className="d-flex flex-column"
                                                >
                                                    <div className={'button'}>
                                                        <AiOutlineFileDone
                                                            className="react-icon me-2"
                                                            size={18}
                                                        />
                                                        <span className="text-dark">
                                                            Paste
                                                        </span>
                                                    </div>
                                                </RightClick>
                                            )}
                                        </div>
                                    ) : (
                                        <TableView
                                            header={header as any}
                                            moduleType={moduleType}
                                            withoutCheckbox={withoutCheckbox}
                                            hiddenCheckbox={hiddenCheckbox}
                                            handleCheckAll={handleCheckAll}
                                            handleCheck={handleCheck}
                                            handleCheckRow={handleCheckRow}
                                            renderActions={renderActions}
                                            renderContent={renderContent}
                                            disableClickRow={disableClickRow}
                                            loading={loadingStructure}
                                            renderIcons={renderIcons}
                                            withRightClick={withRightClick}
                                            dataTemp={dataTemp}
                                            singleSelected={singleSelected}
                                        />
                                    )}
                                </div>
                                {withDetail === 'user' && (
                                    <UserDetailView
                                        projectAccessState={projectAccessState ?? null}
                                        handleProjectAccess={handleProjectAccess}
                                        detailsState={detailsState}
                                        moduleType={moduleType}
                                        withListProject={withListProject}
                                        withProjectAccess={withProjectAccess}
                                        withAwardNullify={withAwardNullify}
                                        handleAward={handleAward}
                                        handleNullify={handleNullify}
                                        handleSavePermissions={handleSavePermissions}
                                        detailsLoading={detailsLoading}
                                        handleRemoveUserFromProject={
                                            handleRemoveUserFromProject
                                        }
                                    />
                                )}
                                {withDetail === 'company' && (
                                    <CompanyDetailView
                                        detailsState={detailsState}
                                        moduleType={moduleType}
                                        detailsLoading={detailsLoading}
                                    />
                                )}
                            </div>

                            <PaginationView
                                items={items}
                                handlePagination={handlePagination}
                                withoutPagination={withoutPagination}
                            />
                        </>
                    ) : (
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color="#5648FB"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                visible={loading}
                            />
                        </div>
                    )}
                </Card>
            </div>
        </div>
    );
}
