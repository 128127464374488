import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import RenameDrawing from './RenameDrawing';
import UploadDrawing from './UploadDrawing';
import { storeVaporFile } from '@/routes/Dashboard/Home/redux/actions';
import { toast } from 'react-toastify';
import { useAppDispatch } from '@/redux/hook';
import { uploadDrawingFile } from '../../redux/actions';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';

const bucketName = import.meta.env.VITE_BUCKET_NAME;

export default function ModalDrawingUpload({
    modalUpload,
    setModalUpload,
    modalRename,
    setModalRename,
}: {
    modalUpload: boolean;
    modalRename: boolean;
    setModalUpload: Dispatch<SetStateAction<boolean>>;
    setModalRename: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
    const dispatch = useAppDispatch();

    const { id, urlSpaceId, urlFolderId } = useParams();

    const uploadDrawingFileState = useSelector(
        (state: RootState) => state.uploadDrawingFile,
    );

    const projectId = id ?? '';
    const folderUuid = urlFolderId ?? urlSpaceId ?? '';
    const [uploading, setUploading] = useState(false);

    const [files, setFiles] = useState<
        Array<{
            names: string;
            descriptions: string;
            files: File;
            progress: number;
        }>
    >([]);

    // If upload success, reset state
    useEffect(() => {
        if (uploadDrawingFileState.status === 200) {
            setTimeout(() => {
                setFiles([]);
                setUploading(false);
            }, 500);
        }

        if (uploadDrawingFileState.status === 400) {
            setUploading(false);
        }
    }, [uploadDrawingFileState]);

    const handleNext = (): void => {
        setModalUpload(false);
        setModalRename(true);
    };

    const handleBack = (): void => {
        setModalUpload(true);
        setModalRename(false);
    };

    const handleUpload = async (): Promise<void> => {
        setUploading(true);

        // Filter files that have names
        const validFiles = files.filter(file => file.names !== '');

        if (validFiles.length === 0) {
            return;
        }

        // split the dispatch to 5 files per request
        const chunkSize = 5;

        for (let i = 0; i < validFiles.length; i += chunkSize) {
            const chunk = validFiles.slice(i, i + chunkSize);
            const chunkPayload = new FormData();

            for (const [index, file] of chunk.entries()) {
                await processFile(file, index, chunkPayload);

                // add file names
                chunkPayload.append(`names[${index}]`, file.names);
                chunkPayload.append(`descriptions[${index}]`, file.descriptions);
            }

            dispatchUploadRequest(chunkPayload);

            toast.success('Files uploaded successfully', {
                position: toast.POSITION.BOTTOM_RIGHT,
                toastId: 'uploadNotification',
                autoClose: 5000,
            });

            // if last chunk, reset state
            // if (i + chunkSize >= validFiles.length) {
            // toast.update(loadingToast, {
            //     render: 'Files uploaded successfully',
            //     type: 'success',
            // });
            // }
        }
    };

    const processFile = async (
        file: any,
        index: number,
        payload: FormData,
    ): Promise<void> => {
        try {
            const response = await storeVaporFile(file.files, {
                bucket: bucketName,
                visibility: 'public-read',
                progress: progress => {
                    setFiles(prev => {
                        const newFiles = prev.map(prevFile => {
                            if (prevFile.files === file.files) {
                                return {
                                    ...prevFile,
                                    progress,
                                };
                            }

                            return prevFile;
                        });

                        return newFiles;
                    });
                },
            });

            if (response !== undefined) {
                const { extension } = response;

                ['uuid', 'bucket', 'key'].forEach(field =>
                    payload.append(`file_${field}s[${index}]`, response[field]),
                );

                if (extension !== undefined) {
                    // Assuming 'types' is the correct property name, you can adjust it accordingly
                    payload.append(`file_types[${index}]`, response.extension);
                }

                payload.append(`file_sizes[${index}]`, file.files.size);
            } else {
                setUploading(false);
                throw new Error('Failed to upload');
            }
        } catch (error) {
            setUploading(false);
            toast.error('Failed to upload, please try again later');
        }
    };

    const dispatchUploadRequest = (payload: FormData): void => {
        dispatch(
            uploadDrawingFile(
                {
                    project_id: projectId,
                    folder_id: folderUuid,
                },
                payload,
            ),
        );
    };

    return (
        <>
            <UploadDrawing
                modal={modalUpload}
                setModal={setModalUpload}
                files={files}
                setFiles={setFiles}
                handleNext={handleNext}
            />
            <RenameDrawing
                modal={modalRename}
                setModal={setModalRename}
                files={files}
                setFiles={setFiles}
                handleUpload={handleUpload}
                uploading={uploading}
                handleBack={handleBack}
            />
        </>
    );
}
