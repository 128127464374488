import { useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie';
// import * as PusherPushNotifications from '@pusher/push-notifications-web';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import ProgressBar from '@ramonak/react-progress-bar';
import CryptoJS from 'crypto-js';
import { RootState } from '@/redux/store';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import {
    AiOutlineFileExcel,
    AiOutlineFileImage,
    AiOutlineFilePdf,
    AiOutlineFilePpt,
    AiOutlineFileWord,
    AiOutlineFileZip,
    AiOutlineFolder,
} from 'react-icons/ai';

/**
 * Function for use script
 *
 * @returns {string} user id
 */
export const useScript = (url: string): void => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [url]);
};

/**
 * Function for detect outside click
 *
 * @param ref : React.RefObject<HTMLElement>
 * @param callback : () => void
 * @returns void
 */
export function outsideClick(
    ref: React.RefObject<HTMLElement>,
    callback: () => void,
): void {
    /**
     * Function for handle click outside
     */
    function handleClickOutside(event: MouseEvent): void {
        if (ref.current != null && !ref.current.contains(event.target as Node)) {
            callback();
        }
    }
    // Bind the event listener
    if (ref.current != null) {
        document.addEventListener('mousedown', handleClickOutside);
    } else {
        document.removeEventListener('mousedown', handleClickOutside);
    }
}

/**
 * Function for check only input number
 *
 * @param e : React.KeyboardEvent<HTMLInputElement>
 * @returns void
 */
export function onlyNumber(e: React.KeyboardEvent<HTMLInputElement>): void {
    // only number and delete key
    const number = /^[0-9\b]+$/;
    if (!number.test(e.key) && e.key !== 'Backspace') {
        e.preventDefault();
    }
}

/**
 * Function for get id from cookie
 *
 * @returns {string} token
 */
export function getId(): any {
    if (getCredential() !== null) {
        const {
            id,
        }: {
            id: string;
        } = getCredential() as any;

        return id;
    }
}

/**
 * Function for get email from cookie
 *
 * @returns {string} email
 */
export function getEmail(): any {
    if (getCredential() !== null) {
        const {
            email,
        }: {
            email: string;
        } = getCredential() as any;

        return email;
    }
}

/**
 * Function for get token from cookie
 *
 * @returns {string} token
 */
export function getToken(): any {
    if (getCredential() !== null) {
        const {
            token,
        }: {
            token: string;
        } = getCredential() as any;

        return token;
    }
}

/**
 * Function for get roles from cookie
 *
 * @returns {string} token
 */
export function checkRoles(): string {
    if (getCredential() !== null) {
        const {
            role,
        }: {
            role: {
                name: string;
            };
        } = getCredential() as any;

        return role?.name;
    }
    return '';
}

/**
 * Function for convert data url to file
 *
 * @param dataURL : any
 * @param filename : any
 * @returns {string} user id
 */
export function dataURLtoFile(dataURL: any, filename: any): any {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);

    let n = bstr.length;

    const u8arr = new Uint8Array(n);

    while (n-- !== 0) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

// Pusher Beams
// export const beamsClient = new PusherPushNotifications.Client({
//     instanceId: import.meta.env.VITE_PUSHER_INSTANCE_ID,
// });

// // Pusher Beams Token
// export const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
//     url: import.meta.env.VITE_PUSHER_TOKEN_BEAM,
// });

/**
 * Function for format date from unix to australia/sydney
 *
 * @param date : number
 * @param format : string
 * @param zone : string
 * @returns {string} date
 */
export const formatDate = (date: number, format?: string, zone?: string): string => {
    // Format to date readable
    const newFormat = moment.unix(date);

    // Convert with timezone
    return momentTimeZone(newFormat)
        .tz(zone ?? 'Australia/Sydney')
        .format(format ?? 'DD/MM/YYYY');
};

export const formatDateIso = (date: string, format?: string, zone?: string): string => {
    // Format to date readable
    const newFormat = moment(date);

    // Convert with timezone
    return momentTimeZone(newFormat)
        .tz(zone ?? 'Australia/Sydney')
        .format(format ?? 'DD/MM/YYYY');
};

/**
 * Function for format date from unix to australia/sydney
 *
 * @param date: number
 * @param format: string
 * @param zone: string
 * @returns
 */
export const formatDateAgo = (date: number, format?: string, zone?: string): string => {
    // Format to date readable
    const newFormat = moment.unix(date);

    // Convert with timezone
    return momentTimeZone(newFormat)
        .tz(zone ?? 'Australia/Sydney')
        .fromNow();
};

export const formatDateIsoAgo = (
    date: string,
    format?: string,
    zone?: string,
): string => {
    // Format to date readable
    const newFormat = moment(date);

    // Convert with timezone
    return momentTimeZone(newFormat)
        .tz(zone ?? 'Australia/Sydney')
        .fromNow();
};

/**
 * Function for get initial name
 *
 * @returns string
 * @param name
 */
export const getInitialName = (name: string): string => {
    // if name include space
    if (name?.includes(' ')) {
        const splitName = name.split(' ');
        return `${splitName[0].charAt(0)}${splitName[1].charAt(0)}`;
    }
    // if name not include space
    return name?.charAt(0);
};

/**
 * Function for get today date in australia/sydney
 *
 * @returns string
 */
export const getAustraliaToday = (): string => {
    return moment().tz('Australia/Sydney').format('DD-MM-YYYY');
};

/**
 * Function for get yesterday date in australia/sydney
 *
 * @returns
 */
export const getAustraliaYesterdayDate = (): string => {
    return moment().tz('Australia/Sydney').subtract(1, 'days').format('DD-MM-YYYY');
};

/**
 * Function for format string to kebab case
 *
 * @returns string
 * @param str
 */
export const formatKebabCase = (str: string): string => {
    return str.replace(/\s+/g, '-').toLowerCase();
};

/**
 * Function for capitalize first letter.
 *
 * @returns string
 * @param str
 */
export const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Function for capitalize each word.
 *
 * @param str string
 * @returns string
 */
export const capitalizeEachWord = (str: string): string => {
    return str
        .toLowerCase()
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
};

/**
 * Function for get mb or kb from bytes
 *
 * @param bytes
 * @returns number
 */
export const readableBytes = (bytes: number): string => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes === 0) return '0 Byte';

    const i: any = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());

    return `${Math.round(bytes / Math.pow(1024, i))}${sizes[i]}`;
};

/**
 * Function for create loading progress bar
 *
 * @returns JSX.Element
 */
export const ProgressLoading = (): JSX.Element => {
    // create loading 0 to 100
    const [progress, setProgress] = useState(0);

    // create interval
    const interval = useRef<any>();

    // create function for start loading
    const startLoading = (): void => {
        interval.current = setInterval(() => {
            setProgress((prevProgress: number) =>
                prevProgress >= 100 ? 100 : prevProgress + 1,
            );
        }, 100);
    };

    // create function for stop loading
    const stopLoading = (): void => {
        clearInterval(interval.current);
    };

    // start loading when component mount
    useEffect(() => {
        startLoading();
        return () => {
            stopLoading();
        };
    }, []);

    return (
        <ProgressBar
            completed={progress}
            labelSize="12px"
            height="12px"
            bgColor="#5648FB"
            animateOnRender={true}
            isLabelVisible={false}
        />
    );
};

export const handleTypeFileByName = (fileName: string): string => {
    fileName = fileName?.slice(fileName?.lastIndexOf('.') + 1);

    switch (fileName) {
        case 'pdf':
            return 'pdf';

        case 'doc':
        case 'docx':
        case 'rtf':
        case 'txt':
        case 'odt':
            return 'word';

        case 'xls':
        case 'xlsx':
            return 'excel';

        case 'ppt':
        case 'pptx':
            return 'powerpoint';

        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'webp':
        case 'gif':
            return 'image';

        case 'zip':
        case 'rar':
            return 'archive';

        default:
            return 'file';
    }
};

export const handleTypeFileByFormat = (format: string): string => {
    switch (format) {
        case 'pdf':
            return 'pdf';

        case 'doc':
        case 'docx':
        case 'rtf':
        case 'txt':
        case 'odt':
            return 'word';

        case 'xls':
        case 'xlsx':
            return 'excel';

        case 'ppt':
        case 'pptx':
            return 'powerpoint';

        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
        case 'webp':
            return 'image';

        case 'zip':
        case 'rar':
            return 'archive';

        default:
            return 'file';
    }
};

// Key of encryption
export const keyEnum = import.meta.env.VITE_KEY;

/**
 * Function to encrypt data
 *
 * @param data string
 */
export const encryptData = (data: string): string => {
    return CryptoJS.AES.encrypt(data, keyEnum as string).toString();
};

/**
 * Function to encrypt data
 *
 * @param data string
 */
export const decryptData = (data: string): any => {
    const decryptedBytes = CryptoJS.AES.decrypt(data, keyEnum as string);

    return decryptedBytes?.toString(CryptoJS.enc.Utf8);
};

/**
 * Function to get credential as object
 *
 * @returns void
 */
export const getCredential = (): {
    id: string;
    email: string;
    name: string;
    token: string;
    role: {
        name: string;
    };
} | null => {
    const user = Cookies.get('user');

    if (user !== undefined) {
        try {
            const data = decryptData(user);

            return JSON.parse(data);
        } catch (error) {
            toast.error('Something went wrong, please login again', {
                toastId: 'error',
            });

            Cookies.remove('user');
        }
    }
    return null;
};

/**
 * Function to convert date to unix australia
 *
 * @param date
 * @returns date
 */
export const convertToUnixAustralia = (date: any): any => {
    const newFormat = moment(date).startOf('day');

    return momentTimeZone(newFormat).tz('Australia/Sydney').unix();
};

/**
 * Function to convert date to unix australia
 *
 * @returns date
 */
export const currentProjectAccess = (): {
    summary: {
        collaborate: boolean;
        view: boolean;
        invite: boolean;
    };
    // schedule: {
    //     can_invite_schedule: boolean;
    //     construction: {
    //         can_collaborate_schedule: boolean;
    //         can_view_schedule: boolean;
    //         disable_option: number;
    //     };
    //     design: {
    //         can_collaborate_schedule: boolean;
    //         can_view_schedule: boolean;
    //         disable_option: number;
    //     };
    // };
    schedule: any;
    document: {
        collaborate: boolean;
        view: boolean;
        invite: boolean;
        post_publicly: boolean;
    };
    drawing: {
        collaborate: boolean;
        view: boolean;
        invite: boolean;
        post_publicly: boolean;
    };
} => {
    const currentProfileProjectState = useSelector(
        (state: RootState) => state.currentProfileProject,
    );

    return currentProfileProjectState?.response?.access;
};

/**
 * Function to check if user is project owner
 *
 * @returns boolean
 */
export const isProjectOwner = (): boolean => {
    const currentProfileProjectState = useSelector(
        (state: RootState) => state.currentProfileProject,
    );

    return currentProfileProjectState?.response?.is_owner;
};

export const generateInitialImage = (
    url: string,
    name: string,
    size: string,
    className?: string,
): JSX.Element => {
    if (url !== null) {
        return (
            <img
                src={url}
                alt=""
                className={className}
                style={{
                    width: size,
                    height: size,
                    borderRadius: '50px',
                    objectFit: 'cover',
                }}
            />
        );
    }
    return (
        <WrapperPicture
            className={className}
            style={{
                width: size,
                height: size,
            }}
        >
            <span className="initial-name">{getInitialName(name)}</span>
        </WrapperPicture>
    );
};

export const generateInitialImageUrl = (name: string): string => {
    return `https://ui-avatars.com/api/?name=${name}&background=5648fb&color=fff&size=128`;
};

const WrapperPicture = styled.div`
    border-radius: 50px;
    background-color: #5648fb;

    .initial-name {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
    }
`;

export const handleLongText = (str: string): string => {
    if (str.length > 35) {
        return str.substring(0, 20) + '...' + str.substring(str.length - 10, str.length);
    }

    return str;
};

export const handleArrayWithComma = (arr: any[]): string => {
    return arr?.map((item: any) => item?.name).join(', ');
};

/**
 * Function to debounce
 *
 * @see https://stackoverflow.com/questions/24004791/can-someone-explain-the-debounce-function-in-javascript
 * @param func - (...args: any[]) => void
 * @param delay - number
 * @returns (...args: any[]) => void
 */
export const debounce = (
    func: (...args: any[]) => void,
    delay: number,
): ((...args: any[]) => void) => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    return (...args: any[]): void => {
        if (timeoutId != null) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

export const handleIcons = (format: string): JSX.Element => {
    switch (format) {
        case 'pdf':
            return (
                <AiOutlineFilePdf
                    className="me-2"
                    size="18px"
                    style={{
                        verticalAlign: 'text-top',
                    }}
                />
            );

        case 'doc':
        case 'docx':
        case 'rtf':
        case 'txt':
        case 'odt':
            return (
                <AiOutlineFileWord
                    className="me-2"
                    size="18px"
                    style={{
                        verticalAlign: 'text-top',
                    }}
                />
            );

        case 'xls':
        case 'xlsx':
            return (
                <AiOutlineFileExcel
                    className="me-2"
                    size="18px"
                    style={{
                        verticalAlign: 'text-top',
                    }}
                />
            );

        case 'ppt':
        case 'pptx':
            return (
                <AiOutlineFilePpt
                    className="me-2"
                    size="18px"
                    style={{
                        verticalAlign: 'text-top',
                    }}
                />
            );

        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
        case 'webp':
            return (
                <AiOutlineFileImage
                    className="me-2"
                    size="18px"
                    style={{
                        verticalAlign: 'text-top',
                    }}
                />
            );

        case 'zip':
        case 'rar':
            return (
                <AiOutlineFileZip
                    className="me-2"
                    size="18px"
                    style={{
                        verticalAlign: 'text-top',
                    }}
                />
            );

        default:
            return (
                <AiOutlineFolder
                    className="me-2"
                    size="18px"
                    style={{
                        verticalAlign: 'text-top',
                    }}
                />
            );
    }
};

export const copyTextToClipboard = async (text: string): Promise<void> => {
    await navigator.clipboard.writeText(text);
};
