import { Fragment } from 'react';

export default function HowToStopSubscription(): JSX.Element {
    const lists = [
        'Company User deletes his account',
        'Subscriptions will be terminated',
        'Company personal data (not having a relationship with other companies such as: Projects, Documents, etc.) will be deleted gently for 30 days (can be restored when contacting customer service) then after that it will be permanently deleted.',
    ];

    return (
        <Fragment>
            <ul className="text-grey-300 mb-10 list-disc pl-10">
                {lists.map((list, index) => (
                    <li key={index}>{list}</li>
                ))}
            </ul>
        </Fragment>
    );
}
