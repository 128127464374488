import { useEffect, useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';

import styled from 'styled-components';

import { DetailTaskProps } from '../../interfaces';

import InformationTask from './InformationTask';
import { useForm } from 'react-hook-form';
import AdditionalInformation from './AdditionalInformation';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import HistoryTask from './HistoryTask';
import { RootState } from '@/redux/store';
import { useSelector } from 'react-redux';
import defaultColorTask from '@/assets/json/default_color_task.json';
import moment from 'moment';
import { useAppDispatch } from '@/redux/hook';

import { capitalizeFirstLetter, getCredential } from '@/utility/Utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
    resetDeleteTaskComment,
    resetEditTaskComment,
    resetPostTaskComment,
} from '../../redux/actions';

export default function DetailTask({
    subtask,
    modal,
    setModal,
    projectId,
    setModalType,
    assignees,
    setAssignees,
    selectedTask,
    setSelectedTask,
    type,
    handleSuccessEditTask,
    handleSelectSubtask,
    handleOpenModalArchive,
    handleAddSubtask,
    handleSendComment,
    handleEditComment,
    handleDeleteComment,
    handleOpenModalRemoveAssignee,
    handleDetailTask,
    commentId,
    permissions,
    setIsLoadingAssignee,
    isLoadingAssignee,
    handleDragDropUpdateState,
    backTasks,
    setBackTasks,
}: DetailTaskProps): JSX.Element {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
    });

    const dispatch = useAppDispatch();

    const detailTaskCommentState = useSelector(
        (state: RootState) => state.detailTaskComment,
    );
    const detailTaskState = useSelector((state: RootState) => state.detailTask);
    const postTaskCommentState = useSelector((state: RootState) => state.postTaskComment);
    const editTaskCommentState = useSelector((state: RootState) => state.editTaskComment);
    const deleteTaskCommentState = useSelector(
        (state: RootState) => state.deleteTaskComment,
    );

    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
        new Date(new Date().setDate(new Date().getDate())),
        new Date(new Date().setDate(new Date().getDate() + 10)),
    ]);

    const [nicheLists, setNicheLists] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [statusLists, setStatusLists] = useState<
        Array<{
            value: string;
            label: JSX.Element;
        }>
    >([]);
    const [comment, setComment] = useState<any>([]);

    // if date change, then calculate the duration
    useEffect(() => {
        if (dateRange[0] !== null && dateRange[1] !== null) {
            setValue(
                'total_duration',
                moment(dateRange[1]).diff(moment(dateRange[0]), 'days') + 1,
            );
        }
    }, [dateRange]);

    // If get comment success
    useEffect(() => {
        if (detailTaskCommentState?.status === 200) {
            setComment(detailTaskCommentState?.response);
        }
    }, [detailTaskCommentState]);

    // If post comment success
    useEffect(() => {
        if (postTaskCommentState?.status === 200) {
            setComment((prev: any) => [...prev, postTaskCommentState?.response]);

            dispatch(resetPostTaskComment());
        }
    }, [postTaskCommentState]);

    // If edit comment success
    useEffect(() => {
        if (editTaskCommentState?.status === 200) {
            setComment((prev: any) => [
                ...prev.map((item: any) =>
                    item.id === editTaskCommentState?.response.id
                        ? editTaskCommentState?.response
                        : item,
                ),
            ]);

            dispatch(resetEditTaskComment());
        }
    }, [editTaskCommentState]);

    // If delete comment success
    useEffect(() => {
        if (deleteTaskCommentState?.status === 200) {
            setComment((prev: any) => [
                ...prev.filter((item: any) => item.id !== commentId),
            ]);
            setModal(prev => ({ ...prev, deleteCautionComment: false }));

            dispatch(resetDeleteTaskComment());
        }
    }, [deleteTaskCommentState]);

    const tabs = [
        {
            key: 'taskInformation',
            title: 'Task Information',
            page: (
                <InformationTask
                    subtask={subtask}
                    register={register}
                    errors={errors}
                    control={control}
                    watch={watch}
                    selectedTask={selectedTask}
                    commentData={comment}
                    defaultColorLists={defaultColorTask}
                    handleSendComment={handleSendComment}
                    handleEditComment={handleEditComment}
                    handleDeleteComment={handleDeleteComment}
                    setModal={setModal}
                    handleSelectSubtask={handleSelectSubtask}
                    handleAddSubtask={handleAddSubtask}
                    permissions={permissions}
                    handleDetailTask={handleDetailTask}
                    handleDragDropUpdateState={handleDragDropUpdateState}
                />
            ),
        },
        {
            key: 'history',
            title: 'History',
            page: <HistoryTask />,
        },
    ];

    const handleLabel = (item: { name: string; value: string }): JSX.Element => {
        const handleBackground = (value: string): string => {
            switch (value) {
                case 'open':
                    return '#E0E0E0';
                case 'in progress':
                    return '#DDDAFE';
                case 'on hold':
                    return '#FFF5DA';
                case 'completed':
                    return '#CDF2E6';

                default:
                    return '#FFC107';
            }
        };

        const handleColor = (value: string): string => {
            switch (value) {
                case 'open':
                    return '#FFF';
                case 'in progress':
                    return '#5648FB';
                case 'on hold':
                    return '#FFCB45';
                case 'completed':
                    return '#06BD80';

                default:
                    return '#06BD80';
            }
        };

        const handleInitial = (value: string): string => {
            switch (value) {
                case 'open':
                    return 'O';
                case 'in progress':
                    return 'P';
                case 'on hold':
                    return 'H';
                case 'completed':
                    return 'C';

                default:
                    return 'O';
            }
        };

        return (
            <div className="d-flex">
                <span
                    className="me-2"
                    style={{
                        width: 25,
                        height: 25,
                        borderRadius: 5,
                        backgroundColor: handleBackground(item.value),
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: handleColor(item.value),
                    }}
                >
                    {handleInitial(item.value)}
                </span>
                {item.name}
            </div>
        );
    };

    useEffect(() => {
        if (selectedTask !== null) {
            const {
                name,
                description,
                color,
                status,
                startDate,
                endDate,
                niche,
                parent_task_name,
            } = selectedTask;
            // map array status change to status.name to status.label
            const statusLists = status.map(
                (item: { name: string; value: string; selected: boolean }) => {
                    // if selected true set to state
                    if (item.selected) {
                        setValue('status', item?.value);
                    }

                    return {
                        value: item.value,
                        label: handleLabel(item),
                    };
                },
            );
            setStatusLists(statusLists);

            setValue('name', name);
            setValue('trade', niche.id);
            setValue('color', color);
            setValue('description', description);
            setValue('parent_task_name', parent_task_name);
            setDateRange([new Date(startDate), new Date(endDate)]);

            // FIXME: Fix the assignee
            // setAssignees(assignees);
        }
    }, [selectedTask]);

    const handleNumberAccess = (
        can_collaborate_schedule: boolean,
        can_view_schedule: boolean,
    ): number => {
        let result = 0;

        if (can_collaborate_schedule) {
            result = 2;
        } else if (can_view_schedule) {
            result = 1;
        }

        return result;
    };

    useEffect(() => {
        if (detailTaskState?.status === 200) {
            const assigneeResponse = detailTaskState?.response?.assignees?.map(
                (item: any) => ({
                    id: item?.id,
                    can_invite_user: item?.can_invite_schedule === 1,
                    disabled: item?.disabled,
                    user_name: item?.user_name,
                    user_avatar: item?.user_avatar,
                    user_email: item.user_email,
                    permission: item?.member_space_schedules?.map((permission: any) => ({
                        access: handleNumberAccess(
                            permission?.can_collaborate_schedule,
                            permission?.can_view_schedule,
                        ),
                        type: capitalizeFirstLetter(permission?.space_type),
                        disable_option: permission?.disable_option,
                        no_access_disabled: permission?.no_access_disabled,
                    })),
                }),
            );

            setAssignees(assigneeResponse);

            setTimeout(() => {
                setIsLoadingAssignee(false);
            }, 2000);
        }
    }, [detailTaskState]);

    /**
     * Function on submit
     *
     * @param object data - any
     * @return void
     */
    const onSubmit = (data: any): void => {
        const apiUrl: string = import.meta.env.VITE_BASE_URL;
        const credential = getCredential();
        const { token } = credential as any;

        setModalType(subtask.id !== null ? 'update subtask' : 'update task');
        setIsLoading(true);

        const newData = {
            ...data,
            project_id: projectId,
            color: data.color,
            type,
            parent_id: null,
            start_at: moment(dateRange[0]).format('YYYY-MM-DD'),
            end_at: moment(dateRange[1]).format('YYYY-MM-DD'),
            assignees: assignees.map((item: { id: number }) => item.id),
            trade: data.trade,
        };

        if (subtask.id !== null) {
            newData.parent_id = subtask.id;
        }

        // Dispatch login action with form data as payload
        axios
            .put(
                `${apiUrl}/v2/project/tasks/${selectedTask?.id ?? '0'}/update`,
                newData,
                {
                    headers: {
                        Authorization: `Bearer ${token as string}`,
                    },
                },
            )
            .then(res => {
                const item = res?.data;

                handleSuccessEditTask({
                    ...item,
                    startDate: moment(item.start_at).format('YYYY-MM-DD'),
                    endDate: moment(item.end_at).format('YYYY-MM-DD'),
                    backgroundColor: item.color,
                    borderColor: item.color,
                    level: subtask.id !== null ? subtask.level + 1 : 0,
                });

                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
                toast.error(err.response.data.message);
            });
    };

    return (
        <>
            <ModalWrapper
                show={modal.detailTask}
                onHide={() => {
                    setModal(prev => ({ ...prev, detailTask: false }));
                    setBackTasks(null);
                }}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header
                    closeButton
                    style={{
                        padding: '1.5rem 2.3rem',
                    }}
                >
                    <h6 className="fw-semibold mb-0">
                        {subtask.id !== null ? 'Subtask Information' : 'Task Information'}
                    </h6>
                </Modal.Header>
                <form onSubmit={handleSubmit(onSubmit)} method="post">
                    <Modal.Body className="py-0">
                        <div className="row">
                            <div className="col-lg-7 col-xl-8 px-0">
                                <WrapperTabs
                                    defaultActiveKey={tabs[0].key}
                                    id="uncontrolled-tab-example"
                                    justify
                                >
                                    {tabs.map(tab => (
                                        <Tab
                                            eventKey={tab.key}
                                            title={tab.title}
                                            key={tab.key}
                                            className="p-4"
                                        >
                                            {tab.page}
                                        </Tab>
                                    ))}
                                </WrapperTabs>
                            </div>
                            <div className="col-lg-5 col-xl-4 border-start px-0">
                                <AdditionalInformation
                                    register={register}
                                    errors={errors}
                                    control={control}
                                    nicheLists={nicheLists}
                                    statusLists={statusLists}
                                    assignees={assignees}
                                    dateRange={dateRange}
                                    selectedTask={selectedTask}
                                    setDateRange={setDateRange}
                                    setModal={setModal}
                                    setNicheLists={setNicheLists}
                                    setSelectedTask={setSelectedTask}
                                    handleLabel={handleLabel}
                                    permissions={permissions}
                                    handleOpenModalRemoveAssignee={
                                        handleOpenModalRemoveAssignee
                                    }
                                    isLoadingAssignee={isLoadingAssignee}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="d-flex justify-content-between px-3 pb-4 pt-2">
                                <div>
                                    {permissions.collaborate &&
                                    selectedTask?.deleted_at === null ? (
                                        <ButtonBasic
                                            id="btnSubmit"
                                            text="Save Changes"
                                            type="submit"
                                            className="btn btn-primary btn-sm me-2"
                                            loading={isLoading}
                                        />
                                    ) : null}
                                    <ButtonBasic
                                        id="btnCancel"
                                        text="Cancel"
                                        type="button"
                                        className="btn btn-outline-primary btn-sm"
                                        onClick={() => {
                                            if (
                                                backTasks !== null &&
                                                backTasks.length > 1
                                            ) {
                                                handleDetailTask(
                                                    backTasks[backTasks.length - 2],
                                                    true,
                                                );
                                                setBackTasks((prev: any) =>
                                                    prev.slice(0, -1),
                                                );
                                            } else {
                                                setBackTasks(null);
                                                setModal(prev => ({
                                                    ...prev,
                                                    detailTask: false,
                                                }));
                                            }
                                        }}
                                    />
                                </div>

                                {permissions.collaborate &&
                                selectedTask?.deleted_at === null ? (
                                    <ButtonBasic
                                        id="bntArchive"
                                        text="Move to Archive"
                                        type="button"
                                        className="btn btn-outline-danger btn-sm"
                                        onClick={handleOpenModalArchive}
                                    />
                                ) : null}
                            </div>
                        </div>
                    </Modal.Body>
                </form>
            </ModalWrapper>
        </>
    );
}

const ModalWrapper = styled(Modal)``;

const WrapperTabs = styled(Tabs)`
    border-bottom: 0 !important;

    .nav-link {
        border: none;
        border-bottom: 2px solid rgb(230, 230, 230);
        background: none !important;
        color: rgba(207, 207, 207, 1);
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        padding-left: 0;
        padding-right: 0;
        padding: 1rem 1.5rem;
        &.active {
            color: #5648fb !important;
            border-bottom: 2px solid #5648fb;
        }
    }
`;
