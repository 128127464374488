import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import InputSearchSelect from '@/components/atoms/Inputs/InputSearchSelect';
import ModalAction from '@/components/atoms/Modals/ModalAction';
import { useEffect, useState } from 'react';
import { sendDocumentToEmail } from '../redux/actions';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllMemberInhouse,
    getAllMemberInvited,
} from '@/routes/Dashboard/Project/redux/actions';
import { RootState } from '@/redux/store';
import InputArea from '@/components/atoms/Inputs/InputArea';
import { useForm } from 'react-hook-form';

export default function ModalDocumentSend({
    modal,
    setModal,
    selected,
}: {
    modal: boolean;
    setModal: (modal: boolean) => void;
    selected: string[];
}): JSX.Element {
    const {
        register,
        getValues,
        resetField,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
    });

    const { id } = useParams();
    const dispatch = useDispatch();

    const projectId = id ?? '';

    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/g;

    const allMemberInhouseState = useSelector(
        (state: RootState) => state.allMemberInhouse,
    );
    const allMemberInvitedState = useSelector(
        (state: RootState) => state.allMemberInvited,
    );

    const sendDocumentState = useSelector((state: RootState) => state.sendDocument);

    const [selectedUser, setSelectedUser] = useState<
        Array<{
            label: string;
            value: string;
        }>
    >([]);

    const [isValidate, setIsValidate] = useState<boolean>(false);
    const [userList, setUserList] = useState<
        Array<{
            value: string;
            label: string;
        }>
    >([]);

    useEffect(() => {
        if (modal) {
            dispatch(
                getAllMemberInvited({ project_id: projectId, page: 1, per_page: 9999 }),
            );
            dispatch(
                getAllMemberInhouse({ project_id: projectId, page: 1, per_page: 9999 }),
            );
        }

        if (!modal) {
            setSelectedUser([]);
            setIsValidate(false);
            resetField('comment');
        }
    }, []);

    useEffect(() => {
        if (sendDocumentState?.status === 200) {
            setSelectedUser([]);
            setIsValidate(false);
            resetField('comment');
        }
    }, [sendDocumentState]);

    useEffect(() => {
        if (allMemberInhouseState?.status === 200) {
            const data = allMemberInhouseState?.response?.data;
            const newData = data.map((item: any) => {
                return {
                    label: item.name,
                    value: item.email,
                };
            });

            setUserList((prevData: any) => {
                const mergedData = prevData.concat(newData);
                const filteredData = mergedData.filter(
                    (item: any, index: number, self: any) =>
                        index === self.findIndex((t: any) => t.value === item.value),
                );
                return filteredData;
            });
        }

        if (allMemberInvitedState?.status === 200) {
            const data = allMemberInvitedState?.response?.data;
            const newData = data.map((item: any) => {
                return {
                    label: item.name,
                    value: item.email,
                };
            });

            setUserList((prevData: any) => {
                const mergedData = prevData.concat(newData);
                const filteredData = mergedData.filter(
                    (item: any, index: number, self: any) =>
                        index === self.findIndex((t: any) => t.value === item.value),
                );
                return filteredData;
            });
        }
    }, [allMemberInhouseState, allMemberInvitedState]);

    const handleChange = (val: Array<{ value: string; label: string }>): void => {
        setSelectedUser(val);
    };

    const handleBlur = (e: any): void => {
        const val = e.target.value;

        if (val !== '') {
            // Add validation email
            const isValid = regex.test(val);

            if (isValid) {
                setSelectedUser([...selectedUser, { label: val, value: val }]);

                return setIsValidate(false);
            }

            setIsValidate(true);
        }
    };

    const handleCreateOption = (val: string): void => {
        // Add validation email
        const isValid = regex.test(val);

        if (isValid) {
            setSelectedUser([...selectedUser, { label: val, value: val }]);

            return setIsValidate(false);
        }

        setIsValidate(true);
    };

    const handleSendToMail = (): void => {
        const formData = new FormData();

        selected.forEach((item: any) => {
            formData.append('ids[]', item);
        });

        selectedUser.forEach((item: any) => {
            formData.append('emails[]', item.value);
        });

        formData.append('comment', getValues('comment'));

        dispatch(
            sendDocumentToEmail(
                {
                    project_id: projectId,
                },
                formData,
            ),
        );
    };

    return (
        <ModalAction
            modal={modal}
            setModal={setModal}
            aria-labelledby="contained-modal-title-vcenter"
            title={'Send Document'}
            size={'md'}
        >
            <div className="p-3 pb-0">
                <div className="w-full text-end">
                    <small className="text-muted">{selected.length} items selected</small>
                </div>
                <div className="form-group mb-3">
                    <InputSearchSelect
                        placeholder="Invite or type email"
                        innerClassName="mb-1"
                        outerClassName="mt-3"
                        options={userList}
                        isMulti={true}
                        withCreatable
                        placeholderCreatable="Invite"
                        value={selectedUser}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onCreateOption={handleCreateOption}
                    />
                    {isValidate && (
                        <span
                            className="text-danger"
                            style={{
                                fontSize: 12,
                            }}
                        >
                            Please enter a valid email address
                        </span>
                    )}
                </div>

                <InputArea
                    id="Comment"
                    placeholder="Comment"
                    outerClassName="mb-4"
                    rules={{
                        function: register,
                        name: 'comment',
                        rules: {},
                        errors,
                    }}
                />

                <ButtonBasic
                    type="button"
                    text="Send"
                    onClick={() => handleSendToMail()}
                    loading={sendDocumentState?.loading}
                    className="w-25 btn-primary"
                />
            </div>
        </ModalAction>
    );
}
