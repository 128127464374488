import { Fragment, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import VerticalStepper from '@/components/molecules/VerticalStepper';
import Loader from '@/components/molecules/Loader';

import CompanyDetails from './CompanyUser/CompanyDetails';
import CompanyNiche from './CompanyUser/CompanyNiche';
import CompanyPeopleRange from './CompanyUser/CompanyPeopleRange';
import InviteInhouse from './CompanyUser/InviteInhouse';
import { useDispatch, useSelector } from 'react-redux';

import { dataURLtoFile, decryptData } from '@/utility/Utils';

import {
    registerCompanyCompleted,
    getCompanyUserCredentialsToken,
    getNiche,
    getSubContractor,
    clearRegisterState,
} from '../redux/actions';

import { RootState } from '@/redux/store';
import Cookies from 'js-cookie';
import { useSearchBoxCore } from '@mapbox/search-js-react';
import { MapRef } from 'react-map-gl';

interface DataProps {
    id: number;
    name: string;
    trades: DataProps[];
}

export default function SetAccount(): JSX.Element {
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        unregister,
        control,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);

    const [activeStep, setActiveStep] = useState(0);
    const [loadParameters, setLoadParameter] = useState<any>(null);

    const [nicheLists, setNicheList] = useState<DataProps[]>([]);
    const [subContractorLists, setSubContractorLists] = useState<DataProps[]>([]);

    const mapRef = useRef<MapRef>();

    const [defaultMap, setDefaultMap] = useState({
        lat: -33.8688,
        lng: 151.2093,
        address: 'Sydney',
        zoom: 15,
    });

    const companyUserCredentialState = useSelector(
        (state: RootState) => state.companyUserCredential,
    );
    const allNicheSubContractorState = useSelector(
        (state: RootState) => state.allNicheSubContractor,
    );
    const registerCompletedState = useSelector(
        (state: RootState) => state.registerCompanyCompleted,
    );
    const subContractorOther = subContractorLists?.find(
        (item: any) => item.name === 'Other',
    );
    const nicheOther = nicheLists?.find((item: any) => item.name === 'Other');

    const cookiesCompany = decryptData(Cookies?.get('company') as any);
    const parseCookies = JSON.parse(cookiesCompany);

    const stepStorage = sessionStorage.getItem('step');
    const temp = JSON.parse(sessionStorage.getItem('temp') as any);
    const location = JSON.parse(sessionStorage.getItem('location') as any);

    // get set to state
    useEffect(() => {
        if (allNicheSubContractorState?.status === 200) {
            setNicheList(allNicheSubContractorState?.niche?.data);
            setSubContractorLists(allNicheSubContractorState?.subcontractor?.data);
        }
    }, [allNicheSubContractorState]);

    // get all niche and subcontractor
    useEffect(() => {
        dispatch(getNiche());
        dispatch(getSubContractor());
    }, []);

    // Load step from session storage
    useEffect(() => {
        // if the step is not null
        if (stepStorage !== null) {
            // set the value to the state
            setActiveStep(JSON.parse(stepStorage));

            // set the value to the form
            if (temp !== null) {
                for (const key in temp) {
                    setValue(key, temp[key]);
                }
            }
        }
    }, [stepStorage]);

    // Load location from session storage
    useEffect(() => {
        if (location !== null) {
            setTimeout(() => {
                setDefaultMap((prev: any) => ({
                    ...prev,
                    lat: location.lat,
                    lng: location.lng,
                    address: location.address,
                }));
            }, 1000);
        } else {
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition(async function (position) {
                    const { latitude, longitude } = position.coords;
                    const result = await handleFindAddress({
                        lat: latitude,
                        lng: longitude,
                    });

                    setDefaultMap((prev: any) => ({
                        ...prev,
                        lat: latitude,
                        lng: longitude,
                        address:
                            result?.features[0]?.properties?.place_formatted ??
                            `lat:${latitude}, lang:${longitude}`,
                    }));

                    mapRef.current?.flyTo({
                        center: [longitude, latitude],
                        duration: 2000,
                    });
                });
            }
        }
    }, []);

    // set params value to the state
    useEffect(() => {
        // loop through query params
        for (const [key, value] of queryParams) {
            // change the key name to camel case
            const camelCaseKey = key.replace(/_([a-z])/g, g => g[1].toUpperCase());

            // set the value to the state
            setLoadParameter((prev: any) => ({
                ...prev,
                [camelCaseKey]: value,
            }));
        }
    }, [search]);

    // set the value to the form
    useEffect(() => {
        if (loadParameters !== null) {
            // set the value to the form
            dispatch(getCompanyUserCredentialsToken(loadParameters.verificationToken));

            // set redirect url to session storage
            if (loadParameters?.redirectAfterRegister !== null) {
                sessionStorage.setItem('redirectUrl', '/notification');
            }
        }
    }, [loadParameters]);

    // handle register completed
    useEffect(() => {
        if (registerCompletedState.status === 200) {
            // remove all session storage
            // sessionStorage?.removeItem('user_avatar');
            sessionStorage?.clear();
            Cookies?.remove('company');

            navigate('/register/complete');
        }
    }, [registerCompletedState]);

    // component did unmount
    useEffect(() => {
        return () => {
            dispatch(clearRegisterState());
        };
    }, []);

    // set the value to the form if the status is 200
    useEffect(() => {
        if (companyUserCredentialState.status === 200) {
            setValue('user_name', temp?.user_name ?? parseCookies?.user_name);
            setValue('user_email', companyUserCredentialState?.response?.email);
        }

        // if the status is 400 redirect to not found page
        // TODO:ON After end
        if (companyUserCredentialState.status === 400) {
            navigate('/token-expired');
        }
    }, [companyUserCredentialState]);

    /**
     * Initialize steps
     */
    const steps = [
        {
            title: 'Personal Details',
            subtitle: 'Add personal details',
            component: (
                <CompanyDetails
                    control={control}
                    register={register}
                    errors={errors}
                    defaultMap={defaultMap}
                    setDefaultMap={setDefaultMap}
                    mapRef={mapRef}
                />
            ),
        },
        {
            title: 'Construction Company Niche',
            subtitle: 'Select company niche',
            component: (
                <CompanyNiche
                    register={register}
                    errors={errors}
                    watch={watch}
                    nicheLists={nicheLists}
                    subContractorLists={subContractorLists}
                />
            ),
        },
        {
            title: 'Number Of People',
            subtitle: 'Select number of people',
            component: <CompanyPeopleRange register={register} errors={errors} />,
        },
        {
            title: 'Invite Inhouse Worker',
            subtitle: 'Invite worker (Optional)',
            component: (
                <InviteInhouse
                    register={register}
                    errors={errors}
                    unregister={unregister}
                    watch={watch}
                />
            ),
        },
    ];
    const key = import.meta.env.VITE_MAP_BOX;

    const searchBoxCore = useSearchBoxCore({
        accessToken: key,
        types: 'country, place, region, postcode, district, locality, street, address',
    });

    /**
     * Function to handle find address
     *
     * @param latLang lat and lang
     * @returns {Promise<any>}
     */
    const handleFindAddress = async (latLang: {
        lng: number;
        lat: number;
    }): Promise<any> => {
        const response = await searchBoxCore.reverse(latLang);

        return response;
    };

    /**
     * Function to handle back button
     *
     * @return {void}
     */
    // const handleBack = (): void => {
    //     setActiveStep(activeStep - 1);
    // };

    /**
     * Function on submit and handle next and previous button
     *
     * @param {object} data
     * @return {void}
     */
    const onSubmit = (data: any, type: string): void => {
        if (type === 'next') {
            // validation location
            // if (markerPosition.lat === 0 && markerPosition.lng === 0) {
            //     setIsValidLocation(true);
            //     return;
            // } else {
            //     setIsValidLocation(false);
            // }

            setActiveStep(activeStep + 1);

            // add step to session storage
            sessionStorage.setItem('step', JSON.stringify(activeStep + 1));

            // add data to session storage
            sessionStorage.setItem('temp', JSON.stringify(data));
        }

        if (type === 'prev') {
            setActiveStep(activeStep - 1);

            // add step to session storage
            sessionStorage.setItem('step', JSON.stringify(activeStep - 1));
        }

        if (type === 'finish') {
            for (const key in data) {
                if (key.includes('invited_inhouse_users') && data[key] !== '') {
                    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                    data[key].trim() === '' && delete data[key];

                    const newKey = key.replace(
                        /invited_inhouse_users(\d+)/,
                        'invited_inhouse_user_emails[$1]',
                    );
                    data[newKey] = data[key];
                    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                    delete data[key];
                }
            }
            // initialize form data
            const payload = new FormData();

            // append data to form data
            for (const key in data) {
                payload.append(key, data[key as keyof typeof data]);
            }

            // remove company_sub_contract_trade_id if the value is not 6(sub contractor)
            if (
                Number(data?.company_niche_id) !==
                nicheLists?.find(list => list.name === 'Sub Contractor')?.id
            ) {
                payload.delete('company_sub_contract_trade_id');
            }

            // remove company_niche_other and company_sub_contract_trade_other if the value is not other
            if (Number(data?.company_niche_id) === nicheOther?.id) {
                payload.delete('company_niche_id');
                payload.delete('company_sub_contract_trade_id');
            } else {
                payload.delete('company_niche_other');
            }

            // remove company_niche_other and company_sub_contract_trade_other if the value is not other
            if (Number(data?.company_sub_contract_trade_id) === subContractorOther?.id) {
                payload.delete('company_sub_contract_trade_id');
            } else {
                payload.delete('company_sub_contract_trade_other');
            }

            // remove invited_inhouse_users if not included [] if
            if (payload.get('invited_inhouse_users') === '') {
                payload.delete('invited_inhouse_users');
            }

            // append user_avatar from session storage to file
            const userAvatar = sessionStorage.getItem('user_avatar');
            if (userAvatar != null) {
                const file = dataURLtoFile(userAvatar, 'user_avatar');
                payload.append('user_avatar', file);
            }

            // if (data.company_address !== null) {
            payload.append('company_address', defaultMap.address);
            payload.append('company_address_latitude', defaultMap.lat.toString());
            payload.append('company_address_longitude', defaultMap.lng.toString());

            payload.append('user_password', parseCookies?.user_password);

            // address
            // payload.delete('company_address');
            // }

            dispatch(
                registerCompanyCompleted(
                    payload,
                    loadParameters?.verificationToken,
                    true,
                ),
            );
        }
    };

    return (
        <Fragment>
            {registerCompletedState.loading === true ||
                (companyUserCredentialState.loading === true && <Loader />)}
            <div className="container-fluid container-xs container-lg">
                <main>
                    <VerticalStepper
                        steps={steps}
                        onNext={handleSubmit(data => onSubmit(data, 'next'))}
                        onPrev={handleSubmit(data => onSubmit(data, 'prev'))}
                        onFinish={handleSubmit(data => onSubmit(data, 'finish'))}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        loading={registerCompletedState.loading}
                    />
                </main>
            </div>
        </Fragment>
    );
}
