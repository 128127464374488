import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiOutlineMail } from 'react-icons/hi';
import { FiPhone } from 'react-icons/fi';

import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import { Title } from '@/components/molecules/Typography';

import styled from 'styled-components';

interface MethodListsProps {
    id: number;
    title: string;
    icon: JSX.Element;
}

interface CardMethodProps {
    selected: number;
    index: number;
}

export default function ForgotPasswordMethod(): JSX.Element {
    const navigate = useNavigate();

    const [selectedMethod, setSelectedMethod] = useState<number>(0);

    const methodLists: MethodListsProps[] = [
        {
            id: 1,
            title: 'Email',
            icon: (
                <HiOutlineMail
                    size={50}
                    color={selectedMethod === 1 ? '#5648fb' : '#838383'}
                />
            ),
        },
        {
            id: 2,
            title: 'Phone Number',
            icon: (
                <FiPhone size={50} color={selectedMethod === 2 ? '#5648fb' : '#838383'} />
            ),
        },
    ];

    /**
     * Function to handle redirect on confirm button
     *
     * @returns {void}
     */
    const handleConfirm = (): void => {
        selectedMethod === 1
            ? navigate('/forgot-password/enter-email', {
                  state: { nextStep: true },
              })
            : navigate('/forgot-password/enter-phone', {
                  state: { nextStep: true },
              });
    };

    return (
        <Fragment>
            <div className="row mb-5">
                <div className="col-md-12 text-center">
                    <Title text="Choose Method" />
                </div>
                <div className="col-md-8 col-lg-6 col-xxl-4 mx-auto text-center">
                    <span className="text-muted">
                        Choose method to reset your password
                    </span>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-md-8 col-lg-8 col-xxl-6 mx-auto">
                    <div className="row mb-3">
                        {methodLists.map(item => (
                            <div
                                id={item.id.toString()}
                                key={item.id}
                                className="col-xs-12 col-sm-6 col-md-6 col-lg-6 my-2"
                                onClick={() => setSelectedMethod(item.id)}
                            >
                                <CardMethod
                                    className="card p-5 text-center"
                                    index={item.id}
                                    selected={selectedMethod}
                                >
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        {item.icon}
                                        <span
                                            className={`mt-3 ${
                                                selectedMethod === item.id
                                                    ? 'text-primary fw-semibold'
                                                    : 'text-muted'
                                            }`}
                                        >
                                            {item.title}
                                        </span>
                                    </div>
                                </CardMethod>
                            </div>
                        ))}
                    </div>
                    <div className="row">
                        <div className="col-lg-8 mx-auto text-center">
                            <ButtonBasic
                                type="button"
                                text="Confirm"
                                className="my-3 w-50 btn-primary"
                                onClick={handleConfirm}
                                disabled={selectedMethod === 0}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const CardMethod = styled.div<CardMethodProps>`
    border: ${props =>
        props.selected === props.index ? '2px solid #5648fb' : '2px solid #838383'};
    border-radius: 10px;
    cursor: pointer;
    background-color: transparent;
`;
