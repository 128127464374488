import { useEffect, useRef, useState } from 'react';

import BellIcons from '@/assets/svg/icons/bell.svg';
import MailIcons from '@/assets/svg/icons/mail.svg';

import styled from 'styled-components';

import ChatList from './ChatList';
import NewChat from './NewChat';
import NewGroupChat from './NewGroupChat';
import { useSelector } from 'react-redux';
import { generateInitialImageUrl, getId, getInitialName } from '@/utility/Utils';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RootState } from '@/redux/store';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import BasicTooltip from '@/components/atoms/Tooltips';

interface ParamProps {
    handleNotification: () => void;
    handleOpenModalAddBug: () => void;
    innerRef?: any;
    count: number;
}

export default function RightSidebar(params: ParamProps): JSX.Element {
    const { handleNotification, handleOpenModalAddBug, innerRef, count } = params;

    const navigate = useNavigate();
    const location = useLocation();
    // const active = location.pathname;
    const { id } = useParams();

    const chatListState = useSelector((state: RootState) => state.chatList);
    const totalOnlineChatState = useSelector((state: RootState) => state.totalOnlineChat);

    const [canvas, setCanvas] = useState({
        list: false,
        new: false,
        group: false,
    });

    const unreadNotificationState = useSelector(
        (state: RootState) => state.unreadNotifications,
    );
    const allUnreadProjectNotificationState = useSelector(
        (state: RootState) => state.allUnreadProjectNotification,
    );

    const totalUnreadGeneralNotification = unreadNotificationState?.totalUnread;
    const totalUnreadProjectNotification = allUnreadProjectNotificationState?.totalUnread;

    /**
     * Function to handle redirect and close all canvas
     *
     * @returns void
     */
    const handleCloseNavigate = (): void => {
        setCanvas({
            list: false,
            new: false,
            group: false,
        });
    };

    /**
     * Function to handle redirect to chat page
     *
     * @param id
     */
    const handleRedirect = (id: string): void => {
        navigate('/chat', {
            state: {
                id,
            },
        });
    };

    /**
     * Function for handle notification
     *
     * @returns void
     */
    const NotificationFloat = (): JSX.Element => {
        const wrapperRef = useRef<HTMLDivElement | null>(null);
        const [menuOpen, setMenuOpen] = useState(false);

        const handleClickOutside = (event: MouseEvent): void => {
            if (
                wrapperRef.current != null &&
                !wrapperRef.current.contains(event.target as Node)
            ) {
                setMenuOpen(false);
            }
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);

        if (id !== undefined) {
            return (
                <WrapperNotification>
                    <nav className="menu" ref={wrapperRef}>
                        <input
                            type="checkbox"
                            className="menu-open"
                            name="menu-open"
                            id="menu-open"
                            checked={menuOpen}
                            onChange={() => setMenuOpen(!menuOpen)}
                        />
                        <label className="menu-open-button" htmlFor="menu-open">
                            <span className="lines line-2">
                                <BellIcon src={BellIcons} />
                            </span>
                        </label>

                        <Link to="/notification" className="menu-item btn-purple">
                            {menuOpen && totalUnreadGeneralNotification > 0 && (
                                <div className="notification-count-dropdown">
                                    <span className="count">
                                        {totalUnreadGeneralNotification > 99
                                            ? '99+'
                                            : totalUnreadGeneralNotification}
                                    </span>
                                </div>
                            )}
                            <BasicTooltip text="General Notification">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    height={22}
                                    width={22}
                                    style={{
                                        verticalAlign: 'unset',
                                    }}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 10.5v6m3-3H9m4.06-7.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                                    />
                                </svg>
                            </BasicTooltip>
                        </Link>
                        <Link
                            to={`/project/${id}/notification`}
                            className="menu-item btn-purple"
                        >
                            {menuOpen && totalUnreadProjectNotification > 0 && (
                                <div className="notification-count-dropdown">
                                    <span className="count">
                                        {totalUnreadProjectNotification > 99
                                            ? '99+'
                                            : totalUnreadProjectNotification}
                                    </span>
                                </div>
                            )}
                            <BasicTooltip text="Project Notification">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    height={22}
                                    width={22}
                                    style={{
                                        verticalAlign: 'unset',
                                    }}
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                                    />
                                </svg>
                            </BasicTooltip>
                        </Link>
                    </nav>
                </WrapperNotification>
            );
        }

        return (
            <NotificationButton
                onClick={handleNotification}
                ref={innerRef}
                style={!location.pathname.includes('summary') ? {} : { display: 'none' }}
            >
                <BellIcon src={BellIcons} />
                {count > 0 && (
                    <div className="notification-count">
                        <span className="count"> {count > 99 ? '99+' : count}</span>
                    </div>
                )}
            </NotificationButton>
        );
    };

    return (
        <ContentWrapper>
            <div
                className="d-flex justify-content-center flex-column align-items-center"
                style={{ position: 'relative' }}
            >
                <ReportButton onClick={handleOpenModalAddBug} ref={innerRef}>
                    <HiOutlineExclamationCircle color="#fff" size="22" />
                </ReportButton>
                <NotificationFloat />
                {/* New */}
            </div>

            <MessageWrapper>
                <ButtonChat
                    className="btn btn-primary"
                    onClick={() => setCanvas(prev => ({ ...prev, list: true }))}
                >
                    <MessageIcon src={MailIcons} />
                    <VerticalText>
                        Chat ({totalOnlineChatState?.response?.online_count} Online)
                    </VerticalText>
                    <CircleOnline
                        online={totalOnlineChatState?.response?.online_count > 0}
                    />
                </ButtonChat>
                {/* {profileList.map(list => ( */}
                {chatListState?.broadcast
                    ?.filter((item: any) =>
                        item.pinned_by.find((i: any) => i.id === getId()),
                    )
                    .slice(0, 3)
                    .map((list: any, index: number) =>
                        list.message_channel_category_name === 'Direct Message' ? (
                            <ProfileIcon
                                key={index}
                                src={
                                    list?.participants[0]?.avatar ??
                                    generateInitialImageUrl(list?.participants[0]?.name)
                                }
                                alt="profile-icon"
                                onClick={() => handleRedirect(list.id)}
                            />
                        ) : (
                            <GroupPicture
                                key={index}
                                className="chat-profile"
                                onClick={() => handleRedirect(list.id)}
                            >
                                <span className="initial-name">
                                    {getInitialName(
                                        list?.name ?? 'Unregistered New User',
                                    )}
                                </span>
                            </GroupPicture>
                        ),
                    )}
                {/* ))} */}
            </MessageWrapper>
            <ChatList
                show={canvas.list}
                setShow={setCanvas}
                handleClose={() => handleCloseNavigate()}
            />
            <NewChat
                show={canvas.new}
                setShow={setCanvas}
                handleClose={() => handleCloseNavigate()}
            />
            <NewGroupChat
                show={canvas.group}
                setShow={setCanvas}
                handleClose={() => handleCloseNavigate()}
            />
        </ContentWrapper>
    );
}

const WrapperNotification = styled.div`
    .notification-count-dropdown {
        border-radius: 50px;
        background-color: #fb6056;
        position: absolute;
        top: 5px;
        right: 7px;
        transform: translate(50%, -50%);
        padding: 0px 9px;
        font-size: 0.8rem;
    }

    .menu-item,
    .menu-open-button {
        background: #5648fb;
        border-radius: 100%;
        width: 37px;
        height: 37px;
        // margin-left: -40px;
        position: absolute;
        right: 2px;
        color: #ffffff;
        text-align: center;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-transition: -webkit-transform ease-out 200ms;
        transition: -webkit-transform ease-out 200ms;
        transition: transform ease-out 200ms;
        transition: transform ease-out 200ms, -webkit-transform ease-out 200ms;
        border: 2px solid transparent;

        &:hover {
            border: 2px solid #aba3ff;
        }
    }

    .menu-open {
        display: none;
    }

    .lines {
        width: 37px;
        height: 37px;
        /* background: #596778; */
        /* display: block; */
        /* position: absolute; */
        /* top: 50%;
        left: 50%; */
        /* margin-left: -12.5px;
        margin-top: -1.5px; */
        top: 17px;
        right: 0;
        left: -2px;
        position: absolute;
        -webkit-transition: -webkit-transform 200ms;
        transition: -webkit-transform 200ms;
        transition: transform 200ms;
        transition: transform 200ms, -webkit-transform 200ms;
    }

    .line-2 {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .menu-open:checked + .menu-open-button .line-2 {
        /* -webkit-transform: scale(0.1, 1);
        transform: scale(0.1, 1); */
    }

    .menu {
        // margin: auto;
        // position: absolute;
        /* top: 0;
        bottom: 0;
        left: 0;
        right: 0; */
        width: 40px;
        height: 40px;
        text-align: center;
        box-sizing: border-box;
        font-size: 26px;
    }

    .menu-item {
        transition: all 0.1s ease 0s;
    }

    .menu-item:hover {
        background: #5648fb;
        border: 2px solid #aba3ff;
    }

    .menu-item:nth-child(3) {
        -webkit-transition-duration: 180ms;
        transition-duration: 180ms;
    }

    .menu-item:nth-child(4) {
        -webkit-transition-duration: 180ms;
        transition-duration: 180ms;
    }

    .menu-open-button {
        z-index: 2;
        -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        -webkit-transition-duration: 400ms;
        transition-duration: 400ms;
        -webkit-transform: scale(1.1, 1.1) translate3d(0, 0, 0);
        transform: scale(1.1, 1.1) translate3d(0, 0, 0);
        cursor: pointer;
        /* box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.14); */
    }

    .menu-open:checked ~ .menu-item:nth-child(3) {
        transition-duration: 180ms;
        -webkit-transition-duration: 180ms;
        -webkit-transform: translate3d(0.08361px, -104.99997px, 0);
        transform: translate3d(-50.9466px, -20.47586px, 0);
    }

    .menu-open:checked ~ .menu-item:nth-child(4) {
        transition-duration: 280ms;
        -webkit-transition-duration: 280ms;
        -webkit-transform: translate3d(90.9466px, -52.47586px, 0);
        transform: translate3d(-50.9466px, 20.476px, 0);
    }

    .btn-purple {
        background-color: #5648fb;
        border: 2px solid transparent;
        background-color: #5648fb;
    }

    .btn-purple:hover {
        border: 2px solid #aba3ff;
    }

    .credit {
        margin: 24px 20px 120px 0;
        text-align: right;
        color: #eeeeee;
    }

    .credit a {
        padding: 8px 0;
        color: #c49cde;
        text-decoration: none;
        transition: all 0.3s ease 0s;
    }

    .credit a:hover {
        text-decoration: underline;
    }
`;

const ButtonChat = styled.button`
    padding: 0.5rem;
    border-radius: 50px;
    width: 2.5rem;
`;

const VerticalText = styled.span`
    writing-mode: vertical-lr;
    text-orientation: mixed;
    font-size: 0.75rem;
    font-weight: 500;
    margin-top: 0.5rem;
`;

const MessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const ContentWrapper = styled.div`
    position: sticky;
    z-index: 1;
    top: 5%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1rem;

    @media (max-width: 991px) {
        display: none;
    }
`;

const NotificationButton = styled.button`
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: #5648fb;
    border: 2px solid transparent;

    &:hover {
        border: 2px solid #aba3ff;
    }

    .notification-count {
        border-radius: 50px;
        background-color: #fb6056;
        position: absolute;
        top: 60px;
        right: 7px;
        transform: translate(50%, -50%);
        padding: 0px 9px;
    }

    .count {
        color: #fff;
        font-size: 0.8rem;
    }
`;

const ReportButton = styled.button`
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: #5648fb;
    border: 2px solid transparent;
    margin-bottom: 1rem;

    &:hover {
        border: 2px solid #aba3ff;
    }
`;

const BellIcon = styled.img`
    object-fit: contain;
    width: 18px;
    height: 18px;
    position: absolute;
    transform: translate(-50%, -50%);
`;

const CircleOnline = styled.span<{ online: boolean }>`
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background-color: ${({ online }) => (online ? '#06bd80' : '#fb6056;')};
    display: inline-block;
`;

const MessageIcon = styled.img`
    width: 18px;
    height: 18px;
`;
const ProfileIcon = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50px;
    margin-top: 1rem;
    border: 2px solid #fff;
    object-fit: cover;
    cursor: pointer;

    &:hover {
        border: 2px solid #5648fb;
    }
`;

const GroupPicture = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: #5648fb;
    margin-top: 1rem;
    cursor: pointer;
    border: 2px solid #fff;

    &:hover {
        border: 2px solid #5648fb;
    }

    .initial-name {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
    }
`;
