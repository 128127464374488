export const ALL_COMPANY_REQUEST = 'ALL_COMPANY_REQUEST';
export const ALL_COMPANY_SUCCESS = 'ALL_COMPANY_SUCCESS';
export const ALL_COMPANY_FAIL = 'ALL_COMPANY_FAIL';

export const ALL_COMPANY_MAP_REQUEST = 'ALL_COMPANY_MAP_REQUEST';
export const ALL_COMPANY_MAP_SUCCESS = 'ALL_COMPANY_MAP_SUCCESS';
export const ALL_COMPANY_MAP_FAIL = 'ALL_COMPANY_MAP_FAIL';

export const DETAIL_COMPANY_REQUEST = 'DETAIL_COMPANY_REQUEST';
export const DETAIL_COMPANY_SUCCESS = 'DETAIL_COMPANY_SUCCESS';
export const DETAIL_COMPANY_FAIL = 'DETAIL_COMPANY_FAIL';
export const DETAIL_COMPANY_RESET = 'DETAIL_COMPANY_RESET';

export const CLEAR_STATE = 'CLEAR_STATE';

const initialState = {
    loading: false,
    response: null,
    error: null,
    status: null,
};

export const allCompanyReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_COMPANY_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_COMPANY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const allCompanyMapReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_COMPANY_MAP_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_COMPANY_MAP_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_COMPANY_MAP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const detailCompanyReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DETAIL_COMPANY_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DETAIL_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DETAIL_COMPANY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case DETAIL_COMPANY_RESET:
            return initialState;

        default:
            return state;
    }
};
