import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import ErrorIcons from '@/assets/svg/errors/401.svg';

export default function Error401(): JSX.Element {
    /**
     * Function for handle back to login page
     *
     * @returns {void}
     */
    const handleBack = (): void => {
        window.location.href = '/';
    };
    return (
        <main className="container-fluid container-xs container-lg">
            <div className="d-flex align-items-center justify-content-center vh-100">
                <div className="row">
                    <div className="col-md-6 p-5">
                        <div className="mt-5">
                            <h1 className="fw-bold mb-5">Constructapp</h1>
                            <h2 className="fw-semibold h6 mb-3">
                                <span className="text-primary">Error 401. </span>
                                Unauthorized
                            </h2>
                            <p className="">
                                Your authorization failed, please try refreshing the page
                                and fill in the correct login details.
                            </p>
                            <ButtonBasic
                                type="button"
                                text="Back to Login Page"
                                className="my-3 w-30 btn-primary"
                                onClick={handleBack}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 text-center">
                        <img src={ErrorIcons} alt="404" className="img-fluid" />
                    </div>
                </div>
            </div>
        </main>
    );
}
