import CreatableSelect from 'react-select/creatable';
import Select, { CSSObjectWithLabel, InputActionMeta } from 'react-select';

import styled from 'styled-components';

/**
 * Input search select component boilerplate.
 *
 * @param props
 * @return
 */
export default function InputSearchSelect(props: {
    options: object[];
    isMulti: boolean;
    onChange?: (value: any, actionMeta?: any) => void;
    outerClassName?: string;
    innerClassName?: string;
    placeholder?: string;
    defaultValue?: any;
    withCreatable?: boolean;
    disabled?: boolean;
    placeholderCreatable?: string;
    value?: any;
    styles?: any;
    isClearable?: boolean;
    onBlur?: (value: any) => void;
    onMenuClose?: () => void;
    id?: string;
    loading?: boolean;
    onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
    onCreateOption?: (inputValue: string) => void;
}): JSX.Element {
    const {
        options,
        onChange,
        outerClassName,
        innerClassName,
        placeholder,
        isMulti,
        withCreatable,
        defaultValue,
        disabled,
        placeholderCreatable,
        value,
        styles,
        isClearable,
        id,
        onBlur,
        onMenuClose,
        loading,
        onInputChange,
        onCreateOption,
    } = props;

    /**
     * Basic styles for the select component.
     *
     * @param baseStyles - Base styles for the select component.
     * @param state
     */
    const basicStyles = {
        control: (
            baseStyles: CSSObjectWithLabel,
            state: { isFocused: boolean; isDisabled: boolean; isHover: boolean },
        ) => ({
            ...baseStyles,
            boxShadow: 'none',
            border: 'none',
            borderBottom: state.isFocused ? '1px solid #cfcfcf' : '1px solid #cfcfcf',
            borderRadius: 0,
            backgroundColor: state?.isDisabled ? 'transparent' : 'transparent',
            minHeight: 55,
            // margin: '2px 2px 22px',
            padding: '10px 0',
        }),
        valueContainer: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            padding: '10px 0 0',
            fontSize: '1rem',
        }),
        option: (baseStyles: CSSObjectWithLabel, state: { isFocused: boolean }) => ({
            ...baseStyles,
            backgroundColor: state.isFocused ? '#5648fb' : 'white',
            color: state.isFocused ? 'white' : 'black',
            fontSize: '14px',
        }),
        placeholder: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            fontSize: '1rem',
        }),
        singleValue: (
            baseStyles: CSSObjectWithLabel,
            state: { isDisabled: boolean; isFocused: boolean },
        ) => ({
            ...baseStyles,
            color: state.isDisabled && '#cfcfcf',
        }),
        menuPortal: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            zIndex: 9999,
        }),
        menu: (baseStyles: CSSObjectWithLabel) => ({
            ...baseStyles,
            zIndex: 9999,
        }),
    };

    return (
        <InputWrapper className={outerClassName}>
            {withCreatable ?? false ? (
                <div className="d-flex">
                    <CreatableSelect
                        id={id}
                        isMulti={isMulti}
                        options={options}
                        onChange={onChange}
                        className={`${innerClassName as string} w-100`}
                        placeholder={placeholder}
                        defaultValue={defaultValue}
                        styles={styles ?? basicStyles}
                        value={value}
                        isDisabled={disabled}
                        formatCreateLabel={(inputValue: string) =>
                            `${placeholderCreatable ?? 'Create'} "${inputValue}"`
                        }
                        onMenuClose={onMenuClose}
                        onInputChange={onInputChange}
                        isClearable={isClearable}
                        onBlur={onBlur}
                        onCreateOption={onCreateOption}
                        menuPortalTarget={document.body}
                    />
                    {(loading ?? false) && (
                        <div
                            className="spinner-border spinner-border-sm position-absolute text-primary"
                            role="status"
                            style={{
                                right: 75,
                                top: 65,
                            }}
                        />
                    )}
                </div>
            ) : (
                <Select
                    id={id}
                    isMulti={isMulti}
                    options={options}
                    onChange={onChange}
                    className={innerClassName}
                    placeholder={placeholder}
                    styles={styles ?? basicStyles}
                    defaultValue={defaultValue}
                    isDisabled={disabled}
                    value={value}
                    onBlur={onBlur}
                    menuPortalTarget={document.body}
                    isClearable={isClearable}
                    isLoading={loading}
                />
            )}
        </InputWrapper>
    );
}

const InputWrapper = styled.div`
    text-align: left;

    .css-art2ul-ValueContainer2 {
        padding: 0;
    }

    .css-1u9des2-indicatorSeparator {
        display: none;
    }
`;
