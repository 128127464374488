import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import Card from '@/components/molecules/Card';
import InputFile from '@/components/atoms/Inputs/InputFile';
import InputBasic from '@/components/atoms/Inputs/InputBasic';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import InputArea from '@/components/atoms/Inputs/InputArea';
import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';

import {
    updateCompanyPicture,
    clearUpdateCompany,
    updateCompany,
    getCompanySetting,
    getAllPendingInhouse,
    getActiveInhouse,
    getInactiveInhouse,
} from '../redux/actions';

import { RootState } from '@/redux/store';
import {
    getNiche,
    getSubContractor,
} from '@/routes/Authentication/Register/redux/actions';

import { v4 } from 'uuid';
import MiniMaps from '@/components/organism/MapBox/MiniMaps';

interface DataProps {
    id: number;
    name: string;
    trades: DataProps[];
}

export default function CompanyInformationSetting(): JSX.Element {
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const pictureState = useSelector((state: RootState) => state.updateCompanyPicture);
    const companyState = useSelector((state: RootState) => state.settingCompany);
    const updateCompanyState = useSelector((state: RootState) => state.updateCompany);
    const allNicheSubContractorState = useSelector(
        (state: RootState) => state.allNicheSubContractor,
    );

    const [nicheLists, setNicheList] = useState<DataProps[]>([]);
    const [subContractorLists, setSubContractorLists] = useState<DataProps[]>([]);
    const [isUpdatePicture, setIsUpdatePicture] = useState(false);
    const [modalType, setModalType] = useState('');
    const [modal, setModal] = useState({
        caution: false,
        success: false,
    });
    const [defaultMap, setDefaultMap] = useState({
        lat: 0,
        lng: 0,
        zoom: 5,
        address: '',
    });

    const nicheOther = nicheLists?.find((item: any) => item.name === 'Other');
    const subContractorOther = subContractorLists?.find(
        (item: any) => item.name === 'Other',
    );

    // get company setting in the first time
    useEffect(() => {
        dispatch(getCompanySetting());
        dispatch(getNiche());
        dispatch(getSubContractor());
    }, []);

    useEffect(() => {
        if (allNicheSubContractorState?.status === 200) {
            setNicheList(allNicheSubContractorState?.niche?.data);
            setSubContractorLists(allNicheSubContractorState?.subcontractor?.data);
        }
    }, [allNicheSubContractorState]);

    // handle modal, if update company picture and company information success
    useEffect(() => {
        if (pictureState.status === 200 || updateCompanyState.status === 200) {
            // If update company picture success, set isUpdatePicture to true

            if (pictureState.status === 200) {
                setModalType('company picture');
                setIsUpdatePicture(true);
            } else {
                setModalType('company information');
                setIsUpdatePicture(false);
            }

            setModal(prev => ({
                ...prev,
                caution: false,
            }));

            setTimeout(() => {
                setModal(prev => ({
                    ...prev,
                    success: true,
                }));
                dispatch(getCompanySetting());
                dispatch(clearUpdateCompany());

                const newParams = {
                    page: 1,
                    per_page: 10,
                    search: '',
                    sort_by: 'name',
                    sort_asc: 1,
                };

                dispatch(getActiveInhouse(newParams));
                dispatch(getInactiveInhouse(newParams));
                dispatch(
                    getAllPendingInhouse({
                        page: 1,
                        per_page: 10,
                        search: '',
                        sort_by: 'created_at',
                        sort_asc: 0,
                    }),
                );
            }, 1000);
        }
    }, [pictureState, updateCompanyState]);

    // set default value to the state
    useEffect(() => {
        if (companyState.status === 200) {
            const {
                company_name,
                company_address,
                company_website_url,
                company_niche,
                company_niche_trade,
                company_description,
                company_number_of_people,
                company_niche_other,
                company_sub_contract_trade,
                company_sub_contract_trade_other,
                company_email,
                latitude,
                longitude,
            } = companyState.response;

            if (!isUpdatePicture) {
                setValue('company_name', company_name);
                setValue('company_address', company_address);

                setValue('company_website_url', company_website_url);
                setValue('company_number_of_people', company_number_of_people);
                setValue('company_niche_id', company_niche?.id);
                setValue('company_niche_trade_id', company_niche_trade?.id);
                setValue('company_display_email', company_email);

                company_description !== 'null' &&
                    setValue('company_description', company_description);

                if (company_niche_other !== null) {
                    setValue('company_niche_id', nicheOther?.id);
                    setValue('company_niche_other', company_niche_other);
                }

                if (company_niche?.name === 'Sub Contractor') {
                    setValue(
                        'company_sub_contract_trade_id',
                        company_sub_contract_trade?.id,
                    );

                    if (company_sub_contract_trade_other !== null) {
                        setValue('company_sub_contract_trade_id', subContractorOther?.id);
                        setValue(
                            'company_sub_contract_trade_other',
                            company_sub_contract_trade_other,
                        );
                    }
                }
            }

            setDefaultMap((prev: any) => ({
                ...prev,
                lat: Number(latitude),
                lng: Number(longitude),
                address: company_address,
            }));
        }
    }, [companyState]);

    const peopleRangeList = [
        {
            id: 1,
            name: 'Only me',
        },
        {
            id: 2,
            name: '2 - 5',
        },
        {
            id: 3,
            name: '6 - 10',
        },
        {
            id: 4,
            name: '11 - 15',
        },
        {
            id: 5,
            name: '16 - 25',
        },
        {
            id: 6,
            name: '25 - 50',
        },
        {
            id: 7,
            name: '51 - 100',
        },
        {
            id: 8,
            name: '101 - 500',
        },
    ];

    /**
     * Function on submit
     *
     * @param {object} data
     * @return {void}
     * @see cypress/e2e/authentication/login/login.cy.ts
     *      To cypress unit tester
     */
    const onSubmit = (data: any): void => {
        // initialize form data
        const payload = new FormData();

        // append data to form data
        for (const key in data) {
            payload.append(key, data[key as keyof typeof data]);
        }

        // remove company_sub_contract_trade_id if the value is not 6(sub contractor)
        if (
            Number(data?.company_niche_id) !==
            nicheLists?.find(list => list.name === 'Sub Contractor')?.id
        ) {
            payload.delete('company_sub_contract_trade_id');
        }

        // remove company_niche_other and company_sub_contract_trade_other if the value is not other
        if (Number(data?.company_niche_id) === nicheOther?.id) {
            payload.delete('company_niche_id');
            payload.delete('company_sub_contract_trade_id');
        } else {
            payload.delete('company_niche_other');
        }

        // remove company_niche_other and company_sub_contract_trade_other if the value is not other
        if (Number(data?.company_sub_contract_trade_id) === subContractorOther?.id) {
            payload.delete('company_sub_contract_trade_id');
        } else {
            payload.delete('company_sub_contract_trade_other');
        }

        // remove company_website_url if the value is empty
        if (payload.get('company_website_url') === 'null') {
            payload.delete('company_website_url');
        }

        // remove niche trade if the is not architecture or engineering
        if (
            payload.get('company_niche_id') !== '1' &&
            payload.get('company_niche_id') !== '2'
        ) {
            payload.delete('company_niche_trade_id');
        }

        payload.append('company_address', defaultMap.address);
        payload.append('company_address_latitude', defaultMap.lat.toString());
        payload.append('company_address_longitude', defaultMap.lng.toString());

        // Dispatch login action with form data as payload
        dispatch(updateCompany(payload));
    };

    // const companyPhones = watch('company_phone')?.toString();

    const SubTrades = (): JSX.Element | null => {
        const nicheId = Number(watch('company_niche_id'));
        const findNiche = nicheLists?.find(list => list.id === nicheId);

        if (findNiche !== null) {
            return (
                <div className="mb-2">
                    {findNiche?.trades?.map(list => (
                        <Form.Check
                            id={v4()}
                            key={list.id}
                            type="radio"
                            label={list.name}
                            className="mb-3"
                            value={list.id}
                            checked={
                                list.id === parseInt(watch('company_niche_trade_id'))
                            }
                            {...register('company_niche_trade_id', {
                                required: 'Niche trade is required',
                            })}
                        />
                    ))}
                    {errors.company_niche_trade_id != null && (
                        <p
                            className="invalid-feedback d-block text-start"
                            style={{ fontSize: '0.75rem' }}
                        >
                            {errors.company_niche_trade_id.message as string}
                        </p>
                    )}
                </div>
            );
        }

        return null;
    };

    return (
        <>
            <Card>
                <div className="row mb-5">
                    <div className="col-md-12">
                        <h1 className="card-title fw-semibold h6">Company Information</h1>
                    </div>
                </div>
                <form action="#" onSubmit={handleSubmit(onSubmit)} method="post">
                    <div className="row mb-3">
                        <div className="col-lg-12 mb-4">
                            <InputFile
                                file={
                                    companyState?.response?.company_picture ??
                                    pictureState?.response?.company_picture
                                }
                                action={updateCompanyPicture}
                                payload="company_picture"
                                loading={pictureState.loading}
                                name={companyState?.response?.company_name}
                            />
                        </div>
                        <div className="col-lg-4">
                            <InputBasic
                                id="companyName"
                                placeholder="Company Name"
                                type="text"
                                rules={{
                                    function: register,
                                    name: 'company_name',
                                    rules: {
                                        required: 'Company name is required',
                                    },
                                    errors,
                                }}
                            />
                        </div>
                        <div className="col-lg-4">
                            <InputBasic
                                id="websiteUrl"
                                placeholder="Website URL"
                                type="text"
                                rules={{
                                    function: register,
                                    name: 'company_website_url',
                                    rules: {
                                        pattern: {
                                            value: /^(http|https):\/\/[^ "]+$/,
                                            message:
                                                'Invalid URL, add http:// or https://',
                                        },
                                    },
                                    errors,
                                }}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-4">
                            <InputArea
                                id="companyDescription"
                                placeholder="Company Description"
                                rules={{
                                    function: register,
                                    name: 'company_description',
                                    rules: {
                                        maxLength: {
                                            value: 2000,
                                            message: 'Maximum length of 2000 characters',
                                        },
                                    },
                                    errors,
                                }}
                                value={watch('company_description')}
                            />
                        </div>
                        <div className="col-lg-4">
                            <InputBasic
                                id="workEmail"
                                placeholder="Company Display Email"
                                type="email"
                                rules={{
                                    function: register,
                                    name: 'company_display_email',
                                    rules: {
                                        pattern: {
                                            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                                            message: 'Email is not valid',
                                        },
                                    },
                                    errors,
                                }}
                            />
                        </div>
                    </div>
                    <div className="row mb-3 border-bottom">
                        <div className="col-lg-4 mb-5">
                            <label
                                id="companyPeopleRange"
                                className="form-label mb-3 text-muted"
                                style={{ color: '#838383' }}
                            >
                                Number of People in Company
                            </label>
                            {peopleRangeList.map(list => (
                                <Form.Check
                                    id={`companyId_${list.id}`}
                                    key={list.id}
                                    type="radio"
                                    label={list.name}
                                    className="mb-3"
                                    value={list.name}
                                    checked={
                                        list.name === watch('company_number_of_people')
                                    }
                                    {...register('company_number_of_people', {
                                        required: 'Please select number of people',
                                    })}
                                />
                            ))}
                            {errors.company_number_of_people != null && (
                                <p
                                    className="invalid-feedback d-block text-start"
                                    style={{ fontSize: '0.75rem' }}
                                >
                                    {errors.company_number_of_people.message as string}
                                </p>
                            )}
                        </div>
                        <div className="col-lg-4 mb-5">
                            <label
                                id="companyNiche"
                                className="form-label mb-3 text-muted"
                                style={{ color: '#838383' }}
                            >
                                Company Niche
                            </label>
                            {nicheLists?.map(list => (
                                <Form.Check
                                    id={v4()}
                                    key={list.id}
                                    type="radio"
                                    label={list.name}
                                    className="mb-3"
                                    value={list?.id}
                                    checked={
                                        list.id === parseInt(watch('company_niche_id'))
                                    }
                                    {...register('company_niche_id', {
                                        required: 'Niche is required',
                                    })}
                                />
                            ))}
                            {errors.company_niche_id != null && (
                                <p
                                    className="invalid-feedback d-block text-start"
                                    style={{ fontSize: '0.75rem' }}
                                >
                                    {errors.company_niche_id.message as string}
                                </p>
                            )}
                            {Number(watch('company_niche_id')) ===
                                nicheLists?.find(list => list.name === 'Other')?.id && (
                                <InputBasic
                                    id="nicheIdOther"
                                    type="text"
                                    placeholder="Enter Construction Company Niche"
                                    outerClassName="mb-2"
                                    rules={{
                                        function: register,
                                        name: 'company_niche_other',
                                        rules: {
                                            required: 'Please enter your niche',
                                        },
                                        errors,
                                    }}
                                />
                            )}
                        </div>
                        <div className="col-lg-4 mb-5">
                            <SubTrades />
                            {Number(watch('company_niche_id')) ===
                                nicheLists?.find(list => list.name === 'Sub Contractor')
                                    ?.id && (
                                <div className="mb-2">
                                    {subContractorLists?.map(list => (
                                        <Form.Check
                                            id={v4()}
                                            key={list.id}
                                            type="radio"
                                            label={list.name}
                                            className="mb-3"
                                            value={list.id}
                                            checked={
                                                list.id ===
                                                parseInt(
                                                    watch(
                                                        'company_sub_contract_trade_id',
                                                    ),
                                                )
                                            }
                                            {...register(
                                                'company_sub_contract_trade_id',
                                                {
                                                    required: 'Niche is required',
                                                },
                                            )}
                                        />
                                    ))}
                                    {errors.company_sub_contract_trade_id != null && (
                                        <p
                                            className="invalid-feedback d-block text-start"
                                            style={{ fontSize: '0.75rem' }}
                                        >
                                            {
                                                errors.company_sub_contract_trade_id
                                                    .message as string
                                            }
                                        </p>
                                    )}
                                    {Number(watch('company_sub_contract_trade_id')) ===
                                        subContractorLists?.find(
                                            list => list.name === 'Other',
                                        )?.id && (
                                        <InputBasic
                                            id="subContractTradeOther"
                                            type="text"
                                            placeholder="Enter Sub Contract Trade"
                                            outerClassName="mb-2"
                                            rules={{
                                                function: register,
                                                name: 'company_sub_contract_trade_other',
                                                rules: {
                                                    required:
                                                        'Please enter sub contractor trade',
                                                },
                                                errors,
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    {companyState.status === 200 && (
                        <MiniMaps defaultMap={defaultMap} setDefaultMap={setDefaultMap} />
                    )}

                    <div className="row mt-5 text-md-end">
                        <div className="col-md-12 ms-auto mb-3">
                            <ButtonBasic
                                text="Cancel"
                                type="button"
                                className="btn-outline-primary mx-1 my-1"
                                onClick={() => navigate('/')}
                            />
                            <ButtonBasic
                                id="btnSubmit"
                                type="button"
                                text="Save Changes"
                                className="btn-primary mx-1 my-1"
                                onClick={() =>
                                    errors.company_name == null &&
                                    errors.company_address == null &&
                                    setModal({ ...modal, caution: true })
                                }
                            />
                        </div>
                    </div>
                </form>
            </Card>
            <ModalDashboard
                modal={modal.caution}
                setModal={setModal}
                variant="info"
                type="caution"
                title="Save Changes?"
                body="Are you sure want to save this changes?"
                withCancel
                onConfirm={handleSubmit(onSubmit)}
                loading={updateCompanyState?.loading}
            />
            <ModalDashboard
                modal={modal.success}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body={`Successfully update ${modalType}`}
                onConfirm={() => {
                    setModal({ ...modal, success: false });
                }}
            />
        </>
    );
}
