/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Badge, Dropdown, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import CoverBackground from '@/assets/images/CoverProfile.png';
import UserQuestionIcon from '@/assets/svg/icons/user-question.svg';
import TrashIcon from '@/assets/svg/icons/trash.svg';

import { BsTelephone } from 'react-icons/bs';
import { FiChevronDown } from 'react-icons/fi';
import { HiOutlineMail, HiDotsVertical } from 'react-icons/hi';

import {
    WrapperRowRight,
    WrapperSelected,
    CoverSelected,
    ProfileSelected,
    ProjectList,
    ImageProject,
    CustomDropdown,
    CustomDropdownMenu,
    IconDropdown,
    CustomDropdownAccess,
    WrapperNotSelected,
    IconContent,
} from './styles';

import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';

import { ThreeDots } from 'react-loader-spinner';
import { currentProjectAccess, generateInitialImageUrl } from '@/utility/Utils';

import { v4 } from 'uuid';
import { useEffect, useState } from 'react';
import { RootState } from '@/redux/store';
import { useSelector } from 'react-redux';

export default function UserDetailView(props: {
    // projectAccessState: {
    //     schedule: {
    //         can_invite_schedule: boolean;
    //         construction: {
    //             can_collaborate_schedule: boolean;
    //             can_view_schedule: boolean;
    //             disable_option: number;
    //         };
    //         design: {
    //             can_collaborate_schedule: boolean;
    //             can_view_schedule: boolean;
    //             disable_option: number;
    //         };
    //     };
    //     summary: {
    //         collaborate: boolean;
    //         view: boolean;
    //         invite: boolean;
    //     };
    //     document: {
    //         collaborate: boolean;
    //         view: boolean;
    //         invite: boolean;
    //         post_publicly: boolean;
    //     };
    //     drawing: {
    //         collaborate: boolean;
    //         view: boolean;
    //         invite: boolean;
    //         post_publicly: boolean;
    //     };
    // } | null;
    projectAccessState: any;
    detailsState: any;
    moduleType: string;
    withListProject?: boolean;
    withProjectAccess?: boolean;
    handleProjectAccess?: (
        value: boolean,
        type: string,
        access: string,
        space?: string,
    ) => void;
    withAwardNullify?: boolean;
    handleAward?: () => void;
    handleNullify?: () => void;
    detailsLoading?: boolean;
    handleSavePermissions?: () => void;
    handleRemoveUserFromProject?: (projectDetail: any) => void;
}): JSX.Element {
    const {
        projectAccessState,
        detailsState,
        moduleType,
        withListProject,
        withProjectAccess,
        handleProjectAccess,
        withAwardNullify,
        handleAward,
        handleNullify,
        detailsLoading,
        handleSavePermissions,
        handleRemoveUserFromProject,
    } = props;

    const projectAccess = currentProjectAccess();
    const currentProfileProjectState = useSelector(
        (state: RootState) => state.currentProfileProject,
    );

    const updateMemberPermissionState = useSelector(
        (state: RootState) => state.updateMemberPermission,
    );
    const summaryAccess = projectAccess?.summary;

    const initialAccess = [
        {
            id: 'summary',
            title: 'Summary',
        },
        {
            id: 'schedule',
            title: 'Schedule',
        },
        {
            id: 'document',
            title: 'Document',
        },
        {
            id: 'drawing',
            title: 'Drawing',
        },
    ];
    const [access, setAccess] = useState<
        Array<{
            id: string;
            title: string;
        }>
    >(initialAccess);

    // Function for hidden if user not have access
    useEffect(() => {
        if (currentProfileProjectState.status === 200) {
            setAccess(initialAccess);

            const accessUserOnProject = currentProfileProjectState?.response?.access;

            if (
                accessUserOnProject.summary.collaborate === false &&
                accessUserOnProject.summary.view === false
            ) {
                setAccess((prevState: Array<{ id: string; title: string }>) =>
                    prevState.filter(item => item.id !== 'summary'),
                );
            }

            if (
                accessUserOnProject.schedule.collaborate === false &&
                accessUserOnProject.schedule.view === false
            ) {
                setAccess((prevState: Array<{ id: string; title: string }>) =>
                    prevState.filter(item => item.id !== 'schedule'),
                );
            }

            if (
                accessUserOnProject.drawing.collaborate === false &&
                accessUserOnProject.drawing.view === false
            ) {
                setAccess((prevState: Array<{ id: string; title: string }>) =>
                    prevState.filter(item => item.id !== 'drawing'),
                );
            }

            if (
                accessUserOnProject.document.collaborate === false &&
                accessUserOnProject.document.view === false
            ) {
                setAccess((prevState: Array<{ id: string; title: string }>) =>
                    prevState.filter(item => item.id !== 'document'),
                );
            }
        }
    }, [currentProfileProjectState]);

    return (
        <div className="col-lg-5 col-xl-4 order-md-2 px-0">
            <WrapperRowRight
                className={`row g-0 ${detailsState === null ? 'align-items-center' : ''}`}
            >
                {detailsLoading ?? false ? (
                    <WrapperSelected>
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color="#5648FB"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                visible={detailsLoading}
                            />
                        </div>
                    </WrapperSelected>
                ) : detailsState !== null ? (
                    <WrapperSelected>
                        <CoverSelected cover={CoverBackground}>
                            <ProfileSelected
                                src={
                                    detailsState?.avatar ??
                                    generateInitialImageUrl(detailsState?.name)
                                }
                            />
                        </CoverSelected>

                        <div className="row pt-5 px-4 mb-4">
                            <div className="col-lg-12">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h6 className="mb-0 fw-semibold">
                                            {detailsState?.name}
                                        </h6>
                                        <span
                                            className="text-muted"
                                            style={{
                                                fontSize: 14,
                                            }}
                                        >
                                            {detailsState?.job_title?.name}
                                        </span>
                                    </div>
                                    {(withAwardNullify ?? false) && (
                                        <div className="">
                                            {detailsState?.awarded !== false ? (
                                                <Badge bg="primary">Awarded</Badge>
                                            ) : (
                                                <Badge bg="danger">Nullified</Badge>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row px-4 mb-4">
                            <div className="col-lg-12">
                                <h6 className="mb-0 fw-semibold" style={{ fontSize: 14 }}>
                                    Contact Info
                                </h6>
                            </div>
                            <div
                                className="col-lg-12 col-xxl-7"
                                style={{
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                <HiOutlineMail size={14} color="rgba(131, 131, 131, 1)" />
                                <span
                                    className="text-muted ms-1"
                                    style={{
                                        fontSize: 12,
                                    }}
                                >
                                    {detailsState?.email}
                                </span>
                            </div>
                            <div className="col-lg-12 col-xxl-5 text-xxl-end">
                                <BsTelephone size={14} color="rgba(131, 131, 131, 1)" />
                                <span
                                    className="text-muted ms-1"
                                    style={{
                                        fontSize: 12,
                                    }}
                                >
                                    {detailsState?.phone}
                                </span>
                            </div>
                        </div>
                        {(withListProject ?? false) && (
                            <ProjectList className="row px-4 mb-5">
                                <div
                                    className={`d-flex justify-content-between ${
                                        detailsState?.projects?.length !== 0
                                            ? ' mb-3'
                                            : 'mb-0'
                                    }`}
                                >
                                    <h6
                                        className="mb-0 fw-semibold"
                                        style={{
                                            fontSize: 16,
                                        }}
                                    >
                                        Project
                                    </h6>
                                    {/* <Link
                                        to="#"
                                        className="fw-semibold"
                                        style={{
                                            fontSize: 14,
                                        }}
                                    >
                                        +Add to Project
                                    </Link> */}
                                </div>
                                {detailsState?.projects?.length !== 0 ? (
                                    detailsState?.projects?.map((item: any) => (
                                        <div
                                            key={item.id}
                                            className="d-flex justify-content-between align-items-center mb-3"
                                        >
                                            <div>
                                                <ImageProject src={item?.picture} />
                                                <span
                                                    style={{
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    {item?.name}
                                                </span>
                                            </div>
                                            <Dropdown className="d-inline mx-2">
                                                <CustomDropdown
                                                    variant="none"
                                                    id="dropdown-autoclose-true"
                                                >
                                                    <HiDotsVertical
                                                        size={14}
                                                        color="#000"
                                                    />
                                                </CustomDropdown>

                                                <CustomDropdownMenu>
                                                    <Dropdown.Item
                                                        href="#"
                                                        style={{
                                                            fontSize: 14,
                                                            fontFamily: 'Montserrat',
                                                        }}
                                                        onClick={() =>
                                                            handleRemoveUserFromProject?.(
                                                                item,
                                                            )
                                                        }
                                                    >
                                                        <IconDropdown
                                                            className="icon-active"
                                                            icon={TrashIcon}
                                                            color="#FB6056"
                                                        />
                                                        Remove From this Project
                                                    </Dropdown.Item>
                                                </CustomDropdownMenu>
                                            </Dropdown>
                                        </div>
                                    ))
                                ) : (
                                    <div className="d-flex h-100">
                                        <span
                                            className="text-muted mb-0"
                                            style={{
                                                fontSize: 12,
                                            }}
                                        >
                                            This user is not assigned to any project
                                        </span>
                                    </div>
                                )}
                            </ProjectList>
                        )}

                        {(withProjectAccess ?? false) && (
                            <ProjectList className="row g-3 px-4 mb-5">
                                <h6
                                    className="fw-semibold"
                                    style={{
                                        fontSize: 16,
                                    }}
                                >
                                    Project Access
                                </h6>
                                {access.map((item, index) => (
                                    <div
                                        key={index}
                                        className="d-flex justify-content-between align-items-center p-0 ps-3"
                                    >
                                        <div
                                            style={{
                                                fontSize: 14,
                                            }}
                                        >
                                            <label className="form-check-label">
                                                {item.title}
                                            </label>
                                        </div>
                                        {/* TODO: */}
                                        {item.id !== 'schedule' ? (
                                            <Dropdown className="d-inline">
                                                <CustomDropdown
                                                    variant="none"
                                                    id="dropdown-autoclose-true"
                                                >
                                                    {projectAccessState?.[item.id]
                                                        ?.collaborate === true && (
                                                        <span
                                                            className="mb-0"
                                                            style={{
                                                                fontSize: 14,
                                                            }}
                                                        >
                                                            Collaborator
                                                        </span>
                                                    )}
                                                    {projectAccessState?.[item.id]
                                                        ?.view === true && (
                                                        <span
                                                            className="mb-0"
                                                            style={{
                                                                fontSize: 14,
                                                            }}
                                                        >
                                                            View Only
                                                        </span>
                                                    )}
                                                    {projectAccessState?.[item.id]
                                                        ?.collaborate === false &&
                                                        projectAccessState?.[item.id]
                                                            ?.view === false && (
                                                            <span
                                                                className="mb-0"
                                                                style={{
                                                                    fontSize: 14,
                                                                }}
                                                            >
                                                                No Access
                                                            </span>
                                                        )}
                                                    <FiChevronDown size={20} />
                                                </CustomDropdown>
                                                <CustomDropdownAccess>
                                                    <div className="">
                                                        <div className="d-flex justify-content-between align-items-center px-3 py-1">
                                                            {currentProfileProjectState
                                                                ?.response?.access?.[
                                                                item.id
                                                            ]?.collaborate === true && (
                                                                <Form.Check
                                                                    type="radio"
                                                                    label="Collaborator"
                                                                    id={v4()}
                                                                    className="my-1"
                                                                    style={{
                                                                        fontSize: 14,
                                                                    }}
                                                                    checked={
                                                                        projectAccessState?.[
                                                                            item.id
                                                                        ]?.collaborate
                                                                    }
                                                                    onChange={e =>
                                                                        handleProjectAccess?.(
                                                                            e.target
                                                                                .checked,
                                                                            item.id,
                                                                            'collaborate',
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                            <Form.Check
                                                                type="radio"
                                                                label="View Only"
                                                                id={v4()}
                                                                className="my-1"
                                                                style={{
                                                                    fontSize: 14,
                                                                }}
                                                                checked={
                                                                    projectAccessState?.[
                                                                        item.id
                                                                    ]?.view
                                                                }
                                                                onChange={e =>
                                                                    handleProjectAccess?.(
                                                                        e.target.checked,
                                                                        item.id,
                                                                        'view',
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <hr className="m-0" />
                                                        <div className="d-flex flex-column px-3 py-1">
                                                            {projectAccessState?.[item.id]
                                                                ?.post_publicly !==
                                                                undefined &&
                                                                currentProfileProjectState
                                                                    ?.response?.access?.[
                                                                    item.id
                                                                ]?.post_publicly ===
                                                                    true && (
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        label="Post Publicly"
                                                                        id={v4()}
                                                                        className="mt-2"
                                                                        style={{
                                                                            fontSize: 14,
                                                                        }}
                                                                        checked={
                                                                            projectAccessState?.[
                                                                                item.id
                                                                            ]
                                                                                ?.post_publicly
                                                                        }
                                                                        onChange={e =>
                                                                            handleProjectAccess?.(
                                                                                e.target
                                                                                    .checked,
                                                                                item.id,
                                                                                'post_publicly',
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                            {currentProfileProjectState
                                                                ?.response?.access?.[
                                                                item.id
                                                            ]?.invite === true && (
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    label="Ability to invite users"
                                                                    id={v4()}
                                                                    className="mt-2"
                                                                    style={{
                                                                        fontSize: 14,
                                                                    }}
                                                                    checked={
                                                                        projectAccessState?.[
                                                                            item.id
                                                                        ]?.invite
                                                                    }
                                                                    onChange={e =>
                                                                        handleProjectAccess?.(
                                                                            e.target
                                                                                .checked,
                                                                            item.id,
                                                                            'invite',
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                            {item.title !== 'Summary' && (
                                                                <Link
                                                                    to="#"
                                                                    className="text-danger text-end ms-3"
                                                                    style={{
                                                                        fontSize: 12,
                                                                    }}
                                                                    onClick={() =>
                                                                        handleProjectAccess?.(
                                                                            false,
                                                                            item.id,
                                                                            'remove',
                                                                        )
                                                                    }
                                                                >
                                                                    Revoke Access
                                                                </Link>
                                                            )}
                                                        </div>
                                                    </div>
                                                </CustomDropdownAccess>
                                            </Dropdown>
                                        ) : (
                                            <Dropdown className="d-inline">
                                                <CustomDropdown
                                                    variant="none"
                                                    id="dropdown-autoclose-true"
                                                >
                                                    {(projectAccessState?.[item.id]
                                                        ?.construction
                                                        ?.can_collaborate_schedule ??
                                                        false) ||
                                                    (projectAccessState?.[item.id]
                                                        ?.construction
                                                        ?.can_view_schedule ??
                                                        false) ? (
                                                        <span
                                                            className="mb-0"
                                                            style={{
                                                                fontSize: 14,
                                                            }}
                                                        >
                                                            Construction
                                                        </span>
                                                    ) : null}{' '}
                                                    {(projectAccessState?.[item.id]
                                                        ?.design
                                                        ?.can_collaborate_schedule ??
                                                        false) ||
                                                    (projectAccessState?.[item.id]?.design
                                                        ?.can_view_schedule ??
                                                        false) ? (
                                                        <span
                                                            className="mb-0"
                                                            style={{
                                                                fontSize: 14,
                                                            }}
                                                        >
                                                            Design
                                                        </span>
                                                    ) : null}
                                                    {!(
                                                        projectAccessState?.[item.id]
                                                            ?.construction
                                                            ?.can_collaborate_schedule ??
                                                        false
                                                    ) &&
                                                        !(
                                                            projectAccessState?.[item.id]
                                                                ?.construction
                                                                ?.can_view_schedule ??
                                                            false
                                                        ) &&
                                                        !(
                                                            projectAccessState?.[item.id]
                                                                ?.design
                                                                ?.can_collaborate_schedule ??
                                                            false
                                                        ) &&
                                                        !(
                                                            projectAccessState?.[item.id]
                                                                ?.design
                                                                ?.can_view_schedule ??
                                                            false
                                                        ) && (
                                                            <span
                                                                className="mb-0"
                                                                style={{
                                                                    fontSize: 14,
                                                                }}
                                                            >
                                                                No Access
                                                            </span>
                                                        )}
                                                    <FiChevronDown size={20} />
                                                </CustomDropdown>
                                                <CustomDropdownAccess
                                                    style={{
                                                        width: '25rem',
                                                    }}
                                                >
                                                    <div className="w-100">
                                                        {(currentProfileProjectState
                                                            ?.response?.access?.[item.id]
                                                            ?.design
                                                            ?.can_collaborate_schedule ??
                                                            false) ||
                                                        (currentProfileProjectState
                                                            ?.response?.access?.[item.id]
                                                            ?.design?.can_view_schedule ??
                                                            false) ? (
                                                            <>
                                                                <small className="px-3 pt-2 pb-0 mb-0">
                                                                    Design
                                                                </small>
                                                                <div className="d-flex justify-content-between align-items-center px-3">
                                                                    {currentProfileProjectState
                                                                        ?.response
                                                                        ?.access?.[
                                                                        item.id
                                                                    ]?.design
                                                                        ?.can_collaborate_schedule ===
                                                                        true && (
                                                                        <Form.Check
                                                                            type="radio"
                                                                            label="Collaborator"
                                                                            id={v4()}
                                                                            className="my-1"
                                                                            style={{
                                                                                fontSize: 14,
                                                                            }}
                                                                            checked={
                                                                                projectAccessState?.[
                                                                                    item
                                                                                        .id
                                                                                ]?.design
                                                                                    ?.can_collaborate_schedule
                                                                            }
                                                                            onChange={e =>
                                                                                handleProjectAccess?.(
                                                                                    e
                                                                                        .target
                                                                                        .checked,
                                                                                    item.id,
                                                                                    'can_collaborate_schedule',
                                                                                    'design',
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                projectAccessState?.[
                                                                                    item
                                                                                        .id
                                                                                ]?.design
                                                                                    ?.disable_option ===
                                                                                    0 ||
                                                                                projectAccessState?.[
                                                                                    item
                                                                                        .id
                                                                                ]?.design
                                                                                    ?.disable_option ===
                                                                                    1
                                                                            }
                                                                        />
                                                                    )}
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="View Only"
                                                                        id={v4()}
                                                                        className="my-1"
                                                                        style={{
                                                                            fontSize: 14,
                                                                        }}
                                                                        checked={
                                                                            projectAccessState?.[
                                                                                item.id
                                                                            ]?.design
                                                                                ?.can_view_schedule
                                                                        }
                                                                        onChange={e =>
                                                                            handleProjectAccess?.(
                                                                                e.target
                                                                                    .checked,
                                                                                item.id,
                                                                                'can_view_schedule',
                                                                                'design',
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            projectAccessState?.[
                                                                                item.id
                                                                            ]?.design
                                                                                ?.disable_option ===
                                                                            0
                                                                        }
                                                                    />
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="No Access"
                                                                        id={v4()}
                                                                        className="my-1"
                                                                        style={{
                                                                            fontSize: 14,
                                                                        }}
                                                                        checked={
                                                                            !(
                                                                                projectAccessState?.[
                                                                                    item
                                                                                        .id
                                                                                ]?.design
                                                                                    ?.can_collaborate_schedule ??
                                                                                false
                                                                            ) &&
                                                                            !(
                                                                                projectAccessState?.[
                                                                                    item
                                                                                        .id
                                                                                ]?.design
                                                                                    ?.can_view_schedule ??
                                                                                false
                                                                            )
                                                                        }
                                                                        onChange={e =>
                                                                            handleProjectAccess?.(
                                                                                e.target
                                                                                    .checked,
                                                                                item.id,
                                                                                'noAccess',
                                                                                'design',
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            projectAccessState?.[
                                                                                item.id
                                                                            ]?.design
                                                                                ?.no_access_disabled
                                                                        }
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : null}

                                                        <hr className="m-0" />

                                                        {(currentProfileProjectState
                                                            ?.response?.access?.[item.id]
                                                            ?.construction
                                                            ?.can_collaborate_schedule ??
                                                            false) ||
                                                        (currentProfileProjectState
                                                            ?.response?.access?.[item.id]
                                                            ?.construction
                                                            ?.can_view_schedule ??
                                                            false) ? (
                                                            <>
                                                                <small className="px-3 pt-2 pb-0 mb-0">
                                                                    Construction
                                                                </small>
                                                                <div className="d-flex justify-content-between align-items-center px-3">
                                                                    {currentProfileProjectState
                                                                        ?.response
                                                                        ?.access?.[
                                                                        item.id
                                                                    ]?.construction
                                                                        ?.can_collaborate_schedule ===
                                                                        true && (
                                                                        <Form.Check
                                                                            type="radio"
                                                                            label="Collaborator"
                                                                            id={v4()}
                                                                            className="my-1"
                                                                            style={{
                                                                                fontSize: 14,
                                                                            }}
                                                                            checked={
                                                                                projectAccessState?.[
                                                                                    item
                                                                                        .id
                                                                                ]
                                                                                    ?.construction
                                                                                    ?.can_collaborate_schedule
                                                                            }
                                                                            onChange={e =>
                                                                                handleProjectAccess?.(
                                                                                    e
                                                                                        .target
                                                                                        .checked,
                                                                                    item.id,
                                                                                    'can_collaborate_schedule',
                                                                                    'construction',
                                                                                )
                                                                            }
                                                                            disabled={
                                                                                projectAccessState?.[
                                                                                    item
                                                                                        .id
                                                                                ]
                                                                                    ?.construction
                                                                                    ?.disable_option ===
                                                                                    0 ||
                                                                                projectAccessState?.[
                                                                                    item
                                                                                        .id
                                                                                ]
                                                                                    ?.construction
                                                                                    ?.disable_option ===
                                                                                    1
                                                                            }
                                                                        />
                                                                    )}
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="View Only"
                                                                        id={v4()}
                                                                        className="my-1"
                                                                        style={{
                                                                            fontSize: 14,
                                                                        }}
                                                                        checked={
                                                                            projectAccessState?.[
                                                                                item.id
                                                                            ]
                                                                                ?.construction
                                                                                ?.can_view_schedule
                                                                        }
                                                                        onChange={e =>
                                                                            handleProjectAccess?.(
                                                                                e.target
                                                                                    .checked,
                                                                                item.id,
                                                                                'can_view_schedule',
                                                                                'construction',
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            projectAccessState?.[
                                                                                item.id
                                                                            ]
                                                                                ?.construction
                                                                                ?.disable_option ===
                                                                            0
                                                                        }
                                                                    />

                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="No Access"
                                                                        id={v4()}
                                                                        className="my-1"
                                                                        style={{
                                                                            fontSize: 14,
                                                                        }}
                                                                        checked={
                                                                            !(
                                                                                projectAccessState?.[
                                                                                    item
                                                                                        .id
                                                                                ]
                                                                                    ?.construction
                                                                                    ?.can_collaborate_schedule ??
                                                                                false
                                                                            ) &&
                                                                            !(
                                                                                projectAccessState?.[
                                                                                    item
                                                                                        .id
                                                                                ]
                                                                                    ?.construction
                                                                                    ?.can_view_schedule ??
                                                                                false
                                                                            )
                                                                        }
                                                                        onChange={e =>
                                                                            handleProjectAccess?.(
                                                                                e.target
                                                                                    .checked,
                                                                                item.id,
                                                                                'noAccess',
                                                                                'construction',
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            projectAccessState?.[
                                                                                item.id
                                                                            ]
                                                                                ?.construction
                                                                                ?.no_access_disabled
                                                                        }
                                                                    />
                                                                </div>
                                                                <hr className="m-0" />
                                                            </>
                                                        ) : null}

                                                        <div className="d-flex flex-column px-3">
                                                            {currentProfileProjectState
                                                                ?.response?.access?.[
                                                                item.id
                                                            ]?.can_invite_schedule ===
                                                                true && (
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    label="Ability to invite users"
                                                                    id={v4()}
                                                                    className="mt-2"
                                                                    style={{
                                                                        fontSize: 14,
                                                                    }}
                                                                    checked={
                                                                        projectAccessState?.[
                                                                            item.id
                                                                        ]
                                                                            ?.can_invite_schedule
                                                                    }
                                                                    onChange={e =>
                                                                        handleProjectAccess?.(
                                                                            e.target
                                                                                .checked,
                                                                            item.id,
                                                                            'can_invite_schedule',
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </CustomDropdownAccess>
                                            </Dropdown>
                                        )}
                                    </div>
                                ))}

                                {(withProjectAccess ?? false) &&
                                    summaryAccess?.collaborate && (
                                        <div className="col-12 text-end">
                                            <ButtonBasic
                                                text="Save Permissions"
                                                className="btn btn-outline-primary btn-sm"
                                                onClick={handleSavePermissions}
                                                style={{
                                                    minHeight: 32,
                                                    padding: '0 20px',
                                                    fontSize: 13,
                                                }}
                                                loading={
                                                    updateMemberPermissionState?.loading
                                                }
                                            />
                                        </div>
                                    )}
                                <div className="col-12 text-end">
                                    {(withAwardNullify ?? false) &&
                                        (detailsState?.awarded !== true ? (
                                            <ButtonBasic
                                                text="Award"
                                                onClick={handleAward}
                                                className="btn btn-primary btn-lg"
                                                style={{
                                                    minHeight: 32,
                                                    padding: '0 20px',
                                                    fontSize: 13,
                                                }}
                                            />
                                        ) : (
                                            <ButtonBasic
                                                text="Nullify"
                                                className="btn btn-danger btn-sm"
                                                onClick={handleNullify}
                                                style={{
                                                    minHeight: 32,
                                                    padding: '0 20px',
                                                    fontSize: 13,
                                                }}
                                            />
                                        ))}
                                </div>
                            </ProjectList>
                        )}
                    </WrapperSelected>
                ) : (
                    <WrapperNotSelected className="col-lg-6">
                        <IconContent className="icon-selected" icon={UserQuestionIcon} />
                        <p className="text-muted text-center text-selected mb-0">
                            No {moduleType.toLowerCase()} selected
                        </p>
                    </WrapperNotSelected>
                )}
            </WrapperRowRight>
        </div>
    );
}

// const WrapperPicture = styled.div`
//     border-radius: 50px;
//     background-color: #5648fb;
//     position: relative;
//     left: 4rem;
//     top: 4rem;
//     transform: translateX(-50%);
//     width: 5rem;
//     height: 5rem;
//     border-radius: 50%;
//     border: 5px solid #fff;
//     object-fit: cover;

//     .initial-name {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         height: 100%;
//         color: #fff;
//         font-size: 20px;
//         font-weight: 600;
//     }
// `;
