import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

import styled from 'styled-components';
import { AiOutlineInfoCircle } from 'react-icons/ai';

interface InputSwitcherProps {
    id?: string;
    label?: string;
    withTooltip?: string;
    checked?: boolean;
    onChange?: (val: boolean) => void;
}

/**
 * Input switcher component boilerplate.
 *
 * @param props
 * @constructor
 */
export default function InputSwitcher(props: InputSwitcherProps): JSX.Element {
    const { id, label, withTooltip, checked, onChange } = props;

    return (
        <>
            <InputWrapper className="d-flex align-items-center mb-1">
                <Label htmlFor="label" className="me-2">
                    {label}
                </Label>
                {withTooltip != null && (
                    <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="top-tooltip-2">{withTooltip}</Tooltip>}
                    >
                        {({ ref, ...triggerHandler }) => (
                            <>
                                <span ref={ref}>
                                    <AiOutlineInfoCircle
                                        color="#5648fb"
                                        size={18}
                                        {...triggerHandler}
                                    />
                                </span>
                            </>
                        )}
                    </OverlayTrigger>
                )}
            </InputWrapper>
            <CustomSwitcher
                onChange={onChange}
                checked={checked}
                type="switch"
                id={id}
                className="mb-5"
            />
        </>
    );
}

const CustomSwitcher = styled(Form.Check)`
    .form-check-input {
        height: 30px;
        width: 60px;
        box-shadow: none;
        background-color: rgba(196, 196, 196, 1);
        border: none;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");

        &:after {
            content: 'OFF';
            font-size: 9px;
            color: #fff;
            margin-left: 35px;
            font-weight: 600;
        }

        &:checked {
            background-color: #5648fb;
            border-color: #5648fb;
            &:after {
                content: 'ON';
                font-size: 9px;
                color: #fff;
                margin-left: 5px;
                font-weight: 600;
            }
        }

        &:focus {
            box-shadow: none;
            border-color: #5648fb;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
        }
    }

    .form-switch .form-check-input {
        background-size: 100% 100%;
    }
`;

const Label = styled.label`
    font-size: 14px;
    font-weight: 500;
`;

const InputWrapper = styled.div`
    .tooltip {
        opacity: 1;
    }
`;
