import { RootState } from '@/redux/store';
import { WrapperTabs } from './styles';
import { getAllProjectHistory } from '@/routes/ProjectDashboard/History/redux/actions';
import { getAllProjectNotificationFloat } from '@/routes/ProjectDashboard/Notification/redux/actions';
import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function NotificationHistoryBanner(): JSX.Element {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const floatProjectNotificationState = useSelector(
        (state: RootState) => state.floatProjectNotification,
    );

    const allProjectHistoryState = useSelector(
        (state: RootState) => state.allProjectHistory,
    );

    const [notificationData, setNotificationData] = useState<any[]>([]);
    const [historyData, setHistoryData] = useState<any[]>([]);

    const [historyParams, setHistoryParams] = useState({
        project_id: id,
        page: 1,
        per_page: 10,
        search: '',
        summary: 1,
        schedule: 1,
        drawing: 1,
        document: 1,
        invited_user: 1,
        inhouse_user: 1,
    });

    const [notificationParams, setNotificationParams] = useState({
        project_id: id,
        page: 1,
        per_page: 10,
        sort_asc: 0,
    });

    useEffect(() => {
        if (floatProjectNotificationState?.status === 200) {
            if (notificationParams.page > 1) {
                const newTableData = [
                    ...notificationData,
                    ...floatProjectNotificationState?.response?.data,
                ];
                const uniqueTableData = Array.from(
                    new Set(newTableData.map(item => item.id)),
                ).map(id => {
                    return newTableData.find(item => item.id === id);
                });
                return setNotificationData(uniqueTableData);
            }
            setNotificationData(floatProjectNotificationState?.response?.data);
        }
    }, [floatProjectNotificationState]);

    useEffect(() => {
        if (allProjectHistoryState?.status === 200) {
            if (historyParams.page > 1) {
                const newTableData = [
                    ...historyData,
                    ...allProjectHistoryState?.response?.data,
                ];
                const uniqueTableData = Array.from(
                    new Set(newTableData.map(item => item.id)),
                ).map(id => {
                    return newTableData.find(item => item.id === id);
                });
                return setHistoryData(uniqueTableData);
            }
            setHistoryData(allProjectHistoryState?.response?.data);
        }
    }, [allProjectHistoryState]);

    /**
     * Handle project notification
     *
     * @param number type
     * @returns JSX.Element
     */
    const handleProjectNotification = (data: {
        id: string;
        actions: Array<{
            name: string;
            method: string;
            accept: string;
            uri: string;
        }>;
    }): JSX.Element => {
        const name = data?.actions[0]?.name;
        const baseUrl = import.meta.env.VITE_FE_URL;
        const uri = data?.actions[0]?.uri?.replace(baseUrl, '');

        switch (name) {
            case 'Reply Now':
                return (
                    <ButtonBasic
                        className="btn btn-success text-white rounded-5 px-4 py-1"
                        text={'Reply'}
                        onClick={() => {
                            navigate(uri);
                        }}
                    />
                );
            case 'go to':
                return (
                    <ButtonBasic
                        className="btn btn-success text-white rounded-5 px-4 py-1"
                        text={'Go to'}
                        onClick={() => {
                            navigate(uri);
                        }}
                    />
                );
            default:
                return <div style={{ height: 31.5 }} />;
        }
    };

    /**
     * Handle pagination
     *
     * @param number page
     * @param string type
     * @returns void
     */
    const handlePagination = (page: number, type: boolean): void => {
        if (type) {
            setNotificationParams({
                ...notificationParams,
                page: notificationParams.page + 1,
            });

            const payload = {
                ...notificationParams,
                page: notificationParams.page + 1,
            };

            dispatch(getAllProjectNotificationFloat(payload));
        } else {
            setHistoryParams({
                ...historyParams,
                page: historyParams.page + 1,
            });

            const payload = {
                ...historyParams,
                page: historyParams.page + 1,
            };

            dispatch(getAllProjectHistory(payload));
        }
    };

    return (
        <WrapperTabs>
            <Tabs defaultActiveKey="notification" id="uncontrolled-tab-example" fill>
                {/* Notification */}
                <Tab eventKey="notification" title="Notification">
                    {notificationData.length > 0 ? (
                        <InfiniteScroll
                            next={() =>
                                handlePagination(notificationParams.page + 1, true)
                            }
                            hasMore={
                                floatProjectNotificationState?.response?.meta
                                    ?.last_page !== notificationParams.page
                            }
                            loader={<span></span>}
                            dataLength={notificationData.length}
                            scrollableTarget="scrollableNotification"
                        >
                            <div
                                className="text-center p-3"
                                style={{
                                    fontSize: 14,
                                }}
                            >
                                <Link
                                    to={`/project/${id as string}/notification`}
                                    className="text-primary text-decoration-none fw-semibold"
                                >
                                    see all notifications
                                </Link>
                            </div>
                            <ul id="scrollableNotification" className="notification-list">
                                {notificationData.map((list: any, index: number) => (
                                    <li
                                        key={index}
                                        className="py-4 mb-3 notification-items rounded-4"
                                    >
                                        <div className="d-flex justify-content-between px-3">
                                            <div className="d-flex justify-content-between flex-column me-2 notification-content">
                                                <h5 className="notification-title mb-0">
                                                    {list?.title}
                                                </h5>
                                                <p
                                                    className="notification-message mb-0"
                                                    dangerouslySetInnerHTML={{
                                                        __html: list?.description,
                                                    }}
                                                ></p>
                                            </div>
                                            <div className="d-flex flex-column text-end">
                                                <p className="text-muted fw-semibold mb-2">
                                                    {list?.diff_for_humans_created_at}
                                                </p>
                                                {handleProjectNotification(list)}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </InfiniteScroll>
                    ) : (
                        <ul className="notification-list">
                            <li className="text-muted mb-0 text-center p-3">
                                No notification found
                            </li>
                        </ul>
                    )}
                </Tab>

                {/* History */}
                <Tab eventKey="history" title="History">
                    {historyData?.length > 0 ? (
                        <InfiniteScroll
                            next={() => handlePagination(historyParams.page + 1, false)}
                            hasMore={
                                allProjectHistoryState?.response?.meta?.last_page !==
                                historyParams.page
                            }
                            loader={<span></span>}
                            dataLength={historyData.length}
                            scrollableTarget="scrollableHistory"
                        >
                            <div
                                className="text-center p-3"
                                style={{
                                    fontSize: 14,
                                }}
                            >
                                <Link
                                    to={`/project/${id as string}/history`}
                                    className="text-primary text-decoration-none fw-semibold"
                                >
                                    see all history
                                </Link>
                            </div>
                            <ul id="scrollableHistory" className="notification-list">
                                {historyData?.map((list: any, index: number) => (
                                    <li
                                        key={index}
                                        className="py-4 mb-3 notification-items rounded-4 "
                                    >
                                        <div className="d-flex justify-content-between px-3">
                                            <div className="d-flex justify-content-between flex-column me-2 notification-content">
                                                <h5
                                                    className="notification-title mb-0"
                                                    dangerouslySetInnerHTML={{
                                                        __html: list?.title,
                                                    }}
                                                ></h5>
                                                <p
                                                    className="notification-message mb-0"
                                                    dangerouslySetInnerHTML={{
                                                        __html: list?.description,
                                                    }}
                                                ></p>
                                            </div>
                                            <div className="d-flex flex-column text-end">
                                                <p className="text-muted fw-semibold mb-2">
                                                    {list?.diff_for_humans_created_at}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </InfiniteScroll>
                    ) : (
                        <ul className="notification-list">
                            <li className="text-muted mb-0 text-center p-3">
                                No history found
                            </li>
                        </ul>
                    )}
                </Tab>
            </Tabs>
        </WrapperTabs>
    );
}
