import { Stage, Layer, Image, Transformer } from 'react-konva';

export default function CompareImage({
    stageRef,
    image,
    imageRef,
    newImage,
    newImageRef,
    trRef,
    initialItem,
    compareItem,
    selectedItem,
    dimensions,
    handleSelectItem,
    handleDragEnd,
    handleTransformEnd,
    handleUnselectItem,
    lockItems,
}: {
    stageRef: any;
    image: any;
    imageRef: any;
    newImage: any;
    newImageRef: any;
    trRef: any;
    initialItem: any;
    compareItem: any;
    selectedItem: any;
    dimensions: any;
    handleSelectItem: any;
    handleDragEnd: any;
    handleTransformEnd: any;
    handleUnselectItem: any;
    lockItems: boolean;
}): JSX.Element {
    return (
        <Stage
            ref={stageRef}
            width={dimensions.width}
            height={dimensions.height}
            className="bg-white"
            // onClick={handleUnselectItem}
            onMouseDown={handleUnselectItem}
            onTouchStart={handleUnselectItem}
        >
            <Layer>
                <Image
                    alt="image"
                    id={initialItem?.uuid}
                    offset={initialItem?.offset}
                    scale={initialItem?.scale}
                    opacity={initialItem?.opacity}
                    image={image}
                    draggable={!lockItems}
                    ref={imageRef}
                    onDragStart={handleSelectItem}
                    onDragEnd={handleDragEnd}
                    onClick={handleSelectItem}
                    onTap={handleSelectItem}
                    onTransformEnd={handleTransformEnd}
                />
                {compareItem !== null && (
                    <Image
                        alt="image"
                        id={compareItem?.uuid}
                        offset={compareItem?.offset}
                        scale={compareItem?.scale}
                        opacity={compareItem?.opacity}
                        image={newImage}
                        draggable
                        ref={newImageRef}
                        onDragStart={handleSelectItem}
                        onDragEnd={handleDragEnd}
                        onClick={handleSelectItem}
                        onTap={handleSelectItem}
                        onTransformEnd={handleTransformEnd}
                    />
                )}

                {selectedItem !== '' && selectedItem === initialItem?.uuid && (
                    <Transformer
                        ref={trRef}
                        rotateEnabled={!lockItems}
                        resizeEnabled={!lockItems}
                        flipEnabled={!lockItems}
                        // keepRatio={true}
                        // enabledAnchors={[
                        //     'top-left',
                        //     'top-right',
                        //     'bottom-left',
                        //     'bottom-right',
                        // ]}
                        // boundBoxFunc={(oldBox, newBox) => {
                        //     // limit resize
                        //     if (
                        //         Math.abs(newBox.width) < 5 ||
                        //         Math.abs(newBox.height) < 5
                        //     ) {
                        //         return oldBox;
                        //     }
                        //     return newBox;
                        // }}
                    />
                )}
                {selectedItem !== '' && selectedItem === compareItem?.uuid && (
                    <Transformer
                        ref={trRef}
                        rotateEnabled={true}
                        resizeEnabled={true}
                        flipEnabled={true}
                        // keepRatio={true}
                        // enabledAnchors={[
                        //     'top-left',
                        //     'top-right',
                        //     'bottom-left',
                        //     'bottom-right',
                        // ]}
                        // boundBoxFunc={(oldBox, newBox) => {
                        //     // limit resize
                        //     if (
                        //         Math.abs(newBox.width) < 5 ||
                        //         Math.abs(newBox.height) < 5
                        //     ) {
                        //         return oldBox;
                        //     }
                        //     return newBox;
                        // }}
                    />
                )}
            </Layer>
        </Stage>
    );
}
