import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

interface ModalBasicProps {
    modal: boolean;
    setModal: (value: any) => void;
    title?: string;
    body?: string;
    children: JSX.Element;
    size?: 'sm' | 'lg' | 'xl';
    withClose?: boolean;
    backdrop?: boolean;
}

/**
 * Create basic modal component boilerplate.
 *
 * @param ModalBasicProps
 * @constructor
 */
export default function ModalBasic({
    modal,
    setModal,
    title,
    children,
    size,
    withClose,
    backdrop,
}: ModalBasicProps): JSX.Element {
    return backdrop === false ? (
        <ModalWrapper
            show={modal}
            onHide={() => setModal(false)}
            size={size ?? 'lg'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton={withClose}>
                <h5 className="fw-semibold text-center mt-4">{title}</h5>
            </Modal.Header>
            <Modal.Body className="mb-4">{children}</Modal.Body>
        </ModalWrapper>
    ) : (
        <ModalWrapper
            show={modal}
            onHide={() => setModal(false)}
            size={size ?? 'lg'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton={withClose}>
                <h5 className="fw-semibold text-center mt-4">{title}</h5>
            </Modal.Header>
            <Modal.Body className="mb-4">{children}</Modal.Body>
        </ModalWrapper>
    );
}

const ModalWrapper = styled(Modal)`
    .modal-content {
        font-family: 'Montserrat', sans-serif;
        padding: 0.5rem 1rem;
        border-radius: 10px;
    }

    .modal-header {
        border: none;
        justify-content: center;

        .btn-close {
            position: absolute;
            right: 40px;
            top: 35px;
            font-size: 10px;
        }
    }
    .modal-body {
        text-align: center;
        font-size: 13px;
        padding: 0;
    }
    .modal-footer {
        justify-content: center;
        border: none;
        flex-wrap: initial;
    }
`;
