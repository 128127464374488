import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import InputSearchSelect from '@/components/atoms/Inputs/InputSearchSelect';
import ModalAction from '@/components/atoms/Modals/ModalAction';
import { useEffect, useState } from 'react';
import {
    clearDeleteSharedUserPrivateDrawing,
    clearUpdateSharedUserPrivateDrawing,
    deleteSharedUserPrivateDrawing,
    getSharedUserPrivateDrawing,
    shareDrawingToEmail,
    updateSharedUserPrivateDrawing,
} from '../redux/actions';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllMemberInhouse,
    getAllMemberInvited,
} from '@/routes/Dashboard/Project/redux/actions';
import { RootState } from '@/redux/store';
import styled from 'styled-components';
import { Dropdown, Form, Spinner } from 'react-bootstrap';
import { FiChevronDown } from 'react-icons/fi';
import { generateInitialImageUrl } from '@/utility/Utils';

export default function ModalDrawingShare({
    modal,
    setModal,
    selected,
}: {
    modal: boolean;
    setModal: (modal: boolean) => void;
    selected: string[];
}): JSX.Element {
    const { id } = useParams();
    const dispatch = useDispatch();

    const projectId = id ?? '';

    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/g;

    const allMemberInhouseState = useSelector(
        (state: RootState) => state.allMemberInhouse,
    );
    const allMemberInvitedState = useSelector(
        (state: RootState) => state.allMemberInvited,
    );

    const shareDrawingState = useSelector((state: RootState) => state.shareDrawing);
    const sharedUserPrivateDrawingState = useSelector(
        (state: RootState) => state.sharedUserPrivateDrawing,
    );
    const updateUserPrivateDrawingState = useSelector(
        (state: RootState) => state.updateUserPrivateDrawing,
    );
    const deleteUserPrivateDrawingState = useSelector(
        (state: RootState) => state.deleteUserPrivateDrawing,
    );

    const [selectedUser, setSelectedUser] = useState<
        Array<{
            label: string;
            value: string;
            assignee_emails: string;
            assignee_can_collaborates: number;
            assignee_can_invites: number;
            assignee_can_post_publics: number;
        }>
    >([
        {
            label: 'Select or type email',
            value: 'Select user',
            assignee_emails: '',
            assignee_can_collaborates: 0,
            assignee_can_invites: 0,
            assignee_can_post_publics: 0,
        },
    ]);

    const [loadUsers, setLoadUsers] = useState<
        Array<{
            label: string;
            value: string;
            assignee_emails: string;
            assignee_can_collaborates: number;
            assignee_can_invites: number;
            assignee_can_post_publics: number;
        }>
    >([]);

    const [isValidate, setIsValidate] = useState<boolean>(false);
    const [userList, setUserList] = useState<
        Array<{
            value: string;
            label: string;
        }>
    >([]);

    useEffect(() => {
        if (modal) {
            dispatch(
                getAllMemberInvited({ project_id: projectId, page: 1, per_page: 9999 }),
            );
            dispatch(
                getAllMemberInhouse({ project_id: projectId, page: 1, per_page: 9999 }),
            );
        }

        if (!modal) {
            setSelectedUser([
                {
                    label: 'Select or type email',
                    value: 'Select user',
                    assignee_emails: '',
                    assignee_can_collaborates: 0,
                    assignee_can_invites: 0,
                    assignee_can_post_publics: 0,
                },
            ]);
            setIsValidate(false);
        }
    }, []);

    useEffect(() => {
        if (sharedUserPrivateDrawingState?.status === 200) {
            setLoadUsers(sharedUserPrivateDrawingState?.response);
        }
    }, [sharedUserPrivateDrawingState]);

    useEffect(() => {
        if (updateUserPrivateDrawingState?.status === 200) {
            setTimeout(() => {
                dispatch(clearUpdateSharedUserPrivateDrawing());
            }, 1000);
        }
    }, [updateUserPrivateDrawingState]);

    useEffect(() => {
        if (deleteUserPrivateDrawingState?.status === 200) {
            setTimeout(() => {
                dispatch(
                    getSharedUserPrivateDrawing({
                        project_id: projectId,
                        drawing_id: selected[0],
                    }),
                );
                dispatch(clearDeleteSharedUserPrivateDrawing());
            }, 1000);
        }
    }, [deleteUserPrivateDrawingState]);

    useEffect(() => {
        if (shareDrawingState?.status === 200) {
            setSelectedUser([
                {
                    label: 'Select or type email',
                    value: 'Select user',
                    assignee_emails: '',
                    assignee_can_collaborates: 0,
                    assignee_can_invites: 0,
                    assignee_can_post_publics: 0,
                },
            ]);
            setIsValidate(false);
        }
    }, [shareDrawingState]);

    useEffect(() => {
        if (allMemberInhouseState?.status === 200) {
            const data = allMemberInhouseState?.response?.data;
            const newData = data.map((item: any) => {
                return {
                    label: item.name,
                    value: item.email,
                };
            });

            setUserList((prevData: any) => {
                const mergedData = prevData.concat(newData);
                const filteredData = mergedData.filter(
                    (item: any, index: number, self: any) =>
                        index === self.findIndex((t: any) => t.value === item.value),
                );
                return filteredData;
            });
        }

        if (allMemberInvitedState?.status === 200) {
            const data = allMemberInvitedState?.response?.data;
            const newData = data.map((item: any) => {
                return {
                    label: item.name,
                    value: item.email,
                };
            });

            setUserList((prevData: any) => {
                const mergedData = prevData.concat(newData);
                const filteredData = mergedData.filter(
                    (item: any, index: number, self: any) =>
                        index === self.findIndex((t: any) => t.value === item.value),
                );
                return filteredData;
            });
        }
    }, [allMemberInhouseState, allMemberInvitedState]);

    // const handleChange = (val: Array<{ value: string; label: string }>): void => {
    //     setSelectedUser(val);
    // };

    // const handleBlur = (e: any): void => {
    //     const val = e.target.value;

    //     if (val !== '') {
    //         // Add validation email
    //         const isValid = regex.test(val);

    //         if (isValid) {
    //             setSelectedUser([...selectedUser, { label: val, value: val }]);

    //             return setIsValidate(false);
    //         }

    //         setIsValidate(true);
    //     }
    // };

    // const handleCreateOption = (val: string): void => {
    //     // Add validation email
    //     const isValid = regex.test(val);

    //     if (isValid) {
    //         setSelectedUser([...selectedUser, { label: val, value: val }]);

    //         return setIsValidate(false);
    //     }

    //     setIsValidate(true);
    // };

    const handleShareToMail = (): void => {
        const formData = new FormData();

        selected.forEach((item: any) => {
            formData.append('ids[]', item);
        });

        selectedUser.forEach((item: any) => {
            formData.append('assignee_emails[]', item.value);
            formData.append(
                'assignee_can_collaborates[]',
                item.assignee_can_collaborates,
            );
            formData.append('assignee_can_invites[]', item.assignee_can_collaborates);
            formData.append('assignee_can_post_publics[]', '0');
        });

        dispatch(
            shareDrawingToEmail(
                {
                    project_id: projectId,
                },
                formData,
            ),
        );
    };

    const handleUpdatePermission = (): void => {
        const formData = new FormData();

        selected.forEach((item: any) => {
            formData.append('ids[]', item);
        });

        loadUsers.forEach((item: any, index: number) => {
            formData.append(`assignee_emails[${index}]`, item.user_email);
            formData.append(
                `assignee_can_collaborates[${index}]`,
                item.can_collaborate_drawing,
            );
            formData.append(
                `assignee_can_invites[${index}]`,
                item.can_collaborate_drawing,
            );
            formData.append(`assignee_can_post_publics[${index}]`, '0');
        });

        dispatch(
            updateSharedUserPrivateDrawing(
                {
                    drawing_id: selected[0],
                    project_id: projectId,
                },
                formData,
            ),
        );
    };

    const handleRemoveUser = (email: string): void => {
        const formData = new FormData();

        formData.append('emails[]', email);

        dispatch(
            deleteSharedUserPrivateDrawing(
                {
                    project_id: projectId,
                    drawing_id: selected[0],
                },
                formData,
            ),
        );
    };

    const handleAccess = (value: boolean, type: string, index: number): void => {
        const newData = selectedUser.map((item: any, key: number) => {
            if (key === index) {
                if (type === 'collaborate') {
                    return {
                        ...item,
                        assignee_can_collaborates: value ? 1 : 0,
                    };
                } else if (type === 'view') {
                    return {
                        ...item,
                        assignee_can_collaborates: value ? 0 : 1,
                    };
                } else if (type === 'invite') {
                    return {
                        ...item,
                        assignee_can_invites: value ? 1 : 0,
                    };
                } else if (type === 'public') {
                    return {
                        ...item,
                        assignee_can_post_publics: value ? 1 : 0,
                    };
                }
            }
            return item;
        });

        setSelectedUser(newData);
    };

    const handleUpdateAccess = (value: boolean, type: string, index: number): void => {
        const newData = loadUsers.map((item: any, key: number) => {
            if (key === index) {
                if (type === 'collaborate') {
                    return {
                        ...item,
                        can_collaborate_drawing: value ? 1 : 0,
                    };
                } else if (type === 'view') {
                    return {
                        ...item,
                        can_collaborate_drawing: value ? 0 : 1,
                    };
                } else if (type === 'invite') {
                    return {
                        ...item,
                        can_invite_drawing: value ? 1 : 0,
                    };
                } else if (type === 'public') {
                    return {
                        ...item,
                        can_post_publicly_drawing: value ? 1 : 0,
                    };
                }
            }
            return item;
        });

        setLoadUsers(newData);
    };

    /**
     * Function to handle add users
     *
     * @returns void
     */
    const handleAddUser = (): void => {
        setSelectedUser((prevData: any) => {
            const newData = prevData.concat({
                label: 'Select or type email',
                value: '',
                assignee_emails: '',
                assignee_can_collaborates: 0,
                assignee_can_invites: 0,
                assignee_can_post_publics: 0,
            });
            return newData;
        });
    };

    /**
     * Function to handle delete user
     *
     * @param number index
     * @returns void
     */
    const handleDeleteUser = (index: number): void => {
        // delete user from index and refresh state
        setSelectedUser((prevData: any) => {
            const newData = prevData.filter((item: any, key: number) => key !== index);
            return newData;
        });

        // update user list with new data after deleting user
        setUserList((prevData: any) => {
            const newOptions = prevData.filter(
                (item: any) =>
                    selectedUser.findIndex(user => user.value === item.value) === -1,
            );
            return newOptions;
        });
    };

    return (
        <ModalAction
            modal={modal}
            setModal={setModal}
            aria-labelledby="contained-modal-title-vcenter"
            title={'Share Drawing'}
            size={'md'}
        >
            <div className="p-3 pb-0">
                <div className="d-flex justify-content-between">
                    <span
                        className="link-primary"
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={() => handleAddUser()}
                    >
                        + Add User
                    </span>
                    <small className="text-muted">{selected.length} items selected</small>
                </div>

                <div className="form-group mb-3">
                    {selectedUser.map((item: any, key: number) => (
                        <div
                            key={key}
                            className="d-flex align-items-center text-start mb-3"
                        >
                            <div
                                style={{
                                    width: '75%',
                                }}
                            >
                                <InputSearchSelect
                                    placeholder="Email"
                                    innerClassName="mb-1"
                                    outerClassName="mt-3"
                                    options={userList}
                                    isMulti={false}
                                    withCreatable
                                    placeholderCreatable="Invite"
                                    value={{ value: item.value, label: item.label }}
                                    onChange={(value: {
                                        value: string;
                                        label: string;
                                    }) => {
                                        setSelectedUser((prevData: any) => {
                                            const newData = prevData.map(
                                                (item: any, index: number) => {
                                                    if (index === key) {
                                                        return {
                                                            ...item,
                                                            label: value.label,
                                                            value: value.value,
                                                            assignee_emails: value.value,
                                                        };
                                                    }
                                                    return item;
                                                },
                                            );
                                            return newData;
                                        });
                                    }}
                                    onBlur={e => {
                                        const isValid = regex.test(e.target.value);
                                        if (e.target.value !== '' && isValid) {
                                            setSelectedUser((prevData: any) => {
                                                const newData = prevData.map(
                                                    (item: any, index: number) => {
                                                        if (index === key) {
                                                            return {
                                                                ...item,
                                                                label: e.target.value,
                                                                value: e.target.value,
                                                                assignee_emails:
                                                                    e.target.value,
                                                            };
                                                        }
                                                        return item;
                                                    },
                                                );
                                                return newData;
                                            });
                                        }
                                    }}
                                />
                                {selectedUser[0].value === '' && isValidate && (
                                    <span
                                        className="text-danger"
                                        style={{
                                            fontSize: 12,
                                        }}
                                    >
                                        Please select user at least one
                                    </span>
                                )}
                            </div>
                            <Dropdown className="mt-4 ms-2">
                                <CustomDropdown
                                    variant="none"
                                    id="dropdown-autoclose-true"
                                >
                                    <span
                                        style={{
                                            fontSize: 14,
                                        }}
                                    >
                                        {item.assignee_can_collaborates === 1 &&
                                            'Collaborator'}
                                        {item.assignee_can_collaborates === 0 &&
                                            'View Only'}
                                    </span>
                                    <FiChevronDown size={20} />
                                </CustomDropdown>
                                <CustomDropdownAccess>
                                    <div className="">
                                        <div className="d-flex justify-content-between align-items-center px-3 py-1">
                                            <Form.Check
                                                type="radio"
                                                label="Collaborator"
                                                id={`collaborator${key}`}
                                                className="my-1"
                                                style={{
                                                    fontSize: 14,
                                                }}
                                                checked={
                                                    item.assignee_can_collaborates === 1
                                                }
                                                onChange={e =>
                                                    handleAccess(
                                                        e.target.checked,
                                                        'collaborate',
                                                        key,
                                                    )
                                                }
                                            />
                                            <Form.Check
                                                type="radio"
                                                label="View Only"
                                                id={`viewOnly${key}`}
                                                className="my-1"
                                                style={{
                                                    fontSize: 14,
                                                }}
                                                checked={
                                                    item.assignee_can_collaborates === 0
                                                }
                                                onChange={e =>
                                                    handleAccess(
                                                        e.target.checked,
                                                        'view',
                                                        key,
                                                    )
                                                }
                                            />
                                        </div>
                                        {/* <hr className="m-0" /> */}
                                        {key !== 0 && (
                                            <div className="d-flex flex-column px-3 py-1">
                                                {/* <Form.Check
                                                type="checkbox"
                                                label="Post Publicly"
                                                id={`postPublicly${key}`}
                                                className="mt-2"
                                                style={{
                                                    fontSize: 14,
                                                }}
                                                checked={
                                                    item.assignee_can_post_publics === 1
                                                }
                                                onChange={e =>
                                                    handleAccess(
                                                        e.target.checked,
                                                        'public',
                                                        key,
                                                    )
                                                }
                                            />

                                            <Form.Check
                                                type="checkbox"
                                                label="Invite User"
                                                id={`inviteUser${key}`}
                                                className="mt-2"
                                                style={{
                                                    fontSize: 14,
                                                }}
                                                checked={item.assignee_can_invites === 1}
                                                onChange={e =>
                                                    handleAccess(
                                                        e.target.checked,
                                                        'invite',
                                                        key,
                                                    )
                                                }
                                            /> */}
                                                <span
                                                    role="button"
                                                    style={{
                                                        fontSize: 14,
                                                    }}
                                                    className="link-danger text-end"
                                                    onClick={() => handleDeleteUser(key)}
                                                >
                                                    Remove User
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </CustomDropdownAccess>
                            </Dropdown>
                        </div>
                    ))}
                    <ButtonBasic
                        type="button"
                        text="Share"
                        onClick={() => handleShareToMail()}
                        loading={shareDrawingState?.loading}
                        // className="w-25 btn-primary"
                        disabled={
                            selectedUser.filter(
                                (item: any) => item.assignee_emails === '',
                            ).length > 0
                        }
                    />
                </div>
                <hr />

                {/* User list */}
                <div className="mb-3">
                    <h6 className="text-start fw-normal text-muted">
                        User who can access this drawing
                    </h6>
                    {sharedUserPrivateDrawingState?.loading === true ? (
                        <div className="text-center">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : sharedUserPrivateDrawingState?.response?.length === 0 ? (
                        <div className="text-center pt-5 pb-3">
                            <p className="text-muted">No user shared</p>
                        </div>
                    ) : (
                        loadUsers?.map((item: any, index: number) => (
                            <div key={index} className="d-flex flex-column gap-3">
                                <div className="d-flex align-items-center justify-content-between mb-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <img
                                            src={
                                                item?.user_avatar ??
                                                generateInitialImageUrl(
                                                    item?.user_name as string,
                                                )
                                            }
                                            alt="avatar"
                                            className="rounded-circle img-fluid"
                                            style={{
                                                width: 40,
                                                height: 40,
                                                objectFit: 'cover',
                                            }}
                                        />
                                        <div className="w-100 text-start ms-2">
                                            <p className="mb-0">{item?.user_name}</p>
                                            <p
                                                className="mb-0 text-muted"
                                                style={{
                                                    fontSize: 12,
                                                    lineHeight: '14px',
                                                }}
                                            >
                                                {item?.user_email}
                                            </p>
                                        </div>
                                    </div>
                                    <Dropdown className="mt-4 ms-2">
                                        <CustomDropdown
                                            variant="none"
                                            id="dropdown-autoclose-true"
                                        >
                                            <span
                                                style={{
                                                    fontSize: 14,
                                                }}
                                            >
                                                {item.can_collaborate_drawing === 1 &&
                                                    'Collaborator'}

                                                {item.can_collaborate_drawing === 0 &&
                                                    'View Only'}
                                            </span>
                                            <FiChevronDown size={20} />
                                        </CustomDropdown>
                                        <CustomDropdownAccess>
                                            <div className="">
                                                <div className="d-flex justify-content-between align-items-center px-3 py-1">
                                                    <Form.Check
                                                        type="radio"
                                                        label="Collaborator"
                                                        id={`updateCollaborator${index}`}
                                                        className="my-1"
                                                        style={{
                                                            fontSize: 14,
                                                        }}
                                                        checked={
                                                            item.can_collaborate_drawing ===
                                                            1
                                                        }
                                                        onChange={e =>
                                                            handleUpdateAccess(
                                                                e.target.checked,
                                                                'collaborate',
                                                                index,
                                                            )
                                                        }
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        label="View Only"
                                                        id={`updateViewOnly${index}`}
                                                        className="my-1"
                                                        style={{
                                                            fontSize: 14,
                                                        }}
                                                        checked={
                                                            item.can_collaborate_drawing ===
                                                            0
                                                        }
                                                        onChange={e =>
                                                            handleUpdateAccess(
                                                                e.target.checked,
                                                                'view',
                                                                index,
                                                            )
                                                        }
                                                    />
                                                </div>
                                                {/* <hr className="m-0" /> */}
                                                <div className="d-flex flex-column px-3 py-1">
                                                    {/* <Form.Check
                                                        type="checkbox"
                                                        label="Post Publicly"
                                                        id={`updatePostPublicly${index}`}
                                                        className="mt-2"
                                                        style={{
                                                            fontSize: 14,
                                                        }}
                                                        checked={
                                                            item.can_post_publicly_drawing ===
                                                            1
                                                        }
                                                        onChange={e =>
                                                            handleUpdateAccess(
                                                                e.target.checked,
                                                                'public',
                                                                index,
                                                            )
                                                        }
                                                    />

                                                    <Form.Check
                                                        type="checkbox"
                                                        label="Invite User"
                                                        id={`updateInviteUser${index}`}
                                                        className="mt-2"
                                                        style={{
                                                            fontSize: 14,
                                                        }}
                                                        checked={
                                                            item.can_invite_drawing === 1
                                                        }
                                                        onChange={e =>
                                                            handleUpdateAccess(
                                                                e.target.checked,
                                                                'invite',
                                                                index,
                                                            )
                                                        }
                                                    /> */}

                                                    <span
                                                        role="button"
                                                        style={{
                                                            fontSize: 14,
                                                        }}
                                                        className="link-danger text-end"
                                                        onClick={() =>
                                                            handleRemoveUser(
                                                                item.user_email,
                                                            )
                                                        }
                                                    >
                                                        Remove User{' '}
                                                        {deleteUserPrivateDrawingState?.loading ===
                                                            true && (
                                                            <Spinner
                                                                animation="border"
                                                                role="status"
                                                                size="sm"
                                                            >
                                                                <span className="visually-hidden">
                                                                    Loading...
                                                                </span>
                                                            </Spinner>
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </CustomDropdownAccess>
                                    </Dropdown>
                                </div>
                            </div>
                        ))
                    )}
                </div>
                {sharedUserPrivateDrawingState?.response?.length > 0 && (
                    <ButtonBasic
                        type="button"
                        text="Update permission"
                        onClick={() => handleUpdatePermission()}
                        loading={updateUserPrivateDrawingState?.loading}
                        className="btn-outline-primary"
                    />
                )}
            </div>
        </ModalAction>
    );
}

const CustomDropdown = styled(Dropdown.Toggle)`
    background: transparent;
    border: none;
    display: flex;
    padding: 0;
    &:focus {
        box-shadow: none;
        background: transparent;
        border: none;
    }

    &:hover {
        box-shadow: none;
        background: transparent;
        border: none;
    }

    &:active {
        box-shadow: none;
        background-color: transparent !important;
        border: none;
    }

    &:focus-visible {
        box-shadow: none;
        background-color: transparent;
        border: none;
    }

    &:after {
        display: none;
    }
`;

const CustomDropdownAccess = styled(Dropdown.Menu)`
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 17rem;

    a {
        text-decoration: none;
        color: #000;
    }

    .label-filter {
        font-size: 14px;
    }

    .dropdown-item {
        font-size: 14px;
        vertical-align: middle;
        padding: 0.3rem 0.5rem;
        border-radius: 5px;

        &:hover {
            background: #dddafe;
            border-radius: 5px;
            a {
                color: #000;
            }
        }
    }
`;
