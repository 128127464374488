import { useEffect, useRef } from 'react';
import { SketchPicker } from 'react-color';
import { Controller } from 'react-hook-form';
import { AiOutlinePlus } from 'react-icons/ai';
import styled from 'styled-components';
interface InputColorProps {
    rules?: {
        function: (name: any, rules: any) => any;
        name: string;
        rules: any;
        errors?: any;
        control?: any;
    };
    value?: string;
}

export const InputColor = (props: InputColorProps): JSX.Element => {
    const { rules, value } = props;
    const colorRef = useRef<HTMLDivElement>(null);

    const handleOpenColor = (): void => {
        if (colorRef.current != null) {
            colorRef.current.style.display = 'block';
        }
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent): void {
            if (
                colorRef.current != null &&
                !colorRef.current.contains(event.target as Node)
            ) {
                colorRef.current.style.display = 'none';
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <InputColorWrapper className="relative">
            <label htmlFor="btnOpenColor" className="text-start mb-2 mt-3 w-100">
                Color
            </label>

            {value !== undefined ? (
                <div className="w-auto">
                    <CircleWrapper onClick={() => handleOpenColor()}>
                        <CircleColor color={value} className="p-2">
                            <AiOutlinePlus className="icon" color="white" />
                        </CircleColor>
                    </CircleWrapper>
                </div>
            ) : (
                <ButtonAssignee id="btnOpenColor" onClick={() => handleOpenColor()}>
                    <AiOutlinePlus className="icon" />
                </ButtonAssignee>
            )}

            <div
                ref={colorRef}
                className="position-relative"
                style={{
                    display: 'none',
                }}
            >
                <div
                    className="position-absolute"
                    style={{
                        top: '-310px',
                        left: '70px',
                    }}
                >
                    <Controller
                        name={rules?.name ?? ''}
                        control={rules?.control}
                        render={({ field }) => (
                            <>
                                <SketchPicker
                                    color={field.value}
                                    onChange={color => {
                                        field.onChange(
                                            `rgba(${color.rgb.r}, ${color.rgb.g}, ${
                                                color.rgb.b
                                            }, ${color.rgb.a ?? '1'})`,
                                        );
                                    }}
                                />
                            </>
                        )}
                    />
                </div>

                {rules?.errors[rules?.name] !== undefined && (
                    <span
                        className="invalid-feedback d-block text-start"
                        style={{
                            fontSize: '0.75rem',
                        }}
                    >
                        {rules?.errors[rules?.name].message}
                    </span>
                )}
            </div>
        </InputColorWrapper>
    );
};

export default InputColor;

const ButtonAssignee = styled.span`
    width: 38px;
    height: 38px;
    background-color: #dddafe;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #5648fb;

    .icon {
        color: #5648fb;
    }

    &:hover {
        border: 1px solid #5648fb;
    }
`;

const CircleColor = styled.div<{ color?: string }>`
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: ${props => props.color};
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CircleWrapper = styled.div`
    border-radius: 50%;
    border: 1px dashed #5648fb;
    cursor: pointer;
    padding: 2px;
    display: inline-block;

    .icon {
        opacity: 0;
    }

    &:hover {
        border: 1px solid #5648fb;
        .icon {
            opacity: 1;
        }
    }
`;

const InputColorWrapper = styled.div`
    .sketch-picker {
        border: 1px solid #c8d0dc !important;
        border-radius: 4px !important;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
    }
`;
