import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    getAllPendingInhouse,
    resendInvitation,
    cancelInvitation,
    getCompanySetting,
    clearStateCompanySetting,
} from '../redux/actions';

import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';
import moment from 'moment';
import { RootState } from '@/redux/store';
import TableBorderless from '@/components/organism/TableBorderless';

export default function PendingInvitation(): JSX.Element {
    const dispatch = useDispatch();

    const pendingInhouseState = useSelector(
        (state: RootState) => state.allPendingInhouse,
    );
    const resendInvitationState = useSelector(
        (state: RootState) => state.resendInvitation,
    );
    const cancelInvitationState = useSelector(
        (state: RootState) => state.cancelInvitation,
    );
    const companyState = useSelector((state: RootState) => state.settingCompany);

    const [searchValue, setSearchValue] = useState('');
    const [modal, setModal] = useState({
        resend: false,
        cancel: false,
        success: false,
    });
    const [params, setParams] = useState({
        page: 1,
        per_page: 10,
        search: '',
        sort_by: 'created_at',
        sort_asc: 0,
    });
    const [modalType, setModalType] = useState({
        id: 0,
        type: '',
        email: '',
    });

    const tableItems = [
        {
            title: 'Company',
            Cell: (row: { response: { company_name: string } }) =>
                companyState?.response?.company_name,
        },
        {
            title: 'Email',
            selector: 'address',
        },
        {
            title: 'Invitation Date',
            Cell: (row: { created_at: number; status: { id: number; name: string } }) =>
                moment.unix(row?.created_at).format('DD/MM/YYYY'),
        },
        {
            title: 'Invitation Status',
            Cell: (row: { status: { id: number; name: string }; expired_at: number }) => (
                <span className={`text-${handleStatusColor(row?.status?.id)}`}>
                    {row?.status?.name}
                </span>
            ),
        },
        {
            title: 'Expired Date',
            Cell: (row: { expired_at: number; status: { id: number; name: string } }) =>
                moment.unix(row?.expired_at).format('DD/MM/YYYY'),
        },
        {
            title: 'Action',
            withAction: true,
            only: (row: {
                status: { id: number; name: string };
                id: number;
                address: string;
            }) => row?.status?.id === 4 || row?.status?.id === 3,
            actions: [
                {
                    text: 'Resend Invitation',
                    type: 'resend',
                    variant: '',
                    onClick: (row: any) =>
                        handleOpenModal(row?.id, row?.address, 'resend'),
                },
                {
                    text: 'Cancel Invitation',
                    type: 'cancel',
                    variant: 'danger',
                    onClick: (row: any) =>
                        handleOpenModal(row?.id, row?.address, 'cancel'),
                    only: (row: any) => row?.status?.id === 4,
                },
            ],
        },
    ];

    /**
     * Function to handle search
     *
     * @param string value
     * @returns void
     */
    const handleSearch = useCallback((value: string): void => {
        setSearchValue(value);
    }, []);

    /**
     * Function to handle debounce search
     *
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleDebouncedSearch = useCallback(() => {
        setParams({
            ...params,
            search: searchValue,
            page: 1,
        });

        const payload = {
            ...params,
            search: searchValue,
            page: 1,
        };

        dispatch(getAllPendingInhouse(payload));
    }, [searchValue]);

    /**
     * Function to handle status color
     *
     * @param number id
     * @returns string
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleStatusColor = (id: number): string => {
        switch (id) {
            case 1:
                return 'success';
            case 2:
                return 'danger';
            case 3:
                return 'danger';
            case 4:
                return 'warning';

            default:
                return 'dark';
        }
    };

    /**
     * Function to handle resend invitation
     *
     * @param id
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleResendInvitation = (id: number): void => {
        dispatch(resendInvitation(id));
    };

    /**
     * Function to handle cancel invitation
     *
     * @param id
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleCancelInvitation = (id: number): void => {
        dispatch(cancelInvitation(id));
    };

    /**
     * Function to handle open modal
     *
     * @param id
     * @param email
     * @param type
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleOpenModal = (id: number, email: string, type: string): void => {
        setModalType({
            id,
            type,
            email,
        });

        setModal(prev => ({
            ...prev,
            [type]: true,
        }));
    };

    /**
     * Function to handle close modal
     *
     * @param number page
     * @param string type
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handlePagination = (page: number, type: string): void => {
        if (type === 'page') {
            setParams({
                ...params,
                page,
            });

            const payload = {
                ...params,
                page,
            };

            dispatch(getAllPendingInhouse(payload));
        }

        if (type === 'next') {
            setParams({
                ...params,
                page: params.page + 1,
            });

            const payload = {
                ...params,
                page: params.page + 1,
            };

            dispatch(getAllPendingInhouse(payload));
        }

        if (type === 'prev') {
            setParams({
                ...params,
                page: params.page - 1,
            });

            const payload = {
                ...params,
                page: params.page - 1,
            };

            dispatch(getAllPendingInhouse(payload));
        }
    };

    // Function to handle modal, when resend invitation success
    useEffect(() => {
        if (resendInvitationState?.status === 200) {
            dispatch(getCompanySetting());
            setModal(prev => ({
                ...prev,
                resend: false,
            }));

            dispatch(clearStateCompanySetting());
            setTimeout(() => {
                dispatch(getAllPendingInhouse(params));

                setModal(prev => ({
                    ...prev,
                    success: true,
                }));
            }, 1000);
        }
    }, [resendInvitationState]);

    // Function to handle modal, when cancel invitation success
    useEffect(() => {
        if (cancelInvitationState?.status === 200) {
            dispatch(getCompanySetting());
            setModal(prev => ({
                ...prev,
                cancel: false,
            }));

            dispatch(clearStateCompanySetting());
            setTimeout(() => {
                dispatch(getAllPendingInhouse({ ...params, page: 1 }));

                setModal(prev => ({
                    ...prev,
                    success: true,
                }));
            }, 1000);
        }
    }, [cancelInvitationState]);

    // Function for handle debounce search
    useEffect(() => {
        const debounce = setTimeout(() => {
            handleDebouncedSearch();
        }, 500);

        return () => {
            clearTimeout(debounce);
        };
    }, [handleDebouncedSearch]);

    return (
        <>
            <TableBorderless
                moduleType="Pending Invitation"
                header={tableItems}
                items={pendingInhouseState?.response ?? []}
                loading={pendingInhouseState?.loading}
                handleSearch={handleSearch}
                handlePagination={handlePagination}
                withoutCheckbox
            />
            <ModalDashboard
                modal={modal.cancel}
                setModal={setModal}
                variant="danger"
                type="cancel"
                title="Cancel Invitation?"
                body={`Are you sure want to cancel invitation on ${modalType.email}?`}
                withCancel
                onConfirm={() => handleCancelInvitation(modalType.id)}
                loading={cancelInvitationState?.loading}
            />
            <ModalDashboard
                modal={modal.resend}
                setModal={setModal}
                variant="info"
                type="resend"
                title="Resend Invitation?"
                body={`Are you sure want to resend invitation on ${modalType.email}?`}
                withCancel
                onConfirm={() => handleResendInvitation(modalType.id)}
                loading={resendInvitationState?.loading}
            />
            <ModalDashboard
                modal={modal.success}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body={`Successfully ${modalType.type} invitation on ${modalType.email}`}
                onConfirm={() => {
                    setModal({ ...modal, success: false });
                }}
            />
        </>
    );
}
