import { RootState } from '@/redux/store';
import { useSelector } from 'react-redux';
import { formatDateIsoAgo } from '@/utility/Utils';

export default function HistoryTask(): JSX.Element {
    const detailTaskHistoryState = useSelector(
        (state: RootState) => state.detailTaskHistory,
    );
    return (
        <div className="">
            {detailTaskHistoryState?.response
                ?.slice()
                ?.reverse()
                ?.map(
                    (
                        history: {
                            id: number;
                            description: string;
                            user_email: string;
                            created_at: string;
                        },
                        key: number,
                    ) => (
                        <div
                            key={key}
                            className="d-flex justify-content-between mb-4 text-muted"
                            style={{ fontSize: 14 }}
                        >
                            <span
                                style={{
                                    maxWidth: '70%',
                                }}
                            >
                                {history?.description?.replace('<br>', '')} by{' '}
                                {history?.user_email}
                            </span>
                            <span>{formatDateIsoAgo(history?.created_at)}</span>
                        </div>
                    ),
                )}
        </div>
    );
}
