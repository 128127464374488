import { axiosClient } from '@/services/GatewayService';
import { AppDispatch } from '@/redux/store';

import {
    ALL_PROJECT_NOTIFICATION_REQUEST,
    ALL_PROJECT_NOTIFICATION_SUCCESS,
    ALL_PROJECT_NOTIFICATION_FAIL,
    ALL_UNREAD_PROJECT_NOTIFICATION_REQUEST,
    ALL_UNREAD_PROJECT_NOTIFICATION_SUCCESS,
    ALL_UNREAD_PROJECT_NOTIFICATION_FAIL,
    FLOAT_PROJECT_NOTIFICATION_REQUEST,
    FLOAT_PROJECT_NOTIFICATION_SUCCESS,
    FLOAT_PROJECT_NOTIFICATION_FAIL,
    MARK_READ_PROJECT_NOTIFICATION_REQUEST,
    MARK_READ_PROJECT_NOTIFICATION_SUCCESS,
    MARK_READ_PROJECT_NOTIFICATION_FAIL,
    MARK_UNREAD_PROJECT_NOTIFICATION_REQUEST,
    MARK_UNREAD_PROJECT_NOTIFICATION_SUCCESS,
    MARK_UNREAD_PROJECT_NOTIFICATION_FAIL,
    DELETE_PROJECT_NOTIFICATION_REQUEST,
    DELETE_PROJECT_NOTIFICATION_SUCCESS,
    DELETE_PROJECT_NOTIFICATION_FAIL,
    RESET_ACTIONS,
} from '../reducers';

/**
 * Function to get all notifications of project
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param number params.per_page - number of project per page
 * @param string params.search - search of project
 * @param string params.sort_by - sort by of project
 * @param number params.sort_asc - sort asc of project
 * @return Promise<void>
 */
export const getAllProjectNotifications = (params: {
    project_id?: string;
    page: number;
    per_page: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_PROJECT_NOTIFICATION_REQUEST });
        try {
            const res = await axiosClient.get('project/notifications', {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: ALL_PROJECT_NOTIFICATION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_PROJECT_NOTIFICATION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all notifications of project
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param number params.per_page - number of project per page
 * @param string params.search - search of project
 * @param string params.sort_by - sort by of project
 * @param number params.sort_asc - sort asc of project
 * @return Promise<void>
 */
export const getAllUnreadProjectNotifications = (params: {
    project_id?: string;
    page: number;
    per_page: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_UNREAD_PROJECT_NOTIFICATION_REQUEST });
        try {
            const res = await axiosClient.get('project/notifications', {
                params: {
                    ...params,
                    unreaded: 1,
                },
            });

            dispatch({
                type: ALL_UNREAD_PROJECT_NOTIFICATION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_UNREAD_PROJECT_NOTIFICATION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all notifications of project
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param number params.per_page - number of project per page
 * @param string params.search - search of project
 * @param string params.sort_by - sort by of project
 * @param number params.sort_asc - sort asc of project
 * @return Promise<void>
 */
export const getAllProjectNotificationFloat = (params: {
    project_id?: string;
    page: number;
    per_page: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: FLOAT_PROJECT_NOTIFICATION_REQUEST });
        try {
            const res = await axiosClient.get('project/notifications', {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: FLOAT_PROJECT_NOTIFICATION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: FLOAT_PROJECT_NOTIFICATION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to mark notifications as read
 *
 * @param string id
 * @return Promise<void>
 */
export const markAsReadProjectNotifications = (ids: string, project_id: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: MARK_READ_PROJECT_NOTIFICATION_REQUEST });
        try {
            const res = await axiosClient.put('project/notifications/read', null, {
                params: {
                    ids,
                    project_id,
                },
            });

            dispatch({
                type: MARK_READ_PROJECT_NOTIFICATION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: MARK_READ_PROJECT_NOTIFICATION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to mark notifications as unread
 *
 * @param string id
 * @return Promise<void>
 */
export const markAsUnReadProjectNotifications = (
    ids: string,
    project_id: string,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: MARK_UNREAD_PROJECT_NOTIFICATION_REQUEST });
        try {
            const res = await axiosClient.put('project/notifications/unread', null, {
                params: {
                    ids,
                    project_id,
                },
            });

            dispatch({
                type: MARK_UNREAD_PROJECT_NOTIFICATION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: MARK_UNREAD_PROJECT_NOTIFICATION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to delete notifications
 *
 * @param string id
 * @return Promise<void>
 */
export const deleteProjectNotifications = (ids: string, project_id: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_PROJECT_NOTIFICATION_REQUEST });
        try {
            const res = await axiosClient.delete('project/notifications', {
                params: {
                    ids,
                    project_id,
                },
            });

            dispatch({
                type: DELETE_PROJECT_NOTIFICATION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DELETE_PROJECT_NOTIFICATION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to reset state of actions
 *
 * @returns Promise<void>
 */
export const clearActionsNotifications = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESET_ACTIONS });
    };
};
