import { Fragment } from 'react';
import { Form } from 'react-bootstrap';

import LinkBack from '@/components/molecules/LinkBack';
import { HeaderContent } from '@/components/molecules/Typography';
import { HeaderStepper, BodyStepper } from '@/components/molecules/VerticalStepper';

interface peopleRangeListProps {
    id: number;
    title: string;
}

export default function CompanyPeopleRange(params: any): JSX.Element {
    const { register, errors } = params;

    const peopleRangeList: peopleRangeListProps[] = [
        {
            id: 1,
            title: 'Only me',
        },
        {
            id: 2,
            title: '2 - 5',
        },
        {
            id: 3,
            title: '6 - 10',
        },
        {
            id: 4,
            title: '11 - 15',
        },
        {
            id: 5,
            title: '16 - 25',
        },
        {
            id: 6,
            title: '25 - 50',
        },
        {
            id: 7,
            title: '51 - 100',
        },
        {
            id: 8,
            title: '101 - 500',
        },
    ];

    return (
        <Fragment>
            <HeaderStepper className="row fade-in mt-5">
                <div className="col-md-4 ms-auto mt-2 mb-5">
                    <HeaderContent
                        text="How many people are in your company?"
                        subtext="Select range number of people in your company"
                    />
                </div>
                <div className="col-md-4 text-end">
                    <LinkBack text="Return to home screen" />
                </div>
            </HeaderStepper>
            <BodyStepper className="row fade-in">
                <div className="col-md-4 mx-auto">
                    {peopleRangeList.map(list => (
                        <Form.Check
                            id={list.id}
                            key={list.id}
                            type="radio"
                            label={list.title}
                            className="mb-3"
                            name="peopleRange"
                            value={list.title}
                            {...register('company_number_of_people', {
                                required: 'Please select number of people',
                            })}
                        />
                    ))}
                    {errors.company_number_of_people != null && (
                        <p
                            className="invalid-feedback d-block text-start"
                            style={{ fontSize: '0.75rem' }}
                        >
                            {errors.company_number_of_people.message as string}
                        </p>
                    )}
                </div>
            </BodyStepper>
        </Fragment>
    );
}
