import authImage9 from '@/assets/images/auth/help-auth-image-9.webp';
import authImage10 from '@/assets/images/auth/help-auth-image-10.webp';
import authImage11 from '@/assets/images/auth/help-auth-image-11.webp';
import authImage12 from '@/assets/images/auth/help-auth-image-12.webp';

import { Fragment } from 'react';

export default function HowToJoinInhouseUser(): JSX.Element {
    const contents = [
        {
            id: 1,
            text: "Inhouse users can only join the company after the owner sends an invitation to the user's email.After opening the invitation contained in the email, in-house users can click the Accept Invitation button contained in the email, after that the page will be redirected to the application.",
            alt: 'loginPage',
            image: authImage9,
        },
        {
            id: 2,
            text: 'In-house users will be directed to the register page, users will be asked to fill in information such as email address, full name, and password, the email in this section cannot be changed and is only for show the data.',
            alt: 'registerPage',
            image: authImage10,
        },
        {
            id: 3,
            text: 'After you have successfully filled in your personal data, data about your company will appear, on this page you cannot make changes to data, only the owner can make changes to company data.',
            alt: 'registerPage',
            image: authImage11,
        },
        {
            id: 4,
            text: 'Next you are asked to complete your personal data again such as your photo, full name, telephone number and job title, If it is already done the registration process is complete and you can start accessing the application.',
            alt: 'registerPage',
            image: authImage12,
        },
    ];

    return (
        <Fragment>
            {contents.map((content, index) => (
                <div key={index} className="text-grey-300 mb-10">
                    <img src={content.image} alt={content.alt} className="img-fluid" />
                    <p className="mt-4 pl-2">{content.text}</p>
                </div>
            ))}
        </Fragment>
    );
}
