import styled from 'styled-components';

export const CircleRed = styled.span`
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #ff0000;
    display: inline-block;
    margin-right: 5px;
    vertical-align: text-top;
`;

export const WrapperTabs = styled.div`
    font-size: 12px;
    border-radius: 10px;

    .nav {
        border-radius: 30px;
        background: rgba(86, 72, 251, 0.3) !important;
        padding: 0.3rem;
    }

    .nav-link {
        border: none;
        color: #ffff !important;
        font-weight: 600;
        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
        padding: 0.5rem 0;
        border-radius: 30px;

        &.active {
            background: #5648fb !important;
            border-radius: 30px;
            color: #ffff;
        }
    }

    .icon-gear {
        stroke: #5648fb;
        &:hover {
            stroke: #838383;
        }
    }

    .notification-list {
        list-style: none !important;
        padding: 0;
        margin-bottom: 0;
        overflow-y: scroll;
        scrollbar-width: none;

        -ms-overflow-style: none;
        &::-webkit-scrollbar {
            display: none;
        }

        /* height: 70vh; */

        height: calc(100vh - 320px);

        // @media (max-height: 900px) {
        //     height: calc(100vh - 500px);
        // }

        // // @media (max-height: 800px) {
        // //     height: calc(100vh - 300px);
        // // }

        // @media (max-height: 720px) {
        //     height: calc(100vh - 500px);
        // }
    }

    .notification-content {
        width: 80%;
    }

    @media only screen and (max-width: 1988px) {
        .notification-content {
            width: 70%;
        }
    }

    @media only screen and (max-width: 1500px) {
        .notification-content {
            width: 65%;
        }
    }

    @media only screen and (max-width: 1200px) {
        .notification-content {
            width: 80%;
        }
    }

    @media only screen and (max-width: 768px) {
        .notification-content {
            width: 76%;
        }
    }

    @media only screen and (max-width: 480px) {
        .notification-content {
            width: 60%;
        }
    }

    .form-check-input {
        width: 1.2em;
        height: 1.2em;

        &:focus {
            box-shadow: none;
        }
    }

    .form-check-label {
        vertical-align: middle;
    }

    .text-time {
        display: contents;
        font-size: 10px;
    }
    .notification-title {
        font-size: 14px;
        font-weight: 600;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .notification-message {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .notification-reply {
        margin-left: 1.2rem;
        text-decoration: none;
        font-size: 11px !important;
    }

    .notification-items {
        background-color: rgba(86, 72, 251, 0.1);
    }

    .unselect {
        font-size: 11px !important;
        background-color: #edecfe;
        color: #5648fb;
        border-radius: 50px;
        padding: 10px 10px;
        font-weight: 500;
    }

    .read {
        font-size: 11px !important;
        background-color: rgba(6, 189, 128, 0.2);
        color: rgba(6, 189, 128, 1);
        border-radius: 50px;
        padding: 10px 10px;
        font-weight: 500;
    }

    /* .form-check-label {
        min-width: 5.5rem !important;
    } */
`;
