import { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import InputBasic from '@/components/atoms/Inputs/InputBasic';
import ModalAction from '@/components/atoms/Modals/ModalAction';

import { RootState } from '@/redux/store';

import { useSelector, useDispatch } from 'react-redux';
import { editGroup } from '../redux/actions';

interface ParamsProps {
    modal: boolean;
    setModal: Dispatch<
        SetStateAction<{
            pin: boolean;
            unpin: boolean;
            archive: boolean;
            delete: boolean;
            success: boolean;
            createGroup: boolean;
            manageUserGroup: boolean;
            exitGroup: boolean;
            deleteGroup: boolean;
            editGroup: boolean;
            deleteMessage: boolean;
            invalidUpload: boolean;
        }>
    >;
}

export default function ModalEditGroup(props: ParamsProps): JSX.Element {
    const { modal, setModal } = props;
    const dispatch = useDispatch();

    const chatDetailsState = useSelector((state: RootState) => state.chatDetails);
    const editGroupState = useSelector((state: RootState) => state.editGroup);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
    });

    useEffect(() => {
        if (modal) {
            setValue('group_name', chatDetailsState?.data?.name);
            setValue('group_description', chatDetailsState?.data?.description);
        }
    }, [modal, setValue]);

    /**
     * Function on submit
     *
     * @param any data
     * @return void
     * @see cypress/e2e/authentication/login/login.cy.ts
     *      To cypress unit tester
     */
    const onSubmit = (data: any): void => {
        // initialize form data
        const payload = new FormData();

        // append data to form data
        for (const key in data) {
            payload.append(key, data[key as keyof typeof data]);
        }

        // Dispatch send message action with form data as payload
        dispatch(editGroup({ channel_id: chatDetailsState?.channelId }, payload));
    };

    return (
        <ModalAction modal={modal} setModal={setModal} title="Edit Group">
            <form onSubmit={handleSubmit(onSubmit)}>
                <InputBasic
                    id="groupName"
                    type="text"
                    placeholder="Group Name"
                    innerClassName="my-3"
                    outerClassName="px-4"
                    rules={{
                        function: register,
                        name: 'group_name',
                        rules: {
                            required: 'Group name is required',
                        },
                        errors,
                    }}
                />

                <InputBasic
                    id="groupDescription"
                    type="text"
                    placeholder="Group Description"
                    innerClassName="mb-3"
                    outerClassName="px-4"
                    rules={{
                        function: register,
                        name: 'group_description',
                        rules: {
                            maxLength: {
                                value: 2000,
                                message: 'Maximum length of 2000 characters',
                            },
                        },
                        errors,
                    }}
                />

                <ButtonBasic
                    text="Save"
                    type="submit"
                    className="btn btn-primary mt-2"
                    loading={editGroupState.loading}
                />
            </form>
        </ModalAction>
    );
}
