import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Card from '@/components/molecules/Card';
import { HeaderCard } from '@/components/molecules/Typography';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { clearStateSwapBilling, swapBilling } from '../redux/actions';
import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';
import { getCurrentProfile } from '../../PersonalSetting/redux/actions';
import { RootState } from '@/redux/store';

export default function SubscriptionRenew(): JSX.Element {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const swapBillingState = useSelector((state: RootState) => state.swapBilling);
    const currentProfileState = useSelector((state: RootState) => state.currentProfile);

    const detailSubscription = currentProfileState?.response?.subscription;

    const [modal, setModal] = useState({
        success: false,
    });

    const [selectedMethod, setSelectedMethod] = useState<string>('');
    const [disableSelected, setDisableSelected] = useState<string>('');

    const subscriptionLists = [
        {
            id: 'month',
            title: 'Monthly',
            price: '$39.99',
            type: '/month',
            items: ['Including Administration User', '1 Inhouse User'],
        },
        // TODO: Hidden for now
        // {
        //     id: 'annual',
        //     title: 'Annually',
        //     price: '$431.99',
        //     type: '/year',
        //     items: ['Including 1 Owner', ' 1 Inhouse User'],
        // },
    ];

    // Function if user have subscription annual, then disable monthly
    useEffect(() => {
        if (detailSubscription !== undefined) {
            detailSubscription?.subscription_billing_cycle?.type === 'Annual'
                ? setDisableSelected('annual')
                : setDisableSelected('month');
        }
    }, [detailSubscription]);

    // if success change billing, then show modal success
    useEffect(() => {
        if (swapBillingState?.status === 200) {
            dispatch(getCurrentProfile());

            setModal(prev => ({
                ...prev,
                success: true,
            }));

            setTimeout(() => {
                dispatch(clearStateSwapBilling());
            }, 1000);
        }
    }, [swapBillingState]);

    /**
     * Function for handle submit billing
     *
     * @param type string
     * @returns void
     */
    const handleSubmit = (type: string): void => {
        const formData = new FormData();

        formData.append('type', type);

        dispatch(swapBilling(formData));
    };

    /**
     * Function for handle confirm modal success
     *
     * @returns void
     */
    const handleConfirm = (): void => {
        setModal(prev => ({
            ...prev,
            success: false,
        }));
        navigate('/setting/subscription');
    };

    return (
        <>
            <div className="row mb-5">
                <div className="col-lg-8 mx-auto my-5">
                    <Card classOuter="px-2 py-4">
                        <div className="row mb-2">
                            <div className="col-lg-12 mb-5">
                                <HeaderCard
                                    text="Select Billing Cycle"
                                    className="mb-2"
                                />
                                <p
                                    className="mb-0 text-info mb-5"
                                    style={{
                                        fontSize: 12,
                                    }}
                                >
                                    You can change your billing cycle only one time per
                                    one billing cycle. If you mistakenly change your
                                    billing cycle, please contact our support team.
                                </p>
                            </div>
                            <div className="col-lg-12">
                                <HeaderCard text="Billing Cycle" />
                            </div>
                        </div>
                        <div className="row mb-2">
                            {subscriptionLists.map((item: any) => (
                                <div
                                    key={item.id}
                                    className="col-xs-12 col-sm-12 col-md-6 col-lg-6 my-2"
                                    onClick={() => {
                                        if (disableSelected !== item.id) {
                                            setSelectedMethod(item.id);
                                        }
                                    }}
                                >
                                    <CardMethod
                                        className="card px-3 py-3 border-primary"
                                        index={item.id}
                                        selected={selectedMethod}
                                        disabled={disableSelected === item.id}
                                    >
                                        <div className="d-flex flex-column justify-content-center align-items-center text-center">
                                            <span
                                                style={{
                                                    fontSize: 20,
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {item.title}
                                            </span>
                                            <div className="mt-3 mb-1">
                                                <span
                                                    className=" text-primary"
                                                    style={{
                                                        fontSize: 40,
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    {item.price}
                                                </span>
                                                <span className="text-muted">
                                                    {item.type}
                                                </span>
                                            </div>
                                            <div className="mt-3">
                                                {item.items.map(
                                                    (item: any, index: number) => (
                                                        <>
                                                            <p
                                                                style={{ fontSize: 16 }}
                                                                className="mb-1"
                                                            >
                                                                {item}
                                                            </p>
                                                        </>
                                                    ),
                                                )}
                                            </div>
                                        </div>
                                    </CardMethod>
                                </div>
                            ))}
                            <div className="row text-center mt-5">
                                <div className="col-md-12">
                                    <ButtonBasic
                                        text="Cancel"
                                        className="px-5 btn-outline-danger me-3"
                                        onClick={() => navigate('/setting/subscription')}
                                    />
                                    <ButtonBasic
                                        text="Apply Changes"
                                        className="px-5 btn-primary"
                                        onClick={() => handleSubmit(selectedMethod)}
                                        disabled={selectedMethod === ''}
                                        loading={swapBillingState.loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <ModalDashboard
                    modal={modal.success}
                    setModal={setModal}
                    variant="success"
                    type="success"
                    title="Success!"
                    body="Successfully swap billing"
                    onConfirm={() => handleConfirm()}
                />
            </div>
        </>
    );
}

const CardMethod = styled.div<{ selected: string; index: string; disabled: boolean }>`
    border-radius: 10px;
    user-select: none;

    background-color: ${({ disabled }) => (disabled ? '#d6d6d8' : 'transparent')};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    border-color: ${({ selected, index, disabled }) =>
        disabled ? '#b9b9b9' : selected === index ? '#5648fb' : 'rgba(207, 207, 207, 1)'};
`;
