export const CHAT_LIST_REQUEST = 'CHAT_LIST_REQUEST';
export const CHAT_LIST_SUCCESS = 'CHAT_LIST_SUCCESS';
export const CHAT_LIST_FAIL = 'CHAT_LIST_FAIL';
export const CHAT_LIST_BROADCAST = 'CHAT_LIST_BROADCAST';

export const CHAT_DETAILS_REQUEST = 'CHAT_DETAILS_REQUEST';
export const CHAT_DETAILS_SUCCESS = 'CHAT_DETAILS_SUCCESS';
export const CHAT_DETAILS_FAIL = 'CHAT_DETAILS_FAIL';
export const CHAT_DETAILS_BROADCAST = 'CHAT_DETAILS_BROADCAST';
export const CHAT_DETAILS_DATA = 'CHAT_DETAILS_DATA';
export const CHAT_DETAILS_CHANNEL = 'CHAT_DETAILS_CHANNEL';
export const CHAT_DETAILS_RESET = 'CHAT_DETAILS_RESET';
export const CHAT_DETAILS_NEW_MESSAGE = 'CHAT_DETAILS_NEW_MESSAGE';
export const RESET_CHAT_DETAILS_BROADCAST = 'RESET_CHAT_DETAILS_BROADCAST';
export const RESET_CHAT_DETAILS_NEW_MESSAGE = 'RESET_CHAT_DETAILS_NEW_MESSAGE';

export const CHAT_PIN_REQUEST = 'CHAT_PIN_REQUEST';
export const CHAT_PIN_SUCCESS = 'CHAT_PIN_SUCCESS';
export const CHAT_PIN_FAIL = 'CHAT_PIN_FAIL';
export const CHAT_PIN_RESET = 'CHAT_PIN_RESET';

export const CHAT_UNPIN_REQUEST = 'CHAT_UNPIN_REQUEST';
export const CHAT_UNPIN_SUCCESS = 'CHAT_UNPIN_SUCCESS';
export const CHAT_UNPIN_FAIL = 'CHAT_UNPIN_FAIL';
export const CHAT_UNPIN_RESET = 'CHAT_UNPIN_RESET';

export const DELETE_CHAT_REQUEST = 'DELETE_CHAT_REQUEST';
export const DELETE_CHAT_SUCCESS = 'DELETE_CHAT_SUCCESS';
export const DELETE_CHAT_FAIL = 'DELETE_CHAT_FAIL';
export const DELETE_CHAT_RESET = 'DELETE_CHAT_RESET';

export const NEW_DIRECT_MESSAGE_REQUEST = 'NEW_DIRECT_MESSAGE_REQUEST';
export const NEW_DIRECT_MESSAGE_SUCCESS = 'NEW_DIRECT_MESSAGE_SUCCESS';
export const NEW_DIRECT_MESSAGE_FAIL = 'NEW_DIRECT_MESSAGE_FAIL';
export const NEW_DIRECT_REDIRECTED = 'NEW_DIRECT_REDIRECTED';
export const NEW_DIRECT_MESSAGE_RESET = 'NEW_DIRECT_MESSAGE_RESET';

export const NEW_GROUP_MESSAGE_REQUEST = 'NEW_GROUP_MESSAGE_REQUEST';
export const NEW_GROUP_MESSAGE_SUCCESS = 'NEW_GROUP_MESSAGE_SUCCESS';
export const NEW_GROUP_MESSAGE_FAIL = 'NEW_GROUP_MESSAGE_FAIL';
export const NEW_GROUP_REDIRECTED = 'NEW_GROUP_REDIRECTED';
export const NEW_GROUP_MESSAGE_RESET = 'NEW_GROUP_MESSAGE_RESET';

export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAIL = 'SEND_MESSAGE_FAIL';
export const SEND_MESSAGE_RESET = 'SEND_MESSAGE_RESET';

export const SEND_FILE_MESSAGE_REQUEST = 'SEND_FILE_MESSAGE_REQUEST';
export const SEND_FILE_MESSAGE_SUCCESS = 'SEND_FILE_MESSAGE_SUCCESS';
export const SEND_FILE_MESSAGE_FAIL = 'SEND_FILE_MESSAGE_FAIL';

export const RECEIVE_MESSAGE_REQUEST = 'RECEIVE_MESSAGE_REQUEST';
export const RECEIVE_MESSAGE_SUCCESS = 'RECEIVE_MESSAGE_SUCCESS';
export const RECEIVE_MESSAGE_FAIL = 'RECEIVE_MESSAGE_FAIL';
export const RECEIVE_MESSAGE_RESET = 'RECEIVE_MESSAGE_RESET';

export const RECEIVE_FILE_MESSAGE_REQUEST = 'RECEIVE_FILE_MESSAGE_REQUEST';
export const RECEIVE_FILE_MESSAGE_SUCCESS = 'RECEIVE_FILE_MESSAGE_SUCCESS';
export const RECEIVE_FILE_MESSAGE_FAIL = 'RECEIVE_FILE_MESSAGE_FAIL';

export const DELETE_CHANNEL_CHAT_REQUEST = 'DELETE_CHANNEL_CHAT_REQUEST';
export const DELETE_CHANNEL_CHAT_SUCCESS = 'DELETE_CHANNEL_CHAT_SUCCESS';
export const DELETE_CHANNEL_CHAT_FAIL = 'DELETE_CHANNEL_CHAT_FAIL';
export const DELETE_CHANNEL_CHAT_RESET = 'DELETE_CHANNEL_CHAT_RESET';

export const EXIT_GROUP_REQUEST = 'EXIT_GROUP_REQUEST';
export const EXIT_GROUP_SUCCESS = 'EXIT_GROUP_SUCCESS';
export const EXIT_GROUP_FAIL = 'EXIT_GROUP_FAIL';
export const EXIT_GROUP_RESET = 'EXIT_GROUP_RESET';

export const EDIT_GROUP_REQUEST = 'EDIT_GROUP_REQUEST';
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS';
export const EDIT_GROUP_FAIL = 'EDIT_GROUP_FAIL';
export const EDIT_GROUP_RESET = 'EDIT_GROUP_RESET';

export const KICK_ADMIN_REQUEST = 'KICK_ADMIN_REQUEST';
export const KICK_ADMIN_SUCCESS = 'KICK_ADMIN_SUCCESS';
export const KICK_ADMIN_FAIL = 'KICK_ADMIN_FAIL';
export const KICK_ADMIN_RESET = 'KICK_ADMIN_RESET';

export const KICK_MEMBER_REQUEST = 'KICK_MEMBER_REQUEST';
export const KICK_MEMBER_SUCCESS = 'KICK_MEMBER_SUCCESS';
export const KICK_MEMBER_FAIL = 'KICK_MEMBER_FAIL';
export const KICK_MEMBER_RESET = 'KICK_MEMBER_RESET';

export const PROMOTE_ADMIN_REQUEST = 'PROMOTE_ADMIN_REQUEST';
export const PROMOTE_ADMIN_SUCCESS = 'PROMOTE_ADMIN_SUCCESS';
export const PROMOTE_ADMIN_FAIL = 'PROMOTE_ADMIN_FAIL';
export const PROMOTE_ADMIN_RESET = 'PROMOTE_ADMIN_RESET';

export const PROMOTE_MEMBER_REQUEST = 'PROMOTE_MEMBER_REQUEST';
export const PROMOTE_MEMBER_SUCCESS = 'PROMOTE_MEMBER_SUCCESS';
export const PROMOTE_MEMBER_FAIL = 'PROMOTE_MEMBER_FAIL';
export const PROMOTE_MEMBER_RESET = 'PROMOTE_MEMBER_RESET';

export const INVITE_TO_CURRENT_GROUP_REQUEST = 'INVITE_TO_CURRENT_GROUP_REQUEST';
export const INVITE_TO_CURRENT_GROUP_SUCCESS = 'INVITE_TO_CURRENT_GROUP_SUCCESS';
export const INVITE_TO_CURRENT_GROUP_FAIL = 'INVITE_TO_CURRENT_GROUP_FAIL';
export const INVITE_TO_CURRENT_GROUP_RESET = 'INVITE_TO_CURRENT_GROUP_RESET';

export const ALL_USER_REQUEST = 'ALL_USER_REQUEST';
export const ALL_USER_SUCCESS = 'ALL_USER_SUCCESS';
export const ALL_USER_FAIL = 'ALL_USER_FAIL';

export const TOTAL_ONLINE_REQUEST = 'TOTAL_ONLINE_REQUEST';
export const TOTAL_ONLINE_SUCCESS = 'TOTAL_ONLINE_SUCCESS';
export const TOTAL_ONLINE_FAIL = 'TOTAL_ONLINE_FAIL';

export const SET_ONLINE_REQUEST = 'SET_ONLINE_REQUEST';
export const SET_ONLINE_SUCCESS = 'SET_ONLINE_SUCCESS';
export const SET_ONLINE_FAIL = 'SET_ONLINE_FAIL';

export const CHAT_MARK_AS_READ_REQUEST = 'CHAT_MARK_AS_READ_REQUEST';
export const CHAT_MARK_AS_READ_SUCCESS = 'CHAT_MARK_AS_READ_SUCCESS';
export const CHAT_MARK_AS_READ_FAIL = 'CHAT_MARK_AS_READ_FAIL';
export const CHAT_MARK_AS_READ_RESET = 'CHAT_MARK_AS_READ_RESET';

export const CLEAR_STATE = 'CLEAR_STATE';

const initialState = {
    loading: false,
    response: null,
    error: null,
    status: null,
};

export const chatListReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case CHAT_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CHAT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case CHAT_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CHAT_LIST_BROADCAST:
            return {
                ...state,
                broadcast: action.payload,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const chatDetailsReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case CHAT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CHAT_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case CHAT_DETAILS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CHAT_DETAILS_BROADCAST:
            return {
                ...state,
                broadcast: action.payload,
            };

        case CHAT_DETAILS_CHANNEL:
            return {
                ...state,
                channelId: action.payload,
            };

        case CHAT_DETAILS_DATA:
            return {
                ...state,
                data: action.payload,
            };

        case CHAT_DETAILS_NEW_MESSAGE:
            return {
                ...state,
                newMessage: action.payload,
            };

        case RESET_CHAT_DETAILS_NEW_MESSAGE:
            return {
                ...state,
                newMessage: undefined,
            };

        case RESET_CHAT_DETAILS_BROADCAST:
            return {
                ...state,
                broadcast: null,
            };

        case CHAT_DETAILS_RESET:
            return initialState;

        // case CHAT_DETAILS_RESET:
        //     return initialState;

        default:
            return state;
    }
};

export const chatPinReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case CHAT_PIN_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CHAT_PIN_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case CHAT_PIN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CHAT_PIN_RESET:
            return initialState;

        default:
            return state;
    }
};

export const chatUnpinReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case CHAT_UNPIN_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CHAT_UNPIN_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case CHAT_UNPIN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CHAT_UNPIN_RESET:
            return initialState;

        default:
            return state;
    }
};

export const chatSendMessageReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case SEND_MESSAGE_REQUEST:
        case SEND_FILE_MESSAGE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case SEND_MESSAGE_SUCCESS:
        case SEND_FILE_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case SEND_MESSAGE_FAIL:
        case SEND_FILE_MESSAGE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case SEND_MESSAGE_RESET:
            return initialState;

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const chatReceiveMessageReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case RECEIVE_MESSAGE_REQUEST:
        case RECEIVE_FILE_MESSAGE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case RECEIVE_MESSAGE_SUCCESS:
        case RECEIVE_FILE_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case RECEIVE_MESSAGE_FAIL:
        case RECEIVE_FILE_MESSAGE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case RECEIVE_MESSAGE_RESET:
            return initialState;

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const chatChannelDeleteReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DELETE_CHANNEL_CHAT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_CHANNEL_CHAT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DELETE_CHANNEL_CHAT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case DELETE_CHANNEL_CHAT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const newDirectMessageReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case NEW_DIRECT_MESSAGE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case NEW_DIRECT_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case NEW_DIRECT_MESSAGE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case NEW_DIRECT_REDIRECTED:
            return {
                ...state,
                isRedirected: action.payload,
            };

        case NEW_DIRECT_MESSAGE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const newGroupMessageReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case NEW_GROUP_MESSAGE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case NEW_GROUP_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case NEW_GROUP_MESSAGE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case NEW_GROUP_REDIRECTED:
            return {
                ...state,
                isRedirected: action.payload,
            };

        case NEW_GROUP_MESSAGE_RESET:
            return initialState;

        default:
            return state;
    }
};

export const exitGroupReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case EXIT_GROUP_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case EXIT_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case EXIT_GROUP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case EXIT_GROUP_RESET:
            return initialState;

        default:
            return state;
    }
};

export const editGroupReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case EDIT_GROUP_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case EDIT_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case EDIT_GROUP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case EDIT_GROUP_RESET:
            return initialState;

        default:
            return state;
    }
};

export const kickGroupParticipantReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case KICK_ADMIN_REQUEST:
        case KICK_MEMBER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case KICK_ADMIN_SUCCESS:
        case KICK_MEMBER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case EXIT_GROUP_FAIL:
        case KICK_MEMBER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case EXIT_GROUP_RESET:
        case KICK_MEMBER_RESET:
            return initialState;

        default:
            return state;
    }
};

export const promoteGroupReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case PROMOTE_ADMIN_REQUEST:
        case PROMOTE_MEMBER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case PROMOTE_ADMIN_SUCCESS:
        case PROMOTE_MEMBER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case PROMOTE_ADMIN_FAIL:
        case PROMOTE_MEMBER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case PROMOTE_ADMIN_RESET:
        case PROMOTE_MEMBER_RESET:
            return initialState;

        default:
            return state;
    }
};

export const inviteToCurrentGroupReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case INVITE_TO_CURRENT_GROUP_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case INVITE_TO_CURRENT_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case INVITE_TO_CURRENT_GROUP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case INVITE_TO_CURRENT_GROUP_RESET:
            return initialState;

        default:
            return state;
    }
};

export const allUserChatReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ALL_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ALL_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case ALL_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const deleteChatReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case DELETE_CHAT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case DELETE_CHAT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case DELETE_CHAT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case DELETE_CHAT_RESET:
            return initialState;

        default:
            return state;
    }
};

export const totalOnlineChatReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case TOTAL_ONLINE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case TOTAL_ONLINE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case TOTAL_ONLINE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const setOnlineReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case SET_ONLINE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case SET_ONLINE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case SET_ONLINE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const chatMarkAsReadReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case CHAT_MARK_AS_READ_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case CHAT_MARK_AS_READ_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case CHAT_MARK_AS_READ_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CHAT_MARK_AS_READ_RESET:
            return initialState;

        default:
            return state;
    }
};
