export const ADVERTISEMENT_GENERAL_DASHBOARD_REQUEST =
    'ADVERTISEMENT_GENERAL_DASHBOARD_REQUEST';
export const ADVERTISEMENT_GENERAL_DASHBOARD_SUCCESS =
    'ADVERTISEMENT_GENERAL_DASHBOARD_SUCCESS';
export const ADVERTISEMENT_GENERAL_DASHBOARD_FAIL =
    'ADVERTISEMENT_GENERAL_DASHBOARD_FAIL';

export const ADVERTISEMENT_PROJECT_DASHBOARD_REQUEST =
    'ADVERTISEMENT_PROJECT_DASHBOARD_REQUEST';
export const ADVERTISEMENT_PROJECT_DASHBOARD_SUCCESS =
    'ADVERTISEMENT_PROJECT_DASHBOARD_SUCCESS';
export const ADVERTISEMENT_PROJECT_DASHBOARD_FAIL =
    'ADVERTISEMENT_PROJECT_DASHBOARD_FAIL';

export const ADVERTISEMENT_LANDING_REQUEST = 'ADVERTISEMENT_LANDING_REQUEST';
export const ADVERTISEMENT_LANDING_SUCCESS = 'ADVERTISEMENT_LANDING_SUCCESS';
export const ADVERTISEMENT_LANDING_FAIL = 'ADVERTISEMENT_LANDING_FAIL';

export const WEATHER_REQUEST = 'WEATHER_REQUEST';
export const WEATHER_SUCCESS = 'WEATHER_SUCCESS';
export const WEATHER_FAIL = 'WEATHER_FAIL';

export const IP_REQUEST = 'IP_REQUEST';
export const IP_SUCCESS = 'IP_SUCCESS';
export const IP_FAIL = 'IP_FAIL';

export const CLEAR_STATE = 'CLEAR_STATE';

const initialState = {
    loading: false,
    response: null,
    error: null,
    status: null,
};

export const advertisementWallpaperDashboardReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case ADVERTISEMENT_GENERAL_DASHBOARD_REQUEST:
        case ADVERTISEMENT_PROJECT_DASHBOARD_REQUEST:
        case ADVERTISEMENT_LANDING_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ADVERTISEMENT_GENERAL_DASHBOARD_SUCCESS:
            return {
                ...state,
                loading: false,
                general: action.payload,
                status: 200,
            };

        case ADVERTISEMENT_PROJECT_DASHBOARD_SUCCESS:
            return {
                ...state,
                loading: false,
                project: action.payload,
                status: 200,
            };

        case ADVERTISEMENT_LANDING_SUCCESS:
            return {
                ...state,
                loading: false,
                landing: action.payload,
                status: 200,
            };

        case ADVERTISEMENT_GENERAL_DASHBOARD_FAIL:
        case ADVERTISEMENT_PROJECT_DASHBOARD_FAIL:
        case ADVERTISEMENT_LANDING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const weatherReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case WEATHER_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case WEATHER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case WEATHER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};

export const ipReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case IP_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case IP_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case IP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATE:
            return initialState;

        default:
            return state;
    }
};
