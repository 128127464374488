import { Accordion } from 'react-bootstrap';
import styled from 'styled-components';

export default function AccordionBasic({
    defaultActiveKey = '0',
    lists,
}: {
    defaultActiveKey?: string;
    lists: Array<{
        id: string;
        title: string;
        content: JSX.Element;
    }>;
}): JSX.Element {
    return (
        <AccordionWrapper defaultActiveKey={defaultActiveKey}>
            {lists.map((list, index) => (
                <Accordion.Item key={index} eventKey={list.id}>
                    <Accordion.Header>{list.title}</Accordion.Header>
                    <Accordion.Body>{list.content}</Accordion.Body>
                </Accordion.Item>
            ))}
        </AccordionWrapper>
    );
}

const AccordionWrapper = styled(Accordion)`
    .accordion-item {
        border-color: #ffff;
        background-color: transparent;
    }

    .accordion-header {
        border-bottom: 1px solid #e2e2e2;
        background-color: transparent !important;
    }

    .accordion-button {
        background-color: transparent !important;
    }

    .accordion-button:focus {
        border-color: transparent;
        box-shadow: none;
    }
`;
