import projectImage3 from '@/assets/images/project/help-project-image-3.webp';
import projectImage4 from '@/assets/images/project/help-project-image-4.webp';
import projectImage5 from '@/assets/images/project/help-project-image-5.webp';
import projectImage6 from '@/assets/images/project/help-project-image-6.webp';

import { Fragment } from 'react';

export default function HowToManageProject(): JSX.Element {
    const contents = [
        {
            id: 1,
            text: 'You can manage your project by clicking the gear icon on the project card.',
            alt: 'projectPage',
            image: projectImage3,
        },
        {
            id: 2,
            text: 'In the project settings, as a project owner you can manage the project members, project details, and project niche. You can also update permissions for each member, invite, remove, and awarding the member.',
            alt: 'projectPage',
            image: projectImage4,
        },
        {
            id: 3,
            text: 'Niches are use to categorize your project, so that the project can be easily found by other. You can add, edit, and delete niches.',
            alt: 'projectPage',
            image: projectImage5,
        },
        {
            id: 4,
            text: 'You can also rename your project, change the project description, and change picture of your project.',
            alt: 'projectPage',
            image: projectImage6,
        },
    ];

    return (
        <Fragment>
            {contents.map((content, index) => (
                <div key={index} className="text-grey-300 mb-10">
                    {content.image.length > 0 && (
                        <img
                            src={content.image}
                            alt={content.alt}
                            className="img-fluid"
                        />
                    )}
                    <p className="mt-4 pl-2">{content.text}</p>
                </div>
            ))}
        </Fragment>
    );
}
