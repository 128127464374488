import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@/components/molecules/Card';
import { Title } from '@/components/molecules/Typography';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';

import styled from 'styled-components';

import BgVisa from '@/assets/images/BgVisa.png';
import BgAmerican from '@/assets/images/BgAmericanExpress.png';
import BgMasterCard from '@/assets/images/BgMasterCard.png';
import BgDiscover from '@/assets/images/BgDiscover.png';
import BgCreditDefault from '@/assets/images/BgGeneral.png';

import {
    CardElement,
    useStripe,
    useElements,
    AddressElement,
} from '@stripe/react-stripe-js';
import Stripe from '@/services/Stripe';

import {
    addPaymentMethod,
    clearStateAddPayment,
    getPaymentMethod,
} from '../redux/actions';
import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCurrentProfile } from '../../PersonalSetting/redux/actions';
import { RootState } from '@/redux/store';

import StripeLogo from '@/assets/images/StripeLogo.png';
import StripeLogo2 from '@/assets/images/StripeLogo2.png';

const CheckoutForm = (): JSX.Element => {
    const stripe: any = useStripe();
    const navigate = useNavigate();

    const elements = useElements();
    const dispatch = useDispatch();

    const addPaymentMethodState = useSelector(
        (state: RootState) => state.addPaymentMethod,
    );

    const [modal, setModal] = useState({
        success: false,
    });

    const [cardType, setCardType] = useState('');

    // If successful change card, then show the modal success
    useEffect(() => {
        if (addPaymentMethodState.status === 200) {
            setModal(prev => ({
                ...prev,
                success: true,
            }));
            dispatch(getPaymentMethod());
            dispatch(getCurrentProfile());

            setTimeout(() => {
                dispatch(clearStateAddPayment());
            }, 1000);
        }
    }, [addPaymentMethodState]);

    /**
     * Function for handle submit card
     *
     * @param object event
     * @returns Promise<void>
     */
    const handleSubmit = async (event: any): Promise<void> => {
        event.preventDefault();

        if (elements == null) {
            return;
        }

        // crete token credit card
        const addressElement = elements.getElement('address');

        // get value from address element
        const { complete, value }: any = await addressElement?.getValue();

        if (complete !== false) {
            // create payment method
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: {
                    name: value.name,
                    address: {
                        line1: value.address.line1,
                        line2: value.address.line2,
                        city: value.address.city,
                        state: value.address.state,
                        postal_code: value.address.postal_code,
                        country: value.address.country,
                    },
                },
            });

            // if not error, then add payment method
            if (paymentMethod !== undefined) {
                dispatch(addPaymentMethod(paymentMethod.id));
            }

            if (error !== undefined) {
                toast.error(error.message);
            }
        }
    };

    const handleCard = (type: string): string => {
        switch (type) {
            case 'visa':
                return BgVisa;
            case 'mastercard':
                return BgMasterCard;
            case 'amex':
                return BgAmerican;
            case 'discover':
                return BgDiscover;
            default:
                return BgCreditDefault;
        }
    };

    /**
     * Function for handle confirm card
     *
     * @param object event
     * @returns Promise<void>
     */
    const handleConfirm = (): void => {
        setModal(prev => ({
            ...prev,
            success: false,
        }));

        navigate('/setting/subscription');
    };

    /**
     * Options for address element
     */
    const addressOptions: any = {
        mode: 'billing',
    };

    return (
        <div className="row mb-5">
            <Title text="Subscription and Billing" />
            <div className="col-lg-8 my-5">
                <form onSubmit={handleSubmit}>
                    <Card classOuter="p-4">
                        <div className="row mb-2">
                            <div className="col-lg-12 mb-4">
                                <h6 className="card-title fw-semibold mb-4">
                                    Card Information
                                </h6>
                                <CardElement
                                    className="form-control py-4 mb-4 bg-transparent"
                                    onChange={(e: any) => setCardType(e.brand)}
                                />
                            </div>
                            <div className="col-lg-12 mb-3">
                                <h6 className="card-title fw-semibold mb-4">
                                    Billing Address
                                </h6>
                                <AddressElement
                                    options={addressOptions}
                                    className="border-0"
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 text-md-end">
                            <ButtonBasic
                                text="Cancel"
                                className="btn-outline-primary m-1"
                                onClick={() => navigate('/setting/subscription')}
                            />

                            <ButtonBasic
                                type="submit"
                                text={
                                    <div className="">
                                        <img
                                            src={StripeLogo2}
                                            alt="stripe"
                                            className="img-fluid"
                                            style={{
                                                verticalAlign: 'sub',
                                            }}
                                            width={40}
                                        />
                                        <span className="ms-2">Change Card</span>
                                    </div>
                                }
                                loading={addPaymentMethodState.loading}
                            />
                        </div>
                    </Card>
                </form>
            </div>

            <div className="col-lg-4 my-5">
                <Card classOuter="px-2 py-4">
                    <div className="row mb-2">
                        <div className="col-lg-12 mb-4">
                            <span className="card-title fw-semibold">Card Preview</span>
                        </div>
                        <div className="col-md-8 col-lg-12">
                            <img
                                src={handleCard(cardType)}
                                alt="card"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                    <div className="col-lg-12 text-center">
                        <small className="fs-italic">
                            <em>Powered by</em>
                        </small>
                        <img
                            src={StripeLogo}
                            alt="stripe"
                            className="img-fluid"
                            width={50}
                        />
                    </div>
                </Card>
            </div>

            <ModalDashboard
                modal={modal.success}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body="Successfully change card."
                onConfirm={() => handleConfirm()}
            />
        </div>
    );
};

export default function SubscriptionChangeCard(): JSX.Element {
    return (
        <Stripe>
            <CheckoutForm />
        </Stripe>
    );
}

export const InputWrapper = styled.div<{ errors?: boolean }>`
    &:focus-within {
        .icon-active {
            background-color: ${props => (props.errors ?? false ? '#FB6056' : '#5648fb')};
        }

        .label-active {
            color: ${props => (props.errors ?? false ? '#FB6056' : '#5648fb')};
        }
    }
`;
