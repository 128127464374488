import styled from 'styled-components';
import { FallingLines } from 'react-loader-spinner';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import React from 'react';

interface ButtonPrimaryProps {
    id?: string;
    type?: 'submit' | 'reset' | 'button';
    text?: string | JSX.Element;
    onClick?: any; // () => void;
    className?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    loading?: boolean | undefined;
    withTooltip?: string;
    tooltipPlacement?: 'top' | 'right' | 'bottom' | 'left';
}

export default function ButtonBasic(props: ButtonPrimaryProps): JSX.Element {
    const { id, type, text, onClick, className, disabled, style, loading, withTooltip } =
        props;

    return withTooltip !== undefined ? (
        <OverlayTrigger
            placement={props.tooltipPlacement ?? 'right'}
            delay={{ show: 250, hide: 400 }}
            overlay={props => (
                <Tooltip id="button-tooltip" {...props}>
                    {withTooltip}
                </Tooltip>
            )}
        >
            <Button
                id={id}
                style={style}
                type={type}
                className={`btn text-white ${className ?? 'btn btn-primary'}`}
                onClick={onClick}
                disabled={loading === true ? true : disabled}
                tabIndex={0}
            >
                {loading === undefined || !loading ? <span>{text}</span> : null}
                <FallingLines color="#5648fb" width="20" visible={loading === true} />
            </Button>
        </OverlayTrigger>
    ) : (
        <Button
            id={id}
            style={style}
            type={type}
            className={`btn text-white ${className ?? 'btn btn-primary'}`}
            onClick={onClick}
            disabled={loading === true ? true : disabled}
            tabIndex={0}
        >
            {loading === undefined || !loading ? <span>{text}</span> : null}
            <FallingLines color="#5648fb" width="20" visible={loading === true} />
        </Button>
    );
}

const Button = styled.button<{ loading?: boolean | undefined }>`
    border-radius: 50px;
    font-size: 13px;
    padding: 10px 20px;
    font-weight: 500;

    &:disabled {
        background-color: #c4c4c4;
        color: ${props =>
            props.loading === true ? '#5648fb!important' : '#fff!important'};
        border: 1px solid #c4c4c4;
    }
`;
