import { Fragment } from 'react';

import BuildingIcons from '@/assets/svg/icons/building.svg';
import MailIcons from '@/assets/svg/icons/mail-alt-3.svg';

import InputBasic from '@/components/atoms/Inputs/InputBasic';
import LinkBack from '@/components/molecules/LinkBack';
import { HeaderContent } from '@/components/molecules/Typography';
import { HeaderStepper, BodyStepper } from '@/components/molecules/VerticalStepper';

export default function CompanyDetailsInhouse(params: any): JSX.Element {
    const { register, errors } = params;

    return (
        <Fragment>
            <HeaderStepper className="row fade-in mt-5">
                <div className="col-md-4 ms-auto mt-2 mb-5">
                    <HeaderContent
                        text="Company Details"
                        subtext="Your company details"
                    />
                </div>
                <div className="col-md-4 text-end">
                    <LinkBack text="Return to home screen" />
                </div>
            </HeaderStepper>
            <BodyStepper className="row fade-in">
                <div className="col-md-4 mx-auto">
                    {/* <InputFile title="Upload your profile picture (Optional)" /> */}
                    <InputBasic
                        id="companyEmail"
                        type="text"
                        placeholder="Company Email Address"
                        outerClassName="mb-3"
                        icon={MailIcons}
                        disabled
                        rules={{
                            function: register,
                            name: 'company_email',
                            rules: {
                                required: 'Company email address is required',
                            },
                            errors,
                        }}
                    />
                    <InputBasic
                        id="companyName"
                        type="text"
                        placeholder="Company Name"
                        outerClassName="mb-5"
                        icon={BuildingIcons}
                        disabled
                        rules={{
                            function: register,
                            name: 'company_name',
                            rules: {
                                required: 'Company name is required',
                            },
                            errors,
                        }}
                    />
                </div>
            </BodyStepper>
        </Fragment>
    );
}
