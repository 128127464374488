import HowToPreviewFile from './Sub/DocumentsNDrawings/HowToPreviewFile';
import HowToShareFile from './Sub/DocumentsNDrawings/HowToShareFile';
import HowToUploadFile from './Sub/DocumentsNDrawings/HowToUploadFile';
import WhatIsDifferentModule from './Sub/DocumentsNDrawings/WhatIsDifferentModule';
import WhatIsDifferentSpace from './Sub/DocumentsNDrawings/WhatIsDifferentSpace';

import AccordionBasic from '@/components/molecules/AccordionBasic';

export default function HelpDocumentsNDrawings(): JSX.Element {
    const lists = [
        {
            id: 'differentModule',
            title: "What is different between Document's and Drawing's",
            content: <WhatIsDifferentModule />,
        },
        {
            id: 'differentSpace',
            title: 'What is different between public space and private space',
            content: <WhatIsDifferentSpace />,
        },
        {
            id: 'uploadFile',
            title: 'How to upload file',
            content: <HowToUploadFile />,
        },
        {
            id: 'shareFile',
            title: 'How to share private file to other user',
            content: <HowToShareFile />,
        },
        {
            id: 'previewFile',
            title: 'How to preview file',
            content: <HowToPreviewFile />,
        },
    ];

    return <AccordionBasic lists={lists} />;
}
