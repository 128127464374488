import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Table, Pagination } from 'react-bootstrap';
import { ThreeDots } from 'react-loader-spinner';

import Card from '@/components/molecules/Card';

import { AiOutlineSearch, AiOutlineHistory } from 'react-icons/ai';

import styled from 'styled-components';

import { RootState } from '@/redux/store';

import collect from 'collect.js';
import PaginationView from '@/components/organism/TableBorderless/PaginationView';

interface DataTempProps {
    key: string;
    isChecked: boolean;
    data: any;
    created_at: number;
    readed: boolean;
    fixed_created_at: string;
    labeled_created_at: string;
}

interface ParamsProps {
    handlePagination: (page: number, type: string) => void;
    params: {
        invited_user: number;
        inhouse_user: number;
    };
    handleCheckFilter: (name: string, value: boolean) => void;
    handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function Histories(props: ParamsProps): JSX.Element {
    const { handlePagination, params, handleCheckFilter, handleSearch } = props;

    const allProjectHistoryState = useSelector(
        (state: RootState) => state.allProjectHistory,
    );

    const detailProjectState = useSelector((state: RootState) => state.detailProject);

    const metaHistory = allProjectHistoryState?.response?.meta;

    const [dataTemp, setDataTemp] = useState<DataTempProps[]>([]);

    // Function for set to state and add is checked attribute
    useEffect(() => {
        if (allProjectHistoryState?.response?.data !== undefined) {
            const temp: Array<{
                id?: number;
                isChecked: boolean;
            }> = allProjectHistoryState?.response?.data?.map((item: any) => {
                return {
                    ...item,
                    isChecked: false,
                };
            });

            // grouping by fixed_created_at as key
            const newData: any = collect(temp)
                .groupBy('fixed_created_at')
                .map((data: any, key: any) => ({
                    key,
                    label: data?.items[0]?.labeled_created_at,
                    isChecked: false,
                    data,
                }))
                .toArray();

            setDataTemp(newData);
        }
    }, [allProjectHistoryState]);

    return (
        <Card classOuter="p-0" classBody="p-0">
            <SearchWrapper className="row g-0">
                <div className="col-lg-12 p-2">
                    <div
                        className="d-flex align-items-center"
                        style={{ paddingLeft: 14 }}
                    >
                        <AiOutlineSearch size={20} color="rgba(131, 131, 131, 1)" />
                        <TableSearch
                            type="text"
                            id="searchNotification"
                            placeholder={`Search ${
                                detailProjectState?.response?.name as string
                            }...`}
                            className="w-100 ms-2"
                            onChange={e => handleSearch?.(e)}
                            // value={searchValue}
                        />
                    </div>
                </div>
            </SearchWrapper>
            <div className="row g-0 p-0">
                <div className="col-lg-12">
                    {allProjectHistoryState?.loading === true ? (
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <ThreeDots
                                height="80"
                                width="80"
                                radius="9"
                                color="#5648FB"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                visible={true}
                            />
                        </div>
                    ) : (
                        <TableWrapper>
                            <Table responsive id="notificationTable">
                                <TableHeader>
                                    <tr>
                                        <td
                                            className="ps-4"
                                            colSpan={2}
                                            style={{
                                                width: '2.5rem',
                                            }}
                                        >
                                            <div className="d-flex">
                                                <Form.Check
                                                    type="checkbox"
                                                    id="InhouseUser"
                                                    className="mb-0 me-2"
                                                    label="Inhouse User"
                                                    checked={params.invited_user === 1}
                                                    onChange={e => {
                                                        handleCheckFilter(
                                                            'invited_user',
                                                            e.target.checked,
                                                        );
                                                    }}
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    id="InvitedUser"
                                                    className="mb-0"
                                                    label="Invited User"
                                                    checked={params.inhouse_user === 1}
                                                    onChange={e => {
                                                        handleCheckFilter(
                                                            'inhouse_user',
                                                            e.target.checked,
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </td>
                                        <th className="text-end fw-semibold pe-4">
                                            {metaHistory?.current_page}
                                            &nbsp;-&nbsp;
                                            {metaHistory?.per_page}
                                            &nbsp;from&nbsp;
                                            {metaHistory?.total}
                                        </th>
                                    </tr>
                                </TableHeader>
                                {dataTemp?.length > 0 ? (
                                    <tbody>
                                        {dataTemp.map((list: any) => (
                                            <Fragment key={list?.key}>
                                                <tr>
                                                    <th
                                                        colSpan={3}
                                                        className="fw-semibold ps-4"
                                                    >
                                                        {list?.label}
                                                    </th>
                                                </tr>
                                                {list?.data?.items?.map((item: any) => (
                                                    <tr key={item.id}>
                                                        <td></td>
                                                        <td>
                                                            <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: item?.title,
                                                                }}
                                                            ></span>
                                                            &nbsp;-&nbsp;
                                                            <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: item?.description,
                                                                }}
                                                            ></span>
                                                        </td>
                                                        <td className="text-end">
                                                            <span
                                                                className="me-4"
                                                                style={{
                                                                    fontSize: 12,
                                                                }}
                                                            >
                                                                {
                                                                    item?.diff_for_humans_created_at
                                                                }
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </Fragment>
                                        ))}
                                    </tbody>
                                ) : (
                                    <tbody
                                        style={{
                                            height: '50vh',
                                        }}
                                    >
                                        <tr>
                                            <td
                                                style={{
                                                    textAlign: 'center',
                                                    border: 'none',
                                                    width: '100%',
                                                }}
                                                colSpan={3}
                                            >
                                                <AiOutlineHistory
                                                    size={50}
                                                    color="rgba(131, 131, 131, 1)"
                                                />
                                                <h6
                                                    className="text-muted"
                                                    style={{ fontSize: 14 }}
                                                >
                                                    No History found
                                                </h6>
                                            </td>
                                        </tr>
                                    </tbody>
                                )}
                            </Table>
                        </TableWrapper>
                    )}

                    <PaginationView
                        items={allProjectHistoryState?.response}
                        handlePagination={handlePagination}
                        withoutPagination={false}
                    />
                </div>
            </div>
        </Card>
    );
}

const TableSearch = styled.input`
    background-color: transparent;
    border: none;
    height: 2rem;
    font-family: 'Montserrat', sans-serif;

    &::placeholder {
        color: #303030;
        font-size: 14px;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }
`;

const TableWrapper = styled.div`
    color: #303030;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;

    th,
    td {
        vertical-align: middle;
        height: 50px;
    }

    .table {
        margin-bottom: 0;
    }

    @media (min-width: 768px) {
        .table-responsive {
            overflow-x: inherit;
            overflow-y: inherit !important;
        }
    }
`;

const TableHeader = styled.thead`
    font-weight: 500;
`;

const SearchWrapper = styled.div`
    border-bottom: 1px solid #dee2e6;
`;
