export const FORGOT_PASSWORD_EMAIL_REQUEST = 'FORGOT_PASSWORD_EMAIL_REQUEST';
export const FORGOT_PASSWORD_EMAIL_SUCCESS = 'FORGOT_PASSWORD_EMAIL_SUCCESS';
export const FORGOT_PASSWORD_EMAIL_FAIL = 'FORGOT_PASSWORD_EMAIL_FAIL';

export const FORGOT_PASSWORD_PHONE_REQUEST = 'FORGOT_PASSWORD_PHONE_REQUEST';
export const FORGOT_PASSWORD_PHONE_SUCCESS = 'FORGOT_PASSWORD_PHONE_SUCCESS';
export const FORGOT_PASSWORD_PHONE_FAIL = 'FORGOT_PASSWORD_PHONE_FAIL';

export const RESET_PASSWORD_EMAIL_REQUEST = 'RESET_PASSWORD_EMAIL_REQUEST';
export const RESET_PASSWORD_EMAIL_SUCCESS = 'RESET_PASSWORD_EMAIL_SUCCESS';
export const RESET_PASSWORD_EMAIL_FAIL = 'RESET_PASSWORD_EMAIL_FAIL';

export const RESET_PASSWORD_PHONE_REQUEST = 'RESET_PASSWORD_PHONE_REQUEST';
export const RESET_PASSWORD_PHONE_SUCCESS = 'RESET_PASSWORD_PHONE_SUCCESS';
export const RESET_PASSWORD_PHONE_FAIL = 'RESET_PASSWORD_PHONE_FAIL';
export const RESET_PASSWORD_PHONE_RESET = 'RESET_PASSWORD_PHONE_RESET';

export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL';

export const CLEAR_STATES = 'CLEAR_STATES';

const initialState = {
    loading: false,
    response: null,
    error: null,
    status: null,
};

export const forgotPasswordEmailReducer = (
    state: object = initialState,
    action: any,
): any => {
    switch (action.type) {
        case FORGOT_PASSWORD_EMAIL_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case FORGOT_PASSWORD_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                error: null,
                status: 200,
            };

        case FORGOT_PASSWORD_EMAIL_FAIL:
            return {
                ...state,
                loading: false,
                response: null,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATES:
            return {
                initialState,
            };

        default:
            return state;
    }
};

export const forgotPasswordPhoneReducer = (
    state: object = initialState,
    action: any,
): any => {
    switch (action.type) {
        case FORGOT_PASSWORD_PHONE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case FORGOT_PASSWORD_PHONE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                error: null,
                status: 200,
            };

        case FORGOT_PASSWORD_PHONE_FAIL:
            return {
                ...state,
                loading: false,
                response: null,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATES:
            return {
                initialState,
            };

        default:
            return state;
    }
};

export const resetPasswordEmailReducer = (
    state: object = initialState,
    action: any,
): any => {
    switch (action.type) {
        case RESET_PASSWORD_EMAIL_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case RESET_PASSWORD_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                error: null,
                status: 200,
            };

        case RESET_PASSWORD_EMAIL_FAIL:
            return {
                ...state,
                loading: false,
                response: null,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATES:
            return {
                initialState,
            };

        default:
            return state;
    }
};

export const resetPasswordPhoneReducer = (
    state: object = initialState,
    action: any,
): any => {
    switch (action.type) {
        case RESET_PASSWORD_PHONE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case RESET_PASSWORD_PHONE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                error: null,
                status: 200,
            };

        case RESET_PASSWORD_PHONE_FAIL:
            return {
                ...state,
                loading: false,
                response: null,
                error: action.payload,
                status: 400,
            };

        case RESET_PASSWORD_PHONE_RESET:
            return initialState;

        case CLEAR_STATES:
            return {
                initialState,
            };

        default:
            return state;
    }
};

export const verifyOTPReducer = (state: object = initialState, action: any): any => {
    switch (action.type) {
        case VERIFY_OTP_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case VERIFY_OTP_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                error: null,
                status: 200,
            };

        case VERIFY_OTP_FAIL:
            return {
                ...state,
                loading: false,
                response: null,
                error: action.payload,
                status: 400,
            };

        case CLEAR_STATES:
            return {
                initialState,
            };

        default:
            return state;
    }
};
