import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import TableBorderless from '@/components/organism/TableBorderless';
import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';

import {
    getAllMemberInvited,
    getDetailMemberInvited,
    clearStateDetailMemberInvited,
} from '@/routes/Dashboard/Project/redux/actions';
import {
    kickMemberProject,
    awardingMember,
    nullifyMember,
    updateMemberPermission,
    clearStateKickMemberProject,
    clearStateAwardingNullifyMember,
} from '../../redux/actions';

import { RootState } from '@/redux/store';

import { currentProjectAccess, generateInitialImageUrl } from '@/utility/Utils';

export default function ManageProjectInvited(props: any): JSX.Element {
    const {
        setModal,
    }: {
        setParams: (params: boolean) => void;
        setModal: (modal: object) => void;
    } = props;

    const dispatch = useDispatch();

    const { id } = useParams();
    const projectId: string = id ?? '';

    const projectAccess = currentProjectAccess();

    const summaryAccess = projectAccess?.summary;

    const allMemberInvitedState = useSelector(
        (state: RootState) => state.allMemberInvited,
    );
    const detailMemberInvitedState = useSelector(
        (state: RootState) => state.detailMemberInvited,
    );
    const kickMemberProjectState = useSelector(
        (state: RootState) => state.kickMemberProject,
    );

    const awardingNullifyMemberState = useSelector(
        (state: RootState) => state.awardingNullifyMember,
    );

    const [searchValue, setSearchValue] = useState('');
    const [selectedInvited, setSelectedInvited] = useState<[]>([]);
    const [modalType, setModalType] = useState<string>('');
    const [params, setParams] = useState({
        project_id: id,
        page: 1,
        per_page: 10,
        search: '',
        sort_by: '',
        sort_asc: 1,
    });

    const [modalChild, setModalChild] = useState({
        danger: false,
        success: false,
        award: false,
        nullify: false,
    });

    const [accessTemp, setAccessTemp] = useState<{
        schedule: {
            can_invite_schedule: boolean;
            construction: {
                can_collaborate_schedule: boolean;
                can_view_schedule: boolean;
                disable_option: number;
            };
            design: {
                can_collaborate_schedule: boolean;
                can_view_schedule: boolean;
                disable_option: number;
            };
        };
        summary: {
            collaborate: boolean;
            view: boolean;
            invite: boolean;
        };
        document: {
            collaborate: boolean;
            view: boolean;
            invite: boolean;
            post_publicly: boolean;
        };
        drawing: {
            collaborate: boolean;
            view: boolean;
            invite: boolean;
            post_publicly: boolean;
        };
    }>({
        schedule: {
            can_invite_schedule: false,
            construction: {
                can_collaborate_schedule: false,
                can_view_schedule: false,
                disable_option: 0,
            },
            design: {
                can_collaborate_schedule: false,
                can_view_schedule: false,
                disable_option: 0,
            },
        },
        summary: { collaborate: false, view: false, invite: false },
        document: {
            collaborate: false,
            view: false,
            invite: false,
            post_publicly: false,
        },
        drawing: {
            collaborate: false,
            view: false,
            invite: false,
            post_publicly: false,
        },
    });

    const tableItems = [
        {
            title: 'Name',
            Cell: (row: { name: string; avatar: string; email: string }) => (
                <div className="d-flex align-items-center">
                    <div className="me-3">
                        <img
                            src={row.avatar ?? generateInitialImageUrl(row.name)}
                            alt="cover"
                            style={{
                                width: 40,
                                height: 40,
                                borderRadius: 100,
                                objectFit: 'cover',
                            }}
                        />
                    </div>
                    <div>
                        <p className="mb-0">{row.name}</p>
                        <p
                            className="text-muted mb-0"
                            style={{
                                fontSize: 12,
                            }}
                        >
                            {row.email}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Company',
            selector: '',
            Cell: (row: any) => <span>{row.company.company_name}</span>,
        },
        {
            title: 'Phone',
            selector: 'phone',
        },
    ];

    /**
     * Function to handle search
     *
     * @param string value
     * @returns void
     */
    const handleSearch = useCallback((value: string): void => {
        setSearchValue(value);
    }, []);

    /**
     * Function to handle debounce search
     *
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleDebouncedSearch = useCallback(() => {
        setParams({
            ...params,
            search: searchValue,
            page: 1,
        });

        const payload = {
            ...params,
            search: searchValue,
            page: 1,
        };

        dispatch(getAllMemberInvited(payload));
    }, [searchValue]);

    /**
     * Function to handle kick of member
     *
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleKickMember = (): void => {
        const payload = selectedInvited.map((item: any) => item.email);

        dispatch(kickMemberProject(projectId, payload.toString()));
    };

    /**
     * Function to handle awarding of member
     *
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleAwardingMember = (): void => {
        const payload = selectedInvited.map((item: any) => item.email);
        const projectId: string = id ?? '';

        setModalType('awarding');

        dispatch(awardingMember(projectId, payload.toString()));
    };

    /**
     * Function to handle nullify of member
     *
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handleNullifyMember = (): void => {
        const payload = selectedInvited.map((item: any) => item.email);
        const projectId: string = id ?? '';

        setModalType('nullify');

        dispatch(nullifyMember(projectId, payload.toString()));
    };

    /**
     * Function to handle pagination
     *
     * @param number page
     * @param string type
     * @returns void
     * @see cypress/e2e/dashboards/settings/companySetting.cy.ts - To cypress test
     */
    const handlePagination = (page: number, type: string): void => {
        setSelectedInvited([]);
        if (type === 'page') {
            setParams({
                ...params,
                page,
            });

            const payload = {
                ...params,
                page,
            };

            dispatch(getAllMemberInvited(payload));
        }

        if (type === 'next') {
            setParams({
                ...params,
                page: params.page + 1,
            });

            const payload = {
                ...params,
                page: params.page + 1,
            };

            dispatch(getAllMemberInvited(payload));
        }

        if (type === 'prev') {
            setParams({
                ...params,
                page: params.page - 1,
            });

            const payload = {
                ...params,
                page: params.page - 1,
            };

            dispatch(getAllMemberInvited(payload));
        }
    };

    /**
     * Function for handle switch access
     *
     * @param boolean value
     * @param string type
     * @param string access
     * @returns void
     */
    const handleProjectAccess = (
        value: boolean,
        type: string,
        access: string,
        space?: any,
    ): void => {
        const newAccessTemp: any = { ...accessTemp };

        if (type === 'schedule') {
            if (access === 'can_invite_schedule') {
                newAccessTemp.schedule.can_invite_schedule = value;
            } else {
                newAccessTemp[type][space][access] = value;

                if (access === 'can_collaborate_schedule') {
                    newAccessTemp[type][space].can_view_schedule = !value;
                } else if (access === 'can_view_schedule') {
                    newAccessTemp[type][space].can_collaborate_schedule = !value;
                } else if (access === 'noAccess') {
                    newAccessTemp[type][space] = {
                        ...newAccessTemp[type][space],
                        can_collaborate_schedule: false,
                        can_view_schedule: false,
                    };
                }
            }
        } else {
            newAccessTemp[type as keyof typeof newAccessTemp] = {
                ...accessTemp[type as keyof typeof accessTemp],
                [access]: value,
            };

            if (access === 'collaborate') {
                newAccessTemp[type].view = !value;
            } else if (access === 'view') {
                newAccessTemp[type].collaborate = !value;
            } else if (access === 'remove') {
                if (type === 'document' || type === 'drawing') {
                    newAccessTemp[type] = {
                        collaborate: false,
                        view: false,
                        invite: false,
                        post_publicly: false,
                    };
                } else {
                    newAccessTemp[type] = {
                        collaborate: false,
                        view: false,
                        invite: false,
                    };
                }
            }
        }

        setAccessTemp(newAccessTemp);
    };

    /**
     * Function for handle save permissions
     *
     * @returns void
     */
    const handleSavePermissions = (): void => {
        const payload: {
            project_id: string;
            email: string;
        } = {
            project_id: projectId,
            email: detailMemberInvitedState?.response?.email,
        };

        const updatedAccessTemp = {
            can_collaborate_summary: accessTemp.summary.collaborate ? 1 : 0,
            can_view_summary: accessTemp.summary.view ? 1 : 0,
            can_invite_summary: accessTemp.summary.invite ? 1 : 0,
            can_collaborate_document: accessTemp.document.collaborate ? 1 : 0,
            can_view_document: accessTemp.document.view ? 1 : 0,
            can_invite_document: accessTemp.document.invite ? 1 : 0,
            can_post_publicly_document: accessTemp.document.post_publicly ? 1 : 0,
            can_collaborate_drawing: accessTemp.drawing.collaborate ? 1 : 0,
            can_view_drawing: accessTemp.drawing.view ? 1 : 0,
            can_invite_drawing: accessTemp.drawing.invite ? 1 : 0,
            can_post_publicly_drawing: accessTemp.drawing.post_publicly ? 1 : 0,
            can_invite_schedule: accessTemp.schedule.can_invite_schedule ? 1 : 0,
            member_space_schedules: [
                {
                    can_collaborate_schedule: accessTemp?.schedule?.design
                        ?.can_collaborate_schedule
                        ? 1
                        : 0,
                    can_view_schedule: accessTemp?.schedule?.design?.can_view_schedule
                        ? 1
                        : 0,
                    space_type: 'design',
                },
                {
                    can_collaborate_schedule: accessTemp?.schedule?.construction
                        ?.can_collaborate_schedule
                        ? 1
                        : 0,
                    can_view_schedule: accessTemp?.schedule?.construction
                        ?.can_view_schedule
                        ? 1
                        : 0,
                    space_type: 'construction',
                },
            ],
        };

        dispatch(updateMemberPermission(payload, updatedAccessTemp));
    };

    // Function for reset state when component unmount
    // useEffect(() => {
    //     return () => {
    //         setSelectedInvited([]);
    //         dispatch(clearStateDetailMemberInvited());
    //     };
    // }, []);

    // Function for handle if activated inhouse is clicked then get detail
    useEffect(() => {
        if (selectedInvited.length > 0) {
            const payload = selectedInvited.map((item: any) => item.id).pop();

            dispatch(
                getDetailMemberInvited({
                    project_id: id,
                    id: payload.toString(),
                }),
            );
        } else {
            dispatch(clearStateDetailMemberInvited());
        }
    }, [selectedInvited]);

    // Function for handle if delete user success, then refresh data
    useEffect(() => {
        if (kickMemberProjectState?.status === 200) {
            setModalChild(prev => ({
                ...prev,
                danger: false,
            }));

            dispatch(getAllMemberInvited(params));

            setTimeout(() => {
                setSelectedInvited([]);
                dispatch(clearStateKickMemberProject());
                setModal((prev: object) => ({
                    ...prev,
                    successDelete: true,
                }));
            }, 1000);
        }
    }, [kickMemberProjectState]);

    // Function if awarding or nullify success then refresh data
    useEffect(() => {
        if (awardingNullifyMemberState?.status === 200) {
            const payload = selectedInvited.map((item: any) => item.id).pop();

            setModalChild(prev => ({
                ...prev,
                award: false,
                nullify: false,
            }));

            setTimeout(() => {
                dispatch(
                    getDetailMemberInvited({
                        project_id: id,
                        id: payload.toString(),
                    }),
                );
                dispatch(clearStateAwardingNullifyMember());
                setModalChild(prev => ({
                    ...prev,
                    success: true,
                }));
            }, 1000);
        }
    }, [awardingNullifyMemberState]);

    // Function for handle debounce search
    useEffect(() => {
        const debounce = setTimeout(() => {
            handleDebouncedSearch();
        }, 500);

        return () => {
            clearTimeout(debounce);
        };
    }, [handleDebouncedSearch]);

    // Function for handle if update member permission success then set to state
    useEffect(() => {
        if (detailMemberInvitedState !== undefined) {
            setAccessTemp(detailMemberInvitedState?.response?.access);
        }
    }, [detailMemberInvitedState]);

    return (
        <>
            <TableBorderless
                withoutBorder
                header={tableItems}
                items={allMemberInvitedState?.response ?? []}
                loading={allMemberInvitedState?.loading}
                selected={selectedInvited}
                detailsState={detailMemberInvitedState?.response ?? null}
                detailsLoading={detailMemberInvitedState?.loading}
                handlePagination={handlePagination}
                handleSearch={handleSearch}
                setSelected={setSelectedInvited}
                handleProjectAccess={handleProjectAccess}
                projectAccessState={accessTemp}
                moduleType="Invited User"
                actionTitle="Delete selected invited user"
                actionClass="btn-outline-danger"
                addTitle="Invite User"
                withDetail="user"
                withAdd={summaryAccess?.invite}
                withAction={summaryAccess?.collaborate}
                withProjectAccess={true}
                withAwardNullify
                handleAward={() => setModalChild(prev => ({ ...prev, award: true }))}
                handleNullify={() => setModalChild(prev => ({ ...prev, nullify: true }))}
                handleAction={() => setModalChild(prev => ({ ...prev, danger: true }))}
                handleAdd={() => setModal((prev: object) => ({ ...prev, invite: true }))}
                handleSavePermissions={handleSavePermissions}
            />
            <ModalDashboard
                modal={modalChild.danger}
                setModal={setModalChild}
                variant="danger"
                type="delete"
                title="Delete Invited User?"
                body="Are you sure want to delete this invited user?"
                withCancel
                onConfirm={handleKickMember}
                loading={kickMemberProjectState?.loading}
            />
            <ModalDashboard
                modal={modalChild.award}
                setModal={setModalChild}
                variant="primary"
                type="caution"
                title="Awarding Invited User?"
                body="Are you sure want to awarding this invited user?"
                withCancel
                onConfirm={handleAwardingMember}
                loading={awardingNullifyMemberState?.loading}
            />
            <ModalDashboard
                modal={modalChild.success}
                setModal={setModalChild}
                variant="success"
                type="success"
                title="Success!"
                body={`Successfully ${modalType} user to project`}
                onConfirm={() => {
                    setModalChild(prev => ({ ...prev, success: false }));
                }}
            />

            <ModalDashboard
                modal={modalChild.nullify}
                setModal={setModalChild}
                variant="danger"
                type="terminate"
                title="Nullify Invited User?"
                body="Are you sure want to nullify this invited user?"
                withCancel
                onConfirm={handleNullifyMember}
                loading={awardingNullifyMemberState?.loading}
            />
        </>
    );
}
