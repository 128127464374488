import ModalAction from '@/components/atoms/Modals/ModalAction';
import { useState } from 'react';
import styled from 'styled-components';
import InputDateRangeHeaderNew from '@/components/atoms/Inputs/InputDateRangeHeaderNew';
import InputBasic from '@/components/atoms/Inputs/InputBasic';
import { AiOutlineLink } from 'react-icons/ai';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function ModalDrawingShareLink({
    modal,
    setModal,
    selected,
}: {
    modal: boolean;
    setModal: (modal: boolean) => void;
    selected: string[];
}): JSX.Element {
    const [isExpired, setIsExpired] = useState(false);
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
        new Date(),
        new Date(),
    ] as unknown as [Date, Date]);

    const handleChangeDateRange = (dateRange: [Date | null, Date | null]): void => {
        setDateRange(dateRange);
        // setMaxMinDate(dateRange);
    };

    const handleCopyToClipboard = (text: string): void => {
        console.log('Copied to clipboard: ', text);
    };

    return (
        <ModalAction
            modal={modal}
            setModal={setModal}
            aria-labelledby="contained-modal-title-vcenter"
            title={'Share Drawing Link'}
            size={'md'}
        >
            <div className="d-flex flex-column p-3 gap-3">
                <div className="d-flex justify-content-between">
                    <div className="form-check d-flex align-items-center gap-2">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="inputDate"
                            checked={isExpired}
                            onChange={() => setIsExpired(!isExpired)}
                        />
                        <label
                            className="form-check-label mt-1"
                            style={{ fontSize: '14px' }}
                            htmlFor="inputDate"
                        >
                            Add expiration date
                        </label>
                    </div>
                    {isExpired && (
                        <InputDateRangeHeaderNew
                            onChange={val => handleChangeDateRange(val)}
                            value={dateRange as [Date, Date]}
                            minDate={new Date()}
                            required
                            placement="right"
                            customInput={
                                <div className="p-0 d-flex align-items-center">
                                    <span
                                        style={{
                                            paddingLeft: 4,
                                        }}
                                    >
                                        {dateRange[0]?.toLocaleDateString()} -{' '}
                                        {dateRange[1]?.toLocaleDateString()}
                                    </span>
                                </div>
                            }
                        />
                    )}
                </div>
                <div className="position-relative d-flex align-items-end gap-2">
                    <InputBasicCustom
                        placeholder="Link"
                        value="https://www.google.com"
                        outerClassName="mb-1"
                        readOnly
                    />
                    <CopyToClipboard
                        text={'aweeaweawe'}
                        onCopy={() => handleCopyToClipboard('asd')}
                    >
                        <ButtonCopyLink>
                            <AiOutlineLink size={20} />
                        </ButtonCopyLink>
                    </CopyToClipboard>
                </div>
            </div>
        </ModalAction>
    );
}

const InputBasicCustom = styled(InputBasic)`
    input .form-control {
        font-size: 14px !important;
    }
`;

const ButtonCopyLink = styled.button`
    border: none;
    padding: 4px;
    background-color: transparent;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    transform: translateY(50%);

    &:hover {
        color: #5648fb;
    }
`;
