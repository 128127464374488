import chatImage1 from '@/assets/images/chat/help-chat-image-1.webp';
import chatImage2 from '@/assets/images/chat/help-chat-image-2.webp';
import chatImage3 from '@/assets/images/chat/help-chat-image-3.webp';

import { Fragment } from 'react';

export default function HowToChat(): JSX.Element {
    const contents = [
        {
            id: 1,
            text: 'You can chat with other user by clicking the chat button on the sidebar, and click new direct message',
            alt: 'chatPage',
            image: chatImage1,
        },
        {
            id: 2,
            text: 'After that you can select the user that you want to chat',
            alt: 'chatPage',
            image: chatImage2,
        },
        {
            id: 3,
            text: 'You will be redirected to the chat page, and you can start chat with the user',
            alt: 'chatPage',
            image: chatImage3,
        },
    ];

    return (
        <Fragment>
            {contents.map((content, index) => (
                <div key={index} className="">
                    {content.image.length > 0 && (
                        <img
                            src={content.image}
                            alt={content.alt}
                            className="img-fluid"
                        />
                    )}
                    <p className="mt-4 pl-2">{content.text}</p>
                </div>
            ))}
        </Fragment>
    );
}
