import { axiosClient } from '@/services/GatewayService';
import { AppDispatch } from '@/redux/store';

import {
    ALL_TENDER_BOX_REQUEST,
    ALL_TENDER_BOX_SUCCESS,
    ALL_TENDER_BOX_FAIL,
    // ALL_TENDER_BOX_RESET,
    ALL_PROJECT_SECTOR_REQUEST,
    ALL_PROJECT_SECTOR_SUCCESS,
    ALL_PROJECT_SECTOR_FAIL,
    // ALL_PROJECT_SECTOR_RESET,
    ALL_PROJECT_LOCATION_REQUEST,
    ALL_PROJECT_LOCATION_SUCCESS,
    ALL_PROJECT_LOCATION_FAIL,
    // ALL_PROJECT_LOCATION_RESET,
    ALL_TENDER_INTENTION_REQUEST,
    ALL_TENDER_INTENTION_SUCCESS,
    ALL_TENDER_INTENTION_FAIL,
    // ALL_TENDER_INTENTION_RESET,
    ALL_PROJECT_RANGE_VALUE_REQUEST,
    ALL_PROJECT_RANGE_VALUE_SUCCESS,
    ALL_PROJECT_RANGE_VALUE_FAIL,
    // ALL_PROJECT_RANGE_VALUE_RESET,
    EDIT_PROJECT_TENDER_REQUEST,
    EDIT_PROJECT_TENDER_SUCCESS,
    EDIT_PROJECT_TENDER_FAIL,
    EDIT_PROJECT_TENDER_RESET,
    OPEN_PROJECT_TO_TENDER_REQUEST,
    OPEN_PROJECT_TO_TENDER_SUCCESS,
    OPEN_PROJECT_TO_TENDER_FAIL,
    OPEN_PROJECT_TO_TENDER_RESET,
    CLOSE_PROJECT_TO_TENDER_REQUEST,
    CLOSE_PROJECT_TO_TENDER_SUCCESS,
    CLOSE_PROJECT_TO_TENDER_FAIL,
    CLOSE_PROJECT_TO_TENDER_RESET,
    SEND_TENDER_REQUEST,
    SEND_TENDER_SUCCESS,
    SEND_TENDER_FAIL,
    SEND_TENDER_RESET,
    APPROVE_TENDER_REQUEST,
    APPROVE_TENDER_SUCCESS,
    APPROVE_TENDER_FAIL,
    APPROVE_TENDER_RESET,
    REJECT_TENDER_REQUEST,
    REJECT_TENDER_SUCCESS,
    REJECT_TENDER_FAIL,
    REJECT_TENDER_RESET,
    CLEAR_STATE,
} from '../reducers';

/**
 * Function to get of notification setting
 *
 * @return Promise<void>
 */
export const getAllTenderBox = (params: {
    min_value?: number;
    max_value?: number;
    range?: string;
    location?: string;
    sector?: string;
    sort_by?: string;
    sort_asc?: number;
    search: string;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_TENDER_BOX_REQUEST });
        try {
            const res = await axiosClient.get('project/tenders/list', {
                params,
            });

            dispatch({
                type: ALL_TENDER_BOX_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_TENDER_BOX_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all project sector
 *
 * @return Promise<void>
 */
export const getAllProjectSector = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_PROJECT_SECTOR_REQUEST });
        try {
            const res = await axiosClient.get('project/tenders/sectors');

            dispatch({
                type: ALL_PROJECT_SECTOR_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_PROJECT_SECTOR_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all project location
 *
 * @return Promise<void>
 */
export const getAllProjectLocation = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_PROJECT_LOCATION_REQUEST });
        try {
            const res = await axiosClient.get('project/tenders/locations');

            dispatch({
                type: ALL_PROJECT_LOCATION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_PROJECT_LOCATION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all tender intention
 *
 * @return Promise<void>
 */
export const getAllTenderIntention = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_TENDER_INTENTION_REQUEST });
        try {
            const res = await axiosClient.get('project/tenders/intentions');

            dispatch({
                type: ALL_TENDER_INTENTION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_TENDER_INTENTION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to edit project to tender
 *
 * @param FormData body
 * @return Promise<void>
 */
export const editProjectTender = (body: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: EDIT_PROJECT_TENDER_REQUEST });
        try {
            const res = await axiosClient.post('project/tenders/open', body);

            dispatch({
                type: EDIT_PROJECT_TENDER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: EDIT_PROJECT_TENDER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to open project to tender
 *
 * @param FormData body
 * @return Promise<void>
 */
export const openProjectToTender = (body: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: OPEN_PROJECT_TO_TENDER_REQUEST });
        try {
            const res = await axiosClient.post('project/tenders/open', body);

            dispatch({
                type: OPEN_PROJECT_TO_TENDER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: OPEN_PROJECT_TO_TENDER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to close project to tender
 *
 * @return Promise<void>
 */
export const closeProjectToTender = (params: { project_id: number }): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CLOSE_PROJECT_TO_TENDER_REQUEST });
        try {
            const res = await axiosClient.delete('project/tenders/close', {
                params,
            });

            dispatch({
                type: CLOSE_PROJECT_TO_TENDER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: CLOSE_PROJECT_TO_TENDER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all project range value
 *
 * @return Promise<void>
 */
export const getAllProjectRangeValue = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_PROJECT_RANGE_VALUE_REQUEST });
        try {
            const res = await axiosClient.get('project/tenders/range-values');

            dispatch({
                type: ALL_PROJECT_RANGE_VALUE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_PROJECT_RANGE_VALUE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to send application tender
 *
 * @return Promise<void>
 */
export const sendTender = (formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SEND_TENDER_REQUEST });
        try {
            const res = await axiosClient.post('project/tenders/applicant', formData);

            dispatch({
                type: SEND_TENDER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: SEND_TENDER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to approve tender
 *
 * @return Promise<void>
 */
export const approveTender = (formData: object): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: APPROVE_TENDER_REQUEST });
        try {
            const res = await axiosClient.put('project/tenders/approve', formData);

            dispatch({
                type: APPROVE_TENDER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: APPROVE_TENDER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to reject tender
 *
 * @return Promise<void>
 */
export const rejectTender = (params: object): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: REJECT_TENDER_REQUEST });
        try {
            const res = await axiosClient.delete('project/tenders/reject', {
                params,
            });

            dispatch({
                type: REJECT_TENDER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: REJECT_TENDER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to clear state
 *
 * @return void
 */
export const clearState = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CLEAR_STATE });
    };
};

export const clearEditProjectTenderState = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: EDIT_PROJECT_TENDER_RESET });
    };
};

export const clearOpenProjectToTenderState = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: OPEN_PROJECT_TO_TENDER_RESET });
    };
};

export const clearCloseProjectToTenderState = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CLOSE_PROJECT_TO_TENDER_RESET });
    };
};

export const clearSendTenderState = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SEND_TENDER_RESET });
    };
};

export const clearApproveTenderState = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: APPROVE_TENDER_RESET });
    };
};

export const clearRejectTenderState = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: REJECT_TENDER_RESET });
    };
};
