import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentProfile } from '../../Setting/PersonalSetting/redux/actions';

import { getWeather } from '../redux/actions';

import styled from 'styled-components';
import { RootState } from '@/redux/store';
import AdvertisementBanner from '@/components/molecules/AdvertisementBanner';
// import WeatherBanner from '@/components/molecules/WeatherBanner';
import NotificationGeneralBanner from '@/components/molecules/NotificationGeneralBanner';
import Loader from '@/components/molecules/Loader';

export default function Dashboard(): JSX.Element {
    const dispatch = useDispatch();

    const currentProfileState = useSelector((state: RootState) => state.currentProfile);
    const weatherState = useSelector((state: RootState) => state.weather);
    const ipState = useSelector((state: RootState) => state.ip);

    const trialSubscription: boolean = currentProfileState?.response?.subscription?.trial;

    // get Ip then get weather and profile
    useEffect(() => {
        dispatch(getCurrentProfile());
    }, []);

    useEffect(() => {
        if (ipState?.status === 200) {
            dispatch(getWeather(ipState?.response?.ipString));
        }
    }, [ipState]);

    return (
        <WrapperRow>
            {trialSubscription &&
                currentProfileState?.response?.role?.name === 'Company User' && (
                    <div className="col-md-12 col-xl-8">
                        <TrialBadge
                            className="badge bg-warning rounded fw-semibold text-start text-white shadow-md"
                            style={{
                                fontSize: 12,
                            }}
                        >
                            You are using a trial version of the app. Check your {''}
                            <Link
                                to="/setting/subscription/change-card"
                                className="link-info fw-semibold text-decoration-none"
                            >
                                subscription
                            </Link>
                        </TrialBadge>
                    </div>
                )}
            <div className="row equal-cols">
                <div className="col-md-12 col-xl-8 px-2 px-xl-5 mt-3">
                    <AdvertisementBanner />
                </div>
                {weatherState?.loading !== true && (
                    <div className="col-md-12 col-xl-4 px-2 px-xl-5 mt-3">
                        {/* <WeatherBanner /> */}
                        <NotificationGeneralBanner />
                    </div>
                )}
            </div>
            {currentProfileState.loading === true && <Loader />}
        </WrapperRow>
    );
}

const WrapperRow = styled.div`
    .row.equal-cols {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .row.equal-cols:before,
    .row.equal-cols:after {
        display: block;
    }

    .row.equal-cols > [class*='col-'] {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .row.equal-cols > [class*='col-'] > * {
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    }

    @media (min-width: 1200px) and (max-width: 1800px) {
        .px-xl-5 {
            padding: 0 15px !important;
        }
    }
`;

const TrialBadge = styled.span`
    @media screen and (max-width: 768px) {
        white-space: normal;
        line-height: 1.5;
    }
`;
