import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import ModalAction from '@/components/atoms/Modals/ModalAction';
import InputBasic from '@/components/atoms/Inputs/InputBasic';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';

import { AiOutlineCloudUpload } from 'react-icons/ai';

import { createProject, clearStateCreateProject } from '../redux/actions';

import styled from 'styled-components';
import InputCurrency from '@/components/atoms/Inputs/InputCurrency';
import { RootState } from '@/redux/store';
import {
    getAllProjectLocation,
    getAllProjectSector,
    getAllTenderIntention,
} from '../../TenderBox/redux/actions';
import InputSelectControlled from '@/components/atoms/Inputs/InputSelectControlled';

interface ParamsProps {
    modal: boolean;
    setModal: (modal: any) => void;
}

export default function AddProject(props: ParamsProps): JSX.Element {
    const { modal, setModal } = props;

    const {
        register,
        handleSubmit,
        reset,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm<any>({
        mode: 'onChange',
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [projectName, setProjectName] = useState('Upload Project Image');
    const [projectImage, setProjectImage] = useState('');
    const [modalChild, setModalChild] = useState({
        success: false,
    });
    const [imageError, setImageError] = useState(false);
    const [sectorLists, setSectorLists] = useState<
        Array<{
            value: string;
            label: string;
        }>
    >([]);
    const [locationLists, setLocationLists] = useState<
        Array<{
            value: string;
            label: string;
        }>
    >([]);
    const createProjectState = useSelector((state: RootState) => state.createProject);
    const allProjectSectorState = useSelector(
        (state: RootState) => state.allProjectSector,
    );
    const allProjectLocationState = useSelector(
        (state: RootState) => state.allProjectLocation,
    );

    const inputFileRef = useRef<HTMLInputElement>(null);

    // Function to get all tender intention, project sector and location
    useEffect(() => {
        dispatch(getAllTenderIntention());
        dispatch(getAllProjectSector());
        dispatch(getAllProjectLocation());
        setValue('location', 'VIC, AUS');
        setValue('sector', 'Residential');
    }, []);

    // Function to handle modal, when pin or unpin project
    useEffect(() => {
        if (createProjectState?.status === 200) {
            setModal(false);

            setModalChild(prev => ({
                ...prev,
                success: true,
            }));
        }
    }, [createProjectState]);

    // Function to get all project sector
    useEffect(() => {
        if (allProjectSectorState?.status === 200) {
            const sectorList = allProjectSectorState?.response?.map((sector: string) => ({
                value: sector,
                label: sector,
            }));

            setSectorLists(sectorList);
        }
    }, [allProjectSectorState]);

    // Function to get all project location
    useEffect(() => {
        if (allProjectLocationState?.status === 200) {
            const locationList = allProjectLocationState?.response?.map(
                (location: string) => ({
                    value: location,
                    label: location,
                }),
            );

            setLocationLists(locationList);
        }
    }, [allProjectLocationState]);

    /**
     * Function on submit
     *
     * @param any data
     * @return void
     * @see cypress/e2e/dashboard/Project/project.cy.ts
     *      To cypress unit tester
     */
    const onSubmit = (data: any): void => {
        // initialize form data
        const payload: any = new FormData();

        // append data to form data
        for (const key in data) {
            payload.append(key, data[key as keyof typeof data]);
        }

        // Remove $ from value
        payload.set('value', payload.get('value')?.replace(/\$/g, ''));

        // initialize separator
        const separator: boolean = payload.get('value')?.includes(',');

        // Remove , from value
        if (separator) {
            payload.set('value', payload.get('value')?.replace(/,/g, ''));
        }

        // append image to form data
        if (projectImage !== '') {
            payload.append('picture', projectImage);
        }

        // Dispatch login action with form data as payload
        dispatch(createProject(payload));
    };

    /**
     * Function to handle upload image
     *
     * @return void
     */
    const handleUpload = (): void => {
        if (inputFileRef.current !== undefined && inputFileRef.current !== null) {
            inputFileRef.current.click();

            inputFileRef.current.onchange = (e: any) => {
                const file = e.target.files[0];
                const fileName = e.target.files[0].name;

                const fileSize = file.size / 1024 / 1024; // Convert file size to MB

                if (file !== undefined && fileSize <= 2) {
                    setProjectName(fileName);
                    setProjectImage(file);
                    setImageError(false);
                } else {
                    setImageError(true);
                }
            };
        }
    };

    /**
     * Function to redirect to project dashboard summary
     *
     * @return void
     */
    const handleConfirmModal = (): void => {
        // reset input
        reset();

        // navigate to project dashboard summary
        navigate(`/project/${createProjectState?.response?.id as number}/summary`);

        // close modal
        setModalChild(prev => ({
            ...prev,
            success: false,
        }));

        // clear state
        dispatch(clearStateCreateProject());
    };

    return (
        <>
            <ModalAction
                modal={modal}
                setModal={setModal}
                aria-labelledby="contained-modal-title-vcenter"
                title="Create New Project"
                size="lg"
            >
                <form action="#" onSubmit={handleSubmit(onSubmit)} method="post">
                    <Row className="row">
                        <div className="col-md-6">
                            <InputBasic
                                id="projectName"
                                type="text"
                                placeholder="Project Name"
                                innerClassName="my-0"
                                outerClassName="px-4 mt-3"
                                rules={{
                                    function: register,
                                    name: 'name',
                                    rules: {
                                        required: 'Project name is required',
                                    },
                                    errors,
                                }}
                            />
                            <InputBasic
                                id="projectAddress"
                                type="text"
                                placeholder="Project Address"
                                innerClassName="my-0"
                                outerClassName="px-4 mt-3"
                                rules={{
                                    function: register,
                                    name: 'address',
                                    rules: {
                                        // required: 'Project address is required',
                                    },
                                    errors,
                                }}
                            />
                            <InputCurrency
                                id="projectEstimate"
                                placeholder="Estimate Project Value"
                                innerClassName="my-0"
                                outerClassName="px-4 mt-3"
                                rules={{
                                    function: register,
                                    name: 'value',
                                    rules: {
                                        required: 'Estimate value is required',
                                    },
                                    errors,
                                }}
                                prefix="$ "
                            />
                            <InputBasic
                                id="projectNumber"
                                type="text"
                                placeholder="Existing Project Number"
                                innerClassName="my-0"
                                outerClassName="px-4 mt-3"
                                rules={{
                                    function: register,
                                    name: 'number',
                                    rules: {
                                        // required: 'Project number is required',
                                    },
                                    errors,
                                }}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputSelectControlled
                                options={locationLists}
                                innerClassName="my-0"
                                outerClassName="px-4 mt-3"
                                label="Location"
                                rules={{
                                    function: register,
                                    name: 'location',
                                    control,
                                    rules: {
                                        required: 'Location is required',
                                    },
                                    errors,
                                }}
                            />

                            <InputSelectControlled
                                options={sectorLists}
                                innerClassName="my-0"
                                outerClassName="px-4 mt-3"
                                label="Sector"
                                rules={{
                                    function: register,
                                    name: 'sector',
                                    rules: {
                                        required: 'Sector is required',
                                    },
                                    control,
                                    errors,
                                }}
                            />

                            <FileWrapper
                                className="my-4"
                                onClick={handleUpload}
                                id="inputPicture"
                            >
                                <input
                                    type="file"
                                    name="file"
                                    className=""
                                    hidden
                                    ref={inputFileRef}
                                    accept="image/*"
                                />

                                <div className="file d-flex justify-content-between align-items-center">
                                    <span className="form-label mb-0 text-start">
                                        {projectName}{' '}
                                    </span>
                                    <AiOutlineCloudUpload
                                        color="rgba(131, 131, 131, 1)"
                                        size={20}
                                    />
                                </div>
                                {imageError && (
                                    <small
                                        className="invalid-feedback d-block text-start ms-4"
                                        style={{
                                            fontSize: '0.75rem',
                                        }}
                                    >
                                        Max file size 2mb
                                    </small>
                                )}
                            </FileWrapper>
                        </div>

                        <div className="col-md-12 mt-4">
                            <ButtonBasic
                                text="Create Project"
                                type="submit"
                                className="btn btn-primary btn-sm"
                                loading={createProjectState.loading}
                            />
                        </div>
                    </Row>
                </form>
            </ModalAction>

            <ModalDashboard
                modal={modalChild.success}
                setModal={setModalChild}
                variant="success"
                type="success"
                title="Success!"
                body={'Successfully create new project'}
                onConfirm={() => handleConfirmModal()}
            />
        </>
    );
}

const FileWrapper = styled.div`
    cursor: pointer;

    .file {
        border-bottom: 1px solid #cfcfcf;
        padding: 0.8rem 0;
        margin: 0 1.5rem;
    }

    .form-label {
        font-size: 14px;
        color: rgba(131, 131, 131, 1);

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        width: 13rem;
    }
`;

const Row = styled.div``;
