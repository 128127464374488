import { Fragment, useRef, useState } from 'react';
import { ThreeCircles } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import userPenIcon from '@/assets/svg/icons/user-pen.svg';
import styled from 'styled-components';

interface InputFileProps {
    title?: string;
    action?: any;
    payload?: string;
}

export default function InputPicture(props: InputFileProps): JSX.Element {
    const { title, payload } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const file = sessionStorage.getItem(payload ?? '');

    const inputFileRef = useRef<HTMLInputElement>(null);
    const [imageError, setImageError] = useState(false);

    const handleUpload = (): void => {
        if (inputFileRef.current !== undefined && inputFileRef.current !== null) {
            inputFileRef.current.click();

            inputFileRef.current.onchange = (e: any) => {
                const file = e.target.files[0];
                const fileSize = file.size / 1024 / 1024; // Convert file size to MB

                if (file !== undefined && fileSize <= 2) {
                    // set to session storage with base 64
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        sessionStorage.setItem(payload ?? '', reader.result as string);
                    };

                    setLoading(true);

                    setTimeout(() => {
                        setLoading(false);
                        setImageError(false);
                    }, 2000);
                } else {
                    setImageError(true);
                }
            };
        }
    };

    return (
        <Fragment>
            <div className="d-flex mb-2">
                <StyledInputFile type="file" ref={inputFileRef} accept="image/*" />

                <label htmlFor="profilePicture" className="mb-0">
                    {!loading ? (
                        <CirclePreviewFile
                            id="profile-picture"
                            file={file ?? userPenIcon}
                            onClick={handleUpload}
                        />
                    ) : (
                        <ThreeCircles
                            height="90"
                            width="90"
                            color="#5648fb"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                            ariaLabel="three-circles-rotating"
                            outerCircleColor=""
                            innerCircleColor=""
                            middleCircleColor=""
                        />
                    )}
                </label>
                <div className="ms-3 mt-1">
                    {title != null ? (
                        <p className="mb-1 h6">{title}</p>
                    ) : (
                        <Link
                            className="link-primary"
                            to="#"
                            onClick={handleUpload}
                            style={{
                                fontSize: 14,
                                display: 'block',
                            }}
                        >
                            Change Photo
                        </Link>
                    )}
                    <span
                        className="fst-italic"
                        style={{
                            fontSize: 12,
                            color: 'rgba(141, 180, 223, 1)',
                        }}
                    >
                        Type file : .jpg, .jpeg, .png
                        <br />
                        Max size : 2mb
                        <br />
                        Recommend Ratio : (1 : 1) max 1080px : 1080px
                    </span>
                    {imageError && (
                        <small
                            className="invalid-feedback d-block text-start"
                            style={{
                                fontSize: '0.75rem',
                            }}
                        >
                            Max file size 2mb
                        </small>
                    )}
                </div>
            </div>
            {title != null && (
                <Link
                    className="link-primary"
                    to="#"
                    onClick={handleUpload}
                    style={{
                        fontSize: 14,
                    }}
                >
                    Change Photo
                </Link>
            )}
        </Fragment>
    );
}

const StyledInputFile = styled.input`
    display: none;
`;

const CirclePreviewFile = styled.section<{ file?: string }>`
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-image: url(${props => props.file});
    background-size: ${props => (props.file !== userPenIcon ? 'cover' : 'auto')};
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    background-color: #ffffff;
    border: 1px solid #ffffff;

    &:hover {
        border: 1px solid #5648fb;
        transition: 1s ease;
    }
`;
