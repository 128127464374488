import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import ButtonBasic from '../Buttons/ButtonBasic';
import { FiEdit, FiSend } from 'react-icons/fi';
import {
    AiOutlineCheckCircle,
    AiOutlineLogout,
    AiOutlineUserSwitch,
} from 'react-icons/ai';
import { RiIndeterminateCircleLine } from 'react-icons/ri';
import { MdOutlineCancel, MdOutlineRestore } from 'react-icons/md';
import { SlHome } from 'react-icons/sl';
import { BsFillPinAngleFill, BsFillPinFill } from 'react-icons/bs';
import { HiOutlineXCircle } from 'react-icons/hi';
import { ReactNode, useEffect, useState } from 'react';

interface ModalBasicProps {
    modal: boolean;
    setModal: (value: any) => void;
    variant: string;
    type: string;
    title: string;
    body: string;
    subBody?: string;
    withCancel?: boolean;
    onConfirm?: () => void;
    loading?: boolean;
    countdownButton?: boolean;
    countdown?: number;
    bodyClass?: string;
    overlay?: 1 | 2 | 3;
    outsideClick?: () => void;
    extraBody?: ReactNode;
}

export default function ModalDashboard(props: ModalBasicProps): JSX.Element {
    const {
        modal,
        setModal,
        type,
        variant,
        title,
        body,
        withCancel,
        onConfirm,
        subBody,
        loading,
        countdownButton,
        countdown,
        bodyClass,
        overlay,
        outsideClick,
        extraBody,
    } = props;

    const [time, setTime] = useState<number>(countdown ?? 0);

    const handleIcon = (type: string): JSX.Element => {
        switch (type) {
            case 'caution':
                return <FiEdit className="text-info fs-1" size={80} />;
            case 'success':
                return <AiOutlineCheckCircle className="text-success fs-1" size={80} />;
            case 'activate':
                return <AiOutlineUserSwitch className="text-info fs-1" size={80} />;
            case 'terminate':
                return (
                    <RiIndeterminateCircleLine className="text-danger fs-1" size={80} />
                );
            case 'resend':
                return <FiSend className="text-info fs-1" size={80} />;
            case 'cancel':
                return <MdOutlineCancel className="text-danger fs-1" size={80} />;
            case 'back':
                return <SlHome className="text-danger fs-1" size={80} />;
            case 'pin':
                return <BsFillPinFill className="text-info fs-1" size={80} />;
            case 'unpin':
                return <BsFillPinAngleFill className="text-danger fs-1" size={80} />;
            case 'leave':
                return <HiOutlineXCircle className="text-danger fs-1" size={80} />;
            case 'delete':
                return (
                    <RiIndeterminateCircleLine className="text-danger fs-1" size={80} />
                );
            case 'close':
            case 'delete permanent':
                return (
                    <RiIndeterminateCircleLine className="text-danger fs-1" size={80} />
                );
            case 'restore':
                return <MdOutlineRestore className="text-success fs-1" size={80} />;
            case 'logout':
                return <AiOutlineLogout className="text-danger fs-1" size={80} />;
            default:
                return <div></div>;
        }
    };

    const handleConfirmText = (type: string): string => {
        switch (type) {
            case 'caution':
                return 'Yes';
            case 'success':
                return 'Confirm';
            case 'terminate':
                return 'Terminate';
            case 'activate':
                return 'Activate';
            case 'pin':
                return 'Pin';
            case 'unpin':
                return 'Unpin';
            case 'leave':
                return 'Leave';
            case 'delete':
                return 'Delete';
            case 'logout':
                return 'Logout';
            case 'close':
                return 'Close';
            default:
                return 'Confirm';
        }
    };

    const handleButtonStyle = (variant: string): string => {
        switch (variant) {
            case 'caution':
                return 'btn-outline-info';
            case 'success':
                return 'btn-outline-success';
            case 'danger':
                return 'btn-outline-danger';
            case 'info':
                return 'btn-outline-info';
            case 'warning':
                return 'btn-outline-warning';
            default:
                return 'btn-outline-info';
        }
    };

    const handleOverlay = (overlay: 0 | 1 | 2 | 3): string => {
        switch (overlay) {
            case 1:
                return '#0000009c';
            case 2:
                return '#000000b8';
            case 3:
                return '#000000d4';
            default:
                return 'rgba(0, 0, 0, 0.5)';
        }
    };

    useEffect(() => {
        if (countdownButton ?? false) {
            const interval = setInterval(() => {
                setTime(prevTime => prevTime - 1);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [countdownButton]);

    useEffect(() => {
        if (modal) {
            setTime(countdown ?? 0);
        }
    }, [modal]);

    const handleCountdownDisable = (time: number): boolean => {
        if (time > 0) {
            if (loading ?? false) {
                return true;
            }
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            <ModalWrapper
                show={modal}
                onHide={() => {
                    setModal(false);
                    outsideClick?.();
                }}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{
                    backgroundColor: handleOverlay(overlay ?? 0),
                }}
            >
                <Modal.Header closeButton>
                    <h5
                        className="fw-semibold text-center"
                        style={{
                            maxWidth: '95%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'pre',
                        }}
                    >
                        {title}
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    {handleIcon(type)}
                    <p className={`mt-3 text-center ${bodyClass as string}`}>{body}</p>
                    <p
                        className="mt-3 text-center text-muted"
                        style={{ wordBreak: 'break-word', fontSize: 12 }}
                    >
                        {subBody}
                    </p>
                    {extraBody}
                </Modal.Body>
                <Modal.Footer>
                    {(withCancel ?? false) && (
                        <ButtonBasic
                            text="Cancel"
                            className={`${handleButtonStyle(variant)} w-100`}
                            onClick={() => setModal(false)}
                        />
                    )}
                    {countdownButton ?? false ? (
                        <ButtonBasic
                            className={`btn-${variant} ${
                                withCancel ?? false ? 'w-100' : 'w-50'
                            }`}
                            text={time > 0 ? `${time} s` : handleConfirmText(type)}
                            onClick={() => onConfirm?.()}
                            loading={loading}
                            disabled={handleCountdownDisable(time)}
                        />
                    ) : (
                        <ButtonBasic
                            className={`btn-${variant} ${
                                withCancel ?? false ? 'w-100' : 'w-50'
                            }`}
                            type="button"
                            text={handleConfirmText(type)}
                            onClick={() => onConfirm?.()}
                            loading={loading}
                        />
                    )}
                </Modal.Footer>
            </ModalWrapper>
        </>
    );
}

const ModalWrapper = styled(Modal)`
    .modal-content {
        font-family: 'Montserrat', sans-serif;
        padding: 0.5rem 1rem;
        border-radius: 10px;
    }

    .modal-header {
        border: none;
        justify-content: center;

        .btn-close {
            position: absolute;
            right: 20px;
            top: 25px;
            font-size: 10px;
        }
    }
    .modal-body {
        text-align: center;
        font-size: 14px;
        padding: 0;
    }
    .modal-footer {
        justify-content: center;
        border: none;
        flex-wrap: initial;
    }
`;
