import BasicTooltip from '@/components/atoms/Tooltips';
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai';
import {
    AssigneeProps,
    ChartDataProps,
    SelectedTask,
    SubTaskListProps,
} from '../../interfaces';
import styled from 'styled-components';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MdDragIndicator } from 'react-icons/md';
import { generateInitialImageUrl } from '@/utility/Utils';
import moment from 'moment';
import { OverlayTrigger, Placeholder, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { useRef, useMemo, useState, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useAppDispatch } from '@/redux/hook';
import { updateTaskOrdering } from '../../redux/actions';

export default function SubTaskList({
    selectedTask,
    setModal,
    handleSelectSubtask,
    handleAddSubtask,
    permissions,
    handleDetailTask,
    handleDragDropUpdateState,
}: SubTaskListProps): JSX.Element {
    const dispatch = useAppDispatch();

    const ItemTypes = {
        TASK: 'task',
        SUBTASK: 'subtask',
    };

    const detailTaskState = useSelector((state: RootState) => state.detailTask);

    const [childrenData, setChildrenData] = useState<ChartDataProps>([]);

    // const loadSubTask = detailTaskState?.response;

    useEffect(() => {
        if (detailTaskState?.status === 200) {
            setChildrenData(detailTaskState?.response?.children ?? []);
        }
    }, [detailTaskState]);

    /**
     * Function to swap tasks at the same level
     *
     * @param tasks - ChartDataProps
     * @param draggedId  - number
     * @param targetId  - number
     * @returns ChartDataProps
     */
    const swapTasksAtSameLevel = (
        tasks: ChartDataProps,
        draggedId: number,
        targetId: number,
    ): ChartDataProps => {
        return tasks.map(task => {
            if (task.id === draggedId) {
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                return findTaskById(tasks, targetId) || task;
            } else if (task.id === targetId) {
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                return findTaskById(tasks, draggedId) || task;
            } else if (task.children !== undefined && task.children.length > 0) {
                return {
                    ...task,
                    children: swapTasksAtSameLevel(task.children, draggedId, targetId),
                };
            } else {
                return task;
            }
        });
    };

    /**
     * Function to find task by id
     *
     * @param data - ChartDataProps
     * @param id  - number
     * @returns any
     */
    const findTaskById = (data: ChartDataProps, id: number): any => {
        for (const task of data) {
            if (task.id === id) {
                return task;
            }

            if (task.children !== undefined && task.children.length > 0) {
                const child = findTaskById(task.children, id);
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                if (child) {
                    return child;
                }
            }
        }
        return null;
    };

    /**
     * Function to debounce
     *
     * @see https://stackoverflow.com/questions/24004791/can-someone-explain-the-debounce-function-in-javascript
     * @param func - (...args: any[]) => void
     * @param delay - number
     * @returns (...args: any[]) => void
     */
    const debounce = (
        func: (...args: any[]) => void,
        delay: number,
    ): ((...args: any[]) => void) => {
        let timeoutId: ReturnType<typeof setTimeout> | null = null;

        return (...args: any[]): void => {
            if (timeoutId != null) {
                clearTimeout(timeoutId);
            }

            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    /**
     * Function to handle update order
     *
     * @param taskId - number
     * @param targetId - number
     */
    const handleUpdateOrder = (taskId: number, targetId: number): void => {
        const payload = new FormData();

        payload.append('target', targetId.toString());

        dispatch(updateTaskOrdering(taskId, payload));
    };

    const debouncedHandleUpdateOrder = debounce((draggedId, targetId) => {
        handleUpdateOrder?.(draggedId, targetId);
    }, 1000);

    /**
     * Function to update state when drag and drop
     *
     * @param draggedId - number
     * @param draggedLevel - number
     * @param draggedTaskId - number
     * @param targetId - number
     * @param targetLevel - number
     * @param targetTaskId - number
     */
    const handleDragDropUpdateStateChildren = async (
        draggedId: number,
        draggedLevel: number,
        draggedTaskId: number,
        targetId: number,
        targetLevel: number,
        targetTaskId: number,
    ): Promise<void> => {
        // Find the task that is being dragged
        const draggedTask = findTaskById(childrenData, draggedId);
        // const draggedIndex = findTaskIndexById(data, draggedId);

        // Find the task that is the target of the drop
        const targetTask = findTaskById(childrenData, targetId);
        // const targetIndex = findTaskIndexById(data, targetId);

        // If the dragged task and the target task are at the same level
        // and have the same parent, swap their order
        if (draggedLevel === targetLevel && draggedTask?.parent === targetTask?.parent) {
            const newData = swapTasksAtSameLevel(childrenData, draggedId, targetId);
            setChildrenData(newData);
        }
    };

    /**
     * Function to find task index by id
     *
     * @param tasks - ChartDataProps
     * @param id - number
     *
     * @returns number | null
     */
    const findTaskIndexById = (tasks: ChartDataProps, id: number): number | null => {
        for (let i = 0; i < tasks.length; i++) {
            if (tasks[i].id === id) {
                return i;
            }

            if (tasks[i].children !== null && tasks[i].children !== undefined) {
                const index = findTaskIndexById(tasks[i].children, id);
                if (index !== null) {
                    return index;
                }
            }
        }
        return null;
    };

    /**
     * Function to render draggable name
     *
     * @param record - any
     * @returns - JSX.Element
     */
    const DraggableName = ({ record }: { record: any }): JSX.Element => {
        const refDrag = useRef<HTMLDivElement>(null);

        // drag
        const [{ isDragging, handlerId }, connectDrag] = useDrag(() => ({
            type: ItemTypes.TASK,
            item: {
                id: record.id,
                level: record.level,
                taskId: record.task_id,
                index: findTaskIndexById(childrenData, record.id),
            },
            collect: monitor => {
                const result = {
                    handlerId: monitor.getHandlerId(),
                    isDragging: monitor.isDragging(),
                };
                return result;
            },
        }));

        // drop
        const [, connectDrop] = useDrop({
            accept: ItemTypes.TASK,
            hover(
                {
                    id: draggedId,
                    level: draggedLevel,
                    taskId: draggedTaskId,
                    index: draggedIndex,
                }: {
                    id: number;
                    level: number;
                    taskId: number;
                    index: number;
                },
                monitor,
            ) {
                // Don't replace items with themselves
                if (refDrag.current == null) {
                    return;
                }

                const hoverIndex = findTaskIndexById(childrenData, record.id);

                // If hover on itself then return
                if (hoverIndex === null) {
                    return;
                }

                // Determine rectangle on screen
                const hoverBoundingRect = refDrag.current?.getBoundingClientRect();
                // Get vertical middle
                const hoverMiddleY =
                    (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                // Determine mouse position
                const clientOffset = monitor.getClientOffset();

                if (clientOffset == null) {
                    return;
                }
                // Get pixels to the top
                const hoverClientY = clientOffset.y - hoverBoundingRect.top;
                // Only perform the move when the mouse has crossed half of the items height
                // When dragging downwards, only move when the cursor is below 50%
                // When dragging upwards, only move when the cursor is above 50%
                // Dragging downwards
                if (draggedIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return;
                }
                // Dragging upwards
                if (draggedIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return;
                }

                if (
                    draggedId !== record.id &&
                    draggedLevel === record.level &&
                    draggedTaskId === record.task_id
                ) {
                    handleDragDropUpdateStateChildren(
                        draggedId,
                        draggedLevel,
                        draggedTaskId,
                        record.id,
                        record.level,
                        record.task_id,
                    )
                        .then(() => {
                            debouncedHandleUpdateOrder(draggedId, record.id);
                        })
                        .catch(err => {
                            throw new Error(`Error updating state: ${err as string}`);
                        });

                    void handleDragDropUpdateState(
                        draggedId,
                        draggedLevel,
                        draggedTaskId,
                        record.id,
                        record.level,
                        record.task_id,
                    ).then(() => {});
                }

                // Time to actually perform the action
                draggedIndex = hoverIndex;
            },
        });

        connectDrag(refDrag);
        connectDrop(refDrag);
        const opacity = isDragging ? 0.4 : 1;
        const containerStyle = useMemo(() => ({ opacity }), [opacity]);

        return (
            <div
                style={containerStyle}
                ref={refDrag}
                data-handler-id={handlerId}
                role="button"
            >
                <div className="d-flex align-items-center">
                    <div>
                        <MdDragIndicator
                            // role="Handle"
                            color="rgb(189, 185, 185)"
                        />
                    </div>

                    <span
                        className={
                            record.level === 0
                                ? 'fw-semibold text-primary'
                                : 'fw-normal text-primary'
                        }
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            width: '23rem',
                            whiteSpace: 'nowrap',
                        }}
                        onClick={() => handleDetailTask(record, true)}
                    >
                        {record.name}
                    </span>
                </div>
            </div>
        );
    };

    const renderChildren = (children: SelectedTask[]): JSX.Element | JSX.Element[] => {
        return children?.map(child => (
            <ul key={child.id}>
                <li className="my-2">
                    <div className="d-flex align-items-center justify-content-between">
                        {permissions.collaborate ? (
                            <DraggableName record={child} />
                        ) : (
                            <span className="fw-normal text-primary">{child.name}</span>
                        )}

                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center">
                                <div className="d-flex me-2">
                                    {child.assignees
                                        ?.slice(0, 4)
                                        .map((a: AssigneeProps, key: number) => (
                                            <div key={key}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={(props: any) => (
                                                        <Tooltip
                                                            id="button-tooltip"
                                                            {...props}
                                                        >
                                                            {a.user_name}
                                                        </Tooltip>
                                                    )}
                                                >
                                                    <AssigneeAvatar
                                                        src={
                                                            a?.user_avatar ??
                                                            generateInitialImageUrl(
                                                                a?.user_name ??
                                                                    a?.user_email,
                                                            )
                                                        }
                                                        alt="assignee"
                                                    />
                                                </OverlayTrigger>
                                            </div>
                                        ))}
                                    {child.assignees?.length > 4 && (
                                        <BasicTooltip
                                            placement="right"
                                            text={child.assignees
                                                ?.slice(4)
                                                .map(
                                                    (assignee: {
                                                        user_name: string;
                                                        user_email: string;
                                                    }) => (
                                                        <p
                                                            key={assignee.user_email}
                                                            className="my-1"
                                                        >
                                                            {assignee?.user_name ??
                                                                assignee?.user_email}
                                                        </p>
                                                    ),
                                                )}
                                        >
                                            <div
                                                className="rounded-circle bg-primary text-white border border-white d-flex justify-content-center align-items-center"
                                                style={{
                                                    width: 25,
                                                    height: 25,
                                                    fontSize: 12,
                                                }}
                                            >
                                                <span className="">
                                                    +{child.assignees?.length - 4}
                                                </span>
                                            </div>
                                        </BasicTooltip>
                                    )}
                                </div>
                            </div>
                            <span className="me-2 text-dark">
                                {moment(child.endDate).diff(
                                    moment(child.startDate),
                                    'days',
                                ) + 1}{' '}
                                Days
                            </span>
                            {permissions.collaborate &&
                                selectedTask?.deleted_at === null && (
                                    <>
                                        <BasicTooltip
                                            text={'Add Subtask'}
                                            className="mx-2"
                                        >
                                            <AddButtonAssignee
                                                className=""
                                                type="button"
                                                onClick={() => handleAddSubtask(child)}
                                            >
                                                <AiOutlinePlus
                                                    role="button"
                                                    className="icon"
                                                    color="#5648fb"
                                                />
                                            </AddButtonAssignee>
                                        </BasicTooltip>
                                        <BasicTooltip
                                            text={'Delete Subtask'}
                                            className="mx-2"
                                        >
                                            <AiOutlineDelete
                                                onClick={() => handleSelectSubtask(child)}
                                                role="button"
                                                className="text-danger"
                                                style={{
                                                    fontSize: 20,
                                                }}
                                            />
                                        </BasicTooltip>
                                    </>
                                )}
                        </div>
                    </div>
                </li>
                <li>{child?.children != null && renderChildren(child?.children)}</li>
            </ul>
        ));
    };

    const renderData = (): JSX.Element | JSX.Element[] | undefined => {
        return detailTaskState?.loading !== false ? (
            Array.from(Array(3).keys()).map(item => (
                <Placeholder key={item} animation="glow" className="">
                    <Placeholder xs={4} />
                    &nbsp;
                    <Placeholder xs={2} />
                    &nbsp;
                    <Placeholder xs={5} />
                </Placeholder>
            ))
        ) : childrenData?.length === 0 || childrenData === undefined ? (
            <div className="d-flex justify-content-center p-5">
                <p className="mb-0 text-muted" style={{ fontSize: 14 }}>
                    No subtask found
                </p>
            </div>
        ) : (
            childrenData?.map((d: any) => (
                <ul key={d.id}>
                    <li className="my-2">
                        <div className="d-flex align-items-center justify-content-between">
                            {permissions.collaborate ? (
                                <DraggableName record={d} />
                            ) : (
                                <span
                                    className="fw-normal text-primary"
                                    onClick={() => handleDetailTask(d, true)}
                                    role="button"
                                >
                                    {d.name}
                                </span>
                            )}

                            <div className="d-flex align-items-center">
                                <div className="d-flex me-2">
                                    {d.assignees
                                        ?.slice(0, 4)
                                        .map((a: any, key: number) => (
                                            <div key={key}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={(props: any) => (
                                                        <Tooltip
                                                            id="button-tooltip"
                                                            {...props}
                                                        >
                                                            {a.user_name}
                                                        </Tooltip>
                                                    )}
                                                    key={key}
                                                >
                                                    <AssigneeAvatar
                                                        src={
                                                            a?.user_avatar ??
                                                            generateInitialImageUrl(
                                                                a?.user_name ??
                                                                    a?.user_email,
                                                            )
                                                        }
                                                        alt="assignee"
                                                    />
                                                </OverlayTrigger>
                                            </div>
                                        ))}
                                    {d.assignees?.length > 4 && (
                                        <BasicTooltip
                                            placement="right"
                                            text={d.assignees
                                                ?.slice(4)
                                                .map(
                                                    (assignee: {
                                                        user_name: string;
                                                        user_email: string;
                                                    }) => (
                                                        <p
                                                            key={assignee.user_email}
                                                            className="my-1"
                                                        >
                                                            {assignee?.user_name ??
                                                                assignee?.user_email}
                                                        </p>
                                                    ),
                                                )}
                                        >
                                            <div
                                                className="rounded-circle bg-primary text-white border border-white d-flex justify-content-center align-items-center"
                                                style={{
                                                    width: 25,
                                                    height: 25,
                                                    fontSize: 12,
                                                }}
                                            >
                                                <span className="">
                                                    +{d.assignees?.length - 4}
                                                </span>
                                            </div>
                                        </BasicTooltip>
                                    )}
                                </div>
                                <span className="me-2 text-dark">
                                    {moment(d.endDate).diff(moment(d.startDate), 'days') +
                                        1}{' '}
                                    Days
                                </span>
                                {permissions.collaborate &&
                                    selectedTask?.deleted_at === null && (
                                        <>
                                            <BasicTooltip
                                                text={'Add Subtask'}
                                                className="mx-2"
                                            >
                                                <AddButtonAssignee
                                                    className=""
                                                    type="button"
                                                    onClick={() => handleAddSubtask(d)}
                                                >
                                                    <AiOutlinePlus
                                                        role="button"
                                                        className="icon"
                                                        color="#5648fb"
                                                    />
                                                </AddButtonAssignee>
                                            </BasicTooltip>
                                            <BasicTooltip
                                                text={'Archive Subtask'}
                                                className="mx-2"
                                            >
                                                <AiOutlineDelete
                                                    onClick={() => handleSelectSubtask(d)}
                                                    role="button"
                                                    className="text-danger"
                                                    style={{
                                                        fontSize: 20,
                                                    }}
                                                />
                                            </BasicTooltip>
                                        </>
                                    )}
                            </div>
                        </div>
                    </li>
                    <li>
                        {d?.children != null && <ul>{renderChildren(d?.children)}</ul>}
                    </li>
                </ul>
            ))
        );
    };

    return (
        <div className="">
            <div className="d-flex align-items-center">
                <h6 className="fw-normal me-2 mb-0">Sub Task</h6>
                <div className="fw-semibold me-2 mb-0">
                    {/* {detailTaskState?.loading !== true ? (
                        <Placeholder animation="glow" className="">
                            <Placeholder xs={12} />
                        </Placeholder>
                    ) : ( */}
                    {detailTaskState?.response?.subtask_date_ranges ?? ''}
                    {/* )} */}
                </div>

                {permissions.collaborate && selectedTask?.deleted_at === null && (
                    <BasicTooltip text={'Add Subtask'} className="mx-2">
                        <AddButtonAssignee
                            className=""
                            type="button"
                            onClick={() => handleAddSubtask(selectedTask)}
                        >
                            <AiOutlinePlus
                                role="button"
                                className="icon"
                                color="#5648fb"
                            />
                        </AddButtonAssignee>
                    </BasicTooltip>
                )}
            </div>
            <WrapperList>{renderData()}</WrapperList>
        </div>
    );
}

const WrapperList = styled.div`
    font-size: 14px;
    ul {
        list-style: none;
        // padding-left: 0;
        margin: 0;
    }

    ol,
    ul {
        padding-left: 0.5rem;
    }
`;

const AddButtonAssignee = styled.button`
    background: var(--primary-100, #dddafe);
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background: #e0e0e0;
    }

    &:focus {
        box-shadow: none;
    }

    &:active {
        box-shadow: none;
        border: none !important;
        border-color: none !important;
    }
`;

const AssigneeAvatar = styled.img`
    width: 25px;
    height: 25px;
    // className="rounded-circle img-fluid border border-white"
    border-radius: 50%;
    border: 1px solid #fff;
`;
