import { axiosClient } from '@/services/GatewayService';
import { AppDispatch } from '@/redux/store';

import {
    ALL_TASK_DESIGN_REQUEST,
    ALL_TASK_DESIGN_SUCCESS,
    ALL_TASK_DESIGN_FAIL,
    ALL_TASK_DESIGN_RESET,
    ALL_TASK_CONSTRUCTION_REQUEST,
    ALL_TASK_CONSTRUCTION_SUCCESS,
    ALL_TASK_CONSTRUCTION_FAIL,
    ALL_TASK_CONSTRUCTION_RESET,
    ALL_TASK_PRIVATE_REQUEST,
    ALL_TASK_PRIVATE_SUCCESS,
    ALL_TASK_PRIVATE_FAIL,
    ALL_TASK_PRIVATE_RESET,
    ALL_TASK_ARCHIVE_REQUEST,
    ALL_TASK_ARCHIVE_SUCCESS,
    ALL_TASK_ARCHIVE_FAIL,
    SEARCH_PUBLIC_TASK_REQUEST,
    SEARCH_PUBLIC_TASK_SUCCESS,
    SEARCH_PUBLIC_TASK_FAIL,
    SEARCH_PRIVATE_TASK_REQUEST,
    SEARCH_PRIVATE_TASK_SUCCESS,
    SEARCH_PRIVATE_TASK_FAIL,
    DETAIL_TASK_REQUEST,
    DETAIL_TASK_SUCCESS,
    DETAIL_TASK_FAIL,
    DETAIL_TASK_RESET,
    DETAIL_TASK_HISTORY_REQUEST,
    DETAIL_TASK_HISTORY_SUCCESS,
    DETAIL_TASK_HISTORY_FAIL,
    DETAIL_TASK_COMMENT_REQUEST,
    DETAIL_TASK_COMMENT_SUCCESS,
    DETAIL_TASK_COMMENT_FAIL,
    ADD_TASK_REQUEST,
    ADD_TASK_SUCCESS,
    ADD_TASK_FAIL,
    ADD_TASK_RESET,
    ADD_SUB_TASK_REQUEST,
    ADD_SUB_TASK_SUCCESS,
    ADD_SUB_TASK_FAIL,
    ADD_SUB_TASK_RESET,
    UPDATE_TASK_REQUEST,
    UPDATE_TASK_SUCCESS,
    UPDATE_TASK_FAIL,
    CLEAR_TASK_ACTION,
    SHARE_TASK_REQUEST,
    SHARE_TASK_SUCCESS,
    SHARE_TASK_FAIL,
    SHARE_TASK_RESET,
    ARCHIVE_TASK_REQUEST,
    ARCHIVE_TASK_SUCCESS,
    ARCHIVE_TASK_FAIL,
    UNARCHIVE_TASK_REQUEST,
    UNARCHIVE_TASK_SUCCESS,
    UNARCHIVE_TASK_FAIL,
    ARCHIVE_UNARCHIVE_RESET,
    UPDATE_TASK_ORDERING_REQUEST,
    UPDATE_TASK_ORDERING_SUCCESS,
    UPDATE_TASK_ORDERING_FAIL,
    UPDATE_TASK_ORDERING_RESET,
    UPDATE_TASK_RANGE_REQUEST,
    UPDATE_TASK_RANGE_SUCCESS,
    UPDATE_TASK_RANGE_FAIL,
    UPDATE_TASK_RANGE_RESET,
    ALL_PROJECT_MEMBER_REQUEST,
    ALL_PROJECT_MEMBER_SUCCESS,
    ALL_PROJECT_MEMBER_FAIL,
    FIND_PERMISSIONS_BY_EMAIL_REQUEST,
    FIND_PERMISSIONS_BY_EMAIL_SUCCESS,
    // FIND_PERMISSIONS_BY_EMAIL_FAIL,
    FIND_PERMISSIONS_BY_EMAIL_RESET,
    UPDATE_PERMISSION_ASSIGNEE_REQUEST,
    UPDATE_PERMISSION_ASSIGNEE_SUCCESS,
    UPDATE_PERMISSION_ASSIGNEE_FAIL,
    UPDATE_PERMISSION_ASSIGNEE_RESET,
    ADD_PERMISSION_ASSIGNEE_DIRECTLY_REQUEST,
    ADD_PERMISSION_ASSIGNEE_DIRECTLY_SUCCESS,
    ADD_PERMISSION_ASSIGNEE_DIRECTLY_FAIL,
    ADD_PERMISSION_ASSIGNEE_DIRECTLY_RESET,
    POST_TASK_COMMENT_REQUEST,
    POST_TASK_COMMENT_SUCCESS,
    POST_TASK_COMMENT_FAIL,
    POST_TASK_COMMENT_RESET,
    EDIT_TASK_COMMENT_REQUEST,
    EDIT_TASK_COMMENT_SUCCESS,
    EDIT_TASK_COMMENT_FAIL,
    EDIT_TASK_COMMENT_RESET,
    DELETE_TASK_COMMENT_REQUEST,
    DELETE_TASK_COMMENT_SUCCESS,
    DELETE_TASK_COMMENT_FAIL,
    DELETE_TASK_COMMENT_RESET,
    RESTORE_TASK_REQUEST,
    RESTORE_TASK_SUCCESS,
    RESTORE_TASK_FAIL,
    RESTORE_TASK_RESET,
    DELETE_TASK_REQUEST,
    DELETE_TASK_SUCCESS,
    DELETE_TASK_FAIL,
    DELETE_TASK_RESET,
    REMOVE_TASK_ASSIGNEE_REQUEST,
    REMOVE_TASK_ASSIGNEE_SUCCESS,
    REMOVE_TASK_ASSIGNEE_FAIL,
    REMOVE_TASK_ASSIGNEE_RESET,
    COLLAPSE_TASK_REQUEST,
    COLLAPSE_TASK_SUCCESS,
    COLLAPSE_TASK_FAIL,
    COLLAPSE_TASK_RESET,
} from '../reducers';
import { toast } from 'react-toastify';

/**
 * Function to get all task on space design
 *
 * @return Promise<void>
 */
export const getAllTaskDesignSchedule = (params: {
    project_id?: string;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
    only_me?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_TASK_DESIGN_REQUEST });
        try {
            const res = await axiosClient.get('v2/project/tasks', {
                params: {
                    ...params,
                    type: 'design',
                },
            });

            dispatch({
                type: ALL_TASK_DESIGN_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_TASK_DESIGN_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all task on space construction
 *
 * @return Promise<void>
 */
export const getAllTaskConstructionSchedule = (params: {
    project_id?: string;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
    only_me?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_TASK_CONSTRUCTION_REQUEST });
        try {
            const res = await axiosClient.get('v2/project/tasks', {
                params: {
                    ...params,
                    type: 'construction',
                },
            });

            dispatch({
                type: ALL_TASK_CONSTRUCTION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_TASK_CONSTRUCTION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all task on space private
 *
 * @return Promise<void>
 */
export const getAllTaskPrivateSchedule = (params: {
    project_id?: string;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
    only_me?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_TASK_PRIVATE_REQUEST });
        try {
            const res = await axiosClient.get('v2/project/private-tasks', {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: ALL_TASK_PRIVATE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_TASK_PRIVATE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all task on space design
 *
 * @return Promise<void>
 */
export const getAllArchiveTask = (params: {
    project_id: string;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
    type: string;
    page?: number;
    per_page: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_TASK_ARCHIVE_REQUEST });
        try {
            const res = await axiosClient.get('v2/project/tasks/archives', {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: ALL_TASK_ARCHIVE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_TASK_ARCHIVE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all task on space private
 *
 * @return Promise<void>
 */
export const searchPublicTask = (params: {
    project_id?: string;
    type: string;
    search?: string;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SEARCH_PUBLIC_TASK_REQUEST });
        try {
            const res = await axiosClient.get('v2/project/tasks/search', {
                params,
            });

            dispatch({
                type: SEARCH_PUBLIC_TASK_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: SEARCH_PUBLIC_TASK_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all task on space private
 *
 * @return Promise<void>
 */
export const searchPrivateTask = (params: {
    project_id?: string;
    search?: string;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SEARCH_PRIVATE_TASK_REQUEST });
        try {
            const res = await axiosClient.get('v2/project/private-tasks/search', {
                params,
            });

            dispatch({
                type: SEARCH_PRIVATE_TASK_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: SEARCH_PRIVATE_TASK_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get share task to user
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param FormData formData - data of project
 * @return Promise<void>
 */
export const updateTaskOrdering = (id: number, data: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_TASK_ORDERING_REQUEST });
        try {
            const res = await axiosClient.post(`v2/project/tasks/${id}/ordering`, data);

            dispatch({
                type: UPDATE_TASK_ORDERING_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UPDATE_TASK_ORDERING_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get share task to user
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param FormData formData - data of project
 * @return Promise<void>
 */
export const updateTaskRangeSchedule = (
    id: number,
    data: { start_at: string; end_at: string },
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_TASK_RANGE_REQUEST });
        try {
            const res = await axiosClient.post(
                `v2/project/tasks/${id}/schedule-update`,
                data,
            );

            dispatch({
                type: UPDATE_TASK_RANGE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UPDATE_TASK_RANGE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to create task schedule
 *
 * @param number params.project_id - id of project
 * @param number params.id - id of task
 * @param FormData formData - data of task
 * @return Promise<void>
 */
export const createTask = (data: object): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ADD_TASK_REQUEST });
        try {
            const res = await axiosClient.post('v2/project/tasks', data);

            dispatch({
                type: ADD_TASK_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ADD_TASK_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all project member
 *
 * @param number params.project_id - id of project
 * @param number params.id - id of task
 * @param FormData formData - data of task
 * @return Promise<void>
 */
export const getAllProjectMemberSchedule = (params: { project_id: string }): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_PROJECT_MEMBER_REQUEST });
        try {
            const res = await axiosClient.get('v2/project/tasks/member', {
                params,
            });

            dispatch({
                type: ALL_PROJECT_MEMBER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_PROJECT_MEMBER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to find permission by email
 *
 * @param number params.project_id - id of project
 * @param number params.id - id of task
 * @param FormData formData - data of task
 * @return Promise<void>
 */
export const findPermissionByEmail = (data: FormData, email: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: FIND_PERMISSIONS_BY_EMAIL_REQUEST });

        try {
            const res = await axiosClient.post('v2/project/tasks/invite', data);

            dispatch({
                type: FIND_PERMISSIONS_BY_EMAIL_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            // dispatch({
            //     type: FIND_PERMISSIONS_BY_EMAIL_FAIL,
            //     payload: error?.response?.data.message,
            // });
            dispatch({
                type: FIND_PERMISSIONS_BY_EMAIL_RESET,
            });
            toast.error(`You don't have permission to invite ${email}`);
        }
    };
};

/**
 * Function to update permission assignee
 *
 * @param number params.project_id - id of project
 * @param number params.id - id of task
 * @param FormData formData - data of task
 * @return Promise<void>
 */
export const updatePermissionAssignee = (data: object): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_PERMISSION_ASSIGNEE_REQUEST });

        try {
            const res = await axiosClient.post('v2/project/tasks/invite/update', data);

            dispatch({
                type: UPDATE_PERMISSION_ASSIGNEE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UPDATE_PERMISSION_ASSIGNEE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to add permission assignee directly to task id
 *
 * @param number params.project_id - id of project
 * @param number params.id - id of task
 * @param FormData formData - data of task
 * @return Promise<void>
 */
export const addPermissionAssigneeDirectly = (task_id: number, data: object): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ADD_PERMISSION_ASSIGNEE_DIRECTLY_REQUEST });

        try {
            const res = await axiosClient.post(
                `v2/project/tasks/${task_id}/assignees/create`,
                data,
            );

            dispatch({
                type: ADD_PERMISSION_ASSIGNEE_DIRECTLY_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ADD_PERMISSION_ASSIGNEE_DIRECTLY_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to update permission assignee
 *
 * @param number params.project_id - id of project
 * @param number params.id - id of task
 * @param FormData formData - data of task
 * @return Promise<void>
 */
export const getDetailTask = (task_id: number, project_id: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_TASK_REQUEST });
        try {
            const res = await axiosClient.get(`v2/project/tasks/${task_id}/detail`, {
                params: {
                    project_id,
                },
            });

            dispatch({
                type: DETAIL_TASK_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DETAIL_TASK_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get history on detail ask
 *
 * @param number params.project_id - id of project
 * @param number params.id - id of task
 * @param FormData formData - data of task
 * @return Promise<void>
 */
export const getDetailTaskHistory = (task_id: number, project_id: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_TASK_HISTORY_REQUEST });
        try {
            const res = await axiosClient.get(`v2/project/tasks/${task_id}/history`, {
                params: {
                    project_id,
                },
            });

            dispatch({
                type: DETAIL_TASK_HISTORY_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DETAIL_TASK_HISTORY_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get comment on detail ask
 *
 * @param number params.project_id - id of project
 * @param number params.id - id of task
 * @param FormData formData - data of task
 * @return Promise<void>
 */
export const getDetailTaskComment = (task_id: number, project_id: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_TASK_COMMENT_REQUEST });
        try {
            const res = await axiosClient.get(`v2/project/tasks/${task_id}/comments`, {
                params: {
                    project_id,
                },
            });

            dispatch({
                type: DETAIL_TASK_COMMENT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DETAIL_TASK_COMMENT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to post comment
 *
 * @param number params.project_id - id of project
 * @param number params.id - id of task
 * @param FormData formData - data of task
 * @return Promise<void>
 */
export const postTaskComment = (
    task_id: number,
    project_id: string,
    data: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: POST_TASK_COMMENT_REQUEST });
        try {
            const res = await axiosClient.post(
                `v2/project/tasks/${task_id}/comments`,
                data,
                {
                    params: {
                        project_id,
                    },
                },
            );

            dispatch({
                type: POST_TASK_COMMENT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: POST_TASK_COMMENT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to edit comment
 *
 * @param number params.project_id - id of project
 * @param number params.id - id of task
 * @param FormData formData - data of task
 * @return Promise<void>
 */
export const editTaskComment = (
    task_id: number,
    project_id: string,
    comment_id: number,
    data: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: EDIT_TASK_COMMENT_REQUEST });
        try {
            const res = await axiosClient.put(
                `v2/project/tasks/${task_id}/comments/${comment_id}`,
                data,
                {
                    params: {
                        project_id,
                    },
                },
            );

            dispatch({
                type: EDIT_TASK_COMMENT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: EDIT_TASK_COMMENT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to delete comment
 *
 * @param number params.project_id - id of project
 * @param number params.id - id of task
 * @param number params.comment_id - id of comment
 * @return Promise<void>
 */
export const deleteTaskComment = (
    task_id: number,
    project_id: string,
    comment_id: number,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_TASK_COMMENT_REQUEST });
        try {
            const res = await axiosClient.delete(
                `v2/project/tasks/${task_id}/comments/${comment_id}`,
                {
                    params: {
                        project_id,
                    },
                },
            );

            dispatch({
                type: DELETE_TASK_COMMENT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DELETE_TASK_COMMENT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to update detail of task
 *
 * @param number params.project_id - id of project
 * @param number params.id - id of task
 * @param FormData formData - data of task
 * @return Promise<void>
 */
export const updateDetailTask = (
    task_id: number,
    project_id: string,
    data: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_TASK_REQUEST });
        try {
            const res = await axiosClient.put(
                `v2/project/tasks/${task_id}/update`,
                data,
                {
                    params: {
                        project_id,
                    },
                },
            );

            dispatch({
                type: UPDATE_TASK_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UPDATE_TASK_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get archive a task
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param FormData formData - data of project
 * @return Promise<void>
 */
export const archivingTask = (id: number, project_id: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ARCHIVE_TASK_REQUEST });
        try {
            const res = await axiosClient.delete(`v2/project/tasks/${id}/archive`, {
                params: {
                    project_id,
                },
            });

            dispatch({
                type: ARCHIVE_TASK_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ARCHIVE_TASK_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get archive a task
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param FormData formData - data of project
 * @return Promise<void>
 */
export const restoreTask = (
    project_id: string,
    data: {
        task_ids: number[];
        type: string;
    },
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESTORE_TASK_REQUEST });
        try {
            const res = await axiosClient.post(
                'v2/project/tasks/archives/restore',
                data,
                {
                    params: {
                        project_id,
                    },
                },
            );

            dispatch({
                type: RESTORE_TASK_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: RESTORE_TASK_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get archive a task
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param FormData formData - data of project
 * @return Promise<void>
 */
export const deletePermanentTask = (
    project_id: string,
    data: {
        task_ids: number[];
        type: string;
    },
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_TASK_REQUEST });
        try {
            const res = await axiosClient.post('v2/project/tasks/archives/delete', data, {
                params: {
                    project_id,
                },
            });

            dispatch({
                type: DELETE_TASK_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DELETE_TASK_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to remove assignee from task
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param FormData formData - data of project
 * @return Promise<void>
 */
export const removeTaskAssignee = (task_id: number, data: any): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: REMOVE_TASK_ASSIGNEE_REQUEST });
        try {
            const res = await axiosClient.delete(
                `v2/project/tasks/${task_id}/assignees/delete`,
                // data,
                {
                    params: {
                        project_id: data.project_id,
                        type: data.type,
                        assignees: data.assignees,
                    },
                },
            );

            dispatch({
                type: REMOVE_TASK_ASSIGNEE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: REMOVE_TASK_ASSIGNEE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

// -----------------------Deprecated Function------------------------------- \\
/**
 * Function to get detail of task
 *
 * @param number params.project_id - id of project
 * @param number params.id - page of project
 * @return Promise<void>
 */
export const getDetailTaskSchedule = (params: {
    project_id?: string;
    id: string;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_TASK_REQUEST });
        try {
            const res = await axiosClient.get('project/tasks', {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: DETAIL_TASK_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DETAIL_TASK_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get share task to user
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param FormData formData - data of project
 * @return Promise<void>
 */
export const shareTaskSchedule = (
    params: { project_id?: string; id: string },
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SHARE_TASK_REQUEST });
        try {
            const res = await axiosClient.post('project/tasks/share', formData, {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: SHARE_TASK_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: SHARE_TASK_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to create subtask schedule
 *
 * @param number params.project_id - id of project
 * @param number params.id - id of task
 * @param FormData formData - data of task
 * @return Promise<void>
 */
export const addSubtaskSchedule = (
    params: { project_id?: string },
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ADD_SUB_TASK_REQUEST });
        try {
            const res = await axiosClient.post('project/tasks/sub', formData, {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: ADD_SUB_TASK_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ADD_SUB_TASK_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to update task schedule
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @param FormData formData - data of project
 * @return Promise<void>
 */
export const updateTaskSchedule = (
    params: { project_id?: string; id: number },
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_TASK_REQUEST });
        try {
            const res = await axiosClient.post('project/tasks', formData, {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: UPDATE_TASK_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UPDATE_TASK_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to delete task schedule
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @return Promise<void>
 */
export const deleteTaskSchedule = (params: { project_id?: string; ids: number }): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_TASK_REQUEST });
        try {
            const res = await axiosClient.delete('project/tasks', {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: DELETE_TASK_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DELETE_TASK_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to mark task as done
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @return Promise<void>
 */
export const markTaskAsDone = (params: { project_id?: string; ids: number }): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ARCHIVE_TASK_REQUEST });
        try {
            const res = await axiosClient.put('project/tasks/done', null, {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: ARCHIVE_TASK_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ARCHIVE_TASK_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to mark task as undone
 *
 * @param number params.project_id - id of project
 * @param number params.page - page of project
 * @return Promise<void>
 */
export const markTaskAsUndone = (params: { project_id?: string; ids: number }): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UNARCHIVE_TASK_REQUEST });
        try {
            const res = await axiosClient.put('project/tasks/undone', null, {
                params: {
                    ...params,
                },
            });

            dispatch({
                type: UNARCHIVE_TASK_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UNARCHIVE_TASK_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

export const collapseTask = (params: { task_id: number; is_open: number }): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: COLLAPSE_TASK_REQUEST });
        try {
            const res = await axiosClient.put('v2/project/tasks/open-status', null, {
                params,
            });

            dispatch({
                type: COLLAPSE_TASK_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: COLLAPSE_TASK_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

// Function to clear state
export const clearShareTask = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: SHARE_TASK_RESET });
    };
};

export const clearTaskAction = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CLEAR_TASK_ACTION });
    };
};

export const clearArchiveUnarchive = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ARCHIVE_UNARCHIVE_RESET });
    };
};

export const clearAddSubtaskSchedule = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ADD_SUB_TASK_RESET });
    };
};

export const resetAllTaskDesignSchedule = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_TASK_DESIGN_RESET });
    };
};

export const resetAllTaskConstructionSchedule = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_TASK_CONSTRUCTION_RESET });
    };
};

export const resetAllTaskPrivateSchedule = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_TASK_PRIVATE_RESET });
    };
};

export const resetAllTaskSpace = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_TASK_PRIVATE_RESET });
        dispatch({ type: ALL_TASK_CONSTRUCTION_RESET });
        dispatch({ type: ALL_TASK_DESIGN_RESET });
    };
};

export const resetFindPermissionByEmail = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: FIND_PERMISSIONS_BY_EMAIL_RESET });
    };
};

export const resetUpdatePermissionAssignee = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_PERMISSION_ASSIGNEE_RESET });
    };
};

export const resetCreateTask = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ADD_TASK_RESET });
    };
};

export const resetPostTaskComment = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: POST_TASK_COMMENT_RESET });
    };
};

export const resetDeleteTask = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_TASK_RESET });
    };
};

export const resetRestoreTask = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: RESTORE_TASK_RESET });
    };
};

export const resetEditTaskComment = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: EDIT_TASK_COMMENT_RESET });
    };
};

export const resetDeleteTaskComment = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_TASK_COMMENT_RESET });
    };
};

export const resetUpdateTaskOrdering = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_TASK_ORDERING_RESET });
    };
};

export const resetUpdateTaskRange = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_TASK_RANGE_RESET });
    };
};

export const resetAddPermissionAssigneeDirectly = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ADD_PERMISSION_ASSIGNEE_DIRECTLY_RESET });
    };
};

export const resetRemoveTaskAssignee = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: REMOVE_TASK_ASSIGNEE_RESET });
    };
};

export const resetDetailTask = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_TASK_RESET });
    };
};

export const clearCollapseTask = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: COLLAPSE_TASK_RESET });
    };
};
