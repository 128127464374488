import { useState } from 'react';
import styled from 'styled-components';
import { CustomPagination } from '../TableBorderless/styles';
import { Accordion, Pagination } from 'react-bootstrap';
import { ThreeDots } from 'react-loader-spinner';

export default function TableAccordion({
    columns,
    items,
    collapseContent,
    extraHeader,
    handlePagination,
    loading,
    tableName,
}: {
    columns?: Array<{
        title: string;
        selector?: string;
        className?: string;
        style?: React.CSSProperties;
        Cell?: (row: any, index: number, activeKey: string | null) => JSX.Element;
    }>;
    items: {
        data: any[];
        meta: {
            current_page: number;
            last_page: number;
        };
    };
    collapseContent?: (row: any) => JSX.Element;
    extraHeader?: JSX.Element;
    handlePagination?: (page: number, type: string) => void;
    loading: boolean;
    tableName: string;
}): JSX.Element {
    const [activeKey, setActiveKey] = useState<string | null>(null);

    const { current_page: currentPage, last_page: lastPage } =
        items.meta !== undefined
            ? items.meta
            : {
                  current_page: 1,
                  last_page: 1,
              };

    /**
     * Function to handle Collapse
     *
     * @params event : string
     * @returns void
     */
    const handleCollapse = (eventKey: string): void => {
        setActiveKey(eventKey);
    };

    /**
     * Function  to should display plage
     *
     * @param page : number
     * @returns void
     */
    const shouldDisplayPage = (page: number): boolean => {
        if (
            page === 1 ||
            page === lastPage ||
            (page >= currentPage - 1 && page <= currentPage + 1) ||
            (page === currentPage - 2 && currentPage > 3) ||
            (page === currentPage + 2 && currentPage < lastPage - 2)
        ) {
            return true;
        }

        return false;
    };

    return (
        <AccordionContainer>
            {extraHeader !== undefined && extraHeader}

            <div className="table-responsive">
                <TableWrapper>
                    <table className="table table-borderless mb-0">
                        <thead>
                            <tr>
                                {columns?.map((column, index) => (
                                    <th
                                        key={index}
                                        className={`p-3 fw-semibold ${
                                            column.className ?? ''
                                        }`}
                                        style={column.style}
                                    >
                                        {column.title}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                    </table>
                </TableWrapper>

                {loading ? (
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#5648FB"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            visible={loading}
                        />
                    </div>
                ) : (
                    <AccordionWrapper onSelect={() => handleCollapse('')}>
                        {items?.data?.length === 0 ? (
                            <div className="text-center mt-3">
                                <p className="text-muted mb-0" style={{ fontSize: 14 }}>
                                    No {tableName} found
                                </p>
                            </div>
                        ) : (
                            items?.data?.map((row, index) => (
                                <Accordion.Item key={index} eventKey={row.id}>
                                    <Accordion.Header
                                        onClick={() =>
                                            activeKey === row.id
                                                ? handleCollapse('')
                                                : handleCollapse(row.id)
                                        }
                                    >
                                        <table className="table table-borderless mb-0">
                                            <tbody>
                                                <tr>
                                                    {columns?.map((column, colIndex) => (
                                                        <td
                                                            key={colIndex}
                                                            className={`p-3 ${
                                                                column.className ?? ''
                                                            }`}
                                                            style={column.style}
                                                        >
                                                            {column.Cell != null
                                                                ? column.Cell(
                                                                      row,
                                                                      colIndex,
                                                                      activeKey,
                                                                  )
                                                                : column.selector != null
                                                                ? row[column.selector]
                                                                : null}
                                                        </td>
                                                    ))}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <table className="table table-borderless mb-0">
                                            <tbody>
                                                <tr>
                                                    {collapseContent !== undefined
                                                        ? collapseContent(row)
                                                        : null}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))
                        )}

                        <div className="row g-0">
                            <div className="col-lg-12 mx-auto text-center mt-3">
                                <CustomPagination className="justify-content-center align-items-center">
                                    <Pagination.Prev
                                        onClick={() => handlePagination?.(0, 'prev')}
                                        disabled={currentPage === 1}
                                    />

                                    {Array.from(
                                        { length: lastPage },
                                        (_, i) => i + 1,
                                    ).map(page => {
                                        if (shouldDisplayPage(page)) {
                                            return (
                                                <Pagination.Item
                                                    key={page}
                                                    active={page === currentPage}
                                                    onClick={() =>
                                                        handlePagination?.(page, 'page')
                                                    }
                                                    disabled={page === currentPage}
                                                >
                                                    {page}
                                                </Pagination.Item>
                                            );
                                        } else if (
                                            (page === currentPage - 3 &&
                                                currentPage > 3) ||
                                            (page === currentPage + 3 &&
                                                currentPage < lastPage - 2)
                                        ) {
                                            return <Pagination.Ellipsis key={page} />;
                                        }

                                        return null;
                                    })}

                                    <Pagination.Next
                                        onClick={() => handlePagination?.(0, 'next')}
                                        disabled={currentPage === lastPage}
                                    />
                                </CustomPagination>
                            </div>
                        </div>
                    </AccordionWrapper>
                )}
            </div>
        </AccordionContainer>
    );
}

const TableWrapper = styled.div`

        .rounded-top {
            td {
                background-color: #edecfe;
            }

            td:first-child {
                border-top-left-radius: 0.5rem;
                background-color: #edecfe;
            }

            td:last-child {
                border-top-right-radius: 0.5rem;
                background-color: #edecfe;
            }
        }
        
        .rounded-bottom {
            td {
                background-color: #edecfe;
                border: 1px solid transparent;
            }

            td:first-child {
                border-bottom-left-radius: 0.5rem;
                background-color: #edecfe;
            }

            td:last-child {
                border-bottom-right-radius: 0.5rem;
                background-color: #edecfe;
            }
        }
`;

const AccordionWrapper = styled(Accordion)`
    .accordion-item {
        border: none;
    }

    .accordion-header {
    }

    .accordion-body {
        padding: 0;
        background-color: #eeedff;
    }

    .accordion-button {
        /* background-color: transparent !important; */
        padding: 0;
        border-top-left-radius: 18px !important;
        border-top-right-radius: 18px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }

    .show {
        .accordion-body {
            border-bottom-left-radius: 18px !important;
            border-bottom-right-radius: 18px !important;
            border-top-left-radius: 0px !important;
            border-top-right-radius: 0px !important;
        }
    }

    .accordion-button::after {
        display: none;
    }

    .accordion-button:focus {
        border-color: transparent;
        box-shadow: none;
    }
`;

const AccordionContainer = styled.div`
    th,
    td {
        min-width: 140px;
        max-width: 400px;
    }

    .accordion {
        border: none !important;
        border-color: transparent !important;
        border-width: 0 !important;
    }
`;
