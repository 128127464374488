import { RootState } from '@/redux/store';
import {
    acceptProjectInvitation,
    clearStateAcceptDeclineProject,
    declineProjectInvitation,
    getNotifications,
    getUnreadNotifications,
    markAsReadNotifications,
} from '@/routes/Dashboard/Notification/redux/actions';
import { WrapperTabs } from './styles';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import ButtonBasic from '@/components/atoms/Buttons/ButtonBasic';
import InfiniteScroll from 'react-infinite-scroll-component';
import ModalDashboard from '@/components/atoms/Modals/ModalDashboard';
import AcceptTender from '@/routes/Dashboard/Notification/components/AcceptTender';
import {
    clearApproveTenderState,
    clearRejectTenderState,
    rejectTender,
} from '@/routes/Dashboard/TenderBox/redux/actions';

export default function NotificationGeneralBanner(): JSX.Element {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const acceptDeclineProjectInvitationState = useSelector(
        (state: RootState) => state.acceptDeclineProjectInvitation,
    );
    const approveTenderState = useSelector((state: RootState) => state.approveTender);
    const rejectTenderState = useSelector((state: RootState) => state.rejectTender);

    const [modal, setModal] = useState({
        danger: false,
        caution: false,
        success: false,
        acceptTender: false,
        rejectTender: false,
    });
    const [modalType, setModalType] = useState<string>('');
    const [data, setData] = useState<any[]>([]);

    const [notificationDetail, setNotificationDetail] = useState<{
        id: string;
        uuid: string;
        actions: Array<{
            name: string;
            method: string;
            accept: string;
            uri: string;
        }>;
    }>({
        id: '',
        uuid: '',
        actions: [],
    });

    const allNotificationState = useSelector(
        (state: RootState) => state.allNotifications,
    );

    const [params, setParams] = useState({
        page: 1,
        per_page: 10,
        // sort_by: 'created_at',
        sort_asc: 0,
    });

    useEffect(() => {
        dispatch(getNotifications(params));
    }, []);

    // Function for show modal success when accept or decline invitation success
    useEffect(() => {
        const newParams = {
            ...params,
            page: 1,
        };

        if (acceptDeclineProjectInvitationState?.status === 200) {
            setModal(prev => ({ ...prev, caution: false, danger: false, success: true }));

            dispatch(getNotifications(newParams));
            dispatch(getUnreadNotifications(newParams));
        }

        if (acceptDeclineProjectInvitationState?.status === 400) {
            setModal(prev => ({
                ...prev,
                caution: false,
                danger: false,
                success: false,
            }));

            dispatch(getNotifications(newParams));
            dispatch(getUnreadNotifications(newParams));
        }

        setParams(newParams);
    }, [acceptDeclineProjectInvitationState]);

    // Function for handle approve tender success
    useEffect(() => {
        if (approveTenderState.status === 200) {
            setModal(prev => ({ ...prev, acceptTender: false }));

            setTimeout(() => {
                setModal(prev => ({ ...prev, success: true }));
                dispatch(getNotifications(params));
                dispatch(getUnreadNotifications(params));
                dispatch(clearApproveTenderState());
            }, 1000);
        }
    }, [approveTenderState.status]);

    // Function for handle reject tender success
    useEffect(() => {
        if (rejectTenderState.status === 200) {
            setModal(prev => ({ ...prev, rejectTender: false }));

            setTimeout(() => {
                setModal(prev => ({ ...prev, success: true }));
                dispatch(getNotifications(params));
                dispatch(getUnreadNotifications(params));
                dispatch(clearRejectTenderState());
            }, 1000);
        }
    }, [rejectTenderState.status]);

    /**
     * Function for handle accept or decline project invitation
     *
     * @param number type
     * @return void
     */
    const handleAcceptDeclineInvitation = (type: number): void => {
        const acceptUri: string | undefined = notificationDetail?.actions?.find(
            (action: { method: string }) => action?.method === 'PUT',
        )?.uri;

        const declineUri: string | undefined = notificationDetail?.actions?.find(
            (action: { method: string }) => action.method === 'DELETE',
        )?.uri;

        type !== 1
            ? dispatch(acceptProjectInvitation(acceptUri ?? ''))
            : dispatch(declineProjectInvitation(declineUri ?? ''));

        dispatch(markAsReadNotifications(notificationDetail?.id));
    };

    /**
     * Function for handle accept or decline tender
     *
     * @param number type
     * @return void
     */
    const handleRejectTender = (): void => {
        const getLink = notificationDetail?.actions.find(
            (item: { name: string; method: string; accept: string; uri: string }) =>
                item.name === 'Reject',
        );

        const urlParams = new URLSearchParams(new URL(getLink?.uri ?? '').search);

        const payload = {
            tender_applicant_id: urlParams.get('tender_applicant_id') as string,
            notification_uuid: urlParams.get('notification_uuid') as string,
        };

        dispatch(rejectTender(payload));
        dispatch(markAsReadNotifications(notificationDetail?.id));
    };

    /**
     * Handle project notification
     *
     * @param number type
     * @returns JSX.Element
     */
    const handleProjectNotification = (data: {
        id: string;
        uuid: string;
        actions: Array<{
            name: string;
            method: string;
            accept: string;
            uri: string;
        }>;
    }): JSX.Element => {
        const name = data?.actions[0]?.name;
        const baseUrl = import.meta.env.VITE_FE_URL;
        const uri = data?.actions[0]?.uri?.replace(baseUrl, '');

        let chatId = 0;

        if (name === 'Reply Now') {
            const url = new URL(data?.actions[0]?.uri);
            const params = new URLSearchParams(url.search);
            chatId = parseInt(params.get('chat_id') ?? '0');
        }

        if (data?.actions?.length > 0) {
            return (
                <div
                    className={`d-flex justify-content-end gap-2 ${
                        data?.actions?.length > 2 ? 'flex-wrap' : ''
                    }`}
                    style={{
                        fontSize: 12,
                    }}
                >
                    {data?.actions.map((action: any, index: number) => {
                        switch (action.name) {
                            case 'Info':
                                return (
                                    <ButtonBasic
                                        className="btn btn-warning text-white rounded-5 px-4 py-0"
                                        text={'Info'}
                                        onClick={() => {
                                            navigate(uri);
                                        }}
                                        key={index}
                                    />
                                );
                            case 'Accept':
                                return (
                                    <ButtonBasic
                                        className="btn btn-success text-white rounded-5 px-4 py-0"
                                        text={'Accept'}
                                        onClick={() => {
                                            setModal(prev => ({
                                                ...prev,
                                                acceptTender: true,
                                            }));
                                            setModalType('accept tender');
                                            setNotificationDetail(data ?? {});
                                        }}
                                        key={index}
                                    />
                                );
                            case 'Reject':
                                return (
                                    <ButtonBasic
                                        className="btn btn-danger text-white rounded-5 px-4 py-0"
                                        text={'Decline'}
                                        onClick={() => {
                                            setModal(prev => ({
                                                ...prev,
                                                rejectTender: true,
                                            }));
                                            setModalType('reject tender');
                                            setNotificationDetail(data ?? {});
                                        }}
                                        key={index}
                                    />
                                );
                            case 'Reply Now':
                                return (
                                    <ButtonBasic
                                        className="btn btn-success text-white rounded-5 px-4 py-1"
                                        text={'Reply'}
                                        onClick={() => {
                                            navigate(uri, {
                                                state: {
                                                    id: chatId,
                                                },
                                            });
                                        }}
                                        key={index}
                                    />
                                );
                            case 'go to':
                                return (
                                    <ButtonBasic
                                        className="btn btn-success text-white rounded-5 px-4 py-1"
                                        text={'Go to'}
                                        onClick={() => {
                                            navigate(uri);
                                        }}
                                        key={index}
                                    />
                                );
                            case 'Accept Invitation':
                                return (
                                    <div className="d-flex">
                                        <ButtonBasic
                                            className="btn btn-success text-white rounded-5 px-4 py-1"
                                            text={'Accept'}
                                            onClick={() => {
                                                setModal(prev => ({
                                                    ...prev,
                                                    caution: true,
                                                }));
                                                setNotificationDetail(data ?? {});
                                            }}
                                            key={index}
                                        />
                                        &nbsp;•&nbsp;
                                        <ButtonBasic
                                            className="btn btn-danger text-white rounded-5 px-4 py-1"
                                            text={' Reject'}
                                            onClick={() => {
                                                setModal(prev => ({
                                                    ...prev,
                                                    danger: true,
                                                }));
                                                setNotificationDetail(data ?? {});
                                            }}
                                            key={index}
                                        />
                                    </div>
                                );
                            default:
                                return <div style={{ height: 31.5 }} />;
                        }
                    })}
                </div>
            );
        }

        return <div style={{ height: 31.5 }} />;
    };

    useEffect(() => {
        if (allNotificationState?.status === 200) {
            if (params.page > 1) {
                const newTableData = [...data, ...allNotificationState?.response?.data];
                const uniqueTableData = Array.from(
                    new Set(newTableData.map(item => item.id)),
                ).map(id => {
                    return newTableData.find(item => item.id === id);
                });
                return setData(uniqueTableData);
            }
            setData(allNotificationState?.response?.data);
        }
    }, [allNotificationState]);

    /**
     * Handle pagination
     *
     * @param number page
     * @param string type
     * @returns void
     */
    const handlePagination = (page: number): void => {
        setParams({
            ...params,
            page: params.page + 1,
        });

        const payload = {
            ...params,
            page: params.page + 1,
        };

        dispatch(getNotifications(payload));
    };

    /**
     * Function for handle confirm modal
     *
     * @param string type
     * @returns void
     */
    const handleConfirmModal = (type: string): void => {
        type === 'accept'
            ? navigate(
                  `/project/${
                      acceptDeclineProjectInvitationState?.response?.id as number
                  }/summary`,
              )
            : setModal(prev => ({ ...prev, success: false }));

        dispatch(clearStateAcceptDeclineProject());
    };

    return (
        <WrapperTabs>
            {data?.length > 0 ? (
                <InfiniteScroll
                    next={() => handlePagination(params.page + 1)}
                    hasMore={
                        allNotificationState?.response?.meta?.last_page !== params.page
                    }
                    loader={<span></span>}
                    dataLength={data.length}
                    scrollableTarget="scrollableDiv"
                >
                    <div
                        className="text-center p-3"
                        style={{
                            fontSize: 14,
                        }}
                    >
                        <Link
                            to="/notification"
                            className="text-primary text-decoration-none fw-semibold"
                        >
                            see all notifications
                        </Link>
                    </div>
                    <ul id="scrollableDiv" className="notification-list">
                        {data?.map((list: any, index: number) => (
                            <li
                                key={index}
                                className="py-4 mb-3 notification-items rounded-4"
                            >
                                <div className="d-flex justify-content-between px-3">
                                    <div className="d-flex justify-content-between flex-column me-2 notification-content">
                                        <h5 className="notification-title mb-0">
                                            {list?.title}
                                        </h5>
                                        <p
                                            className="notification-message mb-0"
                                            dangerouslySetInnerHTML={{
                                                __html: list?.description,
                                            }}
                                        ></p>
                                    </div>
                                    <div className="d-flex flex-column text-end">
                                        <p className="text-muted fw-semibold mb-2">
                                            {list?.diff_for_humans_created_at}
                                        </p>
                                        {handleProjectNotification(list)}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </InfiniteScroll>
            ) : (
                <ul className="notification-list">
                    <li className="text-muted mb-0 text-center p-3">
                        No notification found
                    </li>
                </ul>
            )}
            <ModalDashboard
                modal={modal.danger}
                setModal={setModal}
                variant="danger"
                type="cancel"
                title="Decline Invitation?"
                body="Are you sure want to decline this invitation?"
                withCancel
                onConfirm={() => {
                    handleAcceptDeclineInvitation(1);
                    setModalType('decline');
                }}
                loading={acceptDeclineProjectInvitationState?.loading}
            />
            <ModalDashboard
                modal={modal.caution}
                setModal={setModal}
                variant="info"
                type="caution"
                title="Accept Invitation?"
                body="Are you sure want to accept this invitation?"
                withCancel
                onConfirm={() => {
                    handleAcceptDeclineInvitation(2);
                    setModalType('accept');
                }}
                loading={acceptDeclineProjectInvitationState?.loading}
            />
            <ModalDashboard
                modal={modal.rejectTender}
                setModal={setModal}
                variant="danger"
                type="cancel"
                title="Reject Tender?"
                body="Are you sure want to reject this tender request?"
                withCancel
                onConfirm={() => handleRejectTender()}
                loading={rejectTenderState?.loading}
            />

            <AcceptTender
                modal={modal.acceptTender}
                setModal={setModal}
                setModalType={setModalType}
                notificationDetail={notificationDetail}
            />

            <ModalDashboard
                modal={modal.success}
                setModal={setModal}
                variant="success"
                type="success"
                title="Success!"
                body={`Successfully ${modalType} on project`}
                onConfirm={() => handleConfirmModal(modalType)}
            />
        </WrapperTabs>
    );
}
