import subscriptionImage2 from '@/assets/images/subscription/help-subscription-image-2.webp';
import subscriptionImage3 from '@/assets/images/subscription/help-subscription-image-3.webp';
import subscriptionImage4 from '@/assets/images/subscription/help-subscription-image-4.webp';

import { Fragment } from 'react';

export default function HowToInsertCouponCode(): JSX.Element {
    const contents = [
        {
            id: 1,
            text: 'Coupons here can be used to get discounts or get special prices, and for the record, when you input the coupon at this time the coupon will begin to be used in the next subscription period. To input coupons code you must add card first, if you have added a card then you can input the coupon code.',
            alt: '',
            image: subscriptionImage2,
        },
        {
            id: 2,
            text: 'After that a popup will appear to enter the coupon code that you already have, you can also check whether the code you entered is correct or not, if you have clicked the apply coupon button to continue and your coupon will be used automatically in the next subscription period.',
            alt: '',
            image: subscriptionImage3,
        },
        {
            id: 3,
            text: 'If already in the coupon column there will be information about the coupon that you input earlier.',
            alt: '',
            image: subscriptionImage4,
        },
    ];

    return (
        <Fragment>
            {contents.map((content, index) => (
                <div key={index} className="text-grey-300 mb-10">
                    <img src={content.image} alt={content.alt} className="img-fluid" />
                    <p className="mt-4 pl-2">{content.text}</p>
                </div>
            ))}
        </Fragment>
    );
}
