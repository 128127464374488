import { axiosClient } from '@/services/GatewayService';
import { AppDispatch } from '@/redux/store';

import {
    ALL_PENDING_INVITATION_REQUEST,
    ALL_PENDING_INVITATION_SUCCESS,
    ALL_PENDING_INVITATION_FAIL,
    CURRENT_PROFILE_PROJECT_REQUEST,
    CURRENT_PROFILE_PROJECT_SUCCESS,
    CURRENT_PROFILE_PROJECT_FAIL,
    KICK_MEMBER_REQUEST,
    KICK_MEMBER_SUCCESS,
    KICK_MEMBER_FAIL,
    KICK_MEMBER_RESET,
    ALL_NICHE_TRADE_REQUEST,
    ALL_NICHE_TRADE_SUCCESS,
    ALL_NICHE_TRADE_FAIL,
    DETAIL_NICHE_TRADE_REQUEST,
    DETAIL_NICHE_TRADE_SUCCESS,
    DETAIL_NICHE_TRADE_FAIL,
    ADD_NICHE_TRADE_REQUEST,
    ADD_NICHE_TRADE_SUCCESS,
    ADD_NICHE_TRADE_FAIL,
    UPDATE_NICHE_TRADE_REQUEST,
    UPDATE_NICHE_TRADE_SUCCESS,
    UPDATE_NICHE_TRADE_FAIL,
    DELETE_NICHE_TRADE_REQUEST,
    DELETE_NICHE_TRADE_SUCCESS,
    DELETE_NICHE_TRADE_FAIL,
    AWARDING_MEMBER_REQUEST,
    AWARDING_MEMBER_SUCCESS,
    AWARDING_MEMBER_FAIL,
    NULLIFY_MEMBER_REQUEST,
    NULLIFY_MEMBER_SUCCESS,
    NULLIFY_MEMBER_FAIL,
    UPDATE_MEMBER_PERMISSION_REQUEST,
    UPDATE_MEMBER_PERMISSION_SUCCESS,
    UPDATE_MEMBER_PERMISSION_FAIL,
    UPDATE_MEMBER_PERMISSION_RESET,
    CLEAR_AWARDING_NULLIFY_MEMBER,
    CLEAR_ACTION_NICHE_TRADE,
} from '../reducers';

/**
 * Function to get all niche trade
 *
 * @param string project_id
 */
export const getCurrentProfileOnProject = (project_id: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CURRENT_PROFILE_PROJECT_REQUEST });
        try {
            const res = await axiosClient.get('project/members/profile', {
                params: {
                    project_id,
                },
            });

            dispatch({
                type: CURRENT_PROFILE_PROJECT_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: CURRENT_PROFILE_PROJECT_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all niche trade
 *
 * @param number params.page - page of list
 * @param number params.per_page - number of list per page
 * @param string params.search - search of list
 * @param string params.sort_by - sort by of list
 * @param number params.sort_asc - sort asc of list
 * @return Promise<void>
 */
export const getAllPendingInvitation = (params: {
    project_id: string | undefined;
    page: number;
    per_page: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_PENDING_INVITATION_REQUEST });
        try {
            const res = await axiosClient.get('project/members/invitations', {
                params,
            });

            dispatch({
                type: ALL_PENDING_INVITATION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_PENDING_INVITATION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get all niche trade
 */
export const getAllNicheTrade = (params: {
    project_id: string;
    page?: number;
    per_page?: number;
    search?: string;
    sort_by?: string;
    sort_asc?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ALL_NICHE_TRADE_REQUEST });
        try {
            const res = await axiosClient.get('project/niches', {
                params,
            });

            dispatch({
                type: ALL_NICHE_TRADE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ALL_NICHE_TRADE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to get detail niche trade
 */
export const getDetailNicheTrade = (params: { project_id: string; id: number }): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DETAIL_NICHE_TRADE_REQUEST });
        try {
            const res = await axiosClient.get('project/niches', {
                params,
            });

            dispatch({
                type: DETAIL_NICHE_TRADE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DETAIL_NICHE_TRADE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to add all niche trade
 *
 * @params string project_id
 * @params FormData formData
 * @return Promise<void>
 */
export const createNicheTrade = (project_id: string, formData: FormData): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: ADD_NICHE_TRADE_REQUEST });
        try {
            const res = await axiosClient.post('project/niches', formData, {
                params: {
                    project_id,
                },
            });

            dispatch({
                type: ADD_NICHE_TRADE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: ADD_NICHE_TRADE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to update niche trade
 *
 * @params string project_id
 * @params FormData formData
 * @return Promise<void>
 */
export const updateNicheTrade = (
    params: {
        id: string;
        project_id: string;
    },
    formData: FormData,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_NICHE_TRADE_REQUEST });
        try {
            const res = await axiosClient.post('project/niches', formData, {
                params,
            });

            dispatch({
                type: UPDATE_NICHE_TRADE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UPDATE_NICHE_TRADE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to delete niche trade
 *
 * @params string project_id
 * @return Promise<void>
 */
export const deleteNicheTrade = (params: { ids: string; project_id: string }): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: DELETE_NICHE_TRADE_REQUEST });
        try {
            const res = await axiosClient.delete('project/niches', {
                params,
            });

            dispatch({
                type: DELETE_NICHE_TRADE_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: DELETE_NICHE_TRADE_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to kick a invited user or inhouse user of project
 *
 * @param string project_id
 * @param string emails
 * @return Promise<void>
 */
export const kickMemberProject = (project_id: string, emails: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: KICK_MEMBER_REQUEST });
        try {
            const res = await axiosClient.delete('project/members', {
                params: {
                    project_id,
                    emails,
                },
            });

            dispatch({
                type: KICK_MEMBER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: KICK_MEMBER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to award a invited user or inhouse user of project
 *
 * @param string project_id
 * @param string emails
 * @return Promise<void>
 */
export const awardingMember = (project_id: string, emails: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: AWARDING_MEMBER_REQUEST });
        try {
            const res = await axiosClient.put('project/members/award', null, {
                params: {
                    project_id,
                    emails,
                },
            });

            dispatch({
                type: AWARDING_MEMBER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: AWARDING_MEMBER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to nullify a invited user or inhouse user of project
 *
 * @param string project_id
 * @param string emails
 * @return Promise<void>
 */
export const nullifyMember = (project_id: string, emails: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: NULLIFY_MEMBER_REQUEST });
        try {
            const res = await axiosClient.put('project/members/nullify', null, {
                params: {
                    project_id,
                    emails,
                },
            });

            dispatch({
                type: NULLIFY_MEMBER_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: NULLIFY_MEMBER_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

/**
 * Function to update member permission on project
 *
 * @param string project_id
 * @param string emails
 * @return Promise<void>
 */
export const updateMemberPermission = (
    params: { project_id: string; email: string },
    formData: object,
): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_MEMBER_PERMISSION_REQUEST });
        try {
            const res = await axiosClient.post('project/members/permission', formData, {
                params,
            });

            dispatch({
                type: UPDATE_MEMBER_PERMISSION_SUCCESS,
                payload: res.data,
            });
        } catch (error: any) {
            dispatch({
                type: UPDATE_MEMBER_PERMISSION_FAIL,
                payload: error?.response?.data.message,
            });
        }
    };
};

// Clear state
export const clearStateKickMemberProject = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: KICK_MEMBER_RESET });
    };
};

export const clearActionNicheTrade = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CLEAR_ACTION_NICHE_TRADE });
    };
};

export const clearStateAwardingNullifyMember = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: CLEAR_AWARDING_NULLIFY_MEMBER });
    };
};

export const clearStateUpdateMemberPermission = (): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: UPDATE_MEMBER_PERMISSION_RESET });
    };
};
